import { Component } from 'react'
import TimePicker from 'rc-time-picker'
import i18next from 'i18next'

/*
 * Input mechanism for cron based scheduling.  Properties:
 *   value - current cron expression.
 *   onChange - called on value change
 */
export default class Schedule extends Component {
  static defaultProps = {
    timeZone: 'GMT',
    options: ['daily', 'weekly', 'monthly', 'yearly'],
  }

  static getDerivedStateFromProps(props) {
    return getState(props)
  }

  constructor(props) {
    super(props)

    this.state = getState(props)
  }

  componentDidUpdate() {
    const now = new Date()
    const yearFromNow = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
    let start = new Date(now.getFullYear(), this.state.month - 1, this.state.daysOfMonth[0])
    if (start < now) {
      start = new Date(now.getFullYear() + 1, this.state.month - 1, this.state.daysOfMonth[0])
    }
    $('#dayOfYear').daterangepicker({
      startDate: start,
      singleDatePicker: true,
      showDropdowns: true,
      minDate: now,
      maxDate: yearFromNow,
      locale: {
        format: 'M/D'
      }
    }, (start) => {
      this.updateDayOfYear(start.format('M'), start.format('D'))
    })
    $('.calendar.right').each(function() {
      this.style.setProperty( 'display', 'none', 'important' )
    })
  }

  render() {
    const dayOfMonthOptions = []
    for (let i = 1; i <= 31; i++) {
      dayOfMonthOptions.push('' + i)
    }
    const dayOfYear = this.state.month + '/' + this.state.daysOfMonth
    return (
      <div id="frequency" className="col-md-12" style={{'marginLeft': '8px'}}>
        <div className="col-md-3">
          <label>{i18next.t('schedule.frequency')}</label>
          <select disabled={this.props.disabled} value={this.state.frequency} onChange={() => {this.updateFrequency(event.target.value)}}
                  className="form-control" id="schedule-interval" >
            {(this.state.frequency == 'daily' || this.props.options.includes('daily')) && (
              <option value='daily'>{i18next.t('schedule.daily')}</option>              
            )}
            {(this.state.frequency == 'weekly' || this.props.options.includes('weekly')) && (
              <option value='weekly'>{i18next.t('schedule.weekly')}</option>
            )}
            {(this.state.frequency == 'monthly' || this.props.options.includes('monthly')) && (
              <option value='monthly'>{i18next.t('schedule.monthly')}</option>
            )}
            {(this.state.frequency == 'yearly' || this.props.options.includes('yearly')) && (
              <option value='yearly'>{i18next.t('schedule.yearly')}</option>
            )}
          </select>
        </div>
        { this.state.frequency == 'yearly' && (
          <div className="col-md-3">
            <label>{i18next.t('schedule.dayOfYear')}</label>
            <input disabled={this.props.disabled} id="dayOfYear" value={dayOfYear} readOnly className="form-control" />
          </div>
        )}
        { this.state.frequency == 'monthly' && (
          <div className="col-md-3">
            <label>{i18next.t('schedule.daysOfMonth')}</label>
            <select disabled={this.props.disabled} style={{height: 145}} multiple value={this.state.daysOfMonth} onChange={event => {this.updateDaysOfMonth(Array.from(event.target.options).filter(o => o.selected).map(o => o.value))}} className="form-control">
              { dayOfMonthOptions.map(option => {
                return (<option key={option} value={option}>{option}</option>)
              }) }
            </select>
          </div>
        )}
        { this.state.frequency == 'weekly' && (
          <div className="col-md-3">
            <label>{i18next.t('schedule.daysOfWeek')}</label>
            <select disabled={this.props.disabled} style={{height: 145}} multiple value={this.state.daysOfWeek} onChange={event => {this.updateDaysOfWeek(Array.from(event.target.options).filter(o => o.selected).map(o => o.value))}} className="form-control">
              {[0, 1, 2, 3, 4, 5, 6].map(i => (
                <option key={i} value={i}>{i18next.t('schedule.days.' + i)}</option>
              ))}
            </select>
          </div>
        )}
        <div className="col-md-6">
          <label>{i18next.t('schedule.time', {timeZone: this.props.timeZone})}</label>
          <TimePicker disabled={this.props.disabled} allowEmpty={false} showSecond={false} value={this.state.time} onChange={(time) => {this.updateTime(time)}} />
        </div>
      </div>
    )
  }

  updateFrequency(frequency) {
    this.props.onChange(this.calculateCron(frequency, this.state.month, this.state.daysOfMonth, this.state.daysOfWeek, this.state.time))
  }

  updateDayOfYear(month, dayOfMonth) {
    this.props.onChange(this.calculateCron(this.state.frequency, month, dayOfMonth, this.state.daysOfWeek, this.state.time))
  }

  updateDaysOfMonth(daysOfMonth) {
    this.props.onChange(this.calculateCron(this.state.frequency, this.state.month, daysOfMonth.length > 0 ? daysOfMonth.join(',') : '*', this.state.daysOfWeek, this.state.time))
  }

  updateDaysOfWeek(daysOfWeek) {
    this.props.onChange(this.calculateCron(this.state.frequency, this.state.month, this.state.daysOfMonth, daysOfWeek.length > 0 ? daysOfWeek.join(',') : '*', this.state.time))
  }

  updateTime(time) {
    this.props.onChange(this.calculateCron(this.state.frequency, this.state.month, this.state.daysOfMonth, this.state.daysOfWeek, time))
  }

  calculateCron(frequency, month, daysOfMonth, daysOfWeek, time) {
    const hour = time.hour()
    const minute = time.minute()
    let schedule = ''
    switch (frequency) {
      case 'daily':
        schedule = minute + ' ' + hour + ' * * *'
        break
      case 'weekly':
        schedule = minute + ' ' + hour + ' * * ' + daysOfWeek
        break
      case 'monthly':
        schedule = minute + ' ' + hour + ' ' + daysOfMonth + ' * *'
        break
      case 'yearly':
        schedule = minute + ' ' + hour + ' ' + daysOfMonth + ' ' + month + ' *'
        break
    }
    return schedule
  }
}


function getState(props) {
  const state = {
    schedule: props.value,
  }

  parseSchedule(state, props)
  return state
}

function parseSchedule(state, props) {
  const schedule = props.value
  const schedule_parts = schedule.split(" ")

  if (schedule_parts.length > 4) {
    let minute = schedule_parts[0] != '*' ? parseInt(schedule_parts[0]) : 0
    let hour = schedule_parts[1] != '*' ? parseInt(schedule_parts[1]) : 0
    state.frequency = getScheduleInterval(schedule_parts)
    hour = hour > 9 ? hour : '0' + hour
    minute = minute > 9 ? minute : '0' + minute
    state.time = moment(hour + ':' + minute, 'h:mm')
    state.daysOfMonth = (schedule_parts[2] == '*' ? new Date().getDate() + 1 + '' : schedule_parts[2]).split(',')
    state.month = schedule_parts[3] == '*' ? (new Date().getMonth() + 1 + '') : schedule_parts[3]
    state.daysOfWeek = (schedule_parts[4] == '*' ? '1' : schedule_parts[4]).split(',')
  }
}

function getScheduleInterval(schedule_parts) {
  if (schedule_parts[1] == '*') {
    return 'hourly'
  } else if (schedule_parts[4] != '*') {
    return 'weekly'
  } else if (schedule_parts[3] != '*') {
    return 'yearly'
  } else if (schedule_parts[2] != '*') {
    return 'monthly'
  } else {
    return 'daily'
  }
}
