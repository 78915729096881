import EditSteps from './EditSteps'
import Field from '../Field'
import { updateState } from "../../utilities/feed_utils"
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'

export default class BuildFeed extends EditSteps {
  getTable() {
    return 'build';
  }

  colorize() {
    return {
      'preprocessing': params => params.value == 'preprocessing',
      'processing': params => params.value != 'preprocessing' && params.value != 'postprocessing',
      'postprocessing': params => params.value == 'postprocessing',
    }
  }

  getColumns() {
    return [{
      id: 'step_order',
      header: 'Order',
      rowDrag: true,
      width: 100,
    },{
      id: 'step_name',
      header: 'Step',
      cellRenderer: (value, row) => {
        return (
          <a onClick={() => {this.editStep(row.id)}} style={{cursor: 'pointer'}}>{value}</a>
        )
      },
      width: 300,
    },{
      id: 'stage',
      header: i18next.t('feeds.stage'),
      width: 200,
      classRules: this.colorize(),
      format: (value) => i18next.t('feeds.' + value),
    }, {
      id: 'step_description',
      header: 'Description',
      width: 500,
    },{
      id: 'user_name',
      header: 'Last Modified By',
      width: 300,
    }];
  }

  prepareData(data) {
    data.user_id = this.props.user_id
  }

  newStep() {
    this.setState({editView: true, id: null, step_name: '', step_description: '', step_sql: '', database: 'redshift'});
  }

  renderFields() {
    return (
      <div>
        <Field label="Name">
          <input type="text" className="form-control" value={this.state.step_name} onChange={() => updateState('step_name', event.target.value, this)} />
        </Field>
        <Field label="Description">
          <textarea className="form-control" rows="5" value={this.state.step_description} onChange={() => updateState('step_description', event.target.value, this)} />
        </Field>
        <Field label="SQL" >
          <SQLEditor
            code={this.state.step_sql}
            onChange={(sql) => updateState('step_sql', sql, this)}
          />
          <div className="col-md-12 mt-25">
            <div className="col-md-3"></div>
            <div className="col-md-4">
              <input type="radio" checked={this.state.database=='redshift'} onChange={() => updateState('database', 'redshift', this)} />
              <label>Redshift</label>
            </div>
            <div className="col-md-4">
              <input type="radio" checked={this.state.database=='postgres'} onChange={() => updateState('database', 'postgres', this)} />
              <label>PostgreSQL</label>
            </div>
          </div>
        </Field>
        {this.renderExtra()}
      </div>
    );
  }

  renderExtra() {
    return null; // Can be overriden
  }
}
