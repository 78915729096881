import React, { useState } from 'react'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import { Box } from '@mui/material'
import Select from '../widgets/Select'
import Option from '../widgets/Option'

const SqlTemplateSelect = (props) => {
  const [sqlTemplate, setSqlTemplate] = useState('')

  const handleChange = (templateId) => {
    props.handleSelectTemplate(templateId)
    setSqlTemplate(templateId)
  }

  return (
    <Box sx={{ display: "flex", minWidth: 508, alignContent: "flex-start" }}>
      <Select
        sx={{
          minWidth: 508,
          width: "auto",
          '& > svg': {
            right: -230
          }
        }}
        IconComponent={ArrowDropDownCircleIcon}
        autoWidth
        labelText="abtest.edit.sqlTemplate"
        onChange={event => handleChange(event.target.value)}
        value={sqlTemplate}
      >
        {props.sqlTemplates ? props.sqlTemplates.map((template, i) => (
          <Option key={i} value={template.id} labelText={template.name} />
        )) : null}
      </Select>
    </Box>
  )
}

export default SqlTemplateSelect