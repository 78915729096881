import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import History from './History'
import Dependencies from './Dependencies'
import { getFeeds } from '../../api/feeds'

class Feeds extends Component {
  tableRef = createRef()
  
  tabs = [
    {id: null, name: 'All', dependencies: false},
    {id: 'Structure', name: 'Structure', dependencies: true},
    {id: 'Performance', name: 'Performance', dependencies: true},
    {id: 'Revenue', name: 'Revenue', dependencies: true},
    {id: 'Stand Alone', name: 'Stand Alone', dependencies: false},
  ]

  sections = [
    {id: 'all', name: 'Feeds'},
    {id: 'dependencies', name: 'Dependencies'},
    {id: 'history', name: 'History'},
  ]

  constructor(props){
    super(props)
    const section = props.match.params.section ? props.match.params.section : 'all'
    const tab = props.match.params.filter ? props.match.params.filter : (section == 'dependencies' ? 'Structure' : null)
    this.state = {section, tab}
  }

  updateTab(tab) {
    this.props.history('/feeds/section/' + this.state.section + (tab == null ? '' : ('/' + tab)))
    this.setState({tab}, () => {
      if (this.tableRef.current) {
        this.tableRef.current.refreshRows()
      }
    })
  }

  updateSection(section) {
    this.props.history('/feeds/section/' + section + (section == 'dependencies' ? '/Structure' : ''))
    this.setState({section, tab: section == 'dependencies' && (this.state.tab == null || this.state.tab == 'Stand Alone') ? 'Structure' : this.state.tab})
  }

  formatBoolean(value) {
    return value=='true' ? (<i className="fa fa-check"></i>) : (<i className="fa fa-times"></i>)
  }

  formatLink(value, row) {
    return (
      <Link to={'/feeds/'+row.feed_id + '/overview'} > {value} </Link>
    )
  }

  getColumns() {
    return [{
        id: 'client_name',
        datatype: 'text',
      }, {
        id: 'feed',
        datatype: 'text',
        cellRenderer: this.formatLink,
        width: 300,
      }, {
        id: 'feed_type',
        datatype: 'text',
      }, {
        id: 'source',
        datatype: 'text',
      }, {
        id: 'destination_table',
        header: 'feeds.table',
        datatype: 'text',
      }, {
        id: 'consumers',
        datatype: 'text',
      }, {
        id: 'status',
        cellRenderer: this.formatBoolean,
      },
    ]
  }

  renderFeeds() {
    const tab = this.tabs.find(t => t.id == this.state.tab)
    const tabIndex = this.tabs.indexOf(tab)
    return(
      <div>
        <div className="box-header">
          <div className="add-new-button" >
            <Link to={'/feeds/new'} className="btn btn-box-tool text-green">
              <i className="fa fa-plus js_complete"></i>
              <span>Add New Feed</span>
            </Link>
          </div>
        </div>
        <Tabs selectedIndex={tabIndex} onSelect={i => this.updateTab(this.tabs[i].id)}>
          <TabList>
            {this.tabs.map((tab) => {
              return (
                <Tab key={tab.id}>
                  {tab.name}
                </Tab>
              )
            })}
          </TabList>
          {this.tabs.map((tab) => {
            return (
              <TabPanel key={tab.id}></TabPanel>
            )
          })}
          <Table 
            tableName='feeds'
            ref={this.tableRef}
            fetch={(tableData) => {
              const filter = this.state.tab
              if (filter) {
                if (!tableData['text_equal_filter']) {
                  tableData['text_equal_filter'] = []
                }
                tableData['text_equal_filter'].push('feed_type ' + filter)
              }
              return getFeeds(this.props.internal_client_id, tableData)
            }}
            columns={this.getColumns()} />
        </Tabs>
      </div>
    )
  }


  renderDependencies() {
    const allTabs = this.tabs.map((t, i) => Object.assign({}, t, {index: i}))
    const tabs = allTabs.filter(t => t.dependencies)
    let tab = tabs.find(t => t.id == this.state.tab)
    tab = tab == null ? tabs.find(t => t.id == 'Structure') : tab
    const tabIndex = tabs.indexOf(tab)
    return(
      <div>
        <Tabs selectedIndex={tabIndex} onSelect={i => this.updateTab(tabs[i].id)}>
          <TabList>
            {tabs.map((tab) => {
              return (
                <Tab key={tab.id}>
                  {tab.name}
                </Tab>
              )
            })}
          </TabList>
          {tabs.map((tab) => {
            return (
              <TabPanel key={tab.id}></TabPanel>
            )
          })}
          <Dependencies filter={tab.id}/>
        </Tabs>
      </div>
    )
  }

  render() {
    const section = this.sections.find(s => s.id == this.state.section)
    const sectionIndex = this.sections.indexOf(section)
    return (
      <div className="site-canvas">
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <Tabs selectedIndex={sectionIndex} onSelect={i => this.updateSection(this.sections[i].id)}>
                <TabList>
                  {this.sections.map((tab) => {
                    return (
                      <Tab key={tab.id}>
                        {tab.name}
                      </Tab>
                    )
                  })}
                </TabList>
                  <TabPanel style={{backgroundColor: 'white'}}>
                    {this.renderFeeds()}
                  </TabPanel>
                  <TabPanel style={{backgroundColor: 'white'}}>
                    {this.renderDependencies()}
                  </TabPanel>
                  <TabPanel style={{backgroundColor: 'white'}}>
                    <History/>
                  </TabPanel>
              </Tabs>
            </div>
          </div>
        </section>
      </div>)
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Feeds))
