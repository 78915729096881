import _ from "lodash"
import locale from '../../api/locale'
import { connect } from 'react-redux'
import { Component, } from 'react'
import withRouter from '../../wrappers/withRouter'
import TextField from '../widgets/TextField'
import Button from '../widgets/Button'
import Form from '../Form'
import Field from '../Field'
import FTNotice from '../FTNotice'
import { Box } from '@mui/material'
import { getDescriptions, updateDescription, getElementHistory, getSampleData} from '../../api/ai_infrastructure'
import i18next from 'i18next'
import queryString from 'query-string'
import Table from '../widgets/Table'
import { determineType } from '../../utilities/utils'

class AiPrompt extends Component {
  constructor(props) {
    super(props)
    const queryParams = queryString.parse(this.props.location.search)
    const hideEngineering = queryParams.hideEngineering == 'true'
    const sampleData = queryParams.sampleData == 'true'
    this.state = {
      prompt: '',
      type: props.match.params.type,
      view: props.match.params.type.toLowerCase().replace(' ', '_') + '_' + props.internal_client_id,
      conversation: '',
      agent: '',
      tool: '',
      campaign_causes: '',
      metrics: '',
      analytics: '',
      currentText: {},
      currentTextHistory: [],
      currentHistory: '',
      hideEngineering,
      showSampleData: sampleData,
      sampleData: null
    }
  }

  componentDidMount() {
    getDescriptions().then(response => {
      const newState = {}
      response.data.forEach(element => {
        const subState = {'code_description': element['code_description'], 'user_description': element['user_description']}
        newState[element.element] = subState
        if (this.state.type == element.element) {
          newState['currentText'] = subState
        }
      })
      this.setState(newState)
    })
    getElementHistory(this.state.type).then(response => {
      const latest = response.data.length > 0 ? response.data[0].code_description : ''
      this.setState({currentTextHistory: response.data, currentHistory: latest})
    })
    if (this.state.showSampleData) {
      getSampleData(this.state.type).then(sampleData => {
        this.setState({sampleData})
      })
    }
  }

  updateEngineering(text) {
    const subState = {'code_description': text, 'user_description': this.state[this.state.type]['user_description']}
    const newState = {currentText: subState}
    newState[this.state.type] = subState
    this.setState(newState)
  }

  save() {
    updateDescription({'element': this.state.type, 'code_description': this.state[this.state.type]['code_description'], 'user_description': this.state[this.state.type]['user_description']}).then(result => {
      if (result?.success) {
        this.returnToModel()
      } else {
        FTNotice(i18next.t('aiInfrastructure.couldNotUpdate', {error: result?.message}), 15000)
      }
    })
  }

  returnToModel() {
    window.location.href = `/#/ai_infrastructure/`
  }

  getHeader(attribute) {
    if (!(attribute in locale.metrics)) {
      attribute = attribute.charAt(0).toUpperCase() + attribute.slice(1)
      return attribute.replace(/_/g, " ")
    } else {
      return locale.metrics[attribute]
    }
  }
  
  getSampleColumns() {
    const rows = this.state.sampleData
    const columns = []
    if (rows.length > 0) {
      Object.keys(rows[0]).forEach(columnName => {
        const column = {
          id: columnName,
          header: this.getHeader(columnName),
          datatype: determineType(rows, columnName),
        }
        columns.push(column)
      })
    }
    return columns
  }

  render() {
    return (
      <div>
        <Form
          objectType={this.state.type + ' ' + i18next.t('aiInfrastructure.engineering')}
          updateObject={() => this.save()}
          canEdit={!this.state.hideEngineering}
          canDelete={false}
          parentLink={`/ai_infrastructure/`}>
          <Field label="aiInfrastructure.element">
            <Box sx={{marginLeft: 1.5}}>
              {this.state.type}
            </Box>
          </Field>
          <Field label="aiInfrastructure.description">
            <Box sx={{marginLeft: 1.5}}>
              {this.state.currentText.user_description?.split('\n').map((description, i) => (
                <div key={i}>{description}</div>
              ))} 
            </Box>
          </Field>
          {!this.state.hideEngineering && (
            <>
              <Field label="aiInfrastructure.history">
                <select value={this.state.currentHistory}
                  onChange={event => this.setState({ currentHistory: event.target.value })}
                  className="form-control" id="schedule-dag" >
                  {this.state.currentTextHistory.map((history, i) => (
                    <option key={i} value={history.code_description}>{history.code_description}</option>
                  ))}
                </select>
                {this.state.currentHistory != '' && (
                  <Button
                    buttonText="aiInfrastructure.revert"
                    onClick={() => this.updateEngineering(this.state.currentHistory)}
                    sx={{marginTop: 1}}
                  />
                )}
              </Field>
              <Field label="aiInfrastructure.engineering">
                <TextField
                  fullWidth
                  multiline
                  value={this.state.currentText['code_description']}
                  onChange={event => this.updateEngineering(event.target.value)}
                  variant="outlined"
                />
              </Field>
            </>
          )}
          {this.state.sampleData && (
            <>
              <Field label="aiInfrastructure.view">
                <Box sx={{marginLeft: 1.5}}>
                  {this.state.view}
                </Box>
              </Field>
              <Field label="aiInfrastructure.sampleData">
                <Table
                  columns={() => this.getSampleColumns()}
                  dataset={this.state.sampleData}
                  hidePagination={true}
                  height={200}
                />
              </Field>
            </>
          )}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user.client.internal_client_id
  }
}

export default withRouter(connect(mapStateToProps)(AiPrompt))

