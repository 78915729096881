import request from './request'
import querystring from 'querystring'

// admin only
export function getClients(table_data) {
  const url = '/api/clients?' + querystring.stringify(table_data)
  return request(url)
}

// all users
export function getClient(clientId) {
  return request(`/api/clients/${clientId}`);
}

// admin only
export function updateClient(data) {
  return request(`/api/clients/${data.internal_client_id}`, {}, 'PUT', data);
}

// admin only except: budget_sql (budget_admin) and use_engine_revenue (feeds or revenue_pipeline)
export function patchClient(data) {
  return request(`/api/clients/${data.internal_client_id}`, {}, 'PATCH', data);
}

// admin only
export function createClient(data) {
  return request('/api/clients', {}, 'POST', data);
}

// all users
export function selectClient(internal_client_id) {
  return request(`/api/clients/${internal_client_id}/select`, {}, 'PATCH');
}

// all users
export function getPermissions(internal_client_id) {
  return request(`/api/clients/${internal_client_id}/permissions`)
}

// admin only
export function updatePermissions(internal_client_id, permissions) {
  return request(`/api/clients/${internal_client_id}/permissions`, {}, 'POST', {permissions: permissions})
}
