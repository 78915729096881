import React, { Component } from "react"
import { Bar } from "react-chartjs-2"
import { Chart, BarElement, Tooltip, Title } from 'chart.js'
import "../../assets/stylesheets/charts.css"
import { COLORS } from "./util"
import { metrics } from "../../api/locale.js"
import { dataFormatter } from "../../utilities/formatting"

Chart.register(BarElement, Tooltip, Title)

/**
 * A bar chart component created with chart.js.
 * Source: https://www.chartjs.org   React Wrapper: https://github.com/reactchartjs/react-chartjs-2
 *
 * Properties (labels and datasets should have the same number of elements):
 *  labels        - An array of names of datasets. E.g. ['Desktop', 'Mobile', ...]
 *  datasets      - An array of data for the metrics (label is optional). E.g [{metric: 'Clicks', data: [2031, 3324, ...]}, ...]]
 *  dataset_labels- An array of axis labels for the data (optional)
 *  type          - (optional) column or bar (defaults to column)
 *  stacked       - (optional) whether to stack bars are not (defaults to false)
 *  title         - (optional) Chart title.
 *  colorPerValue - (optional) whether to show a different color for each value (defaults to false)
 *  animation     - (optional) Whether to animate on drawing.  Default to true.
 */

export default class BarChart extends Component {
  static defaultProps = {
    animation: true,
    type: 'column',
    stacked: false,
    colorPerValue: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      data: null,
      options: null,
    }
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  generateDatasets() {
    const datasets = []
    let i = 0
    this.props.datasets.forEach((dataset, i) => {
      let backgroundColor = null
      if (this.props.colorPerValue) {
        backgroundColor = []
        dataset.data.forEach(() => {
          backgroundColor.push(COLORS[i])
          i++
        })
      } else {
        backgroundColor = COLORS[i]
      }
      const label1 = this.props.dataset_labels != null ? this.props.dataset_labels[0] : null
      const label = this.props.dataset_labels != null ? this.props.dataset_labels[i] : null
      datasets.push({
        type: dataset.type,
        label: metrics[dataset.metric] ? metrics[dataset.metric] : dataset.metric,
        metric: dataset.metric,
        data: dataset.data,
        backgroundColor: backgroundColor,
        xAxisID: this.props.type == 'bar' ? (label == label1 ? 'x' : 'x2') : 'x',
        yAxisID: this.props.type == 'bar' ? 'y' : (label == label1 ? 'y' : 'y2')
      })
      if (!this.props.colorPerValue) {
        i++
      }
    })
    return datasets
  }
  
  getTicks(_this) {
    return {
      callback: function (value) {
        value = value.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })

        var formatter = _this.state.data && _this.state.data.datasets.length == 1 ? dataFormatter(_this.state.data.datasets[0].metric) : null
        if (formatter != null) {
          return formatter(value)
        } else {
          return value
        }
      }
    }
  }

  updateChart() {
    const _this = this
    const datasets = this.generateDatasets()
    const animation = this.props.animation ? {} : { duration: 0 }
    const options = {
      animation: animation,
      maintainAspectRatio: false,
      indexAxis: this.props.type == 'bar' ? 'y' : 'x',
      responsive: true,
      plugins: {
        title: {
          display: this.props.title,
          text: this.props.title,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const metric = context.dataset.metric || ""
              let label = context.dataset.label || ""
              const formatter = dataFormatter(metric)
              if (label) {
                label += ": " + formatter(context.formattedValue)
              }
              return label
            },
          },
        },
        legend: {
          position: "bottom",
          display: datasets.length > 1,
        }
      },
      scales: {}
    }
    const label1 = this.props.dataset_labels != null ? this.props.dataset_labels[0] : null
    const label2 = this.props.dataset_labels != null ? this.props.dataset_labels.find(label => label != label1) : null
    options.scales[this.props.type == 'bar' ? 'x' : 'y'] = {
      stacked: this.props.stacked,
      ticks: this.getTicks(_this),
      title: {
        display: label1,
        text: label1,
      },
    }
    options.scales[this.props.type == 'bar' ? 'x2' : 'y2'] = {
      display: "auto",
      stacked: this.props.stacked,
      ticks: this.getTicks(_this),
      position: this.props.type == 'bar' ? "top" : "right",
      title: {
        display: label2,
        text: label2,
      },
    }
    options.scales[this.props.type == 'bar' ? 'y' : 'x'] = {
      beginAtZero: true,
      stacked: this.props.stacked
    }
    this.setState({
      data: {
        labels: this.props.labels,
        datasets: datasets,
      },
      options: options
    })
  }

  render() {
    if (this.state.data) {
      return (
        <Bar
          key={JSON.stringify(this.props)}
          data={this.state.data}
          options={this.state.options}
          height={this.props.height}
          width={this.props.width}
        />
      )
    } else {
      return null
    }
  }
}
