import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import {getSQLTemplate, createSQLTemplate, updateSQLTemplate, deleteSQLTemplate} from  "../../api/sql_templates"
import FTNotice from "../FTNotice"
import Form from '../Form'
import Field from '../Field'
import SQLEditor from '../SQLEditor'

class AddEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id == 'new' ? 0 : this.props.match.params.id,
      name: '',
      sql: '',
      updateButtonClicked: false
    }
  }

  componentDidMount() {
    if(this.state.id)
      this.getSQLTemplate()
  }

  getSQLTemplate() {
    getSQLTemplate(this.state.id).then((response) => {
      this.setState({name: response.name, sql: response.sql})
    })
  }

  saveSQLTemplate() {
    if (this.state.name.trim() == '') {
      FTNotice('Please enter a name', 3000)
    } else {
      this.setState({updateButtonClicked: true})
      let data = {
        name: this.state.name,
        sql: this.state.sql
      }
      const api_call = this.state.id ? updateSQLTemplate(this.state.id, data) : createSQLTemplate(data)
      api_call
        .then(response => {
          if (response.success) {
            this.props.history('/sql_templates')
          } else {
            FTNotice(response.data, 3000)
          }
        })
        .catch(error => FTNotice(error, 3000))
        .finally(() => this.setState({updateButtonClicked: false}))
    }
  }

  deleteSQLTemplate() {
    deleteSQLTemplate(this.state.id)
      .then(response => {
        if (response.success) {
           this.props.history('/sql_templates')
        } else {
          FTNotice(response.error, 3000)
        }
      })
  }

  render() {
    return (
      <Form
        objectType="Template"
        objectName={this.state.name}
        newObject={!this.state.id}
        updateObject={() => this.saveSQLTemplate}
        deleteObject={() => this.deleteSQLTemplate}
        parentLink={'/sql_templates'}
        buttonDisabled={this.state.updateButtonClicked}
      >
        <Field label="Name">
          <input type="text"
            value={this.state.name}
            onChange={() => this.setState({name: event.target.value})}
            className="form-control" />
        </Field>
        <Field label="SQL">
          <SQLEditor
            code={this.state.sql}
            onChange={(sql) => this.setState({sql: sql})}
          />
        </Field>
      </Form>
    )
  }
}

export default withRouter(AddEdit)