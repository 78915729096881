import React from 'react'
import { Box } from '@mui/material'
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'
import { validateSQLBeforeSave } from '../../utilities/utils'
import { getColumns } from '../../api/database'
import Section from '../widgets/Section'

const SqlInputs = (props) => {
  const validate = (sql) => {
    return validateSQLBeforeSave(sql, 'redshift', null, true).then(error => {
      if (!error) {
        const desired_columns = ['ad_group_id', 'criteria_id']
        return getColumns({ 'sql': sql }).then(response => {
          if (!response.data || !_.isEqual(response.data, desired_columns)) {
            return i18next.t('abtest.edit.queryMustReturn', { columns: desired_columns.join(', ') })
          }
        })
      } else {
        return error
      }
    })
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <SQLEditor
        instructions={i18next.t('abtest.edit.instructions')}
        code={props.sql}
        validate={validate}
        onChange={(sql) => props.onUpdate(sql)}
      />
    </Box>
  )
}

const Sql = (props) => {
  return (
    <Section name={i18next.t(props.name)}>
      {<SqlInputs {...props} />}
    </Section>
  )
}

export default Sql