import Switch from '../widgets/Switch'
import i18next from 'i18next'

const HasControl = (props) => {
  return (
    <Switch
      checked={props.hasControl}
      onChange={() => props.setHasControl(!props.hasControl)}
      label={i18next.t('abtest.edit.experimentHasControl')} />
  )
}

export default HasControl