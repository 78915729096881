import { useState } from 'react'
import { Box, Paper } from '@mui/material'
import Tab from '../../widgets/Tab'
import Tabs from '../../widgets/Tabs'
import ProgressDialog from '../../widgets/ProgressDialog'
import BidSummary from '../bidding_run/BidSummary'
import ConfirmRevert from './ConfirmRevert'

const FullScreenDialogContent = (props) => {
  // state for which tab is active
  const [tab, setTab] = useState(0)

  // state for the confirm revert dialog
  const [confirmRevertOpen, setConfirmRevertOpen] = useState(false)

  // unique state for when a user clicks revert from BiddingSuccessDialog
  React.useEffect(() => {
    setTab(props.revertPathChosen)
  }, [props.revertPathChosen])

  // controls revert tab focus 
  React.useEffect(() => {
    if (props.isRevert && props.status != 'queued' && props.status != 'published') {
      setTab(2)
      setConfirmRevertOpen(true)
    }
  }, [props.reverting, props.isRevert])

  // used to trigger progress circle immediately
  const [publishingProgressCircle, setPublishingProgressCircle] = React.useState(false)
  const [generatingRevertProgressCircle, setGeneratingRevertProgressCircle] = React.useState(false)
  React.useEffect(() => {
    if (publishingProgressCircle) {
      setPublishingProgressCircle(false)
    }
    if (generatingRevertProgressCircle) {
      setGeneratingRevertProgressCircle(false)
    }
  }, [props.status])

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      width: "97vw",
      alignSelf: 'center'
    }}>
      <ProgressDialog open={props.started} text="biddingRun.generatingBids" />
      <ProgressDialog open={publishingProgressCircle} text="biddingRun.publishingBids" />
      <ProgressDialog open={generatingRevertProgressCircle} text="biddingRun.generatingRevert" />
      <ConfirmRevert open={confirmRevertOpen} setConfirmRevertOpen={setConfirmRevertOpen} />
      {props.bidding_run_id > -1 && !props.started && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 1 }}>
          <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)} centered>
            <Tab disabled={(props.isRevert && props.status != 'published' && props.status != 'queued') || (props.revertPathChosen == 2)} label="biddingRun.summary" value={0} />
            <Tab disabled={!props.isRevert && props.status != 'queued'} label="biddingRun.revert" value={2} />
          </Tabs>
          <Paper elevation={3} sx={{
            width: '97vw',
          }}>
            <Box margin="auto" padding={5}>
              {(tab == 0 || tab == 2) && (
                <BidSummary
                  publish={props.publish}
                  setPublishingProgressCircle={setPublishingProgressCircle}
                  setGeneratingRevertProgressCircle={setGeneratingRevertProgressCircle}
                  isAdmin={props.isAdmin}
                  bidding_run_id={props.bidding_run_id}
                  isDraft={props.isDraft}
                  onUpdate={props.onUpdate}
                  setBidSummaryOpen={props.setBidSummaryOpen}
                  lookbackDuration={props.lookbackDuration}
                  props={props}
                />
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default FullScreenDialogContent