const styles = {
  node: {
    padding: 10,
    marginTop: 25,
    marginBottom: 25,
    height: 60,
    width: 100,
    backgroundColor: 'white',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  container: {
    width: 60,
    textAlign: 'center',
    cursor: 'auto'
  },
  image: {
    height: 50
  },
}
  
export default styles
