import { Component } from 'react'
import {
  getChartAnnotations,
  addEditChartAnnotations,
} from '../../api/dashboards'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import FTNotice from '../FTNotice'
import Dialog from '../widgets/Dialog'
import Table from '../widgets/Table'
import Button from '../widgets/Button'
import i18next from 'i18next'

/**
 * A dialog component to show, add, update and delete chart annotations
 * 
 * Properties:
 *   open        - true to open the dialog, false to close the dialog.
 *   location    - the location of the annotations. Current options are 'dashboard', 'homepage' and 'dimension'.
 *   location_id - the id of corresponding location. 
                   'dashboard' -> dashboard_id, 
                   'homepage'   -> internal_client_id, 
                   'dimension' -> internal_client_id.
 * updateCallBack - a function that will be executed after OK button is clicked and annotations are updated successfully.
 * closeCallBack - a function that will be executed after cancel button is clicked.
 */

export default class AddEditAnnotations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      annotations: null,
    }
  }

  componentDidMount() {
    this.getChartAnnotations()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open != this.props.open) this.getChartAnnotations()
  }

  getChartAnnotations() {
    this.setState({ annotations: null })
    getChartAnnotations(this.props.location, this.props.location_id).then(
      (response) => {
        this.setState({ annotations: response.results })
      }
    )
  }

  getColumns() {
    return [
      {
        id: 'x_value',
        header: i18next.t('annotations.xAxisValue'),
        width: 130,
        cellRenderer: (value, row) => {
          return this.renderCell('x', value, row)
        },
      },
      {
        id: 'text',
        header: i18next.t('annotations.label'),
        width: 300,
        cellRenderer: (value, row) => {
          return this.renderCell('text', value, row)
        },
      },
      {
        id: 'delete',
        width: 120,
        cellRenderer: (_, row) => {
          return this.renderDeleteAnnotation(row.index)
        },
      },
    ]
  }

  renderDeleteAnnotation(index) {
    return (
      <Button buttonText="annotations.delete" onClick={() => this.deleteAnnotation(index)} color="warning" />
    )
  }

  renderCell(type, value, row) {
    const styles = {
      textField: {
        width: 85,
        height: 30,
        '& input': {
          padding: '5px 6px 5px 5px',
          fontSize: 12,
        }
      },
    }
    return (
      <div>
        {type == "x" ? (
          <TextField
            id="date"
            type="date"
            defaultValue={value}
            sx={styles.textField}
            onChange={(event) =>
              this.updateCellValue(type, row, event.target.value)
            }
          />
        ) : (
          <input
            type="text"
            defaultValue={value}
            style={{width: 275}}
            onChange={(event) =>
              this.updateCellValue(type, row, event.target.value)
            }
          />
        )}
      </div>
    )
  }

  updateCellValue(type, row, value) {
    if (type == "x") row.x_value = value
    else if (type == "text") row.text = value
  }

  deleteAnnotation(index) {
    var annotations = [...this.state.annotations]
    annotations.splice(index, 1)
    this.setState({ annotations: annotations })
  }

  addAnnotationRow() {
    var index = this.state.annotations.length
    var annotations = this.state.annotations.concat({
      index: index,
      location: this.props.location,
      location_id: this.props.location_id,
      x_value: null,
      text: null,
    })
    this.setState({ annotations: annotations })
  }

  updateAnnotations() {
    addEditChartAnnotations(
      this.state.annotations,
      this.props.location,
      this.props.location_id
    ).then((response) => {
      if (response.success) {
        this.props.closeCallBack()
        this.props.updateCallBack()
      } else {
        FTNotice(i18next.t('annotations.error', {error: response.error}), 15000)
      }
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="sm"
        title="annotations.chartAnnotations"
        buttons={[
          <Button buttonText="annotations.ok" onClick={() => this.updateAnnotations()} />,
          <Button buttonText="annotations.cancel" onClick={() => this.props.closeCallBack()} />
        ]}
      >
        {this.state.annotations ? (
          <Table
            hideExport
            hidePagination
            dataset={this.state.annotations}
            columns={this.getColumns()}
            height={280}
            rowHeight={45}
            changeParams={this.state.annotations}
          />
        ) : (
          <span>
            <CircularProgress
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <br />
          </span>
        )}
        <Button buttonText="annotations.addNewAnnotation" onClick={() => this.addAnnotationRow()} />
      </Dialog>
    )
  }
}
