import { Component } from "react"
import withRouter from '../../wrappers/withRouter'
import * as qs from 'query-string'
import Form from "../Form"
import Field from "../Field"
import FTNotice from "../FTNotice"
import { getIntegrations } from "../../api/integrations"
import { getImage } from "../../utilities/clients"
import {
  getAccount,
  createAccount,
  updateAccount,
  deleteAccount,
} from "../../api/client_accounts"

class Account extends Component {
  constructor(props) {
    super(props)
    const url_params = qs.parse(this.props.location.search)
    this.state = {
      name: "",
      publisher_name: url_params.integration ? url_params.integration : "",
      manager_account_id: "",
      pub_customer_id: "",
      pub_account_id: "",
      status: true,
      instructions: url_params.instructions
    }
  }

  componentDidMount() {
    if (this.props.match.params.account_id != undefined) {
      this.getData()
    } else {
      this.getIntegration()
    }
  }

  getData() {
    getAccount(
      this.props.match.params.internal_client_id,
      this.props.match.params.account_id
    ).then((results) => {
      this.setState(results, () => this.getIntegration())
    })
  }

  getIntegration() {
    getIntegrations(this.props.match.params.internal_client_id, this.state.publisher_name).then(integration => {
      this.setState({integration: integration})
    })
  }

  updateAccount() {
    if (this.state.name.trim() == "") {
      FTNotice("Please enter a value for name", 3000)
    } else if (this.state.integration.account_fields['pub_customer_id'] && this.state.pub_customer_id == "") {
      FTNotice("Please enter a value for " + this.state.integration.account_fields['pub_customer_id'], 3000)
    } else if (this.state.integration.account_fields['pub_account_id'] && this.state.pub_account_id == "") {
      FTNotice("Please enter a value for " + this.state.integration.account_fields['pub_account_id'], 3000)
    } else {
      const formData = Object.assign({}, this.state)
      if (this.state.publisher_name == 'google') {
        formData.pub_customer_id = formData.pub_account_id
      }
      formData.customer_id = formData.pub_account_id.replace(/[^0-9]/g, "")
      if (formData.customer_id == "") {
        formData.customer_id = "0"
      }
      if (formData.manager_account_id == "") {
        formData.manager_account_id = null
      }
      formData.customer_name = this.state.name
      if (this.state.id == undefined) {
        createAccount(this.props.match.params.internal_client_id, formData).then(
          (response) => {
            this.updateResults(response)
          }
        )
      } else {
        updateAccount(
          this.props.match.params.internal_client_id,
          this.state.id,
          formData
        ).then((response) => {
          this.updateResults(response)
        })
      }
    }
  }

  updateResults(response) {
    if (response.result === "success") {
      window.location.href =
        "/#/integrations"
    } else {
      const error = response.error
      delete response.result
      delete response.error
      this.setState(response)
      FTNotice(
        error ? error : "The credentials provided are not valid. The account is currently disabled.",
        6000
      )
    }
  }

  deleteAccount() {
    deleteAccount(this.props.match.params.internal_client_id, this.state.id).then(
      (response) => {
        if (response.success) {
          window.location.href =
            "/#/integrations"
        } else {
          FTNotice("The account couldn't be deleted.", 6000)
        }
      }
    )
  }

  render() {
    if (this.state.integration) {
      const image = getImage(this.state.publisher_name)
      return (
        <Form
          objectType="Account"
          objectName={this.state.name}
          newObject={this.state.id == undefined}
          updateObject={() => this.updateAccount()}
          deleteObject={() => this.deleteAccount()}
          parentLink={"/integrations"}
        >
          {this.state.instructions != null && (
            <Field><div style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: this.state.instructions}} /></Field>
          )}
          {image != null && (
            <Field>
              <img
                src={"/resources/images/workflow_icons/" + image}
                height="90"
                width="90"
              />
            </Field>
          )}
          <Field label="Status">
            <div className='js_complete'>
              <input
                type="checkbox"
                checked={this.state.status}
                onChange={() => {
                  this.setState({ status: event.target.checked })
                }}
              />{" "}
              Active
            </div>
          </Field>
          <Field label="Name">
            <input
              type="text"
              className="form-control"
              value={this.state.name}
              onChange={() => {
                this.setState({ name: event.target.value })
              }}
            />
          </Field>
          {this.state.integration.account_fields['manager_account_id'] && (
            <Field
              label={<span dangerouslySetInnerHTML={{__html: this.state.integration.account_fields['manager_account_id']}}/>}
            >
              <input
                type="text"
                className="form-control"
                value={this.state.manager_account_id}
                onChange={() => {
                  this.setState({ manager_account_id: event.target.value })
                }}
              />
            </Field>
          )}
          {this.state.integration.account_fields['pub_customer_id'] && (
            <Field
              label={<span dangerouslySetInnerHTML={{__html: this.state.integration.account_fields['pub_customer_id']}}/>}
            >
              <input
                type="text"
                className="form-control"
                value={this.state.pub_customer_id}
                onChange={() => {
                  this.setState({ pub_customer_id: event.target.value })
                }}
              />
            </Field>
          )}
          {this.state.integration.account_fields['pub_account_id'] && (
            <Field
              label={<span dangerouslySetInnerHTML={{__html: this.state.integration.account_fields['pub_account_id']}}/>}
            >
              <input
                type="text"
                className="form-control"
                value={this.state.pub_account_id}
                onChange={() => {
                  this.setState({ pub_account_id: event.target.value })
                }}
              />
            </Field>
          )}
        </Form>
      )
    } else {
      return null
    }
  }
}

export default withRouter(Account)