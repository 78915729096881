import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const history = useNavigate()
    const match = {params: useParams()}
    return (
      <Component
        {...props}
        match={match}
        location={location}
        history={history}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter