import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notifications: null,
}

export const alerts = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    saveNotifications: (state, { payload }) => {
      state.notifications = payload
    },
  }
})

export const { saveNotifications } = alerts.actions
export default alerts.reducer
