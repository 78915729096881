import React, { useRef } from 'react'
import Table from '../../widgets/Table'
import DeleteButton from '../../DeleteButton'
import { getWorkflowSchedules, deleteWorkflowSchedule } from '../../../api/workflows'
import { formatSchedule } from '../../../utilities/formatting'
import i18next from 'i18next'

const EditSchedule = ({workflow_id}) => {
  const tableRef = useRef()
  
  const formatOverrides = overrides => {
    const newOverrides = []
    Object.keys(overrides).forEach(key => {
      const value = overrides[key]
      newOverrides.push({key, value})
    })
    return (
      <ul style={{marginBottom: 0}}>
        {newOverrides.toSorted((a, b) => a.key.localeCompare(b.key)).map((o, i) => (
          <li key={i}>{o.key}={o.value}</li>
        ))}
      </ul>
    )
  }
  
  const editWorkflowSchedule = row => {
    window.location.href = `/#/workflows/notebook/${row.workflow_id}/run/${row.runid}/-1`
  }
  
  const columns = [{
    header: 'workflow.schedule',
    datatype: 'text',
    format: (_, row) => row ? formatSchedule(row.schedule) : null,
    width: 300
  },{
    header: 'workflow.overrides',
    datatype: 'text',
    alignment: 'left',
    cellRenderer: (_, row) => row ? formatOverrides(row.overrides) : null,
    width: 300
  }, {
    cellRenderer: (_, row) => (
      <button name="edit" className="btn btn-primary" onClick={() => editWorkflowSchedule(row)}>
        {i18next.t('workflow.editSchedule')}
      </button>
    ),
    width:150,
  }, {
    cellRenderer: (_, row) => (
      <DeleteButton
        objectType="workflow.schedule"
        objectName={i18next.t('workflow.thisSchedule')}
        deleteObject={() => deleteWorkflowSchedule(row.id).then(() => {})}/>
    ),
    width:150,
  }]

  return(
    <Table
      ref={tableRef}
      fetch={tableData => getWorkflowSchedules(workflow_id, null, tableData).then(result => {
        if (tableRef.current) {
          setTimeout(() => {
            tableRef.current.resizeColumns()
          }, 500)
        }
        return result
      })}
      columns={columns}
    />
  )
}

export default EditSchedule