import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import Form from '../Form'
import Field from '../Field'
import FTNotice from '../FTNotice'
import { getLink, updateLink, createLink, deleteLink } from '../../api/links.js'

class Link extends Component {
  constructor(props) {
    super(props)
    this.state = {
       deleteModal: false,
       formData: {
         name: '',
         href: '',
         internal_client_id: props.currentClientId,
       }
    }
    if(this.props.match.params.id !== 'new') {
      getLink(this.props.match.params.id).then((link) => {
        this.setState({formData: link})
      })
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.deleteLink = this.deleteLink.bind(this)
  }

  handleSubmit() {
    if (this.state.formData.name.trim() == '') {
      FTNotice('Please enter a name', 3000)
    } else if (this.state.formData.href.trim() == '') {
      FTNotice('Please enter a URL', 3000)
    } else {
      if (this.props.match.params.id !== 'new') {
        updateLink(this.props.match.params.id, this.state.formData).then((result) => {
          if (result.name) {
            window.location.href = '/#/links'
          } else {
            FTNotice('The link could not be created', 3000)
          }
        })
      } else {
        createLink(this.state.formData).then((result) => {
          if (result.name) {
            window.location.href = '/#/links'
          } else {
            FTNotice('The link could not be created', 3000)
          }
        })
      }
    }
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.formData)
    const { name, value } = event.target
    formData[name] = value
    this.setState({formData})
  }

  deleteLink() {
    deleteLink(this.props.match.params.id).then((result) => {
      window.location.href = '/#/links'
    }).catch((result) => {
      FTNotice('The link could not be deleted')
    })
  }

  render() {
    const formData = this.state.formData
    const canEdit = this.props.isAdmin || formData.internal_client_id != ''
    return (
      <Form
        objectType="Link"
        objectName={formData.name}
        canEdit={canEdit}
        canDelete={canEdit}
        newObject={this.props.match.params.id == 'new'}
        updateObject={this.handleSubmit}
        deleteObject={this.deleteLink}
        parentLink={'/links'}
      >
        <Field label="Name">
          { canEdit && (
            <input className="form-control" type="text" value={formData.name} name="name" onChange={this.handleChange} />
          )}
          { !canEdit && (
            <span>{formData.name}</span>
          )}
        </Field>
        <Field label="URL">
          { canEdit && (
            <input className="form-control" value={formData.href} name="href" onChange={this.handleChange}/>
          )}
          { !canEdit && (
            <span>{formData.href}</span>
          )}
        </Field>
        <Field label="Client">
          { this.props.isAdmin && (
            <select className="form-control" value={formData.internal_client_id || ''} name='internal_client_id'
                    onChange={this.handleChange}>
              <option value=''>All Clients</option>
              {
                this.props.clients.map(client => {
                  return (
                    <option key={client.internal_client_id} value={client.internal_client_id}>
                      {client.name}
                    </option>
                  )
                })
              }
            </select>
          )}
          { !this.props.isAdmin && (
            <span>{formData.internal_client_id == '' ? 'All Clients' : this.props.currentClientName}</span>
          )}
        </Field>
      </Form>
    )
  }
}

const mapStateToProps = (state, own) => ({
  clients: state.users.user ? state.users.user.clients : [],
  currentClientId: state.users.user ? state.users.user.client.internal_client_id : null,
  currentClientName: state.users.user ? state.users.user.client.name : null,
  isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
})

export default withRouter(connect(mapStateToProps)(Link))
