import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { getBidAlgo, saveBidAlgo, deleteBidAlgo } from "../../api/bidding"
import Form from '../Form'
import Field from '../Field'
import FTNotice from '../FTNotice'
import SQLEditor from '../SQLEditor'
import queryString from 'query-string'
import i18next from 'i18next'

class AddEditAlgo extends Component {
  PRELIM_BIDS = 'prelim_bids'
  BIDS = 'bids'

  constructor(props) {
    super(props)
    const queries = queryString.parse(props.location.search)
    this.state = {
      id: -1,
      name: '',
      description: '',
      internal_client_id: props.isAdmin ? '' : props.internal_client_id,
      admin_only: false,
      algo: '',
      loading: false,
      referer: queries.referer ? queries.referer : '/bidding/algos'
    }
    let id = this.props.match.params.id
    if (id) {
      this.state.loading = true
      this.getData(id)
    }
    this.saveData = this.saveData.bind(this)
    this.deleteAlgo = this.deleteAlgo.bind(this)
  }

  getData(id) {
    getBidAlgo(id)
    .then(response => {
      if (response.success) {
        const data = response.data
        this.setState({
          id: data.id,
          name: data.name,
          description: data.description,
          internal_client_id: data.internal_client_id == null ? '' : (data.internal_client_id + ''),
          algo: data.algo,
          admin_only: data.admin_only,
          loading: false,
        })             
      } else {
        FTNotice('Error: '+ response.data, 3000)
      }
    })
  }

  saveData() {
    let data = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      internal_client_id: this.state.internal_client_id == '' ? null : parseInt(this.state.internal_client_id),
      algo: this.state.algo,
      admin_only: this.state.admin_only,
    }
    if (data.name.trim() == '') {
      FTNotice('Please enter a name', 3000)
    } else if (data.description.trim() == '') {
      FTNotice('Please enter a description', 3000)
    } else if (data.algo.trim() == '') {
      FTNotice('Please enter an algorithm', 3000)
    } else {
      saveBidAlgo(data)
      .then(response => {
        if (!response.success) {
          FTNotice('Save failed: ' + response.error, 3000)
        } else {
          this.props.history(this.state.referer)
        }
      })
    }
  }

  deleteAlgo() {
    deleteBidAlgo(this.state.id).then(response => {
      if (response && response.success) {
        this.props.history(this.state.referer)
      } else {
        FTNotice(response && response.error ? response.error : i18next.t('algos.couldNotDelete'), 20000)
      }
    })
  }

  substituteVariables(sql) {
    sql = sql.replace(/(\/\*[^*]*\*\/)/g, '')
    sql = sql.replace(/\{internal_client_id\}/g, 1)    
    sql = sql.replace(/\{bidding_run_id\}/g, 1)
    sql = sql.replace(/\{roas\}/g, 1)
    return sql
  }

  render() {
    if (!this.state.loading) {
      return (
        <Form
          objectType="algo.algorithm"
          objectName={this.state.name}
          newObject={this.state.id == -1}
          updateObject={this.saveData}
          deleteObject={this.deleteAlgo}
          parentLink={this.state.referer}
        >
          <Field label="algo.name">
            <input type="text"
              value={this.state.name}
              onChange={event => this.setState({name:event.target.value})}
              className="form-control" />
          </Field>
          <Field label="algo.description">
            <textarea
              rows="5"
              value={this.state.description}
              onChange={event => this.setState({description:event.target.value})}
              className="form-control" />
          </Field>
          {this.props.isAdmin && (
            <Field label="algo.client">
              <select
                className="form-control"
                value={this.state.internal_client_id}
                onChange={event => this.setState({internal_client_id: event.target.value})}
              >
                {this.props.clients.map((client) => {
                  return (
                    <option
                      key={client.internal_client_id}
                      value={client.internal_client_id}
                    >
                      {client.name}
                    </option>
                  )
                })}
                <option value={""}>{i18next.t('algo.all')}</option>
              </select>
            </Field>
          )}
          <Field label="algo.status">
            <div>
              <input
                type="checkbox"
                checked={this.state.admin_only}
                onChange={event => this.setState({admin_only: event.target.checked})}
              />{" "}
              {i18next.t('algo.adminOnly')}
            </div>
          </Field>
          <Field label="algo.algorithm">
            <SQLEditor
              code={this.state.algo}
              instructions={'The following variables are available: {internal_client_id}, {bidding_run_id}, {roas}.'}
              substitute={(sql) => this.substituteVariables(sql)}
              onChange={(sql) => this.setState({algo: sql})}
              width="100%"
            />
          </Field>
        </Form>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function(state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default withRouter(connect(mapStateToProps)(AddEditAlgo))
