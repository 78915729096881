import _ from 'lodash'
import { Component } from 'react'
import ComparisonChart from './ComparisonChart'
import SelectMetric from './SelectMetric'
import { Box, Typography } from '@mui/material'
import i18next from 'i18next'

export default class ComparisonChartWithSelectors extends Component {
  /**
   * Compare metrics (allowing the user to choose which metrics).
   *
   * Properties:
   *   type - ads (default), metrics or analytics
   *   hideUnusedMetrics - whether to default to a metric if not provided (default = false)
   *   fromDate1 - the from date of the time range (ex., '2022-10-01')
   *   toDate1 - the to date of the time range (ex., '2022-10-01')
   *   summary1 - summary data for date range
   *   dataset1 - detailed (daily) data for date range
   *   fromDate2 - (optional) the from date of the second time range (ex., '2022-10-01')
   *   toDate2 - (optional) the to date of the second time range (ex., '2022-10-01')
   *   summary2 - (optional) summary data for second date range (if comparing)
   *   dataset2 - (optional) detailed (daily)) data for second date range (if comparing)
   *   granularity - (optional) granularity (see ComparisonChart; DAILY, WEEKLY or MONTHLY; defaults to DAILY)
   *   annotations - (optional) annotations to show on chart
   *   annotationType - (optional) type of annotation (ex., 'homepage')
   *   updateAnnotationsCallback - (optional) callback when annotations have changed
   *   engine1 - (optional) the default engine to show in the first selector (defaults to all)
   *   metric1 - (optional) the defualt metric to show in the first selector (defaults to clicks)
   *   engine2 - (optional) the default engine to show in the second selector (defaults to all)
   *   metric2 - (optional) the defualt metric to show in the second selector (defaults to impressions)
   *   callback - (optional) callBack method (will receive dictionary with engine and metric)
   *   maxHeight - (optional) the max height of the pull downs.
   **/
  static defaultProps = {
    type: 'ads',
    engine1: 'all',
    engine2: 'all',
    hideUnusedMetrics: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      engine1: this.props.engine1,
      metric1: this.props.metric1 ? this.props.metric1 : (this.props.hideUnusedMetrics ? null : 'clicks'),
      engine2: this.props.engine2,
      metric2: this.props.metric2 ? this.props.metric2 : (this.props.hideUnusedMetrics ? null : 'impressions'),
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.metric1 != prevProps.metric1 || this.props.metric2 != prevProps.metric2) {
      this.setState({ metric1: this.props.metric1, metric2: this.props.metric2 })
    }
  }

  callback() {
    if (this.props.callback) {
      this.props.callback({ engine1: this.state.engine1, metric1: this.state.metric1, engine2: this.state.engine2, metric2: this.state.metric2 })
    }
  }

  render() {
    return (
      <div>
        <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <SelectMetric
            type={this.props.type}
            aiMetricSelectChart={this.props.aiMetricSelectChart}
            engine={this.state.engine1}
            metric={this.state.metric1}
            maxHeight={this.props.maxHeight}
            callback={values => this.setState({ engine1: values.engine, metric1: values.metric }, () => this.callback())}
          />
          {this.state.metric2 && (
            <>
              <Typography variant='h6' sx={{ mx: 2, marginLeft: '5px', marginRight: '5px', color: this.props.aiMetricSelectChart ? 'white.100' : null }}>{i18next.t('comparisonChart.vs')}</Typography>
              <SelectMetric
                type={this.props.type}
                aiMetricSelectChart={this.props.aiMetricSelectChart}
                engine={this.state.engine2}
                metric={this.state.metric2}
                maxHeight={this.props.maxHeight}
                callback={values => this.setState({ engine2: values.engine, metric2: values.metric }, () => this.callback())}
              />
            </>
          )}
        </Box>
        <ComparisonChart
          type={this.props.type}
          aiMetricSelectChart={this.props.aiMetricSelectChart}
          granularity={this.props.granularity}
          dates1={[this.props.fromDate1, this.props.toDate1]}
          dates2={[this.props.fromDate2 ? this.props.fromDate2 : this.props.fromDate1, this.props.toDate2 ? this.props.toDate2 : this.props.toDate1]}
          summary1={this.props.summary1}
          summary2={this.props.summary2 ? this.props.summary2 : this.props.summary1}
          dataset1={this.props.dataset1}
          dataset2={this.props.dataset2 ? this.props.dataset2 : this.props.dataset1}
          metric1={this.state.metric1}
          metric2={this.state.metric2}
          annotations={this.props.annotations}
          annotationType={this.props.annotationType}
          updateAnnotationsCallback={this.props.updateAnnotationsCallback}
          useAiStyledChart={this.props.useAiStyledChart}
        />
      </div>
    )
  }
}
