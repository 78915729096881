import CodeEditor from './CodeEditor'
import { python } from '@codemirror/lang-python'

// Define the extensions outside the component for the best performance.
const extensions = [python()]

export default class SQLEditor extends CodeEditor {
  constructor(props) {
    super(props)
  }
  
  getExtensions() {
    return super.getExtensions().concat(extensions)
  }
}
