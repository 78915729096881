export const pages = {
  report: 'Report',
  exec_summary: 'Exec Summary',
}

export const metrics = {
  ab_test_table_change_log_id:              'Id',
  ab_test_table_duration:                   'Days',
  ab_test_table_revenue_change:             'Rev Diff',
  ab_test_table_revenue_change_percent:     'Rev Diff %',
  ab_test_table_roas_change:                'Roas Diff',
  ab_test_table_roas_change_percent:        'Roas Diff %',
  ab_test_table_test_created:               'Created',
  ab_test_table_title:                      'Name',
  account_descriptive_name: 'Account',
  accounts:                 'Accounts',
  action:                   'Action',
  ad_group_name:            'Ad Group',
  algorithm:                'Algorithm',
  algo_weight:              'Weight',
  aov:                      'Internal AOV',
  applicable:               'Applicable',
  average_position:         'Avg Position',
  authorized:               'Authorized',
  bid_change:               'Bid Change',
  bid_direction:            'Bid Change',
  bid_run:                  'Bidding Run',
  bing:                     'Bing',
  campaign_name:            'Campaign',
  clicks:                   'Clicks',
  clicks_impact:            'Clicks Impact',
  clicks_pop:               'Clicks Change',
  clicks_to_total:          'Click %',
  client_name:              'Client',
  configured:               'Configured',
  consumers:                'Consumers',
  conv:                     'Internal Conversions',
  conversion:               'Internal Conversions',
  conversions:              'Internal Conversions',
  conversion_rate:          'Internal Conversion Rate',
  cost:                     'Cost',
  cost_impact:              'Cost Impact',
  cost_pop:                 'Cost Change',
  cost_to_total:            'Cost %',
  count:                    'Count',
  cpa:                      'Internal CPA',
  cpc:                      'CPC',
  cpc_pop:                  'CPC Change',
  cr:                       'Internal Conversion Rate',
  crawler_history_table_client:             'Client',
  crawler_history_table_crawl_date:         'Date',
  crawler_history_table_crawl_details:      'Crawl Details',
  crawler_history_table_file:               'Download',
  crawler_history_table_id:                 'ID',
  crawler_history_table_name:               'Crawler Name',
  crawler_history_table_runid:              'Run ID',
  crawler_history_table_status:             'Status',
  crawler_history_table_stop_crawl:         'Stop Crawl',
  crawler_history_table_summary:            'Summary',
  created_at:               'Created At',
  criteria:                 'Criteria',
  ctr:                      'CTR',
  date:                     'Date',
  date_time:                'Date Time',
  delivery_method:          'Delivery Method',
  description:              'Description',
  device:                   'Device',
  dsa:                      'DSA',
  engine:                   'Engine',
  engine_aov:               'Engine AOV',
  engine_conversion:        'Engine Conversions',
  engine_conversions:       'Engine Conversions',
  engine_conversion_rate:   'Engine Conversion Rate',
  engine_cpa:               'Engine CPA',
  engine_cr:                'Engine Conversion Rate',
  engine_revenue:           'Engine Revenue',
  engine_roas:              'Engine ROAS',
  engine_rpc:               'Engine RPC',
  eng_conversions:          'Engine Conversions',
  eng_revenue:              'Engine Revenue',
  eng_roas:                 'Engine ROAS',
  eng_rpc:                  'Engine RPC',
  entity_type:              'Entity',
  error_start:              'Error Start',
  escore_clicks:            'Escore / Clicks',
  escore_cost:              'Escore / Cost',
  executor:                 'Executor',
  executions:               'Executions',
  experiment_type:          'Type',
  express:                  'Express',
  facebook:                 'Facebook',
  failures:                 'Failures',
  feed:                     'Feed',
  feed_id:                  'Feed ID',
  feed_type:                'Type',
  google:                   'Google',
  impressions:              'Impressions',
  integration:              'Integration',
  internal_aov:             'Internal AOV',
  internal_conversion:      'Internal Conversions',
  internal_conversions:     'Internal Conversions',
  internal_conversion_rate: 'Internal Conversion Rate',
  internal_cpa:             'Internal CPA',
  internal_cr:              'Internal Conversion Rate',
  internal_revenue:         'Internal Revenue',
  int_conv:                 'Internal Conversions',
  int_conversions:          'Internal Conversions',
  int_cvr:                  'Internal Conversion Rate',
  int_roas:                 'Internal ROAS',
  int_revenue:              'Internal Revenue',
  internal_roas:            'Internal ROAS',
  internal_rpc:             'Internal RPC',
  is_blended:               'Blended',
  job_type:                 'Job type',
  kpi:                      'Key Performance Indicator',
  max_cpc:                  'Max CPC',
  name:                     'Name',
  pla:                      'PLA',
  product_count:            'Product Count',
  pub_account_id:           'Account ID',
  pub_customer_id:          'External Customer ID',
  revenue:                  'Internal Revenue',
  revenue_pop:              'Int. Rev. Change',
  revenue_to_total:         'Int. Rev. %',
  roas:                     'Internal ROAS',
  roas_goal:                'ROAS Goal',
  roas_pop:                 'Int. ROAS Change',
  rpc:                      'Internal RPC',
  runid:                    'Run Id',
  run_by_default:           'Default',
  run_date:                 'Run date',
  run_id:                   'Run Id',
  run_order:                'Run Order',
  source:                   'Source',
  spend:                    'Spend',
  spend_last_30:            'Spend Last 30 Days',
  status:                   'Status',
  strategy:                 'Strategy',
  subbudget:                'Subbudget',
  successes:                'Successes',
  tables:                   'Tables',
  test_type:                'Test Type',
  total:                    'Total',
  uac:                      'UAC',
  updated_at:               'Updated At',
  user:                     'User',
  user_name:                'Last Modified By'
}

export const transactions = {
  all: 'All',
  google: 'Google',
  bing: 'Bing',
  facebook: 'Facebook'
}

export default {
  pages,
  metrics,
  transactions
}

