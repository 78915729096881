import _ from "lodash"
import html2pdf from "html2pdf.js"
import React from "react"
import { connect } from "react-redux"
import withRouter from '../../wrappers/withRouter'
import {
  getVariables,
  getPipeline,
  getSteps,
  getLastStep,
} from "../../api/workflows"
import { guid } from "../../utilities/guids"
import Form from "../Form"
import EditSteps from "./workflow/EditSteps"
import EditSchedule from "./workflow/EditSchedule"
import AddEditWorkflow from "./AddEditWorkflow"
import History from "./workflow/History"
import NotebookStep from "./NotebookStep"
import querystring from 'querystring'
import Header from "./WorkflowComponents/Header"
import ButtonNav from "./WorkflowComponents/ButtonNav"
import UtilityPanel from "./WorkflowComponents/UtilityPanel"
import { Box, ThemeProvider, Paper } from '@mui/material'
import i18next from 'i18next'
import { theme } from "../widgets/theme"
import Section from "../widgets/Section"

class Notebook extends React.Component {
  NOTEBOOK = "run"
  EDIT_PIPELINE = "edit"
  EDIT_STEPS = "steps"
  VIEW_HISTORY = "history"
  SCHEDULE = "schedule"
  STEPS = 1

  notebookSteps = {}

  constructor(props) {
    super(props)
    const qs = querystring.parse(props.location.search && props.location.search.length > 1 ? props.location.search.substring(1) : null)
    const referer = qs.referer ? (location.origin + '/#/' + qs.referer) : null
    this.state = {
      internal_client_id: this.props.internal_client_id,
      workflow_id: this.props.match && this.props.match.params.workflow_id != 0
        ? this.props.match.params.workflow_id
        : null,
      serviceCheck: this.props.match.params.workflow_id != null
        ? false
        : true,
      run_id: this.props.run_id
        ? this.props.run_id
        : this.props.match && this.props.match.params.run_id
          ? this.props.match.params.run_id
          : guid(),
      steps: this.props.steps ? this.props.steps : null,
      variables: [],
      edit: false,
      result: null,
      result_message: null,
      mode:
        this.props.match &&
          this.props.match.params.mode &&
          this.props.match.params.mode != ""
          ? this.props.match.params.mode
          : this.NOTEBOOK,
      pane: this.STEPS,
      referer: referer,
      devMode: false,
      stepIndex: this.props.match && this.props.match.params.step_index ? this.props.match.params.step_index : 0,
      isAdmin: this.props.isAdmin,
    }
    if (this.props.location.pathname.indexOf('/services/') == -1 && (!this.props.match || this.props.match.params.run_id != this.state.run_id)) {
      this.updateUrl(this.state.mode, this.state.stepIndex)
    }
  }

  updateUrl(mode, stepIndex) {
    const workflowId = this.state.workflow_id ? this.state.workflow_id : '0'
    const newUrl = "/workflows/notebook/" + workflowId + "/" + mode + "/" + this.state.run_id + (stepIndex ? ('/' + stepIndex) : '')
    if (this.props.location.pathname != newUrl) {
      setTimeout(() => this.props.history(newUrl), 0)
    }
  }

  componentDidMount() {
    if (this.state.workflow_id) {
      this.getData()
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.steps, prevProps.steps)) {
      this.setState({ steps: this.props.steps })
    }
    if (
      this.props.match &&
      (this.props.match.params.run_id != prevProps.match.params.run_id ||
        this.props.match.params.mode != prevProps.match.params.mode)
    ) {
      this.setState(
        {
          run_id: this.props.run_id
            ? this.props.run_id
            : this.props.match && this.props.match.params.run_id
              ? this.props.match.params.run_id
              : guid(),
          mode:
            this.props.match &&
              this.props.match.params.mode &&
              this.props.match.params.mode != ""
              ? this.props.match.params.mode
              : this.NOTEBOOK,
        },
        () => {
          this.getSteps()
        }
      )
    }
    if (this.props.match?.params.step_index != prevProps.match?.params.step_index) {
      console.log('triggered')
      this.setState({stepIndex: this.props.match && this.props.match.params.step_index ? this.props.match.params.step_index : 0})
    }
  }

  getData() {
    getPipeline(this.state.workflow_id).then((response) => {
      this.setState(
        {
          internal_client_id:
            response.data.internal_client_id != null
              ? response.data.internal_client_id
              : this.props.internal_client_id,
          name: response.data.name,
          description: response.data.description,
          allClients: response.data.internal_client_id == null,
        },
        () => {
          getVariables(
            this.state.workflow_id,
            this.state.run_id,
            this.state.internal_client_id
          ).then((res) => {
            let variables = res.data
            variables.unshift(
              {
                name: "internal_client_id",
                value: this.state.internal_client_id,
                workflow: this.state.workflow_id,
              },
              {
                name: "run_id",
                value: this.state.run_id,
                workflow: this.state.workflow_id,
              },
              {
                name: "user_id",
                value: this.props.current_user_id,
                workflow: this.state.workflow_id,
              }
            )
            this.setState({ variables: variables })
          })
        }
      )
    })

    this.getSteps()
  }

  getSteps() {
    getSteps(this.state.workflow_id, this.state.run_id).then(response => {
      const steps = response.results
      if (steps != null) {
        steps.push({
          "step_name": i18next.t('workflow.schedule'),
          "step_description": i18next.t('workflow.scheduleDescription'),
          "workflow_id": this.state.workflow_id,
          "step_num": steps.length + 1,
          "type": this.SCHEDULE,
        })
      }
      this.setState({ steps }, () => {
        const stepIndex = parseInt(this.state.stepIndex)
        if (stepIndex < 0 && this.state.steps.length + stepIndex >= 0) {
          this.setStepIndex(this.state.steps.length + stepIndex)
        } else {
          getLastStep(this.state.run_id).then((res) => {
            if (res.data != null) {
              this.state.steps.forEach((step) => {
                if (step.step_num == res.data.step__step_num) {
                  this.selectStep(step)
                }
              })
            }
          })
        }
      })
    })
  }

  setStepBody(step, body) {
    const steps = [...this.state.steps]
    step.original_step_body = body
    this.setState({ steps: steps })
  }

  selectStep(step) {
    this.setState({ step: step, edit: false })
    const stepView = $("#step" + step.step_id).get(0)
    if (stepView) {
      stepView.scrollIntoView()
    }
  }

  runAllSteps() {
    this.setState({ edit: false })
    const steps = []
    for (let i = 0; i < this.state.steps.length; i++) {
      const notebookStep = this.notebookSteps[i]
      if (notebookStep == null) {
        return // can't proceed
      }
      if (notebookStep.type != this.SCHEDULE) {
        steps.push(notebookStep)
      }
    }
    this.runFollowingSteps(steps)
  }

  runFollowingSteps(steps) {
    if (steps.length) {
      const nextStep = steps[0]
      const followingSteps = steps.slice(1)
      nextStep.runStep(() => {
        this.runFollowingSteps(followingSteps)
      })
    }
  }

  exportToPDF() {
    $("#steps").css("max-height", "none")
    html2pdf()
      .set({
        filename: this.state.name + ".pdf",
        margin: 0.25,
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
        },
      })
      .from(document.getElementById("report"))
      .save()
    setTimeout(() => {
      // Allow print to take effect
      $("#steps").css("max-height", "calc(100vh - 36px)")
    }, 100)
  }

  renderMenu() {
    return (
      <div
        className="nav navbar navbar-static-top"
        role="navigation"
        style={{ marginBottom: 0, marginRight: 40 }}
      >
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              className="navbar-toggle collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <i className="fa fa-bars" />
            </button>
          </div>
          <div id="navbar-collapse" className="collapse navbar-collapse">
            <ul className="nav navbar-nav">
              <li>
                <a
                  className="dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  {i18next.t('workflows.file')}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <a onClick={() => this.runAllSteps()}>Run All Steps</a>
                  </li>
                  <li>
                    <a onClick={() => this.exportToPDF()}>Export To PDF</a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  {i18next.t('workflows.edit')}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <a onClick={() => this.updateMode(this.NOTEBOOK)}>
                      {i18next.t('workflows.notebook')}
                    </a>
                  </li>
                  {this.props.isAdmin && (
                    <li>
                      <a onClick={() => this.updateMode(this.EDIT_PIPELINE)}>
                        {i18next.t('workflows.workflow')}
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={() => this.updateMode(this.SCHEDULE)}>
                      {i18next.t('workflow.schedule')}
                    </a>
                  </li>
                  {this.props.isAdmin && (
                    <li>
                      <a onClick={() => this.updateMode(this.EDIT_STEPS)}>
                        {i18next.t('workflows.steps')}
                      </a>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <a
                  className="dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  {i18next.t('workflows.history')}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <a onClick={() => this.updateMode(this.VIEW_HISTORY)}>
                      {i18next.t('workflows.view')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  updateModeAndState(mode) {
    this.updateMode(mode)
    this.getData()
  }

  updateMode(mode) {
    this.updateUrl(mode, this.state.stepIndex)
    this.setState({ mode: mode })
  }

  updateDevMode() {
    this.setState({ devMode: !this.state.devMode })
  }

  updateStep(step) {
    this.setState({steps: this.state.steps.map(s => s.step_id==step.step_id ? step : s)})
  }

  updateServiceMode() {
    this.setState({ servicesMode: true })
  }

  setStepIndex(i) {
    this.updateUrl(this.state.mode, i)
    this.setState({ stepIndex: i })
  }

  setStepCache(i) {
    this.setState({ stepCache: [...i] })
  }

  renderNotebook() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box
            display="flex"
            flexDirection={"column"}
            width="100%"

            margin={"auto"}
            maxHeight={"85vh"}
            overflow={"auto"}
            borderRadius={3}
          >
            {
              this.state.serviceCheck == true ?
                <Header
                  name={this.props.serviceType}
                  description={i18next.t('services.' + this.props.serviceType + '.description')}
                  serviceCheck={this.state.serviceCheck}
                  serviceType={this.props.serviceType}
                /> :
                <Header
                  name={this.state.name}
                  description={this.state.description}
                  serviceCheck={this.state.serviceCheck}
                  serviceType={this.props.serviceType}
                />
            }

          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 3fr"
            width="99%"
            margin="auto"
            gap={3}
            padding={1}
            borderRadius={3}
          >
            {this.state.serviceCheck == false ?
              <Box
                sx={{ borderRadius: 3, width: '30vw', maxWidth: "30vw", gridColumn: "1fr" }}
              >
                <Paper variant="elevation" elevation={3} >
                  <UtilityPanel
                    workflow_id={this.state.workflow_id}
                    run_id={this.state.run_id}
                    steps={this.state.steps}
                    allClients={this.state.allClients}
                    indexCheck={this.state.stepIndex}
                    setStepIndex={(index) => this.setStepIndex(index)}
                    passBack={(variables) => {
                      this.setState({ variables: variables })
                    }}
                  />
                </Paper>
              </Box>
              : null}
            <Section width={this.state.serviceCheck ? '90vw' : '62vw'} maxWidth={this.state.serviceCheck ? '90vw' : '62vw'}>
              {this.state.serviceCheck == false &&
                <ButtonNav
                  nextOnClick={() => this.setStepIndex(parseInt(this.state.stepIndex) + 1)}
                  updateDevMode={() => this.updateDevMode()}
                  step={this.state.steps.length > this.state.stepIndex ? this.state.steps[this.state.stepIndex] : null}
                  updateStep={step => this.updateStep(step)}
                  isAdmin={this.state.isAdmin}
                  showNext={this.state.stepIndex < this.state.steps.length - 1}
                />}
              <Box component="div"
                id="report"
                margin={1}
                borderRadius={3}
                height="auto"
                padding={2}
                display="flex"
                flexDirection="column"
                marginTop={3}
                width='100%'
                sx={{
                  bgcolor: theme.palette.white[100],
                }}

              >
                <div id="steps">
                  {this.state.pane == this.STEPS && (
                    <div>
                      {this.state.steps.length == 0 && (
                        <h4>{i18next.t('workflows.noStepsConfigured')}</h4>
                      )}
                      {this.state.steps.length > 0 && (
                        <div>
                          {this.state.steps.map((step, i) => {
                            return (
                              <Box component="div" key={i} maxWidth="100%">
                                <div
                                  onClick={() => {
                                    if (this.state.step != step)
                                      this.setState({ step: step, edit: false })
                                  }}
                                >
                                  {this.state.stepIndex == i ?
                                    <Box sx={{ display: 'flex' }}>
                                      <NotebookStep
                                        internal_client_id={this.state.internal_client_id}
                                        current_user_id={this.props.current_user_id}
                                        workflow_id={this.state.workflow_id}
                                        variables={this.state.variables}
                                        original_run_id={this.state.run_id}
                                        run_id={this.state.run_id}
                                        steps={this.state.steps}
                                        step={step}
                                        setStepBody={(step, body) =>
                                          this.setStepBody(step, body)
                                        }
                                        ref={(notebookStep) =>
                                          (this.notebookSteps[i] = notebookStep)
                                        }
                                        devMode={this.state.devMode}
                                        serviceCheck={this.state.serviceCheck}
                                      />
                                    </Box>
                                    : null}
                                </div>
                              </Box>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Box>
            </Section>
          </Box>
        </ThemeProvider>
      </div >
    )
  }

  render() {
    if (this.state.steps) {
      return (
        <div className="container-fluid js_complete">
          <Form
            showTitle={this.state.workflow_id}
            objectType="Pipeline"
            objectName={this.state.name}
            newObject={false}
            canEdit={false}
            canDelete={false}
            parentLink={
              this.state.referer ?
                this.state.referer :
                (location.href.indexOf("/services/") >= 0
                  ? "/services"
                  : "/workflows/all")
            }
            noRow={true}
            noIndent={this.props.noIndent}
          >
            {/* {(this.state.workflow_id && this.state.workflow_id != 18) && this.renderMenu()} */}

            {this.state.workflow_id && this.renderMenu()}
            {this.state.mode == this.NOTEBOOK && this.renderNotebook()}
            {this.state.mode == this.EDIT_PIPELINE && (
              <AddEditWorkflow
                workflow_id={this.state.workflow_id}
                workflow_name={this.state.name}
                history={this.props.history}
                run_id={this.state.run_id}
                variables={this.state.variables}
                internal_client_id={this.state.internal_client_id}
                hideHeader={true}
                updateMode={(mode) => this.updateModeAndState(mode)}
              />
            )}
            {this.state.mode == this.EDIT_STEPS && (
              <EditSteps
                workflow_id={this.state.workflow_id}
                workflow_name={this.state.name}
                history={this.props.history}
                run_id={this.state.run_id}
                variables={this.state.variables}
                internal_client_id={this.state.internal_client_id}
              />
            )}
            {this.state.mode == this.SCHEDULE && (
              <EditSchedule workflow_id={this.state.workflow_id} />
            )}
            {this.state.mode == this.VIEW_HISTORY && (
              <History
                internal_client_id={this.props.internal_client_id}
                workflow_id={this.state.workflow_id}
                history={this.props.history}
              />
            )}
          </Form>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    current_user_id: state.users.user ? state.users.user.id : null,
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,

  }
}

export default withRouter(connect(mapStateToProps)(Notebook))

