import { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Table from '../widgets/Table';
import { getUsers } from '../../api/users'
import i18next from 'i18next'

class Interface extends Component {
  getColumns() {
    return [{
      id: 'name',
      header: 'users.user',
      datatype: 'text',
      width: 200,
      cellRenderer: (value, row) => (<Link to={`/users/${row.id}`}>{value}</Link>),
    },{
      id: 'email',
      header: 'users.email',
      datatype: 'text',
      width: 300,
    },{
      id: 'client_name',
      header: 'users.client',
      datatype: 'text',
      cellRenderer: (value, row) => (<div className="text-center"><Link to={`/clients/${row.internal_client_id}`}>{value}</Link></div>),
    },{
      id: 'role',
      header: 'users.role',
      datatype: 'text',
    },{
      id: 'last_used_at',
      header: 'users.lastUsed',
      datatype: 'date',
    }]
  }

  render() {
    return (
      <div className="site-canvas" style={{ minHeight: '540px' }}>
        <div className='content'>
          <div className='box'>
            <div className='box-header'>
              <div className='box-tools'>
                <Link to='/users/new' className='btn btn-box-tool text-green'>
                  <i className='fa fa-plus' />
                  <span>{i18next.t('users.addNewUser')}</span>
                </Link>
              </div>
            </div>
            <div className='box-body no-padding js_complete' style={{marginTop: '15px'}}>
              <Table
                fetch={(tableData) => {return getUsers(this.props.internal_client_id, tableData)}}
                columns={this.getColumns()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(Interface)
