import { Component } from 'react'
import { connect } from 'react-redux'
import { getClient, patchClient } from '../../../api/clients.js'
import FTNotice from '../../FTNotice'
import i18next from 'i18next'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      test_bidding: null
    };
  }

  componentDidMount() {
    this.getClient();
  }

  getClient() {
    getClient(this.props.internal_client_id).then(results => {
      this.setState({test_bidding: results.test_bidding})
    })
  }

  patchClient() {
    patchClient({internal_client_id: this.props.internal_client_id, test_bidding: this.state.test_bidding}).then(result => {
      if (!result) {
        FTNotice('biddingTest.couldNotUpdateTesting', 15000)
      }
    })
  }

  render() {
    if (this.state.test_bidding == null) {
      return null
    }
    return (
      <div className='row'>
        <div className='col-md-12 mt-25'>
          <div className="col-md-2"></div>
          <input
            type="radio"
            checked={!this.state.test_bidding}
            onChange={event => this.setState({test_bidding: false}, () => this.patchClient())}/>
          &nbsp;{i18next.t('biddingTest.useClientData')}
        </div>
        <div className='col-md-12 mt-25 js_complete'>
          <div className="col-md-2"></div>
          <input
            type="radio" 
            checked={this.state.test_bidding}
            onChange={event => this.setState({test_bidding: true},  () => this.patchClient())}/>
          &nbsp;{i18next.t('biddingTest.useTestData')}
        </div>
      </div>
    )
  } 
}

const mapStateToProps = (state) => ({
  internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
})

export default connect(mapStateToProps)(Interface);
