import _ from 'lodash'
import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getAbTest, getSummaryData, getDailyData } from '../../api/ab_tests'
import DatePicker from '../widgets/DatePicker'
import ABTestConfig from './ABTestConfig'
import Metrics from './ab_test/Metrics'
import Significance from './ab_test/Significance'
import Performance from './ab_test/Performance'
import Table from './ab_test/Table'
import Page from '../widgets/Page'
import Title from '../widgets/Title'
import Section from '../widgets/Section'
import Wrench from '../widgets/Wrench'
import FTNotice from '../FTNotice'
import { Box, Grid, Divider, Typography } from '@mui/material'
import i18next from 'i18next'

class ABTest extends Component {
  DEFAULT_SETTINGS = {
    info_boxes: [
      { clicks: true },
      { cost: true },
      { cpc: true },
      { clickthrough: true },
      { impressions: false },
      { max_cpc: false },
      { engine_conversions: false },
      { engine_cr: false },
      { engine_revenue: false },
      { engine_cpa: false },
      { engine_roas: false },
      { engine_aov: false },
      { engine_rpc: false },
      { escore_clicks: false },
      { escore_cost: false },
      { conversions: false },
      { conv_rate: true },
      { revenue: true },
      { cpa: false },
      { roas: true },
      { aov: false },
      { rpc: true },
    ],
    significance: [
      { clicks: false },
      { cost: false },
      { cpc: false },
      { clickthrough: true },
      { impressions: false },
      { max_cpc: false },
      { engine_conversions: false },
      { engine_cr: false },
      { engine_revenue: false },
      { engine_cpa: false },
      { engine_roas: false },
      { engine_aov: false },
      { engine_rpc: false },
      { escore_clicks: false },
      { escore_cost: false },
      { conversions: false },
      { conv_rate: true },
      { revenue: true },
      { cpa: false },
      { roas: true },
      { aov: false },
      { rpc: false },
    ],
  }

  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id,
      summary_data: null,
      daily_data: null,
      title: null,
      description: null,
      date: null,
      filters: {},
      settings: this.DEFAULT_SETTINGS,
      date_from: null,
      date_to: null
    }
    this.getAbTestData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.id != prevState.id ||
      (prevState.date_from && this.state.date_from != prevState.date_from) ||
      (prevState.date_to && this.state.date_to != prevState.date_to) ||
      !_.isEqual(this.state.filters, prevState.filters)
    ) {
      this.getAbTestData()
    }
  }

  getAbTestData() {
    const params = Object.assign(
      { comparison: this.props.match.params.type },
      this.state.filters,
      { date_from: null, date_to: null }
    );
    getAbTest(this.state.id).then(abTest => {
      if (abTest.error) {
          FTNotice(abTest.error, 3000)
          this.props.history('/ab_tests')
      } else if (this.state.date_from && this.state.date_to) {
        params.date_from = this.state.date_from
        params.date_to = this.state.date_to
      } else { // Should be in sync with logic in models/ab_tests.py/ABTestMetric#list
        let start = moment(abTest.start_date.split('T')[0])
        let end = moment(abTest.end_date)
        const yesterday = moment().subtract(1, 'days')
        if (end.isAfter(yesterday)) {
          end = yesterday
        }
        if (!abTest.control_sql) {
          start = start.subtract(end.diff(start, 'days'), 'days')
        }
        params.date_from = start.format('YYYY-MM-DD')
        params.date_to = end.format('YYYY-MM-DD')
      }
      getSummaryData(this.state.id, params).then((response) => {
        if (response.result.date) {
          const res = response.result
          const newState = {
            summary_data: res,
            title: res.title,
            description: res.description,
            date: moment(res.date).subtract(1, "day").format("YYYY-MM-DD"),
            settings: response.settings ? response.settings : this.DEFAULT_SETTINGS,
          }
          if (!this.state.date_from) {
            newState.date_from = params.date_from
            newState.date_to = params.date_to
          }
          this.setState(newState)
          getDailyData(this.state.id, params).then((response) => {
            this.setState({ daily_data: response.result })
          })
        } else {
          const data = {
            cost: 0,
            cost_before: 0,
            clicks: 0,
            click_before: 0,
            revenue: 0,
            revenue_before: 0,
            internal_roas: 0,
            roas_before: 0,
            cpc: 0,
            cpc_before: 0,
            rpc: 0,
            rpc_before: 0,
            cr: 0,
            cr_before: 0,
            ctr: 0,
            ctr_before: 0,
            conv_rate_probability: 0,
            clickthrough_probability: 0,
            roas_probability: 0,
            revenue_probability: 0,
          }
          this.setState({ summary_data: data })
        }
      })
    })
  }

  updateDates(dates) {
    if (this.state.date_from) {
      this.setState({ date_from: dates.start, date_to: dates.end })
    }
  }

  render() {
    const abTestElevation = 4
    if (this.state.summary_data && this.state.daily_data && this.state.date)
      return (
        <Page>
          <ABTestConfig
            id={this.props.match.params.id}
            settings={this.state.settings}
            updateSettings={settings => this.setState({ settings })}
            defaults={this.DEFAULT_SETTINGS}
            filtersToInclude={{
              channels: null,
              engines: null,
              entity_types: null,
              criteria_types: null,
              devices: null,
            }}
            filterValues={this.state.filters}
            changed={(filters) => {
              if (
                JSON.stringify(filters) !== JSON.stringify(this.state.filters)
              )
                this.setState({ filters: filters });
            }}
          />
          <Grid container>
            <Grid item md={12}>
              {/* <Paper elevation={3} sx={{ borderRadius: 5, padding: 3, mb: 1 }}> */}
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                overflowWrap: 'breakWord'
              }}>
                <Typography variant="h3">{i18next.t('abtest.edit.abtest') + ': ' + this.state.title}</Typography>
                <Divider />
                <Typography><strong>{i18next.t('abtest.abtest.published')}</strong>{this.state.date}</Typography>
                {this.state.description && <Typography><strong>{i18next.t('abtest.abtest.summary')}</strong>{this.state.description}</Typography>}
              </Box>
              {/* </Paper> */}
            </Grid>
          </Grid>
          <section className="content">
            <div className="page-nav" style={{ position: "relative" }}>
              <div className="btn-group" style={{ width: "100%" }}>
                <Wrench />
                <DatePicker
                  start={this.state.date_from}
                  end={this.state.date_to}
                  showCompare={false}
                  callback={(dates) => this.updateDates(dates)}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <br />
            {this.state.summary_data && (
              <div className="row">
                <Section padding={0} elevation={abTestElevation}>
                  <Title title="abtest.config.metrics" />
                  <Metrics
                    settings={this.state.settings.info_boxes}
                    values={this.state.summary_data}
                  />
                </Section>
              </div>
            )}
            {this.state.summary_data && (
              <div className="row">
                <Section padding={0} elevation={abTestElevation}>
                  <Title title="abtest.config.significance" />
                  <Significance
                    settings={this.state.settings.significance}
                    values={this.state.summary_data}
                  />
                </Section>
              </div>
            )}
            {this.state.title && (
              <div className="row">
                <Section padding={0} elevation={abTestElevation}>
                  <Title title="abtest.config.performance" />
                  <Performance
                    title={this.state.title}
                    data={this.state.daily_data}
                    comparison={this.props.match.params.type}
                  />
                </Section>
              </div>
            )}
            <div className="row">
              <Section padding={0} elevation={abTestElevation}>
                <Title title="abtest.config.performance" />
                <Table data={this.state.daily_data} />
              </Section>
            </div>
          </section >
        </Page >
      )
    else {
      return null
    }
  }
}

export default withRouter(ABTest)