import React, { useEffect, useState } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getModelFeatures, getPending, getTopFeatureValues } from '../../api/data_science'
import Table from '../widgets/Table'
import Button from '../widgets/Button'
import FTNotice from '../FTNotice'
import Dialog from '../widgets/Dialog'
import { formatInteger } from '../../utilities/formatting'
import Form from '../Form'
import i18next from 'i18next'

const ModelFeatures = ({match}) => {
  const modelId = match.params.id
  const [features, setFeatures] = useState()
  const [pending, setPending] = useState(false)
  const [showCommonFeatures, setShowCommonFeatures] = useState(false)
  const [commonFeatures, setCommonFeatures] = useState()
  
  const getColumns = () => {
    return [{
      id: 'name',
      header: 'dataScience.name',
      datatype: 'text',
      width: 150,
    },{
      id: 'score',
      header: 'dataScience.score',
      datatype: 'number',
      width: 150,
    },{
      id: 'values',
      header: 'dataScience.values',
      datatype: 'text',
      preventSort: true,
      cellRenderer: (_, row) => (
        <Button
        onClick={() => {setShowCommonFeatures(true); getTopFeatureValues({'id': modelId, 'name': row.name}).then((result) => {
          if (result)
            setCommonFeatures(result.result);
          else
            FTNotice(result.error);
        })}}
        buttonText={'View Values'}
        size='small'
        />
      ),
      width:140
    }]
  }

  const getValuesColumns = () => {
    return [{
      id: 'name',
      header: 'dataScience.featureValue',
      datatype: 'text',
      width: 150,
    },{
      id: 'count',
      header: 'dataScience.countValue',
      datatype: 'number',
      format: value => formatInteger(value),
      width: 150,
    },{
      id: 'percentage',
      header: 'dataScience.pctFeature',
      datatype: 'text',
      width: 200,
    }]
  }

  useEffect(() => {
    getModelFeatures(modelId).then(result => {
      setPending(false)
      setFeatures(result.results)
    })
  }, [])

  return (
    <div style={{cursor: pending ? 'wait' : null}}>
      <Form
        canEdit={false}
        canDelete={false}
        parentLink={`/data_science/${modelId}`}>
        <div style={{marginLeft: 30, marginRight: 30, marginTop: 35}}>
          <h3>{i18next.t('dataScience.features')}</h3>
          <Dialog
           open={showCommonFeatures}
           onClose={() => setShowCommonFeatures(false)}
           title={i18next.t('dataScience.mostCommonFeatures')}
           width='lg'
           fullWidth={true}
          >
            {commonFeatures != null && (<Table
                dataset={commonFeatures}
                columns={getValuesColumns()}
                autoHeight={true}/>)}
          </Dialog>
          {features != null && (
            <Table
              dataset={features}
              columns={getColumns()}
              autoHeight={true} />
          )}
        </div>
      </Form>
    </div>
  )
}

export default withRouter(ModelFeatures)


