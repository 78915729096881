import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getNotifications, acknowledge as acknowledgeAction } from '../../redux/alerts/sagaActions'
import styles from './NotificationsStyles'
import { prettyPrint } from '../../utilities/formatting'
import i18next from 'i18next'

const Notifications = ({dispatch, notifications, internal_client_id, is_admin}) => {
  useEffect(() => {
    if (internal_client_id != null && is_admin) {
      dispatch(getNotifications(internal_client_id))
    }
  }, [internal_client_id, is_admin])
  
  if (notifications == null || !is_admin) {
    return null
  }
  
  const displayContent = (content) => {
    if (content.length == 0) {
      return null
    }
    const rows = []
    const keys = Object.keys(content[0])
    const headers = []
    keys.forEach((key, i) => {
      headers.push(<span key={i} style={styles.header}>{prettyPrint(key)}</span>)
    })
    rows.push(<div key={-1} style={styles.row}>{headers}</div>)
    content.forEach((item, i) => {
      const values = []
      keys.forEach((key, j) => {
        values.push(<span key={j} style={styles.cell}>{item[key]}</span>)
      })
      rows.push(<div key={i} style={styles.row}>{values}</div>)
    })
    return rows
  }
  
  const acknowledge = (notification) => {
    dispatch(acknowledgeAction(notification.id))
  }
  
  return (
    <div style={styles.container}>
      {notifications.map((notification, i) => (
        <div key={i} style={styles.mainRow}>
          <span style={styles.cell}>{moment(notification.date).format('M/D/YYYY')}</span>
          <span style={styles.cell}>{notification.name}</span>
          <span style={styles.cell}>{displayContent(notification.content)}</span>
          <span style={Object.assign({}, styles.cell, {textAlign: 'right'})}><button className="btn-primary" onClick={() => acknowledge(notification)}>{i18next.t('notifications.acknowledge')}</button></span>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = function(state) {
  return {
    is_admin: state.users.user ? state.users.user.role == "Admin" : false,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    notifications: state.alerts.notifications
  }
}

export default connect(mapStateToProps)(Notifications)
