import { Component } from "react"
import { Link } from "react-router-dom"
import FTNotice from "../FTNotice"
import { connect } from "react-redux"
import RGL, { WidthProvider } from "react-grid-layout"
import Form from '../Form'
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import DashboardCard from "./DashboardCard"
import {
  getSqlById,
  getChartAnnotations,
  executeSql,
} from "../../api/dashboards"
import { getCustomVariables } from "../../utilities/utils"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import _ from "lodash"
import Field from "../Field"
import AddEditAnnotations from "../charts/AddEditAnnotation"
import DropDownList from '../DropDownList'
import i18next from 'i18next'
import { getName } from '../charts/util'
import { convertVariables } from '../SQLEditor'
import { CUSTOM, RANGE } from '../CodeEditor'
import Button from '../widgets/Button'
import ButtonRow from '../widgets/ButtonRow'
import Page from '../widgets/Page'


const ReactGridLayout = WidthProvider(RGL)

class DashboardCanvas extends Component {
  OLD_KEP_PATH = "https://s3.amazonaws.com/ft-kep/piplineIcons/"

  static defaultProps = {
    className: "layout",
    cols: 100,
    rowHeight: 5,
    onLayoutChange: function () {},
    all_sql: [],
  }

  static getDerivedStateFromProps(props, state) {
    if (state.current_client_id != props.current_client_id) {
      return { current_client_id: props.current_client_id }
    } else {
      return null
    }
  }

  cardStyle = {
    width: "100%",
    height: "100%",
  }

  constructor(props) {
    super(props)
    this.state = Object.assign(
      this.newState(),
      {
        openDialog: false,
        chartType: "",
        currentCardId: null,
        currentCard: null,
        editCard: 0,
        filters: {},
        current_client_id: null,
        annotations: null,
        openDialogAction: null,
        selectedColumns: [],
        selectedLines: [],
      },
      this.processCards(this.props.cards)
    )
  }

  componentDidMount() {
    this.getChartAnnotations()
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.cards != prevProps.cards) {
      this.processCards(this.props.cards)
    }
  }

  getChartAnnotations() {
    this.setState({ annotations: null })
    if (this.props.dashboard_id == "new") {
      this.setState({ annotations: [] })
    } else {
      getChartAnnotations("dashboard", this.props.dashboard_id).then(
        (response) => {
          this.setState({ annotations: response.results })
        }
      )
    }
  }

  processCards(cardJson) {
    let cards = []
    const layout = []
    if (cardJson) {
      cards = JSON.parse(cardJson)
      cards.forEach((card) => {
        layout.push(card.data_grid)
      })
    }
    return { cards: cards, layout: layout }
  }

  onLayoutChange(layout) {
    this.setState({ layout: layout }, () => this.saveLayout())
  }

  addEditCard() {
    if (this.state.chartType == "text label") {
      if (this.state.textLabel.trim() == "") {
        FTNotice('dashboardCanvas.enterLabel', 15000)
        return
      }
    } else if (this.state.chartType == "info box") {
      if (this.state.infoBoxTitle.trim() == "") {
        FTNotice('dashboardCanvas.enterTitle', 15000)
        return
      }
      if (this.state.sqlId == "") {
        FTNotice('dashboardCanvas.enterSql', 15000)
        return
      }
      if (this.state.infoBoxMetric.trim() == "") {
        FTNotice('dashboardCanvas.enterMetric', 15000)
        return
      }
      if (this.state.icon == "") {
        FTNotice('dashboardCanvas.enterIcon', 15000)
        return
      }
    } else if (this.state.chartType != "chart annotations") {
      if (this.state.sqlId == "") {
        FTNotice('dashboardCanvas.enterSql', 15000)
        return
      }
    }

    const action = this.state.openDialogAction
    if (['add', 'edit'].includes(action)) {
      const cards = this.state.cards.filter(card => action == 'add' || card.id != this.state.currentCardId)
      let layout = null
        const newCard = {
        type: this.state.chartType,
        title: this.state.chartTitle,
        page_size: this.state.pageSize,
        axes: this.state.axes,
        sql_id: this.state.sqlId,
        text_label: this.state.textLabel,
        icon: this.state.icon,
        info_box_title: this.state.infoBoxTitle,
        info_box_metric: this.state.infoBoxMetric,
        custom_variables: this.state.custom_variable_values,
        num_pinned: this.state.numPinned,
      }
      if (newCard.type == 'combo_column_line') {
        newCard['columns'] = this.state.columns
        newCard['lines'] = this.state.lines
      }
      if (newCard.type == 'geo') {
        newCard['map'] = this.state.map
        newCard['name_attribute'] = this.state.nameAttribute
        newCard['value_attribute'] = this.state.valueAttribute
      }
      cards.push(newCard)
      if (this.state.openDialogAction == "add") {
        let biggestId = 0
        this.state.cards.forEach((card) => {
          if (card.id > biggestId) {
            biggestId = card.id
          }
        })
        const id = biggestId + 1
        let dataGrid = {}
        switch (this.state.chartType) {
          case "line":
          case "bar":
          case "stacked_bar":
          case "pie":
          case "funnel":
          case "geo":
          case "combo_column_line":
          case "column":
          case "stacked_column":
            dataGrid = { w: 29, h: 27, x: 35, y: 0 }
            break
          case "text label":
            dataGrid = { w: 16, h: 4, x: 42, y: 0 }
            break
          case "info box":
            dataGrid = { w: 29, h: 8, x: 35, y: 0 }
            break
          case "data grid":
            dataGrid = { w: 85, h: 23, x: 6, y: 0 }
            break
          default:
            FTNotice('dashboardCanvas.wrongType', 15000)
        }
        dataGrid["i"] = id + ""
  
        newCard['id'] = id
        newCard['data_grid'] = dataGrid
        layout = this.state.layout.slice()
        layout.push(newCard['data_grid'])
      } else {
        newCard['id'] = this.state.currentCardId
        newCard['data_grid'] = this.state.currentCard.data_grid
      }
      const newState = { cards }
      if (layout != null) {
        newState['layout'] = layout
      }
      this.setState(newState, () => this.saveCards())
    }

    this.setState({
      openDialogAction: null,
      openDialog: false,
      editCard: this.state.editCard + 1,
      currentCardId: null,
      currentCard: null,
    })
  }

  saveLayout() {
    const cards = this.state.cards
    const layouts = this.state.layout
    for (let i = 0; i < cards.length; i++) {
      cards[i].data_grid = layouts.find(element => element.i == cards[i].id)
    }
    this.setState({ cards: cards }, () => {
      this.saveCards()
    })
  }

  saveCards() {
    if (this.props.action) {
      this.props.action(JSON.stringify(this.state.cards))
    }
    if (this.props.getVariables) {
      this.props.getVariables(
        JSON.stringify(this.state.custom_variable_values)
      )
    }
  }

  onRemoveItem(i) {
    this.setState(
      {
        cards: _.reject(this.state.cards, { id: i }),
        layout: _.reject(this.state.layout, { i: i }),
      },
      () => {
        this.saveLayout()
      }
    )
  }

  updateCustomVariables() {
    getSqlById(this.state.sqlId).then(sql => {
      const newState = {}
      if (this.state.custom_variable_values == null) {
        newState.custom_variable_values = convertVariables(sql.default_variables)
      }
      const customVariableValues = this.state.custom_variable_values == null ? newState.custom_variable_values : this.state.custom_variable_values
      const customVariables = getCustomVariables(sql.sql)
      if (
        customVariables.sort().toString() !=
        this.state.custom_variables.sort().toString()
      ) {
        newState.custom_variables = customVariables
      }
      if (Object.keys(newState).length !== 0) {
        this.setState(newState)
      }
      const params = {internal_client_id: this.state.current_client_id}
      
      if (customVariableValues != null) {
        params['custom_variables'] = customVariableValues
      }
      executeSql(this.state.sqlId, params).then(result => this.getCols(result.results))
    })
  }

  handleSelectSql(sqlId) {
    this.setState({ sqlId: sqlId }, () => this.updateCustomVariables())
  }

  handleSelectIcon = (event) => {
    this.setState({ icon: event.target.value })
  }

  handleSelectMetric = (event) => {
    this.setState({ infoBoxMetric: event.target.value })
  }

  getCols(results) {
    if (results != null) {
      let cols = []
      if (results.length > 0) {
        Object.keys(results[0]).forEach(key => {
          cols.push(key)
        })
      }
      this.setState({ cols: cols })
    } else {
      this.setState({ cols: [] })
    }
  }

  generateButton() {
    if (this.props.editable) {
      return (
        <>
        <ButtonRow
          direction="row"
          justifyContent="center"
        >
          <Button
            onClick={() => this.openDialog("text label")}
            buttonText={'dashboardCanvas.addLabel'}
            />
            <Button
            onClick={() => this.openDialog("info box")}
            buttonText={'dashboardCanvas.addInfoBox'}
            />
            <Button
            onClick={() => this.openDialog("line")}
            buttonText={'dashboardCanvas.addChart'}
            />
            <Button
            onClick={() => this.openDialog("data grid")}
            buttonText={'dashboardCanvas.addTable'}
            />
            <Button
            onClick={() => this.openDialog("chart annotation", null)}
            buttonText={'dashboardCanvas.chartAnnotations'}
            />
        </ButtonRow>
        
        </> 
      )
    }
  }

  getAxes(card) {
    return card.axes == null
      ? [{title: card.y_axis_title || '', selectedColumns: [], columns: ['dashboardCanvas.otherColumns']}, {title: "", selectedColumns: [], columns: []}]
      : card.axes.map(axis => Object.assign({selectedColumns: []}, axis))
  }
  
  getCardType(card) {
    return card.type == "multi_line" ? "line" : card.type
  }

  editCard(cardType, cardId) {
    if (this.props.editable) {
      this.state.cards.forEach((card) => {
        if (card.id == cardId) {
          const newState = {
            currentCardId: cardId,
            currentCard: card,
            custom_variable_values: card.custom_variables,
            chartType: this.getCardType(card),
            chartTitle: card.title,
            pageSize: card.page_size == null ? "" : card.page_size,
            nameAttribute: card.name_attribute == null ? 'location_name' : card.name_attribute,
            valueAttribute: card.value_attribute == null ? 'value' : card.value_attribute,
            axes: this.getAxes(card),
            sqlId: card.sql_id,
            textLabel: card.text_label,
            icon: card.icon,
            infoBoxTitle: card.info_box_title,
            infoBoxMetric: card.info_box_metric,
            numPinned: card.num_pinned,
            map: card.map ? card.map : 'USA',
            columns: card.columns ? card.columns: ['dashboardCanvas.otherColumns'],
            lines: card.lines ? card.lines: [],
          }
          this.setState(newState, () => this.updateCustomVariables())
        }
      })
  
      this.openDialog(cardType, "edit")
    }
  }

  generateCards() {
    return (
      <ReactGridLayout
        {...this.props}
        layout={this.state.layout}
        onLayoutChange={layout => this.onLayoutChange(layout)}
      >
        {this.state.cards.map((card, i) => {
          let icon = card.icon
          if (icon && icon.startsWith(this.OLD_KEP_PATH)) {
            icon =
              "/resources/images/workflow_icons/" +
              card.icon.substring(this.OLD_KEP_PATH.length)
          }
          return (
            <div key={card.id} data-grid={card.data_grid}>
              <Card style={this.cardStyle}>
                <CardContent style={{ height: "100%" }}>
                  <DashboardCard
                    id={i}
                    type={this.getCardType(card)}
                    title={card.title}
                    nameAttribute={card.name_attribute == null ? 'location_name' : card.name_attribute}
                    valueAttribute={card.value_attribute == null ? 'value' : card.value_attribute}
                    axes={this.getAxes(card)}
                    page_size={card.page_size}
                    sql_id={card.sql_id}
                    text_label={card.text_label}
                    icon={icon}
                    info_box_title={card.info_box_title}
                    info_box_metric={card.info_box_metric}
                    start_date={this.props.start_date}
                    end_date={this.props.end_date}
                    compare_start={this.props.compare_start}
                    compare_end={this.props.compare_end}
                    key={
                      this.props.start_date +
                      this.props.end_date +
                      this.state.editCard
                    }
                    card_id={card.id}
                    edit_action={(cardType, cardId) =>
                      this.editCard(cardType, cardId)
                    }
                    filters={this.props.filters}
                    annotations={this.state.annotations}
                    variables={this.mergeVariables(card.custom_variables)}
                    num_pinned={card.num_pinned}
                    params={this.props.params}
                    columns={card.columns}
                    lines={card.lines}
                    map={card.map}
                  />
                </CardContent>
              </Card>
              {this.props.editable ? (
                <div style={{ position: "absolute", right: 2, top: 0 }}>
                  <i
                    className="fa fa-close"
                    onMouseDown={event => {
                      event.stopPropagation()
                    }}
                    onTouchStart={event => {
                      event.stopPropagation()
                    }}
                    onClick={this.onRemoveItem.bind(this, card.id)}
                    style={{ cursor: "pointer", color: "red" }}
                  />
                </div>
              ) : null}
            </div>
          )
        })}
      </ReactGridLayout>
    )
  }
  
  mergeVariables(customVariables) {
    const extraFilters = this.props.extraFilters
    const variables = []
    if (customVariables) {
      if (Array.isArray(customVariables)) {
        customVariables.forEach(variable => {
          const overridden = extraFilters == null ? null : extraFilters.find(v => v.name == variable.name)
          variables.push(overridden == null ? variable : overridden)
        })
      } else { // old formatting
        for (const [name, value] of Object.entries(customVariables)) {
          const overridden = extraFilters == null ? null : extraFilters.find(v => v.name == name)
          variables.push(overridden == null ? {name, value, type: CUSTOM} : overridden)
        }
      }
    }
    return variables
  }

  updateAnnotationCallBack() {
    this.getChartAnnotations()
    this.addEditCard()
  }

  renderOkCancel() {
    return (
      <div className="text-center" style={{ marginBottom: 20 }}>
        <button className="btn btn-primary" onClick={() => this.addEditCard()}>
          {i18next.t('dashboardCanvas.ok')}
        </button>
        <button
          className="btn btn-primary"
          onClick={() =>
            this.setState({
              openDialog: false,
              currentCardId: null,
              currentCard: null,
            })
          }
          style={{ marginLeft: 10 }}
        >
          {i18next.t('dashboardCanvas.cancel')}
        </button>
      </div>
    )
  }

  renderVariables() {
    if (this.state.custom_variables.length > 0) {
      const values = Array.isArray(this.state.custom_variable_values)
        ? this.state.custom_variable_values.map(v => Object.assign({}, v))
        : Object.entries(this.state.custom_variable_values).map((key, value) => {
          const dict = {}
          dict[key] = value
          return dict
        })
      return (
        <>
          {this.state.custom_variables.map((variableName, i) => {
            const variable = values.find(v => v.name == variableName)
            if (variable == null) {
              return null
            }
            return (
              <Field
                key={i}
                label={variable.name}
                dialog={true}
                style={{marginTop: 10}}
              >
                {variable.type == CUSTOM && (
                  <input
                    className="form-control"
                    value={variable.value}
                    onChange={event => {
                      variable.value = event.target.value
                      this.setState({custom_variable_values: values})
                    }}
                  />
                )}
                {variable.type == RANGE && (
                  <div>
                    <input style={{display: 'inline-block', width: 100}} type="number"
                      className="form-control"
                      value={variable.min}
                      onChange={event => {
                        variable.min = event.target.value
                        this.setState({custom_variable_values: values})
                      }}
                    />
                    <span style={{marginLeft: 5, marginRight: 5}}>{i18next.t('sqlEditor.to')}</span>
                    <input style={{display: 'inline-block', width: 100}} type="number"
                      className="form-control"
                      value={variable.max}
                      onChange={event => {
                        variable.max = event.target.value
                        this.setState({custom_variable_values: values})
                      }}
                    />
                  </div>
                )}
              </Field>
            )
          })}
        </>
      )
    }
  }
  
  compareColumns(a, b) {
    const ia = this.state.cols.indexOf(a) < 0 ? 9999 : this.state.cols.indexOf(a)  // Put Other Columns last
    const ib = this.state.cols.indexOf(b) < 0 ? 9999 : this.state.cols.indexOf(b)  // Put Other Columns last
    return (ia < ib) ? -1 : (ia > ib) ? 1 : 0;
  }
  
  getCurrentColumnsAndLines() {
    const columns = this.state.columns.filter(column => this.state.cols.includes(column) || column == 'dashboardCanvas.otherColumns')
    const lines = this.state.lines.filter(line => this.state.cols.includes(line) || line == 'dashboardCanvas.otherColumns')
    const potential = this.state.cols.slice()
    if (potential.length > 0) {
      potential.shift() // first column is x axis
    }
    const unmappedColumns = potential.filter(col => !columns.includes(col) && !lines.includes(col))
    if (lines.length == 0 && unmappedColumns.length > 0) {
      lines.push(unmappedColumns.shift())
      columns.push(...unmappedColumns)
    }
    columns.sort((a, b) => this.compareColumns(a, b))
    lines.sort((a, b) => this.compareColumns(a, b))
    return {columns, lines}
  }
  
  moveColumns(oldAxis, newAxis) {
    newAxis.columns.push(...oldAxis.selectedColumns)
    newAxis.selectedColumns = oldAxis.selectedColumns
    oldAxis.columns = oldAxis.columns.filter(column => !oldAxis.selectedColumns.includes(column))
    oldAxis.selectedColumns = []

    // Once we move columns, we need to fix any implied column locations
    const axes = this.state.axes.slice()
    this.setState({axes}, () => this.setState({axes: this.state.axes.map(a => Object.assign(a, {columns: this.getColumns(a)}))}))
  }
  
  updateTitle(axis, title) {
    axis.title = title
    const axes = this.state.axes.slice()
    this.setState({axes})
  }
  
  getColumns(axis) {
    const tableColumns = this.state.cols.slice(1) // first column is other axis (ex., y)
    const columns = axis.columns.filter(column => tableColumns.includes(column) || column == 'dashboardCanvas.otherColumns')
    const missing = tableColumns.filter(column => this.state.axes.find(a => a.columns.includes(column)) == null)
    if (columns.indexOf('dashboardCanvas.otherColumns') >= 0) {
      missing.forEach(column => columns.splice(columns.indexOf('dashboardCanvas.otherColumns'), 0, column))
    }
    columns.sort((a, b) => this.compareColumns(a, b))
    return columns
  }
  
  updateSelected(axis, selected) {
    axis.selectedColumns = selected
    const axes = this.state.axes.slice()
    this.setState({axes})
  }
  
  addColumns() {
    let {columns, lines} = this.getCurrentColumnsAndLines()
    columns.push(...this.state.selectedLines)
    lines = lines.filter(line => !this.state.selectedLines.includes(line))
    this.setState({selectedLines: [], selectedColumns: this.state.selectedLines, columns: columns, lines: lines})
  }
  
  addLines() {
    let {columns, lines} = this.getCurrentColumnsAndLines()
    lines.push(...this.state.selectedColumns)
    columns = columns.filter(column => !this.state.selectedColumns.includes(column))
    this.setState({selectedColumns: [], selectedLines: this.state.selectedColumns, columns: columns, lines: lines})
  }
  
  renderSQLField() {
    const currentSql = this.props.all_sql.find(sql => sql.id == this.state.sqlId)
    return (
      <Field label={i18next.t('dashboardCanvas.sql')} dialog={true}>
        <div>
          <DropDownList buttonLabel={currentSql ? currentSql.name : i18next.t('dashboardCanvas.clickToSelectSql')} values={this.props.all_sql.map(sql => {
              // Before 9/13/21, we didn't track change dates
              const changeDate = sql.updated_at ? sql.updated_at : '2021-09-13 12:00:00'
              return {id: sql.id, name: `${sql.name} (${changeDate})`}
            })} action={sql => this.handleSelectSql(sql.id)}/>
        </div>
        <div style={{paddingLeft: 10}}>
          <Link to={"/dashboard_admin/sql/" + this.state.sqlId}>
            {i18next.t('dashboardCanvas.editSql')}
          </Link>
        </div>
      </Field>
    )
  }

  generateDialog() {
    if (!this.props.editable) {
      return
    }
    if (this.state.chartType == "text label") {
      return (
        <div>
          <Dialog
            open={this.state.openDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">{i18next.t('dashboardCanvas.addLabel')}</DialogTitle>
            <DialogContent>
              <Field label={i18next.t('dashboardCanvas.label')} dialog={true}>
                <input
                  className="form-control"
                  value={this.state.textLabel}
                  onChange={event =>
                    this.setState({ textLabel: event.target.value })
                  }
                />
              </Field>
            </DialogContent>
            {this.renderOkCancel()}
          </Dialog>
        </div>
      )
    } else if (this.state.chartType == "info box") {
      return (
        <div>
          <Dialog
            open={this.state.openDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">{i18next.t('dashboardCanvas.addInfoBox')}</DialogTitle>
            <DialogContent>
              <Field label={i18next.t('dashboardCanvas.title')} dialog={true}>
                <input
                  className="form-control"
                  value={this.state.infoBoxTitle}
                  onChange={event =>
                    this.setState({ infoBoxTitle: event.target.value })
                  }
                />
              </Field>
              {this.renderSQLField()}
              {this.renderVariables()}
              <Field label={i18next.t('dashboardCanvas.metric')} dialog={true}>
                <select
                  className="form-control"
                  value={this.state.infoBoxMetric}
                  onChange={this.handleSelectMetric}
                >
                  <option value="" />
                  {this.state.cols.map((col, i) => {
                    return (
                      <option key={i} value={col}>
                        {col}
                      </option>
                    )
                  })}
                </select>
              </Field>
              <Field label={i18next.t('dashboardCanvas.icon')} dialog={true}>
                <div>
                  <Select
                    className="center"
                    value={this.state.icon}
                    onChange={this.handleSelectIcon}
                    autoWidth
                  >
                    {this.props.icons.map((icon, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={"/resources/images/workflow_icons/" + icon}
                          style={{ width: "100px", height: "100px" }}
                        >
                          <img
                            src={"/resources/images/workflow_icons/" + icon}
                            className="dashboardIcon"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
              </Field>
            </DialogContent>
            {this.renderOkCancel()}
          </Dialog>
        </div>
      )
    } else if (this.state.chartType == "data grid") {
      return (
        <div>
          <Dialog
            open={this.state.openDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">{i18next.t('dashboardCanvas.addTable')}</DialogTitle>
            <DialogContent>
              <Field label={i18next.t('dashboardCanvas.title')} dialog={true}>
                <input
                  className="form-control"
                  value={this.state.chartTitle}
                  onChange={event =>
                    this.setState({ chartTitle: event.target.value })
                  }
                />
              </Field>
              {this.renderSQLField()}
              {this.renderVariables()}
              <Field
                label={i18next.t('dashboardCanvas.pageSize')}
                dialog={true}
              >
                <input
                  className="form-control"
                  value={this.state.pageSize}
                  onChange={event =>
                    this.setState({
                      pageSize: event.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                />
              </Field>
              <Field
                label={i18next.t('dashboardCanvas.pinnedColumns')}
                dialog={true}
              >
                <input
                  className="form-control"
                  value={
                    this.state.numPinned == null ? "" : this.state.numPinned
                  }
                  onChange={event =>
                    this.setState({
                      numPinned: event.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                />
              </Field>
            </DialogContent>
            {this.renderOkCancel()}
          </Dialog>
        </div>
      )
    } else if (this.state.chartType == "chart annotations") {
      return (
        <div>
          <AddEditAnnotations
            open={this.state.openDialog}
            location="dashboard"
            location_id={this.props.dashboard_id}
            closeCallBack={() => this.setState({ openDialog: false })}
            updateCallBack={() => this.updateAnnotationCallBack()}
          />
        </div>
      )
    } else {
      const {columns, lines} = this.getCurrentColumnsAndLines()
      return (
        <div>
          <Dialog
            open={this.state.openDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">{i18next.t('dashboardCanvas.addChart')}</DialogTitle>
            <DialogContent>
              <Field label={i18next.t('dashboardCanvas.title')} dialog={true}>
                <input
                  className="form-control"
                  value={this.state.chartTitle}
                  onChange={event =>
                    this.setState({ chartTitle: event.target.value })
                  }
                />
              </Field>
              <Field label={i18next.t('dashboardCanvas.chartType')} dialog={true}>
                <select
                  className="form-control"
                  value={this.state.chartType}
                  onChange={event =>
                    this.setState({ chartType: event.target.value })
                  }
                >
                  <option value="bar">{i18next.t('dashboardCanvas.bar')}</option>
                  <option value="column">{i18next.t('dashboardCanvas.column')}</option>
                  <option value="combo_column_line">{i18next.t('dashboardCanvas.combo')}</option>
                  <option value="funnel">{i18next.t('dashboardCanvas.funnel')}</option>
                  <option value="geo">{i18next.t('dashboardCanvas.geo')}</option>
                  <option value="line">{i18next.t('dashboardCanvas.line')}</option>
                  <option value="pie">{i18next.t('dashboardCanvas.pie')}</option>
                  <option value="stacked_bar">{i18next.t('dashboardCanvas.stackedBar')}</option>
                  <option value="stacked_column">{i18next.t('dashboardCanvas.stackedColumn')}</option>
                </select>
              </Field>
              {this.renderSQLField()}
              {this.renderVariables()}
              {this.state.chartType == 'geo' && (
                <div style={{paddingLeft: 30, paddingRight: 30, clear: 'both'}}>
                  <div className="mt-25" style={{float: 'left', width: '10%'}}>
                    {i18next.t('dashboardCanvas.map')}
                  </div>
                  <div className="mt-25" style={{float: 'left', width: '45%'}}>
                    <select
                      style={{width: '100%'}}
                      className='form-control'
                      size={2}
                      value={this.state.map}
                      onChange={event => this.setState({map: event.target.value})}>
                      <option value='USA'>{i18next.t('dashboardCanvas.usa')}</option>
                      <option value='World'>{i18next.t('dashboardCanvas.world')}</option>
                    </select>
                  </div>
                  <div className="mt-25" style={{float: 'left', width: '10%', clear: 'both'}}>
                    {i18next.t('dashboardCanvas.nameAttribute')}
                  </div>
                  <div className="mt-25" style={{float: 'left', width: '45%'}}>
                    <select
                      style={{width: '100%'}} className='form-control' value={this.state.nameAttribute}
                      onChange={event => this.setState({nameAttribute: event.target.value})}>
                      {
                        this.state.cols.sort((a, b) => getName(a).localeCompare(getName(b))).map((column, j) => (
                          <option
                            key={j}
                            value={column}>
                            {getName(column)}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="mt-25" style={{float: 'left', width: '10%', clear: 'both'}}>
                    {i18next.t('dashboardCanvas.valueAttribute')}
                  </div>
                  <div className="mt-25" style={{float: 'left', width: '45%'}}>
                    <select
                      style={{width: '100%'}} className='form-control' value={this.state.valueAttribute}
                      onChange={event => this.setState({valueAttribute: event.target.value})}>
                      {
                        this.state.cols.sort((a, b) => getName(a).localeCompare(getName(b))).filter(a => a != 'location_name').map((column, j) => (
                            <option
                              key={j}
                              value={column}>
                              {getName(column)}
                            </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              )}
              {this.state.chartType != 'pie' && this.state.chartType != 'funnel' && this.state.chartType != 'geo' && (
                <div style={{paddingLeft: 30, paddingRight: 30, clear: 'both'}}>
                  {this.state.axes.map((axis, i) => (
                    <div key={i}>
                      {i != 0 && (
                        <div className="mt-25" style={{float: 'left', width: '10%'}}>
                          <button className="btn btn-primary" disabled={this.state.axes[i-1]['selectedColumns'].length == 0} onClick={() => this.moveColumns(this.state.axes[i-1], axis)} style={{marginLeft: 8, marginTop: 95}}>&gt;</button>
                          <button className="btn btn-primary" disabled={axis['selectedColumns'].length == 0} onClick={() => this.moveColumns(axis, this.state.axes[i-1])} style={{marginLeft: 8, marginTop: 5}}>&lt;</button>
                        </div>
                      )}
                      <div className="mt-25" style={{float: 'left', width: '45%'}}>
                        <div style={{paddingLeft: 10}}>{i18next.t('dashboardCanvas.axis', {id: i + 1})}</div>
                        <div>
                          <input
                            className="form-control"
                            value={axis.title}
                            onChange={event => this.updateTitle(axis, event.target.value)}
                          />
                        </div>
                        <div style={{paddingLeft: 10, paddingTop: 5}}>{i18next.t('dashboardCanvas.axisColumns', {id: i + 1})}</div>
                        <select
                          style={{width: '100%'}}
                          className='form-control'
                          size={5}
                          value={axis.selectedColumns} multiple={true}
                          onChange={event => this.updateSelected(axis, Array.from(event.target.selectedOptions).map(option => option.value))}>
                          {
                            this.getColumns(axis).map((column, j) => 
                              <option
                                key={j}
                                value={column}>
                                {column == 'dashboardCanvas.otherColumns' ? i18next.t(column) : getName(column)}
                              </option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {this.state.chartType == 'combo_column_line' && (
                <>
                  <div style={{paddingLeft: 30, paddingRight: 30, clear: 'both'}}>
                    <div className="mt-25" style={{float: 'left', width: '45%'}}>
                      <div style={{paddingLeft: 10}}>{i18next.t('dashboardCanvas.columns')}</div>
                      <select
                        style={{width: '100%'}}
                        className='form-control'
                        size={5}
                        value={this.state.selectedColumns} multiple={true}
                        onChange={event => this.setState({selectedColumns: Array.from(event.target.selectedOptions).map(option => option.value)})}>
                        {
                          columns.map((column, i) => 
                            <option
                              key={i}
                              value={column}>
                              {column == 'dashboardCanvas.otherColumns' ? i18next.t(column) : column}
                            </option>
                          )
                        }
                      </select>
                    </div>
                    <div className="mt-25" style={{float: 'left', width: '10%'}}>
                      <button className="btn btn-primary" disabled={this.state.selectedColumns.length == 0} onClick={() => this.addLines()} style={{marginLeft: 8, marginTop: 35}}>&gt;</button>
                      <button className="btn btn-primary" disabled={this.state.selectedLines.length == 0} onClick={() => this.addColumns()} style={{marginLeft: 8, marginTop: 5}}>&lt;</button>
                    </div>
                    <div className="mt-25" style={{float: 'left', width: '45%'}}>
                      <div style={{paddingLeft: 10}}>{i18next.t('dashboardCanvas.lines')}</div>
                      <select
                        style={{width: '100%'}}
                        className='form-control'
                        size={5}
                        value={this.state.selectedLines} multiple={true}
                        onChange={event => this.setState({selectedLines: Array.from(event.target.selectedOptions).map(option => option.value)})}>
                        {
                          lines.map(line => 
                            <option
                              key={line}
                              value={line}>
                              {i18next.t(line)}
                            </option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                </>
              )}
            </DialogContent>
            {this.renderOkCancel()}
          </Dialog>
        </div>
      )
    }
  }
  
  newState() {
    return {
        custom_variables: [],
        custom_variable_values: null,
        chartTitle: "",
        pageSize: "",
        nameAttribute: 'location_name',
        valueAttribute: 'value',
        axes: [{title: '', selectedColumns: [], columns: ['dashboardCanvas.otherColumns']}, {title: "", selectedColumns: [], columns: []}],
        sqlId: "",
        infoBoxMetric: "",
        textLabel: "",
        infoBoxTitle: "",
        icon: "",
        cols: [],
        numPinned: "",
        columns: ['dashboardCanvas.otherColumns'],
        lines: [],
        map: 'USA'
    }
  }

  openDialog(card_type, action = "add") {
    if (action == "add") {
      this.setState(this.newState())
    }
    this.setState({
      openDialog: true,
      openDialogAction: action,
      chartType: card_type,
    })
  }

  render() {
    return (
        <Form
            parentLink={() => {window.location.href = '/#/dashboards'; window.location.reload()}}
            canEdit={false}
            canDelete={false}
        >
          <br />
          {this.generateButton()}
          {this.state.annotations && this.generateCards()}
          {this.generateDialog()}
        </Form>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    current_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default connect(mapStateToProps)(DashboardCanvas)
