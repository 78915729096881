import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { addEditPipeline, getIcons } from "../../api/workflows"
import { getPipeline, deletePipeline } from "../../api/workflows"
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Form from '../Form'
import Field from '../Field'
import FTNotice from '../FTNotice'
import DeleteButton from '../DeleteButton'
import i18next from 'i18next'

class AddEditWorkflow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      internal_client_id: props.isAdmin ? '' : props.current_client_id,
      icon: '',
      publish_to: '',
      icons: null,
      workflow_id: this.props.match ? this.props.match.params.workflow_id : this.props.workflow_id
    }
    this.saveData = this.saveData.bind(this)
    this.deletePipeline = this.deletePipeline.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    let workflow_id = this.state.workflow_id
    getIcons().then(response => {
      this.setState({ icons: response.data })
    })
    if (workflow_id != 'new')
      getPipeline(workflow_id).then(response => {
        this.setState({
          name: response.data.name,
          description: response.data.description,
          icon: response.data.icon,
          internal_client_id: response.data.internal_client_id != null && response.data.internal_client_id != undefined ? response.data.internal_client_id : ''
        })
      })
  }

  saveData() {
    if (this.state.name.trim() == '') {
      FTNotice('workflow.enterName', 3000)
    } else {
      const data = {
        id: this.state.workflow_id,
        name: this.state.name,
        description: this.state.description,
        icon: this.state.icon,
        publish_to: this.state.publish_to,
        internal_client_id: this.state.internal_client_id,
      }
      addEditPipeline(data).then(response => {
        if (!response.success) {
          FTNotice(i18next.t('saveFailed', {error: response.error}), 3000)
        } else {
          if (this.state.workflow_id == 'new') {
            this.props.history('/workflows/notebook/' + response.id + '/steps')
          } else {
            this.props.updateMode('run')
          }
        }
      })
    }
  }

  deletePipeline() {
    deletePipeline(this.state.workflow_id).then((result) => {
      if (result.success) {
        window.location.href = '/#/workflows/all'
      }
    })
  }

  renderSelectClient() {
    if (this.props.clients && this.props.isAdmin)
      return (
        <Field label="workflow.client">
          <select className="form-control" value={this.state.internal_client_id} onChange={event => this.setState({ internal_client_id: event.target.value })}>
            <option value={''}>{i18next.t('workflow.all')}</option>
            {this.props.clients.map((client, i) => (
              <option key={i} value={client.internal_client_id}>{client.name}</option>
            ))}
          </select>
        </Field>
      )
  }

  renderIcons() {
    if (this.state.icons)
      return (
        <Field label="workflow.icon">
          <Select
            value={this.state.icon} onChange={event => this.setState({ icon: event.target.value })}
            className="form-control" autoWidth style={{ width: '160px', height: '120px' }}
          >
            {this.state.icons.map((icon, i) => {
              var img_url = '/resources/images/workflow_icons/' + icon
              return (
                <MenuItem key={i} value={icon} style={{ width: '120px', height: '120px' }}>
                  <img src={img_url} height="100" width="100" />
                </MenuItem>
              )
            })}
          </Select>
        </Field>
      )
  }

  renderContent() {
    return (
      <div>
        <Field label="workflow.name">
          <input type="text"
            value={this.state.name}
            onChange={event => this.setState({ name: event.target.value })}
            className="form-control" />
        </Field>
        <Field label="workflow.description">
          <textarea
            rows="5" value={this.state.description}
            onChange={event => this.setState({ description: event.target.value })}
            className="form-control" />
        </Field>
        {this.renderSelectClient()}
        {this.renderIcons()}
      </div>
    )
  }

  render() {
    if (this.state.icons) {
      if (this.props.hideHeader) {
        return (
          <div className="row">
            {this.renderContent()}
            <div className="col-md-12 mt-25 text-center">
              <button name="edit" className="btn btn-primary" onClick={this.saveData}>
                {i18next.t(this.state.workflow_id == 'new' ? 'workflow.addWorkflow' : 'workflow.updateWorkflow')}
              </button>
              <DeleteButton
                objectType="workflow.workflow"
                objectName={this.state.name}
                canDelete={this.state.workflow_id != 'new'}
                deleteObject={this.deletePipeline} />
            </div>
          </div>
        )
      } else {
        return (
          <Form
            objectType="workflow.workflow"
            objectName={this.state.name}
            newObject={this.state.workflow_id == 'new'}
            parentLink={'/workflows/all'}
            updateObject={this.saveData}
            deleteObject={this.deletePipeline}
          >
            {this.renderContent()}
          </Form>
        )
      }
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    current_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    clients: state.users.user ? state.users.user.clients : [],
    timeZone: state.users.user ? state.users.user.client.time_zone : 'America/Los_Angeles',
  }
}

export default withRouter(connect(mapStateToProps)(AddEditWorkflow))
