import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { connect } from 'react-redux'
import { getServices } from '../../api/services'
import i18next from 'i18next'

class NewService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceIndex: 0,
    }
    this.getServices()
  }

  getServices() {
    getServices(this.props.internal_client_id, null, true).then(services => {
      const promises = []
      services.forEach(service => {
        promises.push(import('./' + service.service_type + '/AddEdit.jsx'))
      })
      Promise.all(promises).then(modules => {
        const tab = this.props.match.params.tab
        let index = this.state.serviceIndex
        for (let i = 0; i < modules.length; i++) {
          services[i].configure = modules[i].default
          if (services[i].service_type == tab) {
            index = i
          }
        }
        this.setState({services: services, serviceIndex: index})
      })
    })
  }

  updateService(index) {
    this.props.history('/services/new/' + this.state.services[index].service_type)
    this.setState({serviceIndex: index})
  }

  toHomePage() {
    this.props.history('/services')
  }

  render() {
    if (this.state.services) {
      return (
        <div className='site-canvas'>
          <section className='content'>
            <div>
              <Tabs selectedIndex={this.state.serviceIndex} onSelect={serviceIndex => this.updateService(serviceIndex)}>
                <TabList>
                  {this.state.services.map((service, i) => {
                    return (
                      <Tab key={i}>
                        {i18next.t('services.' + service.service_type + '.name')}
                      </Tab>
                    )
                  })}
                </TabList>
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-info">
                      {this.state.services.map((service, i) => {
                        const Configure = service.configure
                        return (
                          <TabPanel key={i}>
                            <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>{i18next.t('services.' + service.service_type + '.description')}</div>
                            <Configure id="new" callback={() => this.toHomePage()} />
                          </TabPanel>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </section>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(NewService))
