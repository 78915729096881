import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Table from '../widgets/Table'
import Title from '../Title'
import DatePicker from '../widgets/DatePicker'
import ComparisonChartWithSelectors from "../charts/ComparisonChartWithSelectors"
import { getChangeHistory, updateChangeHistoryComment } from '../../api/change_history'
import { getSummary } from '../../api/ads'
import i18next from 'i18next'

class Interface extends Component {
  tableRef = createRef()
  
  constructor(props) {
    super(props)
    this.state = {
      from_date: moment().subtract(28, 'day').format('YYYY-MM-DD'),
      to_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      engine1: 'all',
      engine2: 'all',
      annotations: [],
      summary: null,
      dataset: null,
      summary2: null,
      dataset2: null,
      editComment: null,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    const params = {
      internal_client_id: this.props.internal_client_id,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
    }
    const params1 = Object.assign({}, params)
    if (this.state.engine1 != "all") {
      params1["engines"] = this.state.engine1
    }
    getSummary(params1).then((response) => {
      if (this.state.engine1 == this.state.engine2) {
        this.setState({ summary: response.summary, dataset: response.results, summary2: null, dataset2: null })
      } else {
        const params2 = Object.assign({}, params)
        if (this.state.engine2 != "all") {
          params2["engines"] = this.state.engine2
        }
        getSummary(params2).then((response2) => {
          this.setState({ summary: response.summary, dataset: response.results, summary2: response2.summary, dataset2: response2.results })
        })
      }
    })
  }

  getColumns() {
    return [{
      id: 'date',
      header: 'changeHistory.date',
      datatype: 'date',
      width: 100,
    },{
      id: 'source',
      header: 'changeHistory.source',
      datatype: 'text',
      width: 150,
    },{
      id: 'change_type',
      header: 'changeHistory.changeType',
      datatype: 'text',
      width: 150,
    },{
      id: 'run_id',
      header: 'changeHistory.runId',
      datatype: 'text',
      width: 300,
      cellRenderer: (value, row) => (
        <>
          {row.url == null && (
            <>{value}</>
          )}
          {row.url != null && (
            <Link to={row.url}>{value}</Link>
          )}
        </>
      )
    },{
      id: 'count_of_records',
      header: 'changeHistory.count',
      datatype: 'numeric',
      width: 100,
    },{
      id: 'comment',
      header: 'changeHistory.comment',
      datatype: 'text',
      width: 600,
      cellRenderer: (value, row) => (
        <a style={{cursor: 'pointer'}} onClick={() => this.setState({editComment: {row, comment: row.comment || ''}})}>
          {(value == null ? i18next.t('changeHistory.add') : value).split('\n').map((text, i) => (
            <div key={i}>{text}</div>
          ))}
        </a>
      )
    }]
  }
  
  updateComment() {
    const { row, comment } = this.state.editComment
    const object = Object.assign({}, row, {comment})
    updateChangeHistoryComment(object).then(() => {
      row.comment = comment
      this.tableRef.current.refreshCells()
      this.cancelComment()
    })
  }
  
  cancelComment() {
    this.setState({editComment: null})
  }

  renderInputDialog() {
    return (
      <Dialog
        open={this.state.editComment != null}
        onClose={() => this.cancelComment()}
        maxWidth="md"
      >
        <DialogTitle>
          <b>{i18next.t('changeHistory.editComment')}</b>
        </DialogTitle>
        <DialogContent dividers>
          {this.state.editComment && (
            <textarea className="form-control" style={{width: 600, height: 200}}
              value={this.state.editComment.comment}
              onChange={event => this.setState({editComment: Object.assign({}, this.state.editComment, {comment: event.target.value})})} />
          )}
        </DialogContent>
        <div className="text-center" style={{marginTop: 20, marginBottom: 20}}>
          <button className="btn btn-primary" onClick={() => this.updateComment()}>{i18next.t('changeHistory.ok')}</button>
          <button className="btn btn-primary" onClick={() => this.cancelComment()} style={{marginLeft: 10}}>{i18next.t('changeHistory.cancel')}</button>
        </div>
      </Dialog>
    )
  }

  updateDates(dates) {
    this.setState({
      from_date: dates.start,
      to_date: dates.end
    }, this.getData())
  }

  fetchData(tableData) {
    const promise = getChangeHistory(this.props.internal_client_id, this.state.from_date, this.state.to_date, tableData)
    promise.then(response => {
      const annotations = []
      response.results.forEach(row => {
        if (row.source != 'Google Change Log') {
          const annotation = {
            x_value: row.date,
            text: row.change_type + '\n' + row.count_of_records
          }
          annotations.push(annotation)
        }
      })
      this.setState({annotations: annotations})
    })
    return promise
  }

  render() {
    return(
      <div className='site-wrapper-offcanvas' id='chart-hudd'>
        <div className='site-canvas' style={{height: this.props.dashboard ? null : 940}}>
          <section className='content'>
            <Title
                menu="Change History"
                item=''
                link="/change_history" />
            <div className='section'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='box'>
                    <div className='box-header with-border'>
                      <div id='root'>
                        <div className="page-nav" style={{position: 'relative'}}>
                          <div className="btn-group" style={{width: '100%'}}>
                            <DatePicker
                              start={this.state.from_date}
                              end={this.state.to_date}
                              showCompare={false}
                              callback={dates => this.updateDates(dates)}
                            />
                          </div>
                        </div>
                        {this.renderInputDialog()}
                        <div style={{ width: 1400, maxWidth: '100%', margin: 'auto', marginTop: 10 }} >
                          <Table
                            ref={this.tableRef}
                            fetch={tableData => this.fetchData(tableData)}
                            columns={this.getColumns()} />
                        </div>
                         <div
                          className="text-center"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          <ComparisonChartWithSelectors
                            fromDate1={this.state.from_date}
                            toDate1={this.state.to_date}
                            summary1={this.state.summary}
                            dataset1={this.state.dataset}
                            summary2={this.state.summary2 ? this.state.summary2 : this.state.summary}
                            dataset2={this.state.dataset2 ? this.state.dataset2 : this.state.dataset}
                            annotations={this.state.annotations}
                            callback={values => (this.state.engine1 != values.engine1 || this.state.engine2 != values.engine2) && this.setState({engine1: values.engine1, engine2: values.engine2}, () => this.getData())}
                            maxHeight={400}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(Interface)
