import { getHistoricalFiles} from '../../api/feeds';
import { updateState } from '../../utilities/feed_utils';
import { downloadFile } from '../../utilities/utils';
import EditSteps from './EditSteps';
import Field from '../Field';
import FTNotice from '../FTNotice';
import Confirm from '../Confirm';
import { filterInteger } from '../../utilities/utils';
import { filterFloat } from '../../utilities/utils';
import SQLEditor from '../SQLEditor';


export default class SubmitFeed extends EditSteps {
  constructor(props) {
    super(props);
    this.state.links = [];
    this.state.showConfirm = false;
  }

  getTable() {
    return 'submit';
  }

  getAdditionalData() {
    this.getHistoricalFiles();
  }

  getHistoricalFiles() {
    let s3_url = this.state.location;
    const index = s3_url.indexOf('*');
    if (index >= 0) { // s3 matching has to end in *
      s3_url = s3_url.substring(0, index + 1);
    }
    getHistoricalFiles({
      action: 'list',
      url: s3_url,
      username: '',
      password: '',
    }).then((result) => {
      const error = 'Could not find files';
      if (result) {
        const links = result.files ? result.files : [];
        this.setState({links: links, link_error: result.error || links.length ? result.error : error});
      } else {
        this.setState({links: [], link_error: error});
      }
    })
  }

  getHistoricalFile(url) {
    getHistoricalFiles({
      action: 'get',
      url: url,
      username: '',
      password: '',
    }).then((result) => {
      if (result.url) {
        downloadFile(result.url);
      }
      if (result.error) {
        FTNotice(result.error, 15000);
      }
    })
  }

  getColumns() {
    return [{
      id: 'step_order',
      header: 'Order',
      rowDrag: true,
      width: 100,
    },{
      id: 'consumer',
      header: 'Consumer',
      cellRenderer: (value, row) => {
        return (
          <a onClick={() => {this.editStep(row.id)}} style={{cursor: 'pointer'}}>{value}</a>
        )
      },
      width: 300,
    },{
      id: 'location',
      header: 'URL',
      width: 300,
    },{
      id: 'output_format',
      header: 'File Format',
      width: 100,
    },{
      id: 'user_name',
      header: 'Last Modified By',
      width: 300,
    }];
  }

  prepareData(data) {
    data.user_id = this.props.user_id;
    if (data.max_percentage != null && data.max_percentage.trim() == '') {
      data.max_percentage = null;
    }
    const trimmed = data.submit_sql.trim();
    if (trimmed.endsWith(';')) {
      data.submit_sql = trimmed.substring(0, trimmed.length - 1);
    }
    delete data.links;
    delete data.link_error;
    delete data.showConfirm;
  }

  newStep() {
    this.setState({editView: true, id: null, consumer: '', location: '', output_format: 'CSV', submit_sql: '', database: 'redshift', min_rows: 0, min_percentage: 0, max_percentage: '', trigger_sql: ''});
  }

  validateFields() {
    if (this.state.consumer.trim() == '') {
      FTNotice('Please enter a consumer', 3000);
    } else {
      return true;
    }
    return false;
  }

  getSQL() {
    return this.state.submit_sql;
  }

  startUpdate() {
    if (this.state.submit_sql.indexOf('{internal_client_id}') >= 0) {
      this.finishUpdate();
    } else {
      this.showConfirm();
    }
  }

  showConfirm() {
    this.setState({showConfirm: true});
  }

  confirmSave(confirm) {
    this.setState({showConfirm: false});
    if (confirm) {
      this.finishUpdate();
    }
  }

  renderFields() {
    return (
      <div>
        <Confirm opened={this.state.showConfirm} title="Are You Sure?" question={'{internal_client_id} was not used in the SQL.  Are you sure you want to proceed?'}
            action={(confirmed) => {this.confirmSave(confirmed)}} button="Save"/>
        <Field label="Consumer (ex., Google or Client)">
          <input type="text" className="form-control" value={this.state.consumer} onChange={() => updateState('consumer', event.target.value, this)} />
        </Field>
        <Field label="URL (ex. ftp://username:password@hostname/file or s3://bucket/file)">
          <input type="text" className="form-control" value={this.state.location} onBlur={() => this.getHistoricalFiles()} onChange={() => updateState('location', event.target.value, this)} />
        </Field>
        <Field label="Last Submitted Files">
          <div>
            {this.state.link_error && (
              <div style={{marginLeft: '10px', color: 'red'}}>{this.state.link_error}</div>
            )}
            <table style={{marginLeft: '10px'}}>
              <tbody>
                {this.state.links.map((link, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <a onClick={() => this.getHistoricalFile(link.name)}>{link.name}</a>
                      </td>
                      <td style={{paddingLeft: 10}}>{link.date ? moment(link.date + '+00:00', moment.ISO_8601).format('M/D/YYYY h:mm a') : ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Field>
        <Field label="File Format">
          <select className="form-control" value={this.state.output_format} onChange={() => updateState('output_format', event.target.value, this)}>
            <option value="CSV">CSV</option>
            <option value="TAB">Tab delimited</option>
          </select>
        </Field>
        <Field label="SQL" >
          <SQLEditor
            code={this.state.submit_sql}
            onChange={(sql) => updateState('submit_sql', sql, this)}
          />
        </Field>
        <Field label="Minimum Rows (feed fails if it produces less than this; set to 0 to ignore)">
          <input className="form-control" value={this.state.min_rows} onChange={() => updateState('min_rows', event.target.value, this)}
            onKeyUp={event => filterInteger(event)}/>
        </Field>
        <Field label="Minimum Percentage (feed fails if it produces less than this relative to last successful run; set to 0 to ignore)">
          <input className="form-control" value={this.state.min_percentage} onChange={() => updateState('min_percentage', event.target.value, this)}
            onKeyUp={event => filterFloat(event)} />
        </Field>
        <Field label="Maximum Percentage (feed fails if it produces more than this relative to last successful run; leave blank to ignore)">
          <input className="form-control" value={this.state.max_percentage || ''} onChange={() => updateState('max_percentage', event.target.value, this)}
            onKeyUp={event => filterFloat(event)} />
        </Field>
        <Field label="Trigger DAG">
          <select value={this.state.dag ? this.state.dag : ''} onChange={() => {this.setState({dag: event.target.value})}} className="form-control">
            <option value=''>None</option>
            {this.state.triggerable_dags && this.state.triggerable_dags.map((dag) => {
              return(
                <option value={dag.dag_id} key={dag.dag_id} >{dag.dag_id.replaceAll('_',' ')}</option>
              )
            })}
          </select>
        </Field>
        {this.state.dag != '' && (
          <Field label="Trigger SQL (will only trigger DAG if this returns one or more run ids)">
            <SQLEditor
              code={this.state.trigger_sql}
              onChange={(sql) => updateState('trigger_sql', sql, this)}
            />
          </Field>
        )}
      </div>
    );
  }
}
