import { Component, createRef }from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { downloadBudget, uploadBudget, getUnmappedCampaignSpend, getBudgetGoals, updateBudgetGoal, deleteBudgetGoal} from '../../api/budget'
import { getClient, patchClient } from '../../api/clients'
import { getColumns } from '../../api/database'
import querystring from 'querystring'
import FTNotice from '../FTNotice'
import AddRow from '../AddRow'
import Form from '../Form'
import Field from '../Field'
import SQLEditor from '../SQLEditor'
import Table from '../widgets/Table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DeleteButton from '../DeleteButton'
import { filterFloat } from '../../utilities/utils'
import { formatPercent, formatCurrency } from '../../utilities/formatting'

class Interface extends Component {
  TABS = [{id: 'manage_budgets', name: 'Manage Budgets'}, {id: 'unmapped_campaigns' , name: 'Unmapped Campaigns'}];
  MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  BIDDING_GOAL_COLUMNS = [{
    column: 'subbudget',
  }, {
    column: 'roas_goal',
    required: true,
    type: 'float',
  }, {
    column: 'kpi',
    required: true,
    values: [{id: 'budget', name: 'budget'}, {id: 'roas', name: 'roas'}],
  }]
  
  goalTableRef = createRef()
  unmappedTableRef = createRef()

  constructor(props) {
    super(props)
    const values = querystring.parse(this.props.location.search ? this.props.location.search.substring(1) : '')
    this.state = {
      tabIndex: 0,
      month: values.month ? new Date(values.month.split('-')[0], values.month.split('-')[1] - 1, 1): new Date(),
      showMonths: false,
      budget_sql: null,
    }
    if (props.match.params.tab_id) {
      for (let i = 0; i < this.TABS.length; i++) {
        if (this.TABS[i].id == props.match.params.tab_id) {
          this.state.tabIndex = i
        }
      }
    }
  }

  componentDidMount() {
    this.getBudgetSql()
  }

  updateTab(index) {
    let url = '/budget_admin/' + this.TABS[index].id
    if (index == 0) {
      url += '?month=' + this.state.month.getFullYear() + '-' + (this.state.month.getMonth() + 1)
    }
    this.props.history(url)
    this.setState({tabIndex: index}, () => {
      if (index == 1 && this.unmappedTableRef.current) {
        this.unmappedTableRef.current.refreshRows()
      }
    })
  }

  getBudgetGoals() {
    getBudgetGoals(this.props.internal_client_id).then((client) => {
      if (client != null) {
        this.setState({budget_sql: client.budget_sql})
      } else {
        FTNotice('Could not get sub-budgets', 15000)
      }
    })
  }

  getBudgetSql() {
    getClient(this.props.internal_client_id).then((client) => {
      if (client != null) {
        this.setState({budget_sql: client.budget_sql})
      } else {
        FTNotice('Could not get sub-budgets', 15000)
      }
    })
  }

  updateBudgetSql() {
    const desiredColumns = ['ad_group_id', 'criteria_id', 'subbudget']
    if (this.state.budget_sql) {
      getColumns({'sql': this.state.budget_sql.replace(/\{internal_client_id\}/g, this.props.internal_client_id)}).then((response) => {
        if (response.data && _.isEqual(response.data, desiredColumns)) {
          this.saveBudgetSql()
        } else {
          FTNotice('Your Query must return ' + desiredColumns.join(', ') + ' in order.', 15000)
        }
      })
    } else {
      this.saveBudgetSql()
    }
  }

  saveBudgetSql() {
    patchClient({internal_client_id: this.props.internal_client_id, budget_sql: this.state.budget_sql}).then((result) => {
      if (result == null) {
        FTNotice('Could not save sub-budgets', 15000)
      } else {
        FTNotice('The sub-budgets have been updated.  Note: it can take a few minutes before you will see updated data in the application.')
      }
    })
  }

  updateMonth(month) {
    this.setState({month: month.id}, () => this.updateTab(0))
  }

  downloadBudget() {
    const month = this.state.month.getFullYear() + '-' + (this.state.month.getMonth() + 1)
    downloadBudget(this.props.internal_client_id, month).then((result) => {
      if (result.url) {
        const link = document.createElement('a')
        link.href = result.url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        FTNotice(result.error ? result.error : 'Could not download budget.', 15000)
      }
    })
  }

  uploadBudget() {
    this.fileInput.click()
  }

  selectFile() {
    if (event.target.files.length) {
      const formData = new FormData()
      formData.append('file', event.target.files[0])
      this.fileInput.value = '' // Reset so that the same file can be uploaded again
      uploadBudget(this.props.internal_client_id, formData).then((result) => {
        if (!result.success) {
          FTNotice(result.error ? result.error : 'Could not upload budget.', 15000);
        }
      })
    }
  }

  createRow() {
    this.setState({showCreate: false})
    this.refreshRows()
  }

  updateRow(newState) {
    const element = this.__element
    const row = this.state.row
    const column = this.state.column
    if (element && row && column) {
      const newValue = element.value
      if (newValue != row[column]) {
        const data = {}
        data[column] = column == 'subbudget' && newValue.trim() == '' ? null : newValue
        updateBudgetGoal(row.id, data).then((response) => {
          if (response.error) {
            FTNotice(response.error, 15000);
          } else {
            row[column] = newValue;
            this.setState(newState, () => this.goalTableRef.current.refreshCells())
          }
        })
      } else {
        this.setState(newState, () => this.goalTableRef.current.refreshCells())
      }
    }
    if (newState.row == null) {
      this.__element = null
    }
  }

  deleteRow(row) {
    deleteBudgetGoal(row.id).then((response) => {
      if (!response.successful) {
        FTNotice('Could not delete budgetary goal', 15000)
      } else {
        this.refreshRows()
      }
    })
  }

  refreshRows() {
    if (this.goalTableRef.current) {
      this.goalTableRef.current.refreshRows()
    }
  }

  updateElement(element, shouldSelect = true) {
    if (element) {
      setTimeout(() => {
        element.focus()
        if (shouldSelect) {
          element.select()
        }
      }, 0);
      this.__element = element
    }
  }

  handleKeyDown(event) {
    if (event.key == 'Enter' || event.key == 'Tab') {
      this.updateRow({row: null, column: null})
    } else if (event.key == 'Escape') {
      this.setState({row: null, column: null}, () => this.goalTableRef.current.refreshCells())
    }
  }

  getGoalColumns() {
    return [{
        id: 'subbudget',
        datatype: 'text',
        cellRenderer: (value, row) => this.getEditor(row, 'subbudget', value, (
          <input className="form-control" defaultValue={value} ref={element => this.updateElement(element)} onKeyDown={event => this.handleKeyDown(event)}/>
        )),
      }, {
        id: 'roas_goal',
        datatype: 'numeric',
        formatter: value => formatPercent(value),
        cellRenderer: (value, row) => this.getEditor(row, 'roas_goal', value, (
          <input className="form-control" defaultValue={value} ref={element => this.updateElement(element)} onKeyUp={event => filterFloat(event)} onKeyDown={event => this.handleKeyDown(event)}/>
        )),
      }, {
        id: 'kpi',
        datatype: 'text',
        cellRenderer: (value, row) => this.getEditor(row, 'kpi', value, (
          <select className="form-control" value={value == null ? 'budget': value} ref={element => this.updateElement(element, false)} onChange={() => this.updateRow({row: null, column: null})}>
              <option value="budget">budget</option>
              <option value="roas">roas</option>
          </select>
        )),
      }, {
        cellRenderer: (_, row) => ( 
          <DeleteButton
            objectType=""
            objectName="this row"
            deleteObject={() => this.deleteRow(row)}/>
        ),
        width:100,
      }
    ]
  }

  getEditor(row, column, value, editor) {
    if (this.state.row == row && this.state.column == column) {
      return editor
    } else {
      return (
        <div style={{minHeight: 35, minWidth: 95, textAlign: 'center', marginTop: 15}} onClick={() => {
          if (this.state.row != null) {
            this.updateRow({row: row, column: column})
          } else {
            this.setState({row: row, column: column}, () => this.goalTableRef.current.refreshCells())
          }
        }}>
          {value}
        </div>
      )
    }
  }

  getUnmappedColumns() {
    return [{
        id: 'campaign_name',
        datatype: 'text',
        width: 400,
      }, {
        id: 'spend_last_30',
        datatype: 'numeric',
        format: value => formatCurrency(value)
      },
    ]
  }

  render() {
    const months = []
    for (let i = -6; i <= 6; i++) {
      const date = new Date()
      date.setDate(1); // Not all months have a 31st; so go with a date that all months have
      const monthDate = new Date(date.setMonth(date.getMonth() + i))
      months.push({
        id: monthDate,
        name: this.MONTH_NAMES[monthDate.getMonth()] + ' ' + monthDate.getFullYear(),
      })
    }
    return (
      <div className='site-wrapper-offcanvas' id='budget-pacing'>
        <div className='site-canvas js_complete'>
          <div style={{overflow: 'auto', minHeight: 'calc(100vh - 101px)'}}>
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
              <TabList>
                {this.TABS.map((tab) => {
                  return (
                    <Tab key={tab.id}>
                      {tab.name}
                    </Tab>
                  )
                })}
              </TabList>
              <TabPanel key='manage_budgets'>
                <div className="page-nav" style={{position: 'relative', margin: 15}}>
                  <div className="btn-group" style={{width: '100%'}}>
                    <div className="btn btn-default bg-purple" style={{cursor: 'normal', width: 120}}>
                      <span onClick={() => {this.setState({showMonths: !this.state.showMonths})}}>
                        <span>{this.MONTH_NAMES[this.state.month.getMonth()] + ' ' + this.state.month.getFullYear()}</span>
                        {this.state.showMonths && (
                          <div style={{position: 'absolute', backgroundColor: 'white', border: '1px solid black', width: '100%', left: 0, top: 32, zIndex: 10}}>
                            {months.map((month, i) => {
                              return (
                                <option onClick={() => {this.updateMonth(month)}}
                                  key={i} style={{color: 'black', padding: 5}}>{month.name}</option>
                              );
                            })}
                          </div>
                        )}
                      </span>
                    </div>
                    <div title="Download Budget" onClick={() => this.downloadBudget()} style={{textAlign: 'center', display: 'inline-block', marginLeft: 10, backgroundColor: 'white', border: '1px solid black', padding: '0px 5px 0px 5px', width: 55, cursor: 'pointer'}}><i className="fa fa-download"></i><div style={{fontSize: 10, position: 'relative', top: -2}}>Download</div></div>
                    <div title="Upload Budget" onClick={() => this.uploadBudget()} style={{textAlign: 'center', display: 'inline-block', marginLeft: 10, backgroundColor: 'white', border: '1px solid black', padding: '0px 5px 0px 5px', width: 55, cursor: 'pointer'}}><i className="fa fa-upload"></i><div style={{fontSize: 10, position: 'relative', top: -2}}>Upload</div></div>
                  </div>
                </div>
                <Form
                  objectType="Sub-budget Criteria"
                  objectName=""
                  newObject={false}
                  updateObject={() => this.updateBudgetSql()}
                  canDelete={false}
                  parentLink={null}
                >
                  <Field label="Budgetary Goals">
                    <div style={{float: 'right'}}>
                      <a className="btn btn-box-tool text-green" style={{paddingTop: 0}} onClick={(event) => {event.preventDefault(); this.setState({showCreate: true});}}>
                        <i className="fa fa-plus"></i>
                        <span>Add Goal</span>
                      </a>
                      { this.state.showCreate && (
                        <AddRow title="Add Budgetary Goal" table="bidding.bidding_goal" primaryKeys={['id']} columns={this.BIDDING_GOAL_COLUMNS} onClose={() => this.createRow()}
                          defaultValues={{internal_client_id: this.props.internal_client_id, channel: 'sem'}}/>
                      )}
                    </div>
                    <Table 
                      ref={this.goalTableRef}
                      fetch={tableData => getBudgetGoals(this.props.internal_client_id, tableData)}
                      columns={this.getGoalColumns()}
                      autoHeight={true}
                      rowHeight={40}
                      hidePagination={true} />
                  </Field>
                  <Field label="Sub-budgets Criteria (should return ad_group_id, criteria_id, subbudget)">
                    <SQLEditor
                      code={this.state.budget_sql == null ? '' : this.state.budget_sql}
                      onChange={(sql) => this.setState({budget_sql: sql.trim() == '' ? null : sql})}
                      width="100%"
                    />
                  </Field>
                </Form>
              </TabPanel>
              <TabPanel key='unmapped_campaigns'>
                <Table 
                  ref={this.unmappedTableRef}
                  fetch={tableData => getUnmappedCampaignSpend(this.props.internal_client_id, tableData)}
                  columns={this.getUnmappedColumns()} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <input ref={input => this.fileInput = input} style={{display: 'none'}} type="file" onChange={() => this.selectFile()} /> 
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
