import React, { useState } from 'react'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import Page from '../widgets/Page'
import Stack from '../widgets/Stack'
import Section from '../widgets/Section'
import Wrench from '../widgets/Wrench'
import Configuration from './Configuration'
import ClientChart from './ClientChart'
import Pacing from '../pacing/Pacing'

const AllClients = ({allClients, shownClientIds}) => {
  const getShownClients = () => shownClientIds.length == 0 ? allClients : allClients.filter(c => shownClientIds.includes(c.internal_client_id))
  const [clients, setClients] = useState(getShownClients())
  
  return (
    <Page>
      <Configuration clients={clients} setClients={setClients} />
      <Stack direction="row">
        <Wrench />
      </Stack>
      {clients.filter(c => c.active).map((client, i) =>
        <Section key={i} name={client.name}>
          <Pacing internal_client_id={client.internal_client_id} showTotals={true}/>
          <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <ClientChart internal_client_id={client.internal_client_id} />
          </Box>
        </Section>
      )}
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    allClients: state.users.user ? state.users.user.clients : [],
    shownClientIds: state.users.user ? state.users.user.client_ids_to_show : [],
  }
}

export default connect(mapStateToProps)(AllClients)
