import { Component, createRef } from 'react'
import Table from './Table'

export default class OrderedList extends Component {
  /*
    Display an ordered list of items and allow them to be edited / added to.
    objectType - The type of object being edited (ex., 'Link')
    items - list of items to be shown.
    columns - see Table for options.  Column used for ordering should be first, header should be Order and rowDrag should be true.
    addItem - method to be called on adding an item (function ())
    updateItemOrder - method to be called when updating item order (function (item, order))
    formatted - if true, allow adding items and show other formatting.
  */

  static defaultProps = {
    formatted: true,
    autoHeight: false,
    tableName: null,
    updateItemOrder: (item, i) => {}
  }
  
  tableRef = createRef()

  onListChange(newList) {
    let order = null
    this.props.columns.forEach((column) => {
      if (column.rowDrag) {
        order = column.id
      }
    })
    if (order == null) {
      throw 'Could not find order column'
    }
    let i = 1
    newList.forEach((item) => {
      this.props.updateItemOrder(item, i)
      item[order] = i
      i++
    })
    this.tableRef.current.updateRows(newList)
  }

  render() {
    if (this.props.formatted)
      return(
        <div>
          <div className="col-md-11 text-right">
            <a className="btn btn-box-tool text-green" onClick={this.props.addItem} style={{paddingRight: 30, paddingBottom: 10}}>
              <i className="fa fa-plus"></i>
              <span>Add New {this.props.objectType}</span>
            </a>
          </div>
          <div className="col-md-12 text-center">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="main">
                <Table
                  ref={this.tableRef}
                  tableName={this.props.tableName}
                  dataset={this.props.items}
                  columns={this.props.columns}
                  onRowDragEnd={(rows) => {this.onListChange(rows)}}
                  hidePagination={true} />
              </div>
            </div>
          </div>
        </div>
      )
    else
      return(
        <div>
          <Table
            autoHeight={this.props.autoHeight}
            dataset={this.props.items}
            columns={this.props.columns}
            onRowDragEnd={(rows) => {this.onListChange(rows)}}
            hidePagination={true}
            hideExport={true} />
        </div>
      )
  }
}
