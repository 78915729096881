import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../widgets/Table';
import { getOfflineConversions } from '../../api/offline_conversions';

export class Interface extends Component {
  getColumns() {
    return [{
      id: 'name',
      datatype: 'text',
      width: 400,
      cellRenderer: (value, row) => ( 
        <div>
            <Link to={'/offline_conversions/edit/' + row.id}>
              {value}
            </Link>
        </div>
      )
    }]
  }

  render() {
    return (
      <div className="site-canvas">
        <div className='content'>
          <div className='box'>
            <div className='box-header'>
              <div className="add-new-button" >
                <Link to={'/offline_conversions/edit/new'} className="btn btn-box-tool text-green">
                  <i className="fa fa-plus"></i>
                  <span>Add Offline Conversion</span>
                </Link>
              </div>
            </div>
            <div className='box-body no-padding js_complete' style={{marginTop: '15px'}}> 
              <Table
                fetch={(tableData) => getOfflineConversions(this.props.internal_client_id, tableData)}
                columns={this.getColumns()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  };
}

export default connect(mapStateToProps)(Interface);