import React from 'react'
import i18next from 'i18next'
import { Box, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

const WorkflowStepper = (props) => {
  if (props.steps == null) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" >
      <Box sx={{ paddingLeft: 3, borderRadius: 3, paddingBottom: 3, paddingTop: 3, bgcolor: "#ffffff" }}>
        <Typography variant="h4">
          {i18next.t('workflows.steps')}
        </Typography>
      </Box>
      {props.steps.map((step, i) => {
        return (
          <Box key={i} width="100%">
            <Box
              component="div"
              onClick={() => props.setStepIndex(i)}
              sx={{
                bgcolor: "#ffffff",
                padding: 1,
                display: "grid",
                gridTemplateColumns: ("50px auto")
              }}>
              <Typography>
                {i == props.indexCheck ? <ArrowCircleRightIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}
              </Typography>
              <Typography
                noWrap
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  variant: "h6"
                }}>
                {step.step_name}
              </Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default WorkflowStepper
