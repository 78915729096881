import request from './request';
import querystring from 'querystring';

// alerts
export function getAlerts(internal_client_id, table_data) {
  return request('/api/alerts?internal_client_id=' + internal_client_id + '&' + querystring.stringify(table_data));
}

// alerts
export function getAlert(id, internal_client_id) {
  return request('/api/alerts/' + id + '?internal_client_id=' + internal_client_id)
}

// alerts
export function createAlert(internal_client_id, data) {
  return request('/api/alerts?internal_client_id=' + internal_client_id, {}, 'POST', data)
}

// alerts
export function updateAlert(id, internal_client_id, data) {
  return request('/api/alerts/' + id + '?internal_client_id=' + internal_client_id, {}, 'PUT', data)
}

// alerts
export function deleteAlert(id) {
  return request('/api/alerts/' + id, {}, 'DELETE', {})
}

// admin
export function getNotifications(internal_client_id, table_data) {
  return request('/api/alert_notifications?internal_client_id=' + internal_client_id + '&' + querystring.stringify(table_data));
}

// admin
export function acknowledge(notification_id) {
  return request(`/api/alert_notifications/${notification_id}`, {}, 'PUT', {});
}
