import AllClients from '../components/all_clients/AllClients'
import Home from '../components/exec_summary/Home'
import Performance from '../components/performance/Interface'
import Comparison from '../components/comparison/Interface'
import Dimension from '../components/dimensions/Interface'
import Feeds from '../components/feeds/Interface'
import Feed from '../components/feeds/Feed'
import Status from '../components/status/Interface'
import Clients from '../components/clients/Interface'
import Client from '../components/clients/Client'
import Account from '../components/integrations/Account'
import Users from '../components/users/Interface'
import User from '../components/users/User'
import ConfigureIntegration from '../components/integrations/Configure'
import Links from '../components/links/Interface'
import Link from '../components/links/Link'
import ResetPassword from '../components/login/ResetPassword'
import NotFound from '../components/NotFound'
import Bidding from '../components/bidding/Interface'
import AddEditBiddingRun from '../components/bidding/AddEditBiddingRun'
import AddEditAlgo from '../components/bidding/AddEditAlgo'
import RevenuePipelineDiagram from '../components/revenue_pipeline/Interface'
import RevenuePipeline from '../components/revenue_pipeline/RevenuePipeline'
import AddEditStep from '../components/revenue_pipeline/AddEditStep'
import Workflows from '../components/workflows/Interface'
import AddEditWorkflow from '../components/workflows/AddEditWorkflow'
import Notebook from '../components/workflows/Notebook'
import Step from '../components/workflows/Step'
import Dashboard from '../components/dashboards/Interface'
import DashboardAdmin from '../components/dashboard_admin/Interface'
import EditSql from '../components/dashboard_admin/AddEditSql'
import EditDashboard from '../components/dashboard_admin/AddEditDashboard'
import Projects from '../components/projects/ActiveProjects'
import Budget from '../components/budget/Budget'
import BudgetAdmin from '../components/budget_admin/Interface'
import ABTests from '../components/ab_tests/ABTests'
import ABTest from '../components/ab_tests/ABTest'
import BiddingChanges from '../components/bidding_changes/BiddingChanges'
import BiddingChangeDetails from '../components/bidding_changes/Details'
import Config from '../components/config/Interface'
import Integrations from '../components/integrations/Interface'
import SQLTemplates from '../components/sql_templates/Interface'
import AddEditTemplate from '../components/sql_templates/AddEdit'
import Services from '../components/services/Interface'
import Service from '../components/services/Service'
import NewService from '../components/services/NewService'
import ManageDimensions from '../components/manage_dimensions/Interface'
import EditDimension from '../components/manage_dimensions/EditDimension'
import ManageABTests from '../components/manage_ab_tests/Interface'
import EditABTest from '../components/manage_ab_tests/EditABTest'
import OfflineConversions from '../components/offline_conversions/Interface'
import EditOfflineConversion from '../components/offline_conversions/EditOfflineConversion'
import ChangeHistory from '../components/change_history/Interface'
import TimeTracking from '../components/time_tracking/Interface'
import Alerts from '../components/alerts/Interface'
import Alert from '../components/alerts/Alert'
import Forecast from '../components/forecast/Interface'
import DataScience from '../components/data_science/Interface'
import DataScienceModels from '../components/data_science/ModelInterface'
import ModelQuery from '../components/data_science/ModelQuery'
import ModelFeatures from '../components/data_science/ModelFeatures'
import ModelParameters from '../components/data_science/ModelParameters'
import ModelOutputTable from '../components/data_science/ModelOutputTable'
import AiInfrastructure from '../components/ai_infrastructure/Interface'
import AiPrompt from '../components/ai_infrastructure/AiPrompt'

export const REPORT = 'report'
export const OPTIMIZE = 'optimize'
export const AUTOMATE = 'automate'
export const CONFIGURE = 'configure'
export const USER = 'user'

export const AI_DASHBOARD = 'ai_dashboard'
export const AI_INFRASTRUCTURE = 'ai_infrastructure'
export const ALL_CLIENTS = 'all_clients'
export const EXEC_SUMMARY = 'exec_summary'
export const AB_TESTS = 'ab_tests'
export const BIDDING_CHANGES = 'bidding_changes'
export const BIDDING_TEST = 'bidding_test'
export const BUDGET = 'budget'
export const BUDGET_ADMIN = 'budget_admin'
export const COMPARISON = 'comparison'
export const DASHBOARD_ADMIN = 'dashboard_admin'
export const DASHBOARDS = 'dashboards'
export const DIMENSIONS = 'dimensions'
export const FORECAST = 'forecast'
export const PERFORMANCE = 'performance'
export const PROJECTS = 'projects'
export const BIDDING = 'bidding'
export const WORKFLOWS = 'workflows'
export const AD_ENGINES = 'ad_engines'
export const ALERTS = 'alerts'
export const FEEDS = 'feeds'
export const CHANGE_HISTORY = 'change_history'
export const DATA_SCIENCE = 'data_science'
export const MANAGE_AB_TESTS = 'manage_ab_tests'
export const MANAGE_DIMENSIONS = 'manage_dimensions'
export const OFFLINE_CONVERSIONS = 'offline_conversions'
export const REVENUE_PIPELINE = 'revenue_pipeline'
export const SERVICES = 'services'
export const STATUS = 'status'
export const CLIENTS = 'clients'
export const USERS = 'users'
export const INTEGRATIONS = 'integrations'
export const LINKS = 'links'
export const SQL = 'sql'
export const TIME_TRACKING = 'time'
export const PROFILE = 'profile'

export default function (license, admin, timeTracking, showConfig, showJira, permissions) {
  const routes = []
  if (license == null || admin == null) { // Not ready yet
    return routes
  }

  if (permissions.includes(ALL_CLIENTS)) {
    routes.push({
      path: '/all_clients',
      component: <AllClients />,
      menu: REPORT,
      menuItem: ALL_CLIENTS,
    })
  }
  if (permissions.includes(AB_TESTS)) {
    routes.push({
      path: '/ab_tests/ab_test/:id/:type',
      component: <ABTest />,
      menu: REPORT,
      menuItem: AB_TESTS,
    })
    routes.push({
      path: '/ab_tests/:tab',
      component: <ABTests />,
      menu: REPORT,
      menuItem: AB_TESTS,
    })
    routes.push({
      path: '/ab_tests',
      component: <ABTests />,
      menu: REPORT,
      menuItem: AB_TESTS,
      homePage: true
    })
  }
  if (permissions.includes(BIDDING_CHANGES)) {
    routes.push({
      path: '/bidding_changes/:id',
      component: <BiddingChangeDetails />,
      menu: REPORT,
      menuItem: BIDDING_CHANGES,
    })
    routes.push({
      path: '/bidding_changes',
      component: <BiddingChanges />,
      menu: REPORT,
      menuItem: BIDDING_CHANGES,
      homePage: true
    })
  }
  if (permissions.includes(BUDGET)) {
    routes.push({
      path: '/budget',
      component: <Budget />,
      menu: REPORT,
      menuItem: BUDGET,
      homePage: true
    })
  }
  if (permissions.includes(BUDGET_ADMIN)) {
    routes.push({
      path: '/budget_admin',
      component: <BudgetAdmin />,
      menu: REPORT,
      menuItem: BUDGET_ADMIN,
      homePage: true
    })
    routes.push({
      path: '/budget_admin/:tab_id',
      component: <BudgetAdmin />,
      menu: REPORT,
      menuItem: BUDGET_ADMIN,
      homePage: true
    })
  }
  if (permissions.includes(COMPARISON)) {
    routes.push({
      path: '/comparison',
      component: <Comparison />,
      menu: REPORT,
      menuItem: COMPARISON,
      homePage: true
    })
    routes.push({
      path: '/comparison/:filter',
      component: <Comparison />,
      menu: REPORT,
      menuItem: COMPARISON,
      homePage: true
    })
  }
  if (permissions.includes(DASHBOARDS)) {
    routes.push({
      path: '/dashboards/:dashboard_id',
      component: <Dashboard />,
      menu: REPORT,
      menuItem: DASHBOARDS,
    });
    routes.push({
      path: '/dashboards',
      component: <Dashboard />,
      menu: REPORT,
      menuItem: DASHBOARDS,
      homePage: true
    })
  }
  if (permissions.includes(DASHBOARD_ADMIN)) {
    routes.push({
      path: '/dashboard_admin/sql/:id',
      component: <EditSql />,
      menu: REPORT,
      menuItem: DASHBOARD_ADMIN,
    });
    routes.push({
      path: '/dashboard_admin/dashboard/:id',
      component: <EditDashboard />,
      menu: REPORT,
      menuItem: DASHBOARD_ADMIN,
    });
    routes.push({
      path: '/dashboard_admin/dashboard/:id/:aspect',
      component: <EditDashboard />,
      menu: REPORT,
      menuItem: DASHBOARD_ADMIN,
    });
    routes.push({
      path: '/dashboard_admin',
      component: <DashboardAdmin />,
      menu: REPORT,
      menuItem: DASHBOARD_ADMIN,
      homePage: true
    })
    routes.push({
      path: '/dashboard_admin/:tab',
      component: <DashboardAdmin />,
      menu: REPORT,
      menuItem: DASHBOARD_ADMIN,
      homePage: true
    })
  }
  if (permissions.includes(DIMENSIONS)) {
    routes.push({
      path: '/dimensions',
      component: <Dimension />,
      menu: REPORT,
      menuItem: DIMENSIONS,
      homePage: true
    })
    routes.push({
      path: '/dimensions/:filter',
      component: <Dimension />,
      menu: REPORT,
      menuItem: DIMENSIONS,
      homePage: true
    })
  }
  if (permissions.includes(FORECAST)) {
    routes.push({
      path: '/forecast',
      component: <Forecast />,
      menu: REPORT,
      menuItem: FORECAST,
      homePage: true
    })
  }
  if (permissions.includes(PERFORMANCE)) {
    routes.push({
      path: '/performance/:filter',
      component: <Performance />,
      menu: REPORT,
      menuItem: PERFORMANCE,
    })
    routes.push({
      path: '/performance',
      component: <Performance />,
      menu: REPORT,
      menuItem: PERFORMANCE,
      homePage: true
    })
  }
  if (permissions.includes(PROJECTS) && showJira) {
    routes.push({
      path: '/projects',
      component: <Projects />,
      menu: REPORT,
      menuItem: PROJECTS,
      homePage: true
    })
  }

  if (permissions.includes(BIDDING)) {
    routes.push({
      path: '/bidding/algo/:id',
      component: <AddEditAlgo />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding/algo',
      component: <AddEditAlgo />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding/bidding_run',
      component: <AddEditBiddingRun />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding/:tab',
      component: <Bidding />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding/:tab/:subtab',
      component: <Bidding />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding',
      component: <Bidding />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
    })
    routes.push({
      path: '/bidding',
      component: <Bidding />,
      menu: OPTIMIZE,
      menuItem: BIDDING,
      homePage: true
    })
  }

  if (permissions.includes(WORKFLOWS)) {
    routes.push({
      path: '/workflows/add_edit/:workflow_id',
      component: <AddEditWorkflow />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/notebook/:workflow_id',
      component: <Notebook />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/notebook/:workflow_id/:mode',
      component: <Notebook />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/notebook/:workflow_id/:mode/:run_id',
      component: <Notebook />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/notebook/:workflow_id/:mode/:run_id/:step_index',
      component: <Notebook />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/step/new',
      component: <Step />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/step/:step_id',
      component: <Step />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
    })
    routes.push({
      path: '/workflows/:tab',
      component: <Workflows />,
      menu: OPTIMIZE,
      menuItem: WORKFLOWS,
      homePage: true
    })
  }

  if (permissions.includes(ALERTS)) {
    routes.push({
      path: '/alerts/:id',
      component: <Alert />,
      menu: AUTOMATE,
      menuItem: ALERTS,
    })
    routes.push({
      path: '/alerts',
      component: <Alerts />,
      menu: AUTOMATE,
      menuItem: ALERTS,
      homePage: true
    })
  }
  if (permissions.includes(CHANGE_HISTORY)) {
    routes.push({
      path: '/change_history',
      component: <ChangeHistory />,
      menu: AUTOMATE,
      menuItem: CHANGE_HISTORY,
      homePage: true
    })
  }
  if (permissions.includes(FEEDS)) {
    routes.push({
      path: '/feeds/new',
      component: <Feed />,
      menu: AUTOMATE,
      menuItem: FEEDS,
    })
    routes.push({
      path: '/feeds/:feed_id/:tab_id',
      component: <Feed />,
      menu: AUTOMATE,
      menuItem: FEEDS,
    })
    routes.push({
      path: '/feeds/:feed_id/:tab_id/:step_id',
      component: <Feed />,
      menu: AUTOMATE,
      menuItem: FEEDS,
    })
    routes.push({
      path: '/feeds',
      component: <Feeds />,
      menu: AUTOMATE,
      menuItem: FEEDS,
      homePage: true
    })
    routes.push({
      path: '/feeds/section/:section',
      component: <Feeds />,
      menu: AUTOMATE,
      menuItem: FEEDS,
      homePage: true
    })
    routes.push({
      path: '/feeds/section/:section/:filter',
      component: <Feeds />,
      menu: AUTOMATE,
      menuItem: FEEDS,
      homePage: true
    })
  }
  if (permissions.includes(INTEGRATIONS)) {
    routes.push({
      path: '/integrations',
      component: <Integrations />,
      menu: AUTOMATE,
      menuItem: INTEGRATIONS,
      homePage: true
    })
    routes.push({
      path: '/integrations/:tab',
      component: <Integrations />,
      menu: AUTOMATE,
      menuItem: INTEGRATIONS,
      homePage: true
    })
    routes.push({
      path: '/ad_engines/:engine',
      component: <ConfigureIntegration />,
      menu: AUTOMATE,
      menuItem: INTEGRATIONS,
    })
    routes.push({
      path: '/clients/:internal_client_id/accounts/new',
      component: <Account />,
      menu: AUTOMATE,
      menuItem: INTEGRATIONS,
    })
    routes.push({
      path: '/clients/:internal_client_id/accounts/:account_id/edit',
      component: <Account />,
      menu: AUTOMATE,
      menuItem: INTEGRATIONS,
    })
  }
  if (permissions.includes(DATA_SCIENCE)) {
    routes.push({
      path: '/data_science/:id/features',
      component: <ModelFeatures />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
    })
    routes.push({
      path: '/data_science/:id/parameters',
      component: <ModelParameters />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
    })
    routes.push({
      path: '/data_science/:id/output_table',
      component: <ModelOutputTable />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
    })
    routes.push({
      path: '/data_science/:id/:type',
      component: <ModelQuery />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
    })
    routes.push({
      path: '/data_science',
      component: <DataScienceModels />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
      homePage: true
    })
    routes.push({
      path: '/data_science/:id',
      component: <DataScience />,
      menu: AUTOMATE,
      menuItem: DATA_SCIENCE,
      homePage: true
    })
  }
  if (permissions.includes(MANAGE_AB_TESTS)) {
    routes.push({
      path: '/manage_ab_tests/edit/:id',
      component: <EditABTest />,
      menu: AUTOMATE,
      menuItem: MANAGE_AB_TESTS,
    })
    routes.push({
      path: '/manage_ab_tests',
      component: <ManageABTests />,
      menu: AUTOMATE,
      menuItem: MANAGE_AB_TESTS,
      homePage: true
    })
    routes.push({
      path: '/manage_ab_tests/:tab',
      component: <ManageABTests />,
      menu: AUTOMATE,
      menuItem: MANAGE_AB_TESTS,
      homePage: true
    })
  }
  if (permissions.includes(MANAGE_DIMENSIONS)) {
    routes.push({
      path: '/dimension/:dimension',
      component: <EditDimension />,
      menu: AUTOMATE,
      menuItem: MANAGE_DIMENSIONS,
    })
    routes.push({
      path: '/manage_dimensions',
      component: <ManageDimensions />,
      menu: AUTOMATE,
      menuItem: MANAGE_DIMENSIONS,
      homePage: true
    })
  }
  if (permissions.includes(OFFLINE_CONVERSIONS)) {
    routes.push({
      path: '/offline_conversions/edit/:id',
      component: <EditOfflineConversion />,
      menu: AUTOMATE,
      menuItem: OFFLINE_CONVERSIONS,
    })
    routes.push({
      path: '/offline_conversions',
      component: <OfflineConversions />,
      menu: AUTOMATE,
      menuItem: OFFLINE_CONVERSIONS,
      homePage: true
    })
  }
  if (permissions.includes(REVENUE_PIPELINE)) {
    routes.push({
      path: '/revenue_pipeline/new/:type',
      component: <AddEditStep />,
      menu: AUTOMATE,
      menuItem: REVENUE_PIPELINE,
    })
    routes.push({
      path: '/revenue_pipeline/steps/:step_id',
      component: <AddEditStep />,
      menu: AUTOMATE,
      menuItem: REVENUE_PIPELINE,
    })
    routes.push({
      path: '/revenue_pipeline/:tab',
      component: <RevenuePipeline />,
      menu: AUTOMATE,
      menuItem: REVENUE_PIPELINE,
    })
    routes.push({
      path: '/revenue_pipeline',
      component: <RevenuePipelineDiagram />,
      menu: AUTOMATE,
      menuItem: REVENUE_PIPELINE,
      homePage: true
    })
  }
  if (permissions.includes(SERVICES)) {
    routes.push({
      path: '/services/:service_type/:id/:tab/:run_id',
      component: <Service />,
      menu: AUTOMATE,
      menuItem: SERVICES,
    })
    routes.push({
      path: '/services/:service_type/:id/:tab',
      component: <Service />,
      menu: AUTOMATE,
      menuItem: SERVICES,
    })
    routes.push({
      path: '/services/new',
      component: <NewService />,
      menu: AUTOMATE,
      menuItem: SERVICES,
    })
    routes.push({
      path: '/services/new/:tab',
      component: <NewService />,
      menu: AUTOMATE,
      menuItem: SERVICES,
    })
    routes.push({
      path: '/services/:tab',
      component: <Services />,
      menu: AUTOMATE,
      menuItem: SERVICES,
    })
    routes.push({
      path: '/services',
      component: <Services />,
      menu: AUTOMATE,
      menuItem: SERVICES,
      homePage: true
    })
  }
  if (permissions.includes(STATUS)) {
    routes.push({
      path: '/status',
      component: <Status />,
      menu: AUTOMATE,
      menuItem: STATUS,
      homePage: true
    })
  }
  if (permissions.includes(LINKS)) {
    routes.push({
      path: '/links/:id',
      component: <Link />,
      menu: USER,
      menuItem: LINKS,
    })
    routes.push({
      path: '/links',
      component: <Links />,
      menu: USER,
      menuItem: LINKS,
      homePage: true
    })
  }
  if (permissions.includes(SQL)) {
    routes.push({
      path: '/sql_templates/:id',
      component: <AddEditTemplate />,
      menu: USER,
      menuItem: SQL,
    })
    routes.push({
      path: '/sql_templates',
      component: <SQLTemplates />,
      menu: USER,
      menuItem: SQL,
      homePage: true
    })
  }
  if (permissions.includes(TIME_TRACKING) && timeTracking) {
    routes.push({
      path: '/time_tracking',
      component: <TimeTracking />,
      menu: USER,
      menuItem: TIME_TRACKING,
      homePage: true
    })
    routes.push({
      path: '/time_tracking/:date',
      component: <TimeTracking />,
      menu: USER,
      menuItem: TIME_TRACKING,
      homePage: true
    })
  }
  if (permissions.includes(PROFILE)) {
    routes.push({
      path: '/users/profile',
      component: <User />,
      menu: USER,
      menuItem: PROFILE,
      homePage: true
    })
  }

  if (admin) {
    routes.push({
      path: '/clients/new',
      component: <Client />,
      menu: USER,
      menuItem: CLIENTS,
    })
    routes.push({
      path: '/clients/:id',
      component: <Client />,
      menu: USER,
      menuItem: CLIENTS,
    })
    routes.push({
      path: '/clients/:id/:tab',
      component: <Client />,
      menu: USER,
      menuItem: CLIENTS,
    })
    routes.push({
      path: '/clients',
      component: <Clients />,
      menu: USER,
      menuItem: CLIENTS,
      homePage: true
    })
    routes.push({
      path: '/users/new',
      component: <User />,
      menu: USER,
      menuItem: USERS,
    })
    routes.push({
      path: '/users/:id',
      component: <User />,
      menu: USER,
      menuItem: USERS,
    })
    routes.push({
      path: '/users',
      component: <Users />,
      menu: USER,
      menuItem: USERS,
      homePage: true
    })
    if (showConfig) {
      routes.push({
        path: '/config',
        component: <Config />,
        menu: CONFIGURE,
        homePage: true
      })
    }
  }
  if (permissions.includes(EXEC_SUMMARY)) {
    routes.push({
      path: '/*',
      component: <Home />,
      menu: REPORT,
      menuItem: EXEC_SUMMARY,
      homePage: true
    })
  }
  //if (permissions.includes(AI_INFRASTRUCTURE)) {
  routes.push({
    path: '/ai_infrastructure',
    component: <AiInfrastructure />,
    menu: AUTOMATE,
    menuItem: AI_INFRASTRUCTURE,
    homePage: true
  })
  routes.push({
    path: '/ai_infrastructure/:type',
    component: <AiPrompt />,
    menu: AUTOMATE,
    menuItem: AI_INFRASTRUCTURE,
  })
  //}

  const homeRoutes = routes.filter(route => route.homePage)
  if (homeRoutes.length == 0) {
    routes.push({
      path: '/',
      component: <NotFound />
    })
  } else if (routes.filter(route => route.path == '/*' || route.path == '/').length == 0) {
    const route = routes[0]
    routes.push({
      path: '/',
      component: route.component,
      menu: route.menu,
      menuItem: route.menuItem,
    })
  }

  routes.push({
    path: '/reset_password/:token',
    component: <ResetPassword />
  })
  return routes
}
