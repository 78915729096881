import { Component } from 'react'
import { decode, encode } from '../../api/encoding'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import ChartConfig from './ChartConfig'
import { getDimensionSettings } from '../../api/dimensions'
import Summary from './Summary'
import Keywords from './Keywords'
import { Box } from '@mui/material'
import Page from '../widgets/Page'
import Title from '../widgets/Title'
import Stack from '../widgets/Stack'
import Wrench from '../widgets/Wrench'
import DatePicker from '../widgets/DatePicker'
import Tabs from '../widgets/Tabs'
import Tab from '../widgets/Tab'
import Button from '../widgets/Button'

class Interface extends Component {
  SUMMARY = 'summary'
  KEYWORDS = 'keywords'
  
  constructor(props) {
    super(props)
    this.state = {
      from_date: moment().subtract(28, 'day').format('YYYY-MM-DD'),
      to_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      title: null,
      showAnnotations: true,
      tab: this.SUMMARY,
    }
    $.extend(this.state, this.getConfig())
    if (this.getTabs().find(t => t.id == this.state.tab) == null) {
      this.state.tab = this.SUMMARY
    }
  }

  getConfig() {
    if (this.props.match.params.filter) {
      return JSON.parse(decode(this.props.match.params.filter))
    } else {
      this.loadSettings()
      return {}
    }
  }

  loadSettings() {
    getDimensionSettings(this.props.internal_client_id).then(settings => {
      this.setState(settings)
    })
  }

  updateUrl() {
    const params = {
      dimension: this.state.dimension,
      filters: this.state.filters,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      tab: this.state.tab,
    }
    window.location.href = '/#/dimensions/' + encode(JSON.stringify(params))
  }

  updateDates(dates) {
    this.setState(
      {
        from_date: dates.start,
        to_date: dates.end,
      },
      () => {
        this.updateUrl()
      }
    )
  }

  getParams() {
    return Object.assign(
      { dimension: this.state.dimension },
      this.state.filters
    )
  }
  
  getCommonProps() {
    return {
      internal_client_id: this.props.internal_client_id,
      start: this.state.from_date,
      end: this.state.to_date,
      dimension: this.state.dimension,
      dimensionName: this.state.dimensionName,
      filters: this.state.filters,
      showAnnotations: this.state.showAnnotations,
      charts: this.state.charts ? this.state.charts : [],
    }
  }

  getTabs() {
    const commonProps = this.getCommonProps()
    return [{
      id: this.SUMMARY,
      name: 'dimensions.summary',
      component: (<Summary {...commonProps}/>)
    }, {
      id: this.KEYWORDS,
      name: 'dimensions.keywords',
      component: (<Keywords {...commonProps}/>)
    }]
  }
  
  updateTab(tab) {
    this.setState({tab}, () => this.updateUrl())
  }

  render() {
    if (this.state.dimension) {
      const tabs = this.getTabs()
      return (
        <Page>
          <Title title={this.state.title} />
          <ChartConfig
            dimension={this.state.dimension}
            filterValues={this.state.filters}
            hideSubfilters={true}
            charts={(charts) => this.setState({ charts: charts })}
            changed={(filters, dimension, annotations) =>
              this.setState({ filters: filters, dimension: dimension, annotations: annotations }, () => {
                this.updateUrl()
              })
            }
            title={(title, dimension) =>
              this.setState({
                title: title + " By " + dimension,
                dimensionName: dimension,
              })
            }
          />
          <Stack direction="row">
            <Box alignSelf="center">
              <Wrench />
            </Box>
            <Box alignSelf="center">
              <DatePicker
                start={this.state.from_date}
                end={this.state.to_date}
                showCompare={false}
                callback={(dates) => {
                  this.updateDates(dates)
                }}
              />
            </Box>
            <Box alignSelf="center">
              <Button
                buttonText={'dimensions.' + (this.state.showAnnotations ? 'hideAnnotations' : 'showAnnotations')}
                onClick={() => this.setState({showAnnotations: !this.state.showAnnotations})}
              />
            </Box>
          </Stack>
          <Tabs value={this.state.tab} onChange={(_, newValue) => this.updateTab(newValue)} centered>
           {tabs.map(tab => (<Tab key={tab.id} label={tab.name} value={tab.id} />))}
          </Tabs>
          {tabs.find(tab => tab.id == this.state.tab)?.component}
        </Page>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
