import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import ReactFlow from 'reactflow'
import FTNotice from '../FTNotice'
import FeedNode from './FeedNode'
import styles from './DependenciesStyles'
import { connect } from 'react-redux'
import { getFeeds, saveDataOverview } from '../../api/feeds'
import i18next from 'i18next'

import 'reactflow/dist/style.css'

const Flow = ({internal_client_id, filter}) => {
  const nodeHeight = 80
  const nodeWidth = 120
  const nodeSpacing = 30
  const divRef = useRef()
  const [config, setConfig] = useState({numFeeds: 0, numLevels: 0, loaded: false, nodes: [], edges: []})

  const getLevel = (feeds, feed) => {
    if (feed.parents.length == 0) {
      return 0
    } else if (Number.isInteger(feed.parents[0])){ // Check if already swapped
      feed.parents = feed.parents.map(id => feeds.find(feed => feed.feed_id==id))
      feed.parents.forEach(parent => parent.children++)
    }
    return 1 + Math.max(...feed.parents.map(parent => getLevel(feeds, parent)))
  }
  
  const clearConfig = () => {
    setConfig({numFeeds: 0, numLevels: 0, loaded: false, nodes: [], edges: []})
  }
  
  const updateFeeds = () => {
    setConfig({numFeeds: 0, numLevels: 0, loaded: false, nodes: [], edges: []})
    getFeeds(internal_client_id, {text_equal_filter: 'feed_type ' + filter}).then(response => {
      const feeds = response?.results || []
      feeds.forEach(f => {
        f.children = 0
      })
      feeds.forEach(f => {
        f.level = getLevel(feeds, f)
      })
      const maxLevel = Math.max(0, ...feeds.map(f => f.level))
      const levels = [...Array(maxLevel + 1).keys()] // 0 .. maxLevel
      const newNodes = []
      const newEdges = []
      let maxPosition = 0
      levels.forEach(level => {
        const usedLocations = []
        const levelFeeds = feeds.filter(f => f.level == level)
        levelFeeds.forEach((feed, j) => {
          // If no parents, a feed is positioned alphabetically
          let position = j
          
          // If parents, a feed is positioned next to a parent if possible, then 1 away, then 2 away, etc.
          let positioned = false
          if (feed.parents.length > 0) {
            let offset = 0
            while(!positioned) {
              feed.parents.forEach(parent => {
                if (!positioned) {
                  if (parent.position - offset >= 0 && !usedLocations.includes(parent.position - offset)) {
                    position = parent.position - offset
                    usedLocations.push(position)
                    positioned = true
                  } else if (!usedLocations.includes(parent.position + offset)) {
                    position = parent.position + offset
                    usedLocations.push(position)
                    positioned = true
                  }
                }
              })
              offset++
            }
          }
          
          maxPosition = position > maxPosition ? position : maxPosition
          const referer = window.location.hash?.length > 0 ? window.location.hash.substring(1) : window.location.hash
          const node = {
            id: `${feed.feed_id}`, // Needs to be a string
            type: 'custom',
            data: { label: feed.feed, link: `/feeds/${feed.feed_id}/overview?referer=${referer}`},
            position: { x: level * (nodeWidth + nodeSpacing), y: position * (nodeHeight + nodeSpacing) },
            parents: feed.parents,
          }
          feed.node = node
          feed.position = position
          feed.parents.forEach((parent, k) => {
            newEdges.push({ id: `e${level}-${position}-${k}`, source: `${parent.feed_id}`, target: node.id, markerEnd: {type: 'arrowclosed', color: 'black'}})
          })
          if (feed.parents.length == 0) {
            node.data['hideTarget'] = true
          }
          if (feed.children == 0) {
            node.data['hideSource'] = true
          }
          newNodes.push(node)
        })
      })
      
      setConfig({numFeeds: maxPosition + 1, numLevels: maxLevel + 1, loaded: true, nodes: newNodes, edges: newEdges})
    })
  }
  
  useEffect(() => {
    updateFeeds()
  }, [filter])
  
  const updateNodes = useCallback(nodeChanges => {
    nodeChanges.forEach(nodeChange => {
      if (nodeChange.type == 'position') {
        const node = config.nodes.find(node => node.id == nodeChange.id)
        if (node && nodeChange.position) {
          node.position = nodeChange.position
          node.zIndex = 1
          setConfig(Object.assign({...config}, {nodes: [...config.nodes]}))
        }
      }
    })
  }, [config])
  
  const dragStop = useCallback((event, node) => {
    if (node) {
      const div = $(divRef.current)
      const y = event.pageY - div.offset().top; // calculate so that we can factor in scroll
      const targetNode = config.nodes.find(target => target.id != node.id &&
        node.position.x < target.position.x + nodeWidth && node.position.x + nodeWidth > target.position.x &&
        y < target.position.y + nodeHeight && y + nodeHeight > target.position.y)
      
      const parents = node.parents
      const nodeId = parseInt(node.id)
      const targetId = parseInt(targetNode?.id)
      if (targetNode && !parents.includes(targetId)) {
        saveDataOverview({id: nodeId, parents: [...parents.map(parent => parent.feed_id), targetId]}).then(result => {
          clearConfig()
          updateFeeds()
          if (result.error) {
            FTNotice(result.error, 15000)
          }
        })
      } else if (!targetNode) {
        saveDataOverview({id: nodeId, parents: []}).then(result => {
          clearConfig()
          updateFeeds()
          if (result.error) {
            FTNotice(result.error, 15000)
          }
        })
      } else {
        clearConfig()
        updateFeeds()
      }
    }
  }, [config])
  
  const nodeTypes = useMemo(() => ({ custom: FeedNode }), [])
  
  if (!config.loaded) {
    return (
      <div style={styles.loading}>{i18next.t('feeds.dependencies.loading')}</div>
    )
  } else {
    const height = config.numFeeds == 0 ? nodeHeight + nodeSpacing : config.numFeeds * (nodeHeight + nodeSpacing) // extra nodeSpacing for watermark
    const width = config.numLevels * nodeWidth + (config.numLevels - 1) * nodeSpacing  
    return (
      <div style={styles.container}>
        <div ref={divRef} style={{height: height, width: width, marginTop: 20, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto'}}>
          <ReactFlow
            style={styles.diagram}
            nodeTypes={nodeTypes}
            nodes={config.nodes}
            edges={config.edges}
            onNodesChange={nodeChanges => updateNodes(nodeChanges)}
            onNodeDragStop={(event, node, nodes) => dragStop(event, node, nodes)}
            autoPanOnNodeDrag={false}
            panOnDrag={false}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(Flow)
