import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { getLinks } from "../../api/links.js"
import Table from "../widgets/Table"

class Interface extends Component {
  getColumns() {
    return [
      {
        id: "name",
        header: "Link",
        datatype: "text",
        cellRenderer: (value, row) => {
          return <Link to={`/links/${row.id}`}>{value}</Link>;
        },
        width: 200,
      },
      {
        id: "href",
        header: "URL",
        datatype: "text",
        cellRenderer: (value) => {
          return (
            <div className="center">
              <a href={value} target="_blank">
                {value}
              </a>
            </div>
          );
        },
      },
      {
        id: "client",
        header: "Client",
        datatype: "text",
        width: 200,
      },
    ];
  }

  render() {
    return (
      <div className="site-canvas" style={{ minHeight: "540px" }}>
        <div className="content">
          <div className="box">
            <div className="box-header">
              <div className="box-tools">
                <Link
                  to="/links/new"
                  className="btn btn-box-tool text-green"
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-plus"></i>
                  <span>Add New Link</span>
                </Link>
              </div>
            </div>
            <div
              className="box-body no-padding js_complete"
              style={{ marginTop: "15px" }}
            >
              <Table
                fetch={(tableData) => {
                  return getLinks(this.props.internal_client_id, tableData);
                }}
                columns={this.getColumns()}
              />
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  internal_client_id: state.users.user
    ? state.users.user.client.internal_client_id
    : null,
});

export default connect(mapStateToProps)(Interface);
