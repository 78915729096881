import { Box } from '@mui/material'
import HelperToggle from './HelperToggle'
import WorkflowStepper from './WorkflowStepper'
import WorkflowSettings from './WorkflowSettings'
import Section from '../../widgets/Section'
import { useState } from 'react'

const UtilityPanel = (props) => {
  const [helperMode, setHelperMode] = useState("settings")
  const selectMode = (mode) => {
    if (mode == "settings") {
      return (
        <WorkflowSettings
          {...props}
        />
      )
    } else {
      if (mode == "stepper") {
        return (
          <WorkflowStepper
            {...props}
          />
        )
      } else {
        return null
      }
    }
  }

  return (
    <Section>
      {selectMode(helperMode)}
      <Box display="flex" justifyContent="center">
        <HelperToggle
          setHelperMode={setHelperMode}
          helperMode={helperMode} />
      </Box>
    </Section>
  )
}

export default UtilityPanel