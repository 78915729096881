import request from './request';
import querystring from 'querystring';

// feeds
export function getFeeds(internalClientId, tableData) {
  return request('/api/feeds?internal_client_id=' + internalClientId + '&' + querystring.stringify(tableData));
}

// feeds
export function getDataOverview(feed_id) {
  const url = '/api/feeds/' + feed_id;
  return request(url);
}

// feeds
export function getHistoricalFiles(data) {
  return request('/api/feeds/files', {}, 'POST', data);
}

// feeds
export function saveDataOverview(data) {
  const is_new = data['id'] == undefined;
  const url = is_new ? '/api/feeds' : '/api/feeds/' + data['id'];
  return request(url, {}, is_new ? 'POST' : 'PUT', data);
}

// feeds
export function deleteFeed(id) {
  const url = '/api/feeds/' + id;
  return request(url, {}, 'DELETE', {});
}

// feeds
export function getRawData(feed_id) {
  const url = '/api/feeds/' + feed_id + '/raw_data';
  return request(url);
}

// feeds
export function saveRawData(data) {
  const url = '/api/feeds/' + data['feed_id'] + '/raw_data';
  return request(url, {}, 'PUT', data);
}

// feeds
export function patchStep(feed_id, stage, step_id, data) {
  const url = '/api/feeds/' + feed_id + '/' + stage + '/' + step_id;
  return request(url, {}, 'PATCH', data);
}

// feeds
export function saveStep(feed_id, stage, step_id, data) {
  const url = '/api/feeds/' + feed_id + '/' + stage + (step_id ? ('/' + step_id) : '');
  return request(url, {}, step_id ? 'PUT' : 'POST', data);
}

// feeds
export function deleteStep(feed_id, stage, step_id) {
  const url = '/api/feeds/' + feed_id + '/' + stage + '/' + step_id;
  return request(url, {}, 'DELETE');
}

// feeds
export function getStep(feed_id, stage, step_id) {
  const url = '/api/feeds/' + feed_id + '/' + stage + '/' + step_id;
  return request(url);
}

// feeds
export function getSteps(feed_id, stage) {
  const url = '/api/feeds/' + feed_id + '/' + stage;
  return request(url);
}

// feeds
export function previewUpdate(data) {
  const url = '/api/sql/preview';
  return request(url, {}, 'POST', data);
}

// feeds
export function getFeedHistory(internal_client_id, from_date, to_date, tab, table_data, columns) {
  let columnString = '&columns=' + columns.join(',');
  const url = `/api/feed_history?internal_client_id=${internal_client_id}&from_date=${from_date}&to_date=${to_date}&tab=${tab}${columnString}&${querystring.stringify(table_data)}`;
  return request(url);
}

// feeds
export function getLastSubmission(s3_url) {
  const url = '/api/feeds/submission?s3_url=' + s3_url
  return request(url)
}
