import { TextField, InputAdornment } from "@mui/material"
import i18next from 'i18next'

const FtoTextField = (props) => {
  const extraProps = {}
  const defaultProps = {
    size: "medium",
    variant: "outlined",
    defaultValue: undefined,
    disabled: false,
    error: false,
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { inputRef, endAdornmentIcon, startAdornmentText, startAdornmentIcon, ...textFieldProps } = revisedProps
  if (startAdornmentText) {
    extraProps['InputProps'] = {
      startAdornment: <InputAdornment position="start">{i18next.t(startAdornmentText)}</InputAdornment>,
    }
  }
  if (startAdornmentIcon) {
    extraProps['InputProps'] = {
      startAdornment: <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>,
    }
  }
  if (endAdornmentIcon) {
    extraProps['InputProps'] = {
      endAdornment: <InputAdornment position="end">{endAdornmentIcon}</InputAdornment>,
    }
  }
  return (
    <TextField
      inputRef={inputRef}
      {...textFieldProps}
      {...extraProps}
    />
  )
}

export default FtoTextField