import moment from 'moment'
import { Component } from 'react'
import i18next from 'i18next'

export default class DatePicker extends Component {
  /*
    DatePicker uses the JQuery Date Range Picker (see https://www.daterangepicker.com/ for more details).
    Display a date picker so that users can choose what date range to show data for.
    Properties:
      start: start date of range ('YYYY-MM-DD')
      end: end date of range ('YYYY-MM-DD')
      compareStart: start date of compare ('YYYY-MM-DD')
      compareEnd: end date of compare ('YYYY-MM-DD')
      callback: function to call when the values change, receives the props)
      restrictedDatePicker: if true, show less options for ranges (optional)
      autoApply: auto apply (optional)
      stayOpen: if true, always stay open (optional)
      style: style for element (optional)
      maxDate: (optional)
      range: whether to show a date range (optional; defaults to true)
  */

  static defaultProps = {
    start: moment().subtract(28, 'day').format('YYYY-MM-DD'),
    end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    compareStart: null,
    compareEnd: null,
    callback: () => { },
    autoApply: false,
    stayOpen: false,
    style: {},
    range: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      start: this.props.start,
      end: this.props.end,
      compareStart: this.props.compareStart,
      compareEnd: this.props.compareEnd
    }
  }

  updateDates() {
    const $reportrange = $('#reportrange')
    $reportrange.data('daterangepicker').setStartDate(moment(this.state.start, 'YYYY-MM-DD'))
    if (this.props.range) {
      $reportrange.data('daterangepicker').setEndDate(moment(this.state.end, 'YYYY-MM-DD'))
    }

    const $comparerange = $('#comparerange')
    $comparerange.data('daterangepicker').maxDate = moment(this.state.start, 'YYYY-MM-DD').subtract(1, 'day')
    if (this.state.compareStart != null) {
      $comparerange.data('daterangepicker').setStartDate(moment(this.state.compareStart, 'YYYY-MM-DD'))
      if (this.props.range) {
        $comparerange.data('daterangepicker').setEndDate(moment(this.state.compareEnd, 'YYYY-MM-DD'))
      }
      this.changeCompare(true, false)
      this.enableCompare()
    } else {
      this.changeCompare(false, false)
      this.disableCompare()
    }
  }

  showOptions() {
    setTimeout(() => {
      $("#reportrange").click()
    }, 0)
  }

  componentDidMount() {
    const single = !this.props.range
    const $reportrange = $('#reportrange')
    const $comparerange = $('#comparerange')
    const me = this
    const options = {
      parentEl: $('#reportrange-div'),
      opens: $reportrange.data('opens'),
      startDate: moment(this.state.start, 'YYYY-MM-DD'),
      linkedCalendars: false,
    }
    if (single) {
      options['singleDatePicker'] = true
    } else {
      options['endDate'] = moment(this.state.end, 'YYYY-MM-DD')
    }
    if (this.props.autoApply) {
      options['autoApply'] = true
    }
    if (this.props.maxDate) {
      options['maxDate'] = moment(this.props.maxDate, 'YYYY-MM-DD')
    }
    if (this.props.restrictedDatePicker) {
      if (!single) {
        options['showCustomRangeLabel'] = false
        const ranges = {}
        ranges[i18next.t('datePicker.last7Days')] = [moment().subtract(7, 'days'), moment().subtract(1, 'days')]
        ranges[i18next.t('datePicker.last14Days')] = [moment().subtract(14, 'days'), moment().subtract(1, 'days')]
        ranges[i18next.t('datePicker.last30Days')] = [moment().subtract(30, 'days'), moment().subtract(1, 'days')]
        options['ranges'] = ranges
      }
      $reportrange.daterangepicker(options, (start, end) => {
        this.updateState({ start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') })
        $comparerange.data('daterangepicker').maxDate = start.clone().subtract(1, 'day')
      })
    } else {
      if (!single) {
        const ranges = {}
        ranges[i18next.t('datePicker.last7Days')] = [moment().subtract(7, 'days'), moment().subtract(1, 'days')]
        ranges[i18next.t('datePicker.last30Days')] = [moment().subtract(30, 'days'), moment().subtract(1, 'days')]
        ranges[i18next.t('datePicker.thisMonth')] = [moment().startOf('month'), moment().endOf('month')]
        ranges[i18next.t('datePicker.lastMonth')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        options['ranges'] = ranges
      }
      $reportrange.daterangepicker(options, (start, end) => {
        this.updateState({ start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') })
        $comparerange.data('daterangepicker').maxDate = start.clone().subtract(1, 'day')
      })
    }
    if (this.props.stayOpen) {
      $("#reportrange").click()
      $reportrange.on('hide.daterangepicker', this.showOptions)
    }

    const yesterday = moment(this.state.start, 'YYYY-MM-DD').subtract(1, 'day')
    const compareOptions = {
      parentEl: $('#comparerange-div'),
      opens: $comparerange.data('opens'),
      maxDate: yesterday,
      startDate: yesterday,
      linkedCalendars: false,
      locale: { cancelLabel: 'Clear' }
    }
    if (single) {
      compareOptions['singleDatePicker'] = true
    } else {
      compareOptions['endDate'] = yesterday
    }
    $comparerange.daterangepicker(compareOptions, (start, end) => {
      if (start.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && end.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
        this.updateState({ compareStart: null, compareEnd: null })
      } else {
        this.updateState({ compareStart: start.format('YYYY-MM-DD'), compareEnd: end.format('YYYY-MM-DD') })
      }
    })

    this.updateDates()

    $comparerange.on('cancel.daterangepicker', () => this.turnOffCompare())

    $('#compare_menu #compare_ranges').change(function () {
      me.changeCompare($(this).find('input').prop('checked'), true)
    })

    $('#compare_menu').detach().prependTo('#reportrange-div .daterangepicker').removeClass('hidden')
    $('#reportrange-div .daterangepicker .ranges').detach().prependTo('#compare_menu')

    let data = {
      start: this.state.start,
      end: this.state.end,
      compareStart: this.state.compareStart,
      compareEnd: this.state.compareEnd
    }
    this.props.callback(data)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.start != this.props.start || prevProps.end != this.props.end || prevProps.compareStart != this.props.compareStart ||
      prevProps.compareEnd != this.props.compareEnd) {
      this.setState({
        start: this.props.start,
        end: this.props.end,
        compareStart: this.props.compareStart,
        compareEnd: this.props.compareEnd
      })
    }

    if (prevState.start != this.state.start || prevState.end != this.state.end || prevState.compareStart != this.state.compareStart ||
      prevState.compareEnd != this.state.compareEnd) {
      this.updateDates()
    }
  }

  changeCompare(checked, updateState) {
    const toggle = $('#compare_menu #compare_ranges input')
    toggle.prop('checked', checked)
    if (!checked) {
      $('#compare_menu .compare_options ul').slideUp()
      if (updateState) {
        this.turnOffCompare()
      }
    } else {
      $('#compare_menu .compare_options ul').slideDown()
    }
  }

  turnOffCompare() {
    this.updateState({ compareStart: null, compareEnd: null })
  }

  turnOnCompare(start, end, func = null) {
    this.updateState({ compareStart: start.format('YYYY-MM-DD'), compareEnd: end.format('YYYY-MM-DD') }, func)
  }

  previousPeriod(func = null) {
    const end = moment(this.state.start, 'YYYY-MM-DD').clone().subtract(1, 'day')
    if (this.props.range) {
      const diff = moment(this.state.end, 'YYYY-MM-DD').diff(moment(this.state.start, 'YYYY-MM-DD'), 'day')
      const start = end.clone().subtract(diff, 'day')
      this.turnOnCompare(start, end, func)
    } else {
      this.turnOnCompare(end, end, func)
    }
  }

  previousYear() {
    const start = moment(this.state.start, 'YYYY-MM-DD').clone().subtract(1, 'year')
    if (this.props.range) {
      const end = moment(this.state.end, 'YYYY-MM-DD').clone().subtract(1, 'year')
      this.turnOnCompare(start, end)
    } else {
      this.turnOnCompare(start, start)
    }
  }

  previousCustom() {
    this.previousPeriod(() => this.switchPickers())
  }

  switchPickers() {
    $('#reportrange').data('daterangepicker').toggle()
    $('#comparerange').data('daterangepicker').toggle()
  }

  enableCompare() {
    $('#compare_menu #compare_ranges input').prop('checked', true)
    $('#compare_menu .compare_options ul').slideDown()
    $('#compareselect').removeClass('hidden').show()
    $('#comparerange').removeClass('hidden').show()
  }

  disableCompare() {
    $('#compare_menu #compare_ranges input').prop('checked', false)
    $('#compare_menu .compare_options ul').slideUp()
    $('#compareselect').hide()
    $('#comparerange').hide()
  }

  updateState(stateChanges, func = null) {
    this.setState(stateChanges, () => {
      if (func) {
        func()
      }
      this.props.callback({
        start: this.state.start,
        end: this.state.end,
        compareStart: this.state.compareStart,
        compareEnd: this.state.compareEnd
      })
    })
  }

  render() {
    const { showCompare } = this.props
    const start = moment(this.state.start, 'YYYY-MM-DD').format('MMM D, YYYY')
    const end = moment(this.state.end, 'YYYY-MM-DD').format('MMM D, YYYY')
    const dates = this.props.range ? start + ' - ' + end : start
    const compareStart = moment(this.state.compareStart, 'YYYY-MM-DD').format('MMM D, YYYY')
    const compareEnd = moment(this.state.compareEnd, 'YYYY-MM-DD').format('MMM D, YYYY')
    const compareDates = this.props.range ? compareStart + ' - ' + compareEnd : compareStart
    return (
      <div id="fto-date-picker" style={this.props.style}>
        <div className="btn btn-default hidden" id="comparerange" style={{ cursor: 'pointer', position: 'relative', textAlign: 'left' }} title="compare date">
          <i className="fa fa-calendar-plus-o"></i>
          <span className="comparerange">
            {this.state.compareStart == null ? '' : compareDates}
          </span>
        </div>
        <div className="hidden" id="compareselect" style={{ display: 'inline-block', float: 'none' }}>
          {this.state.compareStart == null ? (<i className="fa fa-caret-down"></i>) : (<b>{i18next.t('datePicker.vs')}</b>)}
        </div>
        <div className="btn date-picker" id="reportrange" style={{ cursor: 'pointer', position: 'relative', textAlign: 'center', width: this.props.range ? 220 : 150 }}>
          <i className="fa fa-calendar"></i>&nbsp;
          <span className="reportrange">
            {dates}
          </span>
          <b className="caret" style={{ marginLeft: '10px' }}></b>
        </div>
        <div id="compare_menu" className="hidden">
          {
            showCompare &&
            <div className="datepicker-nav-title">
              <span>{i18next.t('datePicker.compare')}</span>
              <label id="compare_ranges" className="switch">
                <input type="checkbox" name="compare_ranges_checkbox" id="compare_ranges_checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          }
          <div className="compare_options">
            <ul>
              <li><a className="previous_compare" data-previous="period" onClick={() => this.previousPeriod()}>{i18next.t('datePicker.previousPeriod')}</a></li>
              <li><a className="previous_compare" data-previous="year" onClick={() => this.previousYear()}>{i18next.t('datePicker.samePeriod')}</a></li>
              <li><a className="previous_compare" data-previous="custom" onClick={() => this.previousCustom()}>{i18next.t('datePicker.custom')}</a></li>
            </ul>
          </div>
        </div>
        <div id="reportrange-div"></div>
        <div id="comparerange-div"></div>
      </div>
    )
  }
}
