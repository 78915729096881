import Switch from '../../widgets/Switch'
import CodeIcon from '@mui/icons-material/Code'
import { Tooltip, IconButton } from '@mui/material'
import i18next from 'i18next'

const DevModeSwitch = (props) => {
  return (
    <Tooltip title={i18next.t('workflow.showCode')}>
      <IconButton>
        <Switch label={<CodeIcon fontSize="large" />} onChange={props.updateDevMode} />
      </IconButton>
    </Tooltip>
  )
}

export default DevModeSwitch