import { Component } from 'react'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Chart, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip, Title } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { dataFormatter } from '../../utilities/formatting'

import '../../assets/stylesheets/charts.css'

Chart.register(annotationPlugin, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip, Title)

export default class CompareLineChart extends Component {
  static defaultProps = {
    title_t1: "Title 1",
    title_t2: "Title 2",
    height: 60,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  getXAxisLabels() {
    let result = new Set()
    this.props.data_t1.map((row) => {
      result.add(row[0])
    })
    this.props.data_t2.map((row) => {
      result.add(row[0])
    })
    result = Array.from(result).sort()

    return result
  }

  generateDatasets() {
    return [
      {
        label: this.props.title_t1,
        data: this.props.data_t1,
        fill: false,
        backgroundColor: "#7cb5ec",
        borderColor: "#7cb5ec",
        xScaleID: "x",
        yAxisID: "y",
        lineTension: 0.2,
        pointHitRadius: 100,
      },
      {
        label: this.props.title_t2,
        data: this.props.data_t2,
        fill: false,
        backgroundColor: "#434348",
        borderColor: "#434348",
        xScaleID: "x",
        yAxisID: "y",
        lineTension: 0.2,
        pointHitRadius: 100,
      },
    ]
  }

  generateAnnotations() {
    if (
      this.props.annotation_text &&
      this.props.data_t2 &&
      this.props.data_t2.length > 0
    ) {
      let x_labels = this.getXAxisLabels()
      let x_value = x_labels.indexOf(this.props.data_t2[0][0])
      let y_value = this.props.data_t2[0][1]
      return [
        {
          type: "line",
          yScaleID: "y",
          xScaleID: "x",
          yMin: y_value,
          yMax: y_value,
          xMin: x_value,
          xMax: x_value,
          borderColor: "transparent",
          borderWidth: 2,
          label: {
            font: {
              size: 12,
              style: "normal",
            },
            yAdjust: -30,
            rotation: "auto",
            backgroundColor: "rgba(30, 30, 30, 0.7)",
            content: this.props.annotation_text,
            enabled: true,
          },
        },
      ]
    } else {
      return []
    }
  }

  updateChart() {
    let _this = this
    this.setState({
      data: {
        labels: this.getXAxisLabels(),
        datasets: this.generateDatasets(),
      },
      chart_options: {
        responsive: true,
        interaction: {
          mode: "nearest",
          intersect: true,
        },
        layout: {
          padding: {
            top: 20,
          },
        },
        plugins: {
          title: {
            display: this.props.title != null,
            text: this.props.title,
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItems) {
                return new Date(
                  Number(tooltipItems[0].label)
                ).toLocaleDateString()
              },
              label: function (context) {
                const formatter = dataFormatter(
                  _this.props.dimension.toLowerCase().replaceAll(" ", "_")
                )
                return formatter(context.formattedValue)
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              padding: 20,
            },
          },
          annotation: {
            annotations: this.generateAnnotations(),
          },
        },
        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15,
              callback: function (value) {
                let label = this.getLabelForValue(value)
                const date = new Date(label)
                const month = date.toLocaleString("default", {
                  month: "short",
                })
                const day = date.getDate()
                return day + "." + month
              },
            },
          },
          y: {
            beginAtZero: true,
            stacked: false,
            type: "linear",
            position: "left",
            ticks: {
              maxTicksLimit: 6,
              callback: function (value) {
                value = value.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })

                const formatter = dataFormatter(
                  _this.props.dimension.toLowerCase().replaceAll(" ", "_")
                )
                return formatter (value)
              },
            },
          },
        },
      },
    })
  }

  render() {
    if (this.state.data) {
      return (
        <div>
          <Line
            key={JSON.stringify(this.props)}
            data={this.state.data}
            options={this.state.chart_options}
            height={this.props.height}
          />
        </div>
      )
    } else {
      return null
    }
  }
}
