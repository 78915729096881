import { SET_TOKEN, GET_USER, SELECT_CLIENT, VERIFY_PASSWORD_RESET_TOKEN } from './types'
import { put, takeEvery } from 'redux-saga/effects'
import { verifyPasswordResetToken as verifyPasswordResetTokenApi } from '../../api/auth'
import { getCurrentUser } from '../../api/users'
import { selectClient as selectClientApi } from '../../api/clients'
import { setToken as setTokenApi } from '../../api/request'
import { saveUser, saveToken, saveTokenIsValid } from './reducer' 
import FTNotice from '../../components/FTNotice'

function* setToken({token, url}) {
  setTokenApi(token)
  yield put(saveToken(token))
  const user = yield getCurrentUser()
  if (user != null) {
    yield put(saveUser(user))
  }
  if (url != null) {
    location.href = url
  }
}

function* getUser() {
  const user = yield getCurrentUser()
  if (user != null) {
    yield put(saveUser(user))
  }
}

function* selectClient({internalClientId}) {
  const client = yield selectClientApi(internalClientId)
  if (client != null) {
    location.reload() // too much has changed
  }
}

function* verifyPasswordResetToken({url}) {
  const result = yield verifyPasswordResetTokenApi(url)
  if (result && result.isValid) {
    yield put(saveTokenIsValid(true))
  } else {
    FTNotice('Invalid link, please generate a new link by clicking on forgot password');
    window.location.href='/';
  }
}

function* mySaga() {
  yield takeEvery(SET_TOKEN, setToken)
  yield takeEvery(GET_USER, getUser)
  yield takeEvery(SELECT_CLIENT, selectClient)
  yield takeEvery(VERIFY_PASSWORD_RESET_TOKEN, verifyPasswordResetToken)
}

export default mySaga;
