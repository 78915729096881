import * as React from 'react'
import Dialog from '../../widgets/Dialog'
import Button from '../../widgets/Button'

const BiddingSuccessDialog = (props) => {
  const handleClose = () => {
    if (!props.reverting) {
      props.setSuccessDialogOpen(false)
      window.location.href = `/#/bidding/`
    }
  }

  const handleClickSummary = () => {
    if (props.successDialogOpen) {
      props.setSuccessDialogOpen(false)
    }
    if (!props.bidSummaryOpen) {
      props.setBidSummaryOpen(true)
    }
  }

  const handleClickDetails = () => {
    if (props.successDialogOpen) {
      props.setSuccessDialogOpen(false)
    }
  }

  const handleClickRevert = () => {
    props.setRevertPathChosen(2)
    if (props.successDialogOpen) {
      props.setSuccessDialogOpen(false)
    }
    if (!props.bidSummaryOpen) {
      props.setBidSummaryOpen(true)
    }
  }

  return (
    <Dialog
      open={props.successDialogOpen ? true : false}
      onClose={handleClose}
      icon="success"
      title="biddingRun.confirmationBiddingRunPublished"
      text={props.isRevert ? 'biddingRun.revertSuccessMessage' : 'biddingRun.successMessage'}
      buttons={(
        <>
          <Button buttonText="biddingRun.revert" disabled={props.isRevert} onClick={handleClickRevert} color="error" variant="outlined" />
          <Button buttonText="biddingRun.summary" onClick={handleClickSummary} color="secondary" variant="outlined" />
          {props.status == 'published' && (
            <Button buttonText="biddingRun.details" onClick={handleClickDetails} color="secondary" variant="outlined" />
          )}
          <Button buttonText="biddingRun.saveAndClose" onClick={handleClose} color="primary" variant="outlined" />
        </>
      )}
    />
  )
}

export default BiddingSuccessDialog