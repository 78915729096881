import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { getClient } from '../../api/clients.js';

class Interface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: props.clients.length ? props.clients[0] : null,
      tasks: [],
      task: null
    };
  }

  componentDidMount() {
    if (this.state.client) {
      this.getTasks();
    }
  }

  getTasks() {
    getClient(this.state.client.internal_client_id).then(client => {
      this.setState({tasks: client.time_tasks, task: client.time_tasks.length ? client.time_tasks[0] : nll});
    });
  }

  ok() {
    this.props.action({
      internal_client_id: this.state.client.internal_client_id,
      client_name: this.state.client.name,
      task_id: this.state.task.id,
      task_name: this.state.task.name,
      total: 0
    });
  }

  updateClient(internal_client_id) {
    internal_client_id = parseInt(internal_client_id);
    this.props.clients.forEach(client => {
      if(client.internal_client_id == internal_client_id) {
        this.setState({client: client, tasks: [], task: null}, () => this.getTasks());
      }
    });
  }

  updateTask(id) {
    id = parseInt(id);
    this.state.tasks.forEach(task => {
      if(task.id == id) {
        this.setState({task: task});
      }
    });
  }

  cancel() {
    this.props.action(null);
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={() => this.cancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><b>Add Task</b></DialogTitle>
        <DialogContent>
          <table>
            <tbody>
              <tr>
                <td style={{padding: 5, textAlign: 'right'}}>Client</td>
                <td style={{padding: 5}}>
                  <select className="form-control" onChange={event => this.updateClient(event.target.value)}>
                   {this.props.clients.map((client, i) => {
                      return (<option key={i} value={client.internal_client_id}>{client.name}</option>);
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td style={{padding: 5, textAlign: 'right'}}>Task</td>
                <td style={{padding: 5}}>
                  <select className="form-control" onChange={event => this.updateTask(event.target.value)}>
                    {this.state.tasks.map((task, i) => {
                      return (<option key={i} value={task.id}>{task.name}</option>);
                    })}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <div className="text-center" style={{marginBottom: 20}}>
          {this.state.client && this.state.task && (
            <button className="btn btn-primary" onClick={() => this.ok()}>OK</button>
          )}
          <button className="btn btn-primary" onClick={() => this.cancel()} style={{marginLeft: 10}}>Cancel</button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clients: state.users.user ? state.users.user.clients : []
  };
}

export default connect(mapStateToProps)(Interface);
