import request from './request'
import querystring from 'querystring'

// exec_summary, change_history
export function getSummary(filters) {
  return request('/api/ads/summary?' + querystring.stringify(filters))
}

// all users
export function getLabels(internal_client_id) {
  return request(`/api/labels?internal_client_id=${internal_client_id}`)
}
