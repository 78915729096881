import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import BiddingAlgos from './BiddingAlgos'
import BiddingRuns from './BiddingRuns'
import Configuration from './Configuration'
import Flow from './Flow'
import Workflows from '../workflows/Interface'
import Test from './bidding_test/Interface'
import i18next from 'i18next'
import { FEEDS, BIDDING_TEST } from '../../routes/index'

class Bidding extends Component {
  constructor(props) {
    super(props)
    const tab = this.props.match.params.tab
    this.state = {
      tabIndex: tab == 'schedules' ? 1 : tab == 'workflows' ? 2 : tab == 'algos' ? 3 : tab == 'config' ? 4 : tab == 'flow' ? 5 : tab == 'test' ? 6 : 0,
      tabs: [
        {name: 'runs', text: i18next.t('bidding.biddingRuns'), component: <BiddingRuns filter="bidding_runs"/>},
        {name: 'schedules', text: i18next.t('bidding.schedules'), component: <BiddingRuns filter="schedules"/>},
        {name: 'workflows', text: i18next.t('bidding.workflows'), component: <Workflows type='bidding_workflows' />},
        {name: 'algos', text: i18next.t('bidding.algorithms'), component: <BiddingAlgos/>},
        {name: 'config', text: i18next.t('bidding.configuration'), component: <Configuration/>},
        {name: 'flow', text: i18next.t('bidding.flow'), component: <Flow />, hidden: !props.permissions.includes(FEEDS)},
        {name: 'test', text: i18next.t('bidding.test'), component: <Test />, hidden: !props.permissions.includes(BIDDING_TEST)},
      ]
    }
  }

  updateTab(index) {
    this.setState({tabIndex: index});
    window.location.href = "/#/bidding/" + this.state.tabs[index].name;
  }

  render() {
    return (
      <div className='site-canvas'>
        <section className='content'>
          <div>
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
              <TabList>
                {this.state.tabs.map(tab => {
                  return tab.hidden ? null : (
                    <Tab key={tab.name}>
                      {tab.text}
                    </Tab>
                  )
                })}
              </TabList>
              <div className="row">
                <div className="col-md-12">
                  <div className="box box-info">
                    {this.state.tabs.map(tab => {
                      return tab.hidden ? null :  (
                        <TabPanel key={tab.name}>
                          {tab.component}
                        </TabPanel>
                      )
                    })}
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    current_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    permissions: state.users.user ? state.users.user.permissions : [],
  }
}

export default withRouter(connect(mapStateToProps)(Bidding))
