import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import FTNotice from '../FTNotice'
import Form from '../Form'
import Field from '../Field'
import {
  getIcons,
  getDashboardById,
  addDashboard,
  editDashboard,
  removeDashboard,
  getAllSql
} from '../../api/dashboards'
import DashboardCanvas from '../dashboards/DashboardCanvas'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import querystring from 'querystring'
import i18next from 'i18next'
import Page from '../widgets/Page'
import Checkbox from '../widgets/Checkbox'
import Checkboxes from '../widgets/Checkboxes'

class EditDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.id == "new" ? null : props.match.params.id,
      admin_only: false,
      most_viewed: false, 
      title: "",
      description: "",
      icon: "",
      cards: null,
      internal_client_id: props.isAdmin ? "" : props.internal_client_id,
      location: "Dashboards",
      icons: [],
      tabIndex: this.props.match.params.aspect == "canvas" ? 1 : 0,
      all_sql: [],
    }
    this.loadIcons()
    const copy =
      props.location.search.length > 1
        ? querystring.parse(props.location.search.substring(1))["copy"]
        : null
    if (copy) {
      this.loadDashboard(copy, true)
    } else if (this.state.id != null) {
      this.loadDashboard(this.state.id, false)
    } else {
      this.state.cards = "[]"
      this.updateClient()
    }
  }
  
  updateClient() {
    getAllSql(false, this.state.internal_client_id).then((response) => {
      // Sort 5 most recent first and then alphabetical
      const allSql = response.results
      allSql.forEach((sql) => sql.created_at = sql.created_at == null ? '2021-09-13 12:00:00' : sql.created_at)
      allSql.sort((a, b) => b.created_at.localeCompare(a.created_at))
      allSql.forEach((sql, i) => sql.mostRecent = i < 5 ? 1 : 0)
      allSql.sort((a, b) => {
        let compare = b.mostRecent - a.mostRecent
        if (compare == 0 && a.mostRecent) {
          compare = b.created_at.localeCompare(a.created_at)
        }
        return compare == 0 ? a.name.localeCompare(b.name) : compare
      })
      this.setState({ all_sql: allSql })
    })
  }

  loadIcons() {
    getIcons().then((result) => {
      this.setState({ icons: result.data })
    })
  }

  loadDashboard(id, isCopy) {
    getDashboardById(id).then((dashboard) => {
      this.setState({
        id: isCopy ? null : dashboard.id,
        admin_only: dashboard.admin_only,
        most_viewed: dashboard.most_viewed,
        title: dashboard.title + (isCopy ? i18next.t('dashboard.copy') : ""),
        description: dashboard.description,
        icon: dashboard.icon,
        layout: dashboard.layout,
        location: dashboard.location,
        cards: dashboard.cards,
        internal_client_id: dashboard.internal_client_id == null ? "" : dashboard.internal_client_id,
      }, () => this.updateClient())
    })
  }

  save() {
    const values = Object.assign({}, this.state)
    values.internal_client_id =
      values.internal_client_id == "" ? null : parseInt(values.internal_client_id)
    delete values.icons
    delete values.tabs
    delete values.tabIndex
    delete values.variables
    delete values.all_sql
    if (this.state.title.trim() == "") {
      FTNotice('dashboard.enterName', 3000)
    } else if (this.state.icon == "") {
      FTNotice('dashboard.selectIcon', 3000)
    } else {
      const id = values.id
      const api_call = id ? editDashboard(values) : addDashboard(values)
      const action = id ? 'dashboard.couldNotBeUpdated' : 'dashboard.couldNotBeAdded'
      api_call
        .then((result) => {
          if (result.id) {
            if (this.state.id) {
              window.location.href = "/#/dashboard_admin/dashboards"
            } else {
              this.setState({ id: result.id }, () => {
                this.updateTab(1)
              })
            }
          } else {
            FTNotice(action)
          }
        })
        .catch(() => {
          FTNotice(action)
        })
    }
  }

  deleteDashboard() {
    removeDashboard(this.props.match.params.id)
      .then((result) => {
        if (result.success) {
          window.location.href = "/#/dashboard_admin/dashboards"
        } else {
          FTNotice('dashboard.couldNotBeDeleted')
        }
      })
      .catch(() => {
        FTNotice('dashboard.couldNotBeDeleted')
      })
  }

  updateTab(tabIndex) {
    this.setState({ tabIndex: tabIndex })
    window.location.href =
      "/#/dashboard_admin/dashboard/" +
      (this.state.id == null ? 'new' : this.state.id) +
      "/" +
      (tabIndex == 0 ? "overview" : "canvas")
  }

  overview() {
    return (
      <div>
        <Field label="dashboard.name">
          <input
            type="text"
            value={this.state.title}
            onChange={() => {
              this.setState({ title: event.target.value })
            }}
            className="form-control"
          />
        </Field>
        {this.props.isAdmin && (
          <Field label="dashboard.client">
            <select
              className="form-control"
              value={this.state.internal_client_id}
              onChange={event => this.setState({ internal_client_id: event.target.value }, this.updateClient())}
            >
              {this.props.clients.map((client) => {
                return (
                  <option
                    key={client.internal_client_id}
                    value={client.internal_client_id}
                  >
                    {client.name}
                  </option>
                )
              })}
              <option value={""}>{i18next.t('dashboard.all')}</option>
            </select>
          </Field>
        )}
        <Field label="dashboard.status">
          <Checkboxes>
            <Checkbox
              checked={this.state.most_viewed}
              onChange={() => this.setState({most_viewed: event.target.checked})}
              labelText={'dashboard.mostViewed'}/>
            <Checkbox
              checked={this.state.admin_only}
              onChange={() => this.setState({admin_only: event.target.checked})}
              labelText={'dashboard.adminOnly'}/>
          </Checkboxes>
        </Field>
        <Field label="dashboard.location">
          <select
            className="form-control"
            value={this.state.location}
            onChange={() => {
              this.setState({ location: event.target.value })
            }}
          >
            <option value={"Dashboards"}>{i18next.t('dashboard.dashboards')}</option>
            <option value={"Budget"}>{i18next.t('dashboard.budget')}</option>
          </select>
        </Field>
        <Field label="dashboard.description">
          <textarea
            value={this.state.description}
            onChange={() => {
              this.setState({ description: event.target.value })
            }}
            className="form-control"
            rows="5"
          />
        </Field>
        <Field label="dashboard.icon">
          <div>
            <Select
              className="center"
              value={this.state.icon}
              onChange={(event) => {
                this.setState({ icon: event.target.value })
              }}
              autoWidth
            >
              {this.state.icons.map((icon, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={"/resources/images/workflow_icons/" + icon}
                    style={{ width: "100px", height: "100px" }}
                  >
                    <img
                      src={"/resources/images/workflow_icons/" + icon}
                      className="dashboardIcon"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        </Field>
      </div>
    )
  }

  canvas() {
    if (this.state.cards != null) {
      const start_date = moment().subtract(28, "day").format("YYYY-MM-DD")
      const end_date = moment().subtract(1, "day").format("YYYY-MM-DD")
      if (this.state.internal_client_id != null)
        return (
          <DashboardCanvas
            editable
            cards={this.state.cards}
            internal_client_id={this.state.internal_client_id}
            action={(cards) => {
              this.setState({ cards: cards ? cards : this.state.cards })
            }}
            getVariables={(variables) => {
              this.setState({ variables: variables })
            }}
            start_date={start_date}
            end_date={end_date}
            icons={this.state.icons}
            dashboard_id={this.props.match.params.id}
            all_sql={this.state.all_sql}
          />
        )
      else {
        return null
      }
    }
  }

  render() {
    const tabs = [
      { id: "overview", name: i18next.t('dashboard.overview'), component: this.overview() },
      { id: "canvas", name: i18next.t('dashboard.canvas'), component: this.canvas() },
    ]
    return (
      <Page>
          <Form
            objectType="dashboard.dashboard"
            objectName={this.state.title}
            newObject={this.state.id == null}
            updateObject={() => {
              this.save()
            }}
            deleteObject={() => {
              this.deleteDashboard()
            }}
            parentLink={"/dashboard_admin/dashboards"}
            tabs={tabs}
            tabIndex={this.state.tabIndex}
            updateTab={(tabIndex) => {
              this.updateTab(tabIndex)
            }}
          />
        </Page>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default withRouter(connect(mapStateToProps)(EditDashboard))
