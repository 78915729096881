/*
An icon button that can be clicked to navigate somewhere.
  icon - What icon to show (url)
  onClick - What to do when pressed
  name (optional) - Name of the icon
  key (optional) - Needs to be unique if this is used an iterator (ex. map)
*/
const Icon = ({key, icon, onClick, name}) => {
  return (
    <span
      key={key}
      onClick={onClick}
      style={{
        display: "inline-block",
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: "center",
        maxWidth: 150,
        verticalAlign: "top",
        cursor: "pointer",
      }}
    >
      <img
        src={icon}
        height="100"
        className="dashboardImage"
      />
      {name && (
        <span>{name}</span>
      )}
    </span>
  )
}

export default Icon
