import * as React from 'react'
import i18next from 'i18next'
import { Box, Typography, Grid } from '@mui/material'
import BiddingViewSwitch from './BiddingViewSwitch'
import BidNameAndGenerate from './BidNameAndGenerate'
import BiddingDirection from './BiddingDirection'
import BiddingLookbackDuration from './BiddingLookbackDuration'
import BiddingHighLowVolume from './BiddingHighLowVolume'
import BidFloorCeiling from './BidFloorCeiling'
import BidRangePercent from './BidRangePercent'
import CampaignSelect from './CampaignSelect'
import SelectMetrics from './SelectMetrics'
import ImpressionShareFilter from './ImpressionShare'
import RemoveBidChanges from './RemoveBidChanges'
import Schedule from './Schedule'
import FullScreenDialog from './FullScreenDialog'
import BiddingSuccessDialog from './BiddingSuccessDialog'
import Page from '../../widgets/Page'
import Title from '../../widgets/Title'

const BiddingLayout = (props) => {
  // state for ensuring one of the performance metrics have been selected
  const [metricSelected, setMetricSelected] = React.useState(false)

  // watch for state changes of the metrics and set state of metricSelected  
  React.useEffect(() => {
    if (props.use_clicks || props.use_cpa || props.use_roas || props.use_conversion_rate || props.use_engagement_score_clicks || props.use_engagement_score_cost) {
      setMetricSelected(true)
    } else {
      setMetricSelected(false)
    }
  }, [
    props.use_clicks,
    props.use_cpa,
    props.use_roas,
    props.use_conversion_rate,
    props.use_engagement_score_clicks,
    props.use_engagement_score_cost,
  ])

  // full screen dialog state & effect to open when bid started
  const [bidSummaryOpen, setBidSummaryOpen] = React.useState(false)

  // checks to see if previous bid run id is not an empty string, if not empty, this is a revert run
  const [isRevert, setIsRevert] = React.useState(false)

  React.useEffect(() => {
    if (props.previous_bidding_run_id > 0) {
      setIsRevert(true)
    }
    if (props.previous_bidding_run_id > 0 && props.status == 'PENDING' && !bidSummaryOpen) {
      setBidSummaryOpen(true)
    }
  }, [props.previous_bidding_run_id])

  // lets tabs in full screen dialog know when the revert button was clicked from success dialog
  const [revertPathChosen, setRevertPathChosen] = React.useState(0)

  // success dialog opens when status is published
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false)

  // React.useEffect(() => {
  //   if (props.status == 'published' || props.status == "queued") {
  //     setSuccessDialogOpen(true)
  //   }
  // }, [])

  React.useEffect(() => {
    if (props.status == 'published' || props.status == 'queued') {
      setSuccessDialogOpen(true)

    }
  }, [props.status, bidSummaryOpen])

  return (
    <Page>
      <BiddingSuccessDialog
        bidSummaryOpen={bidSummaryOpen}
        setBidSummaryOpen={setBidSummaryOpen}
        successDialogOpen={successDialogOpen}
        setSuccessDialogOpen={setSuccessDialogOpen}
        reverting={props.reverting}
        handleRevert={props.handleRevert}
        status={props.status}
        isRevert={isRevert}
        setIsRevert={setIsRevert}
        previous_bidding_run_id={props.previous_bidding_run_id}
        currentBiddingRunId={props.currentBiddingRunId}
        setRevertPathChosen={setRevertPathChosen} />
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignContent: "center" }}>
        <Grid container spacing={1} sx={{ display: "flex", width: "100%", flexDirection: "column", alignContent: "center" }}>
          <Grid item lg={8}>
            <Grid container>
              <Grid item maxWidth="100%">
                <a className="btn btn-box-tool" style={{float: 'right', marginTop: 5, marginRight: 10}} onClick={() => window.location.href = `/#/bidding/runs/` + (props.schedule == null ? 'bidding_runs' : 'schedules')}>
                  <i className="fa fa-times"></i>
                </a>
                {
                  props.isAdmin && props.status != 'published' ? <BiddingViewSwitch onChange={props.toggleView} /> : null
                }
                <Title title={i18next.t('biddingRun.ftoBidding')} variant="h1" />
                <Box sx={{ paddingTop: 3, paddingBottom: 3, display: "flex" }}>
                  <Typography>{i18next.t('biddingRun.simpleBiddingViewDescription')}</Typography>
                </Box>
                <CampaignSelect
                  allCampaigns={props.allCampaigns}
                  allAdGroups={props.allAdGroups}
                  engines={props.engines}
                  allEngines={props.allEngines}
                  entityTypes={props.entityTypes}
                  allTypes={props.allTypes}
                  allLabels={props.allLabels}
                  labelSelectName={i18next.t('biddingRun.applyLabels')}
                  width="100%"
                  minWidth="100%"
                  onUpdate={props.onUpdate}
                  campaign_ids={props.campaign_ids}
                  ad_group_ids={props.ad_group_ids}
                  labels={props.labels}
                  excludeLabels={props.excludeLabels}
                  selectByLabel={props.selectByLabel}
                  status={props.status}
                />
                <BiddingDirection name={i18next.t('biddingRun.determineBiddingDirection')}
                  onChange={props.onChangeBidDirection}
                  status={props.status}
                  bidDirection={props.bidDirection} />
                
                <BiddingLookbackDuration name={i18next.t('biddingRun.setBidLookbackDuration')}
                  onChange={props.onChangeLookbackDuration}
                  status={props.status}
                  lookbackDuration={props.lookbackDuration} />

                <BiddingHighLowVolume
                  minPercentClicks={props.minPercentClicks}
                  onChangeMinPercentClicks={min_percent_clicks => props.onUpdate({ min_percent_clicks })}
                  minImpressions={props.minImpressions}
                  onChangeMinImpressions={min_impressions => props.onUpdate({ min_impressions })}
                  volume={props.volume}
                  onChangeVolume={volume => props.onUpdate({ volume })}
                  status={props.status}
                  roasFloor={props.roasFloor}
                  onChangeRoasFloor={roas_floor => props.onUpdate({ roas_floor })} />

                <BidFloorCeiling name={i18next.t('biddingRun.setBidFloorCeiling')}
                  bid_floor={props.bid_floor}
                  bid_ceiling={props.bid_ceiling}
                  status={props.status}
                  onUpdate={(attribute, value) => props.onUpdate(attribute, value)}
                />
                <BidRangePercent name={i18next.t('biddingRun.limitBidRange')}
                  bidLevels={props.bidLevels}
                  status={props.status}
                  onUpdate={(attribute, value) => props.onUpdate(attribute, value)}
                />
                <SelectMetrics name={i18next.t('biddingRun.choosePerformanceMetrics')}
                  use_clicks={props.use_clicks}
                  use_roas={props.use_roas}
                  use_cpa={props.use_cpa}
                  use_conversion_rate={props.use_conversion_rate}
                  use_engagement_score_clicks={props.use_engagement_score_clicks}
                  use_engagement_score_cost={props.use_engagement_score_cost}
                  onUpdate={newState => props.onUpdate(newState)}
                  metricSelected={metricSelected}
                  status={props.status}
                />
                <ImpressionShareFilter name={i18next.t('biddingRun.impressionShareFilter')}
                  max_impression_share={props.max_impression_share}
                  onUpdate={newState => props.onUpdate(newState)}
                  status={props.status}
                />
                <RemoveBidChanges name={i18next.t('biddingRun.removeMostRecentBidChanges')}
                  min_days_since_bidding={props.min_days_since_bidding}
                  onUpdate={newState => props.onUpdate(newState)}
                  status={props.status}
                />
                <Schedule
                  schedule={props.schedule}
                  onUpdate={newState => props.onUpdate(newState)}
                  status={props.status}
                />
              </Grid>

              <Grid item lg={12} display="flex" justifyContent="flex-start">
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}>
                  <BidNameAndGenerate
                    display="flex"
                    isAdmin={props.isAdmin}
                    name={i18next.t('biddingRun.bidRunName')}
                    bidRunName={props.bidRunName}
                    onChangeBidRunName={props.onChangeBidRunName}
                    generateBids={props.generateBids}
                    save={props.save}
                    bidding_run_id={props.bidding_run_id}
                    started={props.started}
                    status={props.status}
                    bidSummaryOpen={bidSummaryOpen}
                    setSuccessDialogOpen={setSuccessDialogOpen}
                    setBidSummaryOpen={setBidSummaryOpen}
                    metricSelected={metricSelected}
                    campaignIds={props.campaign_ids}
                    adGroupIds={props.ad_group_ids}
                    engines={props.engines}
                    entityTypes={props.entityTypes}
                    createAbTest={props.createAbTest}
                    schedule={props.schedule}
                    onUpdate={(attribute, value) => props.onUpdate(attribute, value)}
                  />
                </Box>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Box>
      <FullScreenDialog
        isAdmin={props.isAdmin}
        revertPathChosen={revertPathChosen}
        setRevertPathChosen={setRevertPathChosen}
        setSuccessDialogOpen={() => setSuccessDialogOpen()}
        bidSummaryOpen={bidSummaryOpen}
        previous_bidding_run_id={props.previous_bidding_run_id}
        setBidSummaryOpen={setBidSummaryOpen}
        isRevert={isRevert}
        currentBiddingRunId={props.currentBiddingRunId}
        reverting={props.reverting}
        {...props} />

    </Page>
  )
}

export default BiddingLayout