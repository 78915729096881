import { Stack } from "@mui/material"

/*
This uses an MUI Stack (https://mui.com/material-ui/api/stack/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.
*/
const FtoStack = (props) => {
  const defaultProps = {
    spacing: 3,
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { ...stackProps } = revisedProps
  return (
    <Stack {...stackProps} />
  )
}

export default FtoStack