import { Component } from 'react'
import withRouter from '../../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Backtesting from './Backtesting'
import Comparison from './Comparison'
import i18next from 'i18next'

class Interface extends Component {
  TABS = [
    {id: 'backtesting', name: i18next.t('biddingTest.backtesting'), component: <Backtesting/>},
    {id: 'comparison' , name: i18next.t('biddingTest.comparison'), component: <Comparison/>}
  ]

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: this.props.match && this.props.match.params.subtab == 'comparison' ? 1 : 0,
    }
  }

  updateTab(index) {
    this.props.history('/bidding/test/' + this.TABS[index].id)
    this.setState({tabIndex: index})
  }

  render() {
    return (
      <div className='site-canvas'>
        <div className="box" style={{height: 'fit-content', marginLeft: 16, width: 'calc(100% - 30px)', paddingBottom: 40}}>
          <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
            <TabList>
              {this.TABS.map((tab) => {
                return (
                  <Tab key={tab.id}>
                    {tab.name}
                  </Tab>
                )
              })}
            </TabList>
            {this.TABS.map((tab) => {
              return (
                <TabPanel key={tab.id}>
                  {tab.component}
                </TabPanel>
              )
            })}
          </Tabs>
        </div>       
      </div>
    )
  } 
}

export default withRouter(Interface);
