import { Checkbox, FormControlLabel } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI Checkbox (https://mui.com/material-ui/api/checkbox/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - the label to use for the checkbox (this should generally be an i18n key)
*/
const FtoCheckbox = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const { labelText, ...checkboxProps } = revisedProps
  return (
    <FormControlLabel control={<Checkbox {...checkboxProps} />} label={i18next.t(labelText)} />
  )
}

export default FtoCheckbox