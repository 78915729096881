import { Component } from 'react'
import { getRawData, saveRawData, getHistoricalFiles } from '../../api/feeds'
import Field from '../Field'
import FTNotice from '../FTNotice'
import { updateState } from '../../utilities/feed_utils'
import { downloadFile } from '../../utilities/utils'
import i18next from 'i18next'

export default class GetRawData extends Component {
  BING = 'BING'
  CLOUD_WATCH = 'CLOUD_WATCH'
  FACEBOOK = 'FACEBOOK'
  GOOGLE = 'GOOGLE'
  GOOGLE_ANALYTICS_V4 = 'GOOGLE_ANALYTICS_V4'
  GOOGLE_BIG_QUERY = 'GOOGLE_BIG_QUERY'
  GOOGLE_MAIL = 'GOOGLE_MAIL'
  GOOGLE_MERCHANT_CENTER = 'GOOGLE_MERCHANT_CENTER'
  GOOGLE_DISCOVERY = 'GOOGLE_DISCOVERY'
  GOOGLE_TRENDS = 'GOOGLE_TRENDS'
  LINKEDIN = 'LINKEDIN'
  RAKUTEN = 'RAKUTEN'
  SALESFORCE = 'SALESFORCE'
  SHOPIFY = 'SHOPIFY'
  URL = 'URL'

  CSV = 'CSV'
  JSON = 'JSON'
  XML = 'XML'

  ACCESS_KEY_ID = 'access_key_id'
  DESTINATION_TABLE = 'destination_table'
  EMAIL_RECIPIENTS = 'email_recipients'
  ENCODING = 'encoding'
  FILE_LOCATION = 'file_location'
  INPUT_FORMAT = 'input_format'
  INPUT_FORMAT_DETAILS = 'input_format_details'
  LOCATION_DETAILS = 'location_details'
  LOCATION_FILTERS = 'location_filters'
  SECRET_ACCESS_KEY = 'secret_access_key'
  SOURCE = 'source'
  
  static defaultProps = {
    saveUpdates: true,
    alignLeft: false,
    hideEmailRecipients: false,
    input_history: null,
  }
  state = {
    feed_id: parseInt(this.props.feed_id),
    source: this.URL,
    email_recipients: '',
    file_location: '',
    access_key_id: '',
    secret_access_key: '',
    input_format: this.CSV,
    encoding: '',
    input_format_details: '',
    location_details: '',
    include_zero_impressions: false,
    pull_data_day_by_day: false,
    by_campaign: false,
    location_filters: '',
    destination_table: '',
    include_prefix: true,
    links: [],
    link_error: null,
  }

  componentDidMount() {
    if (this.props.feed_id !== undefined) {
      getRawData(this.props.feed_id).then((response) => {
        let result = response['result']
        if (result) {
          this.forceNotNull(result, this.INPUT_FORMAT_DETAILS)
          this.forceNotNull(result, this.LOCATION_DETAILS)
          this.forceNotNull(result, this.LOCATION_FILTERS)
          this.forceNotNull(result, this.SECRET_ACCESS_KEY)
          this.forceNotNull(result, this.ACCESS_KEY_ID)
          this.forceNotNull(result, this.ENCODING)
          this.setState(result, () => this.getHistoricalFiles())
        }
      })
    } else if (this.props.input_history !== null) {
      this.setState(this.props.input_history)
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.input_history, this.props.input_history)) {
      this.setState(this.props.input_history)
    }
  }

  getHistoricalFiles() {
    getHistoricalFiles({
      action: 'list',
      url: this.state.file_location,
      username: this.state.access_key_id,
      password: this.state.secret_access_key,
    }).then((result) => {
      const error = i18next.t('feeds.rawData.couldNotFindFiles')
      if (result) {
        const links = result.files ? result.files : []
        this.setState({
          links: links,
          link_error: result.error || links.length ? result.error : error,
        })
      } else {
        this.setState({ links: [], link_error: error })
      }
    })
  }

  getHistoricalFile(url) {
    getHistoricalFiles({
      action: 'get',
      url: url,
      username: this.state.access_key_id,
      password: this.state.secret_access_key,
    }).then((result) => {
      if (result.url) {
        downloadFile(result.url)
      }
      if (result.error) {
        FTNotice(result.error, 15000)
      }
    })
  }

  forceNotNull(result, field) {
    if (result[field] == null) {
      result[field] = ''
    }
  }

  saveData() {
    const errorMessage = this.getErrorMessage()
    if (errorMessage) {
      FTNotice(errorMessage, 3000)
    } else {
      let data = Object.assign({}, this.state)
      delete data.links
      delete data.link_error
      delete data.internal_client_id
      saveRawData(data).then((response) => {
        if (response && response['success']) {
          if (this.props.isChanged !== undefined) {
            this.props.isChanged(false)
          }
          FTNotice('feeds.rawData.feedSaved', 3000)
        } else {
          FTNotice(response.error ? response.error : 'feeds.rawData.errorSaving', 15000) 
        }
      })
    }
  }

  renderFileLinks() {
    return (
      <div>
        {this.state.link_error && (
          <div style={{ marginLeft: '10px', color: 'red' }}>
            {this.state.link_error}
          </div>
        )}
        <table style={{ marginLeft: '10px' }}>
          {this.state.links.map((link, i) => {
            return (
              <tr key={i}>
                <td>
                  <a onClick={() => this.getHistoricalFile(link.name)}>
                    {link.name}
                  </a>
                </td>
                <td style={{ paddingLeft: 10 }}>
                  {link.date
                    ? moment(link.date + '+00:00', moment.ISO_8601).format(
                        'M/D/YYYY h:mm a'
                      )
                    : ''}
                </td>
              </tr>
            )
          })}
        </table>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Field label="feeds.rawData.source" alignLeft={this.props.alignLeft}>
          <select
            value={this.state.source}
            onChange={event => updateState(this.SOURCE, event.target.value, this)}
            className="form-control"
          >
            <option value="URL">{i18next.t('feeds.rawData.url')}</option>
            <option value="BING">{i18next.t('feeds.rawData.bing')}</option>
            <option value="CLOUD_WATCH">{i18next.t('feeds.rawData.cloudWatch')}</option>
            <option value="FACEBOOK">{i18next.t('feeds.rawData.facebook')}</option>
            <option value="GOOGLE">{i18next.t('feeds.rawData.google')}</option>
            <option value="GOOGLE_ANALYTICS_V4">{i18next.t('feeds.rawData.googleAnalyticsV4')}</option>
            <option value="GOOGLE_BIG_QUERY">{i18next.t('feeds.rawData.googleBigQuery')}</option>
            <option value="GOOGLE_MAIL">{i18next.t('feeds.rawData.googleMail')}</option>
            <option value="GOOGLE_MERCHANT_CENTER">{i18next.t('feeds.rawData.googleMerchantCenter')}</option>
            <option value="GOOGLE_DISCOVERY">{i18next.t('feeds.rawData.googleSearchConsole')}</option>
            <option value="GOOGLE_TRENDS">{i18next.t('feeds.rawData.googleTrends')}</option>
            <option value="LINKEDIN">{i18next.t('feeds.rawData.linkedIn')}</option>
            <option value="RAKUTEN">{i18next.t('feeds.rawData.rakuten')}</option>
            <option value="SALESFORCE">{i18next.t('feeds.rawData.salesforce')}</option>
            <option value="SHOPIFY">{i18next.t('feeds.rawData.shopify')}</option>
            <option value="SQL">{i18next.t('feeds.rawData.sql')}</option>
          </select>
        </Field>
        {this.state.source == this.URL && (
          <>
            <Field
              label="feeds.rawData.userName"
              alignLeft={this.props.alignLeft}
            >
              <input
                type="text"
                value={this.state.access_key_id}
                onBlur={() => this.getHistoricalFiles()}
                onChange={event =>
                  updateState(this.ACCESS_KEY_ID, event.target.value, this)
                }
                className="form-control"
              />
            </Field>
            <Field
              label="feeds.rawData.password"
              alignLeft={this.props.alignLeft}
            >
              <input
                type="text"
                value={this.state.secret_access_key}
                onBlur={() => this.getHistoricalFiles()}
                onChange={event =>
                  updateState(this.SECRET_ACCESS_KEY, event.target.value, this)
                }
                className="form-control"
              />
            </Field>
          </>
        )}
        {[this.GOOGLE].includes(
          this.state.source
        ) && (
          <Field
            label={
              <span>
                {i18next.t('feeds.rawData.googleQuery')}&nbsp;
                <a
                  href="https://developers.google.com/google-ads/api/fields/v10/overview_query_builder"
                  target="_blank"
                >
                  {i18next.t('feeds.rawData.documentation')}
                </a>
                {i18next.t('feeds.rawData.endParenthesis')}
              </span>
            }
            alignLeft={this.props.alignLeft}
          >
            <textarea
              value={this.state.file_location}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {[this.GOOGLE_BIG_QUERY].includes(
          this.state.source
        ) && (
          <Field
            label={
              <span>
                {i18next.t('feeds.rawData.googleBigQueryLanguage')}&nbsp;
                <a
                  href="https://cloud.google.com/bigquery/docs/reference/standard-sql/query-syntax"
                  target="_blank"
                >
                  {i18next.t('feeds.rawData.documentation')}
                </a>
                {i18next.t('feeds.rawData.endParenthesis')}
              </span>
            }
            alignLeft={this.props.alignLeft}
          >
            <textarea
              value={this.state.file_location}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {[this.GOOGLE_MERCHANT_CENTER].includes(
          this.state.source
        ) && (
          <Field
            label={
              <span>
                {i18next.t('feeds.rawData.googleMerchantQuery')}&nbsp;
                <a
                  href="https://developers.google.com/shopping-content/guides/reports/query-language/overview"
                  target="_blank"
                >
                  {i18next.t('feeds.rawData.documentation')}
                </a>
                {i18next.t('feeds.rawData.endParenthesis')}
              </span>
            }
            alignLeft={this.props.alignLeft}
          >
            <textarea
              value={this.state.file_location}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {[this.SALESFORCE].includes(
          this.state.source
        ) && (
          <Field
            label={
              <span>
                {i18next.t('feeds.rawData.salesforceQuery')}&nbsp;
                <a
                  href="https://developer.salesforce.com/docs/atlas.en-us.object_reference.meta/object_reference/sforce_api_objects_list.htm"
                  target="_blank"
                >
                  {i18next.t('feeds.rawData.documentation')}
                </a>
                {i18next.t('feeds.rawData.endParenthesis')}
              </span>
            }
            alignLeft={this.props.alignLeft}
          >
            <textarea
              value={this.state.file_location}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {[this.GOOGLE_ANALYTICS_V4].includes(
          this.state.source
        ) && (
          <Field
            alignLeft={this.props.alignLeft}
            label={
              <span>
                {i18next.t('feeds.rawData.dimensions')}&nbsp;
                <a
                  href="https://developers.google.com/analytics/devguides/reporting/data/v1/api-schema#dimensions"
                  target="_blank"
                >
                  {i18next.t('feeds.rawData.options')}
                </a>
                {i18next.t('feeds.rawData.endParenthesis')}
              </span>
            }
          >
            <textarea
              value={this.state.file_location}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {![this.GOOGLE, this.GOOGLE_BIG_QUERY, this.GOOGLE_MERCHANT_CENTER, this.GOOGLE_ANALYTICS_V4, this.SALESFORCE, this.GOOGLE_MAIL].includes(
          this.state.source
        ) && (
          <Field
            alignLeft={this.props.alignLeft}
            label={
              this.state.source == this.URL ? (
                <span title={i18next.t('feeds.rawData.urlHelp')}>
                  {i18next.t('feeds.rawData.urlValue')}
                </span>
              ) : this.state.source == this.LINKEDIN ? (
                <span>
                  {i18next.t('feeds.rawData.linkedInReport')}&nbsp;
                  <a
                    target="_blank"
                    href="https://docs.microsoft.com/en-us/linkedin/marketing/integrations/ads-reporting/getting-started?tabs=http"
                    style={{ cursor: 'pointer' }}
                  >
                    {i18next.t('feeds.rawData.here')}
                  </a>&nbsp;
                  {i18next.t('feeds.rawData.forDetails')}
                </span>
              ) : this.state.source == this.GOOGLE_DISCOVERY ? (
                'feeds.rawData.googleSearchConsoleReport'
              ) : this.state.source == this.BING ? (
                <span>
                  {i18next.t('feeds.rawData.bingReport')}
                  <br />
                  {i18next.t('feeds.rawData.bingStructure')}&nbsp;
                  <a
                    target="_blank"
                    href="https://docs.microsoft.com/en-us/advertising/bulk-service/downloadentity?view=bingads-13"
                    style={{ cursor: 'pointer' }}
                  >
                    {i18next.t('feeds.rawData.here')}
                  </a>&nbsp;
                  {i18next.t('feeds.rawData.bingStructureOptions')}
                  <br />
                  {i18next.t('feeds.rawData.bingCost')}&nbsp;
                  <a
                    target="_blank"
                    href="https://docs.microsoft.com/en-us/advertising/guides/report-types?view=bingads-13"
                    style={{ cursor: 'pointer' }}
                  >
                    {i18next.t('feeds.rawData.here')}
                  </a>&nbsp;
                  {i18next.t('feeds.rawData.bingCostOptions')}
                </span>
              ) : this.state.source == this.FACEBOOK ? 
                'feeds.rawData.facebookReport'
              : this.state.source == this.SQL ? 
                'feeds.rawData.sqlReport'
              : this.state.source == this.RAKUTEN ? 
                'feeds.rawData.rakutenReport'
              : this.state.source == this.SHOPIFY ? 
                'feeds.rawData.shopifyReport'
              : this.state.source == this.GOOGLE_TRENDS ? (
                <span>
                  {i18next.t('feeds.rawData.googleTrendsReport')}&nbsp;
                  <a
                    target="_blank"
                    href="https://pypi.org/project/pytrends/"
                    style={{ cursor: 'pointer' }}
                  >
                    {i18next.t('feeds.rawData.here')}
                  </a>&nbsp;
                  {i18next.t('feeds.rawData.forOptions')}
                </span>
              ) : this.state.source == this.CLOUD_WATCH ? 
                'feeds.rawData.cloudWatchReport'
                :
                ''
            }
          >
            <input
              type="text"
              value={this.state.file_location}
              onBlur={() => this.getHistoricalFiles()}
              onChange={event =>
                updateState(this.FILE_LOCATION, event.target.value, this)
              }
              className="form-control"
            />
          </Field>
        )}
        {this.state.source == this.GOOGLE_MAIL && (
          <>
            <Field
              alignLeft={this.props.alignLeft}
              label="feeds.rawData.emailQuery"
            >
              <input
                type="text"
                value={this.state.file_location.split('|||')[0]}
                onChange={event => updateState(this.FILE_LOCATION, event.target.value + (this.state.file_location.indexOf('|||') >= 0 ? ('|||' + this.state.file_location.split('|||')[1]) : ''), this)}
                className="form-control"
              />
            </Field>
            <Field
              alignLeft={this.props.alignLeft}
              label="feeds.rawData.emailAttachment"
            >
              <input
                type="text"
                value={this.state.file_location.indexOf('|||') >= 0 ? this.state.file_location.split('|||')[1] : ''}
                onChange={event => updateState(this.FILE_LOCATION, this.state.file_location.split('|||')[0] + '|||' + event.target.value, this)}
                className="form-control"
              />
            </Field>
          </>
        )}
        {!this.props.hideEmailRecipients && (this.state.source == this.URL || this.state.source == this.GOOGLE_MAIL) && (
          <Field
            label="feeds.rawData.emailRecipients"
            alignLeft={this.props.alignLeft}
          >
            <input
              type="text"
              value={this.state.email_recipients}
              onChange={event =>
                updateState(this.EMAIL_RECIPIENTS, event.target.value, this)
              }
              className="form-control"
            />
          </Field>
        )}
        {this.state.source == this.URL && (
          <div>
            <Field alignLeft={this.props.alignLeft} label="feeds.rawData.fileLinks">
              {this.renderFileLinks()}
            </Field>
          </div>
        )}
        {(this.state.source == this.URL || this.state.source == this.SQL || this.state.source == this.GOOGLE_MAIL) && (
          <div>
            <Field alignLeft={this.props.alignLeft} label="feeds.rawData.fileFormat">
              <select
                value={this.state.input_format}
                onChange={event =>
                  updateState(this.INPUT_FORMAT, event.target.value, this)
                }
                className="form-control"
              >
                <option value="CSV">{i18next.t('feeds.rawData.csv')}</option>
                <option value="JSON">{i18next.t('feeds.rawData.json')}</option>
                <option value="TAB">{i18next.t('feeds.rawData.tabDelimited')}</option>
                <option value="PIPE">{i18next.t('feeds.rawData.pipeDelimited')}</option>
                <option value="XML">{i18next.t('feeds.rawData.xml')}</option>
              </select>
            </Field>
            <Field
              alignLeft={this.props.alignLeft}
              label={
                <span>
                  {i18next.t('feeds.rawData.encoding')}&nbsp;
                  <a
                    href="https://docs.python.org/3/library/codecs.html#standard-encodings"
                    target="_blank"
                  >
                    {i18next.t('feeds.rawData.options')}
                  </a>
                  {i18next.t('feeds.rawData.endParenthesis')}
                </span>
              }
            >
              <input
                type="text"
                value={this.state.encoding}
                onChange={event =>
                  updateState(this.ENCODING, event.target.value, this)
                }
                className="form-control"
              />
            </Field>
          </div>
        )}
        {(this.state.input_format == this.XML ||
          this.state.input_format == this.JSON ||
          this.state.source == this.SHOPIFY) && (
          <Field
            alignLeft={this.props.alignLeft}
            label={
              this.state.source == this.SHOPIFY
                ? 'feeds.rawData.shopifyFileFormatDetails'
                : 'feeds.rawData.fileFormatDetails'
            }
          >
            <input
              type="text"
              value={this.state.input_format_details}
              onChange={event =>
                updateState(this.INPUT_FORMAT_DETAILS, event.target.value, this)
              }
              className="form-control"
              id="input-format-details"
            />
          </Field>
        )}
        {[
          this.GOOGLE_ANALYTICS_V4,
          this.GOOGLE_MAIL,
          this.GOOGLE_DISCOVERY,
          this.GOOGLE_TRENDS,
          this.FACEBOOK,
          this.BING,
          this.URL,
          this.LINKEDIN,
          this.CLOUD_WATCH
        ].includes(this.state.source) && (
          <Field
            alignLeft={this.props.alignLeft}
            label={
              this.state.source == this.URL || this.state.source == this.GOOGLE_MAIL ?
                'feeds.rawData.urlColumns'
              : this.state.source == this.LINKEDIN ?
                'feeds.rawData.linkedInColumns'
              : this.state.source == this.GOOGLE_TRENDS ?
                'feeds.rawData.googleTrendsColumns'
              : [this.GOOGLE_ANALYTICS_V4].includes(
                  this.state.source
              ) ? (
                <span>
                  {i18next.t('feeds.rawData.googleAnalyticsColumns')}&nbsp;
                  <a
                    href="https://developers.google.com/analytics/devguides/reporting/data/v1/api-schema#metrics"
                    target="_blank"
                  >
                    {i18next.t('feeds.rawData.options')}
                  </a>
                  {i18next.t('feeds.rawData.endParenthesis')}
                </span>
              ) :
                'feeds.rawData.columns'
            }
          >
            <textarea
              value={this.state.location_details}
              onChange={event =>
                updateState(this.LOCATION_DETAILS, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        {this.state.input_format == this.XML && (
          <Field alignLeft={this.props.alignLeft} label="feeds.rawData.optionsValue">
            <div>
              <input
                type="checkbox"
                checked={this.state.include_prefix}
                onClick={event => {
                  this.setState({ include_prefix: event.target.checked })
                }}
              />
              {i18next.t('feeds.rawData.includePrefix')}
            </div>
          </Field>
        )}
        {this.state.source == this.FACEBOOK && (
          <Field alignLeft={this.props.alignLeft} label="feeds.rawData.optionsValue">
            <div>
              <input
                type="checkbox"
                checked={this.state.by_campaign}
                onClick={event => {
                  this.setState({ by_campaign: event.target.checked })
                }}
              />
              {i18next.t('feeds.rawData.byCampaign')}
            </div>
          </Field>
        )}
        {[
          this.FACEBOOK,
          this.LINKEDIN,
          this.GOOGLE_TRENDS,
        ].includes(this.state.source) && (
          <Field
            alignLeft={this.props.alignLeft}
            label={
              this.state.source == this.LINKEDIN
                ? i18next.t('feeds.rawData.linkedInParameters')
                : this.state.source == this.GOOGLE_TRENDS
                ? i18next.t('feeds.rawData.googleTrendsParameters')
                : i18next.t('feeds.rawData.filters')
            }
          >
            <textarea
              value={this.state.location_filters}
              onChange={event =>
                updateState(this.LOCATION_FILTERS, event.target.value, this)
              }
              className="form-control"
              rows="6"
            />
          </Field>
        )}
        <Field
          alignLeft={this.props.alignLeft}
          label="feeds.rawData.databaseTable"
        >
          <input
            type="text"
            value={this.state.destination_table}
            onChange={event =>
              updateState(this.DESTINATION_TABLE, event.target.value, this)
            }
            className="form-control"
          />
        </Field>

        <div className="col-md-12 mt-25 text-center">
          {this.props.saveUpdates && (
            <button
              name="edit"
              className="btn btn-primary"
              onClick={() => {
                this.saveData()
              }}
            >
              {i18next.t('feeds.rawData.updateFeed')}
            </button>
          )}
        </div>
      </div>
    )
  }

  getErrorMessage() {
    const source = this.state.source
    if (source == this.URL) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterUrl'
      }
      if (
        this.state.input_format_details == '' &&
        this.state.input_format == this.XML
      ) {
        return 'feeds.rawData.enterFileFormatDetails'
      }
    } else if (source == this.GOOGLE_MAIL) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterEmailQuery'
      }
      if (
        this.state.input_format_details == '' &&
        this.state.input_format == this.XML
      ) {
        return 'feeds.rawData.enterFileFormatDetails'
      }
    } else if (source == this.GOOGLE || source == this.SALESFORCE) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterQuery'
      }
    } else if (source == this.GOOGLE_DISCOVERY) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterService'
      }
      if (this.state.location_details == '') {
        return 'feeds.rawData.enterColumns'
      }
    } else if (source == this.GOOGLE_TRENDS) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterFunctionName'
      }
    } else if (source == this.CLOUD_WATCH) {
      if (this.state.file_location == '') {
        return 'feeds.rawData.enterLogGroup'
      }
      if (this.state.location_details == '') {
        return 'feeds.rawData.enterColumns'
      }
    } else {
      if (this.state.file_location == '') {
        if (this.state.source == this.SQL) {
          return 'feeds.rawData.enterSql'
        } else {
          return 'feeds.rawData.enterReport'
        }
      }
    }
    if (this.state.destination_table == '') {
      return 'feeds.rawData.enterDestinationTable'
    }
    return null
  }
}
