import request from './request';
import querystring from 'querystring';

// ab_tests
export function getAbTestResults(internal_client_id, type, table_data) {
  const url = '/api/ab_tests/results?internal_client_id=' + internal_client_id + '&type=' + type + '&' + querystring.stringify(table_data);
  return request(url);
}

// ab_tests
export function getSummaryData(id, data) {
  const url = '/api/ab_tests/' + id + '/summary?' + querystring.stringify(data);
  return request(url);
}

// ab_tests
export function getDailyData(id, data) {
  const url = '/api/ab_tests/' + id + '/daily?' + querystring.stringify(data);
  return request(url);
}

// manage_ab_tests
export function getAbTests(internal_client_id, type, tableData) {
  return request('/api/ab_tests?internal_client_id=' + internal_client_id + '&type=' + type + '&' + querystring.stringify(tableData));
}

// manage_ab_tests
export function getAbTest(id) {
  return request('/api/ab_tests/' + id);
}

// manage_ab_tests
export function createAbTest(data) {
  return request('/api/ab_tests', {}, 'POST', data);
}

// manage_ab_tests
export function updateAbTest(id, data) {
  return request('/api/ab_tests/' + id, {}, 'PUT', data);
}

// manage_ab_tests
export function patchAbTest(id, data) {
  return request('/api/ab_tests/' + id, {}, 'PATCH', data);
}

// manage_ab_tests
export function deleteAbTest(id) {
  return request('/api/ab_tests/' + id, {}, 'DELETE');
}

// manage_ab_tests
export function previewAbTestQuery(sql, table_data) {
  return request('/api/ab_tests/preview?' + querystring.stringify(table_data), {sql: sql}, 'POST', {sql: sql});
}

// manage_ab_tests
export function exportAbTestQuery(sql, table_data) {
  return request('/api/ab_tests/export?' + querystring.stringify(table_data), {sql: sql}, 'POST', {sql: sql});
}
