import { Component } from "react";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "../widgets/Table";
import FTNotice from "../FTNotice";
import { getServiceHistory } from "../../api/services";
import { exportSql } from "../../api/dashboards";

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_dialog: false,
      dialog_title: "",
      dialog_content: "",
    };
  }

  prettify(value) {
    if (value != null)
      return value
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  }

  getColumns() {
    return [
      {
        id: "service_type",
        header: "Service Type",
        datatype: "text",
        format: (value) => this.prettify(value),
        width: 180,
      },
      {
        id: "run_id",
        header: "Run ID",
        datatype: "text",
        width: 300,
        cellRenderer: (_, row) => this.renderRunIDLink(row),
      },
      {
        id: "created_at",
        header: "Created At",
        datatype: "date",
        width: 160,
      },
      {
        id: "user_name",
        header: "User",
        datatype: "text",
        width: 150,
      },
      {
        id: "input",
        header: "Input",
        datatype: "text",
        width: 150,
        cellRenderer: (_, row) => this.renderInput(row),
      },
      {
        id: "output",
        header: "Output",
        datatype: "text",
        width: 150,
        cellRenderer: (_, row) => this.renderOutput(row),
      },
      {
        id: "status",
        header: "Status",
        datatype: "text",
        width: 120,
        cellRenderer: (_, row) => this.renderStatus(row),
      },
    ];
  }

  renderRunIDLink(original) {
    const id = original.service_id ? original.service_id : 0;
    return (
      <div>
        <Link
          to={
            "/services/" +
            original.service_type +
            "/" +
            id +
            "/run/" +
            original.run_id
          }
          onClick={() => this.props.toRunTab && this.props.toRunTab()}
        >
          {original.run_id}
        </Link>
      </div>
    );
  }

  renderInput(run) {
    const inputs = [];

    if (run.service_type == "pull_data") {
      run.input_presigned_url = null;
      inputs.push({
        name: "input",
        value: JSON.stringify(run.input),
      });
    } else {
      Object.keys(run.input).forEach((key) => {
        inputs.push({
          name: this.prettify(key),
          value: run.input[key],
        });
      });
      inputs.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    return (
      <div>
        {inputs.map((input, i) => {
          let value = input.value;
          if (value != null && typeof value == "object") {
            value = JSON.stringify(value);
          }
          return (
            <button
              className="btn-link"
              key={i}
              onClick={() =>
                this.setState({
                  open_dialog: true,
                  dialog_title: input.name,
                  dialog_content: value,
                })
              }
            >
              {input.name}
            </button>
          );
        })}
        {run.input_presigned_url && (
          <div>
            <a
              href={run.input_presigned_url}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <button className="btn-link">Download File</button>
            </a>
          </div>
        )}
      </div>
    );
  }

  downloadFile(outputQuery) {
    exportSql(-1, {
      sql: outputQuery.sql,
      database: outputQuery.database,
    }).then((result) => {
      if (result.presigned_url) {
        const link = document.createElement("a");
        link.href = result.presigned_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (result.error) {
        FTNotice(result.error, 15000);
      }
    });
  }

  renderOutput(run) {
    return (
      <div>
        {run.output_presigned_url && (
          <div>
            <a
              href={run.output_presigned_url}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <button className="btn-link">Download File</button>
            </a>
          </div>
        )}
        {run.output_query && (
          <div>
            <button
              className="btn-link"
              onClick={() => this.downloadFile(run.output_query)}
            >
              Download File
            </button>
          </div>
        )}
      </div>
    );
  }

  renderStatus(history) {
    const status = this.prettify(history.status);
    return (
      <div>
        {history.message == "" && status}
        {history.message != "" && (
          <button
            className="btn-link"
            onClick={() =>
              this.setState({
                open_dialog: true,
                dialog_title: "Message",
                dialog_content: history.message,
              })
            }
          >
            {status}
          </button>
        )}
      </div>
    );
  }

  renderInputDialog() {
    return (
      <Dialog
        open={this.state.open_dialog}
        onClose={() =>
          this.setState({ open_dialog: false, dialog_content: "" })
        }
        maxWidth="md"
      >
        <DialogTitle>
          <b>{this.state.dialog_title}</b>
        </DialogTitle>
        <DialogContent dividers>
          <SyntaxHighlighter
            language="sql"
            style={docco}
            customStyle={{ height: "300px", marginTop: "1px" }}
          >
            {this.state.dialog_content}
          </SyntaxHighlighter>
        </DialogContent>
      </Dialog>
    );
  }

  renderTable() {
    return (
      <div className="box-body no-padding" style={{ marginTop: "15px" }}>
        <Table
          fetch={(tableData) => {
            return getServiceHistory(
              this.props.internal_client_id,
              this.props.service_type,
              tableData
            );
          }}
          columns={this.getColumns()}
          rowHeight={100}
        />
        {this.state.loaded && <span className="js_complete"></span>}
      </div>
    );
  }

  render() {
    return (
      <div className="site-canvas" style={{ minHeight: "540px" }}>
        <section className="content">{this.renderTable()}</section>
        {this.renderInputDialog()}
      </div>
    );
  }
}
