import request from './request'
import querystring from 'querystring'

const url = '/api/workflows';

// workflows
export function getPipelines(internal_client_id, type='workflows') {
  return request(url + `?type=${type}&internal_client_id=${internal_client_id}`)
}

// workflows
export function getPipeline(id) {
  return request(url + '?type=workflow&id='+id)
}

// workflows
export function addEditPipeline(data) {
  return request(url+ '?type=workflow', {}, 'POST', data)
}

// workflows
export function getIcons() {
  return request(url + '?type=icons')
}

// workflows
export function deletePipeline(id) {
  return request(url + '?type=workflow&id='+id, {}, 'DELETE');
}

// workflows
export function getVariables(workflow_id, run_id, internal_client_id) {
  return request(url + '?type=variables&workflow_id='+workflow_id+'&run_id='+run_id+'&internal_client_id='+internal_client_id);
}

// workflows
export function updateVariables(data, workflow_id, internal_client_id, all_clients) {
  return request(`${url}?type=variables&workflow_id=${workflow_id}&internal_client_id=${internal_client_id}&all_clients=${all_clients}`, {}, 'POST', data);
}

// workflows
export function getHistory(workflowId, internal_client_id, table_data) {
  return request('/api/workflows/' + workflowId + '/history?internal_client_id=' + internal_client_id + '&' + querystring.stringify(table_data));
}

// workflows
export function getSteps(workflowId, run_id) {
  return request('/api/workflows/' + workflowId + '/steps?run_id=' + run_id);
}

// workflows
export function getStep(stepId) {
  return request('/api/workflow_steps/' + stepId);
}

// workflows
export function getStepHistory(stepId, runId) {
  return request('/api/workflow_steps/' + stepId + '/' + runId);
}

// workflows
export function createStep(data) {
  return request('/api/workflows/' + data['workflow_id'] + '/steps', {}, 'POST', data);
}

// workflows
export function patchStep(stepId, data) {
  return request('/api/workflow_steps/' + stepId, {}, 'PATCH', data);
}

// workflows
export function saveStep(stepId, data) {
  return request('/api/workflow_steps/' + stepId, {}, 'PUT', data);
}

// workflows
export function deleteStep(stepId) {
  return request('/api/workflow_steps/' + stepId, {}, 'DELETE', {});
}


// workflows
export function updateHistory(run_id, data) {
  return request('/api/workflow_runs/' + run_id, {}, 'POST', data);
}

// workflows
export function deleteRun(runId) {
  return request('/api/workflow_runs/' + runId, {}, 'DELETE', {});
}

// workflows
export function getLastStep(runId) {
  return request('/api/workflow_runs/' + runId);
}

// workflows
export function getRunColumns(stepId, data) {
  return request('/api/workflow_steps/' + stepId + '?type=columns', {}, 'POST', data);
}

// workflows
export function getRunResult(stepId, data) {
  return request('/api/workflow_steps/' + stepId + '?type=result', {}, 'POST', data);
}

// workflows
export function exportRunResult(stepId, data) {
  return request('/api/workflow_steps/' + stepId + '?type=export', {}, 'POST', data);
}

// workflows
export function fetchResultFromS3(filename) {
  return request('/api/workflow_steps/0' + '?type=result_s3&filename=' + filename);
}

// workflows, feeds, revenue_workflow
export function getTriggerableDags() {
  return request('/api/workflow_steps/0' + '?type=triggerable_dag');
}

// workflows, budget_admin
export function createRunResult(stepId, data) {
  return request('/api/workflow_steps/' + (stepId == null ? '-1' : stepId) + '?type=create', {}, 'POST', data);
}

// workflows
export function updateRunResult(stepId, data) {
  return request('/api/workflow_steps/' + stepId + '?type=update', {}, 'POST', data);
}

// workflows
export function deleteRunResult(stepId, data) {
  return request('/api/workflow_steps/' + stepId + '?type=delete', {}, 'POST', data);
}

// workflows
export function runStep(stepId, type, data) {
  return request('/api/workflow_steps/' + (stepId == null ? 0 : stepId) + '?type=' + type, {}, 'POST', data);
}

// workflows
export function getWorkflowSchedules(workflowId, runid, table_data) {
  return request('/api/workflow_schedules?workflow_id=' + workflowId + (runid == null ? '' : ('&runid=' + runid)) + '&' + querystring.stringify(table_data));
}

// workflows
export function getWorkflowSchedule(scheduleId) {
  return request('/api/workflow_schedules/' + scheduleId);
}

// workflows
export function createWorkflowSchedule(data) {
  return request('/api/workflow_schedules', {}, 'POST', data);
}

// workflows
export function saveWorkflowSchedule(data) {
  const id = data.id
  delete data.id
  return request('/api/workflow_schedules/' + id, {}, 'PUT', data);
}

// workflows
export function deleteWorkflowSchedule(scheduleId) {
  return request('/api/workflow_schedules/' + scheduleId, {}, 'DELETE', {});
}
