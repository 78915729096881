import React from 'react'
import i18next from 'i18next'

/*
  Used to display a field on a form.  Properties:
    label - The user facing name of the field
    validate - widget to validate field
    children - The input for the field (ex. <input/>
*/
const Field = ({label, validate, children, dialog, style = {}, alignLeft = false}) => {
  const translatedLabel = typeof label === 'string' ? i18next.t(label) : label
  return (
    <div className="col-md-12 mt-25" style={style}>
      {dialog && (
        <div>
          <div className="col-md-12 text-left">
            {label && <label>{translatedLabel}{i18next.t('field.colon')}</label>}
            {children}
          </div>
        </div>
      )}
      {!dialog && (
        <div>
          {!alignLeft && <div className="col-md-2"></div>}
          <div className="col-md-7 text-left">
            {label && <label>{translatedLabel}{i18next.t('field.colon')}</label>}
            {children}
          </div>
          <div className="col-md-1">{validate}</div>
        </div>
      )}
    </div>
  )
}

export default Field