import { Component, createRef } from 'react'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import { getBiddingRuns, deleteBidRun } from '../../api/bidding'
import i18next from 'i18next'
import { convertName } from '../../utilities/formatting'
import DeleteButton from '../DeleteButton'
import FTNotice from '../FTNotice'

class BiddingRuns extends Component {
  tableRef = createRef()

  deleteBiddingRun(id) {
    deleteBidRun(id)
      .then(response => {
        if (!response.success) {
          FTNotice(response.error, 15000)
        } else {
          this.refreshRows()
        }
      })
  }

  refreshRows() {
    if (this.tableRef.current) {
      this.tableRef.current.refreshRows()
    }
  }
  
  getColumns() {
    const allColumns = this.props.filter == 'bidding_runs'
    const columns = [
      { id: 'bid_run',
        datatype: 'text',
        width: 400,
        cellRenderer: (value, row) => {
          return (
            <Link to={'/bidding/bidding_run?id=' + row.id}>
              {value}
            </Link>
          )
        }
      }, {
        id: 'engines',
        header: 'biddingRuns.engines',
        datatype: 'text',
        format: value => value == null ? null : JSON.parse(value).map(e => convertName(e)).join(', '),
        width: 120
      }
    ]
    if (allColumns) {
      columns.push(...[
        {
          id: 'bid_change',
          datatype: 'text',
          className: 'text-center',
          width: 110,
          cellRenderer: (value) => {return this.renderBidChange(value)},
        }, {
          id: 'clicks',
          datatype: 'numeric',
          width: 90
        }, {
          id: 'cost',
          datatype: 'numeric',
          width: 90
        }, {
          id: 'internal_revenue',
          datatype: 'numeric',
          width: 150,
        }, {
          id: 'status',
          datatype: 'text',
          width: 100,
        }
      ])
    }
    columns.push(...[
      {
        id: 'created_by',
        header: 'biddingRuns.createdBy',
        datatype: 'text',
        width: 160,
      }, {
        id: 'created_at',
        header: 'biddingRuns.createdAt',
        width: 160,
        datatype: 'date',
      }, {
        id: 'updated_by',
        header: 'biddingRuns.updatedBy',
        datatype: 'text',
        width: 160,
      }, {
        id: 'updated_at',
        header: 'biddingRuns.updatedAt',
        datatype: 'date',
        width: 160,
      }
    ])
    return columns
  }
  
  addDeleteColumn(columns) {
    columns.push({
      cellRenderer: (_, row) => row.status != 'published' && ( 
        <DeleteButton
          objectType=""
          objectName={row.bid_run}
          deleteObject={() => this.deleteBiddingRun(row.id)}/>
      ),
      width: 200,
    })
    return columns
  }

  renderBidChange(value) {
    if(value == 'BID UP') {
      value = <font color='green'>{i18next.t('bidding.up')}</font>
    } else if (value == 'BID DOWN') {
      value = <font color='red'>{i18next.t('bidding.down')}</font>
    } else {
      value = <>
        <font color='green'>{i18next.t('bidding.up')}</font>
        <font color='black'> / </font>
        <font color='red'>{i18next.t('bidding.down')}</font>
      </>
    }
    return (<b>{value}</b>)
  }

  render() {
    return (
      <div className='site-canvas'>
        <Link to={'/bidding/bidding_run?id=-1'} className="btn btn-box-tool text-green js_complete" style={{'marginLeft': '90%'}}>
          <i className="fa fa-plus"></i>
          <span>{i18next.t('bidding.addNewBiddingRun')}</span>
        </Link>
        <Table
          ref={this.tableRef}
          tableName='bidding'
          fetch={tableData => {return getBiddingRuns(this.props.internal_client_id, this.props.filter, tableData)}}
          columns={this.getColumns()}
          columnOverride={columns => this.addDeleteColumn(columns)}
          pageSize={50}
          rowHeight={40} />
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
  }
}

export default connect(mapStateToProps)(withRouter(BiddingRuns))
