import { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import { getClients } from "../../api/clients"

import './clients.css'

export default class Interface extends Component {
  getColumns() {
    return [{
      id: 'name',
      header: 'Client',
      datatype: 'text',
      cellRenderer: (value, row) => {return (<Link style={{display: 'block', textAlign: 'center'}} to={`/clients/${row.internal_client_id}`}>{value}</Link>)},
    },{
      id: 'internal_client_id',
      header: 'Internal Client ID',
      datatype: 'text',
    },{
      id: 'active',
      header: 'Active',
      cellRenderer: (value) => {return (<div className="text-center"> {value == 'true' ? <i className="fa fa-check" style={{textAlign:'center'}}/> : <i className="fa fa-times"/>}</div> )},
      datatype: 'option',
      options: ['Active', 'Inactive'],
    }]
  }

  render() {
    return (
      <div className="site-canvas">
        <div className='content'>
          <div className='box'>
            <div className='box-header'>
              <div className="add-new-button" >
                <Link to='/clients/new' className='btn btn-box-tool text-green' >
                  <i className='fa fa-plus' />
                  <span>Add New Client</span>
                </Link>
              </div>
            </div>
            <div className='box-body no-padding js_complete' style={{marginTop: '15px'}}> 
              <Table
                fetch={(tableData) => {return getClients(tableData)}}
                columns={this.getColumns()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
