import React from 'react'
import i18next from 'i18next'
import Section from '../../widgets/Section'
import TextField from '../../widgets/TextField'
import Stack from '../../widgets/Stack'

const BidInputs = (props) => {
  return (
    <Stack direction="row">
      <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" label={i18next.t('biddingRun.bidFloor')}
        disabled={props.status == 'published'}
        value={props.bid_floor}
        onChange={event => props.onUpdate({ bid_floor: event.target.value })}
        startAdornmentText="biddingRun.$"
      />
      <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" label={i18next.t('biddingRun.bidCeiling')}
        disabled={props.status == 'published'}
        value={props.bid_ceiling}
        onChange={event => props.onUpdate({ bid_ceiling: event.target.value })}
        startAdornmentText="biddingRun.$"
      />
    </Stack>
  )
}

const BidFloorCeiling = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.bidFloorCeilingDesc')}>
      {<BidInputs {...props} />}
    </Section>
  )
}

export default BidFloorCeiling