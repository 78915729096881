import { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import i18next from 'i18next'

Chart.register(ArcElement)

export default class SolidGauge extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  getBackgroundColor() {
    let colors = [
      "#cc0202",
      "#d71703",
      "#e73704",
      "#ff6e04",
      "#ff8f00",
      "#ffb800",
      "#ffc001",
      "#fff800",
      "#48a44b",
      "#03965d",
      "#03965d",
    ]
    let color = colors[Math.floor(this.props.value / 10)]
    return [color, "#eeeeee"]
  }

  updateChart() {
    this.setState({
      data: {
        datasets: [
          {
            data: [this.props.value, 100 - this.props.value],
            backgroundColor: this.getBackgroundColor(),
            hoverBackgroundColor: this.getBackgroundColor(),
          },
        ],
      },
      plugin: {
        id: "id",
        beforeDraw: function (chart) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx
          ctx.restore()
          var fontSize = (height / 114).toFixed(2)
          ctx.font = fontSize + "em sans-serif"
          ctx.textBaseline = "middle"

          var text = "75%",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2

          ctx.fillText(text, textX, textY)
          ctx.save()
        },
      },
    })
  }

  render() {
    let _this = this;
    let plugin = {
      id: "id",
      beforeDraw: function (chart) {
        const width = chart.width
        const height = chart.height
        const ctx = chart.ctx

        ctx.restore()
        const fontSize = (height / 114).toFixed(2)
        ctx.font = fontSize + "em sans-serif"
        ctx.textBaseline = "middle"

        const value = _this.props.value
        const text = value == null ? i18next.t('guage.unavailable') : (value + '%')
        const textX = Math.round((width - ctx.measureText(text).width) / 2)
        const textY = height / 2

        ctx.fillText(text, textX, textY)
        ctx.save()
      },
    }

    if (this.state.data != null) {
      return (
        <div style={{ width: "300px", height: "200" }}>
          <h3 style={{width: '100%', textAlign: 'center'}}>{i18next.t(this.props.title)}</h3>
          <Doughnut
            data={this.state.data}
            options={{
              responsive: true,
              legend: {
                display: false,
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (_) {
                      const value = _this.props.value
                      return value == null ? i18next.t('guage.unavailable') : value + "%"
                    },
                  },
                },
              },
            }}
            plugins={[plugin]}
          />
        </div>
      )
    } else {
      return null
    }
  }
}
