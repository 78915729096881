import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { getAllDashboards } from '../../api/dashboards'
import { getAllSql } from '../../api/dashboards'
import i18next from 'i18next'
import Button from '../widgets/Button'

class SQL extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total_pages: 1,
      open_dialog: false,
      id_sql_map: null,
    }
  }

  componentDidMount() {
    getAllSql(false, this.props.internal_client_id, null).then(response => {
      let map = {}
      response.results.forEach(res => {
        map[res.id] = res.name
      })
      this.setState({id_sql_map: map})
    })
  }

  getColumns() {
    return [ {
      id: 'client_name',
      datatype: 'text',
      width: 150,
    }, {
      id: 'location',
      header: 'dashboards.location',
      datatype: 'option',
      options: [i18next.t('dashboards.dashboards'), i18next.t('dashboards.budget')],
      width: 100,
    }, {
      id: 'title',
      header: 'dashboards.dashboard',
      datatype: 'text',
      cellRenderer: (value, row) => (<Link to={`/dashboard_admin/dashboard/${row.id}`} className="text-center" style={{width: '100%', display: 'inline-block'}}>{value}</Link>),
      width: 300,
    }, {
      id: 'description',
      datatype: 'text',
      width: 350,
    }, {
      id: 'sql',
      header: 'dashboards.sql',
      cellRenderer: (_, row) => (this.renderSql(row)),
      width: 400,
    }, {
      id: 'copy',
      header: 'dashboards.copy',
      cellRenderer: (_, row) => (
        <div className='text-center'>
          <Link to={'/dashboard_admin/dashboard/new?copy=' + row.id}>
            <Button
              buttonText={'dashboardSql.copy'}
              size='small'
            >
            </Button>
          </Link>
        </div>
      ),
      width:90,
    }]
  }

  showAllSql(cards) {
    this.setState({open_dialog: true, cards: cards})
  }

  renderSql(row) {
    let count = 0
    return (
      <div className='text-center'>
        {this.getSqlIds(row.cards).map((id, i) => {
          if (count < 3) {
            count++
            return (
               <div key={i}>
                <Link to={'/dashboard_admin/sql/'+id}>
                  {this.state.id_sql_map[id]}
                </Link>
                <br/>
              </div>
            )
          } else if (count == 3){
            count++
            return (
              <Button
                key={i}  
                buttonText={'...'}
                onClick={() => this.showAllSql(row.cards)} 
                style={{paddingTop: 0, lineHeight: 0}}
                variant='text'  
              />
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }
  
  getSqlIds(cards) {
    const ids = []
    if (cards) {
      JSON.parse(cards).forEach(card => {
        if (card.sql_id != '' && !ids.includes(card.sql_id)) {
          ids.push(card.sql_id)
        }
      })
    }
    return ids
  }

  renderDashboardsDialog() {
    return(
      <Dialog open={this.state.open_dialog}
        onClose={() => this.setState({open_dialog: false, cards: null})} 
        maxWidth='md' >
        <DialogTitle><b>{i18next.t('dashboards.allSql')}</b></DialogTitle>
        <DialogContent dividers>
          {this.getSqlIds(this.state.cards).map((id, i) => (
            <div key={i}>
              <Link to={'/dashboard_admin/sql/'+id}>
                {this.state.id_sql_map[id]}
              </Link>
              <br/>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <div style={{paddingLeft: 30, paddingRight: 30}}>
        <div className="box-header"style={{height: 35}}>
          <div className="add-new-button js_complete">
            <Link to={'/dashboard_admin/dashboard/new'} className="btn btn-box-tool text-green">
            <i className="fa fa-plus" style={{color:'#7B1FA2', paddingLeft:30}}></i>
              <Button
                buttonText={'dashboards.addDashboard'}
                size='small'
                variant='text'
                style={{paddingUp: 30}}
              />
            </Link>
          </div>
        </div>
        {this.state.id_sql_map && 
          <Table
            tableName='dashboards'
            fetch={(tableData) => {return getAllDashboards(true, [this.props.internal_client_id], null, tableData, true)}}
            columns={this.getColumns()}
            rowHeight={80} />
        }
        {this.renderDashboardsDialog()}
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(SQL)
