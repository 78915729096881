import { Component } from 'react'
import { connect } from 'react-redux'
import locale from '../../api/locale'
import AddEditAnnotations from '../charts/AddEditAnnotation'
import LineChart from '../charts/LineChart'
import LineChartAi from '../ai/LineChartAi'
import { dataFormatter } from '../../utilities/formatting'
import i18next from 'i18next'

export const DAILY = 0
export const WEEKLY = 1
export const MONTHLY = 2

const ads = 'ads'

class ComparisonChart extends Component {
  /**
   * Compare metrics.
   *
   * Properties:
   *   type = ads (default), metrics or analytics
   *   dates1 - date range
   *   summary1 - summary data for date range
   *   dataset1 - detailed (daily) data for date range
   *   metric1 - the metric to show in the first selector
   *   metric2 - the metric to show in the second selector
   *   dates2 - (optional) second date range (if comparing)
   *   summary2 - (optional) summary data for second date range (if comparing)
   *   dataset2 - (optional) detailed (daily)) data for second date range (if comparing)
   *   granularity - (optional) granularity (DAILY, WEEKLY or MONTHLY; defaults to DAILY)
   *   annotations - (optional) annotations to show on chart
   *   annotationType - (optional) type of annotation (ex., 'homepage')
   *   updateAnnotationsCallback - (optional) callback when annotations have changed
   **/
  static defaultProps = {
    type: ads,
    annotations: [],
    granularity: DAILY,
  }

  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
      dataset1: null,
      dataset2: null,
      x_axis_labels_1: null,
      x_axis_labels_2: null,
      annotations: null,
      showAnnotations: true,
    }
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  updateChart() {
    const newState = this.generateXAxisLabels()
    this.setLineChartData("dataset1", "metric1", newState, newState['x_axis_labels_1'])
    if (this.props.metric2 != null) {
      this.setLineChartData("dataset2", "metric2", newState, newState['x_axis_labels_2'])
    }
    this.setState(newState)
  }

  generateXAxisLabels() {
    const labels_1 = []
    const labels_2 = []

    let date = moment(this.props.dates1[0])
    let date2 = moment(this.props.dates2[0])
    let granularity = 'days'
    if (this.props.granularity == WEEKLY) {
      date = date.startOf('week')
      date2 = date2.startOf('week')
      granularity = 'weeks'
    } else if (this.props.granularity == MONTHLY) {
      date = date.startOf('month')
      date2 = date2.startOf('month')
      granularity = 'months'
    }
    let end = moment(this.props.dates1[1])
    while (!date.isAfter(end)) {
      labels_1.push(date.format('M/D/YY'))
      date = date.add(1, granularity)
    }

    end = moment(this.props.dates2[1])
    while (!date2.isAfter(end)) {
      labels_2.push(date2.format('M/D/YY'))
      date2 = date2.add(1, granularity)
    }

    return { x_axis_labels_1: labels_1, x_axis_labels_2: labels_2 }
  }

  setLineChartData(datasetName, metric, newState, labels) {
    const dataset = this.props[datasetName]
    const metricName = this.props[metric]
    const result = []
    if (dataset) {
      labels.forEach(label => {
        let value = dataset.find((d) => moment(d[0]).format('M/D/YY') == label)
        if (value != null) {
          const metricValue = value[1][metricName]
          result.push(metricValue?.toFixed(2))
        } else {
          result.push(null)
        }
      })
    }
    const metric_id = metric == 'metric2' ? this.props.metric2 : this.props.metric1
    const summary = metric == 'metric2' ? this.props.summary2 : this.props.summary1
    if (summary) {
      newState[datasetName] = result
      newState[metric + "_total"] = dataFormatter(metricName)(summary[metric_id])
    }
  }

  getDates() {
    const sameDates = this.props.dates1[0] == this.props.dates2[0] && this.props.dates1[1] == this.props.dates2[1]
    const dates1 = sameDates ? "" : " (" + this.formatDate(this.props.dates1[0]) + "-" + this.formatDate(this.props.dates1[1]) + ")"
    const dates2 = sameDates ? "" : " (" + this.formatDate(this.props.dates2[0]) + "-" + this.formatDate(this.props.dates2[1]) + ")"
    return [dates1, dates2]
  }

  formatDate(date) {
    return moment(date).format("M/D/YY")
  }
  
  localize(metric) {
    return this.props.type == ads ? locale.metrics[metric] : i18next.t(`metrics.${this.props.type}.${metric}`)
  }

  generateDatasetLabels() {
    const dates = this.getDates()
    const labels = [
      this.localize(this.props.metric1) +
      dates[0] +
      ": " +
      this.state["metric1_total"],
    ]
    if (this.props.metric2 != null) {
      labels.push(
        this.localize(this.props.metric2) +
        dates[1] +
        ": " +
        this.state["metric2_total"],
      )
    }

    const result = []
    labels.forEach((label) => {
      label = label
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
      result.push(label)
    })
    return result
  }

  render() {
    const datasets = [this.state.dataset1]
    if (this.props.metric2 != null) {
      datasets.push(this.state.dataset2)
    }
    return (
      <div style={{ minHeight: "400px" }}>
        <div className="box-header">
          <div className="add-new-button" style={{ marginTop: "10px" }}>
            {this.props.annotations.length > 0 && (
              <button
                className="btn btn-box-tool"
                onClick={() => this.setState({ showAnnotations: !this.state.showAnnotations })}
              >
                {i18next.t('comparisonChart.' + (this.state.showAnnotations ? 'hideAnnotations' : 'showAnnotations'))}
              </button>
            )}
            {this.props.annotationType && (
              <button
                className="btn btn-box-tool text-green"
                onClick={() => this.setState({ openDialog: true })}
              >
                <i className="fa fa-plus js_complete"></i>
                <span> {i18next.t('comparisonChart.chartAnnotations')}</span>
              </button>
            )}
          </div>
        </div>
        {this.state.x_axis_labels_1 != null &&
          this.state.dataset1 != null && (
            <div style={{ height: 370 }}>
              {this.props.useAiStyledChart ?
                <LineChartAi
                  aiMetricSelectChart={this.props.aiMetricSelectChart}
                  animation={false}
                  two_datasets_mode={true}
                  x_axis_labels_1={this.state.x_axis_labels_1}
                  x_axis_labels_2={this.state.x_axis_labels_2}
                  dataset_labels={this.generateDatasetLabels()}
                  datasets={datasets}
                  annotations={
                    this.state.showAnnotations ? (
                      this.props.annotations == null
                        ? this.state.annotations
                        : this.props.annotations
                    ) : null
                  }
                  height={60}
                  top_padding={50}
                /> : <LineChart
                  animation={false}
                  two_datasets_mode={true}
                  x_axis_labels_1={this.state.x_axis_labels_1}
                  x_axis_labels_2={this.state.x_axis_labels_2}
                  dataset_labels={this.generateDatasetLabels()}
                  datasets={[this.state.dataset1, this.state.dataset2]}
                  annotations={
                    this.state.showAnnotations ? (
                      this.props.annotations == null
                        ? this.state.annotations
                        : this.props.annotations
                    ) : null
                  }
                  height={60}
                  top_padding={50}
                />}
            </div>
          )}
        {this.props.annotationType && (
          <AddEditAnnotations
            open={this.state.openDialog}
            location={this.props.annotationType}
            location_id={this.props.internal_client_id}
            closeCallBack={() => this.setState({ openDialog: false })}
            updateCallBack={() => this.props.updateAnnotationsCallback && this.props.updateAnnotationsCallback()}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default connect(mapStateToProps)(ComparisonChart)
