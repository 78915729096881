import { Component } from 'react'
import { connect } from 'react-redux'
import { setToken } from '../redux/users/sagaActions'
import { getConfig } from '../api/config'
import { Routes, Route, matchPath } from 'react-router-dom'
import withRouter from '../wrappers/withRouter'
import Header from './Header'
import Notifications from './notifications'
import Login from './login/Interface'
import Config from './config/Interface'
import { ToastContainer } from "react-toastify"
import ResetPassword from './login/ResetPassword'
import getRoutes from '../routes/index'
import { hideWrench } from '../application'
import querystring from 'querystring'
import i18next from 'i18next'
import PromptDialog from './ai/PromptDialog'
import FabAI from './ai/FabAI'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: null,
      license: null,
      showConfig: null,
      showJira: null,
      promptOpen: false,
    }
    this.checkSessionCookie()
    this.fetchConfig()
  }

  togglePrompt(open) {
    this.setState({ promptOpen: !open })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location != prevProps.location) {
      hideWrench()
      this.setPage()
    }
    let search = location.search
    if (search.startsWith('?')) {
      search = search.substring(1)
    }
    const query = querystring.parse(search)
    if (this.isLoggedIn(this.props) && !this.isLoggedIn(prevProps) && query.redirect) {
      location.href = decodeURI(query.redirect)
    } else if (this.props.user && !prevProps.user) {
      this.setInitialPage()
    }
  }

  isLoggedIn(props) {
    return props.authentication
  }

  setInitialPage() {
    if (this.isProduction()) {
      gtag('set', 'user_properties', this.getUserProps());
      this.setPage()
    }
  }

  isProduction() {
    return location.host == 'app.ftoptimize.com'
  }

  setPage() {
    if (this.isProduction()) {
      const path = this.props.location.pathname
      gtag('event', 'page_view', {
        page_path: this.convertUrl(path),
        page_title: 'AMI',
      });
    }
  }

  convertUrl(url) {
    if (url.startsWith('/performance/')) {
      return '/performance'
    } else if (url.startsWith('/dimensions/')) {
      return '/dimensions'
    } else if (url.startsWith('/comparison/')) {
      return '/comparison'
    } else if (url.startsWith('/ab_tests/ab_test/')) {
      const splits = url.split('/')
      if (splits.length >= 5) {
        return splits[0] + '/' + splits[1] + '/' + splits[2] + '/' + splits[3] + '/' + splits[4]
      }
    } else if (url.startsWith('/feeds/')) {
      const splits = url.split('/')
      if (splits.length >= 3 && (splits[2] == 'all' || splits[2] == 'history')) {
        return splits[0] + '/' + splits[1] + '/' + splits[2]
      }
    } else if (url.length > 50) {
      const splits = url.split('/')
      if (splits.length >= 1 && splits[1].length > 50) {
        return '/'
      }
    }
    return url
  }

  getUserProps() {
    const props = {}
    if (this.props.user) {
      props.user_id = this.props.user.id
      props.client = this.props.user.client.name
      props.role = this.props.user.role
    }
    return props
  }

  checkSessionCookie() {
    const allcookies = document.cookie
    const cookiearray = allcookies.split(';')
    for (let i = 0; i < cookiearray.length; i++) {
      const name = cookiearray[i].split('=')[0].trim()
      if (name == 'token') {
        const token = cookiearray[i].split('=')[1].trim()
        this.props.dispatch(setToken(token))
        return
      }
    }
  }

  fetchConfig() {
    getConfig('').then((json) => {
      const initial = json.initial
      delete json.initial
      const showConfig = json.show_config
      delete json.show_config
      const showJira = json.use_jira
      delete json.use_jira
      this.setState({ initial: initial, config: json, license: json.license, showConfig: showConfig, showJira: showJira })
    })
  }



  render() {
    const loggedIn = this.isLoggedIn(this.props)
    const height = window.innerHeight - 103
    const renderResetPasswordPage = !loggedIn && location.href.includes('reset_password')
    const routes = getRoutes(this.state.license, this.props.admin, this.props.timeTracking, this.state.showConfig, this.state.showJira, this.props.permissions)
    let menu = null
    let menuItem = null
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      if (matchPath(route.path, this.props.location.pathname)) {
        menu = route.menu
        menuItem = route.menuItem
        break
      }
    }
    if (loggedIn && routes.length == 0) {
      return null
    }
    return (
      <div>
        {loggedIn && (
          <div className="wrapper">
            {this.props.isAdmin &&
              <>
                <FabAI
                  open={this.state.promptOpen}
                  togglePrompt={() => this.togglePrompt(this.state.promptOpen)} />
                <PromptDialog
                  open={this.state.promptOpen}
                  togglePrompt={() => this.togglePrompt(this.state.promptOpen)}
                />
              </>}
            <Header license={this.state.license} showConfig={this.state.showConfig} showJira={this.state.showJira} menu={menu} menuItem={menuItem} aiAction={() => this.togglePrompt(this.state.promptOpen)} />
            <Notifications />
            <div className="content-wrapper">
              <div id="chart-hudd" className="site-wrapper-offcanvas" style={{ minHeight: `${height}px` }}>
                <Routes>
                  {routes.map((route, i) => {
                    return (<Route key={i} path={route.path} element={route.component} />)
                  })}
                </Routes>
              </div>
            </div>
            <footer className="main-footer">
              <div className="pull-right hidden-xs">
                {i18next.t('app.tagline')}
              </div>
              <strong><span>{i18next.t('app.copyright', { year: new Date().getFullYear() })}</span> <a href="https://www.ftoptimize.com" target="_blank">{i18next.t('app.company')}.</a></strong><span> {i18next.t('app.allRightsReserved')}</span>
            </footer>
          </div>
        )}
        {
          renderResetPasswordPage &&
          <ResetPassword />
        }
        {!loggedIn && !renderResetPasswordPage && this.state.initial == false && (
          <Login location={this.props.location} />
        )}
        {!loggedIn && this.state.initial == true && (
          <Config config={this.state.config} />
        )}
        <div id="ftNoticeContainer">
          <ToastContainer
            position="top-right"
            autoClose={55000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    authentication: state.users.token,
    admin: state.users.user ? state.users.user.role == 'Admin' : null,
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    timeTracking: state.users.user ? state.users.user.enable_time_tracking : null,
    user: state.users.user,
    permissions: state.users.user ? state.users.user.permissions : [],
  }
}

export default withRouter(connect(mapStateToProps)(App));
