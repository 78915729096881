export function updateState(attribute, value, _this) {
  /*
   * update state and tell parent a change had been made.
   */
  let newState = {};
  newState[attribute] = value;
  _this.setState(newState, () => {
    if (_this.props.isChanged !== undefined) {
      _this.props.isChanged(true);
    }
    if (_this.props.stateUpdateCallback !== undefined) {
      _this.props.stateUpdateCallback(_this.state);
    }
  });
}
