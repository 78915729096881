import {all} from 'redux-saga/effects'
import watchAlerts from './alerts/sagas'
import watchUser from './users/sagas'

export default function* rootSaga() {
  yield all([
    watchAlerts(),
    watchUser(),
  ])
}
