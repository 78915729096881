import { isValidElement } from 'react'
import { Dialog, DialogContent, Stack, Box, Typography } from '@mui/material'
import ButtonRow from './ButtonRow'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import i18next from 'i18next'

/*
Show a Dialog.

Properties:
  open - whether the dialog should be showing
  onClose (optional) - function to call on close
  icon - 'warning' or 'success' 
  title - Title (typically i18n)
  text - Text for body (typically i18n)
  buttons (optional) - Buttons for dialog
*/

const FTODialog = ({open, onClose, icon, title, text, buttons, children, fullWidth=false, width='sm'}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      width={width}
    >
      <Box padding={3} display="flex" flexDirection="row" justifyContent="flex-start">
        {icon && (
          <Box display="flex" marginRight={1}>
            {icon == 'success' && (
              <CheckCircleIcon color="success" />            
            )}
            {icon == 'warning' && (
              <WarningIcon color="warning" />
            )}
          </Box>
        )}
        <Box display="flex">
          <Typography variant="h5">
            {i18next.t(title)}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        <Stack spacing={2}>
          {text && (
            <Box display="flex" alignItems="center" margin="auto">
              <Typography variant="h6">
                {i18next.t(text)}
              </Typography>
            </Box>
          )}
          {children}
        </Stack>
      </DialogContent>
      {buttons && (
        <Box display="flex" justifyContent="center" padding={4}>
          <ButtonRow direction="row" spacing={3}>
            {(Array.isArray(buttons) ? buttons : [buttons]).map((child, i) => {
              // Add key so that the caller doesn't have to
              if (isValidElement(child)) {
                return React.cloneElement(child, { key: i })
              }
              return child
            })}
          </ButtonRow>
        </Box>
      )}
    </Dialog>
  )
}

export default FTODialog