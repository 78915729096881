import * as React from 'react'
import { Modal, Box, Typography, CircularProgress } from '@mui/material'
import i18next from 'i18next'

/*
Props:
  open - Whether the dialog should be shown
  text - text to show on the dialog (typically i18n)
*/

const FTOProgressDialog = ({ open, text }) => {
  return (
    <Modal
      open={open}
    >
      <Box display="flex" flexDirection="column" sx={{
        width: "30vw",
        height: "100vh",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        margin: "auto",
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#edf0f0",
          opacity: .75,
          borderRadius: 5,
          padding: 5,
          width: "100%"
        }}>
          <Box sx={{ margin: 3, display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
          <Box sx={{ margin: 3, display: "flex", justifyContent: "center" }}>
            <Typography color="primary" variant="h4">
              {i18next.t(text)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default FTOProgressDialog