import React, { useEffect, useState } from 'react'
import ReactFlow from 'reactflow'
import styles from './FlowStyles'
import { connect } from 'react-redux'
import { getFeeds, getSteps } from '../../api/feeds'
import { getAlgos } from '../../api/bidding'
import BiddingStep from './BiddingStep'
import i18next from 'i18next'

import 'reactflow/dist/style.css'

const Flow = ({internal_client_id}) => {
  const nodeHeight = 60
  const nodeWidth = 100
  const nodeSpacing = 30
  const [config, setConfig] = useState({numSteps: 0, numAlgos: 0, nodes: [], edges: []})
  
  useEffect(() => {
    getFeeds(internal_client_id).then(feeds => {
      const feed = feeds?.results?.find(f => f.feed=='Bidding')
      if (feed != null) {
        const feedId = feed.feed_id
        getSteps(feedId, 'build').then(steps => {
          if (steps?.feed_steps != null) {
            getAlgos(internal_client_id).then(algos => {
              if (algos?.results != null) {
                const newNumAlgos = algos.results.length
                const middle = newNumAlgos == 0 ? 0 : (newNumAlgos * nodeHeight + (newNumAlgos - 1) * nodeSpacing) / 2 - nodeHeight / 2
                const newNumSteps = steps.feed_steps.length
                const newNodes = []
                const newEdges = []
                let prevAlgos = []
                steps.feed_steps.forEach((step, i) => {
                  const stepName = step.step_name
                  if (stepName == 'Run Algorithm') {
                    prevAlgos = []
                    algos.results.forEach((algo, j) => {
                      const node = {
                        id: `n${i}-${j}`,
                        type: 'custom',
                        data: { label: algo.name, link: `/bidding/algo/${algo.id}?referer=/bidding/flow`},
                        position: { x: i * (nodeWidth + nodeSpacing), y: j * (nodeHeight + nodeSpacing) }
                      }
                      if (i == 0) {
                        node.data['hideTarget'] = true
                      }
                      if (i == newNumSteps - 1) {
                        node.data['hideSource'] = true
                      }
                      newEdges.push({ id: `e${i}-${j}`, source: `n${i - 1}`, target: node.id, markerEnd: {type: 'arrowclosed', color: 'black'} })
                      newNodes.push(node)
                      prevAlgos.push(node)
                    })
                  } else {
                    const node = {
                      id: `n${i}`,
                      type: 'custom',
                      data: { label: stepName, link: `/feeds/${feedId}/build/${step.id}?referer=/bidding/flow`},
                      position: { x: i * (nodeWidth + nodeSpacing), y: middle }
                    }
                    if (i == 0) {
                      node.data['hideTarget'] = true
                    } else if (prevAlgos.length > 0) {
                      prevAlgos.forEach((algo, j) => {
                        newEdges.push({ id: `e${i}-${j}`, source: algo.id, target: node.id, markerEnd: {type: 'arrowclosed', color: 'black'} })
                      })
                      prevAlgos = []
                    } else {
                      newEdges.push({ id: `e${i}`, source: `n${i - 1}`, target: node.id, markerEnd: {type: 'arrowclosed', color: 'black'} })
                    }
                    if (i == newNumSteps - 1) {
                      node.data['hideSource'] = true
                    }
                    newNodes.push(node)
                  }
                })
                setConfig({numSteps: newNumSteps, numAlgos: newNumAlgos, nodes: newNodes, edges: newEdges})
              }
            })
          }
        })
      }
    })
  }, [])
  
  if (config.numSteps == 0) {
    return (
      <div style={styles.loading}>{i18next.t('biddingFlow.loading')}</div>
    )
  } else {
    const nodeTypes = { custom: BiddingStep }
    const height = config.numAlgos == 0 ? nodeHeight + nodeSpacing : config.numAlgos * (nodeHeight + nodeSpacing) // extra nodeSpacing for watermark
    const width = config.numSteps * nodeWidth + (config.numSteps - 1) * nodeSpacing  
    return (
      <div style={styles.container}>
        <div style={{height: height, width: width, margin: 'auto'}}>
          <ReactFlow
            style={styles.diagram}
            nodeTypes={nodeTypes}
            nodes={config.nodes}
            edges={config.edges}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(Flow)
