import React from 'react'
import i18next from 'i18next'
import Section from '../../widgets/Section'
import TextField from '../../widgets/TextField'

const RemoveBidChanges = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.removeBidChangesDesc')}>
      {<TextField
        disabled={props.status == 'published'}
        helperText={i18next.t('biddingRun.enterAWholeNumber')}
        label={i18next.t('biddingRun.removeRecentBids')}
        variant="outlined"
        value={props.min_days_since_bidding}
        onChange={event => props.onUpdate({ min_days_since_bidding: event.target.value })}
      />}
    </Section>
  )
}

export default RemoveBidChanges