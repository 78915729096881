import request from "./request";

// bidding
export function getCampaigns(internal_client_id) {
  return request('/api/campaigns?internal_client_id=' + internal_client_id);
}

// bidding
export function getAdGroups(internal_client_id) {
  return request('/api/ad_groups?internal_client_id=' + internal_client_id);
}

// services, workflows
export function getGoogleCampaigns(internal_client_id) {
  return request('/api/campaigns/google?internal_client_id=' + internal_client_id);
}

// services, workflows
export function getGoogleCampaign(internal_client_id, id) {
  return request('/api/campaigns/google/' + id + '?internal_client_id=' + internal_client_id);
}
