import React, { useMemo, } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import ReactFlow from 'reactflow'
import Node from './Node'
import styles from './InterfaceStyles'
import i18next from 'i18next'

import 'reactflow/dist/style.css'

const AiInfrastructure = () => {
  const nodeTypes = useMemo(() => ({ custom: Node }), [])
  const nodes = [{
    id: '1a',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.prompt'), image: 'Amazon-Comprehend.png', link: `/#/ai_infrastructure/Prompt?hideEngineering=true`},
    position: { x: 0, y: 150},
  }, {
    id: '1b',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.conversation'), image: 'Amazon-Lex.png', link: `/#/ai_infrastructure/Conversation?hideEngineering=true`},
    position: { x: 0, y: 280},
  }, {
    id: '1c',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.classifyPrompt'), image: 'Amazon-Elastic-Inference_light-bg.png', link: `/#/ai_infrastructure/Classify Prompt`},
    position: { x: 125, y: 200 },
  }, {
    id: '1d',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.databaseChain'), image: 'AWS-DeepLens.png', link: `/#/ai_infrastructure/Database Chain`, showBottom: true},
    position: { x: 250, y: 200 },
  }, {
    id: '1e',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.analyzeErrors'), image: 'Amazon-Elastic-Inference_light-bg.png', link: `/#/ai_infrastructure/Analyze Errors`, showTop: true, showLeftSource: true},
    position: { x: 250, y: 350 },
  }, {
    id: '1f',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.addGranularity'), image: 'Amazon-Rekognition.png', link: `/#/ai_infrastructure/Add Granularity`, showRightTarget: true, showTopSource: true},
    position: { x: 125, y: 350 },
  }, {
    id: '2a',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.metrics'), image: 'Amazon-Forecast.png', link: `/#/ai_infrastructure/Metrics?sampleData=true`},
    position: { x: 475, y: 0 },
  }, {
    id: '2b',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.analytics'), image: 'Amazon-Elastic-Inference_light-bg.png', link: `/#/ai_infrastructure/Analytics?sampleData=true`},
    position: { x: 475, y: 80 },
  }, {
    id: '2c',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.campaignCauses'), image: 'Amazon-Personalize.png', link: `/#/ai_infrastructure/Campaign Causes?sampleData=true`},
    position: { x: 475, y: 160 },
  }, {
    id: '2d',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.structure'), image: 'Amazon-Textract_light-bg.png', link: `/#/ai_infrastructure/Structure?sampleData=true`},
    position: { x: 475, y: 260 },
  }, {
    id: '2e',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.keywordStructure'), image: 'Amazon-Textract_light-bg.png', link: `/#/ai_infrastructure/Keyword Structure?sampleData=true`},
    position: { x: 475, y: 340 },
  }, {
    id: '2f',
    type: 'custom',
    data: { label: i18next.t('aiInfrastructure.abTest'), image: 'Amazon-Textract_light-bg.png', link: `/#/ai_infrastructure/AB Test?sampleData=true`},
    position: { x: 475, y: 440 },
  }]
  const edges = [
    { id: 'e1', source: '1a', target: '1c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e2', source: '1b', target: '1c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e3', source: '1c', target: '1d', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e4', source: '1d', sourceHandle: '1dright', target: '2a', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e5', source: '1d', sourceHandle: '1dright', target: '2b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e6', source: '1d', sourceHandle: '1dright', target: '2c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e7', source: '1d', sourceHandle: '1dright', target: '2d', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e8', source: '1d', sourceHandle: '1dright', target: '2e', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e9', source: '1d', sourceHandle: '1dright', target: '2f', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e10', source: '1d', sourceHandle: '1dbottom', target: '1e', targetHandle: '1etop', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e11', source: '1e', sourceHandle: '1eleftsource', target: '1f', targetHandle: '1frighttarget', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e12', source: '1f', sourceHandle: '1ftopsource', target: '1d', markerEnd: {type: 'arrowclosed', color: 'black'} },
  ] 

  return (
    <div style={styles.container}>
      <div style={{width: 'fit-content'}}>
        <div className="js_complete">
        </div>
      </div>
      <div style={Object.assign({}, styles.immediateContainer, {height: 550, width: 575, marginLeft: 20})}>
        <ReactFlow
          style={styles.diagram}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
        />
      </div>
    </div>
  )
}


const mapStateToProps = function(state) {
  return {
    permissions: state.users.user ? state.users.user.permissions : [],
    internal_client_id: state.users.user.client.internal_client_id
  }
}

export default withRouter(connect(mapStateToProps)(AiInfrastructure))
