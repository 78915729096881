import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getPacingInfo, getEntityCostPct } from '../../api/budget'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Section from '../widgets/Section'
import Stack from '../widgets/Stack'
import Metric from '../widgets/Metric'
import LineChart from '../charts/LineChart'
import i18next from 'i18next'
import moment from 'moment'

import './pacing.scss'

const Pacing = ({admin, default_client_id, internal_client_id, channels = ['sem', 'display', 'social'], showTotals = false, subbudgets = ['Overall'], month = moment().format('YYYY-MM'), hideSection = false}) => {
  internal_client_id = internal_client_id == null ? default_client_id : internal_client_id
  const [pacingData, setPacingData] = useState()
  const [subbudgetValues, setSubbudgetValues] = useState()
  const [entityCostPercentData, setEntityCostPercentData] = useState()
  const [lineChartData, setLineChartData] = useState({})
  const [annotations, setAnnotations] = useState({})
  const [showAnnotations, setShowAnnotations] = useState({})
    
  useEffect(() => {
    loadPacingInfo()
  }, [internal_client_id, JSON.stringify(channels), JSON.stringify(subbudgets), month])
  
  useEffect(() => {
    if (subbudgetValues != null) {
      renderLines(pacingData)
  
      // Get Annotations
      const startDate = moment(month + '-01', 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
      const endDate = moment(month + '-01', 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
      getEntityCostPct(
        startDate,
        endDate,
        internal_client_id
      ).then(response => {
        if (response != null && response.result.length) {
          setEntityCostPercentData(response.result)
        }
      })
    }
  }, [subbudgetValues])
  
  useEffect(() => {
    if (entityCostPercentData) {
      renderLines(pacingData)
    }
  }, [entityCostPercentData])

  const loadPacingInfo = () => {
    getPacingInfo(
      internal_client_id,
      channels,
      month,
      showTotals ? null : subbudgets,
    ).then(results => {
      setPacingData(results)
      if (results && Array.isArray(results)) {
        const newSubbudgetValues = []
        results.forEach(result => {
          result.id = "subbudget_" + result.subbudget.replace(" ", "_")
          let values = {
            id: result.id,
            noData: result.pacing_data == null || result.pacing_data.length == 0,
            subbudget: result.subbudget,
          }
          if (!values.noData) {
            values = Object.assign(values, {
              budget: Number(result.pacing_cost["budget"]).toLocaleString(
                "en-US",
                { style: "currency", currency: "USD" }
              ),
              spend: Number(result.pacing_cost["spend"]).toLocaleString(
                "en-US",
                { style: "currency", currency: "USD" }
              ),
              internal_roas:
                result.pacing_cost["internal_roas"] == null
                  ? null
                  : Number(result.pacing_cost["internal_roas"]).toFixed(2),
              internal_cpa:
                result.pacing_cost["internal_cpa"] == null
                  ? null
                  : Number(
                      result.pacing_cost["internal_cpa"]
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }),
              percent_of_budget:
                Number(result.pacing_cost["percent_of_budget"]).toFixed(2) +
                "%",
              forecast: Number(
                result.pacing_total_spend_forecast
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
              forecast_sou: Number(
                result.pacing_spend_over_under
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
              forecast_pou:
                Number(result.pacing_percent_over_under).toFixed(2) + "%",
            })
          }
          newSubbudgetValues.push(values)
        })
        setSubbudgetValues(newSubbudgetValues)
      } else {
        setSubbudgetValues([])
      }
    })
  }

  const renderLines = results => {
    const newLineChartData = lineChartData != null ? { ...lineChartData } : {}
    results.forEach(result => {
      if (result.pacing_data != null && result.pacing_data.length) {
        renderLine(result, newLineChartData, annotations)
      }
    })
    setLineChartData(newLineChartData)
  }

  const renderLine = (result, newLineChartData, annotations) => {
    const current = month == moment().format('YYYY-MM')
    const subbudget = result.id
    let last_real_spend_date = null
    let data_t1 = $.grep(result.pacing_data, function (row, i) {
      if (row["source"] == "real") last_real_spend_date = i
      return row["source"] == "real"
    })
    let data_t2 = $.grep(result.pacing_data, function (row) {
      return row["source"] == "budget"
    })

    // Recommended is null up until the last real point; then it uses that and transitions to recommended
    let data_t3 = []
    result.pacing_data.forEach((row, i) => {
      if(current && row.source == 'real' && i <= last_real_spend_date) {
        const copy = Object.assign({}, row)
        if(i < last_real_spend_date) {
          copy.daily_spend = null
        }
        data_t3.push(copy)
      }
    })
    data_t3.push(...$.grep(result.pacing_data, function (row) {
      return row["source"] == "recommended"
    }))

    let data_t4 = $.grep(result.pacing_roas, function (row) {
      return row["source"] == "real"
    })
    let data_t5 = $.grep(result.pacing_cpa, function (row) {
      return row["source"] == "real"
    })

    data_t1 = $.map(data_t1, function (row) {
      return [
        [
          Math.floor(new Date(row["date"]).getTime()),
          parseFloat(row["daily_spend"]),
        ],
      ]
    })
    data_t2 = $.map(data_t2, function (row) {
      return [
        [
          Math.floor(new Date(row["date"]).getTime()),
          parseFloat(row["daily_budget"]),
        ],
      ]
    })
    data_t3 = $.map(data_t3, function (row) {
      return [
        [
          Math.floor(new Date(row["date"]).getTime()),
          parseFloat(row["daily_spend"]),
        ],
      ]
    })
    data_t4 = $.map(data_t4, function (row) {
      return [
        [
          Math.floor(new Date(row["date"]).getTime()),
          parseFloat(row["internal_roas"]),
        ],
      ]
    })
    data_t5 = $.map(data_t5, function (row) {
      return [
        [
          Math.floor(new Date(row["date"]).getTime()),
          parseFloat(row["internal_cpa"]),
        ],
      ]
    })

    generateLineChartData(
      data_t1,
      data_t2,
      data_t3,
      data_t4,
      data_t5,
      subbudget,
      newLineChartData
    )
    addAnnotations(subbudget, annotations)
  }

  const addAnnotations = (subbudget, annotationDict, show) => {
    if (show == null) {
      show = getShowAnnotations(subbudget)
    }
    if (admin && show) {
      const annotations = []
      if (entityCostPercentData != null) {
        entityCostPercentData.forEach((row) => {
          const annotation = {
            x_value: row.date,
            text: row.bid_change + ", " + row.entity_cost_pct + "%",
          }
          annotations.push(annotation)
        })
      }
      annotationDict[subbudget] = annotations
    } else if(subbudget in annotationDict) {
      delete annotationDict[subbudget]
    }
  }

  const getShowAnnotations = subbudget => {
    return showAnnotations[subbudget]
  }

  const toggleShowAnnotations = (subbudget) => {
    const showSubbudget = {}
    const newValue = !getShowAnnotations(subbudget)
    showSubbudget[subbudget] = newValue
    setShowAnnotations(Object.assign({}, showAnnotations, showSubbudget))

    const newAnnotations = Object.assign({}, annotations)
    addAnnotations(subbudget.id, newAnnotations, newValue)
    setAnnotations(newAnnotations)
  }

  const generateLineChartDataset = (data_t, x_axis_labels) => {
    const mapping = {}
    data_t.forEach((d) => {
      let number = d[1]
      if (number < 0) number = 0
      const date = getDate(d)
      mapping[date] = number
    })
    return x_axis_labels.map(x_value => [x_value, mapping[x_value]])
  }
  
  const getDate = (d) => {
    return new Date(d[0]).toISOString().slice(0, 10)
  }

  const generateLineChartData = (
    data_t1,
    data_t2,
    data_t3,
    data_t4,
    data_t5,
    subbudget,
    newLineChartData
  ) => {
    let x_axis_labels = new Set()
    const data_t = data_t1.concat(data_t2, data_t3, data_t4, data_t5)
    data_t.forEach((d) => {
      x_axis_labels.add(getDate(d))
    })
    x_axis_labels = Array.from(x_axis_labels).sort()

    const datasets = [
      generateLineChartDataset(data_t2, x_axis_labels),
      generateLineChartDataset(data_t1, x_axis_labels),
      generateLineChartDataset(data_t3, x_axis_labels),
      generateLineChartDataset(data_t4, x_axis_labels),
      generateLineChartDataset(data_t5, x_axis_labels),
    ]
    newLineChartData[subbudget] = {
      x_axis_labels: x_axis_labels,
      datasets: datasets,
    }
  }
  
  const getPacingContent = (data, subbudget) => {
    return (
      <Stack>
        <Box>
          <Metric
            className="col-md-3"
            name="pacing.budget"
            value={subbudget.budget}
          />
          <Metric
            className="col-md-3"
            name="pacing.spend"
            value={subbudget.spend}
          />
          <Metric
            className="col-md-3"
            name="pacing.percentOfBudget"
            value={subbudget.percent_of_budget}
          />
          <Metric
            className="col-md-3"
            name="pacing.spendForecast"
            value={subbudget.forecast}
          />
          <Metric
            className="col-md-3"
            name="pacing.spendForecastOverUnder"
            value={subbudget.forecast_sou}
          />
          <Metric
            className="col-md-3"
            name="pacing.spendForecastOverUnder"
            value={subbudget.forecast_pou}
          />
          <Metric
            className="col-md-3"
            name="pacing.roas"
            value={subbudget.internal_roas}
          />
          <Metric
            className="col-md-3"
            name="pacing.internalCPA"
            value={subbudget.internal_cpa}
          />
        </Box>
        {!subbudget.noData && (
          <>
            {data != undefined && (
              <Box>
                <Box className="box-header">
                  <Box className="add-new-button">
                    {admin && (
                      <button
                        className="btn btn-box-tool"
                        onClick={() => toggleShowAnnotations(subbudget.id)}
                      >
                        {i18next.t('budget.' + (getShowAnnotations(subbudget.id) ? 'hideAnnotations' : 'showAnnotations'))}
                      </button>
                    )}
                  </Box>
                </Box>
                <Box style={{height: 250, marginTop: 10}}>
                  <LineChart
                    x_axis_labels_1={data.x_axis_labels}
                    dataset_labels={[
                      i18next.t('pacing.budget'),
                      i18next.t('pacing.actualSpend'),
                      i18next.t('pacing.recommendedSpend'),
                      i18next.t('pacing.roas'),
                      i18next.t('pacing.internalCPA'),
                    ]}
                    datasets={data.datasets}
                    annotations={annotations[subbudget.id]}
                    animation={false}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
        {subbudget.noData && (
          <Box style={{ textAlign: "center" }}>
            <Typography variant="h4">
              {i18next.t('pacing.noBudgetInformation')}
            </Typography>
          </Box>
        )}
      </Stack>
    )
  }

  return (
    <>
      {subbudgetValues == null && (
        <Typography variant="h4">{i18next.t('pacing.loading')}</Typography>
      )}
      {subbudgetValues != null && subbudgetValues.filter(s => !showTotals || s.subbudget == 'Overall').map((subbudget, i) => {
        const data = lineChartData[subbudget.id]
        const pacingContent = getPacingContent(data, subbudget)
        if (hideSection) {
          return (
            <div key={i}>
              {pacingContent}
            </div>
          )
        } else {
          return (
            <Section key={i} name={subbudget.subbudget}>
              {pacingContent}
            </Section>
          )
        }
      })}
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    admin: state.users.user ? state.users.user.role == "Admin" : null,
    default_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default connect(mapStateToProps)(Pacing)
