import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { getSQLTemplates } from  '../../api/sql_templates'
import { validateSQLBeforeSave } from '../../utilities/utils'
import { getColumns } from '../../api/database'
import { getDimensions, editDimension, previewDimensionSummary, previewDimensionDetails, exportDimensionDetails } from '../../api/dimensions'
import _ from "lodash"
import Form from '../Form'
import Field from '../Field'
import FTNotice from '../FTNotice'
import Table from '../widgets/Table'
import SQLEditor from '../SQLEditor'
import { formatInteger } from '../../utilities/formatting'
import i18next from 'i18next'

class Interface extends Component {
  summaryTableRef = createRef()
  detailsTableRef = createRef()
  
  constructor(props) {
    super(props)
    this.state = {
      desired_columns: ['account_id', 'ad_group_id', 'criteria_id', 'value'],
      index: parseInt(this.props.match.params.dimension),
      name: '',
      sql: '',
      sql_templates: [],
      showPreview: false,
    }
    this.getDimension()
    this.getTemplates()
  }

  getDimension() {
    getDimensions(this.props.internal_client_id).then((dimensions) => {
      dimensions.forEach((dimension) => {
        if (dimension.index == this.state.index) {
          if (dimension.name == null) {
            dimension.name = ''
          }
          if (dimension.sql == null) {
            dimension.sql = ''
          }
          this.setState(dimension)
        }
      })
    })
  }

  saveDimension() {
    const data = {
      internal_client_id: this.props.internal_client_id,
      index: this.state.index,
      name: this.state.name,
      sql: this.state.sql,
    }
    editDimension(data).then((dimension) => {
      if (dimension) {
        FTNotice('dimensions.edit.saved')
        location.href = '/#/manage_dimensions'
      } else {
        FTNotice('dimensions.edit.error', 15000)
      }
    })
  }

  getTemplates() {
    getSQLTemplates().then(response => {
      this.setState({sql_templates: response.results})
    })
  }

  handleSelectTemplate(template_id) {
    this.state.sql_templates.forEach(template => {
      if(template.id == template_id)
        this.setState({sql_template_id: template_id, sql: template.sql})
    })
  }

  getSQL() {
    return this.state.sql.replace(/\{internal_client_id\}/g, this.props.internal_client_id)
  }

  validate(sql) {
    return validateSQLBeforeSave(sql, 'redshift', null, true).then(error => {
      if (!error) {
        const desired_columns = ['account_id', 'ad_group_id', 'criteria_id']
        return getColumns({'sql': sql}).then(response => {
          if (!response.data || !_.isEqual(response.data, this.state.desired_columns)) {
            return i18next.t('offlineConversions.queryMustReturn', {columns: desired_columns.join(', ')})
          }
        })
      } else {
        return error
      }
    })
  }

  preview() {
    const summaryTable = this.summaryTableRef.current
    const detailsTable = this.detailsTableRef.current
    this.setState({showPreview: true}, () => {
      if (summaryTable) {
        summaryTable.refreshRows()
      }
      if (detailsTable) {
        detailsTable.refreshRows()
      }
    })
  }

  getSummaryColumns() {
    return [{
      id: 'value',
      header: 'dimensions.edit.value',
      datatype: 'text',
    }, {
      id: 'count',
      header: 'dimensions.edit.count',
      datatype: 'numeric',
      format: value => formatInteger(value),
      width: 120,
    },{
      id: 'clicks',
      header: 'dimensions.edit.clicks',
      datatype: 'numeric',
      width: 120,
    },{
      id: 'cost',
      datatype: 'numeric',
      width: 120,
    },{
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 150,
    }]
  }

  getDetailColumns() {
    return [{
      id: 'account_id',
      header: 'dimensions.edit.accountId',
      datatype: 'text',
      width: 120,
    },{
      id: 'account_descriptive_name',
      header: 'dimensions.edit.accountName',
      datatype: 'text',
    },{
      id: 'campaign_name',
      header: 'dimensions.edit.campaignName',
      datatype: 'text',
      width: 250,
    },{
      id: 'ad_group_id',
      header: 'dimensions.edit.adGroupId',
      datatype: 'text',
      width: 120,
    },{
      id: 'criteria_id',
      header: 'dimensions.edit.criteriaId',
      datatype: 'text',
      width: 120,
    },{
      id: 'criteria',
      header: 'dimensions.edit.criteria',
      datatype: 'text',
    },{
      id: 'clicks',
      header: 'dimensions.edit.clicks',
      datatype: 'numeric',
      width: 90
    },{
      id: 'cost',
      datatype: 'numeric',
      width: 90
    },{
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 100
    },{
      id: 'value',
      header: 'Value',
      datatype: 'text',
      width: 100,
    }]
  }

  render() { 
    return (
      <div className="site-canvas">
        <section className="content">
          <Form
            objectType="dimensions.edit.dimension"
            objectName={'Dimension ' + this.props.match.params.dimension}
            newObject={false}
            canDelete={false}
            updateObject={() => this.saveDimension()}
            parentLink={'/manage_dimensions'}
          >
            <Field label={i18next.t('dimensions.edit.dimension') + ' ' + this.props.match.params.dimension}>
            </Field>
            <Field label="dimensions.edit.name">
              <input type="text"
                value={this.state.name}
                onChange={event => this.setState({name: event.target.value})}
                className="form-control" />
            </Field>
            <Field label="dimensions.edit.sqlTemplate">
              <select className="form-control" value={this.state.sql_template_id} onChange={event => this.handleSelectTemplate(event.target.value)}>
                {this.state.sql_templates.map((template) => {
                  return (<option key={template.id} value={template.id} >{template.name}</option>)
                })}
                <option value={''}></option>
              </select>
            </Field>
            <Field
              label="dimensions.edit.sql"
              validate={
                <div className="js_complete">
                  <button className="form-control btn btn-success mt-25" onClick={() => this.preview()}>{i18next.t('dimensions.edit.preview')}</button>
                </div>
              }
            >
              <SQLEditor
                instructions={i18next.t('dimensions.edit.instructions')}
                code={this.state.sql}
                validate={(sql) => this.validate(sql)}
                onChange={(sql) => this.setState({sql: sql})}
              />
            </Field>
          </Form>
          {this.state.showPreview && (
            <div>
              <div style={{width: 720, margin: 'auto'}}>
                <Table
                  ref={this.summaryTableRef}
                  fetch={(tableData) => {return previewDimensionSummary(this.getSQL(), tableData)}}
                  columns={this.getSummaryColumns()}
                  autoHeight={true}
                  hidePagination={true} />
              </div>
              <div style={{margin: 'auto'}}>
                <Table
                  ref={this.detailsTableRef}
                  fetch={(tableData) => {return previewDimensionDetails(this.getSQL(), tableData)}}
                  export={(tableData) => {return exportDimensionDetails(this.getSQL(), tableData)}}
                  columns={this.getDetailColumns()} />
              </div>
            </div>
          )}
        </section>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
