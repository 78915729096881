import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import FTNotice from '../FTNotice'
import Form from '../Form'
import Field from '../Field'
import {
  getSqlById,
  addSql,
  editSql,
  removeSql,
  executeSql,
  exportSql,
} from '../../api/dashboards'
import { getSQLTemplates } from '../../api/sql_templates'
import querystring from 'querystring'
import Table from '../widgets/Table'
import SQLEditor, { convertVariables } from '../SQLEditor'
import PersistentDrawerLeft from '../Drawer'
import DataConfiguration from '../DataConfiguration'

class EditSql extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      description: "",
      sql: "",
      database: "postgres",
      internal_client_id: props.isAdmin ? "" : props.internal_client_id,
      sql_template_id: undefined,
      sql_templates: [],
      filtersToInclude: null,
      filters: {},
      default_variables: [],
    }
    this.drawer = React.createRef()
    this.available_filters = ["channels", "engines", "entity_types", "devices"]
  }

  componentDidMount() {
    const copy =
      this.props.location.search.length > 1
        ? querystring.parse(this.props.location.search.substring(1))["copy"]
        : null
    if (copy) {
      this.loadSql(copy, true)
    } else if (this.props.match.params.id != "new") {
      this.loadSql(this.props.match.params.id, false)
    }
    getSQLTemplates().then((response) => {
      this.setState({ sql_templates: response.results })
    })
  }

  loadSql(id, isCopy) {
    getSqlById(id).then((sql) => {
      this.setState({
        id: isCopy ? null : sql.id,
        name: sql.name + (isCopy ? " - Copy" : ""),
        description: sql.description,
        sql: sql.sql,
        database: sql.database,
        internal_client_id: sql.internal_client_id == null ? "" : sql.internal_client_id,
        filtersToInclude: this.determineFiltersToInclude(sql.sql),
        default_variables:
          sql.default_variables == null ? [] : convertVariables(sql.default_variables),
      })
    })
  }
  
  determineFiltersToInclude(sql) {
    const filtersToInclude = {}
    if (sql) {
      try {
        const sql_variables = sql
          .match(/{[^}]*}/g)
          .map((res) => res.replace(/{|}/g, ""))

        sql_variables.forEach(variable => {
          if (
            this.available_filters.includes(variable) ||
            variable.startsWith("dim")
          ) {
            if (variable.startsWith("dim")) {
              const number = variable.replace("dim", "").padStart(2, "0")
              variable = "dim_value_" + number
            }
            filtersToInclude[variable] = null
          }
        })
      } catch (err) {
        //pass
      }
    }
    return filtersToInclude
  }
  
  save() {
    const values = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      sql: this.state.sql,
      database: this.state.database,
      internal_client_id: this.state.internal_client_id,
      default_variables: JSON.stringify(this.state.default_variables),
    }
    values.internal_client_id =
      values.internal_client_id == "" ? null : parseInt(values.internal_client_id)
    values.user_id = this.props.current_user_id
    const validationError = this.validateSql()
    if (this.state.name.trim() == "") {
      FTNotice("Please enter a name", 15000)
    } else if (validationError != null) {
      FTNotice(validationError, 15000)
    } else {
      const id = values.id
      const api_call = id ? editSql(values) : addSql(values)
      const action = id ? "updated" : "added"
      api_call
        .then((result) => {
          if (result.success) {
            window.location.href = "/#/dashboard_admin/sql"
          } else {
            FTNotice("The SQL could not be " + action + " " + result.error)
          }
        })
        .catch((result) => {
          FTNotice("The SQL could not be " + action + " " + result.error)
        })
    }
  }

  validateSql() {
    let sql = this.state.sql.toLocaleLowerCase().trim()

    // Get rid of comments
    sql = sql.replace(/(\/\*[^*]*\*\/)/g, "")
    const splits = sql.split("\n")
    const parts = []
    splits.forEach((split) => {
      if (!split.trim().startsWith("--")) {
        parts.push(split)
      }
    })
    sql = parts.join("\n")

    if (sql == "") {
      return "Please enter SQL"
    } else if ((sql.match(/;/g) || []).length > 1) {
      return "SQL must be a single statement"
    } else if (
      sql.startsWith("insert") ||
      sql.startsWith("delete") ||
      sql.startsWith("update")
    ) {
      return "SQL must be a select statement"
    }
  }

  deleteSql() {
    removeSql(this.props.match.params.id)
      .then((result) => {
        if (result.success) {
          window.location.href = "/#/dashboard_admin/sql"
        } else {
          FTNotice(
            result.error ? (
              <div dangerouslySetInnerHTML={{ __html: result.error }} />
            ) : (
              "The SQL could not be deleted"
            ),
            15000
          )
        }
      })
      .catch(() => {
        FTNotice("The SQL could not be deleted")
      })
  }

  handleSelectTemplate(template_id) {
    this.state.sql_templates.forEach((template) => {
      if (template.id == template_id)
        this.setState({ sql_template_id: template_id, sql: temlate.sql })
    })
  }

  fetch(tableData = {}) {
    return this.executeSql(tableData).then((response) => {
      if (response.result != "success") {
        FTNotice(response.result, 3000)
      }
      return response
    })
  }

  exportSql(tableData = {}) {
    return exportSql(
      -1,
      {
        sql: this.getSql(),
        database: this.state.database,
        internal_client_id: this.props.internal_client_id,
      },
      tableData
    )
  }

  executeSql(tableData) {
    return executeSql(
      -1,
      {
        sql: this.getSql(),
        database: this.state.database,
        internal_client_id: this.props.internal_client_id,
        custom_variables: this.state.default_variables,
      },
      tableData
    )
  }

  preview() {
    this.executeSql({ page: 1, page_size: 1 }).then((response) => {
      const sqlResult = response.results
      if (response.result == "success") {
        let columns = []
        if (sqlResult.length > 0) {
          Object.keys(sqlResult[0]).forEach((key) => {
            columns.push({
              id: key,
              header: key.replace("_", " "),
              datatype: "other",
              cellRenderer: (value) => (
                <div dangerouslySetInnerHTML={{ __html: value }} />
              ),
            })
          })
        }
        this.setState({ columns: columns, showPreview: true })
      } else {
        FTNotice(response.result, 3000)
      }
    })
  }

  getFilterValues(filter_name) {
    if (this.state.filters[filter_name]) {
      return "('" + this.state.filters[filter_name].join("','") + "')"
    }
    return ""
  }

  getSql() {
    let sql = this.state.sql.trim()
    sql = sql.replace(/\{internal_client_id\}/g, this.props.internal_client_id)
    sql = sql.replace(
      /\{start_date\}/g,
      moment().subtract(28, "day").format("YYYY-MM-DD")
    )
    sql = sql.replace(
      /\{end_date\}/g,
      moment().subtract(1, "day").format("YYYY-MM-DD")
    )

    sql = sql.replace(/\{channels\}/g, this.getFilterValues("channels"))
    sql = sql.replace(/\{engines\}/g, this.getFilterValues("engines"))
    sql = sql.replace(
      /\{entity_types\}/g,
      this.getFilterValues("entity_types")
    )
    sql = sql.replace(/\{devices\}/g, this.getFilterValues("devices"))

    sql = sql.replace(/\{dim1\}/g, this.getFilterValues("dim_value_01"))
    sql = sql.replace(/\{dim2\}/g, this.getFilterValues("dim_value_02"))
    sql = sql.replace(/\{dim3\}/g, this.getFilterValues("dim_value_03"))
    sql = sql.replace(/\{dim4\}/g, this.getFilterValues("dim_value_04"))
    sql = sql.replace(/\{dim5\}/g, this.getFilterValues("dim_value_05"))
    sql = sql.replace(/\{dim6\}/g, this.getFilterValues("dim_value_06"))
    sql = sql.replace(/\{dim7\}/g, this.getFilterValues("dim_value_07"))
    sql = sql.replace(/\{dim8\}/g, this.getFilterValues("dim_value_08"))
    sql = sql.replace(/\{dim9\}/g, this.getFilterValues("dim_value_09"))
    sql = sql.replace(/\{dim10\}/g, this.getFilterValues("dim_value_10"))

    if (sql.length && sql[sql.length - 1] == ";") {
      sql = sql.substring(0, sql.length - 1)
    }
    return sql
  }

  renderForm() {
    return (
      <Form
        objectType="SQL"
        objectName={this.state.name}
        newObject={this.props.match.params.id == "new"}
        updateObject={() => {
          this.save()
        }}
        deleteObject={() => {
          this.deleteSql()
        }}
        parentLink={"/dashboard_admin/sql"}
      >
        <Field label="Name">
          <input
            type="text"
            value={this.state.name}
            onChange={() => {
              this.setState({ name: event.target.value })
            }}
            className="form-control"
          />
        </Field>
        {this.props.isAdmin && (
          <Field label="Client">
            <select
              className="form-control"
              value={this.state.internal_client_id}
              onChange={() => {
                this.setState({ internal_client_id: event.target.value })
              }}
            >
              {this.props.clients.map((client) => {
                return (
                  <option
                    key={client.internal_client_id}
                    value={client.internal_client_id}
                  >
                    {client.name}
                  </option>
                )
              })}
              <option value={""}>All</option>
            </select>
          </Field>
        )}
        <Field label="Description">
          <textarea
            value={this.state.description}
            onChange={() => {
              this.setState({ description: event.target.value })
            }}
            className="form-control"
            rows="5"
          />
        </Field>
        <Field label="SQL Template">
          <select
            className="form-control"
            value={this.state.sql_template_id}
            onChange={() => this.handleSelectTemplate(event.target.value)}
          >
            {this.state.sql_templates.map((template) => {
              return (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              )
            })}
            <option value={""}></option>
          </select>
        </Field>
        <Field
          label="SQL"
          validate={
            <div>
              <button
                className="form-control btn btn-success mt-25"
                onClick={() => {
                  this.preview()
                }}
              >
                Preview
              </button>
            </div>
          }
        >
          <SQLEditor
            code={this.state.sql}
            showVariables={true}
            default_variables={this.state.default_variables}
            database={this.state.database}
            onChange={(sql, default_variables) =>
              this.setState({ sql: sql, default_variables: default_variables, filtersToInclude: this.determineFiltersToInclude(sql) })
            }
            instructions={
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                The following are examples of subsitutions that can occur in
                your SQL:
                <ul>
                  <li>{"start_date = '{start_date}'"}</li>
                  <li>{"end_date = '{end_date}'"}</li>
                  <li>{"current_month = '{current_month}'"}</li>
                  <li>{"internal_client_id = {internal_client_id}"}</li>
                  <li>{"channel in {channels}"}</li>
                  <li>{"engine in {engines}"}</li>
                  <li>{"entity_type in {entity_types}"}</li>
                  <li>{"device in {devices}"}</li>
                  <li>{"dim_value_01 in {dim1} (can be dim1-dim10)"}</li>
                </ul>
              </div>
            }
          />
          <div className="col-md-12 mt-25">
            <div className="col-md-3"></div>
            <div className="col-md-4">
              <input
                type="radio"
                checked={this.state.database == "redshift"}
                onChange={() => {
                  this.setState({ database: "redshift" })
                }}
              />
              <label>Redshift</label>
            </div>
            <div className="col-md-4">
              <input
                type="radio"
                checked={this.state.database == "postgres"}
                onChange={() => {
                  this.setState({ database: "postgres" })
                }}
              />
              <label>PostgreSQL</label>
            </div>
          </div>
        </Field>
        {this.state.showPreview && (
          <div className="col-md-12 mt-25">
            <div style={{ width: "calc(100% - 20px)", margin: "auto" }}>
              <Table
                fetch={(tableData) => this.fetch(tableData)}
                export={(tableData) => this.exportSql(tableData)}
                columns={this.state.columns}
              />
            </div>
          </div>
        )}
      </Form>
    )
  }

  DataConfiguration() {
    if (this.state.filtersToInclude != null)
      return (
        <div>
          <h3>Filters</h3>
          <DataConfiguration
            filtersToInclude={this.state.filtersToInclude}
            filterValues={this.state.filters}
            changed={(f, d, filters) => this.setState({ filters: filters })}
            attachToSideNav={false}
          />
        </div>
      )
    else return null
  }

  render() {
    return (
      <PersistentDrawerLeft
        ref={this.drawer}
        data_configuration={() => this.DataConfiguration()}
      >
        <div className="page-nav" style={{ position: "relative" }}>
          <div className="btn-group" style={{ width: "100%" }}>
            <a
              className="btn btn-default"
              onClick={() => this.drawer.current.handleDrawerOpen()}
            >
              <i className="fa fa-wrench" />
            </a>
          </div>
          {this.renderForm()}
        </div>
      </PersistentDrawerLeft>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    clients: state.users.user ? state.users.user.clients : [],
    current_user_id: state.users.user ? state.users.user.id : null,
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(EditSql))
