import request from './request'

// integrations
export function getIntegrations(internal_client_id, integration) {
  return request('/api/integrations?internal_client_id='+internal_client_id+(integration ? ('&integration=' + integration) : ''))
}

// integrations
export function deleteCredential(id) {
  return request('/api/integrations/' + id, {}, 'DELETE')
}

// integrations
export function enableIntegration(internal_client_id, integration) {
  return request(`/api/integrations/${integration}/enable?internal_client_id=${internal_client_id}`, {}, 'POST')
}

// integrations
export function disableIntegration(internal_client_id, integration) {
  return request(`/api/integrations/${integration}/disable?internal_client_id=${internal_client_id}`, {}, 'POST')
}
