import { Component } from 'react';

// stylesheets
import './DropDownList.scss'

/*
  Displays a drop down list (see https://www.w3schools.com/howto/howto_js_filter_dropdown.asp):
  buttonLabel - Label for the button
  values - Values to show in the list (assumes an object with a name (ex., {id: 1, name: 'Fred'}))
  action - function to call when a value is selected (will be passed the object).
*/
export default class DropDownList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      values: props.values,
      filter: '',
    }
  }

  filterValues() {
    const filtered = [];
    this.props.values.forEach((value) => {
      if (value.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1) {
        filtered.push(value);
      }
    });
    this.setState({values: filtered});
  }

  render() {
    return(
      <div className="dropdown">
        <div className="form-control simulated-select" onClick={() => {this.setState({expanded: !this.state.expanded})}} style={{cursor: 'pointer'}}>
          {this.props.buttonLabel}
        </div>
        <div className={(this.state.expanded ? 'show' : '') + ' searchField dropdown-content'} style={{maxHeight: '25vw'}}>
          <input type="text" placeholder="Search..." value={this.state.filter} onChange={() => this.setState({filter: event.target.value}, () => {this.filterValues()})}/>
          {this.state.values.map((value, i) => {
            return (
              <a key={i} title={value.name} onClick={() => {this.setState({expanded: false}, () => {this.props.action(value)})}}>{value.name}</a>
            );
          })}
        </div>
      </div>
    )
  }
}
