import i18next from "i18next"
import Metric from "../../widgets/Metric"
import { Grid } from '@mui/material'

const Metrics = ({ settings, values }) => {
  const getIcon = (key) => {
    switch (key) {
      case 'cost':
      case 'aov':
      case 'engine_aov':
        return 'dollar'
      case 'clicks': return 'hand-pointer-o'
      case 'revenue':
      case 'engine_revenue':
      case 'roas':
      case 'engine_roas':
        return 'money'
      case 'cpc': return 'mouse-pointer'
      case 'rpc':
      case 'engine_rpc':
        return 'location-arrow'
      case 'conv_rate':
      case 'engine_cr':
        return 'filter'
      case 'clickthrough': return 'hourglass-half'
      case 'impressions': return 'eye'
      case 'conversions':
      case 'engine_conversions':
      case 'escore_cost':
      case 'escore_clicks':
        return 'check-square-o'
    }
  }

  const isCurrency = (key) => {
    switch (key) {
      case 'cost':
      case 'revenue':
      case 'engine_revenue':
      case 'cpc':
      case 'rpc':
      case 'engine_rpc':
      case 'aov':
      case 'engine_aov':
        return true
      default:
        return false
    }
  }

  const isPercentage = (key) => {
    switch (key) {
      case 'conv_rate':
      case 'engine_cr':
      case 'clickthrough':
        return true
      default:
        return false
    }
  }

  return (
    <Grid container spacing={5} width='100%' sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiGrid-item': {
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '10px'
      }
    }}>
      {settings.map((metric, i) => {
        const key = Object.keys(metric)[0]
        const show = metric[key]
        if (show) {
          return (
            <Grid item md={3} key={i}>
              <Metric
                id={key}
                showNoChange={true}
                name={i18next.t('abtest.metrics.' + key)}
                value={values[key]}
                compare_value={values[key + '_before']}
                isCurrency={isCurrency(key)}
                isPercentage={isPercentage(key)}
              />
            </Grid>
          )
        }
      })}
    </Grid>
  )
}

export default Metrics