import request from './request'

// data_science
export function getModels(internal_client_id) {
  return request('/api/data_science_models?internal_client_id=' + internal_client_id)
}

// data_science
export function getModel(id) {
  return request(`/api/data_science_models/${id}`)
}

// data_science
export function getModelFeatures(id) {
  return request(`/api/data_science_models/${id}/features`)
}

// data_science
export function getModelParameters(id) {
  return request(`/api/data_science_models/${id}/params`)
}

// data_science
export function getModelOptimalParameters(id) {
  return request(`/api/data_science_models/${id}/params/optimal`)
}

// data_science
export function saveModelParameters(id, parameters) {
  return request(`/api/data_science_models/${id}/params`, {}, 'POST', parameters)
}

// data_science
export function createModel(data) {
  return request('/api/data_science_models', {}, 'POST', data)
}

// data_science
export function updateModel(id, data) {
  return request(`/api/data_science_models/${id}`, {}, 'PATCH', data)
}

// data_science
export function deleteModel(id) {
  return request(`/api/data_science_models/${id}`, {}, 'DELETE')
}

// data_science
export function getPending(id) {
  return request(`/api/data_science_models/pending/${id}`)
}

//data_science
export function getTopFeatureValues(data) {
  return request(`/api/data_science_models/${data.id}/features?name=${data.name}`)
}
