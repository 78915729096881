import { FormControlLabel, Switch } from "@mui/material"
import i18next from "i18next"

// Props Summary
// label: provides a string label/name to the switch
// control: accepts the Switch component as a prop value
// checked: if true, the switch is checked
// color: default is primary as defined in theme.js
// disabled: if true, the switch is greyed out and cannot be clicked
// onChange: provide an event handler to fire after interaction


const FtoSwitch = (props) => {
  const defaultProps = {
    color: color,
    defaultChecked: undefined,
    disabled: false
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { color, label, labelColor, ...switchProps } = revisedProps
  return (
    <FormControlLabel
      label={typeof label === 'string' ? i18next.t(label) : label}
      sx={{
        color: labelColor,
      }}
      control={<Switch color={color}
        {...switchProps}
      />}
    />
  )
}

export default FtoSwitch