import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getModel, updateModel} from '../../api/data_science'
import FTNotice from '../FTNotice'
import Form from '../Form'
import Field from '../Field'
import i18next from 'i18next'

class ModelOutputTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.id,
      name: '',
      outputTable: '',
    }
  }

  componentDidMount() {
    getModel(this.state.id).then(model => {
      this.setState({
        name: model.name,
        outputTable: model.output_table || '',
      })
    })
  }
  
  save() {
    if (this.state.outputTable.trim() == '') {
      FTNotice('dataScience.enterOutputTable', 15000)
    } else {
      updateModel(this.state.id, {output_table: this.state.outputTable}).then(model => {
        if (model != null) {
          window.location.href = '/#/data_science'
        } else {
          FTNotice(i18next.t('dataScience.errorUpdating'))
        }
      })
    }
  }

  render() {
    return (
      <Form
        objectType="dataScience.model"
        updateObject={() => this.save()}
        canDelete={false}
        parentLink={`/data_science/${this.state.id}`}>
        <Field label="dataScience.name">
          <input type="text" value={this.state.name} disabled={true} className="form-control"/>
        </Field>
        <Field label="dataScience.outputTable">
          <input type="text" value={this.state.outputTable} onChange={event => this.setState({outputTable: event.target.value})} className="form-control"/>
        </Field>
      </Form>
    )
  }
}

export default withRouter(ModelOutputTable)
