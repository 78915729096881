import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import FTNotice from '../FTNotice'
import Form from '../Form'
import Field from '../Field'
import Variables from './workflow/Variables'
import { getStep, saveStep, createStep, deleteStep, getVariables, getTriggerableDags } from '../../api/workflows'
import { getServices } from '../../api/services'
import { guid } from '../../utilities/guids'
import { getAllDashboards } from '../../api/dashboards'
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'

class Step extends Component {
  constructor(props) {
    super(props)
    const values = queryString.parse(this.props.location.search)
    this.state = {
      name: '',
      description: '',
      workflow_id: values.workflow_id,
      run_id: guid(),
      internal_client_id: this.props.internal_client_id,
      sql: '',
      missing_services: [],
      type: null,
      types: null,
      dashboard_id: null,
      dag_id: null,
      sql_dashboards: null,
      triggerable_dags: null,
      variables: null,
      missingServices: null,
    }
    if (this.props.match.params.step_id != null) {
      this.loadStep()
    } else {
      this.loadClientData()
    }
  }

  loadClientData() {
    this.getVariables()
    this.getServices()
    this.getTriggerableDags()
    this.getDashboards()
  }

  getVariables() {
    getVariables(this.state.workflow_id, this.state.run_id, this.state.internal_client_id).then(response => {
      this.setState({ variables: response.data })
    })
  }

  getServices() {
    const types = [
      { id: 'sql', name: i18next.t('workflowStep.sql') },
      { id: 'sql_dashboard', name: i18next.t('workflowStep.sqlDashboard') },
      { id: 'trigger_dag', name: i18next.t('workflowStep.triggerDag') },
      { id: 'generate_metrics', name: i18next.t('workflowStep.generateBiddingMetrics') }
    ]
    getServices(this.state.internal_client_id).then(services => {
      const missingServices = []
      services.forEach(service => {
        if (service.id == -1) {
          missingServices.push(service)
        } else {
          types.push({ id: service.service_type + '|' + service.id, name: i18next.t('workflowStep.service', { service: service.name ? service.name : i18next.t('services.' + service.service_type + '.name') }) })
        }
      })
      let type = types[0].id
      types.forEach(candidate => {
        if (this.state.type == candidate.id) {
          type = this.state.type
        }
      })
      this.setState({ types: types, type: type, missingServices: missingServices })
    })
  }

  getTriggerableDags() {
    getTriggerableDags().then(response => {
      let dagId = response.result.length ? response.result[0].dag_id : ''
      response.result.forEach((dag) => {
        if (this.state.dag_id == dag.dag_id) {
          dagId = this.state.dag_id
        }
      })
      this.setState({ triggerable_dags: response.result, dag_id: dagId })
    })
  }

  getDashboards() {
    getAllDashboards(false, [this.state.internal_client_id]).then(response => {
      let sqlDashboardId = response.results.length ? response.results[0] : ''
      response.results.forEach((dashboard) => {
        if (this.state.dashboard_id == dashboard.id) {
          sqlDashboardId = this.state.dashboard_id
        }
      })
      this.setState({ sql_dashboards: response.results, dashboard_id: sqlDashboardId })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.step_id != prevProps.match.params.step_id) {
      this.loadStep()
    } else {
      if (this.state.internal_client_id != prevProps.internal_client_id) {
        this.loadClientData()
      }
    }
  }

  loadStep() {
    getStep(this.props.match.params.step_id).then((step) => {
      const state = { type: step.type, name: step.step_name, description: step.step_description, workflow_id: step.workflow_id, dashboard_id: null, dag_id: null, sql: null }
      switch (step.type) {
        case 'sql':
          state.sql = step.step_body
          break
        case 'sql_dashboard':
          state.dashboard_id = parseInt(step.step_body)
          break
        case 'trigger_dag':
          state.dag_id = step.step_body
          break
        case 'generate_metrics':
          break
        default:
          state.type = step.type + '|' + step.step_body
      }
      this.setState(state, () => this.loadClientData())
    })
  }

  saveStep() {
    if (this.state.name.trim() == '') {
      FTNotice('workflowStep.pleaseEnterName', 15000)
    } else {
      let type = this.state.type
      let step_body = null
      switch (this.state.type) {
        case 'sql':
          step_body = this.state.sql
          if (step_body.trim() == '') {
            return FTNotice('workflowStep.pleaseEnterSql', 15000)
          }
          break
        case 'sql_dashboard':
          step_body = this.state.dashboard_id + ''
          if (step_body.trim() == '') {
            return FTNotice('workflowStep.pleaseSelectDashboard', 15000)
          }
          break
        case 'trigger_dag':
          step_body = this.state.dag_id
          if (step_body.trim() == '') {
            return FTNotice('workflowStep.pleaseSelectDag', 15000)
          }
          break
        default:
          const splits = this.state.type.split('|')
          type = splits[0]
          step_body = splits[1]
      }
      const stepId = this.props.match.params.step_id
      const values = {
        step_name: this.state.name,
        type: type,
        step_description: this.state.description,
        step_body: step_body,
        workflow_id: this.state.workflow_id,
        user: this.props.current_user_id
      }

      const api_call = stepId ? saveStep(stepId, values) : createStep(values)
      const action = stepId ? i18next.t('workflowStep.updated') : i18next.t('workflowStep.added')
      api_call.then((result) => {
        if (result.success) {
          window.location.href = '/#/workflows/notebook/' + this.state.workflow_id + '/steps'
        } else {
          FTNotice(i18next.t('workflowStep.stepCouldNotBe', { action: action }), 15000)
        }
      }).catch(() => {
        FTNotice(i18next.t('workflowStep.stepCouldNotBe', { action: action }), 15000)
      })
    }
  }

  deleteStep() {
    deleteStep(this.props.match.params.step_id).then((result) => {
      if (result.success) {
        window.location.href = '/#/workflows/notebook/' + this.state.workflow_id + '/steps'
      } else {
        FTNotice('workflowStep.stepCouldNotBeDeleted')
      }
    }).catch(() => {
      FTNotice('workflowStep.stepCouldNotBeDeleted', 15000)
    })
  }

  getInstructions() {
    const variables = []
    this.state.variables.forEach((variable) => variables.push(variable.name))
    variables.sort()
    return i18next.t('workflowStep.variablesAreAvailable', { variables: variables.join(", ") })
  }

  substitute(sql) {
    this.state.variables.forEach((variable) => {
      sql = sql.replace(new RegExp('\{' + variable.name + '\}', 'g'), variable.value)
    })
    return sql
  }

  render() {
    if (this.state.types && this.state.sql_dashboards && this.state.triggerable_dags && this.state.variables) {
      return (
        <Form
          objectType="workflowStep.step"
          objectName={this.state.name}
          newObject={!this.props.match.params.step_id}
          updateObject={() => this.saveStep()}
          deleteObject={() => this.deleteStep()}
          parentLink={'/workflows/notebook/' + this.state.workflow_id + '/steps'}
          filters={
            <div>
              {this.state.workflow_id && (
                <Variables siteMenu={true} workflow_id={this.state.workflow_id} run_id={this.state.run_id}
                  internal_client_id={this.state.internal_client_id} passBack={(variables) => this.setState({ variables: variables })} />
              )}
              <div className="page-nav" style={{ position: 'relative', margin: 15 }}>
                <div className="btn-group" style={{ width: '100%' }}>
                  <a className="btn btn-default toggle-nav">
                    <i className="fa fa-wrench" />
                  </a>
                </div>
              </div>
            </div>
          }>
          <Field label="workflowStep.name">
            <input type="text" value={this.state.name} onChange={event => { this.setState({ name: event.target.value }) }} className="form-control" />
          </Field>
          <Field label="workflowStep.description">
            <textarea value={this.state.description} onChange={event => { this.setState({ description: event.target.value }) }} className="form-control" maxLength="4096" />
          </Field>
          <Field label="workflowStep.type">
            <select value={this.state.type} onChange={event => { this.setState({ type: event.target.value }) }} className="form-control">
              {this.state.types.map((type, i) => {
                return (
                  <option key={i} value={type.id}>{type.name}</option>
                )
              })}
            </select>
            {this.state.missingServices.length > 0 && (
              <div style={{ marginTop: 10 }}>{i18next.t('workflowStep.servicesNotConfigured')}
                <ul>
                  {this.state.missingServices.map((service, i) => (
                    <li key={i}><a href={"/#/services/new/" + service.service_type} target="_blank">{service.name ? service.name : i18next.t('services.' + service.service_type + '.name')}</a></li>
                  ))}
                </ul>
              </div>
            )}
          </Field>
          {this.state.type == 'sql' && (
            <div>
              <Field label="workflowStep.sql">
                <SQLEditor
                  code={this.state.sql}
                  instructions={this.getInstructions()}
                  substitute={(sql) => this.substitute(sql)}
                  onChange={(sql) => this.setState({ sql: sql })}
                  width="100%"
                />
              </Field>
            </div>
          )}
          {this.state.type == 'sql_dashboard' && (
            <div>
              <Field label="workflowStep.sqlDashboard">
                <select className="form-control" value={this.state.dashboard_id} onChange={event => this.setState({ dashboard_id: event.target.value })}>
                  {this.state.sql_dashboards.map((dashboard) => {
                    return (<option key={dashboard.id} value={dashboard.id}>{dashboard.title}</option>)
                  })}
                </select>
              </Field>
            </div>)}
          {this.state.type == 'trigger_dag' &&
            <Field label="workflowStep.dag">
              <select value={this.state.dag_id} onChange={event => { this.setState({ dag_id: event.target.value }) }} className="form-control">
                {this.state.triggerable_dags && this.state.triggerable_dags.map((dag) => {
                  return (
                    <option value={dag.dag_id} key={dag.dag_id}>{dag.dag_id.replaceAll('_', ' ')}</option>
                  )
                })}
              </select>
            </Field>
          }
        </Form>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    current_user_id: state.users.user ? state.users.user.id : null
  }
}

export default withRouter(connect(mapStateToProps)(Step))
