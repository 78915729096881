import { useState } from 'react'
import { Link } from 'react-router-dom'
import withRouter from '../../wrappers/withRouter'
import Table from '../widgets/Table'
import Page from '../widgets/Page'
import Section from '../widgets/Section'
import { Box, Grid, Divider, Typography } from '@mui/material'
import { getBiddingChange } from '../../api/bidding_changes'
import { formatInteger, formatCurrencyNoDecimal } from '../../utilities/formatting'
import i18next from 'i18next'

const Details = ({match}) => {
  const [summary, setSummary] = useState({})

  const getData = tableData => {
    return getBiddingChange(match.params.id, tableData).then(details => {
      setSummary({...details.summary})
      return details
    })
  }

  const colorize = () => {
    return {
      'positive': params => {return params.value > 0},
      'negative': params => {return params.value < 0},
    }
  }

  const getColumns = () => {
    return [{
      id: 'engine',
      datatype: 'option',
      options: ['Google', 'Bing']
    }, {
      id: 'bid_direction',
      header: 'Bid Direction',
      datatype: 'option',
      options: ['Down', 'Up']
    }, {
      id: 'entity_type',
      datatype: 'option',
      options: ['PLA', 'Keyword']
    }, {
      id: 'cost_impact',
      datatype: 'numeric',
      classRules: colorize(),
      format: value => formatCurrencyNoDecimal(value)
    }, {
      id: 'clicks_impact',
      datatype: 'numeric',
      format: value => formatInteger(value)
    }, {
      id: 'internal_roas',
      datatype: 'numeric',
      classRules: colorize(),
    }]
  }

  return (
    <Page style={{width: '100%'}}>
      <Link to="/bidding_changes" className="btn btn-box-tool" style={{textAlign: 'right'}}>
        <i className="fa fa-times"></i>
      </Link>
      {summary.name && (
        <>
          <Grid container sx={{marginBottom: '10px'}}>
            <Grid item md={12}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                overflowWrap: 'breakWord'
              }}>
                <Typography variant="h3">{i18next.t('biddingChanges.biddingRun') + ': ' + summary.name}</Typography>
                <Divider />
                <Typography><strong>{i18next.t('biddingChanges.biddingRunId')}</strong>: {summary.bidding_run_id}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Section padding={0}>
            <div style={{position: 'relative', top: 10, height: 70}}>
              <div className="olthead col-md-12" style={{textAlign: 'center'}}>
                <div className="olth col-md-3">{i18next.t('biddingChanges.accounts')}</div>
                <div className="olth col-md-3">{i18next.t('biddingChanges.campaigns')}</div>
                <div className="olth col-md-3">{i18next.t('biddingChanges.adGroups')}</div>
                <div className="olth col-md-3">{i18next.t('biddingChanges.keywords')}</div>
              </div>
              <div className="oltbody col-md-12" style={{backgroundColor: 'rgba(0, 0, 0, 0.03)'}}>
                <div className="oltd col-md-3 text-center">{summary.account != null ? summary.account.toLocaleString() : null}</div>
                <div className="oltd col-md-3 text-center">{summary.campaign != null ? summary.campaign.toLocaleString() : null}</div>
                <div className="oltd col-md-3 text-center">{summary.ad_group != null ? summary.ad_group.toLocaleString() : null}</div>
                <div className="oltd col-md-3 text-center">{summary.keywords != null ? summary.keywords.toLocaleString() : null}</div>
              </div>
            </div>
          </Section>
        </>
      )}
      <Section padding={0}>
        <Table
          autoHeight={true}
          tableName={'bidding_change'}
          fetch={tableData => {return getData(tableData)}}
          columns={getColumns()} />
      </Section>
    </Page>
  )
}

export default withRouter(Details)