import request from './request';
import querystring from 'querystring';

// bidding_changes
export function getBiddingChanges(internal_client_id, table_data) {
  return request('/api/bidding_changes?internal_client_id=' + internal_client_id + '&' + querystring.stringify(table_data));
}

// bidding_changes
export function getBiddingChange(id, table_data) {
  return request('/api/bidding_changes/' + id + '?' + querystring.stringify(table_data));
}
