import { Autocomplete, Box, Chip, TextField, createFilterOptions, Checkbox } from "@mui/material"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import i18next from 'i18next'

/*
This uses an MUI Autocomplete (https://mui.com/material-ui/react-autocomplete/).  Any of those properties can be passed through.
It defaults to multi-select (you can override multiple={false}).
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText (optional) - the label to use for the pull down (this should generally be an i18n key)
  optionFunction (optional) - a function to produce the name for an option (passed in the option); should return a string
  optionIndentFunction (optional) - a function to produce the indent for an option (passed in the option); should return an intger (number of pixels)
  optionFilterFunction (optional) - a function to create a string to use for filtering the option; should return a string
  allTagsFunction (optional) - a function to produce all tags (or if null, use optionTagFunction).
  optionTagFunction (optional) - a function to produce the tag for a selected option (passed in the option); should return a string
*/

const FtoPullDown = (props) => {
  const defaultProps = {
    multiple: true,
    disableCloseOnSelect: true,
    optionFunction: option => option,
    optionIndentFunction: _ => 0,
    isConfig: false,
  }

  const dataConfigStyles = {
    '& .MuiInputBase-root': {
      color: 'primary.main',
    },
    '& .MuiInputLabel-root': {
      color: '#000000',
    },
    '& .MuiFormLabel-root': {
      fontSize: '18px',
      paddingLeft: 1,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: 'primary.main',
    },
  };

  const revisedProps = Object.assign(defaultProps, props)
  const { hideSelectedChips, textFieldVariant, isConfig, labelText, optionFunction, optionIndentFunction, optionFilterFunction, optionTagFunction, allTagsFunction, filterOptions, getOptionLabel, ...autoProps } = revisedProps
  const finalTagFunction = optionTagFunction == null ? optionFunction : optionTagFunction
  const extraInputProps = labelText ? { label: i18next.t(labelText) } : {}
  const extraAutoProps = { getOptionLabel: getOptionLabel ? getOptionLabel : optionFunction }

  const finalFilterFunction = optionFilterFunction == null ?
    (filterOptions == null
      ? null
      : filterOptions)
    : createFilterOptions({ stringify: option => optionFilterFunction(option) }
    )
  if (finalFilterFunction) {
    extraAutoProps['filterOptions'] = finalFilterFunction
  }
  const allTags = allTagsFunction ? allTagsFunction() : null
  autoProps['renderInput'] = params => <TextField
    {...params}
    {...extraInputProps}
    sx={isConfig ? dataConfigStyles : {}}
    variant={isConfig ? textFieldVariant : 'outlined'} />
  autoProps['renderOption'] = (optionProps, option) => (
    <Box component="li" {...optionProps} key={optionProps.id}>
      <div style={{ marginLeft: optionIndentFunction(option) }}>
        {isConfig ? <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          style={{ marginRight: 8 }}
          checked={autoProps.value.includes(option)}
        /> : null}
        {optionFunction(option)}
      </div>
    </Box>
  )
  autoProps['renderTags'] = (value, getTagProps) => {
    if (allTags == null && !hideSelectedChips) {
      // returns selected options
      return (
        value.map((option, index) => (
          <Chip
            size='small'
            sx={{
              bgcolor: 'primary.main',
              color: 'white.100',
              width: "auto",
              display: "grid",
              gridTemplateColumns: "auto auto",
              '& .MuiChip-label': {
                fontSize: 13,
              },
              '& .MuiSvgIcon-root': {
                color: 'white.100',
                paddingLeft: 1
              },
            }}
            key={index} variant="outlined" label={finalTagFunction(option)} {...getTagProps({ index })} />
        ))
      )
    } else if (hideSelectedChips && !allTags) {
      // returns 'Filtered Selection' chip
      return (
        <Chip
          size='small'
          variant='filled'
          sx={{
            width: "auto",
            display: "grid",
            gridTemplateColumns: "auto auto",
            my: 1,
            '& .MuiChip-label': {
              fontSize: 15,
              paddingLeft: 2,
              paddingRight: 2,
            },

          }}
          label={i18next.t("widgets.filtered")} />
      )
    } else {
      // below returns 'all' chip
      return (
        <Chip
          size='small'
          variant='filled'
          sx={{
            width: "auto",
            display: "grid",
            gridTemplateColumns: "auto auto",
            my: 1,
            '& .MuiChip-label': {
              fontSize: 15,
              paddingLeft: 2,
              paddingRight: 2,
            },

          }}
          label={i18next.t(allTags)} />
      )
    }
  }
  return (
    <Autocomplete {...autoProps} {...extraAutoProps} />
  )
}

export default FtoPullDown