import { Component } from "react"
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import CircularProgress from '@mui/material/CircularProgress'
import Form from "../Form"
import Field from "../Field"
import FTNotice from '../FTNotice'
import { getIntegrations } from "../../api/integrations"
import { getEngineData, addEditEngineData } from '../../api/ad_engines'

class Configure extends Component {
  DEFAULTS = -999

  constructor(props) {
    super(props)
    this.state = this.getDefaults()
    this.state = Object.assign(this.state, {
      disable_button: false,
      use_default: null,
      integration: null,
    })
  }

  componentDidMount() {
    getIntegrations(this.props.internal_client_id, this.props.match.params.engine).then(integration => {
      this.setState({integration: integration}, () => this.getData(this.props.internal_client_id))
    })
  }

  getDefaults() {
    return {
      developer_token: '',
      refresh_token: '',
      access_token: '',
      client_id: '',
      application_id: '',
      client_secret: '',
      application_secret: '',
      user_agent: '',
      search_credentials: '',
      user_name: '',
      password: '',
      security_token: '',
      api_key: '',
      secret_api_key: '',
      json: '',
      consumer_key: '',
      consumer_secret: '',
    }
  }

  getData(internal_client_id, changeDefaultFlag=true) {
    getEngineData(this.props.match.params.engine, internal_client_id).then(response => {
      if (response.success) {
        let data = null
        if (response.data && response.data != null) {
          data = response.data
        } else if (internal_client_id != this.DEFAULTS && changeDefaultFlag) {
          return this.getData(this.DEFAULTS, changeDefaultFlag)
        } else {
          data = this.getDefaults()
          data.use_default = true
        }
        const newState = {}
        if (changeDefaultFlag) {
          newState.use_default = internal_client_id == this.DEFAULTS
        }
        this.state.integration.fields.forEach(field => {
          newState[field] = data[field]
        })
        this.setState(newState)
      } else {
        FTNotice('Error: '+ response.data, 15000)
      }
    })
  }

  saveData() {
    this.setState({disable_button: true})
    let data = {
      use_default: this.state.use_default,
    }
    this.state.integration.fields.forEach(field => {
      data[field] = this.state[field]
    })
    addEditEngineData(this.props.match.params.engine, this.props.internal_client_id, data).then(response => {
      if (response.success) {
        location.href = '/#/integrations'
      } else {
        FTNotice('Update failed with error: ' + response.error, 15000)
        this.setState({disable_button: false})
      }
    })
  }

  handleUseDefault() {
    this.setState({
        use_default: !this.state.use_default,
        disable_button: true
      }, 
      () => {
        if (this.state.use_default) {
          this.getData(this.DEFAULTS, false)
        } else {
          this.getData(this.props.internal_client_id, false)
        }
        this.setState({disable_button: false})
      }
    )
  }

  render() {
    return (
      <Form
        menu="Automate"
        item="Integrations"
        objectType="Ad Engine"
        objectName={null}
        newObject={false}
        canDelete={false}
        canEdit={this.state.use_default != null}
        updateObject={() => this.saveData()}
        parentLink={'/integrations'}
        otherButtons={<button className="btn btn-primary" onClick={() => location.href='/#/integrations'} style={{marginLeft: 10}}>Cancel</button>}
      >
        {this.state.use_default != null && (
          <div className="js_complete">
            <div style={{textAlign: 'center', paddingTop: '30px'}}>
              <h1>{this.state.integration.name} Credentials</h1>
              {this.state.integration.help && (
                <p>To get started, see &nbsp;
                  <a target="_blank"
                    href={this.state.integration.help}
                    style={{cursor:'pointer'}}>
                    Link
                  </a>
                </p>
              )}
            </div>
            { this.props.is_admin && (
              <div className="col-md-12 mt-25 text-center">
                <input type="checkbox"
                  checked={ this.state.use_default }
                  onChange={ () => this.handleUseDefault() }/> Use for all clients
              </div>
            )}
            {this.state.integration.fields.indexOf('developer_token') >= 0 && (
              <Field label="Developer Token">
                <input type="text"
                  value={this.state.developer_token}
                  onChange={event => this.setState({developer_token:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('refresh_token') >= 0 && (
              <Field label="Refresh Token">
                <input type="text"
                  value={this.state.refresh_token}
                  onChange={event => this.setState({refresh_token:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('client_id') >= 0 && (
              <Field label="Client ID">
                <input type="text"
                  value={this.state.client_id}
                  onChange={event => this.setState({client_id:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('consumer_key') >= 0 && (
              <Field label="Consumer Key">
                <input type="text"
                  value={this.state.consumer_key}
                  onChange={event => this.setState({consumer_key:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('application_id') >= 0 && (
              <Field label="Application ID"> 
                <input type="text"
                  value={this.state.application_id}
                  onChange={event => this.setState({application_id:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('client_secret') >= 0 && (
              <Field label="Client Secret">
                <input type="password"
                  value={this.state.client_secret}
                  onChange={event => this.setState({client_secret:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('consumer_secret') >= 0 && (
              <Field label="Consumer Secret">
                <input type="password"
                  value={this.state.consumer_secret}
                  onChange={event => this.setState({consumer_secret:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('application_secret') >= 0 && (
              <Field label="Application Secret">
                <input type="password"
                  value={this.state.application_secret}
                  onChange={event => this.setState({application_secret:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('user_name') >= 0 && (
              <Field label="Username">
                <input type="text"
                  value={this.state.user_name}
                  onChange={event => this.setState({user_name:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('password') >= 0 && (
              <Field label="Password">
                <input type="password"
                  value={this.state.password}
                  onChange={event => this.setState({password:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('security_token') >= 0 && (
              <Field label="Security Token">
                <input type="text"
                  value={this.state.security_token}
                  onChange={event => this.setState({security_token:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('api_key') >= 0 && (
              <Field label="API Key">
                <input type="text"
                  value={this.state.api_key}
                  onChange={event => this.setState({api_key:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('secret_api_key') >= 0 && (
              <Field label="Secret API Key">
                <input type="password"
                  value={this.state.secret_api_key}
                  onChange={event => this.setState({secret_api_key:event.target.value})}
                  className="form-control" />
              </Field>
            )}
            {this.state.integration.fields.indexOf('json') >= 0 && (
              <Field label="JSON">
                <textarea
                  style={{width: 520}}
                  rows="40"
                  value={this.state.json}
                  onChange={event => this.setState({json:event.target.value})}
                  className="form-control" />
              </Field>
            )}
          </div>
        )}
        {this.state.use_default == null && (
          <div style={{width: '100%', textAlign: 'center'}}>
            <CircularProgress/>
          </div>
        )}
      </Form>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    is_admin: state.users.user ? state.users.user.role == 'Admin' : false,
  }
}

export default withRouter(connect(mapStateToProps)(Configure))
