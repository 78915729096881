import React from 'react'
import FTOFullScreenDialog from '../../widgets/FullScreenDialog'
import FullScreenDialogContent from './FullScreenDialogContent'

const FullScreenDialog = (props) => {
  // handle user actions that close the full screen bid summary dialog
  const handleClose = () => {
    if (props.status != 'PENDING') {
      props.setBidSummaryOpen(false)
      props.setRevertPathChosen(0)
    } else if (props.status == 'PENDING' && props.isRevert) {
      window.location.href = `/#/bidding/`
    }
  }

  return (
    <FTOFullScreenDialog
      isAdmin={props.isAdmin}
      open={props.bidSummaryOpen ? true : false}
      onClose={handleClose}
      title={"biddingRun.biddingRunId"}
      bidding_run_id={props.bidding_run_id}
    >
      <FullScreenDialogContent isAdmin={props.isAdmin} {...props} />
    </FTOFullScreenDialog>
  )
}

export default FullScreenDialog