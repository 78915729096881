import ReactMarkdown from "react-markdown"
import TableExtractor from './TableExtractor'
import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

const MarkdownResponse = ({ status, response, source, aiBot }) => {
  const [markDownTable, setMarkDownTable] = useState('')
  const [remainingText, setRemainingText] = useState('')
  const [inTable, setIntable] = useState(false)

  useEffect(() => {
    const lines = response.split('\n')
    const tableLines = []
    const beforeTableLines = []
    const afterTableLines = []
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]
      if (line.trim().startsWith('|')) {
        tableLines.push(line)
        setIntable(true)
      } else {
        if (inTable) {
          // The remaining lines after the table
          afterTableLines.push(...lines.slice(i))
          break
        } else {
          // The lines before the table
          beforeTableLines.push(line)
        }
      }
    }
    const joinedTableLines = tableLines.join('\n')
    setMarkDownTable(joinedTableLines)
    const joinedRemainingText = beforeTableLines.concat(afterTableLines).join('\n')
    setRemainingText(joinedRemainingText)
  }, [response])

  return (
    <Typography
      component='div'
      sx={{
        fontFamily: 'EB Garamond',
        fontSize: '2.1rem',
        textAlign: 'left',
        color: 'white.100',
      }}>
      <ReactMarkdown children={remainingText} />

      {inTable ? <TableExtractor inTable={inTable} markDownTable={markDownTable} /> : null}
    </Typography>
  )
}

export default MarkdownResponse