const styles = {
  node: {
    padding: 10,
    height: 60,
    width: 100,
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}
  
export default styles
