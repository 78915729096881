import { Component } from 'react'
import { connect } from 'react-redux'
import Field from '../Field'
import FTNotice from '../FTNotice'
import DatePicker from '../widgets/DatePicker'
import { forecast } from '../../api/forecasts.js'
import { filterInteger } from '../../utilities/utils'
import { dataFormatter } from '../../utilities/formatting'

class Interface extends Component {
  constructor(props) {
    super(props)
    const nextMonth = moment().add(1, 'months')
    this.state = {
      start_date: nextMonth.startOf('month').format('YYYY-MM-DD'),
      end_date: nextMonth.endOf('month').format('YYYY-MM-DD'),
      budget: '',
      results: null,
      inProgress: false,
    }
  }

  forecast() {
    if (this.state.budget == '' || parseInt(this.state.budget) == 0) {
      FTNotice('Please enter a budget greater than 0', 15000)
    } else {
      this.setState({inProgress: true}, () => {
        forecast(this.props.internal_client_id, this.state.start_date, this.state.end_date, this.state.budget, (results) => this.setState({results: results, inProgress: false}))
      })
    }
  }

  render() {
    const results = this.state.results
    return (
      <div className='site-wrapper-offcanvas' id="chart-hudd">
        <div className='site-canvas'>
          <div className="box" style={{height: 'fit-content', minHeight: 400, marginLeft: 15, width: 'calc(100% - 30px)', paddingBottom: 25}}>
            <div className="row">
              <Field label="Date Range">
                <DatePicker
                  start={this.state.start_date}
                  end={this.state.end_date}
                  parent={this}
                  showCompare={false}
                  callback={(dates) => {this.setState({start_date: dates.start, end_date: dates.end})}}
                />
              </Field>
              <Field label="Budget">
                <input type="text"
                  value={this.state.budget}
                  onKeyUp={event => filterInteger(event)}
                  onChange={event => this.setState({budget:event.target.value.replace(/[^0-9]/g,'')})}
                  className="form-control" />
              </Field>
              <div className="col-md-12 mt-25 text-center js_complete">
                <button name="edit" className="btn btn-primary" onClick={() => this.forecast()}>
                  Forecast
                </button>
              </div>
            </div>
            {this.state.inProgress && (
              <div className="row" style={{textAlign: 'center', marginTop: 25}}>
                Generating forecast...
              </div>
            )}
            {!this.state.inProgress && results && results.clicks != null && (
              <div className="row" style={{textAlign: 'center', marginTop: 25}}>
                <div>Clicks: {dataFormatter('clicks')(results.clicks)}</div>
                <div>Conversions: {dataFormatter('conversions')(results.conversions)}</div>
                <div>Revenue: {dataFormatter('revenue')(results.revenue)}</div>
                {results.roas != null && <div>ROAS: {dataFormatter('roas')(results.roas)}</div>}
                {results.cpa != null && <div>CPA: {dataFormatter('cpa')(results.cpa)}</div>}
              </div>
            )}
            {!this.state.inProgress && results && results.clicks == null && (
              <div className="row" style={{textAlign: 'center', marginTop: 25}}>
                No data available to make a forecast
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
})

export default connect(mapStateToProps)(Interface)
