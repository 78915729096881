import React, { useEffect, useState } from 'react'
import ComparisonChartWithSelectors from "../charts/ComparisonChartWithSelectors"
import { getSummary } from '../../api/ads'
import { getChartAnnotations } from '../../api/dashboards'

const ClientChart = ({internal_client_id}) => {
  const FROM_DATE = moment().subtract(28, "day").format("YYYY-MM-DD")
  const TO_DATE = moment().subtract(1, "day").format("YYYY-MM-DD")
  const [annotations, setAnnotations] = useState()
  const [config1, setConfig1] = useState({engine: 'all', metric: 'clicks'})
  const [data1, setData1] = useState({summary: null, results: null})
  const [config2, setConfig2] = useState({engine: 'all', metric: 'impressions'})
  const [data2, setData2] = useState({summary: null, results: null})

  useEffect(() => {
    fetchData()
  }, [config1, config2])

  useEffect(() => {
    getClientChartAnnotations()
  }, [])

  const fetchDataSet = (config, setData) => {
    const params = {
      internal_client_id,
      from_date: FROM_DATE,
      to_date: TO_DATE,
    }
    if (config.engine != "all") {
      params["engines"] = config.engine
    }
    getSummary(params).then(response => {
      setData({summary: response.summary, results: response.results})
    })
  }

  const fetchData = () => {
    fetchDataSet(config1, setData1)
    if (config1.engine != config2.engine) {
      fetchDataSet(config2, setData2)
    } else {
      setData2({summary: null, results: null})
    }
  }
  
  const getClientChartAnnotations = () => {
    getChartAnnotations('homepage', internal_client_id).then(
      response => setAnnotations(response.results)
    )
  }
  
  const updateConfig = values => {
    if (values.engine1 != config1.engine || values.metric1 != config1.metric) {
      setConfig1({engine: values.engine1, metric: values.metric1})
    }
    if (values.engine2 != config2.engine || values.metric2 != config2.metric) {
      setConfig2({engine: values.engine2, metric: values.metric2})
    }
  }

  return (
    <ComparisonChartWithSelectors
      annotationType="homepage"
      annotations={annotations}
      updateAnnotationsCallback={() => getClientChartAnnotations()}
      fromDate1={FROM_DATE}
      toDate1={TO_DATE}
      summary1={data1.summary}
      dataset1={data1.results}
      engine1={config1.engine}
      metric1={config1.metric}
      summary2={data2.summary ? data2.summary : data1.summary}
      dataset2={data2.results ? data2.results : data1.results}
      engine2={config2.engine}
      metric2={config2.metric}
      callback={values => updateConfig(values)}
      maxHeight={300}
    />
  )
}

export default ClientChart
