import { Component } from 'react'
import { connect } from 'react-redux'
import FTNotice from '../FTNotice'
import { getDataOverview, saveDataOverview, deleteFeed } from '../../api/feeds'
import { getClient, patchClient } from '../../api/clients'
import { updateState } from '../../utilities/feed_utils'
import Field from '../Field'
import DeleteButton from '../DeleteButton'
import Schedule from '../widgets/Schedule'
import i18next from 'i18next'

class FeedOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      internal_client_id: props.isAdmin ? '' : props.internal_client_id,
      current_client: null,
      schedule: '0 0 * * *',
      trigger: 'cron',
      status: true,
      name: '',
      description: '',
      has_conversions: false,
      showEngineButtons: true,
    }
    this.saveOverview = this.saveOverview.bind(this)
    this.deleteFeed = this.deleteFeed.bind(this)
  }

  componentDidMount() {
    if (this.props.feed_id) {
      getDataOverview(this.props.feed_id).then((response) => {
        if (response.internal_client_id == null) {
          response.internal_client_id = ''
        }
        this.setState(response, () =>
          this.warnUsingEngineRevenue(this.state.internal_client_id)
        )
      })
    }
  }

  deleteFeed() {
    deleteFeed(this.props.feed_id).then(function (response) {
      if (response.success) {
        window.location.href = '/#/feeds/section/all'
      } else {
        FTNotice('feeds.overview.couldNotDelete', 3000)
      }
    })
  }

  warnUsingEngineRevenue(internal_client_id) {
    this.setState({ showEngineButtons: false })
    if (internal_client_id != '') {
      getClient(internal_client_id).then((client) => {
        this.setState({ current_client: client, showEngineButtons: true })
      })
    }
  }

  turnOffEngineRevenue() {
    patchClient({internal_client_id: this.state.internal_client_id, use_engine_revenue: false}).then(response => {
      if (response == null || response.error) {
        FTNotice(i18next.t('feeds.overview.couldNotUpdate', {error: response ? response.error : i18next.t('feeds.overview.unknownError')}), 15000)
      } else {
        this.setState({ current_client: client })
      }
    })
  }

  render() {
    return (
      <div>
        <Field label="feeds.overview.name">
          <input
            type="text"
            className="form-control"
            value={this.state.name}
            onChange={event => updateState('name', event.target.value, this)}
          />
        </Field>
        <Field label="feeds.overview.description">
          <textarea
            rows="5"
            className="form-control"
            value={this.state.description}
            onChange={event =>
              updateState('description', event.target.value, this)
            }
          />
        </Field>
        {this.props.isAdmin && (
          <Field label="feeds.overview.client">
            <select
              className="form-control"
              value={this.state.internal_client_id}
              onChange={(event) => {
                updateState('internal_client_id', event.target.value, this)
                this.warnUsingEngineRevenue(event.target.value)
              }}
            >
              {this.props.clients.map(client => {
                return (
                  <option
                    key={client.internal_client_id}
                    value={client.internal_client_id}
                  >
                    {client.name}
                  </option>
                )
              })}
              <option value={""}>{i18next.t('feeds.overview.all')}</option>
            </select>
          </Field>
        )}
        <Field label="Schedule">
          <div className="col-md-12 mt-25">
            <div className="col-md-4">
              <input
                type="radio"
                checked={this.state.trigger == 'cron'}
                onChange={() => updateState('trigger', 'cron', this)}
              />
              <label>{i18next.t('feeds.overview.atSpecifiedTime')}</label>
            </div>
          </div>
          {this.state.trigger == 'cron' && (
            <Schedule
              value={this.state.schedule}
              onChange={(schedule) => updateState('schedule', schedule, this)}
            />
          )}
          <div className="col-md-12 mt-25">
            <div className="col-md-4">
              <input
                type="radio"
                checked={this.state.trigger != 'cron'}
                onChange={() => updateState('trigger', 'costs', this)}
              />
              <label>{i18next.t('feeds.overview.whenCalledByDag')}</label>
            </div>
          </div>
          {this.state.trigger != 'cron' && (
            <div
              id="structure"
              className="col-md-12 mt-25"
              style={{ marginLeft: "20px" }}
            >
              <div className="col-md-8">
                <div>
                  <label>{i18next.t('feeds.overview.dag')}</label>
                  <select
                    value={this.state.trigger}
                    onChange={event =>
                      updateState('trigger', event.target.value, this)
                    }
                    className="form-control"
                  >
                    <option value="structure">{i18next.t('feeds.overview.structure')}</option>
                    <option value="costs">{i18next.t('feeds.overview.costs')}</option>
                    <option value="revenue">{i18next.t('feeds.overview.revenue')}</option>
                    <option value="after_revenue">{i18next.t('feeds.overview.afterRevenue')}</option>
                  </select>
                </div>
                {this.state.trigger == 'costs' && (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.has_conversions}
                      onChange={event => updateState('has_conversions', event.target.checked, this)}
                    />{' '}
                    {i18next.t('feeds.overview.hasConversions1')} <a href={"/#/clients/" + this.props.internal_client_id}>{i18next.t('feeds.overview.hasConversions2')}</a> {i18next.t('feeds.overview.hasConversions3')}
                  </div>
                )}
              </div>
              {this.state.trigger == 'revenue' &&
              this.state.showEngineButtons &&
              this.state.current_client != null &&
              this.state.current_client.use_engine_revenue ? (
                <div>
                  {i18next.t('feeds.overview.currentlyEngineRevenue')}
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => this.turnOffEngineRevenue()}
                  >
                    {i18next.t('feeds.overview.yes')}
                  </button>
                  /
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => this.setState({ showEngineButtons: false })}
                  >
                    {i18next.t('feeds.overview.no')}
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </Field>
        <Field label="Status">
          <div>
            <input
              type="checkbox"
              checked={this.state.status}
              onChange={event => updateState('status', event.target.checked, this)}
            />{' '}
            {i18next.t('feeds.overview.active')}
          </div>
        </Field>
        <div className="col-md-12 mt-25 text-center">
          <button
            name="edit"
            className="btn btn-primary"
            onClick={() => this.saveOverview()}
          >
            {this.props.feed_id ? i18next.t('feeds.overview.update') : i18next.t('feeds.overview.add')} {i18next.t('feeds.overview.feed')}
          </button>
          {this.props.feed_id && (
            <DeleteButton
              objectType="Feed"
              objectName={this.state.name}
              canDelete={this.props.feed_id}
              deleteObject={this.deleteFeed}
            />
          )}
        </div>
      </div>
    )
  }

  saveOverview() {
    if (this.state.name.trim() == '') {
      FTNotice('feeds.overview.pleaseEnterName', 3000)
    } else {
      const formData = Object.assign({}, this.state)
      formData.id = this.props.feed_id
      formData.internal_client_id =
        formData.internal_client_id == '' ? null : formData.internal_client_id
      delete formData.current_client
      delete formData.showEngineButtons
      saveDataOverview(formData).then((response) => {
        if (response) {
          if (response.success) {
            this.props.isChanged(false)
            FTNotice('feeds.overview.feedHasBeenSaved', 3000)
            if (response.new_feed) {
              window.location.href = '/#/feeds/' + response.id + '/overview'
            }
          } else {
            FTNotice('feeds.overview.nameMustBeUnique', 3000)
          }
        }
      })
    }
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default connect(mapStateToProps)(FeedOverview)
