import React from 'react'
import RadioButtons from '../widgets/RadioButtons'
import RadioButton from '../widgets/RadioButton'


const SelectionMethod = (props) => {
  const handleChange = (e) => {
    props.setSelectionMethod(e.target.value)
  }

  return (
    <RadioButtons value={props.selectionMethod} onChange={handleChange} groupText="abtest.edit.selectionMethod">
      <RadioButton value="campaign" labelText="abtest.edit.selectCampaigns" />
      <RadioButton value="label" labelText="abtest.edit.selectLabels" />
      <RadioButton value="sql" labelText="abtest.edit.editSQL" />
    </RadioButtons>
  )
}

export default SelectionMethod