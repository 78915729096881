import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import i18next from 'i18next'

/*
Confirm something.  Passes in properties:
opened - whether the dialog should be open (true or false)
title - the title of the dialog (ex., Delete User)
question - the question to ask the user (ex., Are you sure you want to delete Walter Bodwell?)
button - Name of the confirmation button.
cancelButton - Name of the cancel button.
action - function to be called on completion (takes a parameter which is true if confirmed, false otherwise.
*/
const Confirm = ({ title, question, action, opened, button = 'confirm.ok', cancelButton = 'confirm.cancel' }) => {
  const cancel = (event) => {
    event.stopPropagation()
    action(false)
  };

  const ok = (event) => {
    event.stopPropagation()
    action(true)
  };

  return (
    <div>
      <Dialog
        open={opened}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{i18next.t(title)}</DialogTitle>
        <DialogContent>
          {i18next.t(question).split('\n').map((str, i) => (
            <DialogContentText key={i} id="alert-dialog-description">
              {str}
            </DialogContentText>
          ))}
        </DialogContent>
        <div className="text-center" style={{ marginBottom: 20 }}>
          <button className="btn btn-primary" onClick={ok}>{i18next.t(button)}</button>
          <button className="btn btn-primary" onClick={cancel} style={{ marginLeft: 10 }}>{i18next.t(cancelButton)}</button>
        </div>
      </Dialog>
    </div>
  )
}

export default Confirm