import { Component } from 'react'
import DailyChart from './DailyChart'
import GroupedChart from './GroupedChart'
import Stack from '../widgets/Stack'
import { fetchDailyDimensionsData } from '../../api/dimensions'

export default class Summary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      summary: null,
      daily: null,
    }
  }

  componentDidMount() {
    this.getChartData()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.getChartData()
    }
  }

  getChartData() {
    if (this.props.dimension) {
      const { start, end, compareStart, compareEnd } = this.props
      const params = Object.assign(
        {
          internal_client_id: this.props.internal_client_id,
          dimension: this.props.dimension,
          from_date: start,
          to_date: end,
          group: 'date',
          from_compare: compareStart === null ? start : compareStart,
          to_compare: compareEnd === null ? end : compareEnd,
        },
        this.props.filters
      )
      fetchDailyDimensionsData(params).then(response => {
        this.setState(response)
      })
    }
  }

  render() {
    if (this.props.charts && this.props.dimensionName && this.state.summary) {
      const commonProps = this.props
      return (
        <Stack sx={{paddingTop: 2}}>
          <DailyChart {...commonProps} data={this.state.daily} />
          <GroupedChart {...commonProps} data={this.state.summary} />
        </Stack>
      )
    } else {
      return null
    }
  }
}
