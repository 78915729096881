import request from "./request"

// revenue_pipeline
export function getSteps(internal_client_id, type) {
  return request(`/api/revenue_pipeline?internal_client_id=${internal_client_id}&type=${type}`)
}

// revenue_pipeline
export function patchStep(step_id, data) {
  return request(`/api/revenue_pipeline?step_id=${step_id}`, {}, 'PATCH', data)
}

// revenue_pipeline
export function getStep(step_id) {
  return request(`/api/revenue_pipeline?step_id=${step_id}`)
}

// revenue_pipeline
export function addStep(data) {
  return request('/api/revenue_pipeline', {}, 'POST', data)
}

// revenue_pipeline
export function deleteStep(step_id) {
  return request(`/api/revenue_pipeline?step_id=${step_id}`, {}, 'DELETE')
}

// revenue_pipeline
export function backfill(data) {
  return request('/api/revenue_pipeline/backfill', {}, 'POST', data)
}
