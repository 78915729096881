import React, { useState } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import { getAbTestResults } from '../../api/ab_tests'
import { formatPercent, formatFloat, formatCurrency } from '../../utilities/formatting'
import Title from '../widgets/Title'
import Tab from '../widgets/Tab'
import Tabs from '../widgets/Tabs'
import Page from '../widgets/Page'
import { Box } from '@mui/material'

export const tabs = [
  { name: 'active', text: 'Active' },
  { name: 'historical', text: 'Historical' },
  { name: 'all', text: 'All' },
]

export const initTabIndex = (match) => {
  const tab = match.params.tab
  switch (tab) {
    case undefined:
      return 0
    case 'active':
      return 0
    case 'historical':
      return 1
    case 'all':
      return 2
  }
}

const updateTab = (index, getBaseUrl, setTabIndex, history) => {
  history(getBaseUrl() + tabs[index].name)
  setTabIndex(index)
}

export const render = (internal_client_id, getBaseUrl, tabIndex, setTabIndex, getAddNew, getTableName, getRows, getColumns, history) => {
  if (tabIndex != null && internal_client_id != null && internal_client_id != undefined) {
    return (
      <Page>
        <Title title="menu.ab_tests" />
        <Box sx={{ pb: 3 }}>
          <Tabs value={tabIndex} onChange={(_, newValue) => updateTab(newValue, getBaseUrl, setTabIndex, history)} centered>
            {tabs.map((tab, i) => <Tab key={tab.name} index={i} value={i} label={tab.text} />)}
          </Tabs>
        </Box>
        {getAddNew()}
        <Table
          changeParams={{ tabIndex }}
          tableName={getTableName()}
          fetch={(tableData) => getRows(tableData)}
          columns={getColumns()} />
      </Page>
    )
  } else {
    return null
  }
}

const ABTests = ({ match, history, internal_client_id }) => {
  const [tabIndex, setTabIndex] = useState(initTabIndex(match))

  const getBaseUrl = () => {
    return '/ab_tests/'
  }

  const getABTestLink = (row) => {
    return '/ab_tests/ab_test/' + row.change_log_id + '/' + (row.is_control ? 'control' : 'experiment')
  }

  const getColumns = () => {
    return [{
      id: 'title',
      localeKey: 'ab_test_table_title',
      datatype: 'text',
      width: 400,
      cellRenderer: (value, row) => (
        <div>
          <Link to={getABTestLink(row)}>
            {value}
          </Link>
        </div>
      )
    }, {
      id: 'test_created',
      header: 'Start Date',
      width: 200,
      datatype: 'date',
    }, {
      id: 'duration',
      localeKey: 'ab_test_table_duration',
      datatype: 'numeric',
      width: 100,
    }, {
      id: 'revenue_change',
      localeKey: 'ab_test_table_revenue_change',
      datatype: 'numeric',
      width: 120,
      format: value => formatCurrency(value),
    }, {
      id: 'revenue_change_percent',
      localeKey: 'ab_test_table_revenue_change_percent',
      datatype: 'numeric',
      width: 120,
      format: value => formatPercent(value),
    }, {
      id: 'roas_change',
      localeKey: 'ab_test_table_roas_change',
      datatype: 'numeric',
      width: 120,
      format: value => formatFloat(value),
    }, {
      id: 'roas_change_percent',
      localeKey: 'ab_test_table_roas_change_percent',
      datatype: 'numeric',
      width: 120,
      format: value => formatPercent(value),
    }]
  }

  const getAddNew = () => {
    return null
  }

  const getRows = (tableData) => {
    return getAbTestResults(internal_client_id, tabs[tabIndex]['name'], tableData)
  }

  const getTableName = () => {
    return 'ab_test_results'
  }

  return render(internal_client_id, getBaseUrl, tabIndex, setTabIndex, getAddNew, getTableName, getRows, getColumns, history)
}

const mapStateToProps = (state) => {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default withRouter(connect(mapStateToProps)(ABTests))