import * as React from 'react'
import { Stack, Grid } from '@mui/material'
import ConfirmPublishSubset from './ConfirmPublishSubset'
import Button from '../../widgets/Button'

const PublishBids = ({setPublishingProgressCircle, setGeneratingRevertProgressCircle, engines, campaigns, adGroups, props}) => {
  const [disabled, setDisabled] = React.useState(true)
  const [publishingStarted, setPublishingStarted] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)

  React.useEffect(() => {
    if (props.publishing || props.status == 'published' || props.status == 'queued' || props.status == 'abandoned') {
      setDisabled(true)
    }
    if (props.status == 'PENDING') {
      setDisabled(false)
    }
    if ((props.status == 'published' || props.status == 'queued') && publishingStarted === true) {
      props.setBidSummaryOpen(false)
      setPublishingStarted(false)
    }
  }, [props.status])

  const publish = () => {
    props.publish(engines, campaigns, adGroups)
    setPublishingProgressCircle(true)
    setPublishingStarted(true)
  }

  const handlePublishClick = () => {
    if (engines || campaigns || adGroups) {
      setShowConfirm(true)
    } else {
      publish()
    }
  }

  const confirmPublish = (value) => {
    setShowConfirm(false)
    if (value) {
      publish()
    }
  }

  const handleGenerateRevertClick = () => {
    props.handleRevert()
    setGeneratingRevertProgressCircle(true)
  }

  return (
    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
      <ConfirmPublishSubset open={showConfirm} onClick={confirmPublish} />
      <Stack direction="row" sx={{height: 'fit-content'}} spacing={2}>
          {!props.schedule && (
            <Button
              buttonText="biddingRun.publish"
              disabled={disabled}
              onClick={handlePublishClick}
              color="primary" />
          )}

          {!props.schedule && (
            <Button
              buttonText="biddingRun.revert"
              disabled={!disabled || props.isRevert}
              onClick={handleGenerateRevertClick}
              color="error"
            />
          )}

          <Button buttonText="biddingRun.returnToSettings" onClick={() => props.setBidSummaryOpen(false)} color="primary" />
      </Stack>
    </Grid>
  )
}

export default PublishBids
