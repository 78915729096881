import Switch from '../../widgets/Switch'
import i18next from 'i18next'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { IconButton, Tooltip } from '@mui/material'
import { patchStep } from '../../../api/workflows'

const RunScheduled = (props) => {
  const update = run_on_schedule => {
    const change = {run_on_schedule}
    patchStep(props.step.step_id, change).then(result => {
      if (result) {
        props.updateStep(Object.assign({}, props.step, change))
      }
    })
  }
  
  return props.step && props.step.run_on_schedule != null ? (
    <Tooltip title={i18next.t('workflow.runOnSchedule')}>
      <IconButton>
        <Switch checked={props.step.run_on_schedule} label={<ScheduleIcon fontSize="large" />} onChange={event => update(event.target.checked)} />
      </IconButton>
    </Tooltip>
  ) : null
}

export default RunScheduled