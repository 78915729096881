import React from 'react'
import DateField from '../widgets/DateField'
import Stack from '../widgets/Stack'

const AbTestDatePicker = ({startDate, endDate, onUpdate}) => {
  return (
    <Stack direction="row" sx={{marginTop: 2}}>
      <DateField labelText="abtest.edit.startDate" value={startDate} onChange={newDate => onUpdate({start_date: newDate})} />
      <DateField labelText="abtest.edit.endDate" value={endDate} onChange={newDate => onUpdate({end_date: newDate})} />
    </Stack>
  )
}

export default AbTestDatePicker
