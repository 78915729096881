import { useState, forwardRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dialog, Box, Fade, Grid, IconButton, Switch, Stack, useMediaQuery } from "@mui/material"
import PromptEngInput from './PromptEngInput'
import { ThemeProvider } from '@mui/system'
import { theme } from '../widgets/theme'
import PromptDialogDashboard from './PromptDialogDashboard'
import PromptEngineeringSelect from './PromptEngineeringSelect'
import PromptBuilder from './PromptBuilder'
import Tab from '../widgets/Tab'
import Tabs from '../widgets/Tabs'
import i18next from 'i18next'
import PromptDeveloper from './PromptDeveloper'
import { postQuestion, getResponse, getConversation } from '../../api/prompts'
import FTNotice from '../FTNotice'
import ReplayIcon from '@mui/icons-material/Replay'

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade
    ref={ref}
    {...props} />
})

const PromptDialog = ({ user, open, togglePrompt }) => {
  const hidePromptInput = true
  const [status, setStatus] = useState('prompt')
  const [requiredAlert, setRequiredAlert] = useState(false)
  const [activeTab, setActiveTab] = useState('chat')
  const [demoComplete, setDemoComplete] = useState(false)
  const [promptDraft, setPromptDraft] = useState('')
  const [sentence, setSentence] = useState('') // used in PromptBuilder.jsx to store formatted draft of prompt before assigning with useEffect to promptDraft state
  const [lastPrompt, setLastPrompt] = useState('')
  const [promptEngDraft, setPromptEngDraft] = useState('')
  const [promptEngHistory, setPromptEngHistory] = useState([])
  const [fullResponse, setFullResponse] = useState({})
  const [responseOutput, setResponseOutput] = useState('')
  const [lastResponseOutput, setLastResponseOutput] = useState('')
  const [lastPromptEngDraft, setLastPromptEngDraft] = useState('')
  const [sql, setSql] = useState('')
  const [demoMode, setDemoMode] = useState(false)
  const [conversationId, setConversationId] = useState()
  const [disabled, setDisabled] = useState(false)
  const [conversation, setConversation] = useState({})
  const [promptThread, setPromptThread] = useState([{ source: '', content: '', date: '' }])
  const [selectedChips, setSelectedChips] = useState([])
  const [demoSentence, setDemoSentence] = useState('')
  const [duration, setDuration] = useState(0)
  // below used in AiRating.jsx
  const [rating, setRating] = useState('')
  const [feedback, setFeedback] = useState('')
  const [promptId, setPromptId] = useState(null)

  const aiBot = 'AiMI'
  const userName = user.name
  const startingTime = moment()
  const initialPackagedPrompt = { source: aiBot, content: i18next.t('ai.pleaseEnter'), date: startingTime.format(`YYYY-MM-DD @ h:mm:ss A`) }
  const isSmall = useMediaQuery('(max-width: 1200px)')

  const addToPromptEngHistory = () => {
    if (promptEngDraft && !promptEngHistory.includes(promptEngDraft)) {
      setPromptEngHistory(prev => [...prev, promptEngDraft])
    }
  }

  const handleActiveTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      event.preventDefault()
      handleSubmit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      handleClose()
    }
  }

  useEffect(() => {
    if (status === 'processing') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [status])

  const handleClose = () => {
    setStatus('prompt')
    togglePrompt()
    setRequiredAlert(false)
    setRating(false)
    setFeedback(false)
  }

  const handleConversationUpdate = conversationId => {
    getConversation(conversationId).then(result => {
      setConversation(result)
    })
  }

  // this loads conversation history and also updates current promptThread
  useEffect(() => {
    if (conversation.prompts) {
      const newThread = []
      let promptId = null
      let rating = null
      let feedback = null
      conversation.prompts.forEach(prompt => {
        const parsedDate = moment.utc(prompt.date, 'YYYY-MM-DD hh:mm:ss')
        if (prompt.input) {
          const formattedDate = parsedDate.local().format(`YYYY:MM:DD @ h:mm:ss A`)
          newThread.push({ source: userName, content: prompt.input, date: formattedDate })
        }
        if (prompt.output) {
          const calculatedDate = parsedDate.valueOf() + prompt.duration
          const parsedCalculatedDate = moment(calculatedDate)
          const formattedDate = parsedCalculatedDate.local().format(`YYYY:MM:DD @ h:mm:ss A`)
          newThread.push({ source: aiBot, content: prompt.output, date: formattedDate })
        }
        promptId = prompt.id
        rating = prompt.rating == 1 ? 'good' : prompt.rating == 0 ? 'not good' : ''
        feedback = prompt.rating_reason == null ? '' : prompt.rating_reason
      })
      setConversationId(conversation.id)
      setPromptThread(newThread)
      setPromptId(promptId)
      setRating(rating)
      setFeedback(feedback)
      if (conversation.prompts.length > 0) {
        const lastPrompt = conversation.prompts[conversation.prompts.length - 1]
        setSql(lastPrompt.sql)
        setDuration(lastPrompt.duration)
      }
    }
  }, [conversation])


  // add newest promptDraft to promptThread
  const addToThread = () => {
    if (promptDraft) {
      const date = moment()
      const userTimeFormatted = date.format(`YYYY:MM:DD @ h:mm:ss A`)
      setPromptThread(() => [...promptThread, { source: userName, content: promptDraft, date: userTimeFormatted }])
    }
  }

  // this function sends information to the server API and handles various tasks related to packaging information and managing state
  const handleSubmit = () => {
    if (!promptDraft && !promptEngDraft) {
      return
    }
    if (!promptDraft && promptEngDraft) {
      addToPromptEngHistory()
    }
    if (promptDraft !== '') {
      addToThread()
      setStatus('processing')
      addToPromptEngHistory()
      setLastPrompt(promptDraft)
      setLastPromptEngDraft(promptEngDraft)
      setLastResponseOutput(responseOutput)
      setRequiredAlert(false)
      postQuestion(promptDraft, promptEngDraft, conversationId).then(response => {
        if (response.successful) {
          setPromptId(response.id)
          checkResult(response.id)
        } else {
          FTNotice(i18next.t('ai.errorProcessing'))
          setStatus('prompt')
        }
      }).catch(_ => {
        FTNotice(i18next.t('ai.errorProcessing'))
        setStatus('prompt')
      })
    }
  }


  // this function does things with the data once it is returned from the server
  const checkResult = (id) => {
    getResponse(id).then(response => {
      if (response.output) {
        setRating('')
        setFeedback('')
        setFullResponse(response)
        setDuration(response.duration)
        setResponseOutput(response.output)
        setPromptDraft('')
        setSentence('')
        setStatus('prompt')
        setSql(response.sql)
        handleConversationUpdate(response.conversation_id)
      } else {
        setTimeout(() => checkResult(id), 5000)
      }
    }).catch(_ => {
      FTNotice(i18next.t('ai.errorProcessing'))
      setStatus('prompt')
    })
  }

  const handleResetInputs = () => {
    setLastResponseOutput(responseOutput)
    setLastPromptEngDraft(promptEngDraft)
    setSelectedChips([])
    setSentence('')
    setTimeout(() => {
      setResponseOutput('')
      setPromptDraft('')
      setPromptEngDraft('')
      setPromptThread([initialPackagedPrompt])
      setConversation({})
    })
  }
  
  // resets to initial message from Aimi when promptThread is reset and also restores current convo if tabs changed
  useEffect(() => {
    if (promptThread.length <= 1) {
      setPromptThread([initialPackagedPrompt])
    }
  }, [activeTab])

  const PromptTabs = () => {
    return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' sx={{ paddingTop: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'flex-start' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', marginLeft: 2 }}>
                  <Switch color='accent'
                    checked={demoMode}
                    onChange={() => setDemoMode(!demoMode)} />
                </Box>
                <Box sx={sxResetBox}>
                  <IconButton
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '4rem',
                        color: disabled ? 'grey' : 'grey.500',
                        ':hover': {
                          color: disabled ? 'grey' : 'grey.100',
                        }
                      },
                      '& button.MuiButtonBase-root.MuiIconButton-root': {
                        paddingBottom: '0px',
                      },
                      paddingBottom: '0px',
                      borderRadius: '50%',
                    }}
                    onClick={handleResetInputs}
                    disabled={disabled}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} >
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginLeft: 1 }}>
                <Tabs
                  value={activeTab}
                  onChange={handleActiveTabChange}
                  sx={sxTabs}>
                  <Tab value='chat' label='ai.chat' />
                  <Tab value='build' label='ai.tap' />
                  {!demoMode && <Tab value='prompt engineering' label='ai.customize' />}
                  {!demoMode && <Tab value="developer" label="ai.developer" />}
                </Tabs>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={0} sx={{ marginLeft: 1 }}>
                  {!demoMode && <Box sx={sxPromptEngSelect}>
                    <PromptEngineeringSelect setPromptEngDraft={setPromptEngDraft} promptEngHistory={promptEngHistory} promptEngDraft={promptEngDraft} />
                  </Box>}
                  {isSmall && (
                    <a className="btn btn-box-tool" onClick={() => handleClose()}>
                      <i className="fa fa-times" style={{color: 'white', marginTop: '15px', marginLeft: '0px'}}></i>
                    </a>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box >
    )
  }

  const sxPromptEngSelect = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    marginRight: isSmall ? 0 : 4,
    marginTop: 1.5
  }
  const sxResetBox = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    paddingLeft: 1,
  }
  const sxBox = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 1,
    width: isSmall ? '100vw' : '90vw',
    height: '100vh',
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: isSmall ? '100vw' : '90vw',
            maxHeight: isSmall ? '100vh' : '85vh',
            overflowY: 'hidden',
            margin: isSmall ? '0px' : '32px',
            height: isSmall ? '100vh !important' : '85vh',
          },
          '& .MuiDialog-container': {
            display: 'flex',
            alignContent: 'center',
            '& .MuiDialog-paper': {
              background: 'rgba(123, 31, 162, 0.75)',
              borderRadius: 5,
              height: '85vh',
            },
          }
        }}
        TransitionComponent={Transition}
        maxWidth={false}
      >
        <PromptTabs />
        <Box sx={{ ...sxBox }}>
          <Box sx={{ overflow: 'hidden' }}>

            {activeTab == 'prompt engineering' ?
              <>
                <PromptEngInput
                  handleSubmit={handleSubmit}
                  requiredAlert={requiredAlert}
                  promptEngDraft={promptEngDraft}
                  promptDraft={promptDraft}
                  setPromptEngDraft={setPromptEngDraft}
                  setPromptDraft={setPromptDraft}
                  handleKeyDown={handleKeyDown}
                  demoMode={demoMode}
                  hidePromptInput={hidePromptInput}
                  status={status}
                  disabled={disabled}
                />
              </> : null}

            {activeTab == 'build' ?

              <PromptBuilder
                status={status}
                handleSubmit={handleSubmit}
                demoComplete={demoComplete}
                setDemoComplete={setDemoComplete}
                promptDraft={promptDraft}
                setPromptDraft={setPromptDraft}
                sentence={sentence}
                setSentence={setSentence}
                lastResponseOutput={lastResponseOutput}
                handleResetInputs={handleResetInputs}
                responseOutput={responseOutput}
                setResponseOutput={setResponseOutput}
                selectedChips={selectedChips}
                setSelectedChips={setSelectedChips}
                demoSentence={demoSentence}
                setDemoSentence={setDemoSentence}
                disabled={disabled}
                isSmall={isSmall}
              />
              : null}

            {activeTab == 'chat' ?

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                maxWidth: isSmall ? 'calc(100vw - 10px)' : '80vw',
              }}>

                <PromptDialogDashboard
                  requiredAlert={requiredAlert}
                  hidePromptInput={hidePromptInput}
                  setStatus={setStatus}
                  status={status}
                  handleSubmit={handleSubmit}
                  demoComplete={demoComplete}
                  setDemoComplete={setDemoComplete}
                  promptDraft={promptDraft}
                  user={user}
                  promptEngDraft={promptEngDraft}
                  setPromptEngDraft={setPromptEngDraft}
                  setPromptDraft={setPromptDraft}
                  handleKeyDown={handleKeyDown}
                  activeTab={activeTab}
                  lastPrompt={lastPrompt}
                  setLastPrompt={setLastPrompt}
                  addToPromptEngHistory={addToPromptEngHistory}
                  conversationId={conversationId}
                  setConversationId={handleConversationUpdate}
                  conversation={conversation}
                  setConversation={setConversation}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  lastResponseOutput={lastResponseOutput}
                  aiBot={aiBot}
                  userName={userName}
                  initialPackagedPrompt={initialPackagedPrompt}
                  promptThread={promptThread}
                  setPromptThread={setPromptThread}
                  rating={rating}
                  setRating={setRating}
                  feedback={feedback}
                  setFeedback={setFeedback}
                  fullResponse={fullResponse}
                  promptId={promptId}
                  demoMode={demoMode}
                /></Box> : null
            }
            {activeTab == 'developer' &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                maxWidth: '80vw',
              }}>
                <PromptDeveloper
                  requiredAlert={requiredAlert}
                  hidePromptInput={hidePromptInput}
                  status={status}
                  demoComplete={demoComplete}
                  promptDraft={promptDraft}
                  user={user}
                  promptEngDraft={promptEngDraft}
                  lastPromptEngDraft={lastPromptEngDraft}
                  activeTab={activeTab}
                  lastPrompt={lastPrompt}
                  lastResponseOutput={lastResponseOutput}
                  conversationId={conversationId}
                  conversation={conversation}
                  disabled={disabled}
                  aiBot={aiBot}
                  userName={userName}
                  responseOutput={responseOutput}
                  initialPackagedPrompt={initialPackagedPrompt}
                  promptThread={promptThread}
                  sentence={sentence}
                  sql={sql}
                  duration={duration}
                />
              </Box>
            }
          </Box>

        </Box>
      </Dialog>
    </ThemeProvider >
  )
}

const mapStateToProps = function (state) {
  return {
    admin: state.users.user ? state.users.user.role == "Admin" : null,
    user: state.users.user ? state.users.user : null,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}
export default connect(mapStateToProps)(PromptDialog)

const sxTabs = {
  '& .MuiTabs-indicator': {
    backgroundColor: 'white.100',
  },
  "& .MuiTab-root": {
    color: 'grey.500',
    fontSize: '1.9rem',
    paddingTop: 0,
    '&:hover': {
      color: 'grey.100',
    },
    '&.Mui-selected': {
      color: 'white.100',
    }
  }
}
