import { Box } from '@mui/material'
import React from 'react'
import Button from '../../widgets/Button'
import DevModeSwitch from './DevModeSwitch'
import RunScheduled from './RunScheduled'

const ButtonNav = (props) => {
  return (
    <Box display="flex" justifyContent={"space-between"} marginTop={2}>
      <Box display="flex">
        { props.isAdmin ? <DevModeSwitch updateDevMode={props.updateDevMode} /> : null}
        { props.isAdmin ? <RunScheduled step={props.step} updateStep={step => props.updateStep(step)} /> : null}
      </Box>
      {props.showNext && (
        <Box display="flex">
          <Box>
            <Button buttonText="workflows.nextStep" color="primary" onClick={props.nextOnClick} />
          </Box>
        </Box>
      )}
    </Box >
  )
}

export default ButtonNav