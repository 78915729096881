import { Box } from "@mui/material"
import Title from '../../widgets/Title'
import Variables from "../workflow/Variables"
import * as React from "react"
import i18next from "i18next"

const WorkflowSettings = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#ffffff",
        borderRadius: 3,
        paddingBottom: 3,
        paddingTop: 3,
        width: "auto"
      }}
    >
      <Box display="flex" margin="auto" padding="3px" marginBottom="1px">
        <Title variant="h4" title={i18next.t('workflows.settings')} />
      </Box>
      <Variables
        workflow_id={props.workflow_id}
        run_id={props.run_id}
        passBack={props.passBack}
        allClients={props.allClients}
      />
    </Box>
  )
}

export default WorkflowSettings
