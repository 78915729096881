import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Table from '../widgets/Table';
import { exploreSQL } from "../../api/dashboards";

class Explore extends Component {
  getColumns() {
    return [{
      id: 'name',
      datatype: 'text',
      cellRenderer: (name, row) => (
        <Link to={`/dashboard_admin/sql/${row.id}`} className="text-center" style={{width: '100%', display: 'inline-block'}}>{name}</Link>
      ),
      width: 300,
    },{
      id: 'description',
      datatype: 'text',
      width: 500,
    },{
      id: 'tables',
      datatype: 'text',
      cellRenderer: (_, row) => (
        <>
          {row.tables.map((table, i) => (
            <div key={i} style={{textAlign: 'center'}}>{table}</div>
          ))}
        </>
      ),
      width: 300
    },{
      id: 'executions',
      datatype: 'numeric',
    }];
  }

  render() {
    return (
      <div style={{paddingLeft: 30, paddingRight: 30}}>
        <div className="box-header"style={{height: 'fit-content'}}>
          <div className="js_complete">
            <h4>Important Tables</h4>
            <ul>
              <li>reports.revenuecost - Redshift - Combines revenue and performance information for PLA's (Product Listing Ads), keywords, videos, images and DSA's (Dynamic Search Ads).</li>
              <li>reports.revenuecost_summary - Redshift and PostgreSQL - Same as reports.revenuecost, but summarized by date (no campaign, ad group or criteria info) and including dimensions.</li>
              <li>reports.revenuecost_details - Redshift and PostgreSQL - same as reports.revenuecost, but summarized by date and including campaign, ad group and criteria info as well as dimensions for the last 90 days.</li>
              <li>reports.structure - Redshift - Contains structural info (no metrics) for for active PLA's (Product Listing Ads), keywords, videos, images and DSA's (Dynamic Search Ads).</li>
              <li>reports.structure_all - Redshift - Contains structural info (no metrics) for for active and historical PLA's (Product Listing Ads), keywords, videos, images and DSA's (Dynamic Search Ads).</li>
              <li>reports.ab_test_metrics - Redshift - Contains the results of AB Tests.</li>
              <li>ab_test_metrics - PostgreSQL - The equivalent of reports.ab_test_metrics in PostgreSQL</li>
            </ul>
            <h4>Most Used SQL:</h4>
          </div>
        </div>
        <Table
          fetch={(tableData) => {return exploreSQL(this.props.internal_client_id, tableData)}}
          columns={this.getColumns()}/>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  };
}

export default connect(mapStateToProps)(Explore);
