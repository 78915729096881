import request from './request'
import querystring from 'querystring'

// links
export function getLinks(internal_client_id, table_data) {
  return request('/api/links?internal_client_id='+internal_client_id + '&' + querystring.stringify(table_data));
}

// links
export function getLink(linkID) {
  return request(`/api/links/${linkID}`)
}

// links
export function updateLink(linkId, params) {
  return request(`/api/links/${linkId}`, {}, 'PUT', params)
}

// links
export function createLink(params) {
  return request('/api/links', {}, 'POST', params)
}

// links
export function deleteLink(linkID) {
  return request(`/api/links/${linkID}`, {}, 'DELETE')
}
