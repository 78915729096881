import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import Title from '../Title'
import {CircularProgress, Box} from '@mui/material'
import {getModels, getModel, createModel} from '../../api/data_science'
import { getIcons } from '../../api/workflows'
import Button from '../widgets/Button'
import AddRow from '../AddRow'
import Icon from '../widgets/Icon'
import Section from '../widgets/Section'
import DataScience from './Interface'
import * as qs from 'query-string'
import i18next from 'i18next'
import Page from '../widgets/Page'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      icons: [],
      icon: '',
      models: null,
      showModels: false,
      currentModel: null,
      params: {},
      showCreate: false
    }
    this.loadIcons()
    if (this.props.model) {
      this.showModel(this.props.model)
    } else if (
      this.props.match &&
      this.props.match.params.model_id != null
    ) {
      getModel(this.props.match.params.model_id).then(
        (model) => {
          this.showModel(model)
        }
      )
    } else {
      this.getAllModels()
    }
  }

  MODEL_COLUMNS = [
    {title: 'dataScience.name', column: 'name', required: true},
    {title: 'datascience.client', column: 'client', required: true, values: [{id: 'All', name: 'dataScience.all'}, {id: String(this.props.internal_client_id), name: String(this.props.client_name)}]},
    {title: 'dataScience.modelType', column: 'model_type', required: true, values: [{id: 'classification', name: 'dataScience.classification'}, {id: 'regression', name: 'dataScience.regression'}]},
    {title: 'dataScience.predictionColumn', column: 'prediction_column'},
    {title: 'dataScience.icon', column: 'icon', type: 'icon', required: true},
  ]

  createRow(model){
    this.setState({showCreate: false})
    if (model != null) {
      var internal_client_id = model.client == String(this.props.internal_client_id) ? this.props.internal_client_id : null
      const data = {
        internal_client_id: internal_client_id,
        name: model.name,
        prediction_column: model.prediction_column,
        icon: model.icon
      }
      createModel(data).then(model => {
        if (model) {
          this.selectModel(model)
        }
      })
    }
  }

  loadIcons() {
    getIcons().then((result) => {
      this.setState({ icons: result.data })
    })
  }

  getAllModels() {
    const pagination = false
    getModels(
      this.props.internal_client_id,
    ).then((response) => {
      const modelId = this.props.match
        ? this.props.match.params.model_id
        : this.props.model_id
      let currentModel = null
      response.results.forEach((model) => {
        if (model.id == modelId) {
          currentModel = model
        }
      })
      if (currentModel) {
        this.showModel(currentDashboard)
      } else {
        this.setState({ models: response.results })
      }
    })
  }

  selectModel(model) {
    window.location.href = '/#/data_science/' + model.id
    this.showModel(model)
  }

  showModel(model) {
    if (model == undefined) {
      this.setState({
        params: {},
        modelId: null,
        showModel: true,
        currentModel: null,
      })
    } else {
        this.setState({
          params: qs.parse(this.props.location.search),
          modelId: model.id,
          showModel: true,
          currentModel: model
        })
    }
  }

  renderModels = (sectionName, models) => {
    if (models.length == 0) {
      return null
    }
    return (
      <>
        <h3>{sectionName}</h3>
        <div className="model">
          {models.map((model, i) => (<div className='col-md-2' key={i}> <Icon name={model.name} icon={"/resources/images/workflow_icons/" + model.icon} 
           onClick={() => this.selectModel(model)}/></div>))}
        </div>
      </>
    )}

  renderPage = () => {
    if (this.state.showModel) {
        return (
          <div className="js_complete">
            <DataScience
              model_id={this.state.currentModel.id}
            />
          </div>
        )

    } else if (this.state.models){
      return (
        <div className="js_complete">
          {this.renderModels(i18next.t('dataScience.allModels'), this.state.models)}
        </div>
      )
    }else {
      return (
              <div className="js_complete">
                <CircularProgress className="progress" />
              </div>
            )
    }
  }



  renderSection() {
    return (
      <Section
        style={{ height: this.props.model ? null : 940 }}
        padding={0}
      >
        {this.state.showModel && (
          <Title
            menu="Models"
            item={
              this.state.currentModel
                ? this.state.currentModel.title
                : i18next.t('dataScience.notExist')
            }
            link="/data_science"
          />
        )}
        {this.state.showCreate && (
            <AddRow title="dataScience.addModel" columns={this.MODEL_COLUMNS} onClose={(model) => {this.createRow(model)}}
              defaultValues={{internal_client_id: this.props.internal_client_id, training_sql: '', inferencing_sql: ''}}>
            </AddRow>
        )}
        <div className="section">
          <Box sx={{height: '10px'}} />
          <div className="add-new-button" >
            <i className="fa fa-plus" style={{color:'#7B1FA2', paddingTop: 25}}>
              <Button
                buttonText={'dataScience.addModel'}
                size='small'
                variant='text'
                style={{color:'#7B1FA2', paddingRight: 100, paddingTop: 5}}
                onClick={() => this.setState({showCreate: true})}
              />
            </i>
          </div>
          <div className="row">
            <div className="col-xs-12">
            {this.state.models && this.state.models.length > 0 && (<div className="box">
                <div className="box-header with-border">
                  <div id="root">{this.renderPage()}</div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </Section>
    )
  }

  render() {
    if (this.props.hidePage) {
      return this.renderSection()
    } else {
      return (
        <Page>
          {this.renderSection()}
        </Page>
      )
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    client_name: state.users.user
      ? state.users.user.client.name
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
