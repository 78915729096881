import request from './request'
import querystring from 'querystring'

// budget, bidding
export function getPacingInfo(internal_client_id, channels, month, subbudgets = null) {
  const json = {internal_client_id, channels, month}
  if (subbudgets != null) {
    json.subbudgets = subbudgets
  }
  return request(`/api/budget?${querystring.stringify(json)}`)
}

// budget, budget_admin
export function downloadBudget(internal_client_id, month) {
  const json = {internal_client_id, month, file_format: 'csv'}
  return request(`/api/budget?${querystring.stringify(json)}`)
}

// budget_admin
export function uploadBudget(internal_client_id, formData) {
  const options = {'content-type': 'multipart/form-data'}
  return request(`/api/budget?internal_client_id=${internal_client_id}`, options, 'POST', formData, true, true)
}

// budget, bidding
export function getEntityCostPct(start_date, end_date, internal_client_id) {
  return request(`/api/budget/entity_cost_pct?start_date=${start_date}&end_date=${end_date}&internal_client_id=${internal_client_id}`)
}

// budget_admin
export function getUnmappedCampaignSpend(internal_client_id, tableData) {
  return request(`/api/budget/unmapped_campaign_spend?internal_client_id=${internal_client_id}&${querystring.stringify(tableData)}`)
}

// bidding, budget_admin
export function getBudgetGoals(internal_client_id, tableData) {
  return request(`/api/budget/goals?internal_client_id=${internal_client_id}&${querystring.stringify(tableData)}`)
}

// bidding, budget_admin
export function createBudgetGoal(data) {
  return request('/api/budget/goals', {}, 'POST', data)
}

// bidding, budget_admin
export function updateBudgetGoal(id, data) {
  return request(`/api/budget/goals/${id}`, {}, 'PUT', data)
}

// bidding, budget_admin
export function deleteBudgetGoal(id) {
  return request(`/api/budget/goals/${id}`, {}, 'DELETE')
}
