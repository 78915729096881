import { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig, saveConfig } from '../../api/config';
import FTNotice from '../FTNotice';

class Interface extends Component {
  constructor(props) {
    super(props);
    if (props.config) {
      this.state = this.loadData(props.config);
    } else {
      this.state = {};
      getConfig('', this.props.loggedIn).then((json) => {
        delete json.initial;
        this.setState(this.loadData(json));
      });
    }
    this.state.ec2_instance = '';
  }

  loadData(config) {
    const redshift_host = config['REDSHIFT_HOST'] ? config['REDSHIFT_HOST'] : '';
    const redshift_db = config['REDSHIFT_DB'] ? config['REDSHIFT_DB'] : '';
    const redshift_port = config['REDSHIFT_PORT'] ? config['REDSHIFT_PORT'] : '';
    const initial_redshift = redshift_host == 'postgres';
    const initial_postgres = config['POSTGRES_HOST'] == 'postgres';
    let redis = 'redis:6379';
    if (config.AIRFLOW__CELERY__BROKER_URL) {
      const parts = config.AIRFLOW__CELERY__BROKER_URL.split('/');
      if (parts.length > 2) {
        redis = parts[2];
      }
    }
    return {
      orig_config: config,
      state: config.state,
      redshift_endpoint: initial_redshift ? '' : redshift_host + (redshift_port != '' ? (':' + redshift_port) : '') + (redshift_db != '' ? ('/' + redshift_db) : ''),
      redshift_username: config['REDSHIFT_USERNAME'] && !initial_redshift ? config['REDSHIFT_USERNAME'] : '',
      redshift_password: config['REDSHIFT_PASSWORD'] && !initial_redshift ? config['REDSHIFT_PASSWORD'] : '',
      postgres_endpoint: config['POSTGRES_HOST'] && !initial_postgres ? config['POSTGRES_HOST'] : '',
      postgres_username: config['POSTGRES_USERNAME'] && !initial_postgres  ? config['POSTGRES_USERNAME'] : '',
      postgres_password: config['POSTGRES_PASSWORD'] && !initial_postgres  ? config['POSTGRES_PASSWORD'] : '',
      s3_bucket: config['S3_TEMP'] ? config['S3_TEMP'] : '',
      aws_key: config['AWS_ACCESS_KEY_ID'] ? config['AWS_ACCESS_KEY_ID'] : '',
      aws_region: config['AWS_REGION'] ? config['AWS_REGION'] : 'us-east-1',
      aws_secret: config['AWS_SECRET_ACCESS_KEY'] ? config['AWS_SECRET_ACCESS_KEY'] : '',
      name: config['ADMIN_NAME'] ? config['ADMIN_NAME'] : '',
      email: config['ADMIN_EMAIL'] ? config['ADMIN_EMAIL'] : '',
      password: config['ADMIN_PASSWORD'] ? config['ADMIN_PASSWORD'] : '',
      confirmPassword: config['ADMIN_PASSWORD'] ? config['ADMIN_PASSWORD'] : '',      
      sendgrid: config['SENDGRID_API_KEY'] ? config['SENDGRID_API_KEY'] : '',
      serverName: config['DOMAIN_NAME'] && config['DOMAIN_NAME'] != 'lvh.me' ? config['DOMAIN_NAME'] : '',
      redis: redis,
      errors: [],
      success: false,
    };
  }

  saveEc2Instance() {
    getConfig(this.state.ec2_instance, this.props.loggedIn).then((json) => {
      if(json.state == 'not_validated') {
        this.setState({success: false, errors: ['Incorrect EC2 Instance ID']});
      } else {
        this.setState(Object.assign({success: true, errors: []}, this.loadData(json)));
      }
    });
  }

  saveData() {
    if (this.state.password != this.state.confirmPassword) {
      this.setState({success: false, errors: ['Password and Confirm Password do not match']});
      return;
    }
    if (this.state.sendgrid.trim() == '') {
      this.setState({success: false, errors: ['Please provide the SendGrid API Key we should use to send email.']});
      return;
    }
    if (this.state.serverName.trim() == '') {
      this.setState({success: false, errors: ['Please provide the Server Name we should use to refer to this server.']});
      return;
    }
    const config = this.getConfig(this.state.orig_config);
    saveConfig(config).then((json) => {
      if(json.errors.length > 0) {
        this.setState({success: false, errors: json.errors});
      } else {
        this.setState({success: true, errors: []});
        $('body').css('cursor', 'wait');

        const self = this;
        function checkSystem() {
          setTimeout(() => {
            getConfig('', self.props.loggedIn).then(function(json) {
                if(json.initial !== null) {
                  location.reload(); // App is up; reload page
                } else {
                  checkSystem();
                }
              }).catch(function() {
                checkSystem();
              });
          }, 3000);
        }

        checkSystem();
      }
    });
  }

  getConfig(currentConfig) {
    const config = Object.assign({}, currentConfig);
    delete config.license;
    delete config.state;
    const redshift_db_splits = this.state.redshift_endpoint.split('/');
    const redshift_port_splits = redshift_db_splits[0].split(':');
    const redshift_host = redshift_port_splits[0];
    const warehouse_db = 'fto_warehouse';
    const redshift_port = redshift_port_splits.length > 1 ? redshift_port_splits[1] : '5439';
    const postgres_db_splits = this.state.postgres_endpoint.split('/');
    const postgres_port_splits = postgres_db_splits[0].split(':');
    const reporting_db = 'fto_reporting';
    const airflow_db = 'fto_airflow';
    const postgres_port = postgres_port_splits.length > 1 ? postgres_port_splits[1] : '5432';
    const airflow_url = this.state.postgres_username + ':' + this.state.postgres_password + '@' + this.state.postgres_endpoint + ':' + postgres_port + '/' + airflow_db;
    const reporting_url = this.state.postgres_username + ':' + this.state.postgres_password + '@' + this.state.postgres_endpoint + ':' + postgres_port + '/' + reporting_db;
    const redshift_url = this.state.redshift_username + ':' + this.state.redshift_password + '@' + redshift_host + ':' + redshift_port + '/' + warehouse_db;
    config['AIRFLOW__CORE__SQL_ALCHEMY_CONN'] = 'postgresql+psycopg2://' + airflow_url;
    config['AIRFLOW__CELERY__BROKER_URL'] = 'redis://' + this.state.redis + '/1';
    config['AIRFLOW__CELERY__RESULT_BACKEND'] = 'db+postgresql+psycopg2://' + airflow_url;
    config['AIRFLOW__CORE__REMOTE_BASE_LOG_FOLDER'] = 's3://' + this.state.s3_bucket + '/airflow/logs';
    config['AIRFLOW_CONN_PIPELINE'] = 'postgres://' + redshift_url;
    config['AIRFLOW_CONN_POSTGRES'] = 'postgres://' + reporting_url;
    config['AIRFLOW_DATABASE'] = warehouse_db;
    config['AWS_ACCESS_KEY_ID'] = this.state.aws_key;
    config['AWS_REGION'] = this.state.aws_region;
    config['AWS_SECRET_ACCESS_KEY'] = this.state.aws_secret;
    config['POSTGRES_DB'] = reporting_db;
    config['POSTGRES_HOST'] = this.state.postgres_endpoint;
    config['POSTGRES_PASSWORD'] = this.state.postgres_password;
    config['POSTGRES_PORT'] = postgres_port;
    config['POSTGRES_USERNAME'] = this.state.postgres_username;
    config['REDSHIFT_ADAPTER'] = 'redshift';
    config['REDSHIFT_DB'] = warehouse_db;
    config['REDSHIFT_HOST'] = redshift_host;
    config['REDSHIFT_PASSWORD'] = this.state.redshift_password;
    config['REDSHIFT_PORT'] = redshift_port;
    config['REDSHIFT_USERNAME'] = this.state.redshift_username;
    config['S3_CLIENT'] = this.state.s3_bucket;
    config['S3_ENGINE'] = this.state.s3_bucket;
    config['S3_INTERNAL'] = this.state.s3_bucket;
    config['S3_TEMP'] = this.state.s3_bucket;
    config['ADMIN_NAME'] = this.state.name;
    config['ADMIN_EMAIL'] = this.state.email;
    config['ADMIN_PASSWORD'] = this.state.password;
    config['SENDGRID_API_KEY'] = this.state.sendgrid;
    config['DOMAIN_NAME'] = this.state.serverName;
    config['ec2_instance'] = this.state.ec2_instance;
    return config;
  }

  download() {
    const config = this.getConfig({});
    delete config.ADMIN_NAME;
    delete config.ADMIN_EMAIL;
    delete config.ADMIN_PASSWORD;
    const blob = new Blob([JSON.stringify(config)], {type: 'application/json'});
    const href = window.URL.createObjectURL(blob);
    const link = this.downloadElement;
    link.href = href;
    link.download = 'config.json';
    link.click();
    window.URL.revokeObjectURL(href);
  }

  upload() {
    this.fileElement.click();
  }

  uploadFile(event) {
    const file = event.target.files[0];
    if (file) {
      event.target.value = ''; // Enables reloading the same file
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = (evt) => {
        try {
          const data = JSON.parse(evt.target.result);
          data.state = 'validated';
          this.setState(this.loadData(data));
        } catch(e) {
          FTNotice('Error parsing file', 3000);
        }
      }
      reader.onerror = () => {
        FTNotice('Error reading file', 3000);
      }
    }
  }

  render() { 
    return (
      <div>
        {!this.props.loggedIn && (
          <div>
            <div style={{overflow: 'hidden', maxWidth: 800, margin: 'auto'}}>
              <a href="https://www.ftoptimize.com/" style={{float: 'left'}}><img src="/images/logo.png" target="_blank"  style={{position: 'relative', left: '50%', transform: 'translateX(-50%)', height: '60px'}}/></a>
              <a href="/resources/pdfs/FT%20Optimize%20Marketing%20Optimization%20Manual.pdf" target="_blank" style={{float: 'right', marginTop: 15, color: '#206ba4', fontSize: 20}}>Help</a>
            </div>
            <div style={{paddingTop: '20px', maxWidth: '800px', margin: 'auto'}}>
              <p>Welcome to FT Optimize Automate!  Before we get started, we need to know a little bit more about your environment.</p>
            </div>
          </div>
        )}
        {this.state.state == 'not_validated' && (
          <div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>EC2 Instance</h2>
              <p>Please find your EC2 Instance ID from the <a href="https://console.aws.amazon.com/ec2/v2/home#Instances" target="_blank">Amazon EC2 Management Console</a> and enter it here.  This information helps us to prevent access to unauthorized people.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>EC2 Instance ID</b> (ex. i-05afa3c7cad9c633a):</label>
                <input type="text" className="form-control" value={this.state.ec2_instance} onChange={event => this.setState({ec2_instance: event.target.value})} />
              </div>
            </div>
            {this.state.errors.map((error, i) => {
              return (
                <div className="row" style={{marginTop: '10px', textAlign: 'center', color: 'red'}} key={'error' + i}>{error}</div>
              );
            })}
            <div className="row" style={{maxWidth: '800px', margin: 'auto', marginBottom: '20px'}}>
              <div className="col-md-3" style={{float: 'none', margin: 'auto'}}>
                <button className="form-control btn btn-success mt-25" onClick={() => this.saveEc2Instance()}>Validate EC2 Instance</button>
              </div>
            </div>
          </div>
        )}
        {this.state.state == 'validated' && (
          <div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>Copy Configuration From Another Instance (Optional)</h2>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto', marginBottom: '20px'}}>
              <div className="col-md-3 mt-25" style={{float: 'none', margin: 'auto', width: 'fit-content'}}>
                <button className="form-control btn btn-success" style={{width: 'fit-content'}} onClick={() => this.download()}>Download Configuration</button>
                <button className="form-control btn btn-success" style={{width: 'fit-content', marginLeft: 10}} onClick={() => this.upload()}>Upload Configuration</button>
              </div>
              <a ref={(element) => {this.downloadElement = element}}></a>
              <input style={{display: 'none'}} ref={(element) => {this.fileElement = element}} type="file" onChange={(event) => this.uploadFile(event)} />
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>Data Warehouse (Redshift)
                <div style={{width: '120px', display: 'inline-block'}}>
                  <a href="https://console.aws.amazon.com/redshiftv2/home#create-cluster" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Create Redshift</button>
                  </a>
                </div>
              </h2>
              <p>We use Redshift for the data warehouse.  To create a new Redshift instance, click on Create Redshift.  We recommend a 5 node dc2.large cluster.  Be sure to remember the user name and password and to make sure the security group enables this server access to access the database (if you put it in the same VPC and this server is in the default security group it should work).  Once created, click on Clusters, the cluster you created, Properties. This will provide you the endpoint.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>Redshift Endpoint</b> (ex. myinstance.chwc711qob5d.us-east-1.redshift.amazonaws.com:5439/mydb):</label>
                <input type="text" className="form-control" value={this.state.redshift_endpoint} onChange={event => this.setState({redshift_endpoint: event.target.value})} />
              </div>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>Redshift Username:</b></label>
                <input type="text" className="form-control" value={this.state.redshift_username} onChange={event => this.setState({redshift_username: event.target.value})} />
              </div>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>Redshift Password:</b></label>
                <input type="text" className="form-control" value={this.state.redshift_password} onChange={event => this.setState({redshift_password: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>Reporting Database (PostgreSQL)
                <div style={{width: '140px', display: 'inline-block'}}>
                  <a href="https://console.aws.amazon.com/rds/home?#launch-dbinstance:gdb=false;s3-import=false" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Create PostgreSQL</button>
                  </a>
                </div>
              </h2>
              <p>We use PostgreSQL for the reporting database.  To create a new PostgreSQL instance, click on Create PostgreSQL.  We recommend choosing the latest version of PostgreSQL 11 and then picking burstable classes and a db.t3.medium with at least 100 Gb of disk space.  Be sure to remember the user name and password and to make sure the security group enables this server access to access the database (if you put it in the same VPC and this server is in the default security group it should work).  Once created, click on Databases and the database you created.  This will provide you the endpoint.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>PostgreSQL Endpoint</b> (ex. reporting.ct2ld4yrfxjj.us-east-1.rds.amazonaws.com):</label>
                <input type="text" className="form-control" value={this.state.postgres_endpoint} onChange={event => this.setState({postgres_endpoint: event.target.value})} />
              </div>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>PostgreSQL Username:</b></label>
                <input type="text" className="form-control" value={this.state.postgres_username} onChange={event => this.setState({postgres_username: event.target.value})} />
              </div>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>PostgreSQL Password:</b></label>
                <input type="text" className="form-control" value={this.state.postgres_password} onChange={event => this.setState({postgres_password: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>S3 Bucket
                <div style={{width: '120px', display: 'inline-block'}}>
                  <a href="https://s3.console.aws.amazon.com/s3/home" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Create S3</button>
                  </a>
                </div>
              </h2>
              <p>We need an S3 Bucket that we can use to import data into Redshift.  In order to create an S3 bucket, click on Create S3 and then click on Create bucket.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>S3 Bucket:</b></label>
                <input type="text" className="form-control" value={this.state.s3_bucket} onChange={event => this.setState({s3_bucket: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>AWS Credentials
                <div style={{width: '120px', display: 'inline-block'}}>
                  <a href="https://console.aws.amazon.com/iam/home#/users$new?step=details" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Create User</button>
                  </a>
                </div>
              </h2>
              <p>In order to interact with Amazon Web Services (AWS), we'll need credentials we can use.  To create an AWS User, click on Create User.  Be sure to give programmatic access and to give the user the ability to write to the S3 Bucket (either AmazonS3FullAccess or see <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_examples_s3_rw-bucket.html">here</a> for instructions on how to create a more specific policy).</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>AWS Access key ID:</b></label>
                <input type="text" className="form-control" value={this.state.aws_key} onChange={event => this.setState({aws_key: event.target.value})} />
              </div>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>AWS Secret access key:</b></label>
                <input type="password" className="form-control" value={this.state.aws_secret} onChange={event => this.setState({aws_secret: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>Redis (Optional)
                <div style={{width: '150px', display: 'inline-block'}}>
                  <a href="https://console.aws.amazon.com/elasticache/home?#create-cluster:ct=redis:" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Create Redis Instance</button>
                  </a>
                </div>
              </h2>
              <p>We use Redis to coordinate across servers.  If you will only have one server, you can leave this as redis:6379 (which will use a local instance). If you will have multiple servers, enter the Redis endpoint and port (ex., redis.efypq5.0001.use1.cache.amazonaws.com:6379).  Note: we suggest a t2.micro instance.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>Redis server</b></label>
                <input type="text" className="form-control" value={this.state.redis} onChange={event => this.setState({redis: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>SendGrid API Key
                <div style={{width: '150px', display: 'inline-block'}}>
                  <a href="https://signup.sendgrid.com/" target="_blank">
                    <button className="form-control btn btn-success mt-25" style={{marginTop: '0px', marginLeft: '10px'}}>Sign Up for SendGrid</button>
                  </a>
                </div>
              </h2>
              <p>We use SendGrid to send email.  To create a free SendGrid account, click on Sign Up for SendGrid.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>SendGrid API Key</b></label>
                <input type="password" className="form-control" value={this.state.sendgrid} onChange={event => this.setState({sendgrid: event.target.value})} />
              </div>
            </div>
            <div style={{maxWidth: '800px', margin: 'auto'}}>
              <h2>Server Name
              </h2>
              <p>Please provide the server name you will use for this server.  We will use this name when referring to the server.</p>
            </div>
            <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-md-10">
                <label><b>Server Name</b></label>
                <input type="text" className="form-control" value={this.state.serverName} onChange={event => this.setState({serverName: event.target.value})} />
              </div>
            </div>
            {!this.props.loggedIn && (
              <div>
                <div style={{maxWidth: '800px', margin: 'auto'}}>
                  <h2>FT Optimize Automate Credentials
                  </h2>
                  <p>Please provide information for the initial user in FT Optimize Automate.  You will use this to login to the application.</p>
                </div>
                <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
                  <div className="col-md-10">
                    <label><b>Name</b></label>
                    <input type="text" className="form-control" value={this.state.name} onChange={event => this.setState({name: event.target.value})} />
                  </div>
                </div>
                <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
                  <div className="col-md-10">
                    <label><b>Email address</b></label>
                    <input type="text" className="form-control" value={this.state.email} onChange={event => this.setState({email: event.target.value})} />
                  </div>
                </div>
                <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
                  <div className="col-md-10">
                    <label><b>Password</b></label>
                    <input type="password" className="form-control" value={this.state.password} onChange={event => this.setState({password: event.target.value})} />
                  </div>
                </div>
                <div className="row" style={{maxWidth: '800px', margin: 'auto'}}>
                  <div className="col-md-10">
                    <label><b>Confirm Password</b></label>
                    <input type="password" className="form-control" value={this.state.confirmPassword} onChange={event => this.setState({confirmPassword: event.target.value})} />
                  </div>
                </div>
              </div>
            )}
            {this.state.success && (
                <div className="row" style={{marginTop: '10px', textAlign: 'center', color: 'green'}}>Successfully configured application!  Restarting...</div>
            )}
            {this.state.errors.map((error, i) => {
              return (
                <div className="row" style={{marginTop: '10px', textAlign: 'center', color: 'red'}} key={'error' + i}>{error}</div>
              );
            })}
            <div className="row" style={{maxWidth: '800px', margin: 'auto', marginBottom: '20px'}}>
              <div className="col-md-3" style={{float: 'none', margin: 'auto'}}>
                <button className="form-control btn btn-success mt-25" onClick={() => this.saveData()}>Save Configuration</button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    loggedIn: state.users.user ? true : false,
  };
}

export default connect(mapStateToProps)(Interface);
