import React from 'react'
import { connect } from 'react-redux'
import Section from '../../widgets/Section'
import Schedule from '../../widgets/Schedule'
import RadioButtons from '../../widgets/RadioButtons'
import RadioButton from '../../widgets/RadioButton'
import Stack from '../../widgets/Stack'
import i18next from 'i18next'

const BiddingSchedule = ({schedule, onUpdate, status, timeZone}) => {
  const disabled= status == 'published'
  return (
    <Section
      name="bidding.schedule"
      description="bidding.scheduleDescription">
      <RadioButtons
        value={schedule==null ? 'now' : 'scheduled'}
        onChange={e => onUpdate({schedule: e.target.value == 'now' ? null : '0 0 * * 0'})}
        groupText="bidding.run">
        <RadioButton disabled={disabled} value="now" labelText={i18next.t("bidding.now")} />
        <RadioButton disabled={disabled} value="scheduled" labelText={i18next.t("bidding.onASchedule")} />
        {schedule != null && (
          <Stack sx={{paddingLeft: '39px'}}>
            <Schedule
              disabled={disabled}
              value={schedule}
              onChange={schedule => onUpdate({schedule})}
              timeZone={timeZone}
            />
          </Stack>
        )}
      </RadioButtons>
    </Section>
  )
}

const mapStateToProps = (state) => ({
  timeZone: state.users.user ? state.users.user.client.time_zone : 'America/Los_Angeles',
})

export default connect(mapStateToProps)(BiddingSchedule)
