import { Box, Typography } from '@mui/material'
import * as React from 'react'
import StepDescriptionPopover from './StepDescriptionPopover'
import Title from '../../widgets/Title'
import Description from '../../widgets/Description'
import Stack from '../../widgets/Stack'

const NotebookInterface = (props) => {
  const prettyPrint = (text) => {
    return text.replace(/_/g, " ");
  }

  return (
    <Box
      // component="div"
      width="60vw"
      maxWidth="60vw"
      id={"step" + props.stepId}
    >
      <Stack spacing={1}>

        <Title title={props.stepName} variant="h4" />

        {props.step.step_description && (
          <Description sx={{ wordWrap: 'break-word', width: '50vw' }} fontSize={18} description={props.step.step_description} />
        )}

      </Stack>
      <Box sx={{
        display: "grid",
        gap: 5,
        mr: 5,
        mb: 5,
        mt: 3,
        gridAutoFlow: "column",
        gridTemplatecolumns: 'repeat(8, 1fr)',
        justifyContent: "flex-start"
      }}>
        {/* left column holds run button, step type and info icon */}
        <Box display="flex" flexDirection="column" alignSelf="start" sx={{ gridColumn: '1fr' }}>
          <Box display="flex" alignSelf="start" margin="auto" sx={{ mb: 3 }}>
            {!["sql_dashboard"].includes(props.type) && (
              props.renderRunButton
            )}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" >
            <Box marginRight={1}>
              <Typography style={{ textTransform: "capitalize" }}>
                {prettyPrint(props.type)}
              </Typography>
            </Box>
            <Box marginLeft={1}>
              <StepDescriptionPopover step_description={props.step.step_description} />
            </Box>
          </Box>
        </Box>
        {/* right column holds SQL body and result */}
        <Box sx={{
          gridColumn: '7fr',
          borderRadius: 3,
          alignSelf: "start",
          width: '45vw',
          maxWidth: '45vw',
          padding: 1,
          overflow: 'auto'
        }} >
          <Box sx={{
            display: "block",
            width: '100%',
            height: 'auto',
          }}>
            {props.renderResult}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotebookInterface