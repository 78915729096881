import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Grid, Divider } from '@mui/material'
import Page from '../widgets/Page'
import Button from '../widgets/Button'
import Tabs from '../widgets/Tabs'
import Tab from '../widgets/Tab'
import CampaignSelect from './CampaignSelect'
import LabelSelect from './LabelSelect'
import Sql from './Sql'
import SqlTemplateSelect from './SqlTemplateSelect'
import PreviewResults from './PreviewResults'
import AbTestSettings from './AbTestSettings'
import SqlTemplateSwitch from './SqlTemplateSwitch'
import i18next from 'i18next'
import { guid } from '../../utilities/guids'
import Title from '../widgets/Title'
import Description from '../widgets/Description'
import Fade from '../widgets/Fade'


const EditABTestLayout = (props) => {
  const [selectionMethod, setSelectionMethod] = useState(props.id == 'new' ? 'campaign' : 'sql')
  const [expPreviewOpen, setExpPreviewOpen] = useState(false)
  const [controlPreviewOpen, setControlPreviewOpen] = useState(false)
  const [activePreviewTab, setActivePreviewTab] = useState("experiment-preview")
  const [showSqlTemplates, setShowSqlTemplates] = useState(false)
  const [experimentCampaigns, setExperimentCampaigns] = useState([])
  const [controlCampaigns, setControlCampaigns] = useState([])
  const [experimentLabels, setExperimentLabels] = useState([])
  const [controlLabels, setControlLabels] = useState([])
  const [previewMode, setPreviewMode] = useState()

  const getSql = (campaigns, labels) => {
    if (selectionMethod == 'campaign') {
      campaigns = campaigns.map(campaign => "'" + campaign.replaceAll("'", "''") + "'")
      const campaign_where = campaigns.length > 0 ? `campaign_name IN (${campaigns})` : 'FALSE'
      return '' +
        `SELECT ad_group_id, criteria_id\n` +
        `FROM reports.structure\n` +
        `WHERE internal_client_id=${props.internal_client_id} AND ${campaign_where}`
    } else {
      labels = labels.map(label => label.replaceAll('"', '\"').replaceAll("'", "''"))
      const labelString = labels.length == 0 ? '[]' : '["' + labels.join('","') + '"]'
      return '' +
        `SELECT ad_group_id, criteria_id\n` +
        `FROM reports.structure\n` +
        `CROSS JOIN (SELECT '${labelString}' AS ablabels)\n` +
        `JOIN sequence ON i < JSON_ARRAY_LENGTH(ablabels) AND (\n` +
        `  campaign_labels LIKE '%"' || JSON_EXTRACT_ARRAY_ELEMENT_TEXT(ablabels, i) || '"%' OR\n` +
        `  ad_group_labels LIKE '%"' || JSON_EXTRACT_ARRAY_ELEMENT_TEXT(ablabels, i) || '"%' OR\n` +
        `  labels LIKE '%"' || JSON_EXTRACT_ARRAY_ELEMENT_TEXT(ablabels, i) || '"%'\n` +
        `)\n` +
        `WHERE internal_client_id=${props.internal_client_id}`
    }
  }

  const getExperimentSql = () => {
    return getSql(experimentCampaigns, experimentLabels)
  }

  const getControlSql = () => {
    return getSql(controlCampaigns, controlLabels)
  }

  React.useEffect(() => {
    if (selectionMethod == 'campaign' || selectionMethod == 'label') {
      const newState = { experiment_sql: getExperimentSql() }
      if (props.controlSql != null) {
        newState['control_sql'] = getControlSql()
      }
      props.onUpdate(newState)
    }
  }, [selectionMethod, experimentCampaigns, experimentLabels, controlCampaigns, controlLabels])

  const handleTabChange = (e, newValue) => {
    setActivePreviewTab(newValue)
  }

  const preview = () => {
    if (expPreviewOpen || controlPreviewOpen) {
      setPreviewMode(guid())
    } else {
      setExpPreviewOpen(true)
      setControlPreviewOpen(true)
      setPreviewMode(guid())
    }
  }

  const closePreview = () => {
    if (expPreviewOpen || controlPreviewOpen) {
      setExpPreviewOpen(false)
      setControlPreviewOpen(false)
    }
  }

  return (
    <Page>
      <Title title={i18next.t('abtest.edit.abTesting')} variant="h1" />
      <Box sx={{ mt: 2, mb: 2, display: "flex", flexWrap: "wrap" }}>
        <Description description={i18next.t('abtest.edit.pageInstructions')} />
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={5}>
          <AbTestSettings
            title={props.title}
            description={props.description}
            excludeFromBidding={props.excludeFromBidding}
            startDate={props.startDate}
            endDate={props.endDate}
            hasControl={props.controlSql != null}
            setHasControl={() => props.onUpdate({ control_sql: props.controlSql == null ? '' : null })}
            selectionMethod={selectionMethod}
            setSelectionMethod={setSelectionMethod}
            google_ab_test={props.google_ab_test}
            onUpdate={props.onUpdate}
            save={props.save}
            delete={props.delete}
            cancel={props.cancel}
            start_date={"start_date"}
            end_date={"end_date"}
            id={props.id} />
        </Grid>
        <Grid item lg={7}>
          {selectionMethod == "sql" ?
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 5 }}>
              <SqlTemplateSwitch setShowSqlTemplates={setShowSqlTemplates} showSqlTemplates={showSqlTemplates} />
              {showSqlTemplates ? <SqlTemplateSelect handleSelectTemplate={props.handleSelectTemplate} sqlTemplates={props.sqlTemplates} /> : null}
            
            </Box>
            : null}
          {selectionMethod == "campaign" ?
            <CampaignSelect
              name={i18next.t('abtest.edit.experimentCampaigns')}
              allCampaigns={props.allCampaigns}
              campaign_ids={experimentCampaigns}
              onUpdate={(campaigns, campaign_ids) => {setExperimentCampaigns(campaigns); props.onUpdate({experiment_campaign: campaign_ids})}}
            /> : null}
          {selectionMethod == "label" ?
            <LabelSelect
              labelSelectName={i18next.t('abtest.edit.experimentLabels')}
              allLabels={props.allLabels}
              labels={experimentLabels}
              onUpdate={labels => setExperimentLabels(labels)}
            /> : null}
          {selectionMethod == "sql" ?
            <Sql name="abtest.edit.experimentSql" sql={props.experimentSql} onUpdate={sql => props.onUpdate({ experiment_sql: sql })} />
            : null}
          {props.controlSql != null &&
            <div>
              {selectionMethod == "campaign" ?
                <CampaignSelect
                  name={i18next.t('abtest.edit.controlCampaigns')}
                  allCampaigns={props.allCampaigns}
                  campaign_ids={controlCampaigns}
                  onUpdate={(campaigns, campaign_ids) => {setControlCampaigns(campaigns); props.onUpdate({control_campaign: campaign_ids}); }}
                /> : null}
              {selectionMethod == "label" ?
                <LabelSelect
                  labelSelectName={i18next.t('abtest.edit.controlLabels')}
                  allLabels={props.allLabels}
                  labels={controlLabels}
                  onUpdate={labels => setControlLabels(labels)}
                /> : null}
              {selectionMethod == "sql" ?
                <Sql name="abtest.edit.controlSql" sql={props.controlSql} onUpdate={sql => props.onUpdate({ control_sql: sql })} />
                : null
              }
            </div>}
          <Divider sx={{ marginTop: 5, marginBottom: 5 }} />
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: '100%',
            marginBottom: 5
          }}>
            <Box sx={{ display: "flex" }}>
              <Tabs
                value={activePreviewTab}
                onChange={handleTabChange}
              >
                <Tab value='experiment-preview' label={i18next.t('abtest.edit.experimentPreview')} />
                <Tab disabled={props.controlSql == null} value='control-preview' label={i18next.t('abtest.edit.controlPreview')} />
              </Tabs>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginRight: 2 }}>
                <Button
                  buttonText={expPreviewOpen || controlPreviewOpen ? 'abtest.edit.reload' : 'abtest.edit.load'}
                  color="primary"
                  onClick={preview} />
              </Box>
              <Box>
                <Button
                  buttonText="abtest.edit.close"
                  disabled={!expPreviewOpen && !controlPreviewOpen}
                  color="primary"
                  onClick={closePreview} />
              </Box>
            </Box>
          </Box>
          {activePreviewTab == 'experiment-preview' &&
            <Fade appear in={expPreviewOpen}>
              <Box sx={{ visibility: expPreviewOpen ? true : false }}>
                <PreviewResults
                  sql={props.experimentSql}
                  previewOpen={expPreviewOpen}
                  setPreviewOpen={setExpPreviewOpen}
                  previewMode={previewMode} />
              </Box>
            </Fade>
          }
          {activePreviewTab == 'control-preview' &&
            <Fade appear in={expPreviewOpen}>
              <Box sx={{ visibility: expPreviewOpen ? true : false }}>
                <PreviewResults
                  sql={props.controlSql}
                  previewOpen={controlPreviewOpen}
                  setPreviewOpen={setControlPreviewOpen}
                  previewMode={previewMode} />
              </Box>
            </Fade>
          }
        </Grid>
      </Grid>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(EditABTestLayout)
