import { Component } from "react"
import { connect } from "react-redux"
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Table from "../widgets/Table"
import Confirm from '../Confirm'
import Accounts from "./Accounts"
import FTNotice from '../FTNotice'
import { getIntegrations, deleteCredential, enableIntegration, disableIntegration } from "../../api/integrations"
import { getAccounts } from "../../api/client_accounts"
import { getImage } from "../../utilities/clients"
import i18next from 'i18next'
import Active from '../../assets/images/active.png'
import Inactive from '../../assets/images/inactive.png'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountMap: {},
      authorizing: null,
      confirmAll: false,
      disabling: null,
      integrations: null
    }
  }

  componentDidMount() {
    this.getIntegrations()
  }

  getIntegrations() {
    getIntegrations(this.props.internal_client_id).then((integrations) => {
      this.setState({ integrations: integrations })
    });
    getAccounts(this.props.internal_client_id).then(response => {
      const accountMap = {}
      response.forEach(account => {
        let accounts = accountMap[account.engine]
        if (!accounts) {
          accounts = []
          accountMap[account.engine] = accounts
        }
        accounts.push(account)
      })
      this.setState({accountMap: accountMap})
    })
  }

  renderStatus(row) {
    let value;
    if (row.help) {
      value = "UNKNOWN"
    } else if (row.credentials.length) {
      value = "AUTHORIZED"
    } else {
      value = "NOT AUTHORIZED"
    }
    let color;
    if (value == "AUTHORIZED") {
      color = "green"
    } else {
      color = "gray"
    }
    return (
      <b>
        <font color={color}>{value}</font>
      </b>
    )
  }

  configure(integration) {
    location.href = '/#/ad_engines/' + integration
  }

  authorize(integration) {
    if (this.props.is_admin) {
      this.setState({authorizing: integration})
    } else {
      location.href = integration.url + this.props.internal_client_id
    }
  }

  authorizeAll() {
    this.setState({confirmAll: true})
  }

  confirmAuthorizeAll(confirm) {
    if (confirm) {
      location.href = this.state.authorizing.url + 'None'
    } else {
      this.setState({confirmAll: false})
    }
  }

  authorizeClient() {
    location.href = this.state.authorizing.url + this.props.internal_client_id
  }

  addAccount(row, instructions = null) {
    if (row.authorization_help) {
      instructions = (instructions ? (instructions + ' ') : '') + row.authorization_help
    }
    location.href = '#/clients/' + this.props.internal_client_id + '/accounts/new?integration=' + row.id + (instructions ? '&instructions=' + instructions : '')
  }

  unauthorize() {
    deleteCredential(this.state.authorizing.authorization_id).then((response) => {
      if (response.success) {
        this.setState({authorizing: null}, () => this.getIntegrations())
      }
    });
  }
  
  activateEnabled(row) {
    if (!row.configured) {
      this.configure(row.application_id ? row.application_id : row.id)
    } else if ((!row.authorized || (row.globally_authorized && row.id == 'slack')) && !row.authorization_help) {
      this.authorize(row)
    } else if (row.account_fields) {
      if (row.accounts.length == 0) {
      this.addAccount(row, i18next.t('integrations.pleaseAddAccount'))
      } else if (row.accounts.filter(account => account.status).length == 0) {
        location.href = "/#/clients/" + this.props.internal_client_id + "/accounts/" + row.accounts[0].id + "/edit?instructions=" + i18next.t('integrations.pleaseEnableAccount')
      }
    }
  }
  
  activate(row) {
    if (row.disabled) {
      enableIntegration(this.props.internal_client_id, row.id).then(result => {
        if (result && result.success) {
          const integration = Object.assign({}, this.state.integrations[row.id], {disabled: false})
          const integrations = Object.assign({}, this.state.integrations)
          integrations[row.id] = integration
          this.setState({integrations: integrations})
          this.activateEnabled(row)
        } else {
          FTNotice('integrations.couldNotEnable', 10000)
        }
      })
    } else {
      this.activateEnabled(row)
    }
  }
  
  disable(row) {
    this.setState({disabling: row})
  }
  
  confirmDisable(confirmed) {
    if (confirmed) {
      const rowId = this.state.disabling.id
      disableIntegration(this.props.internal_client_id, rowId).then(result => {
        if (result && result.success) {
          const integration = Object.assign({}, this.state.integrations[rowId], {disabled: true})
          const integrations = Object.assign({}, this.state.integrations)
          integrations[rowId] = integration
          this.setState({integrations: integrations})
        } else {
          FTNotice('integrations.couldNotDisable', 10000)
        }
      })
    }
    this.setState({disabling: null})
  }

  getColumns() {
    const columns = [{
      header: i18next.t('integrations.integration'),
      cellRenderer: (_, row) => {
        return (
          <div style={{textAlign: 'center'}}>
            <div><img src={"/resources/images/workflow_icons/" + getImage(row.id)} height="40" /></div>
            <div>{row.name}</div>
          </div>
        )
      },
      width: 160,
    }, {
      header: i18next.t('integrations.status'),
      cellRenderer: (_, row) => 
        !row.disabled &&
        row.configured &&
        ((row.authorized || row.service_account) && (!row.globally_authorized || row.id != 'slack')) &&
        (row.accounts.filter(account => account.status).length || !row.account_fields) ?
        (<img src={Active} onClick={() => this.disable(row)} style={{cursor: 'pointer', height: 20}} title={i18next.t('integrations.clickToDisable')} />) :
        (<img src={Inactive} onClick={() => this.activate(row)} style={{cursor: 'pointer', height: 20}} title={i18next.t('integrations.clickToActivate')} />),
      width: 160,
    }];
    if (this.props.is_admin) {
      columns.push({
        header: i18next.t('integrations.configure'),
        cellRenderer: (_, row) => {
          return (
            <div style={{textAlign: 'left', width: '130px'}}>
              {row.fields && (
                <button
                  disabled={row.disabled}
                  className={"btn " + (row.disabled ? "btn-light" : "btn-primary")}
                  style={{marginLeft: 25}}
                  onClick={() => this.configure(row.application_id ? row.application_id : row.id)}>{i18next.t('integrations.configure')}</button>
              )}
            </div>
          )
        },
        width: 150,
      });
    }
    columns.push({
      header: i18next.t('integrations.authorize'),
      cellRenderer: (_, row) => {
        return (
          <div style={{textAlign: 'left', width: '130px'}}>
            {row.configured && row.url && (
              <button
                disabled={row.disabled}
                className={"btn " + (row.disabled ? "btn-light" : "btn-primary")}
                style={{marginLeft: 25}}
                onClick={() => this.authorize(row)}>{i18next.t('integrations.authorize')}</button>
            )}
          </div>
        )
      },
      width: 150,
    },
    {
      id: 'accounts',
      header: i18next.t('integrations.accounts'),
      cellRenderer: (_, row) => {
        return (
          <div style={{textAlign: 'left', width: '905px'}}>
            {row.configured && (row.authorized || row.authorization_help) && row.account_fields && (
              <i
                className="fa fa-plus"
                style={{color: row.disabled ? 'lightgrey' : '#00cc66', cursor: row.disabled ? 'not-allowed' : 'pointer', marginLeft: 30}}
                title={i18next.t('integrations.addAccount')}
                onClick={() => !row.disabled && this.addAccount(row)}></i>
            )}
            {row.configured && (row.authorized || row.authorization_help) && this.state.accountMap[row.id] != null && (
              <Accounts internal_client_id={this.props.internal_client_id} accounts={this.state.accountMap[row.id]} disabled={row.disabled}/>
            )}
          </div>
        )
      },
      width: 905,
    });
    return columns
  }

  generateTableData() {
    let result = []
    Object.keys(this.state.integrations)
      .sort()
      .forEach((k) => {
        result.push(this.state.integrations[k]);
      })
    return result
  }

  renderConfirmAuthorizeAll() {
    return(
      <Confirm opened={this.state.confirmAll == true} title={i18next.t('integrations.authorizeAll')} question={i18next.t('integrations.confirmAuthorizeAll')}
         action={confirmed => {this.confirmAuthorizeAll(confirmed)}} button={i18next.t('integrations.authorizeAll')} />
    )
  }

  renderConfirmDisable() {
    return(
      <Confirm opened={this.state.disabling != null} title={i18next.t('integrations.disable')} question={i18next.t('integrations.confirmDisable', {integration: this.state.disabling ? this.state.disabling.name : null})}
         action={confirmed => {this.confirmDisable(confirmed)}} button={i18next.t('integrations.disable')} />
    )
  }

  renderAuthorizeDialog() {
    return (
      <div>
        <Dialog
          open={this.state.authorizing != null}
          onClose={() => this.setState({authorizing: null})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{i18next.t('integrations.authorizeIntegration', {integration: this.state.authorizing ? this.state.authorizing.name : ''})}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.authorizing && !this.state.authorizing.authorization_help && (
                <>
                  {!this.state.authorizing.authorized && (
                    i18next.t('integrations.notAuthorized')
                  )}
                  {this.state.authorizing.globally_authorized && (
                    i18next.t('integrations.authorizedForAll')
                  )}
                  {this.state.authorizing.authorized && !this.state.authorizing.globally_authorized && (
                    i18next.t('integrations.authorizedForClient')
                  )}
                </>
              )}
              {this.state.authorizing && this.state.authorizing.authorization_help && (
                <span dangerouslySetInnerHTML={{__html: this.state.authorizing.authorization_help}}/>
              )}
            </DialogContentText>
          </DialogContent>
          <div className="text-center" style={{marginBottom: 20}}>
            {this.state.authorizing && !this.state.authorizing.authorization_help && (
              <>
                {this.state.authorizing && (!this.state.authorizing.authorized || this.state.authorizing.globally_authorized) && (
                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    <button className="btn btn-primary" onClick={() => this.authorizeAll()}>{i18next.t('integrations.authorizeAll')}</button>
                    <button className="btn btn-primary" onClick={() => this.authorizeClient()} style={{marginLeft: 10}}>{i18next.t('integrations.authorize')}</button>
                    <button className="btn btn-primary" onClick={() => this.setState({authorizing: null})} style={{marginLeft: 10}}>{i18next.t('integrations.cancel')}</button>
                  </div>
                )}
                {this.state.authorizing && this.state.authorizing.authorized && !this.state.authorizing.globally_authorized && (
                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    <button className="btn btn-primary" onClick={() => this.authorizeClient()}>{i18next.t('integrations.authorize')}</button>
                    <button className="btn btn-primary" onClick={() => this.unauthorize()} style={{marginLeft: 10}}>{i18next.t('integrations.unauthorize')}</button>
                    <button className="btn btn-primary" onClick={() => this.setState({authorizing: null})} style={{marginLeft: 10}}>{i18next.t('integrations.cancel')}</button>
                  </div>
                )}
              </>
            )}
            {this.state.authorizing && this.state.authorizing.authorization_help && (
              <button className="btn btn-primary" onClick={() => this.setState({authorizing: null})}>{i18next.t('integrations.ok')}</button>
            )}
          </div>
        </Dialog>
      </div>
    )
  }

  render() {
    return (
      <div className="site-canvas" style={{ paddingTop: 20 }}>
        {this.state.integrations && <span className="js_complete"></span>}
        {this.renderConfirmAuthorizeAll()}
        {this.renderConfirmDisable()}
        {this.renderAuthorizeDialog()}
        {this.state.integrations && (
          <Table
            hideExport
            dataset={this.generateTableData()}
            columns={this.getColumns()}
            hidePagination={true}
            rowHeight={70}
            autoHeight={true}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    is_admin: state.users.user ? state.users.user.role == "Admin" : false,
  };
};

export default connect(mapStateToProps)(Interface)
