import { Box } from '@mui/material'
import Switch from '../../widgets/Switch'
import i18next from 'i18next'

const BiddingViewSwitch = (props) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignContent={"center"}>
      <Switch
        color="primary"
        onChange={() => props.onChange()}
        label={i18next.t('biddingRun.advanced')}
      />
    </Box>
  )
}

export default BiddingViewSwitch