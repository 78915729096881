import { Component } from 'react'
import Confirm from './Confirm'
import i18next from 'i18next'

/*
  Used to display a delete button on a form.  Properties:
  objectType - The type of object being edited (ex., 'Link')
  objectName - The name of the object being edited (ex. 'FTO Web Site')
  deleteObject - The function to call on delete (or null if delete is not allowed or newObject)
  canDelete - Whether this object can be deleted (default=true if not new)
  style (optional) - Style information for the element.
*/
export default class DeleteButton extends Component {
  static defaultProps = {
    canDelete: true,
    style: {marginLeft: 10},
  }

  constructor(props) {
    super(props)
    this.state = {
       deleteModal: false,
    }
    this.deleteObject = this.deleteObject.bind(this)
  }

  confirmDelete(event) {
    event.preventDefault()
    this.setState({deleteModal: true})
  }

  deleteObject(action) {
    this.setState({deleteModal: false})
    if (action) {
      this.props.deleteObject()
    }
  }

  render() {
    const style = Object.assign({}, this.props.style)
    style.display = this.props.canDelete ? null : 'none'
    return (
      <button name="delete" className="btn btn-danger" onClick={event => {this.confirmDelete(event)}}
        style={style}>
        <Confirm
          opened={this.state.deleteModal}
          title={i18next.t('delete.delete') + ' ' + i18next.t(this.props.objectType)}
          question={i18next.t('delete.confirmDelete', {object: this.props.objectName})}
          action={this.deleteObject}
          button={i18next.t('delete.delete')} />
        {i18next.t('delete.delete')} {i18next.t(this.props.objectType)}
      </button>
    )
  }
}
