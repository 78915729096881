import React, { useMemo, useState, useEffect } from 'react'
import i18next from 'i18next'
import LabelSelect from './LabelSelect'
import Stack from '../../widgets/Stack'
import Section from '../../widgets/Section'
import Switch from '../../widgets/Switch'
import PullDown from '../../widgets/PullDown'
import Button from '../../widgets/Button'
import Checkbox from '../../widgets/Checkbox'
import { Paper, Box, Divider, Typography } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import FTNotice from '../../FTNotice'
import { formatInteger, convertName } from '../../../utilities/formatting'

const CampaignInputs = (props) => {
  const MAX = 4000
  const [filteredCampaigns, setFilteredCampaigns] = useState(props.allCampaigns)
  const debounceFilteredCampaigns = debounce(newVal => {
    if (newVal && !isEqual(newVal, filteredCampaigns)) {
      setFilteredCampaigns(newVal)
    }
  }, 1000)
  const updateFilteredCampaigns = filtered => debounceFilteredCampaigns(filtered)

  const [filteredAdGroups, setFilteredAdGroups] = useState(props.allAdGroups)  
  const debounceFilteredAdGroups = debounce(newVal => {
    if (newVal) {
      const adGroups = newVal.filter(o => o.type == 'adGroup')
      if (!isEqual(adGroups, filteredAdGroups)) {
        setFilteredAdGroups(adGroups)
      }
    }
  }, 1000)
  const updateFilteredAdGroups = filtered => debounceFilteredAdGroups(filtered)
  
  const [campaignFilter, setCampaignFilter] = useState('')
  const [adGroupFilter, setAdGroupFilter] = useState('')

  const filterCampaigns = createFilterOptions()
  const filterAdGroups = createFilterOptions({ stringify: option => option.matchTerms.join("//") })

  const toOptions = campaign => {
    const { campaign_id, campaign_name, ad_groups, engine } = campaign
    const filtererAdGroups = ad_groups.filter(ag => props.engines.includes(engine) && ag.types.filter(t => props.entityTypes.includes(t)).length > 0)
    const children = filtererAdGroups.flatMap(adGroup => ({
      type: 'adGroup',
      id: adGroup.ad_group_id,
      name: adGroup.ad_group_name,
      campaignName: campaign_name,
      depth: 1,
      parentId: adGroup.campaign_id,
      matchTerms: [adGroup.ad_group_name],
    }))
    const option = {
      type: 'campaign',
      id: campaign_id,
      name: campaign_name,
      depth: 0,
      matchTerms: children.map(adGroup => adGroup.name),
    }
    return [option].concat(children)
  }

  const allCampaigns = props.allCampaigns.filter(c => props.engines.includes(c.engine) && c.types.filter(t => props.entityTypes.includes(t)).length > 0)
  const allAdGroups = useMemo(() => (
    props.allAdGroups
      .filter(c => props.campaign_ids.length == 0 || props.campaign_ids.includes(c.campaign_id))
      .filter(c => props.engines.includes(c.engine) && c.types.filter(t => props.entityTypes.includes(t)).length > 0)
      .flatMap(c => toOptions(c))
  ), [props.allAdGroups, props.campaign_ids, props.engines, props.entityTypes])

  const updateCampaigns = campaign_ids => {
    if (campaign_ids != null && campaign_ids.length > MAX) {
      FTNotice(i18next.t('biddingRun.mustBeFewerCampaigns'), {max: formatInteger(MAX)})
      return false
    } else {
      props.onUpdate({campaign_ids, ad_group_ids: []})
      return true
    }
  }

  const updateAdGroups = ad_group_ids => {
    if (ad_group_ids != null && ad_group_ids.length > MAX) {
      FTNotice(i18next.t('biddingRun.mustBeFewerAdGroups', {max: formatInteger(MAX)}))
      return false
    } else {
      props.onUpdate({ad_group_ids})
      return true
    }
  }

  const selectAll = type => {
    const values = type == 'campaigns'
      ? props.campaign_ids.concat(filteredCampaigns.filter(c => !props.campaign_ids.includes(c)).map(c => c.campaign_id))
      : props.ad_group_ids.concat(filteredAdGroups.filter(ag => !props.ad_group_ids.includes(ag)).map(ag => ag.id))
    const valid = type == 'campaigns' ? updateCampaigns(values) : updateAdGroups(values)
    if (valid) {
      const updateFilter = type == 'campaigns' ? setCampaignFilter : setAdGroupFilter
      updateFilter('')
    }
  }

  const getPaper = (type, paperProps) => {
    const { children, ...restPaperProps } = paperProps
    return (
      <Paper {...restPaperProps}>
        <Box
          onMouseDown={e => e.preventDefault()} // prevent blur
          pl={1.5}
          py={0.5}
        >
          <Button buttonText='biddingRun.selectAll' onClick={() => selectAll(type)}/>
        </Box>
        <Divider />
        {children}
      </Paper>
    )
  }
  
  const setEngines = (engine, included) => {
    const other = props.engines.filter(e => e != engine)
    if (included) {
      props.onUpdate({engines: other.concat([engine])})
    } else {
      props.onUpdate({engines: other})
    }
    props.onUpdate({campaign_ids: [], ad_group_ids: []})
  }
  
  const setTypes = (type, included) => {
    const other = props.entityTypes.filter(t => t != type)
    if (included) {
      props.onUpdate({entity_types: other.concat([type])})
    } else {
      props.onUpdate({entity_types: other})
    }
    props.onUpdate({campaign_ids: [], ad_group_ids: []})
  }

  const campaignPulldown = (
    <PullDown
      disabled={props.status == 'published'}
      labelText="biddingRun.campaignSelectDesc"
      value={allCampaigns.filter(c => props.campaign_ids.includes(c.campaign_id))}
      filterOptions={(options, params) => {
        const filtered = filterCampaigns(options, params)
        updateFilteredCampaigns(filtered)
        return filtered
      }}
      options={allCampaigns}
      optionFunction={option => option.campaign_name}
      optionTagFunction={option => option.campaign_name}
      noOptionsText={i18next.t('biddingRun.noCampaignsMatchYourQuery')}
      onChange={(_, value) => updateCampaigns(value.map(c => c.campaign_id))}
      PaperComponent={paperProps => getPaper('campaigns', paperProps)}
      inputValue={campaignFilter}
      onInputChange={(_, value, reason) => reason !== 'reset' && setCampaignFilter(value)}
      onBlur={() => setCampaignFilter('')}
    />
  )
  const adGroupPulldown = (
    <PullDown
      disabled={props.status == 'published'}
      labelText="biddingRun.adGroupSelectDesc"
      value={allAdGroups.filter(o => o.type == 'adGroup' && props.ad_group_ids.includes(o.id))}
      filterOptions={(options, params) => {
        const filtered = filterAdGroups(options, params)
        updateFilteredAdGroups(filtered)
        return filtered
      }}
      options={allAdGroups}
      optionFunction={option => option.name}
      optionIndentFunction={option => 20 * option.depth}
      optionTagFunction={option => `${option.campaignName} > ${option.name}`}
      noOptionsText={i18next.t('biddingRun.noAdGroupsMatchYourQuery')}
      onChange={(_, value) => updateAdGroups(value.filter(o => o.type == 'adGroup').map(o => o.id))}
      PaperComponent={paperProps => getPaper('ad_groups', paperProps)}
      inputValue={adGroupFilter}
      onInputChange={(_, value, reason) => reason !== 'reset' && setAdGroupFilter(value)}
      onBlur={() => setAdGroupFilter('')}
    />
  )
  return (
    <Stack sx={{ width: props.width, minWidth: props.minWidth }}>
      <Box>
        <Typography>{i18next.t('biddingRun.engines')}</Typography>
        {props.allEngines.map((engine, i) => (
          <Checkbox
            key={i}
            disabled={props.status == 'published'}
            checked={props.engines.includes(engine)}
            onChange={event => setEngines(engine, event.target.checked)}
            labelText={convertName(engine)} />
        ))}
      </Box>
      <Box>
        <Typography>{i18next.t('biddingRun.adTypes')}</Typography>
        {props.allTypes.map((type, i) => (
          <Checkbox
            key={i}
            disabled={props.status == 'published'}
            checked={props.entityTypes.includes(type)}
            onChange={event => setTypes(type, event.target.checked)}
            labelText={convertName(type)} />
        ))}
      </Box>
      {campaignPulldown}
      {adGroupPulldown}
      <Switch
        disabled={props.status == 'published'}
        checked={props.selectByLabel}
        onChange={() => props.onUpdate({ selectByLabel: !props.selectByLabel })}
        label={i18next.t('biddingRun.labelSelectSwitch')} />
      {props.selectByLabel && <LabelSelect {...props} />}
    </Stack>
  )
}

const CampaignSelect = (props) => {
  return (
    <Section
      width={props.width}
      minWidth={props.minWidth}
      minHeight={props.minHeight}
      name={i18next.t('biddingRun.filterKeywords')}
      description={i18next.t('biddingRun.filterKeywordsDesc')}
      showWarning={props.showWarning} >
      {<CampaignInputs {...props} />}
    </Section>
  )
}

export default CampaignSelect