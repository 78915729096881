import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import FtoTabs from '../widgets/Tabs'
import FtoTab from '../widgets/Tab'
import Section from '../widgets/Section'
import { theme } from '../widgets/theme'
import { Box, ThemeProvider } from '@mui/material'
import Page from '../widgets/Page'
import { guid } from '../../utilities/guids'
import { encode, decode } from '../../api/encoding'
import { getPerformanceData, exportPerformanceData } from '../../api/performance'
import ComparisonChartWithSelectors from "../charts/ComparisonChartWithSelectors"
import Table from '../widgets/Table'
import DataConfiguration from '../DataConfiguration'
import DatePicker from '../widgets/DatePicker'
import { convertName, formatCurrency } from '../../utilities/formatting'
import { getSummary } from '../../api/ads'

const Interface = ({ location, history, internal_client_id }) => {
  const tabs = [
    { id: 'account_descriptive_name', name: 'Account' },
    { id: 'campaign_name', name: 'Campaign' },
    { id: 'ad_group_name', name: 'Ad Group' },
    { id: 'criteria', name: 'Criteria' },
  ]

  const getConfig = () => {
    let config = {}
    const pathname = location.pathname
    if (pathname != '/performance') {
      const paths = pathname.replace("/performance", "").split('/')
      if (paths[1]) {
        config = JSON.parse(decode(paths[1]))
      }
    }
    return config
  }

  const config = getConfig()
  const [changeCode, setChangeCode] = useState(guid())
  const [tab, setTab] = useState(0)
  const [dates, setDates] = useState(config.dates || {
    from: moment().subtract(28, 'day').format('YYYY-MM-DD'),
    to: moment().subtract(1, 'day').format('YYYY-MM-DD')
  })
  const [filters, setFilters] = useState(config.filters || {})
  const [additionalFilters, setAdditionalFilters] = useState({})
  const [engines, setEngines] = useState({ engine1: 'all', engine2: 'all' })
  const [data, setData] = useState({ summary1: null, dataset1: null, summary2: null, dataset2: null })

  const getEntity = () => {
    return tabs[tab].id
  }

  useEffect(() => {
    const newAdditionalFilters = Object.assign({}, additionalFilters)
    for (let i = tab; i < tabs.length; i++) {
      delete newAdditionalFilters[tabs[i].id]
    }
    setAdditionalFilters(newAdditionalFilters)
  }, [tab])

  useEffect(() => {
    updateUrl()
  }, [JSON.stringify({ filters, dates })])

  useEffect(() => {
    getChartData()
  }, [JSON.stringify({ filters, dates, additionalFilters, engines, entity: getEntity() })])

  const clickable_columns = [
    {
      id: 'engine',
      datatype: 'text',
      width: 90,
      format: value => value == '' ? '' : convertName(value)
    }, {
      id: 'account_descriptive_name',
      datatype: 'text',
      width: 400,
      cellRenderer: (_, row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => {
          const newAdditionalFilters = Object.assign({}, additionalFilters)
          newAdditionalFilters['account_descriptive_name'] = row['account_descriptive_name']
          setAdditionalFilters(newAdditionalFilters)
          setTab(1)
        }}>{row['account_descriptive_name']}</div>
      ),
    }, {
      id: 'campaign_name',
      datatype: 'text',
      width: 400,
      cellRenderer: (_, row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => {
          const newAdditionalFilters = Object.assign({}, additionalFilters)
          newAdditionalFilters['campaign_name'] = row['campaign_name']
          setAdditionalFilters(newAdditionalFilters)
          setTab(2)
        }}>{row['campaign_name']}</div>
      ),
    }, {
      id: 'ad_group_name',
      datatype: 'text',
      width: 400,
      cellRenderer: (_, row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => {
          const newAdditionalFilters = Object.assign({}, additionalFilters)
          newAdditionalFilters['ad_group_name'] = row['ad_group_name']
          setAdditionalFilters(newAdditionalFilters)
          setTab(3)
        }}>{row['ad_group_name']}</div>
      ),
    }, {
      id: 'criteria',
      datatype: 'text',
      width: 400,
    }
  ]

  const updateUrl = (source_path = location.pathname) => {
    const encoded = encode(JSON.stringify({ filters, dates }))
    const path = source_path.split('/')
    path[2] = encoded
    const fullPath = path.join('/')
    if (location.pathname != '/performance' && location.pathname != fullPath) {
      history(fullPath)
    }
  }

  const getChartData = () => {
    const params = {}
    if (engines.engine1 != 'all') {
      params.engines = engines.engine1
    }
    getSummary(getParams(params)).then(response => {
      if (engines.engine1 == engines.engine2) {
        setData({
          summary1: response.summary,
          dataset1: response.results,
          summary2: null,
          dataset2: null,
        })
      } else {
        const params2 = {}
        if (engines.engine2 != 'all') {
          params2['engines'] = engines.engine2
        }
        getSummary(getParams(params2)).then(response2 => {
          setData({
            summary1: response.summary,
            dataset1: response.results,
            summary2: response2.summary,
            dataset2: response2.results,
          })
        })
      }
    })
  }

  const getColumns = () => {
    const columns = [{
      id: 'clicks',
      datatype: 'numeric',
      width: 80,
    }, {
      id: 'cost',
      datatype: 'numeric',
      width: 100
    }, {
      id: 'cpc',
      datatype: 'numeric',
      width: 100,
      format: value => formatCurrency(value),
    }, {
      id: 'internal_roas',
      datatype: 'numeric',
      width: 140
    }, {
      id: 'internal_rpc',
      datatype: 'numeric',
      width: 130
    }, {
      id: 'impressions',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 155,
    }, {
      id: 'engine_roas',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'engine_rpc',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'engine_revenue',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'ctr',
      datatype: 'numeric',
      width: 80
    }, {
      id: 'internal_cr',
      datatype: 'numeric',
      width: 150
    }, {
      id: 'engine_cr',
      datatype: 'numeric',
      width: 150
    }, {
      id: 'internal_aov',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'engine_aov',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'internal_cpa',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'engine_cpa',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'max_cpc',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'internal_conversion',
      datatype: 'numeric',
      width: 120
    }, {
      id: 'engine_conversion',
      datatype: 'numeric',
      width: 120
    },
    ]
    return overrideColumns(columns) // the extra columns will be ignored here, but it forces a recalculation
  }

  const overrideColumns = (columns) => {
    return clickable_columns.slice(0, tab + 2).concat(columns)
  }

  const getParams = (tableData = {}) => {
    const params = Object.assign({
      internal_client_id,
      from_date: dates.from,
      to_date: dates.to,
      entity: getEntity(),
    }, tableData)
    Object.assign(params, filters)

    // Switch group_by to actual column name
    Object.keys(params).forEach((key) => {
      if (key.indexOf('text_') == 0) {
        const newValues = []
        params[key].forEach((value) => {
          if (value.indexOf('group_by ') == 0) {
            value = value.replace('group_by ', tabs[tab].id + ' ')
          }
          if (location.pathname != value) {
            newValues.push(value)
          }
        })
        params[key] = newValues
      }
    })

    // Add and filtering as a result of drill down
    if (!params['text_equal_filter']) {
      params['text_equal_filter'] = []
    }
    Object.keys(additionalFilters).forEach((key) => {
      params['text_equal_filter'].push(key + ' ' + additionalFilters[key])
    })

    return params
  }

  const removeFilter = (id) => {
    const newFilters = Object.assign({}, additionalFilters)
    delete newFilters[id]
    setAdditionalFilters(newFilters)
    setChangeCode(guid()) // forces update of table
  }

  const getFilterWidgets = () => {
    let widgets = null
    if (Object.keys(additionalFilters).length) {
      widgets = []
      tabs.forEach((tab, i) => {
        if (additionalFilters[tab.id] != null) {
          widgets.push((
            <span key={i} style={{ marginRight: 7 }}>
              {tab.name + '=' + additionalFilters[tab.id]}
              <i className="fa fa-times" style={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => removeFilter(tab.id)}></i>
            </span>
          ))
        }
      })
    }
    return widgets
  }

  const table = useMemo(() => (
    <Table
      tableName={'performance'}
      fetch={tableData => getPerformanceData(getParams(tableData))}
      export={tableData => exportPerformanceData(getParams(tableData))}
      headerHeight={40}
      columns={getColumns()}
      columnOverride={columns => overrideColumns(columns)}
      changeParams={{ changeCode, dates, filters }}
    />
  ), [JSON.stringify({ internal_client_id, dates, filters, additionalFilters, changeCode, entity: getEntity() })])

  const filterWidgets = getFilterWidgets()

  const handleChange = (event, newTab) => {
    setTab(newTab)
  }

  return (
    <ThemeProvider theme={theme}>
      <Page>
        <DataConfiguration
          filterValues={filters}
          changed={newFilters => {
            setFilters(newFilters)
            getChartData()
          }}
        />
        <div className="clearfix"></div>
        <br />
        <Section>
          <div className="page-nav" style={{ position: 'relative' }}>
            <div className="btn-group" style={{ width: '265px', display: 'inline-block' }}>
              <a className="btn btn-default toggle-nav">
                <i className="fa fa-wrench" />
              </a>
              <DatePicker
                start={dates.from}
                end={dates.to}
                showCompare={false}
                callback={newDates => setDates({ from: newDates.start, to: newDates.end })}
              />
            </div>
          </div>
          <FtoTabs value={tab} onChange={handleChange}>
            {tabs.map((tab, i) => (
              <FtoTab label={tab.name} key={tab.id} value={i} />
            ))}
          </FtoTabs>
          <Tabs selectedIndex={tab} onSelect={newTab => setTab(newTab)}>
            <div className="row">
              <div className="col-md-12">
                <div className="box box-info js_complete">
                  {tabs.map((tab, i) => {
                    return (
                      <TabPanel key={tab.id} selectedIndex={i} />
                    )
                  })}
                  {filterWidgets != null && (<div style={{ padding: 5 }}>{filterWidgets}</div>)}
                  <Box sx={{ mt: 5 }}>
                    <ComparisonChartWithSelectors
                      fromDate1={dates.from}
                      toDate1={dates.to}
                      summary1={data.summary1}
                      dataset1={data.dataset1}
                      summary2={data.summary2 == null ? data.summary1 : data.summary2}
                      dataset2={data.dataset2 == null ? data.dataset1 : data.dataset2}
                      callback={values => (engines.engine1 != values.engine1 || engines.engine2 != values.engine2) && setEngines({ engine1: values.engine1, engine2: values.engine2 })}
                    />
                  </Box>
                  {table}
                </div>
              </div>
            </div>
          </Tabs>
        </Section>
      </Page>
    </ThemeProvider>
  )
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
