import { Component } from 'react';
import { getConfig } from '../../api/config';

export default class Interface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airflow_url: null,
    };
  }

  componentDidMount() {
    getConfig('').then((json) => {
      this.setState({airflow_url: json.airflow_url + '/home'});
    });
  }

  render() {
    if (this.state.airflow_url) {
      return (
        <div className="site-canvas">
          <section className="content">
            <iframe className="js_complete" src={this.state.airflow_url} style={{width: '100%', height: 'calc(100vh - 135px)'}}/>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}
