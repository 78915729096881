import _ from "lodash"
import { Component, createRef } from 'react'
import Table from '../../widgets/Table'
import FTNotice from '../../FTNotice'
import DeleteButton from '../../DeleteButton'
import AddRow from '../../AddRow'
import { getRunResult, exportRunResult, updateRunResult, deleteRunResult } from '../../../api/workflows'
import { stopService } from '../../../api/services'
import { connect } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { formatInteger } from '../../../utilities/formatting'

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class RunResult extends Component {
  tableRef = createRef()

  constructor(props) {
    super(props);
    this.state = {
      rowsToDelete: [],
      columns: this.getColumns(props.columns),
      stopping: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.columns, prevProps.columns)) {
      this.setState({ columns: this.getColumns(this.props.columns) }, () => this.refreshRows());
    } else if (!_.isEqual(this.state.primaryKeys, prevState.primaryKeys) || this.state.dbtable != prevState.dbtable || this.state.editing != prevState.editing) {
      this.setState({ columns: this.getColumns(this.props.columns) }, () => {
        if (this.tableRef.current) {
          this.tableRef.current.refreshCells()
        }
      });
    }
    if (this.props.result && prevProps.result && this.props.result.status != prevProps.result.status && this.state.stopping) {
      this.setState({ stopping: false });
    }
  }

  isService() {
    return !['sql', 'sql_dashboard', 'trigger_dag', 'generate_metrics'].includes(this.props.type);
  }

  refreshRows() {
    if (this.tableRef.current) {
      this.tableRef.current.refreshRows();
    }
  }

  getColumns(columns) {
    if (columns) {
      let new_cols = [];
      columns.forEach(column => {
        const columnDefinition = {
          id: column,
          header: column,
          datatype: 'other',
          width: 200
        };
        if (this.state && this.state.primaryKeys && !this.state.primaryKeys.includes(column)) {
          columnDefinition.cellRenderer = (value, row) => {
            const key = this.getPrimaryKey(row);
            key.__row = row;
            key.__column = column;
            const isEditing = this.isEquivalent(this.state.editing, key);
            return (
              <div className="text-center" style={{ cursor: 'pointer', width: '100%', minHeight: 20 }} onClick={() => {
                if (!isEditing) {
                  if (this.state.editing) {
                    this.updateRow(this.__element, this.state.editing.__row, column, key);
                  } else {
                    this.setState({ editing: key });
                  }
                }
              }}>
                {isEditing && (
                  <input type="text" className="form-control" defaultValue={value}
                    ref={(element) => { if (element) { element.focus(); element.select(); this.__element = element; } }} onKeyDown={(event) => {
                      if (event.key == 'Enter' || event.key == 'Tab') {
                        this.updateRow(event.target, this.state.editing.__row, column);
                      } else if (event.key == 'Escape') {
                        this.setState({ editing: null });
                      }
                    }}
                  />
                )}
                {!isEditing && (
                  <div>{value}</div>
                )}
              </div>
            );
          }
        }
        new_cols.push(columnDefinition);
      });
      if (this.state && this.state.primaryKeys) {
        new_cols.push({
          cellRenderer: (_, row) => (
            <DeleteButton
              objectType=""
              objectName="this row"
              deleteObject={() => this.deleteRow(row)} />
          ),
          width: 100,
        });
      }
      return new_cols;
    }
  }

  isEquivalent(a, b) {
    if (a == null || b == null) {
      return false;
    }
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    // If number of properties is different, objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      // If values of same property are not equal, objects are not equivalent
      if (propName != '__row' && a[propName] !== b[propName]) {
        return false;
      }
    }
    // If we made it this far, objects are considered equivalent
    return true;
  }

  createRow() {
    this.setState({ showCreate: false });
    this.refreshRows();
  }

  updateRow(element, row, column, key) {
    const newValue = element.value;
    if (newValue != row.value) {
      const primaryKeys = {};
      this.state.primaryKeys.forEach((primaryKey) => {
        primaryKeys[primaryKey] = row[primaryKey];
      });
      const data = {
        table: this.state.dbtable,
        primary_keys: primaryKeys,
        column: column,
        value: newValue,
      }
      updateRunResult(this.props.step_id, data).then((response) => {
        if (response.error) {
          FTNotice(response.error, 15000);
        } else {
          row[column] = newValue.toLowerCase() == 'null' ? null : newValue;
          this.setState({ editing: key });
        }
      });
    } else {
      this.setState({ editing: key });
    }
  }

  deleteRow(row) {
    const key = this.getPrimaryKey(row);
    const data = {
      table: this.state.dbtable,
      primary_keys: key,
    }
    deleteRunResult(this.props.step_id, data).then((response) => {
      if (response.error) {
        FTNotice(response.error, 15000);
      } else {
        this.refreshRows();
      }
    });
  }

  getPrimaryKey(row) {
    const key = {};
    this.state.primaryKeys.forEach((primaryKey) => {
      key[primaryKey] = row[primaryKey];
    });
    return key;
  }

  fetch(tableData) {
    return getRunResult(this.props.step_id, this.getData(tableData)).then((response) => {
      if (response.success) {
        this.setState({ primaryKeys: response.primary_keys, dbtable: response.table, columnDefs: response.columns });
        return response;
      } else {
        const error = response.error ? response.error : 'Could not execute sql.';
        FTNotice(error, 15000);
        return {
          results: [],
          total_rows: 0
        }
      }
    });
  }

  exportData(tableData) {
    return exportRunResult(this.props.step_id, this.getData(tableData));
  }

  getData(tableData) {
    const data = Object.assign({}, tableData);
    data['sql'] = this.props.sql;
    data['user_id'] = this.props.current_user_id;
    data['run_id'] = this.props.run_id;
    data['step_id'] = this.props.step_id;
    return data;
  }

  getColumnWidth(rows, accessor, headerText) {
    const maxWidth = 400
    const magicSpacing = 10
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  getProgress() {
    const result = this.props.result;
    if (result && result.total) {
      const progress = 100 * result.processed / result.total;
      return progress > 0 ? progress : null;
    }
  }

  getRunInfo() {
    const result = this.props.result;
    let runInfo = null;
    if (result) {
      runInfo = result.status.charAt(0).toUpperCase() + result.status.slice(1);
      if (result.message) {
        runInfo += ' (' + result.message + ')';
      }
      if (result.total) {
        runInfo += ': ' + formatInteger(result.processed) + ' / ' + formatInteger(result.total);
      }
    }
    return runInfo;
  }

  stopService() {
    stopService(this.props.run_id).then(() => {
      FTNotice('Service is being stopped...');
      this.setState({ stopping: true });
    });
  }

  renderMessage() {
    if (this.isService()) {
      if (this.props.result) {
        const runInfo = this.getRunInfo();
        const progress = this.getProgress();
        return (
          <div>
            <pre style={{ borderColor: this.props.success ? null : 'red' }}>
              {runInfo != '' && <div>{runInfo}</div>}
            </pre>
            {progress != null && (
              <div>
                <LinearProgressWithLabel value={progress} />
              </div>
            )}
            {this.props.running && (
              <button className="btn btn-danger" onClick={() => { !this.state.stopping && this.stopService() }}>Stop</button>
            )}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div>
          {this.state.running && (
            <pre>Running</pre>
          )}
          {!this.state.running && (
            <pre style={{ borderColor: this.props.success ? null : 'red' }}>{this.props.result}</pre>
          )}
        </div>
      );
    }
  }

  renderResult() {
    if (this.state.columns) {
      return (
        <div>
          {this.renderMessage()}
          <div className='site-wrapper-offcanvas'>
            <h4 style={{ textAlign: 'center' }}>Results</h4>
            {this.state.dbtable && this.state.primaryKeys == null && this.props.devMode == true && (
              <div style={{ float: 'left' }}>These results do not have a primary key, so cannot be updated / deleted.  If this is querying a single table, you can add a primary key by executing: ALTER TABLE table_name ADD CONSTRAINT constraint_name PRIMARY KEY (columns); Note: columns must be not null, see <a href="https://popsql.com/learn-sql/redshift/how-to-add-or-remove-default-values-or-null-constraints-to-a-column-in-redshift" target="_blank">https://popsql.com/learn-sql/redshift/how-to-add-or-remove-default-values-or-null-constraints-to-a-column-in-redshift</a> for more details.</div>
            )}
            {this.state.dbtable && this.state.primaryKeys != null && (
              <div style={{ float: 'left' }}>To edit the results, click on a cell.  Note: primary keys ({this.state.primaryKeys.join(', ')}) cannot be changed.  To save a value, click on another cell or hit tab or return.</div>
            )}
            {this.state.columnDefs != null && (
              <div style={{ float: 'right' }}>
                <a className="btn btn-box-tool text-green" onClick={(event) => { event.preventDefault(); this.setState({ showCreate: true }); }}>
                  <i className="fa fa-plus"></i>
                  <span>Add New Row</span>
                </a>
                {this.state.showCreate && (
                  <AddRow stepId={this.props.step_id} table={this.state.dbtable} primaryKeys={this.state.primaryKeys} columns={this.state.columnDefs} onClose={() => this.createRow()} />
                )}
              </div>
            )}
          </div>
          <Table
            ref={this.tableRef}
            fetch={(tableData) => { return this.fetch(tableData) }}
            export={(tableData) => { return this.exportData(tableData) }}
            columns={this.state.columns}
            rowHeight={50} />
        </div>
      );
    } else if (this.props.result) {
      return this.renderMessage();
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='run_result'> {this.renderResult()} </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    current_user_id: state.users.user ? state.users.user.id : null
  };
}

export default connect(mapStateToProps)(RunResult);
