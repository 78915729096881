import React, { Component } from 'react'
import { Chart, Tooltip, Title } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import '../../assets/stylesheets/charts.css'
import { COLORS } from '../charts/util'

Chart.register(Tooltip, Title)

/**
 * A pie chart component created with chart.js.
 * Source: https://www.chartjs.org   React Wrapper: https://github.com/reactchartjs/react-chartjs-2
 *
 * Properties:
 *  labels          - An array of names of metrics. E.g. ['Bing', 'Google', ...]
 *  datasets        - An array of data for the metrics. E.g [[2031, 3324, ...]]
 *  title           - (optional) Chart title.
 *  data_formatter  - (optional) E.g "{value}%"
 *  animation       - (optional) Whether to animate on drawing.  Default to true.
 */

export default class PieChart extends Component {
  static defaultProps = {
    animation: true,
  }
  
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      options: null,
    }
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  generateDatasets() {
    let datasets = []
    for (let i = 0; i < this.props.datasets.length; i++) {
      let backgroundColors = []
      let data = this.props.datasets[i]
      for (let j = 0; j < data.length; j++) {
        backgroundColors.push(COLORS[j])
      }
      datasets.push({
        label: this.props.labels[i],
        data: data,
        backgroundColor: backgroundColors,
      })
    }

    return datasets
  }

  updateChart() {
    let _this = this
    const animation = this.props.animation ? {} : { duration: 0 }
    this.setState({
      data: {
        labels: this.props.labels,
        datasets: this.generateDatasets(),
      },
      options: {
        animation: animation,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: this.props.title,
            text: this.props.title,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.label || ""
                var formatter = _this.props.data_formatter
                if (label) {
                  if (formatter) {
                    label +=
                      ": " +
                      formatter(context.formattedValue)
                  } else {
                    label += ": " + context.formattedValue
                  }
                }
                let total = 0
                context.dataset.data.forEach(value => total += parseFloat(value))
                const percent = total ? (' (' + Math.round(100 * context.parsed / total) + '%)') : ''
                return label + percent
              },
            },
          },
        },
      },
    })
  }

  render() {
    const plugin = {
      id: "id",
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx

        ctx.restore()
        var fontSize = (height / 114).toFixed(2)
        ctx.font = fontSize + "em sans-serif"
        ctx.textBaseline = "middle"

        var text = "75%",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2

        ctx.fillText(text, textX, textY)
        ctx.save()
      },
    }

    if (this.state.data) {
      return (
        <Pie
          key={JSON.stringify(this.props)}
          data={this.state.data}
          options={this.state.options}
          plugin={[plugin]}
        />
      )
    } else {
      return null
    }
  }
}
