import moment from "moment"
import { Component } from "react"
import Table from "../widgets/Table"
import { getChartColumnNames } from "./chart"
import BarChart from "../charts/BarChart"
import PieChart from "../charts/PieChart"
import { dataFormatter, convertName } from "../../utilities/formatting"

export default class GroupedChart extends Component {
  static defaultProps = {
    start: moment().subtract(28, "day").format("YYYY-MM-DD"),
    end: moment().subtract(1, "day").format("YYYY-MM-DD"),
    compareStart: null,
    compareEnd: null,
  };

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.drawGroupedChart(this.props.data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.data != prevProps.data) {
      this.drawGroupedChart(this.props.data)
    }
  }

  drawGroupedChart(results) {
    if (results) {
      this.drawGroupChartType(results, 'pie_chart');
      this.drawGroupChartType(results, 'bar_chart');
    }
  }

  drawGroupChartType(results, type) {
    const dimension = this.getSingularDimension();
    const chartColumnNames = getChartColumnNames(
      this.props.charts,
      type
    );
    $.each(chartColumnNames, (_, metric) => {
      var data = {};
      var hcdata = [];
      $.each(results.slice(0, 10), (_, row) => {
        data[convertName(row[dimension])] = row[metric.name];
      }); //Reduce to one metric per dimension: {Domestic: 49.9, International: 71.5},
      $.each(data, (k, v) => {
        hcdata.push({ name: k, y: v });
      }); // Oddball transformation for pie charts
      const state = {}
      if (type == 'pie_chart') {
        this.renderPieChart(metric, hcdata, state);
      } else if (type == 'bar_chart') {
        this.renderBarChart(metric, hcdata, state);
      }
      this.setState(state)
    });
  }

  renderPieChart(metric, data, state) {
    const labels = [];
    const values = [];
    data.forEach((d) => {
      labels.push(d.name);
      values.push(d.y == null ? null : d.y.toFixed(2));
    });
    state["pie_chart_data_" + metric.name] = {
      labels: labels,
      data: values,
    };
  }

  renderBarChart(metric, data, state) {
    const labels = [];
    const values = [];
    data.forEach((d) => {
      labels.push(d.name);
      values.push(d.y == null ? null : d.y.toFixed(2));
    });
    state["bar_chart_data_" + metric.name] = {
      labels: labels,
      data: [{
        metric: metric.name,
        data: values
      }],
    };
  }

  getSingularDimension() {
    return this.props.dimension[this.props.dimension.length - 1] == "s"
      ? this.props.dimension.substring(0, this.props.dimension.length - 1)
      : this.props.dimension;
  }

  getGroupBy() {
    return this.props.dimensionName;
  }

  getColumns() {
    let columns = [
      {
        columnId: "dimension",
        id: this.getSingularDimension(),
        format: (value) => convertName(value),
        header: "Dimension",
        datatype: "text",
      },
      {
        id: "internal_revenue",
        datatype: "numeric",
      },
      {
        id: "clicks",
        datatype: "numeric",
      },
      {
        id: "cost",
        datatype: "numeric",
      },
      {
        id: "internal_roas",
        datatype: "numeric",
      },
      {
        id: "cr",
        datatype: "numeric",
      },
      {
        id: "cpc",
        datatype: "numeric",
      },
      {
        id: "engine_revenue",
        datatype: "numeric",
      },
      {
        id: "internal_cpa",
        datatype: "numeric",
      },
      {
        id: "internal_conversion",
        datatype: "numeric",
      },
    ];

    return columns;
  }

  renderCharts(type) {
    return this.props.charts
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((chart, i) => {
        const data = this.state[type + "_data_" + chart.name];
        if (chart.type == type && !chart.hide)
          return (
            <div className="col-md-3" key={i}>
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title" title={chart.localizedName} style={{overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                    {chart.localizedName}
                  </h3>
                </div>
                <div className="box-body">
                  {type == "bar_chart" && data && (
                    <div style={{height: 160}}>
                      <BarChart
                        labels={data.labels}
                        datasets={data.data}
                        colorPerValue={true}
                      />
                    </div>
                  )}
                  {type == "pie_chart" && data && (
                    <div style={{height: 160}}>
                      <PieChart
                        labels={data.labels}
                        datasets={[data.data]}
                        data_formatter={dataFormatter(chart.name)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
      });
  }

  render() {
    let groupBy = this.getGroupBy();
    return (
      <div>
        <div className="row">
          {this.renderCharts("pie_chart")}
          {this.renderCharts("bar_chart")}
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title" title={groupBy + ' Details'} style={{overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                  <span className="dimension_name">{groupBy}</span>
                  Details
                </h3>
              </div>
              {this.props.data && (
                <div className="box-body js_complete">
                  <Table
                    tableName={"dimensions"}
                    dataset={this.props.data}
                    columns={this.getColumns()}
                    autoHeight={true}
                    hidePagination={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
