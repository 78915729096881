import { Typography, Box } from '@mui/material'
import i18next from 'i18next'

// props.variant: "h1", "h2", etc. these are defined by typography in theme.js
// props.title: (string value)

const Title = (props) => {
  const defaultProps = {
    variant: "h3",
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { title, ...typographyProps } = revisedProps
  return (
    <Box sx={{paddingBottom: 1}}>
      <Typography {...typographyProps} sx={{ textTransform: "capitalize" }}>
        {i18next.t(title)}
      </Typography>
    </Box>
  )
}

export default Title