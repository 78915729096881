import React from 'react'
import i18next from 'i18next'
import Section from '../../widgets/Section'
import TextField from '../../widgets/TextField'

const ImpressionShareFilter = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.impressionShareDesc')}>
      {<TextField
        disabled={props.status == 'published'}
        label={i18next.t('biddingRun.impressionShare')}
        variant="outlined"
        value={props.max_impression_share}
        helperText={i18next.t('biddingRun.enterAPercentage')}
        onChange={event => props.onUpdate({ max_impression_share: event.target.value })}
      />}
    </Section>
  )
}

export default ImpressionShareFilter