import React from 'react'
import i18next from 'i18next'
import Section from '../../widgets/Section'
import Stack from '../../widgets/Stack'
import Checkbox from '../../widgets/Checkbox'
import GenerateBids from './GenerateBids'
import TextField from '../../widgets/TextField'
import { Box } from '@mui/material'

const BidNameAndGenerate = (props) => {
  const disabled = props.status == 'published'
  return (
    <Section
      name={props.name}
      width="100%"
      description={i18next.t('biddingRun.bidRunNameDesc')}>
      {<Stack spacing={3}>
        <TextField
          disabled={disabled}
          fullWidth
          multiline
          value={props.bidRunName}
          onChange={event => props.onChangeBidRunName(event)}
          variant="outlined"
        />
        <Checkbox
          disabled={props.started || props.status == 'published'}
          value={props.createAbTest}
          onChange={event => props.onUpdate({create_ab_test: event.target.checked})}
          labelText="bidding.createAbTest" />
        <Box display="flex" justifyContent="center">
          <GenerateBids
            isAdmin={props.isAdmin}
            onUpdate={props.onUpdate}
            setSuccessDialogOpen={props.setSuccessDialogOpen}
            setBidSummaryOpen={props.setBidSummaryOpen}
            bidding_run_id={props.bidding_run_id}
            generateBids={props.generateBids}
            save={props.save}
            status={props.status}
            metricSelected={props.metricSelected}
            campaignIds={props.campaignIds}
            adGroupIds={props.adGroupIds}
            engines={props.engines}
            entityTypes={props.entityTypes}
            started={props.started}
            schedule={props.schedule} />
        </Box>
      </Stack>
      }
    </Section>
  )
}

export default BidNameAndGenerate