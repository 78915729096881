import { Box, Typography } from '@mui/material'
import Section from '../../widgets/Section'
import TextField from '../../widgets/TextField'
import Stack from '../../widgets/Stack'
import RadioButtons from '../../widgets/RadioButtons'
import RadioButton from '../../widgets/RadioButton'
import i18next from 'i18next'

const BidInputs = props => {
  return (
    <Stack>
      <RadioButtons value={props.volume} onChange={event => props.onChangeVolume(event.target.value)} row>
        <RadioButton disabled={props.disabled} value="high" labelText="biddingRun.highVolume" />
        <RadioButton disabled={props.disabled} value="low" labelText="biddingRun.lowVolume" />
      </RadioButtons>
      <Stack direction="row" spacing="0">
        <TextField sx={{width: '75px'}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*(.[0-9])?' }} variant="outlined"
          disabled={props.disabled}
          value={props.minPercentClicks}
          onChange={event => props.onChangeMinPercentClicks(event.target.value)}
        /><Box sx={{paddingTop: '17px', width: '17px', paddingRight: '5px'}}>%</Box>
        <Stack spacing="0">
          <Typography variant="body2">{i18next.t('biddingRun.minClickPercentage1')}</Typography>
          <Typography variant="body2">{i18next.t('biddingRun.minClickPercentage2', {percent: props.minPercentClicks})}</Typography>
          <Typography variant="body2">{i18next.t('biddingRun.minClickPercentage3', {percent: props.minPercentClicks})}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing="0" alignItems="center">
        <TextField sx={{width: '75px'}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined"
          disabled={props.disabled}
          value={props.minImpressions}
          onChange={event => props.onChangeMinImpressions(event.target.value)}
        /><Box sx={{paddingTop: '17px', width: '17px'}}></Box>
        <Stack spacing="0">
          <Typography variant="body2">{i18next.t(props.volume == 'high' ? 'biddingRun.minImpressionsHigh' : 'biddingRun.minImpressionsLow', {percent: props.minPercentClicks, impressions: props.minImpressions})}</Typography>
        </Stack>
      </Stack>
      {props.volume == 'low' && (
        <Stack direction="row" spacing="0" alignItems="center">
          <TextField sx={{width: '75px'}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*(.[0-9])?' }} variant="outlined"
            disabled={props.disabled}
            value={props.roasFloor}
            onChange={event => props.onChangeRoasFloor(event.target.value)}
          /><Box sx={{paddingTop: '17px', width: '17px'}}></Box>
          <Stack spacing="0">
            <Typography variant="body2">{i18next.t('biddingRun.roasFloor', {roas: props.roasFloor})}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

const BiddingHighLowVolume = props => {
  return (
    <Section
      name="biddingRun.highLowVolume">
      {<BidInputs
        disabled={props.status == 'published'}
        volume={props.volume}
        onChangeVolume={props.onChangeVolume}
        minPercentClicks={props.minPercentClicks}
        onChangeMinPercentClicks={props.onChangeMinPercentClicks}
        minImpressions={props.minImpressions}
        onChangeMinImpressions={props.onChangeMinImpressions}
        roasFloor={props.roasFloor}
        onChangeRoasFloor={props.onChangeRoasFloor}
        />}
    </Section>
  )
}

export default BiddingHighLowVolume