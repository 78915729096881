import { useState, useEffect } from 'react'
import { Chip, Box, Grid, Typography, Divider, CircularProgress } from '@mui/material'
import Button from '../widgets/Button'
import Stack from '../widgets/Stack'
import i18next from 'i18next'
import MarkdownResponse from './MarkdownResponse'

const PromptBuilder = ({
  status,
  handleResetInputs,
  responseOutput,
  demoComplete,
  setDemoComplete,
  setPromptDraft,
  handleSubmit,
  sentence,
  setSentence,
  setResponseOutput,
  selectedChips,
  setSelectedChips,
  promptDraft,
  disabled,
  isSmall
}) => {

  const [display, setDisplay] = useState()

  const wordBuckets = {
    questionStarters: ['whichCampaign', 'howMany'],
    mostLeast: ['hadTheMost', 'hadTheLeast', 'hadTheLowest', 'hadTheHighest'],
    metrics: ['clicks', 'impressions', 'conversions'],
    performance: ['cpa', 'roas', 'revenue'],
    time: ['yesterday', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth'],
    comparison: ['comparedToPreviousPeriod', 'comparedToTheSameDaysLastMonth', 'comparedToTheSameDaysLastWeek'],
    default: ['whichCampaign', 'hadTheHighest', 'roas', 'lastMonth'],
  }

  useEffect(() => {
    setPromptDraft(sentence)
  }, [sentence])

  const checkSelectedByBucket = (bucket) => {
    for (let i = selectedChips.length - 1; i >= 0; i--) {
      if (bucket.includes(selectedChips[i])) {
        selectedChips.splice(i, 1)
      }
    }
  }

  useEffect(() => {
    if (status == 'processing') {
      setDemoComplete(true)
      setDisplay(<Processing />)
    } else if (responseOutput) {
      setDemoComplete(true)
      setDisplay(<MarkdownResponse response={responseOutput} />)
    } else if (!responseOutput) {
      setDisplay(<MarkdownResponse response={sentence} />)
    }
  }, [])

  useEffect(() => {
    if (status == 'processing') {
      setDisplay(<Processing />)
    } else if (responseOutput !== '' && sentence === '') {
      setSelectedChips([])
      setDisplay(<MarkdownResponse response={responseOutput} />)
    } else {
      setDisplay(<MarkdownResponse response={sentence} />)
    }
  }, [sentence, demoComplete, responseOutput, status, promptDraft])
  
  const capitalizeSentence = newSentence => {
    setSentence(newSentence.charAt(0).toUpperCase() + newSentence.slice(1))
  }

  const handleChipClick = (word, bucket, currentChips) => {
    if (status == 'processing') {
      return
    }
    if (responseOutput) {
      setResponseOutput('')
    }
    if (currentChips.includes(word)) {
      const newSelectedChips = currentChips.filter(selectedWords => selectedWords !== word) // Remove the chip if already selected
      setSelectedChips(newSelectedChips)
      capitalizeSentence(newSelectedChips.map(c => i18next.t('ai.chips.' + c)).join(' '))
    } else if (!currentChips.includes(word)) {
      checkSelectedByBucket(bucket) // check to see if a chip from that bucket is already selected and removes it
      const newSelectedChips = [...currentChips, word] // adds newly selected chip to previously selected chips unless already added
      setSelectedChips(newSelectedChips)
      capitalizeSentence(newSelectedChips.map(c => i18next.t('ai.chips.' + c)).join(' ') + selectPunctuation(currentChips)) // formats sentence which triggers useEffect to update prompt
    }
  }

  const Processing = () => {
    return (
      <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
        <CircularProgress
          open
          size='15rem'
          sx={{
            '& .MuiCircularProgress-circle': {
              color: '#FFFFFF',
            },
          }} />
        <Typography sx={{ alignSelf: 'center', marginTop: 5 }} variant="h5" color="#FFFFFF">
          {i18next.t('ai.processing')}
        </Typography>
      </Box>
    )
  }

  const renderChips = (wordBucket) => {
    return (
      <div>
        {
          wordBucket.map((word, i) => (
            <Chip
              key={i}
              label={i18next.t('ai.chips.' + word)}
              clickable
              onClick={() => handleChipClick(word, wordBucket, selectedChips)}
              sx={{
                margin: 1,
                padding: 1,
                backgroundColor: selectedChips.includes(word) ? 'grey.900' : 'white.100',
                '& .MuiChip-label': {
                  fontSize: '1.5rem',
                  color: selectedChips.includes(word) ? 'white.100' : 'grey.900',
                }
              }}
            />
          ))
        }
      </div>
    )
  }

  const selectPunctuation = (currentChips) => {
    if (currentChips.some(value => wordBuckets.questionStarters.includes(value))) {
      return '?'
    } else {
      return ''
    }
  }

  // animated intro fires once per session (state stored in Prompt)
  useEffect(() => {
    let currentWordIndex = 0
    if (!demoComplete && responseOutput == '' && status != 'processing') {
      const currentChips = []
      const timer = setInterval(() => {
        const currentWord = wordBuckets.default[currentWordIndex]
        handleChipClick(currentWord, [currentWord], currentChips)
        currentChips.push(currentWord)
        currentWordIndex++
        if (currentWordIndex === wordBuckets.default.length) {
          clearInterval(timer)
          setTimeout(() => {
            setDemoComplete(true)
            handleResetInputs()
          }, 1000)
        }
      }, 500)
      return () => clearInterval(timer)
    }
  }, [])

  const gridItemHeight = 'calc(100vh - 180px)'
  const bucketMargins = 2

  return (
    <Grid container spacing={isSmall ? 1 : 5}>

      {/* word buckets */}
      <Grid item xs={7} sx={{ height: gridItemHeight, overflowY: 'auto', width: isSmall ? 'calc(58vw - 10px)' : 'calc(46vw - 30px)' }}>
        {renderChips(wordBuckets.questionStarters)}
        <Divider sx={{ my: bucketMargins, backgroundColor: 'white.100' }} />

        {renderChips(wordBuckets.mostLeast)}
        <Divider sx={{ my: bucketMargins, backgroundColor: 'white.100' }} />

        {renderChips(wordBuckets.metrics)}
        <Divider sx={{ my: bucketMargins, backgroundColor: 'white.100' }} />

        {renderChips(wordBuckets.performance)}
        <Divider sx={{ my: bucketMargins, backgroundColor: 'white.100' }} />

        {renderChips(wordBuckets.time)}
        <Divider sx={{ my: bucketMargins, backgroundColor: 'white.100' }} />

        {renderChips(wordBuckets.comparison)}
       </Grid>

      {/* sentence window */}
      <Grid item xs={5} sx={{ height: gridItemHeight, overflow: 'visible' }}>
        <Box sx={{
          padding: 3,
          backgroundColor: 'rgb(0,0,0,.10)',
          borderRadius: 10,
          marginBottom: 3,
          width: isSmall ? 'calc(41vw - 10px)' : 'calc(33vw - 30px)',
          height: '40vh'
        }}>
          {display}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Stack spacing={1} direction='row'>
            <Button sx={{
              bgcolor: 'white.100',
              color: 'grey.900',
              fontWeight: '400',
              backgroundColor: disabled ? 'grey' : 'white.100',
              '&:hover': {
                backgroundColor: disabled ? 'grey' : 'grey.300',
              }
            }} buttonText='ai.reset' onClick={() => handleResetInputs()} disabled={disabled} />
            <Button sx={{
              bgcolor: 'white.100',
              color: 'grey.900',
              fontWeight: '400',
              backgroundColor: disabled ? 'grey' : 'white.100',
              '&:hover': {
                backgroundColor: disabled ? 'grey' : 'grey.300',
              },
            }} buttonText='ai.submit' onClick={() => handleSubmit()} disabled={disabled} />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PromptBuilder
