import _ from 'lodash'
import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import isUndefined from 'lodash/isUndefined'
import { decode, encode } from '../../api/encoding'
import Metrics from './Metrics'
import Table from './Table'
import ComparisonChartWithSelectors from "../charts/ComparisonChartWithSelectors"
import { DAILY, WEEKLY, MONTHLY } from '../charts/ComparisonChart'
import DataConfiguration from '../DataConfiguration'
import DatePicker from '../widgets/DatePicker'
import Title from '../widgets/Title'
import Section from '../widgets/Section'
import Toggles from '../widgets/Toggles'
import Toggle from '../widgets/Toggle'
import Page from '../widgets/Page'
import Stack from '../widgets/Stack'
import Wrench from '../widgets/Wrench'
import Switch from '../widgets/Switch'
import { Box, Grid } from '@mui/material'
import { getSummary } from '../../api/ads'
import { getChartAnnotations } from '../../api/dashboards'

class Home extends Component {
  MAX_METRICS = ['max_cpc']

  state = {
    from_date: moment().subtract(28, "day").format("YYYY-MM-DD"),
    to_date: moment().subtract(1, "day").format("YYYY-MM-DD"),
    compare_from: null,
    compare_to: null,
    filters: {},
    title: null,
    granularity: DAILY,
    engineMetrics: true,
  }

  constructor(props) {
    super(props)
    $.extend(this.state, this.getConfig())
  }

  componentDidMount() {
    if (this.isReady()) {
      this.fetchData(this.props)
    } else {
      this.updateUrl("/filters/all,clicks/all,impressions/null,null")
    }
  }

  componentDidUpdate(prevProps) {
    if (this.isReady()) {
      if (
        !_.isEqual(this.getFullParams(prevProps), this.getFullParams(this.props))
      ) {
        this.fetchData(this.props)
      }

      if (this.props.location.pathname != prevProps.location.pathname) {
        const { metric1, metric2 } = this.getConfig()
        this.setState({ metric1, metric2 })
      }
    } else {
      this.updateUrl("/filters/all,clicks/all,impressions/null,null")
    }
  }

  getFullParams(props) {
    /* Return anything that would cause us to refetch data */
    const { dim1, dim2, compare_from, compare_to } = this.getPathParams(props)
    return Object.assign(
      {
        dim1: dim1,
        dim2: dim2,
        compare_from: compare_from,
        compare_to: compare_to,
      },
      this.getParams(props)
    )
  }

  isReady() {
    return this.props.location.pathname.split("/").length == 5
  }

  groupBy(data, period) {
    const buckets = []
    data.forEach(day => {
      const bucket = moment(day[0]).startOf(period).format('YYYY-MM-DD')
      if (buckets.length == 0 || buckets[buckets.length - 1][0] != bucket) {
        buckets.push([bucket, Object.assign({}, day[1])])
      } else {
        const values = buckets[buckets.length - 1][1]
        for (const [key, value] of Object.entries(values)) {
          let newValue = day[1][key]
          if (newValue == null) {
            newValue = value
          } else if (value != null) {
            if (this.MAX_METRICS.includes(key)) {
              newValue = newValue > value ? newValue : value
            } else {
              newValue = value + newValue
            }
          }
          values[key] = newValue
        }
      }
    })
    buckets.forEach(bucket => {
      const values = bucket[1]
      for (const key of Object.keys(values)) {
        if (key == 'cpc') {
          values[key] = this.getAverage(values.cost, values.clicks)
        } else if (key == 'ctr') {
          values[key] = this.getAverage(values.clicks, values.impressions, true)
        } else if (key == 'engine_aov') {
          values[key] = this.getAverage(values.engine_revenue, values.engine_conversion)
        } else if (key == 'internal_aov') {
          values[key] = this.getAverage(values.internal_revenue, values.internal_conversion)
        } else if (key == 'engine_cpa') {
          values[key] = this.getAverage(values.cost, values.engine_conversion)
        } else if (key == 'internal_cpa') {
          values[key] = this.getAverage(values.cost, values.internal_conversion)
        } else if (key == 'engine_cr') {
          values[key] = this.getAverage(values.engine_conversion, values.clicks, true)
        } else if (key == 'internal_cr') {
          values[key] = this.getAverage(values.internal_conversion, values.clicks, true)
        } else if (key == 'engine_roas') {
          values[key] = this.getAverage(values.engine_revenue, values.cost)
        } else if (key == 'internal_roas') {
          values[key] = this.getAverage(values.internal_revenue, values.cost)
        } else if (key == 'engine_rpc') {
          values[key] = this.getAverage(values.engine_revenue, values.clicks)
        } else if (key == 'internal_rpc') {
          values[key] = this.getAverage(values.internal_revenue, values.clicks)
        }
      }
    })
    return buckets
  }

  getAverage(numerator, denominator, percent) {
    return numerator == null || denominator == null || denominator == 0 ? null : ((percent ? 100 : 1) * numerator / denominator)
  }

  group(data) {
    if (data == null) {
      return null
    } else if (this.state.granularity == WEEKLY) {
      return this.groupBy(data, 'week')
    } else if (this.state.granularity == MONTHLY) {
      return this.groupBy(data, 'month')
    } else {
      return data
    }
  }

  updateGranularity(granularity) {
    if (this.state.granularity != granularity) {
      this.setState({ granularity }, () => this.setState({ dataset1Grouped: this.group(this.state.dataset1), dataset2Grouped: this.group(this.state.dataset2) }))
    }
  }

  fetchData(props) {
    const params = this.getParams(props)
    const { dim1, dim2 } = this.getPathParams(props)
    const { compare_from, compare_to } = this.state

    const params1 = Object.assign({}, params)
    if (dim1 != "all") {
      params1["engines"] = dim1
    }
    getSummary(params1).then((response) => {
      this.setState({
        fromDate1: params1.from_date,
        toDate1: params1.to_date,
        summary1: response.summary,
        dataset1: response.results,
        dataset1Grouped: this.group(response.results),
      })
    })

    if (compare_from || dim1 != dim2) {
      const params2 = Object.assign({}, params)
      if (dim2 != "all") {
        params2["engines"] = dim2
      }
      if (compare_from && compare_to) {
        params2.from_date = compare_from
        params2.to_date = compare_to
      }
      getSummary(params2).then((response) => {
        this.setState({
          fromDate2: params2.from_date,
          toDate2: params2.to_date,
          summary2: response.summary,
          dataset2: response.results,
          dataset2Grouped: this.group(response.results),
        })
      })
    } else {
      this.setState({
        fromDate2: null,
        toDate2: null,
        summary2: null,
        dataset2: null,
        dataset2Grouped: null,
      })
    }
    this.getChartAnnotations();
  }

  getChartAnnotations() {
    getChartAnnotations("homepage", this.props.internal_client_id).then(
      (response) => {
        this.setState({ annotations: response.results })
      }
    )
  }

  getParams(props) {
    /* Return filters used for fetching data */
    const params = this.getPathParams(props)
    const filter = params.filter
      ? JSON.parse(decode(params.filter))
      : {}
    return Object.assign(
      { internal_client_id: props.internal_client_id },
      filter
    )
  }

  getConfig() {
    let config = {}
    const pathname = this.props.location.pathname
    try {
      if (pathname != "/") {
        const paths = pathname.split("/")
        config = { filters: JSON.parse(decode(paths[1])) }
        if (config.filters.from_date) {
          config.from_date = config.filters.from_date
          delete config.filters.from_date
        }
        if (config.filters.to_date) {
          config.to_date = config.filters.to_date
          delete config.filters.to_date
        }
        if (!isUndefined(paths[2])) {
          config.engine1 = paths[2].split(',')[0]
          config.metric1 = paths[2].split(',')[1]
        }
        if (!isUndefined(paths[3])) {
          config.engine2 = paths[3].split(',')[0]
          config.metric2 = paths[3].split(',')[1]
        }
        if (!isUndefined(paths[4])) {
          const compare = paths[4].split(",")
          config.compare_from = compare[0] == "null" ? null : compare[0]
          config.compare_to = compare[1] == "null" ? null : compare[1]
        }
      }
    } catch (ex) { }
    return config
  }

  updateUrl(source_path = this.props.location.pathname) {
    const filters = this.getFilters()
    const path = source_path.split("/")
    path[1] = filters
    if (this.state.compare_from) {
      path[4] = `${this.state.compare_from},${this.state.compare_to}`
      // compare the same metrics
      path[3] = path[2]
    } else {
      path[4] = "null,null"
    }
    const fullPath = path.join("/")
    if (this.props.location.pathname != fullPath) {
      setTimeout(() => this.props.history(fullPath))
    }
  }

  getFilters() {
    const json = Object.assign({}, this.state.filters)
    json.from_date = this.state.from_date
    json.to_date = this.state.to_date
    return encode(JSON.stringify(json))
  }

  updateDates(dates) {
    this.setState(
      {
        from_date: dates.start,
        to_date: dates.end,
        compare_from: dates.compareStart,
        compare_to: dates.compareEnd,
      },
      () => {
        this.updateUrl()
      }
    )
  }

  getPathParams(props) {
    const parts = props.match.params['*'].split('/')
    return {
      filter: parts.length > 0 ? parts[0] : null,
      dim1: parts.length > 1 && parts[1].indexOf(',') > 0 ? parts[1].split(',')[0] : null,
      metric1: parts.length > 1 && parts[1].indexOf(',') > 0 ? parts[1].split(',')[1] : null,
      dim2: parts.length > 2 && parts[2].indexOf(',') > 0 ? parts[2].split(',')[0] : null,
      metric2: parts.length > 2 && parts[2].indexOf(',') > 0 ? parts[2].split(',')[1] : null,
      compare_from: parts.length > 3 && parts[3].indexOf(',') > 0 ? parts[3].split(',')[0] : null,
      compare_to: parts.length > 3 && parts[3].indexOf(',') > 0 ? parts[3].split(',')[1] : null,
    }
  }

  showEngineMetrics(prev) {
    this.setState({ engineMetrics: !prev })
  }

  render() {
    const params = this.getPathParams(this.props)
    const path = this.props.location.pathname
    const execSummaryElevation = 4
    if (this.isReady()) {
      return (
        <Page>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignContent='space-between'
            spacing={5}
          >
            <Grid item xs={12}>
              <Title title={this.state.title} variant="h2" />
            </Grid>
            <Grid item>
              <DataConfiguration
                filterValues={this.state.filters}
                changed={(filters) =>
                  this.setState({ filters: filters }, () => {
                    this.updateUrl()
                  })
                }
                title={(title) => this.setState({ title: title })}
              />
            </Grid>
          </Grid>
          {this.isReady() && this.state.summary1 && (
            <Grid
              container
              direction='column'
              // justifyContent='space-between'
              // alignContent='space-between'
              spacing={5}
            >
              <Grid container direction='row' spacing={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 'auto',
                  mx: '4rem',
                  mb: '1px'
                }}>
                <Grid item xs={10} md={1} justifyContent='center' alignContent='center'>
                  <Wrench />
                </Grid>
                <Grid item xs={12} md={4.5} justifyContent='center' alignContent='center'>
                  <Box sx={{ display: 'flex', direction: 'row' }}>
                    <DatePicker
                      start={this.state.from_date}
                      end={this.state.to_date}
                      compareStart={this.state.compare_from}
                      compareEnd={this.state.compare_to}
                      showCompare={true}
                      callback={(dates) => this.updateDates(dates)}
                      style={{ display: 'inline-block' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} >
                  <Box sx={{ display: 'flex', direction: 'row' }}>
                    <Toggles
                      value={this.state.granularity}
                      style={{ display: 'inline-block' }}>
                      <Toggle
                        labelText={'execSummary.daily'}
                        onClick={() => this.updateGranularity(DAILY)}
                        value={DAILY}
                      />
                      <Toggle
                        labelText={'execSummary.weekly'}
                        onClick={() => this.updateGranularity(WEEKLY)}
                        value={WEEKLY}
                      />
                      <Toggle
                        labelText={'execSummary.monthly'}
                        onClick={() => this.updateGranularity(MONTHLY)}
                        value={MONTHLY}
                      />
                    </Toggles>
                    <Switch color="secondary" label="execSummary.engineMetrics" defaultChecked onChange={() => this.showEngineMetrics(this.state.engineMetrics)} />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Section padding={0} elevation={execSummaryElevation} width='91vw' maxWidth='91vw'>
                  <Metrics
                    engineMetrics={this.state.engineMetrics}
                    summary1={this.state.summary1}
                    summary2={this.state.summary2}
                    compareFrom={this.state.compare_from}
                    fromDate={this.state.from_date}
                    path={path}
                  />

                </Section>
              </Grid>
              <Grid item xs={12}>
                <Section padding={0} elevation={execSummaryElevation} width='91vw' maxWidth='91vw'>
                  <ComparisonChartWithSelectors
                    aiMetricSelectChart={false}
                    annotationType="homepage"
                    annotations={this.state.annotations}
                    updateAnnotationsCallback={() => this.getChartAnnotations()}
                    granularity={this.state.granularity}
                    fromDate1={this.state.fromDate1}
                    toDate1={this.state.toDate1}
                    summary1={this.state.summary1}
                    dataset1={this.state.dataset1Grouped}
                    engine1={this.state.engine1}
                    metric1={this.state.metric1}
                    fromDate2={this.state.dataset2Grouped ? this.state.fromDate2 : this.state.fromDate1}
                    toDate2={this.state.dataset2Grouped ? this.state.toDate2 : this.state.toDate1}
                    summary2={this.state.summary2 ? this.state.summary2 : this.state.summary1}
                    dataset2={this.state.dataset2Grouped ? this.state.dataset2Grouped : this.state.dataset1Grouped}
                    engine2={this.state.engine2}
                    metric2={this.state.metric2}
                    callback={values => this.updateUrl(`/${this.getFilters()}/${values.engine1},${values.metric1}/${values.engine2},${values.metric2}/${this.state.compare_from},${this.state.compare_to}`)}
                    maxHeight={400}
                  />
                </Section>
              </Grid>
              <Grid item xs={12}>
                <Section padding={0} elevation={execSummaryElevation} width='91vw' maxWidth='91vw'>
                  <Table
                    key={this.props.location.pathname}
                    dataset1={this.state.dataset1Grouped}
                    dataset2={this.state.dataset2Grouped}
                    metrics={[params.metric1, params.metric2]}
                    preventSort={
                      this.state.compare_from &&
                      this.state.compare_from != "null"
                    }
                  />
                </Section>
              </Grid>

            </Grid>
          )}

        </Page>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Home))
