import React, { useMemo } from 'react'
import { connect } from 'react-redux';
import ReactFlow from 'reactflow'
import Node from './Node'
import styles from './InterfaceStyles'

import 'reactflow/dist/style.css'

const Interface = ({permissions}) => {
  const nodeTypes = useMemo(() => ({ custom: Node }), [])
  const canSeeFeeds = permissions.includes('feeds')
  const nodes = [{
    id: '1a',
    type: 'custom',
    data: { label: 'revenuePipeline.structureFeeds', hideTarget: true, link: canSeeFeeds ? '/#/feeds/section/all/Structure' : null },
    position: { x: 10, y: 10 },
  }, {
    id: '1b',
    type: 'custom',
    data: { label: 'revenuePipeline.structure', database: true },
    position: { x: 390, y: 10 },
  }, {
    id: '1c',
    type: 'custom',
    data: { label: 'revenuePipeline.structureAll', database: true },
    position: { x: 580, y: 10 },
  }, {
    type: 'custom',
    id: 'rc',
    data: { label: 'revenuePipeline.revenueCost', hideSource: true, database: true },
    position: { x: 770, y: 170 },
  }, {
    id: '2a',
    type: 'custom',
    data: { label: 'revenuePipeline.performanceFeeds', hideTarget: true, link: canSeeFeeds ? '/#/feeds/section/all/Performance' : null, backfill: true },
    position: { x: 10, y: 170 },
  }, {
    id: '2b',
    type: 'custom',
    data: { label: 'revenuePipeline.beforePerformance', link: '/#/revenue_pipeline/before_cost' },
    position: { x: 200, y: 170 },
  }, {
    id: '2c',
    type: 'custom',
    data: { label: 'revenuePipeline.performance', database: true },
    position: { x: 390, y: 170 },
  }, {
    id: '2d',
    type: 'custom',
    data: { label: 'revenuePipeline.afterPerformance', link: '/#/revenue_pipeline/after_cost' },
    position: { x: 580, y: 170 },
  }, {
    id: '3a',
    type: 'custom',
    data: { label: 'revenuePipeline.revenueFeeds', hideTarget: true, link: canSeeFeeds ? '/#/feeds/section/all/Revenue' : null, revenue: true },
    position: { x: 10, y: 335 },
  }, {
    id: '3b',
    type: 'custom',
    data: { label: 'revenuePipeline.revenue', database: true },
    position: { x: 390, y: 320 },
  }]
  const edges = [
    { id: 'e1', source: '1a', target: '1b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e2', source: '1b', target: '1c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e3', source: '1c', target: 'rc' },
    { id: 'e4', source: '2a', target: '2b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e5', source: '2b', target: '2c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e6', source: '2c', target: '2d', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e7', source: '2d', target: 'rc', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e8', source: '3a', target: '3b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e9', source: '3b', target: 'rc' },
  ]
  return (
    <div style={styles.container}>
      <div style={Object.assign({}, styles.immediateContainer, {height: 430, width: 880})}>
        <ReactFlow
          className="js_complete"
          style={styles.diagram}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
        />
      </div>
    </div>
  )
}


const mapStateToProps = function(state) {
  return {
    permissions: state.users.user ? state.users.user.permissions : [],
  }
}

export default connect(mapStateToProps)(Interface)
