import remarkGfm from "remark-gfm"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { unified } from "unified"
import remarkParse from "remark-parse"
import remarkRehype from "remark-rehype"
import rehypeReact from "rehype-react"
import rehypeRaw from "rehype-raw"
import { styled } from "@mui/system"
import Typography from '@mui/material/Typography'

const StyledTableContainer = styled(TableContainer)`
 border: 1px solid white;
 color: white
`
const StyledTable = styled(Table)`
`

const StyledTableCell = styled(TableCell)`
 border: 1px solid white;
 color: white;

  `

const StyledTableRow = styled(TableRow)`
  `

function compile(val) {
  const processor = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkRehype, null, {
      handlers: Object.assign({}),
    })
    .use(rehypeRaw)
    .use(rehypeReact, {
      createElement: React.createElement,
      components: RehypeComponentsList,
    })

  const ast = processor.runSync(processor.parse(val))

  return {
    ast,
    contents: processor.stringify(ast),
  }
}

const parseMarkdownTable = (markdownTableStr) => {

  const parseRowString = (rowStr) => {
    const values = rowStr.split("|")
    values.pop()
    values.shift()
    return values.map((x) => x.trim())
  }
  markdownTableStr = markdownTableStr.trim()
  const lines = markdownTableStr.split("\n").map(parseRowString)
  let header = lines[0]
  const rows = lines.slice(2)
  const ret = []
  rows.forEach((row) => {
    const rowItem = {}
    header.forEach((headerName, idx) => {
      rowItem[headerName] = row[idx]
    })
    ret.push(rowItem)
  })
  return ret
}

const TableExtractor = ({ markDownTable }) => {

  const renderParsed = () => {
    if (markDownTable !== null) {
      const rows = parseMarkdownTable(markDownTable)
      const header = Object.keys(rows[0])
      const keyHeader = header[0]
      return (
        <StyledTableContainer sx={{ color: 'white', border: 'solid' }}>
          <StyledTable sx={{
            color: 'white',
            border: 'solid',
            '& thead th:first-of-type': {
              maxWidth: '240px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'white',
              border: 'solid',
            },
            '& tbody td:first-of-type': {
              maxWidth: '240px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'white',
              border: 'solid',
            }
          }}>
            <TableHead sx={{ color: 'white', border: 'solid' }}>
              <StyledTableRow sx={{ color: 'white', border: 'solid' }}>
                {header.map((columnName) => (
                  <StyledTableCell key={columnName} sx={{ color: 'white', border: 'solid' }}>{columnName}</StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody sx={{ color: 'white', border: 'solid' }}>
              {rows.map((row) => (
                <TableRow
                  key={row[keyHeader]
                  }>
                  {header.map((columnName) => (
                    <StyledTableCell sx={{ color: 'white', border: 'solid' }} component="th" scope="row" key={row[columnName]}>
                      {row[columnName]}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      )
    } else {
      return
    }
  }
  return renderParsed()
}

export default TableExtractor
