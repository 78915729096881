import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import Title from '../Title'
import CircularProgress from '@mui/material/CircularProgress'
import {
  getAllDashboards,
  getDashboardById,
  getDashboardFilters,
} from '../../api/dashboards'
import Icon from '../widgets/Icon'
import Section from '../widgets/Section'
import CustomDashboard from './CustomDashboard'
import DataConfiguration from '../DataConfiguration'
import * as qs from 'query-string'
import i18next from 'i18next'
import Page from '../widgets/Page'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dashboards: null,
      showDashboard: false,
      currentDashboard: null,
      filtersToInclude: null,
      filters: {},
      params: {},
    }
    if (this.props.dashboard) {
      this.showDashboard(this.props.dashboard)
    } else if (this.props.match?.params.dashboard_id != null) {
      this.loadDashboard(this.props.match.params.dashboard_id)
    } else {
      this.getAllDashboards()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match?.params.dashboard_id != prevProps.match?.params.dashboard_id) {
      if (this.props.match?.params.dashboard_id) {
        this.loadDashboard(this.props.match.params.dashboard_id)
      } else {
        this.getAllDashboards()
      }
    }
  }
  
  loadDashboard(id) {
    getDashboardById(id).then(
      (dashboard) => {
        this.showDashboard(dashboard)
      }
    )
  }

  getAllDashboards() {
    const pagination = false
    getAllDashboards(
      pagination,
      [this.props.internal_client_id],
      'Dashboards'
    ).then((response) => {
      this.setState({ dashboards: response.results, filtersToInclude: null, params: {}, dashboardId: null, showDashboard: false, currentDashboard: null })
    })
  }

  selectDashboard(dashboard) {
    window.location.href = '/#/dashboards/' + dashboard.id
    this.showDashboard(dashboard)
  }

  showDashboard(dashboard) {
    if (dashboard == undefined) {
      this.setState({
        filtersToInclude: null,
        params: {},
        dashboardId: null,
        showDashboard: true,
        currentDashboard: null,
      })
    } else {
      getDashboardFilters(dashboard.id).then((response) => {
        this.setState({
          params: qs.parse(this.props.location.search),
          filtersToInclude: response ? response : {},
          dashboardId: dashboard.id,
          showDashboard: true,
          currentDashboard: dashboard,
          showDates: response ? ('date_range' in response ? 'range' : 'date' in response ? 'date' : null) : null
        })
      })
    }
  }

  renderDashboards = (sectionName, dashboards) => {
    if (dashboards.length == 0) {
      return null
    }
    return (
      <>
        <h3>{sectionName}</h3>
        <div className="dashboards">
          {dashboards.map((dashboard, i) => (<div key={i}><Icon name={dashboard.title} icon={dashboard.icon} onClick={() => this.selectDashboard(dashboard)} /></div>))}
        </div>
      </>
    )
  }

  renderPage = () => {
    if (this.state.showDashboard) {
      if (this.state.filtersToInclude != null) {
        return (
          <div>
            <CustomDashboard
              dashboard_id={this.state.dashboardId}
              params={this.state.params}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              compare_from={this.props.compare_from}
              compare_to={this.props.compare_to}
              showWrench={Object.keys(this.state.filtersToInclude).length > 0}
              dashboard={this.state.currentDashboard}
              filters={this.state.filters}
              extraFilters={Object.keys(this.state.filtersToInclude).map(k => this.state.filtersToInclude[k]).filter(k => k != null)}
              data_configuration={() => this.getDataConfiguration()}
              showDates={this.state.showDates}
            />
          </div>
        )
      } else {
        return null
      }
    } else if (this.state.dashboards) {
      const mostViewed = this.state.dashboards.filter(d => d.most_viewed)
      const client = this.state.dashboards.filter(d => !d.most_viewed && d.internal_client_id != null)
      const other = this.state.dashboards.filter(d => !d.most_viewed && d.internal_client_id == null)
      return (
        <div className="js_complete">
          {this.renderDashboards(i18next.t('dashboards.mostViewed'), mostViewed)}
          {this.renderDashboards(i18next.t('dashboards.clientDashboards', { client: this.props.client_name }), client)}
          {this.renderDashboards(i18next.t('dashboards.other'), other)}
        </div>
      )
    } else {
      return <CircularProgress className="progress" />
    }
  }

  getDataConfiguration() {
    if (Object.keys(this.state.filtersToInclude).length > 0) {
      return (
        <div>
          <DataConfiguration
            filtersToInclude={this.state.filtersToInclude}
            filterValues={this.state.filters}
            changed={(filters, dimension, fullValues, extraFilters) => this.setState({ filters, filtersToInclude: this.getFiltersToInclude(fullValues, extraFilters) })}
            attachToSideNav={false}
          />
        </div>
      )
    } else {
      return null
    }
  }

  getFiltersToInclude(filters, extraFilters) {
    const filtersToInclude = {}
    if (filters != null) {
      const keys = Object.keys(filters)
      keys.forEach(f => filtersToInclude[f] = null)
    }
    if (extraFilters != null) {
      extraFilters.forEach(f => filtersToInclude[f.name] = f)
    }
    return filtersToInclude
  }

  renderSection() {
    return (
      <Section
        style={{ height: this.props.dashboard ? null : 940 }}
        padding={0}
      >
        {this.state.showDashboard && (
          <Title
            menu="Dashboard"
            item={
              this.state.currentDashboard
                ? this.state.currentDashboard.title
                : i18next.t('dashboards.doesNotExist')
            }
            link="/dashboards"
          />
        )}
        <div className="section">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header with-border">
                  <div id="root">{this.renderPage()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
  }

  render() {
    if (this.props.hidePage) {
      return this.renderSection()
    } else {
      return (
        <Page>
          {this.renderSection()}
        </Page>
      )
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    client_name: state.users.user
      ? state.users.user.client.name
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
