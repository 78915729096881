import request from "./request";
import querystring from 'querystring'

// manage_dimensions
export function getDimensions(internal_client_id) {
  return request('/api/dimensions?internal_client_id=' + internal_client_id);
}

// manage_dimensions
export function editDimension(data) {
  return request('/api/dimensions', {}, 'PUT', data);
}

// dimensions
export function getDimensionSettings(internal_client_id) {
  return request('/api/dimensions/settings/' + internal_client_id);
}

// admin only
export function updateDimensionSettings(internal_client_id, data) {
  return request('/api/dimensions/settings/' + internal_client_id, {}, 'PUT', data);
}

// manage_dimensions
export function previewDimensionSummary(sql, table_data) {
  return request('/api/dimensions/preview_summary?' + querystring.stringify(table_data), {sql: sql}, 'POST', {sql: sql});
}

// manage_dimensions
export function previewDimensionDetails(sql, table_data) {
  return request('/api/dimensions/preview_details?' + querystring.stringify(table_data), {sql: sql}, 'POST', {sql: sql});
}

// manage_dimensions
export function exportDimensionDetails(sql, table_data) {
  return request('/api/dimensions/export_details?' + querystring.stringify(table_data), {sql: sql}, 'POST', {sql: sql});
}

// dimensions
export function fetchDailyDimensionsData(params) {
  return request('/api/dimensions/grouped?' + querystring.stringify(params));
}

// admin only
export function updateCharts(data) {
  return request('/api/dimensions/chart', {}, 'POST', data);
}

// dimensions
export function getCharts(internal_client_id) {
  return request('/api/dimensions/chart?internal_client_id='+internal_client_id);
}

// ab_tests, dashboard_admin, dashboards, exec_summary, performance, dimensions
export function fetchDimensionFilters(internal_client_id, options=null) {
  let optionString = '';
  if (options != null) {
    options.forEach((option) => {
      optionString += '&options=' + option;
    })
  }
  return request('/api/ads/dimension_filters?internal_client_id=' + internal_client_id + optionString);
}
