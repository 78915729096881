import { Component } from 'react'
import { createRunResult }  from '../api/workflows'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FTNotice from './FTNotice'
import MenuItem from '@mui/material/MenuItem'
import locale from '../api/locale'
import { filterInteger, filterFloat } from '../utilities/utils'
import i18next from 'i18next'
import { getIcons } from "../api/workflows"
import Select from '@mui/material/Select'

/*
  Used to add a row to a column.  Properties:
    table - the table to update
    primaryKeys - the primary keys for the table (a list of column names)
    columns - the columns for the table
      column - internal name of the column
      name - name to display to the user
      required - true if required
      identity - true if this is set automatically
      type (optional) - integer, float or other
      values (optional) - a list of possible values
        id - what will be used as the value
        name - what will be displayed to the user
    onClose - function which is passed null (if cancel) or the new object (if ok)
    title (optional) - defaults to 'Create Row'
    stepId (optional) - the worklow step that this is related to.
    defaultValues (optional) - default values to include in new rows (ex., internal_client_id)
*/
export default class AddRow extends Component {
  static defaultProps = {
    title: 'addRow.createRow',
    defaultValues: {},
  }

  constructor(props) {
    super(props)
    this.state = this.getColumnState()
    this.state.icon = ''
    this.state.icons = []
    this.loadIcons()
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns != prevProps.columns) {
      this.setState(this.getColumnState())
    }
  }

  loadIcons() {
    getIcons().then((result) => {
      this.setState({ icons: result.data })
    })
  }

  getColumnState() {
    const state = {}
    this.props.columns.forEach(column => {
      if (!column.identity && (!column.values || column.values.length == 0)) {
        state[column.column] = ''
      } else {
        state[column.column] = column.values[0].id
      }
    })
    return state
  }

  createRow() {
    for (let i = 0; i < this.props.columns.length; i++) {
      const column = this.props.columns[i]
      const value = this.state[column.column]
      if (column.required && (value == null || value.trim() == '')) {
        FTNotice(i18next.t('addRow.', {column: this.getColumnName(column)}), 15000)
        return
      }
    }
    let values = Object.assign({}, this.props.defaultValues)
    values = Object.assign(values, this.state)
    if (this.props.table) {
      const data = {
        table: this.props.table,
        primary_keys: this.props.primaryKeys,
        values: values,
      }
      createRunResult(this.props.stepId, data).then(response => {
        if (response.error) {
          FTNotice(response.error, 15000)
        } else {
          let data = null
          if (!response.data) {
            FTNotice('addRow.addedButNotSelected', 15000)
          } else if (response.data.length != 1) {
            FTNotice('addRow.addedButNotUnique', 15000)
          } else {
            data = response.data[0]
          }
          this.props.onClose(data)
        }
      })
  	} else {
        this.props.onClose(values)
  	}
  }
  
  getColumnName(column) {
    return column.name ? column.name : (locale.metrics[column.column] ? locale.metrics[column.column] : this.getName(column.column))
  }

  getName(value) {
    const splits = value.split('_')
    for (let i = 0; i < splits.length; i++) {
      splits[i] = splits[i].charAt(0).toUpperCase() + splits[i].slice(1)
    }
    return splits.join(' ')
  }

  updateColumn(column, event) {
    const state={}
    state[column.column] = event.target.value
    this.setState(state)
  }

  filterValue(column, event) {
    if (column.type == 'integer') {
      return filterInteger(event)
    } else if (column.type == 'float') {
      return filterFloat(event)
    }
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={() => this.props.onClose(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><b>{i18next.t(this.props.title)}</b></DialogTitle>
        <DialogContent>
          <table>
            <tbody>
            {this.props.columns.map((column, i) => {
              if (!column.identity) {
                return (
                  <tr key={i}>
                    <td style={{padding: 5, textAlign: 'right'}}>{this.getColumnName(column)}{column.required ? '*' : ''}</td>
                    <td style={{padding: 5}}>
                      {column.type == 'icon' && (
                        <Select
                          className="center"
                          value={this.state.icon}
                          onChange={(event) => {
                            this.setState({ icon: event.target.value })
                          }}
                          autoWidth
                        >
                          {this.state.icons.map((icon, i) => (
                            <MenuItem
                              key={i}
                              value={icon}
                              style={{ width: "100px", height: "100px" }}
                            >
                              <img
                                src={"/resources/images/workflow_icons/" + icon}
                                className="dashboardIcon"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      {(column.type != 'icon' && column.values && (
                        <select className="form-control" onChange={(event) => this.updateColumn(column, event)}>
                          {column.values.map((value, i) => {
                            return (
                              <option key={i} value={value.id}>{i18next.t(value.name)}</option>
                            )
                          })}
                        </select>
                      ))}
                      {column.type != 'icon' && !column.values && (
                        <input className="form-control" value={this.state[column.column]} onKeyUp={event => this.filterValue(column, event)} onChange={(event) => this.updateColumn(column, event)}/>
                      )}                   
                    </td>
                  </tr>
                );
              }
            })}
            </tbody>
          </table>
          <div style={{textAlign: 'center'}}>{i18next.t('addRow.required')}</div>
        </DialogContent>
        <div className="text-center" style={{marginBottom: 20}}>
          <button className="btn btn-primary" onClick={() => this.createRow()}>{i18next.t('addRow.ok')}</button>
          <button className="btn btn-primary" onClick={() => this.props.onClose(null)} style={{marginLeft: 10}}>{i18next.t('addRow.cancel')}</button>
        </div>
      </Dialog>
    )
  }
}
