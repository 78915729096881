import request from './request'
import querystring from 'querystring'

// exec_summary, dimensions, dashboard_admin
export function addEditChartAnnotations(data, location, location_id) {
  return request(
    '/api/dashboards/chart_annotations/' + location + '/' + location_id,
    {},
    'POST',
    data
  )
}

// exec_summary, dimensions, dashboard_admin, dashboards
export function getChartAnnotations(location, location_id) {
  return request(
    '/api/dashboards/chart_annotations/' + location + '/' + location_id
  )
}

// dashboard_admin
export function getAllSql(pagination, internalClientId, tableData) {
  const url =
    internalClientId == ''
      ? '/api/dashboards/sql?pagination=' + pagination
      : '/api/dashboards/sql?internal_client_id=' +
        internalClientId +
        '&pagination=' +
        pagination
  return request(url + '&' + querystring.stringify(tableData))
}

// dashboard_admin
export function addSql(data) {
  return request('/api/dashboards/sql', {}, 'POST', data)
}

// dashboard_admin, dashboards
export function getSqlById(id) {
  return request('/api/dashboards/sql/' + id)
}

// dashboard_admin
export function editSql(data) {
  return request('/api/dashboards/sql/' + data.id, {}, 'PUT', data)
}

// dashboard_admin
export function removeSql(id) {
  return request('/api/dashboards/sql/' + id, {}, 'DELETE', {})
}

// dashboard_admin
export function executeSql(id, sql_params, table_params = {}) {
  const url = '/api/dashboards/sql/' + id + '/execute'
  return request(url, {}, 'POST', Object.assign({}, sql_params, table_params))
}

// dashboard_admin
export function exportSql(id, sql_params, table_params = {}) {
  const url = '/api/dashboards/sql/' + id + '/export'
  return request(url, {}, 'POST', Object.assign(sql_params, table_params))
}

// dashboard_admin
export function exploreSQL(internalClientId, tableData) {
  const url =
    '/api/dashboards/sql/explore?internal_client_id=' + internalClientId
  return request(url + '&' + querystring.stringify(tableData))
}

// dashboard_admin, dashboards
export function getAllDashboards(
  pagination,
  internalClientIds,
  location,
  tableData,
  clientOnly = false
) {
  let url = `/api/dashboards?pagination=${pagination}&client_only=${clientOnly}`
  const ids = internalClientIds.map(id => `internal_client_id=${id}`).join('&')
  if (ids != '') {
    url += '&' + ids
  }
  if (location) {
    url += '&location=' + location
  }
  return request(url + '&' + querystring.stringify(tableData))
}

// dashboard_admin
export function addDashboard(data) {
  return request('/api/dashboards', {}, 'POST', data)
}

// dashboard_admin, dashboards
export function getDashboardById(id) {
  return request('/api/dashboards/' + id)
}

// dashboard_admin
export function editDashboard(data) {
  return request('/api/dashboards/' + data.id, {}, 'PUT', data)
}

// dashboard_admin
export function removeDashboard(id) {
  return request('/api/dashboards/' + id, {}, 'DELETE', {})
}

// dashboard_admin, dashboards
export function getDashboardFilters(dashboardId) {
  return request('/api/dashboards/' + dashboardId + '/filters')
}

// dashboard_admin
export function getIcons() {
  return request('/api/dashboards/icons')
}
