import {useState} from 'react'
import Box from '@mui/material/Box'
import i18next from 'i18next'

const MonthSelector = ({month, updateMonth}) => {
  const [showMonths, setShowMonths] = useState(false)
  const monthNames = [
    i18next.t('budget.january'),
    i18next.t('budget.february'),
    i18next.t('budget.march'),
    i18next.t('budget.april'),
    i18next.t('budget.may'),
    i18next.t('budget.june'),
    i18next.t('budget.july'),
    i18next.t('budget.august'),
    i18next.t('budget.september'),
    i18next.t('budget.october'),
    i18next.t('budget.november'),
    i18next.t('budget.december'),
  ]
  const months = []
  for (let i = -6; i <= 6; i++) {
    const date = new Date()
    date.setDate(1) // Not all months have a 31st so go with a date that all months have
    const monthDate = new Date(date.setMonth(date.getMonth() + i))
    months.push({
      id: monthDate,
      name: monthNames[monthDate.getMonth()] + " " + monthDate.getFullYear(),
    })
  }
  
  return (
    <Box
      className="btn btn-default"
      style={{ cursor: "pointer", width: 120, backgroundColor: '#7b1fa2', color: 'white' }}
      onClick={() => setShowMonths(!showMonths)}
    >
      {monthNames[month.getMonth()] + " " + month.getFullYear()}
      {showMonths && (
        <Box
          style={{
            position: "absolute",
            backgroundColor: "white",
            border: "1px solid black",
            width: 120,
            left: 63,
            top: 33,
            zIndex: 10,
          }}
        >
          {months.map((month, i) => (
            <option
              onClick={() => {updateMonth(month)}}
              key={i}
              style={{ color: "black", padding: 5 }}
            >
              {month.name}
            </option>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default MonthSelector