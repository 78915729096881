import React, { useState, useEffect } from 'react'
import withRouter from '../../wrappers/withRouter'
import { connect } from 'react-redux'
import Table from '../widgets/Table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import querystring from 'querystring'
import DatePicker from '../widgets/DatePicker'
import { encode, decode } from '../../api/encoding'
import { getAccountChanges } from '../../api/account_changes'
import { formatPercentNoDecimal } from '../../utilities/formatting'
import i18next from 'i18next'

const Comparison = ({location, history, internal_client_id}) => {
  const tabs = [
    {id: 'pop', name: false, text: i18next.t('comparison.periodOverPeriod')},
    {id: 'yoy', name: true, text: i18next.t('comparison.yearOverYear')},
  ]
  const [tabIndex, setTabIndex] = useState()
  const [dates, setDates] = useState()

  useEffect(() => {
    const config = getConfig()
    setTabIndex(config['tabIndex'] == null ? 0 : config['tabIndex'])
    const newFromDate = config['fromDate'] == null ? moment().subtract(7, 'day').format('YYYY-MM-DD') : config['fromDate']
    const newToDate = config['toDate'] == null ? moment().format('YYYY-MM-DD') : config['toDate']
    if (dates == null || dates.fromDate != newFromDate || dates.toDate != newToDate) {
      setDates({
        fromDate: newFromDate,
        toDate: newToDate
      })
    }
  }, [location])

  useEffect(() => {
    if (tabIndex != null && dates != null) {
      updateUrl()
    }
  }, [tabIndex, dates])
  
  const getConfig = () => {
    let config = {}
    const pathname = location.pathname
    if (pathname != '/comparison') {
      const paths = pathname.replace("/comparison","").split('/')
      if (paths[1]) {
        config = JSON.parse(decode(paths[1]))
      }
    }
    return config
  }

  const updateTab = (index) => {
    setTabIndex(index)
  }

  const updateUrl = (source_path=location.pathname) => {
    const params  = $('#account-changes').serialize()
    let json = querystring.parse(params)
    json = Object.assign({}, json)
    json.tabIndex = tabIndex
    json.fromDate = dates.fromDate
    json.toDate = dates.toDate
    const filters = encode(JSON.stringify(json))
    const path = source_path.split('/')
    path[2] = filters
    const newPath = path.join('/')
    if (newPath != location.pathName) {
      history(newPath)
    }
  }

  const updateDates = (newDates) => {
    const newFromDate = newDates.start
    const newToDate = newDates.end
    if (dates.fromDate != newFromDate || dates.toDate != newToDate) {
      setDates({
        fromDate: newFromDate,
        toDate: newToDate
      })
    }
  }

  const colorize = (inverse=false) => {
    return {
      'positive': (params) => {return (inverse ? -params.value : params.value) > 30},
      'negative': (params) => {return (inverse ? -params.value : params.value) < -30},
    }
  }

  const colorizeInverse = () => {
    return colorize(true)
  }

  const getColumns = () => {
    return [{
      id: 'engine',
      datatype: 'option',
      width: 80,
      options: ['Google', 'Bing']
    }, {
      id: 'entity_type',
      datatype: 'text',
      options: ['Keword', 'Pla', 'Image', 'Dsa', 'Video'],
      width: 80,
    }, {
      id: 'device',
      datatype: 'options',
      options: ['Desktop', 'Tablet', 'Mobile'],
      width: 80,
    }, {
      id: 'clicks_to_total',
      datatype: 'numeric',
      width: 85,
      format: formatPercentNoDecimal,
    }, {
      id: 'revenue_to_total',
      datatype: 'numeric',
      width: 100,
      format: formatPercentNoDecimal,
    }, {
      id: 'cost_to_total',
      datatype: 'numeric',
      width: 80,
      format: formatPercentNoDecimal,
    }, {
      id: 'clicks',
      datatype: 'numeric',
      width: 75,
    }, {
      id: 'cpc',
      datatype: 'numeric',
      width: 65,
    }, {
      id: 'cost',
      datatype: 'numeric',
      width: 90
    }, {
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 135,
    }, {
      id: 'internal_roas',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'clicks_pop',
      datatype: 'numeric',
      width: 120,
      format: formatPercentNoDecimal,
    }, {
      id: 'cost_pop',
      datatype: 'numeric',
      classRules: colorizeInverse(),
      width: 110,
      format: formatPercentNoDecimal,
    }, {
      id: 'revenue_pop',
      datatype: 'numeric',
      classRules: colorize(),
      width: 130,
      format: formatPercentNoDecimal,
    }, {
      id: 'roas_pop',
      datatype: 'numeric',
      classRules: colorize(),
      width: 140,
      format: formatPercentNoDecimal,
    }, {
      id: 'cpc_pop',
      datatype: 'numeric',
      width: 110,
      format: formatPercentNoDecimal,
    }]
  }
  
  const getData = (yoy, tableData) => {
    return getAccountChanges(internal_client_id, dates.fromDate, dates.toDate, yoy, tableData)
  }
  
  if (dates == null) {
    return null
  }
  return (
    <div className="site-canvas">
      <section className="content">
        <div className="page-nav" style={{position: 'relative'}}>
          <div className="btn-group" style={{width: '100%'}}>
            <DatePicker
              start={dates.fromDate}
              end={dates.toDate}
              showCompare={false}
              callback={(newDates) => updateDates(newDates)}
              restrictedDatePicker={true}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <br/>
        <Tabs selectedIndex={tabIndex} onSelect={tabIndex => updateTab(tabIndex)}>
          <TabList>
            {tabs.map((tab) => {
              return (
                <Tab key={tab.name}>
                  {tab.text}
                </Tab>
              )
            })}
          </TabList>

          <div className="row">
            <div className="col-md-12">
              <div className="box box-info js_complete">
                {tabs.map((tab) => {
                  return (
                    <TabPanel key={tab.name}>
                      <Table
                        tableName={'comparison'}
                        fetch={(tableData) => getData(tab.id == 'yoy', tableData)}
                        columns={getColumns()}
                        changeParams={dates} />
                    </TabPanel>
                  )
                })}
              </div>
            </div>
          </div>
        </Tabs>
      </section>
    </div>
  )
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Comparison))
