import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { getPipelines } from "../../api/workflows"
import History from './workflow/History'
import { Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Section from '../widgets/Section'
import FtoButton from '../widgets/Button'
import FtoTabs from '../widgets/Tabs'
import FtoTab from '../widgets/Tab'
import Page from '../widgets/Page'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

class Workflows extends Component {
  static defaultProps = {
    type: 'workflows',
  }

  constructor(props) {
    super(props);
    this.state = {
      workflows: null,
      tabIndex: this.props.match && this.props.match.params.tab == 'history' ? 1 : 0,
      tabs: [
        { name: 'all', text: 'Workflows' },
        { name: 'history', text: 'History' }
      ]
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    getPipelines(this.props.internal_client_id, this.props.type)
      .then(response => {
        this.setState({ workflows: response.data });
      })
  }

  updateTab(index) {
    this.props.history('/workflows/' + this.state.tabs[index].name);
    this.setState({ tabIndex: index });
  }

  renderPipelines() {
    if (this.state.workflows != null) {
      return (
        this.state.workflows.map((workflow, i) => {
          var img_url = workflow.icon != null && workflow.icon != '' ? '/resources/images/workflow_icons/' + workflow.icon : null;
          return (
            <div className="col-md-2 mt-50 center" style={{ height: 210 }} key={i}>
              <Link to={"/workflows/notebook/" + workflow.id + "/run?referer=" + location.hash.substring(2)}>
                {img_url && (
                  <img src={img_url}
                    height="100" width="100" />
                )}
                <h4 className="truncate-overflow">{workflow.name}</h4>
              </Link>
            </div>
          )
        })
      )
    } else {
      return null
    }
  }

  renderPage() {
    return (
      <Page>
        <Section direction='column' maxHeight='auto'>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
              <FtoTabs textColor='primary' value={this.state.tabIndex} onChange={(event, newIndex) => this.updateTab(newIndex)}>
                {this.state.tabs.map((tab) => (
                  <FtoTab label={tab.text} key={tab.name} />
                ))}
              </FtoTabs>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FtoButton component={RouterLink}
                to='/workflows/add_edit/new'
                buttonText='workflows.addNewWorkflow'
                startIcon={<LibraryAddIcon />} />
            </Box>
          </Box>
          {this.state.tabIndex == 0 ?
            <Box sx={{ display: 'flex', maxHeight: 'auto', flexWrap: 'wrap', overflowY: 'scroll' }}>
              {this.renderPipelines()}
            </Box>
            : null}
          {this.state.tabIndex == 1 ?
            <Box>
              <History
                internal_client_id={this.props.internal_client_id}
                workflow_id={0}
                history={this.props.history} />
            </Box> : null}
        </Section >
      </Page>
    )
  }

  render() {
    if (this.props.type == 'workflows') {
      return (
        <div className='site-wrapper-offcanvas' id='chart-hudd'>
          <div className='site-canvas' style={{ height: 'auto' }}>
            <section className='content'>
              {this.renderPage()}
            </section>
          </div>
        </div>
      )
    } else {
      return (
        <div className='box'>
          <div className="container js_complete" style={{ width: '80%', minHeight: 520 }}>
            {this.renderPipelines()}
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default withRouter(connect(mapStateToProps)(Workflows))
