import Dialog from '../../widgets/Dialog'
import Button from '../../widgets/Button'

const ConfirmPublishSubset = (props) => {
  const handleConfirm = () => {
    props.onClick(true)
  }

  const handleCancel = () => {
    props.onClick(false)
  }

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={handleCancel}
      icon="warning"
      title="biddingSummary.confirm"
      text="biddingSummary.confirmDelete"
      buttons={[
        <Button buttonText="biddingSummary.ok" onClick={handleConfirm} color="primary" variant="outlined" />,
        <Button buttonText="biddingSummary.cancel" onClick={handleCancel} color="primary" variant="outlined" />
      ]}
    />
  )
}

export default ConfirmPublishSubset