import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'

const PromptDeveloper = ({ status,
  promptDraft,
  requiredAlert,
  conversation,
  disabled,
  responseOutput,
  aiBot,
  userName,
  initialPackagedPrompt,
  promptThread,
  activeTab,
  demoComplete,
  sentence,
  user,
  hidePromptInput,
  promptEngDraft,
  lastPromptEngDraft,
  lastPrompt,
  conversationId,
  startingTime,
  lastResponseOutput,
  sql,
  duration,
}) => {

  const rows = [
    { name: 'sql', value: sql },
    { name: 'duration', value: duration },
    { name: 'conversationId', value: conversationId },
    { name: 'conversation', value: conversation },
    { name: 'promptThread', value: promptThread },
  ]

  const renderValue = value => {
    if (Array.isArray(value)) {
      return JSON.stringify(value)
    } else if (typeof value === 'object') {
      return JSON.stringify(value)
    } else if (typeof value === 'function') {
      return 'Function'
    } else if (typeof value === 'boolean') {
      return JSON.stringify(value)
    } else {
      return value
    }
  }
  
  return (
    <Box sx={{
      borderRadius: 5,
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      height: '70vh',
      overflowY: 'auto',
    }}>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 800, minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell>{i18next.t('ai.variable')}</TableCell>
              <TableCell align="left">{i18next.t('ai.value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'><Typography sx={{ color: 'grey.900' }}>{row.name}</Typography></TableCell>
                <TableCell component='th' scope='row'>
                  {row.name == 'sql' && row.value != '' && (
                    <>
                      {JSON.parse(row.value).map((sql, i) => (
                        <SQLEditor key={i} code={sql} readOnly={true} />
                      ))}
                    </>
                  )}
                  {row.name != 'sql' && (
                    <Typography sx={{ color: 'grey.900' }}>{renderValue(row.value)}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PromptDeveloper