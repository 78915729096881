import { ToggleButtonGroup } from "@mui/material"

/*
This uses an MUI ToggleButtonGroup (https://mui.com/material-ui/api/toggle-button-group/).  Any of those properties can be passed through.  Invididual Toggle tags
should be children of the Toggles tag.
See defaultProps below for changes to default properties / notes on property use.
*/
const FtoToggles = (props) => {
  const defaultProps = {
    color: 'primary',
    exclusive: true
  }
  const revisedProps = Object.assign(defaultProps, props)
  const {labelText, ...togglesProps} = revisedProps
  return (
    <ToggleButtonGroup {...togglesProps} />
  )
}

export default FtoToggles