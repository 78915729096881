import request from './request';
import querystring from 'querystring';

// performance
export function getPerformanceData(tableData) {
  return request('/api/ads/performance?' + querystring.stringify(tableData));
}

// performance
export function exportPerformanceData(tableData) {
  return request('/api/ads/performance?export=true&' + querystring.stringify(tableData));
}
