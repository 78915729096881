const styles = {
  node: {
    padding: 10,
    marginTop: 25,
    marginBottom: 25,
    height: 60,
    width: 100,
    backgroundColor: 'white',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  revenue: {
    padding: 10,
    marginTop: 10,
    marginBottom: 5,
    height: 60,
    width: 100,
    backgroundColor: 'white',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  checkbox: {
    cursor: 'pointer',
    position: 'absolute',
    width: 180,
    '& input': {
      verticalAlign: 'top',
      marginRight: 5
    }
  },
  databaseContainer: {
    paddingTop: 20,
    height: 110,
    width: 100,
    textAlign: 'center',
    cursor: 'auto'
  },
  database: {
    height: 50
  },
  trainingContainer: {
    paddingTop: 20,
    height: 110,
    width: 100,
    textAlign: 'center',
    cursor: 'auto'
  },
  training: {
    height: 50
  },
  predictContainer: {
    paddingTop: 20,
    height: 110,
    width: 100,
    textAlign: 'center',
    cursor: 'auto'
  },
  predict: {
    height: 50
  },
  featureExtractContainer: {
    paddingTop: 20,
    height: 110,
    width: 100,
    textAlign: 'center',
    cursor: 'auto'
  },
  featureExtract: {
    height: 50
  },
  bucketContainer: {
    paddingTop: 20,
    height: 110,
    width: 100,
    textAlign: 'center',
    cursor: 'auto'
  },
  bucket: {
    height: 50
  },
  button: {
    marginTop: -20,
    marginLeft: 15
  }
}
  
export default styles
