import request from './request';

// time
export function listEntries(userId, date) {
  return request('/api/time_tracking/' + userId + '/' + date);
}

// time
export function updateEntries(userId, date, data) {
  return request('/api/time_tracking/' + userId + '/' + date, {}, 'POST', data);
}
