import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAlgos } from '../../api/bidding'
import Table from '../widgets/Table'
import i18next from 'i18next'

class BiddingAlgos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
  }

  getColumns() {
    return [
      { id: 'name',
        datatype: 'text',
        width: 200,
        cellRenderer: (value, row) => {return <Link to={'/bidding/algo/'+ row.id} > {value} </Link>}
      }, {
        id: 'description',
        datatype: 'text',
        width:600
      },
    ]
  }

  updateTab(index) {
    this.setState({tabIndex: index})
  }

  render() {
    return (
      <div className='site-canvas'>
        <Link to={'/bidding/algo'} className="btn btn-box-tool text-green js_complete" style={{'marginLeft': '92%'}}>
          <i className="fa fa-plus"></i>
          <span>{i18next.t('algos.addNewAlgorithm')}</span>
        </Link>
        <Table
          fetch={(tableData) => {return getAlgos(this.props.internal_client_id, tableData)}}
          columns={this.getColumns()} />
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(BiddingAlgos)
