  import DashboardBidSummary from '../simpleBiddingView/DashboardBidSummary'

const BidSummary = ({lookbackDuration, bidding_run_id, isDraft, setPublishingProgressCircle, setGeneratingRevertProgressCircle, setBidSummaryOpen, onUpdate, publish, isAdmin, props }) => {
  return (
    <div>
      <DashboardBidSummary
        publish={publish}
        onUpdate={onUpdate}
        bidding_run_id={bidding_run_id}
        isDraft={isDraft}
        disabled={false}
        setPublishingProgressCircle={setPublishingProgressCircle}
        setGeneratingRevertProgressCircle={setGeneratingRevertProgressCircle}
        setBidSummaryOpen={setBidSummaryOpen}
        lookbackDuration={lookbackDuration}
        props={props}
        isAdmin={isAdmin}
        schedule={props.schedule}
      />
    </div>
  )
}

export default BidSummary