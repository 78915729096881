import SolidGauge from '../../charts/SolidGauge'

const Significance = ({ settings, values }) => {
  return (
    <div>
      <div className="box">
        <div className="box-body">
          {settings.map((metric, i) => {
            const key = Object.keys(metric)[0]
            const show = metric[key]
            if (show) {
              return (
                <div key={i} className="col-xs-3">
                  <SolidGauge title={'abtest.metrics.' + key} value={values[key + '_probability']} />
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Significance