import { FormControl, FormLabel, RadioGroup } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI RadioGroup (https://mui.com/material-ui/api/radio-group/).  Any of those properties can be passed through.  Invididual RadioButton tags
should be children of the RadioButtons tag.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  groupText - the label to use for the group (this should generally be an i18n key)
*/
const FtoRadioButtons = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const {groupText, ...radioButtonsProps} = revisedProps
  return (
    <FormControl>
      {groupText && (
        <FormLabel>{i18next.t(groupText)}</FormLabel>        
      )}
      <RadioGroup {...radioButtonsProps}/>
    </FormControl>
  )
}

export default FtoRadioButtons