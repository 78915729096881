import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { listEntries, updateEntries } from '../../api/time_tracking.js'
import DeleteButton from '../DeleteButton'
import FTNotice from '../FTNotice'
import Table from '../widgets/Table'
import AddTask from './AddTask'
import _ from "lodash"

class Interface extends Component {
  tableRef = createRef()
  
  constructor(props) {
    super(props)
    this.state = {
      date: this.props.match.params.date ? this.props.match.params.date : moment().startOf('isoWeek').format('YYYY-MM-DD'),
      entries: [],
    }
  }

  componentDidMount() {
    this.updateWeek()
  }

  previousWeek() {
    this.setState({date: moment(this.state.date).subtract(7, 'days').format('YYYY-MM-DD'), entries: []}, () => this.updateWeek())
  }

  nextWeek() {
    this.setState({date: moment(this.state.date).add(7, 'days').format('YYYY-MM-DD'), entries: []}, () => this.updateWeek())
  }

  updateWeek() {
    window.location.href = '/#/time_tracking/' + this.state.date
    listEntries(this.props.userId, this.state.date).then(entries => {
      const tasks = []
      const tasksById = {}
      const totals = {task_name: 'Total', total: 0}
      this.getDateColumns().forEach(column => {
        totals[column] = 0
      })
      entries.forEach(entry => {
        let task = tasksById[entry['task_id']]
        if (task == null) {
          task = {
            internal_client_id: entry['internal_client_id'],
            client_name: entry['client_name'],
            task_id: entry['task_id'],
            task_name: entry['task_name'],
            total: 0
          }
          tasks.push(task)
          tasksById[entry['task_id']] = task
        }
        const diff = this.getValidValue(entry['hours'])
        task[entry['date']] = this.getValidValue(task[entry['date']]) + diff
        task.total += diff
        totals[entry['date']] += diff
        totals.total += diff
      })
      tasks.sort((a, b) => {
        const sort = a.client_name.localeCompare(b.client_name)
        return sort == 0 ? a.task_name.localeCompare(b.task_name) : sort
      })
      tasks.push(totals)
      this.setState({entries: tasks})
    })
  }

  updateHours(entry, date, hours) {
    let value = hours
    this.removeInvalidValue(entry, date)
    if (hours != null && hours != '') {
      if(hours.split('.').length > 2) {
        FTNotice('Invalid Value: ' + hours, 5000)
        this.addInvalidValue(entry, date)
      } else {
        const floatValue = parseFloat(hours)
        if (hours.indexOf(':') >= 0 || isNaN(floatValue)) {
          const splits = hours.split(':')
          if (splits.length != 2 || splits[0].indexOf('.') >= 0 || splits[1].indexOf('.') >= 0 || splits[0]=='' || splits[1]=='') {
            FTNotice('Invalid Value: ' + hours, 5000)
            this.addInvalidValue(entry, date)
          } else {
            value = parseInt(splits[0]) + Math.round(parseInt(splits[1]) / 60.0 * 100) / 100
          }
        } else {
          value = floatValue
        }
        if (value == 0) {
          value = null
        } else if (value > 24) {
          FTNotice('Must be less than or equal to 24.', 5000)
          this.addInvalidValue(entry, date)
        }
      }
    } else {
      value = null
    }
    const oldValue = entry[date]
    entry[date] = value
    const diff = this.getValidValue(value) - this.getValidValue(oldValue)
    entry.total += diff
    const totals = this.state.entries[this.state.entries.length - 1]
    totals[date] += diff
    totals.total += diff
    this.submit()
    const table = this.tableRef.current
    if (table) {
      table.refreshCells()
    }
  }

  addInvalidValue(entry, date) {
    if(!entry.invalidEntries) {
      entry.invalidEntries = [date]
    } else if(entry.invalidEntries.indexOf(date) < 0) {
      entry.invalidEntries.push(date)
    }
  }

  removeInvalidValue(entry, date) {
    if(entry.invalidEntries) {
      const index = entry.invalidEntries.indexOf(date)
      if(index >= 0) {
        entry.invalidEntries.splice(index, 1)
      }
    }
  }

  debounce = _.debounce((entry, date, hours) => this.updateHours(entry, date, hours), 500)

  filterFloatPlusColon(event) {
    event.target.value = event.target.value.replace(/[^0-9\.\:]/g,'')
  }

  formatTime(value) {
    if (!isNaN(value) && !_.isString(value)) {
      if (value == 0) {
        return ''
      } else {
        const hours = Math.floor(value)
        const fraction = value - hours
        const minutes = Math.round(fraction * 60)
        return hours + ':' + (minutes < 10 ? '0' : '') + minutes
      }
    } else {
      return value
    }
  }

  getColumns() {
    const columns = [{
      id: 'client_name',
      header: 'Client',
      datatype: 'text',
    }, {
      id: 'task_name',
      header: 'Task',
      datatype: 'text',
    }]
    const date = moment(this.state.date)
    const dayOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    for (let i = 0; i < 7; i++) {
      const current = i > 0 ? date.add(1, 'days') : date
      const column = current.format('YYYY-MM-DD')
      columns.push({
        id: column,
        header: dayOfWeek[i] + ' ' + current.format('M/D'),
        datatype: 'number',
        width: 100,
        format: value => this.formatTime(value),
        cellRenderer: (value, row) => (
          row.internal_client_id == null ? <span>{value}</span> :
          <input style={{borderColor: (row.invalidEntries && row.invalidEntries.indexOf(column) >= 0) ? 'red' : null}}
            tabIndex={1 + this.state.entries.indexOf(row) * 7 + i}
            className="form-control"
            size="2"
            defaultValue={value}
            onKeyUp={event => this.filterFloatPlusColon(event)}
            onChange={(event) => this.debounce(row, column, event.target.value)}/>
        )
      })
    }
    columns.push({
      id: 'total',
      datatype: 'number',
      format: value => this.formatTime(value)
    })
    columns.push({
      cellRenderer: (_, row) => (
        row.internal_client_id == null ? null :
        <DeleteButton
          objectType=""
          objectName={row.task_name}
          deleteObject={() => this.deleteRow(row)}/>
      ),
      width:100,
    })
    return columns
  }

  addRow(row) {
    if (row) {
      const entries = this.state.entries.slice()
      entries.splice(entries.length - 1, 0, row)
      this.setState({entries: entries, showCreate: false})
    } else {
      this.setState({showCreate: false})
    }
  }

  deleteRow(row) {
    const totals = this.state.entries[this.state.entries.length - 1]
    this.getDateColumns().forEach(column => {
      totals[column] -= this.getValidValue(row[column])
    })
    totals.total -= row.total

    const entries = this.state.entries.slice()
    entries.splice(entries.indexOf(row), 1)
    this.setState({entries: entries}, () => this.submit())
  }

  getDateColumns() {
    const columns = []
    const date = moment(this.state.date)
    for (let i = 0; i < 7; i++) {
      const current = i > 0 ? date.add(1, 'days') : date
      columns.push(current.format('YYYY-MM-DD'))
    }
    return columns
  }

  isValid(value) {
    return value != null && !_.isString(value)
  }

  getValidValue(value) {
    return this.isValid(value) ? value : 0
  }

  submit() {
    const entries = []
    let fail = false
    const dateColumns = this.getDateColumns()
    this.state.entries.forEach(entry => {
      if (entry.internal_client_id != null) {
        const taskId = entry.task_id
        let hasEntry = false
        dateColumns.forEach(column => {
          const hours = entry[column]
          if (this.isValid(hours) && hours != 0) {
            entries.push({
              date: column,
              task_id: taskId,
              hours: hours
            })
            hasEntry = true
          }
        })
        if (!hasEntry) { // Don't delete old entries
          entries.push({
              date: dateColumns[0],
              task_id: taskId,
              hours: 0
          })
        }
      }
    })
    if (!fail) {
      updateEntries(this.props.userId, this.state.date, entries).then(entries => {
        if (!entries) {
          FTNotice('Unable to submit time entries', 3000)
        }
      })
    }
  }

  render() {
    return (
      <div className="site-canvas">
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <i style={{cursor: 'pointer'}} className="fa fa-chevron-left" onClick={() => {this.previousWeek()}}/>
                  <span style={{fontSize: 24, paddingLeft: 10, paddingRight: 10}}>Week of {moment(this.state.date).format('M/D/YYYY')}</span>
                  <i style={{cursor: 'pointer'}} className="fa fa-chevron-right" onClick={() => {this.nextWeek()}}/>
                  <div style={{float: 'right'}}>
                    <a className="btn btn-box-tool text-green" style={{paddingTop: 0}} onClick={(event) => {
                      event.preventDefault()
                      this.setState({showCreate: true})
                    }}>
                      <i className="fa fa-plus js_complete"></i>
                      <span>Add Task</span>
                    </a>
                    { this.state.showCreate && (
                      <AddTask action={task => this.addRow(task)} />
                    )}
                  </div>
                </div>
                <Table
                  ref={this.tableRef}
                  dataset={this.state.entries}
                  columns={this.getColumns()}
                  autoHeight={true}
                  rowHeight={45}
                  hidePagination={true}
                  disableCellSelection={true}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    userId: state.users.user ? state.users.user.id : null
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
