import { ThemeProvider, CssBaseline, Box, Paper } from '@mui/material'
import { theme } from './theme'

/*
A page within the application.

Props:
  children - the children to include (just add within the tag).
*/

const FTOPage = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", justifyContent: "center", width: "95vw", padding: 2, margin: "auto" }} className="site-canvas">
        <Paper elevation={3}>
          <Box sx={{
            padding: 2,
            width: "95vw",
            display: "flex",
            flexDirection: "column"
          }} children={props.children} className="js_complete" />
        </Paper>
      </Box>
    </ThemeProvider>
  )
}

export default FTOPage