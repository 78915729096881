import { createTheme } from "@mui/material/styles"
import { grey, blueGrey, purple, pink, green, cyan, lime } from "@mui/material/colors"
// highest parent background color found in AdminLTE.css line 117
// date-picker color (hover and default) found in AdminLTE.css line 5369

// mui theme settings
export const theme = createTheme({
  palette: {
    primary: {
      // #7b1fa2
      main: purple[700],
      light: purple[300],
    },
    secondary: {
      // #455a64
      main: blueGrey[700],
      chatBubble: blueGrey[100],
    },
    accent: {
      // #90a4ae 
      main: blueGrey[100],
      dark: blueGrey[900],
    },
    info: {
      // #006064
      main: cyan[900],
    },
    success: {
      // #46A21F
      main: "#1FA27B",
    },
    warning: {
      // #e2ad0f
      main: "#e2ad0f",
    },
    error: {
      // #a2391f 
      main: "#A2391F",
    },
    background: {
      default: grey[200],
      paper: grey[100],
    },
    grey: {
      100: grey[100],
      200: grey[200],
      300: grey[300],
      400: grey[400],
      500: grey[500],
      600: grey[600],
      700: grey[700],
      800: grey[800],
      900: grey[900],
    },
    red: {
      100: "#ecd7d2",
      200: "#dab0a5",
      300: "#c78879",
      400: "#b5614c",
      500: "#a2391f",
      600: "#822e19",
      700: "#612213",
      800: "#41170c",
      900: "#200b06"
    },
    yellow: {
      100: "#f9efcf",
      200: "#f3de9f",
      300: "#eece6f",
      400: "#e8bd3f",
      500: "#e2ad0f",
      600: "#b58a0c",
      700: "#886809",
      800: "#5a4506",
      900: "#2d2303"
    },
    white: {
      primary: '#ffffff',
      100: "#ffffff",
    },
    tonalOffset: 0.2,
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    htmlFontSize: 14,
    fontSize: 18,
    h1: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "6rem",
    },
    h2: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "5rem",
    },
    h3: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "4rem",
    },
    h4: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "3rem",
    },
    h5: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "2rem",
    },
    h6: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "1.5rem",
    },
    metricValue: {
      fontFamily: ['Open Sans'],
      fontSize: '3.8rem',
      fontWeight: 800
    },
    metricLabel: {
      fontFamily: ['Open Sans'],
      fontSize: '1.8rem',
      fontWeight: 700
    },
    metricPercentChange: {
      fontFamily: ['Open Sans'],
      fontSize: '1.7rem',
      fontWeight: 700
    },
    metricCompareValue: {
      fontFamily: ['Open Sans'],
      fontSize: '1.7rem',
      fontWeight: 500
    },
    fieldResearch: {
      fontFamily: ['EB Garamond'],
      fontSize: '3rem',
      fontWeight: 600
    },
    dashboardCardTitle: {
      fontFamily: ['Open Sans'],
      fontSize: '1.5rem',
      fontWeight: 300
    },
    dashboardTextLabel: {
      fontFamily: ['Open Sans'],
      fontSize: '1.5rem',
      fontWeight: 400
    },
    dashboardCardKPI: {
      fontFamily: ['Open Sans'],
      fontSize: '2.5rem',
      fontWeight: 600
    },
  },
})
