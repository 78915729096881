import _ from "lodash"
import { connect } from 'react-redux'
import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getSQLTemplates } from '../../api/sql_templates'
import { getAbTest, createAbTest, updateAbTest, deleteAbTest } from '../../api/ab_tests'
import { getCampaigns } from "../../api/campaigns"
import { getLabels } from "../../api/ads"
import FTNotice from '../FTNotice'
import EditABTestLayout from "./EditABTestLayout"
import i18next from 'i18next'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allLabels: [],
      allCampaigns: [],
      sql_templates: [],
      id: null,
      internal_client_id: this.props.internal_client_id,
      title: '',
      description: '',
      control_campaign:[],
      experiment_campaign:[],
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(60, 'day').format('YYYY-MM-DD'),
      experiment_sql: '',
      control_sql: null,
      exclude_from_bidding: false,
      google_ab_test: false,
      settings: null,
      loaded: false
    }
  }

  componentDidMount() {
    if (this.props.match.params.id == 'new') {
      this.setState({loaded: true})
    } else {
      this.getAbTest()
    }
    this.getCampaigns()
    this.getLabels()
    this.getTemplates()
  }

  getCampaigns() {
    getCampaigns(this.props.internal_client_id).then(campaigns => {
      this.setState({ allCampaigns: campaigns })
    })
  }

  getLabels() {
    getLabels(this.props.internal_client_id).then(labels => {
      this.setState({ allLabels: labels == null ? [] : labels })
    })
  }


  getTemplates() {
    getSQLTemplates().then(response => {
      this.setState({ sql_templates: response.results });
    })
  }

  handleSelectTemplate(templateId) {
    const template = this.state.sql_templates.find(t => t.id == templateId)
    if (template != null) { 
      this.setState({
        experiment_sql_template: templateId,
        experiment_sql: template.sql,
        control_sql_template: templateId,
        control_sql: template.sql,
      })
    }
  }

  getAbTest() {
    getAbTest(this.props.match.params.id).then(result => {
      if (result.error) {
          FTNotice(result.error, 3000)
          this.props.history('/manage_ab_tests')
      } else if (result.end_date == null) {
        result.end_date = moment(result.start_date, 'YYYY-MM-DD').add(60, 'day').format('YYYY-MM-DD')
      }
      if (result.description == null) {
        result.description = ''
      }
      result.loaded = true
      result.control_campaign = [result.campaign_id]
      delete result.campaign_id
      result.experiment_campaign = [result.exp_campaign_id]
      delete result.exp_campaign_id
      this.setState(result)
    })
  }

  save() {
    const values = {
      internal_client_id: this.state.internal_client_id,
      title: this.state.title,
      description: this.state.description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      experiment_sql: this.state.experiment_sql,
      control_sql: this.state.control_sql,
      campaign_id: this.state.control_campaign?.length ? this.state.control_campaign[0] : null,
      exp_campaign_id: this.state.experiment_campaign?.length ? this.state.experiment_campaign[0] : null,
      exclude_from_bidding: this.state.exclude_from_bidding,
      settings: this.state.settings,
      google_ab_test: this.state.google_ab_test
    }
    if (this.state.title.trim() == '') {
      FTNotice('abtest.edit.enterName', 15000);
    } else if (moment(this.state.start_date, 'YYYY-MM-DD') >= moment(this.state.end_date, 'YYYY-MM-DD')) {
      FTNotice('abtest.edit.startBeforeEnd', 15000)
    } else if (this.state.experiment_sql.trim() == '') {
      FTNotice('abtest.edit.enterExperimentSql', 15000)
    } else {
      const id = this.state.id
      const api_call = id ? updateAbTest(id, values) : createAbTest(values)
      const action = i18next.t('abtest.edit.' + (id ? 'updated' : 'added'))
      api_call.then((result) => {
        if (result.id) {
          FTNotice(i18next.t('abtest.edit.abtestSaved', { action }))
          window.location.href = '/#/manage_ab_tests'
        } else {
          FTNotice(i18next.t('abtest.edit.couldNot', { action, error: result.error }))
        }
      }).catch((result) => {
        FTNotice(i18next.t('abtest.edit.couldNot', { action, error: result.error }))
      })
    }
  }

  deleteAbTest() {
    deleteAbTest(this.props.match.params.id).then((result) => {
      if (result.success) {
        window.location.href = '/#/manage_ab_tests'
      } else {
        FTNotice('abtest.edit.couldNotDelete')
      }
    }).catch(() => {
      FTNotice('abtest.edit.couldNotDelete')
    })
  }

  render() {
    if (this.state && this.state.loaded) {
      return (
        <div>
          <EditABTestLayout
            id={this.props.match.params.id}
            allCampaigns={this.state.allCampaigns}
            allLabels={this.state.allLabels}
            title={this.state.title}
            description={this.state.description}
            excludeFromBidding={this.state.exclude_from_bidding}
            experimentSql={this.state.experiment_sql}
            controlSql={this.state.control_sql}
            experimentCampaign={this.state.experiment_campaign}
            controlCampaign={this.state.control_campaign}
            google_ab_test={this.state.google_ab_test}
            sqlTemplates={this.state.sql_templates}
            handleSelectTemplate={templateId => this.handleSelectTemplate(templateId)}
            startDate={this.state.loaded ? this.state.start_date : null}
            endDate={this.state.loaded ? this.state.end_date : null}
            onUpdate={newState => this.setState(newState)}
            save={() => this.save()}
            delete={() => this.deleteAbTest()}
            cancel={() => location.href = '/#/manage_ab_tests'}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
