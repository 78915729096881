import React, { useEffect, useState } from 'react'
import * as ChartGeo from 'chartjs-chart-geo'
import { ChoroplethChart } from 'chartjs-chart-geo'
import i18next from 'i18next'
import { getName } from './util'

/**
 * A geo chart component created with chart.js.
 * Source: https://github.com/sgratzl/chartjs-chart-geo
 *
 * Properties:
 *  id              - unique identifier
 *  title           - (optional) Chart title
 *  map             - 'USA' or 'World']
 *  values          - An array of data for the metrics. E.g [{'location_name': 'United States', 'value': 25}]
 *  nameAttribute   - Attribute to use for identifying the name of the geographic area
 *  valueAttribute  - Attribute to use for the numeric value to show
 */

const GeoChart = ({ id, title, map, values, nameAttribute, valueAttribute }) => {
  const mappings = { // maps chart names to Google names
    'Antigua and Barb.': 'Antigua and Barbuda',
    'Bahamas': 'The Bahamas',
    'Bosnia and Herz.': 'Bosnia and Herzegovina',
    'Cabo Verde': 'Cape Verde',
    'Central African Rep.': 'Central African Republic',
    'Cook Is.': 'Cook Islands',
    'Congo': 'Republic of the Congo',
    'Dem. Rep. Congo': 'Democratic Republic of the Congo',
    'Dominican Rep.': 'Dominican Republic',
    'Eq. Guinea': 'Equatorial Guinea',
    'eSwatini': 'Eswatini',
    'Fr. Polynesia': 'French Polynesia',
    'Gambia': 'The Gambia',
    'Marshall Is.': 'Marshall Islands',
    'Myanmar': 'Myanmar (Burma)',
    'N. Mariana Is.': 'Northern Mariana Islands',
    'Solomon Is.': 'Solomon Islands',
    'St. Kitts and Nevis': 'Saint Kitts and Nevis',
    'St. Pierre and Miquelon': 'Saint Pierre and Miquelon',
    'Turkey': 'Turkiye',
    'United States of America': 'United States',
  }
  // Special handling due to encoding
  mappings[i18next.t('countries.curacao')] = "Curacao"
  mappings[i18next.t('countries.ivoryCoast')] = "Cote d'Ivoire"
  mappings[i18next.t('countries.saoTome')] = "Sao Tome and Principe"
  // The following couldn't be mapped:
  // Caribbean Netherlands, North Macedonia, Tuvalu, United States Minor Outlying Islands

  const [chart, setChart] = useState()
  const [options, setOptions] = useState([])
  const [attribute, setAttribute] = useState(valueAttribute)
  
  useEffect(() => {
    const newOptions = []
    if (values.length > 0) {
      const choices = Object.keys(values[0])
      choices.forEach(c => {
        if (!values.find(v => v[c] != null && isNaN(v[c]))) {
          newOptions.push(c)
        }
      })
    }
    setOptions(newOptions.sort((a, b) => getName(a).localeCompare(getName(b))))
    if (newOptions.indexOf(valueAttribute) < 0 && newOptions.length > 0) {
      setAttribute(newOptions[0])
    }
    
  }, [values])
  
  const getValue = (name) => {
    if (name in mappings) {
      name = mappings[name]
    }
    const row = values.find(v => v[nameAttribute] == name)
    return row == null ? null  : row[attribute]
  }

  const getUSA = () => {
     fetch('https://unpkg.com/us-atlas/states-10m.json')
      .then((r) => r.json())
      .then((us) => {
        const nation = ChartGeo.topojson.feature(us, us.objects.nation).features[0]
        const states = ChartGeo.topojson.feature(us, us.objects.states).features
        setChart(new ChoroplethChart(document.getElementById("canvas-" + id).getContext("2d"), {
          type: 'choropleth',
          data: {
            labels: states.map((d) => d.properties.name),
            datasets: [{
              outline: nation,
              data: states.map((d) => ({feature: d, value: getValue(d.properties.name)})),
            }]
          },
          options: {
            plugins: {
              legend: {
                display: false
              },
            },
            scales: {
              projection: {
                axis: 'x',
                projection: 'albersUsa'  
              },
              color: {
                axis: 'x',
                quantize: 5,
                legend: {
                  position: 'bottom-right',
                  align: 'bottom'
                },
              }
            },
          }
        }))
      })
  }

  const getWorld = () => {
    fetch('https://unpkg.com/world-atlas/countries-50m.json')
      .then((r) => r.json())
      .then((data) => {
        const countries = ChartGeo.topojson.feature(data, data.objects.countries).features
        setChart(new ChoroplethChart(document.getElementById("canvas-" + id).getContext("2d"), {
          type: 'choropleth',
          data: {
            labels: countries.map((d) => d.properties.name),
            datasets: [{
              data: countries.map((d) => ({feature: d, value: getValue(d.properties.name)})),
            }]
          },
          options: {
            showOutline: true,
            showGraticule: false,
            plugins: {
              legend: {
                display: false
              },
            },
            scales: {
              projection: {
                axis: 'x',
                projection: 'equalEarth'
              }
            }
          }
        }))
      })
  }

  useEffect(() => {
    if (options.indexOf(attribute) >= 0) {
      if (chart != null) {
        chart.destroy()
      }
      map == 'USA' ? getUSA() : getWorld()
    }
  }, [attribute, options])
  
  return (
    <div style={{height: '100%', width: '100%'}}>
      {title && (
        <h3>{title}</h3>
      )}
      {options.length > 1 && (
        <select value={attribute} onChange={event => setAttribute(event.target.value)} style={{verticalAlign: 'top'}}>
          {options.map((option, i) => (
            <option key={i} value={option}>{getName(option)}</option>
          ))}
        </select>
      )}
      <canvas id={"canvas-" + id}></canvas>
    </div>
  )
}

export default GeoChart