import _ from 'lodash'
import React from 'react'
import FTOTable from '../widgets/Table'

// stylesheets
import "./Table.scss"

const Table = ({dataset1, dataset2, metrics, preventSort}) => {
  const isSameEngines = () => {
    return !$("#comparerange .comparerange").html()
  }

  const getColumns = () => {
    const colIds = [
      "cost",
      "cpc",
      "internal_roas",
      "engine_roas",
      "internal_rpc",
      "engine_rpc",
      "internal_revenue",
      "engine_revenue",
      "ctr",
      "internal_cr",
      "engine_cr",
      "internal_aov",
      "engine_aov",
      "internal_cpa",
      "engine_cpa",
      "max_cpc",
      "clicks",
      "impressions",
      "engine_conversion",
      "internal_conversion",
    ]

    const columns = [
      {
        id: "date",
        datatype: "date",
        width: 100,
      },
    ]

    colIds.forEach(function (col) {
      columns.push({
        id: col,
        datatype: "numeric",
        width: col == "internal_revenue" ? 150 : 125,
      })
    })

    return columns
  }

  const columnOverride = (columns) => {
    columns = columns.slice() // copy array

    // Add in selected metrics if not currently shown
    const col1 = metrics[0]
    const col2 = metrics[1]
    const columnDefinitions = getColumns()
    let found1 = false
    let found2 = false
    columns.forEach((column) => {
      if (column.id == col1) {
        found1 = true
      }
      if (column.id == col2) {
        found2 = true
      }
    })
    if (!found1) {
      columnDefinitions.forEach((column) => {
        if (column.id == col1) {
          columns.push(column)
        }
      })
    }
    if (!found2 && col1 != col2) {
      columnDefinitions.forEach((column) => {
        if (column.id == col2) {
          columns.push(column)
        }
      })
    }

    if (!isSameEngines()) {
      const copy = columns.slice()
      copy.forEach(function (col) {
        let new_col = Object.assign({}, col)
        new_col.id = col.id + "_2"
        new_col.localeKey = col.id
        if (col.id == "date") {
          new_col.className =
            (new_col.className ? new_col.className + " " : "") + "separator"
        }
        columns.push(new_col)
      })
    }

    return columns
  }

  const getDataset = () => {
    return (
      dataset1 &&
      dataset1.map((row, i) => {
        const combinedRow = Object.assign({ date: row[0] }, row[1])
        if (dataset2 && dataset2.length > i) {
          const row2 = Object.assign({ date: dataset2[i][0] }, dataset2[i][1])
          for (var key in row2) {
            combinedRow[key + "_2"] = row2[key]
          }
        }
        return combinedRow
      })
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <FTOTable
            tableName={"exec_summary"}
            dataset={getDataset()}
            columns={getColumns()}
            columnOverride={columns => columnOverride(columns)}
            headerHeight={50}
            hidePagination={true}
            preventSort={preventSort}
          />
        </div>
      </div>
    </div>
  )
}

export default Table