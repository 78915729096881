import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import { getAllSql } from "../../api/dashboards"
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import i18next from 'i18next'
import Button from '../widgets/Button'

class SQL extends Component {
  constructor(props){
    super(props)
    this.state = {
      open_dialog: false
    }
  }

  getColumns() {
    return [{
      id: 'client_name',
      datatype: 'text',
      width: 120,
      cellRenderer: (value) => (<div className='text-center'>{value ? value : i18next.t('dashboardSql.all')}</div>),
    }, {
      id: 'name',
      fieldName: 'SQL',
      datatype: 'text',
      cellRenderer: (value, row) => (<Link to={`/dashboard_admin/sql/${row.id}`}  className="text-center" style={{width: '100%', display: 'inline-block'}}>{value}</Link>),
      width: 300,
    }, {
      id: 'description',
      datatype: 'text',
      width: 500,
    }, {
      id: 'database',
      header: 'Database',
      datatype: 'text',
      width: 100,
    }, { 
      id: 'created_at',
      datatype: 'date',
      width: 140,
    }, { 
      id: 'user_name',
      datatype: 'text',
      width: 140,
    }, {
      id: 'sql',
      header: 'search sql',
      datatype: 'text',
      preventSort: true,
      cellRenderer: (value) => (
        <Button
          onClick={() => this.setState({open_dialog: true, dialog_content: value})}
          buttonText={'dashboardSql.showSql'}
          size='small'
        />
      ),
      width:130,
    }, {
      id: 'copy',
      header: 'Copy',
      cellRenderer: (_, row) => (
        <div className='text-center'>
          <Link to={'/dashboard_admin/sql/new?copy=' + row.id}>
            <Button
              buttonText={'dashboardSql.copy'}
              size='small'
            />
          </Link>
        </div>
      ),
      width:85,
    }]
  }

  renderSqlPreview() {
    return(
      <Dialog open={this.state.open_dialog}
        onClose={() => this.setState({open_dialog: false})} 
        maxWidth='md' >
        <DialogTitle><b>{i18next.t('dashboardSql.sql')}</b></DialogTitle>
        <DialogContent dividers>
          <SyntaxHighlighter language="sql" style={docco} 
            customStyle={{marginTop: '1px'}} >
          {this.state.dialog_content}
        </SyntaxHighlighter>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <div style={{paddingLeft: 30, paddingRight: 30}}>
        <div className="box-header js_complete" style={{height: 35}}>
          <div className="add-new-button" >
            <Link to={'/dashboard_admin/sql/new'} className="btn btn-box-tool text-green">
              <i className="fa fa-plus" style={{color:'#7B1FA2', paddingLeft:30}}></i>
              <Button
                buttonText={'dashboardSql.addNewSql'}
                size='small'
                variant='text'
                style={{paddingUp: 30}}
              />
            </Link>
          </div>
        </div>
        <Table 
          tableName='dashboard_sql'
          fetch={(dataTable) => {return getAllSql(true, this.props.internal_client_id, dataTable)}}
          columns={this.getColumns()} />
        {this.renderSqlPreview()}
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(SQL)
