import { Fade } from "@mui/material"

/*
This uses an MUI Fade (https://mui.com/material-ui/api/fade/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.
*/
const FtoFade = (props) => {
  const defaultProps = {
     appear: true,
     timeout: 1500
  }
  const revisedProps = Object.assign(defaultProps, props)
  const {...fadeProps} = revisedProps
  return (
    <Fade {...fadeProps} />
  )
}

export default FtoFade