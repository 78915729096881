import request from './request'

export function postQuestion(prompt, user_engineering, conversation_id) {
  return request('/api/prompts', {}, 'POST', {conversation_id, prompt, user_engineering})
}

export function updatePrompt(id, data) {
  return request(`/api/prompts/${id}`, {}, 'PUT', data)
}

export function getResponse(id) {
  return request(`/api/prompts/${id}`)
}

export function getConversations() {
  return request(`/api/conversations`)
}

export function getConversation(id) {
  return request(`/api/conversations/${id}`)
}
