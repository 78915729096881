import { Component } from 'react';
import { Link } from 'react-router-dom';
import { getSteps, patchStep } from "../../../api/workflows";
import OrderedList from '../../widgets/OrderedList';

export default class EditSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    getSteps(this.props.workflow_id).then((result) => {
      this.setState({steps: result.results});
    });
  }

  prettify(value) {
    return value == null ? null : value.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  getColumns() {
    return [{
      id: 'step_num',
      header: 'Order',
      rowDrag: true,
      width: 100,
    },{
      id: 'step_name',
      header: 'Step',
      cellRenderer: (value, row) => {
        return (
          <Link to={'/workflows/step/' + row.step_id}>{value}</Link>
        )
      },
      width: 300,
    },{
      id: 'type',
      header: 'Type',
      width: 180,
      format: (value) => this.prettify(value) 
    },{
      id: 'step_description',
      header: 'Description',
      width: 500,
    }];
  }

  updateStepOrder(step, order) {
    patchStep(step.step_id, {step_num: order});
  }

  addStep() {
    window.location.href = '/#/workflows/step/new?workflow_id=' + this.props.workflow_id;
  }

  render() {
    return(
      <OrderedList
        objectType="Workflow Step"
        items={this.state.steps}
        columns={this.getColumns()}
        addItem={() => this.addStep()}
        updateItemOrder={this.updateStepOrder}
      />
    )
  }
}
