import withRouter from '../../wrappers/withRouter'
import { Component, createRef } from 'react'
import Table from '../widgets/Table'
import DatePicker from '../widgets/DatePicker'
import { connect } from 'react-redux'
import { encode } from '../../api/encoding'
import { decode } from '../../api/encoding'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { getFeedHistory } from '../../api/feeds'
import { Link } from 'react-router-dom'
import { formatInteger } from '../../utilities/formatting'

class History extends Component {
  tabs = [
    {name: 'all', text: 'All'},
    {name: 'structure', text: 'Structure'},
    {name: 'performance', text: 'Performance'},
    {name: 'revenue', text: 'Revenue'},
    {name: 'stand alone', text: 'Stand Alone'},
    {name: 'publishing', text: 'Publishing'},
  ]

  job_types = [
    'Publish / Change Keywords, Ads, Ad Groups',
    'Publish PLA item levels',
    'Change bids',
    'Change mobile bids (criterion)',
    'Publish product feeds (google, bing, facebook)',
    'Publish Responsive Search Ads',
  ]
  
  tableRef = createRef()

  constructor(props) {
    super(props)
    this.state = {
      feed_history: [],
      tabIndex: 0,
      from_date: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
      columns: null
    }
    $.extend(this.state, this.getConfig())
  }

  componentDidUpdate(_, prevState) {
    if ((prevState.from_date != this.state.from_date || prevState.to_date != this.state.to_date) && this.tableRef.current) {
      this.tableRef.current.refreshRows()
    }
  }

  getConfig() {
    let config = {}
    const pathname = this.props.location.pathname
    if (pathname != '/') {
      const paths = pathname.split('/')
      if (paths[4]) {
        config = JSON.parse(decode(paths[4]))
      }
    }
    return config
  }

  updateTab(index) {
    this.setState( { tabIndex: index }, () => {
      this.updateUrl()
    })
  }

  getData(tableData) {
    let columns = []
    this.getColumns().forEach((column) => {
      columns.push(column.id)
    })
    return getFeedHistory(this.props.internal_client_id, this.state.from_date, this.state.to_date, this.tabs[this.state.tabIndex].name, tableData, columns)
  }

  updateUrl() {
    const source_path=this.props.location.pathname
    const json = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      tabIndex: this.state.tabIndex,
    }
    const filters = encode(JSON.stringify(json))
    const paths = source_path.split('/')
    paths[4] = filters
    const new_path = paths.join('/')
    this.props.history(new_path)
  }

  updateDates(dates) {
    this.setState({from_date: dates.start, to_date: dates.end}, () => {
      this.updateUrl()
      if (this.tableRef.current) {
        this.tableRef.current.refreshRows()
      }
    })
  }

  getColumns() {
    return [{
        id: 'client_name',
        datatype: 'text',
        width: 150
      }, {
        id: 'job_type',
        datatype: 'option',
        width: 350,
        options: this.job_types
      }, {
        id: 'run_id',
        datatype: 'text',
        width: 450,
        cellRenderer: (value, row) => {
          return row.job_type.substring(0,3) == 'bid' ?
          (<Link to={'/bidding/bidding_run?id=' + value} >{value}</Link>) :
          (<span>{value}</span>)
        }
      }, {
        id: 'source',
        datatype: 'text',
        width: 100
      }, {
        id: 'engine',
        datatype: 'text',
        width: 200
      }, {
        id: 'run_date',
        datatype: 'date',
        selected: true
      }, {
        id: 'status',
        datatype: 'text',
        width: 100
      }, {
        id: 'successes',
        datatype: 'numeric',
        width: 100,
        format: value => formatInteger(value)
      }, {
        id: 'failures',
        datatype: 'numeric',
        width: 100,
        format: value => formatInteger(value)
      },
    ]
  }

  render() {
    return (
      <div className="site-canvas">
        <section className='content'>
          <div className="page-nav" style={{position: 'relative'}}>
            <div className="btn-group">
              <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                <DatePicker
                  start={this.state.from_date}
                  end={this.state.to_date}
                  showCompare={false}
                  callback={(dates) => this.updateDates(dates)}
                />
              </div>
            </div>
          </div>
          <div>
            {(this.props.internal_client_id != null) && (
              <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
                <TabList>
                  { this.tabs.map((tab) => {
                      return (
                        <Tab key={tab.name}>
                          {tab.text}
                        </Tab>
                      )
                    })
                  }
                </TabList>
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-info js_complete">
                      { this.tabs.map((tab) => {
                          return (
                            <TabPanel key={tab.name}>
                              <Table
                                ref={this.tableRef}
                                tableName='feed_history'
                                fetch={(tableData) => this.getData(tableData)}
                                columns={this.getColumns()} />
                            </TabPanel>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </Tabs>
            )}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}
export default withRouter(connect(mapStateToProps)(History))
