import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI Select (https://mui.com/material-ui/api/select/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - the label to use for the button (this should generally be an i18n key)
*/
const FtoSelect = (props) => {
  const defaultProps = {
    label: props.labelText ? i18next.t(props.labelText) : null,
    variant: "outlined",
  }
  const revisedProps = Object.assign(defaultProps, props)
  const {labelText, children, ...selectProps} = revisedProps
  return (
    <FormControl sx={{ minWidth: 120 }} >
      <InputLabel>{selectProps.label}</InputLabel>
      <Select {...selectProps}>
        {children.map((child, i) => {
          const {labelText, ...itemProps} = child.props
          return (
            <MenuItem key={i} {...itemProps}>{i18next.t(labelText)}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default FtoSelect