import request from './request'
import querystring from 'querystring'

// alerts, bidding, budget_admin, dashboard_admin, feeds, manage_ab_tests, manage_dimensions, offline_conversions, revenue_pipeline, services, sql, workflows
export function getSQLTemplates(tableData) {
  return request('/api/sql_templates/all?' + + querystring.stringify(tableData))
}

// sql
export function getSQLTemplate(id) {
  return request('/api/sql_templates/' + id)
}

// sql
export function createSQLTemplate(data) {
  return request('/api/sql_templates/0', {}, 'POST', data)
}

// sql
export function updateSQLTemplate(id, data) {
  return request('/api/sql_templates/' + id, {}, 'PUT', data)
}

// sql
export function deleteSQLTemplate(id) {
  return request('/api/sql_templates/' + id, {}, 'DELETE')
}

// alerts, bidding, budget_admin, dashboard_admin, feeds, manage_ab_tests, manage_dimensions, offline_conversions, revenue_pipeline, services, sql, workflows
export function getDatabaseSqlHint(database) {
  const url = '/api/sql/auto_completion?database=' + database;
  return request(url);
}
