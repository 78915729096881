import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Link } from 'react-router-dom'
import { getServices } from '../../api/services'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import History from './History'
import i18next from 'i18next'

class Interface extends Component {
  constructor(props) {
    super(props)
    this.state = {
      services: [],
      unconfiguredServices: [],
      tabIndex: this.props.match.params.tab == 'history' ? 1 : 0,
      tabs: [
        {name: 'services', text: i18next.t('services.services')},
        {name: 'history', text: i18next.t('services.history')},
      ]
    }
  }

  componentDidMount() {
    this.getServices()
  }

  getServices() {
    getServices(this.props.internal_client_id).then(services => {
      const unconfiguredServices = services.filter((service) => service.id == '-1')
      services = services.filter((service) => service.id != '-1')
      services.forEach(service => service.name = service.name ? service.name : i18next.t('services.' + service.service_type + '.name'))
      services.sort((a, b) => a.name.localeCompare(b.name))
      this.setState({services: services, unconfiguredServices: unconfiguredServices})
    })
  }

  updateTab(index) {
    this.props.history('/services/'+this.state.tabs[index].name)
    this.setState({tabIndex: index})
  }

  renderServices() {
    return (
      <div>
        {this.state.services.length > 0 && (
          <div>
            <h3>{i18next.t('services.configuredServices')}</h3>
            {this.state.services.map((service,i) => {
              return (
                <div className="col-md-2 mt-10 center" style={{height: 200, paddingTop: 10}} key={i}>
                  <Link to={"/services/"+service.service_type+"/" + (service.id == null ? '0' : service.id) + "/run"} style={{textAlign: 'center'}}>
                    <img src={'/resources/images/workflow_icons/' + service.image} 
                      height="100" width="100" title={i18next.t('services.' + service.service_type + '.name')} style={{margin: 'auto', display: 'block'}} />
                    <h4 className="truncate-overflow">{service.name ? service.name : i18next.t('services.' + service.service_type + '.name')}</h4>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
        {this.state.unconfiguredServices.length > 0 && (
          <div style={{clear: 'both'}}>
            <h3>{i18next.t('services.unconfiguredServices')}</h3>
            {this.state.unconfiguredServices.map((service,i) => {
              return (
                <div className="col-md-2 mt-10 center" style={{height: 200, paddingTop: 10}} key={i}>
                  <Link to={"/services/new/"+service.service_type} style={{textAlign: 'center'}}>
                    <img src={'/resources/images/workflow_icons/' + service.image} 
                      height="100" width="100" title={i18next.t('services.' + service.service_type + '.name')} style={{margin: 'auto', display: 'block'}} />
                    <h4 className="truncate-overflow">{i18next.t('services.' + service.service_type + '.name')}</h4>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  renderPage() {
    if (this.state.services)
      return(
        <div>
          <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
            <TabList>
              {this.state.tabs.map((tab) => {
                return (
                  <Tab key={tab.name}>
                    {tab.text}
                  </Tab>
                )
              })}
            </TabList>
            <div className="row">
              <div className="col-md-12">
                <div className="box box-info">
                  <TabPanel key="services">
                    <Link to={'/services/new'} className="btn btn-box-tool text-green" style={{'marginLeft': '90%'}}>
                      <i className="fa fa-plus"></i>
                      <span>{i18next.t('services.addNewService')}</span>
                    </Link>
                    <div className="container js_complete" style={{width:'80%'}}>
                      {this.renderServices()}
                    </div>
                  </TabPanel>
                  <TabPanel key="history">
                    <History internal_client_id={this.props.internal_client_id} />
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      )
  }

  render() {
    return(
      <div className="site-canvas" style={{ minHeight: '540px' }}>
        <section className='content'>
          {this.renderPage()}
        </section>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
