import { Component } from 'react';

export default class Project extends Component {
  render() { 
    const project = this.props.project;
    return (
      <div className="project">
        <div className="name">{project.name}</div>
        {project.epic_id ?
          <div className="epic" style={{ color: project.epic_foreground, backgroundColor: project.epic_background }}>{project.epic_name}</div>
          :
          <span></span>}
        <div className="footer">
          <img className="issue_type" src={project.issue_type_icon} title={project.issue_type}/>
          <img className="priority" src={project.priority_icon} title={project.priority + ' priority'}/>
          <div className="id-assignee">
            {project.id}
            {project.assignee_icon ?
              <img className="assignee" src={project.assignee_icon} title={'Assignee: ' + project.assignee}/>
              :
              <span></span>}
          </div>
        </div>
      </div>
    )
  }
}
