import '@babel/polyfill'
import 'bootstrap'
import 'daterangepicker/daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker'
import 'datatables'
import './application'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/scss/font-awesome.scss'
import 'daterangepicker/daterangepicker.css'
import './assets/adminlte/css/AdminLTE.css'
import './assets/adminlte/css/skins/skin-purple.css'
import './application.scss'
import './assets/stylesheets/awesome-bootstrap-checkbox.scss'
import './assets/stylesheets/offcanvas.scss'
import './assets/stylesheets/rc-time-picker.css'
import './assets/stylesheets/FTNotice.scss'
import './assets/stylesheets/datepicker.scss'
import './assets/stylesheets/Tabs.scss'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonEn from './translations/en/english.json'
import PropTypes from 'prop-types'
import React from "react"
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import App from './components/App'
import store from './redux/store'

const resources = {
  en: {
    translation: commonEn
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

const root = createRoot(document.getElementById('root'))
root.render(<Root store={store}/>)
