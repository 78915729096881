import React, { useRef, useEffect } from 'react'
import Table from '../../widgets/Table'
import DeleteButton from '../../DeleteButton'
import { getBidDetails, exportBidDetails, updateBid, deleteBid } from '../../../api/bidding'
import { formatCurrency, formatPercent } from '../../../utilities/formatting'

const BidDetails = ({ bidding_run_id, isDraft, engines, campaigns, adGroups }) => {
  const tableRef = useRef()

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.refreshRows()
    }
  }, [bidding_run_id])

  const updateTableBid = (row, bid) => {
    row.bid = bid == '' ? '0.01' : bid
    row.bid_change = row.bid - row.bid_past
    row.percent_change = 100.0 * (row.bid - row.bid_past) / row.bid_past
    updateBid(row.id, { bid: row.bid }).then(() => {
      if (tableRef.current) {
        tableRef.current.refreshCells()
      }
    })
  }

  const getColumns = () => {
    const columns = [{
      id: 'subbudget',
      datatype: 'text',
      width: 150,
    }, {
      id: 'campaign',
      header: 'biddingDetails.campaign',
      datatype: 'text',
      width: 300,
    }, {
      id: 'ad_group',
      header: 'biddingDetails.adGroup',
      datatype: 'text',
      width: 300,
    }, {
      id: 'criteria',
      datatype: 'text',
      width: 300
    }]
    if (isDraft) {
      columns.push({
        cellRenderer: (_, row) => (
          <DeleteButton
            objectType=""
            objectName="this bid"
            style={{ marginRight: 10, verticalAlign: 'top' }}
            deleteObject={() => deleteTableBid(row)} />
        ),
        width: 100,
      })
    }
    columns.push(...[{
      id: 'bid',
      header: 'biddingDetails.bid',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 70,
      classRules: {
        'positive': params => { return params.data && params.data.bid_change > 0 },
        'negative': params => { return params.data && params.data.bid_change < 0 },
      },
      editable: isDraft,
      cellRenderer: value => (
        <div>
          {value}
        </div>
      ),
      cellEditor: (value, row, setValue, refInput) => (
        <div>
          <input
            ref={refInput}
            type="text"
            className="form-control"
            value={value}
            onChange={event => setValue(event.target.value)}
            style={{ textAlign: 'right', fontSize: 12, width: 45, display: 'initial', padding: 5, lineHeight: 14, height: 34, position: 'relative', marginRight: 10 }} />
        </div>
      ),
      onCellValueChanged: event => {
        updateTableBid(event.data, event.newValue)
      }
    },{
      id: 'bid_change',
      header: 'biddingDetails.change',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 90,
    }, {
      id: 'percent_change',
      header: 'biddingDetails.percentChange',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 120,
    }, {
      id: 'bid_status',
      header: 'biddingDetails.bidStatus',
      datatype: 'text',
      width: 100,
    }, {
      id: 'criterion_type',
      header: 'biddingDetails.criterionType',
      datatype: 'text',
      width: 100,
    },
    {
      id: 'conv_value',
      header: 'biddingDetails.convValue',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 100,
    },
    {
      id: 'cost',
      header: 'biddingDetails.cost',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'clicks',
      header: 'biddingDetails.clicks',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'cpa',
      header: 'biddingDetails.cpa',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'roas',
      header: 'biddingDetails.roas',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'conversion_rate',
      header: 'biddingDetails.convRate',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'escore_clicks',
      header: 'biddingDetails.escoreClicks',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'escore_cost',
      header: 'biddingDetails.escoreCost',
      datatype: 'numeric',
      width: 100,
    },
    {
      id: 'level',
      header: 'biddingDetails.level',
      datatype: 'text',
      width: 100,
    },
    {
      id: 'message',
      header: 'biddingDetails.message',
      datatype: 'text',
      width: 280,
    }])
    return columns
  }

  const deleteTableBid = (row) => {
    deleteBid(row.id).then(() => {
      tableRef.current.refreshRows() // force the table to update
    })
  }

  return (
    <div>
      <div className='row'>
        <div className="col-md-12">
          <Table
            ref={tableRef}
            headerHeight={50}
            rowHeight={isDraft ? 40 : null}
            fetch={tableData => getBidDetails(bidding_run_id, tableData, engines, campaigns, adGroups)}
            export={tableData => {
              return exportBidDetails(bidding_run_id, tableData, engines, campaigns, adGroups)
            }}
            changeParams={{engines, campaigns, adGroups}}
            columns={getColumns()} />
        </div>
      </div>
    </div>
  )
}

export default BidDetails