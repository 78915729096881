import locale from '../../api/locale'

export const COLORS = [
  "#7B1FA2",
  "#1F88A3",
  "#A37B1F",
  "#616161",
  "#1FA37B",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#000099",
  "#330099",
  "#660099",
  "#990099",
  "#CC0099",
  "#FF0099",
  "#003399",
  "#333399",
  "#663399",
  "#993399",
  "#CC3399",
  "#FF3399",
  "#006699",
  "#336699",
  "#666699",
  "#996699",
  "#CC6699",
  "#FF6699",
  "#009999",
  "#339999",
  "#669999",
  "#999999",
  "#CC9999",
  "#FF9999",
  "#00CC99",
  "#33CC99",
  "#66CC99",
  "#99CC99",
  "#CCCC99",
  "#FFCC99",
  "#00FF99",
  "#33FF99",
  "#66FF99",
  "#99FF99",
  "#CCFF99",
  "#FFFF99",
]

export function yyyymmddToMmddyyyy(input) {
  var pattern = /(\d{4})\-(\d{2})\-(\d{2})/
  if (!input || !input.match(pattern)) {
    return null
  }
  return moment(input).format('M/D/YY')
}

export function removeAnnotations(chart) {
  /*
   * remove all annotations from chart.
   */
  const annotations = chart.annotations
  for (let i = annotations.length - 1; i > -1; --i) {
    try {
      chart.removeAnnotation(annotations[i].options.id)
    } catch (e) { }
  }
}

export function addAnnotations(chart, annotations, getY) {
  if (chart && annotations) {
    var labels = []
    annotations.forEach((annotation) => {
      let xValue = annotation.x_value
      let x = Date.parse(xValue)
      if (x != -1) {
        const y = getY(chart, x)
        // convert to pixel
        let y_pixel = chart.yAxis[0].toPixels(y, true)
        let text = annotation.text
        if (x != -1) {
          labels.push({ point: { xAxis: 0, x: x, y: y_pixel }, text: text })
        }
      }
    })
    removeAnnotations(chart)
    chart.addAnnotation({
      id: "a1",
      labels: labels,
      labelOptions: { x: 40, y: -40 },
    })
  }
}

export function getName(attribute) {
  if (!(attribute in locale.metrics)) {
    attribute = attribute.charAt(0).toUpperCase() + attribute.slice(1)
    return attribute.replace(/_/g, " ")
  } else {
    return locale.metrics[attribute]
  }
}