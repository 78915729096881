import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getSteps, patchStep } from '../../api/revenue_pipeline'
import OrderedList from '../widgets/OrderedList'
import i18next from 'i18next'

const Pipelines = ({internal_client_id, type}) => {
  const [loaded, setLoaded] = useState(false)
  const [steps, setSteps] = useState([])
  useEffect(() => {
    getSteps(internal_client_id, type)
      .then(response => {
        setSteps(response.data)
        setLoaded(true)
      })
  }, [])

  const updateStepOrder = (step, i) => {
    patchStep(step.id, {step_order: i}).then(() => {})
  }

  const getColumns = () => {
    return [{
      id: 'step_order',
      header: i18next.t('revenuePipeline.order'),
      width: 100,
      rowDrag: true,
    },{
      id: 'name',
      header: i18next.t('revenuePipeline.step'),
      cellRenderer: (value, row) => {
        return (
          <Link to={'/revenue_pipeline/steps/' + row.id}>{value}</Link>
        )
      },
      width: 300,
    },{
      id: 'description',
      header: i18next.t('revenuePipeline.description'),
      width: 500,
    },{
      id: 'user_name',
      header: i18next.t('revenuePipeline.lastModifiedBy'),
      width: 300,
    }]
  }

  return (
    <div className='site-wrapper-offcanvas' id='chart-hudd' style={{padding: 35}}>
      <OrderedList
        tableName='revenue_pipeline'
        objectType={i18next.t('revenuePipeline.step')}
        items={steps}
        columns={getColumns()}
        addItem={() => {window.location.href='/#/revenue_pipeline/new/' + type}}
        updateItemOrder={(step, i) => updateStepOrder(step, i)}
      />
      {loaded && (<span className="js_complete"></span>)}
    </div>
  )
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(Pipelines)
