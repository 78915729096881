import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { executeSql, exportSql } from '../../api/dashboards'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { Box } from '@mui/material'
import Table from '../widgets/Table'
import FTNotice from '../FTNotice'
import LineChart from '../charts/LineChart'
import PieChart from '../charts/PieChart'
import FunnelChart from '../charts/FunnelChart'
import GeoChart from '../charts/GeoChart'
import BarChart from '../charts/BarChart'
import { guid } from '../../utilities/guids'
import { getName } from '../charts/util'
import Title from '../widgets/Title'
import Stack from '../widgets/Stack'
import { determineType } from '../../utilities/utils'

const Humanize = require('humanize-plus')

const DashboardCard = (props) => {
  const [uniqueId, setUniqueId] = useState()
  const [columns, setColumns] = useState()
  const [emptyResult, setEmptyResult] = useState(false)
  const [datasets, setDatasets] = useState()
  const [xAxisLabels, setXAxisLabels] = useState()
  const [yAxisLabels, setYAxisLabels] = useState()
  const [datasetLabels, setDatasetLabels] = useState()
  const [labels, setLabels] = useState()
  const [dataGridData, setDataGridData] = useState()
  const [categories, setCategories] = useState()
  const defaultPageSize = 500

  const checkDiff = (value) => {
    return value == null ? '' : JSON.stringify(value)
  }

  useEffect(() => {
    if (props.type == "data grid") {
      getColumns()
    }
  }, [props.compare_start != null])

  useEffect(() => {
    if (props.type != "data grid") {
      fetch()
    } else {
      if (columns != null) {
        setUniqueId(guid()) // refresh table
      }
    }
  }, [checkDiff(props.filters), checkDiff(props.variables), checkDiff(props.compare_start), checkDiff(props.compare_end),
  checkDiff(props.columns), checkDiff(props.lines), checkDiff(props.axes), checkDiff(props.map), checkDiff(props.nameAttribute), checkDiff(props.valueAttribute), props.pageSize])

  const generateBarChartData = (sqlResult) => {
    const newLabels = []
    const newDatasets = []
    const newDatasetLabels = []
    const dataKeys = sqlResult.length == 0 ? [] : Object.keys(sqlResult[0])

    // combo_column_line defaults to first column as line and others as columns
    const lines = props.lines ? props.lines : dataKeys.length > 1 ? [dataKeys[1]] : []
    const columns = props.columns ? props.columns : []
    const other = lines.find(l => l == 'dashboardCanvas.otherColumns') ? 'line' : 'bar'

    const defaultAxis = props.axes.find(axis => axis.columns.includes('dashboardCanvas.otherColumns'))
    if (dataKeys.length) {
      const keys = []
      let labelKey = null
      for (let i = 0; i < dataKeys.length; i++) {
        const key = dataKeys[i]
        const axis = props.axes.find(axis => axis.columns.includes(key))
        if (i == 0) {
          labelKey = key
        } else {
          keys.push(key)
          let type = null
          if (props.type == "combo_column_line") {
            type = lines.indexOf(key) >= 0 ? 'line' : columns.indexOf(key) >= 0 ? 'bar' : other
          }
          newDatasetLabels.push(axis == null ? defaultAxis.title : axis.title)
          newDatasets.push({
            type: type,
            metric: key == null ? "Null" : key,
            data: [],
          })
        }
      }
      sqlResult.forEach((result) => {
        newLabels.push(result[labelKey] == null ? "Null" : result[labelKey])
        let i = 0
        keys.forEach((key) => {
          newDatasets[i].data.push(
            result[key] == null ? null : parseFloat(result[key])
          )
          i++
        })
      })
    }
    setLabels(newLabels)
    setDatasets(newDatasets)
    setDatasetLabels(newDatasetLabels)
  }

  const generatePieOrFunnelChartData = (sql_result) => {
    if (sql_result.length > 0) {
      const newLabels = []
      const newDatasets = []
      sql_result.forEach((result) => {
        let i = 0
        for (const l in result) {
          if (i == 0) {
            newLabels.push(result[l])
          } else if (i == 1) {
            newDatasets.push(result[l])
          }
          i++
        }
      })
      setDatasets(newDatasets)
      setLabels(newLabels)
      setEmptyResult(false)
    } else {
      setEmptyResult(true)
      setDatasets(null)
    }
  }

  const generateLineChartData = (sql_result) => {
    const newXAxisLabels = []
    const newYAxisLabels = []
    const data = {}

    sql_result.forEach((row) => {
      const x_key = Object.keys(row)[0]
      newXAxisLabels.push(row[x_key])

      for (let k in row) {
        if (k != x_key) {
          if (!(k in data)) {
            data[k] = []
          }
          data[k].push(row[k])
        }
      }
    })

    const newDatasetLabels = []
    const newDatasets = []

    const defaultAxis = props.axes.find(axis => axis.columns.includes('dashboardCanvas.otherColumns'))
    for (let k in data) {
      const axis = props.axes.find(axis => axis.columns.includes(k))
      newYAxisLabels.push(axis == null ? defaultAxis.title : axis.title)
      newDatasetLabels.push(k)
      newDatasets.push(data[k])
    }

    setDatasets(newDatasets)
    setDatasetLabels(newDatasetLabels)
    setXAxisLabels(newXAxisLabels)
    setYAxisLabels(newYAxisLabels)
  }

  const fetch = async (tableData = {}) => {
    if (props.sql_id != '') {
      const response = await executeCardSql(tableData)
      return processResponse(response)
    }
  }

  const processResponse = (response) => {
    if (response.result == "success") {
      if (props.type != "data grid") {
        if (props.type == "pie" || props.type == "funnel") {
          generatePieOrFunnelChartData(response.results)
        } else if (isBarChart()) {
          generateBarChartData(response.results)
        } else if (props.type == "line" || props.type == "multi_line") {
          generateLineChartData(response.results)
        } else if (props.type == "info box") {
          const newCategories = []
          if (response.results.length) {
            newCategories.push(response.results[0][props.info_box_metric])
          }
          setCategories(newCategories)
        } else if (props.type == "geo") {
          setDatasets(response.results)
        }
      } else {
        setDataGridData(response.results)
      }
    } else {
      FTNotice(response.result, 3000)
    }
    return response
  }

  const exportCardSql = (tableData = {}) => {
    const params = getFilters()
    if (props.variables !== null) {
      params["custom_variables"] = props.variables
    }
    return exportSql(props.sql_id, params, tableData)
  }

  const executeCardSql = (tableData) => {
    const params = getFilters()
    if (props.variables !== null) {
      const variables = props.variables.map(v => Object.assign({}, v))
      if (props.params != null) {
        for (const [key, value] of Object.entries(props.params)) {
          const variable = variables.find(v => v.name == key)
          if (variable != null) {
            variable.value = value
          }
        }
      }
      params["custom_variables"] = variables
    }
    return executeSql(props.sql_id, params, tableData)
  }

  const getFilters = () => {
    if (props.compare_start != null && props.compare_end != null) {
      return Object.assign(
        {
          internal_client_id: props.internal_client_id,
          from_date: props.start_date,
          to_date: props.end_date,
          compare_start: props.compare_start,
          compare_end: props.compare_end,
        },
        props.filters
      )
    } else {
      return Object.assign(
        {
          internal_client_id: props.internal_client_id,
          from_date: props.start_date,
          to_date: props.end_date,
        },
        props.filters
      )
    }
  }

  const kFormatter = (num) => {
    if (/[a-zA-Z]/g.test(num) || num == '') { // check if we have any letters
      return num
    } else {
      const hasCurrency = String(num).startsWith('$')
      const hasPercent = String(num).endsWith('%')
      const stripped = String(num).replace(/$/g, '').replace(/%/g, '').replace(/,/g, '')
      const val = Math.abs(stripped) > 999
        ? Humanize.formatNumber(stripped / 1000, 1) + "k"
        : Humanize.formatNumber(stripped, 2)
      return (hasCurrency ? '$' : '') + val + (hasPercent ? '%' : '')
    }
  }

  const isNotEmptyCol = (col, rows) => {
    for (let i = 0; i < rows.length; i++) {
      let value = rows[i][col]
      if (value != null && value != 0) {
        value = (value + "").replace(/\s+/g, "")
        if (value != "") {
          return true
        }
      }
    }
    return false
  }

  const pageSize =
    props.page_size && props.page_size != ""
      ? parseInt(props.page_size)
      : 0

  const getColumns = () => {
    setUniqueId()
    executeCardSql({ page: 1, page_size: pageSize == 0 ? defaultPageSize : pageSize }).then((response) => {
      const sqlResult = response.results
      if (response.result == "success") {
        determineColumns(sqlResult)
      } else {
        FTNotice(response.result, 3000)
      }
    })
  }

  const determineColumns = (sqlResult) => {
    const newColumns = []
    if (sqlResult.length > 0) {
      Object.keys(sqlResult[0]).forEach((columnName, i) => {
        if (isNotEmptyCol(columnName, sqlResult)) {
          let column = {
            id: columnName,
            datatype: determineType(sqlResult, columnName),
            pinned: props.num_pinned && props.num_pinned > i ? 'left' : null,
            cellRenderer: (value) => (
              <div dangerouslySetInnerHTML={{ __html: value }} />
            ),
            cellRendererAffectsSize: (value) => {
              return value != null && (value + "").indexOf("<") >= 0
            },
          }
          column["header"] = getName(columnName)
          newColumns.push(column)
        }
      })
    }
    setColumns(newColumns)
    setUniqueId(guid())
  }

  const editCard = () => {
    if (props.edit_action) {
      props.edit_action(props.type, props.card_id)
    }
  }

  const isBarChart = () => {
    return [
      "bar",
      "stacked_bar",
      "column",
      "stacked_column",
      "combo_column_line",
    ].includes(props.type)
  }

  const table = useMemo(() => {
    if (uniqueId) {
      return (
        <Table
          fetch={(tableData) => fetch(tableData)}
          export={(tableData) => exportCardSql(tableData)}
          columns={columns}
          pageSize={pageSize == 0 ? defaultPageSize : pageSize}
          hidePagination={pageSize == 0}
          marginBottom={0}
          height="calc(100% - 60px)"
          style={{ height: '100%' }}
          changeParams={uniqueId}
        />
      )
    }
  }, [uniqueId])

  const renderCard = () => {
    let chartTypes = [
      "line",
      "multi_line",
      "bar",
      "stacked_bar",
      "pie",
      "funnel",
      "geo",
      "column",
      "stacked_column",
      "combo_column_line",
    ]
    if (chartTypes.includes(props.type)) {
      if (datasets || emptyResult) {
        let type = props.type
        let stacked = false
        if (isBarChart()) {
          stacked = type == "stacked_bar" || type == "stacked_column"
          if (type == "bar" || type == "stacked_bar") {
            type = "bar"
          } else {
            type = "column"
          }
        }
        return (
          <div style={{ height: "100%", width: "100%" }}>
            {props.type == "line" && datasets && (
              <LineChart
                className="js_complete"
                key={JSON.stringify(props)}
                title={props.title}
                x_axis_labels_1={xAxisLabels}
                x_axis_labels_2={null}
                y_axis_labels={yAxisLabels}
                dataset_labels={datasetLabels}
                datasets={datasets}
                animation={false}
              />
            )}
            {props.type == "pie" && datasets && (
              <PieChart
                className="js_complete"
                key={JSON.stringify(props)}
                labels={labels}
                datasets={[datasets]}
                title={props.title}
                animation={false}
              />
            )}
            {props.type == "funnel" && datasets && (
              <FunnelChart
                className="js_complete"
                key={JSON.stringify(props)}
                labels={labels}
                datasets={[datasets]}
                title={props.title}
              />
            )}
            {props.type == "geo" && datasets && (
              <GeoChart
                className="js_complete"
                id={props.id}
                title={props.title}
                nameAttribute={props.nameAttribute}
                valueAttribute={props.valueAttribute}
                map={props.map}
                values={datasets}
              />
            )}
            {isBarChart() && datasets && (
              <BarChart
                className="js_complete"
                labels={labels}
                dataset_labels={datasetLabels}
                datasets={datasets}
                title={props.title}
                type={type}
                stacked={stacked}
                style={{ left: 1, top: 1 }}
                animation={false}
              />
            )}
          </div>
        )
      } else {
        return <CircularProgress className="progress" />
      }
    } else if (props.type == "text label") {
      return (<Title title={props.text_label} variant='dashboardTextLabel' />)
    } else if (props.type == "info box") {
      if (categories || emptyResult) {
        return (
          <Stack direction="row" spacing={1}>
            {/* <Grid container spacing={0}>
            <Grid item xs={3}> */}
            <img
              src={props.icon}
              style={{ width: "100px", height: "100px" }}

            />
            {/* </Grid>
            <Grid item xs={9}> */}
            <Box sx={{ paddingLeft: 2, paddingRight: 2, width: '100%' }}>
              <Stack spacing={1}>
                <Title title={props.info_box_title} variant='dashboardCardTitle' />
                <Divider />
                <Title title={kFormatter(categories[0])} variant='dashboardCardKPI' />
              </Stack>
            </Box>
            {/* </Grid>
          </Grid> */}
          </Stack>
        )
      } else {
        return <CircularProgress className="progress" />
      }
    } else if (props.type == "data grid") {
      if (columns) {
        return (
          <div style={{ height: "100%", width: "100%" }} className="center">
            <h3>{props.title}</h3>
            <br />
            <div
              className={dataGridData ? "js_complete" : null}
              onMouseDown={(event) => {
                event.stopPropagation()
              }}
              style={{ height: "calc(100% - 60px)", overflow: "auto" }}
            >
              {table}
            </div>
          </div>
        )
      } else {
        return <CircularProgress className="progress" />
      }
    }
  }

  return (
    <div onDoubleClick={() => editCard()} style={{ height: "100%" }}>
      {renderCard()}
    </div>
  )
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default connect(mapStateToProps)(DashboardCard)
