import * as React from 'react'
import Fab from '@mui/material/Fab'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import { ThemeProvider } from '@mui/system'
import { theme } from '../widgets/theme'

const fabStyle = {
  position: "fixed",
  bottom: "2%",
  right: "1%",
  color: "#FFF"
}

const FtoFab = (props) => {
  return (
    <Fab
      sx={fabStyle}
      color="primary"
      size="large"
      variant="circular"
      onClick={props.onClick}>
      <ElectricBoltIcon />
    </Fab>
  )
}

const FabAI = (props) => {
  const handleClick = () => {
    props.togglePrompt()
  }

  return (
    <ThemeProvider theme={theme}>
      <FtoFab onClick={handleClick} open={props.open} />
    </ThemeProvider>
  )
}

export default FabAI