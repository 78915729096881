import request from './request'
import querystring from 'querystring';

// services, workflows
export function getServiceHistory(internalClientId, serviceType, tableData) {
  const url = '/api/service_history?internal_client_id=' + internalClientId + (serviceType ? ('&service_type=' + serviceType): '') + '&' + querystring.stringify(tableData);
  return request(url);
}

// services, workflows
export function getRunHistory(run_id) {
  return request('/api/service_history?run_id=' + run_id);
}

// services, workflows
export function addServiceHistory(data) {
  const url = '/api/service_history'
  return request(url, {}, 'POST', data)
}

// services, workflows
export function getServices(internal_client_id, serviceType=null, dynamic=null) {
  return request('/api/services?internal_client_id='+internal_client_id + (serviceType ? ('&service_type=' + serviceType) : '') + (dynamic ? '&dynamic=true' : ''));
}

// services, workflows
export function getService(id, serviceType) {
  return request('/api/services/' + id + '?service_type=' + serviceType);
}

// services, workflows
export function createService(serviceType, data) {
  return request('/api/services?service_type=' + serviceType, {}, 'POST', data);
}

// services, workflows
export function updateService(id, serviceType, data) {
  return request('/api/services/' + id + '?service_type=' + serviceType, {}, 'PUT', data);
}

// services, workflows
export function deleteService(id, serviceType) {
  return request('/api/services/' + id + '?service_type=' + serviceType, {}, 'DELETE');
}

// services, workflows
export function getServiceStatus(run_id) {
  return request('/api/service_runs/' + run_id + '/status');
}

// services, workflows
export function stopService(run_id) {
  return request('/api/service_runs/' + run_id + '/stop', {}, 'POST', {});
}
