import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import SQL from './Sql'
import Dashboard from './Dashboard'
import Explore from './Explore'
import Form from '../Form'
import Page from '../widgets/Page'

class Interface extends Component {
  constructor(props){
    super(props)
    this.state = {
      tabIndex: 0,
      tabs: [{
        id: 'sql',
        name: 'SQL',
        component: (<SQL/>)
      }, {
        id: 'dashboards',
        name: 'Dashboards',
        component: (<Dashboard/>)
      }, {
        id: 'explore',
        name: 'Explore',
        component: (<Explore/>)
      }],
    }
    if (props.match.params.tab) {
      for (let i = 0; i < this.state.tabs.length; i++) {
        if (this.state.tabs[i].id == props.match.params.tab) {
          this.state.tabIndex = i
        }
      }
    }
  }

  updateTab(tabIndex) {
    this.setState({tabIndex: tabIndex})
    window.location.href = '/#/dashboard_admin/' + this.state.tabs[tabIndex].id
  }

  render() {
    return (
    <Page>
      <Form
        menu="Report"
        item="Dashboard Admin"
        objectType=""
        objectName={null}
        newObject={false}
        canDelete={false}
        canEdit={false}
        parentLink={null}
        tabs={this.state.tabs} tabIndex={this.state.tabIndex} updateTab={(tabIndex) => {this.updateTab(tabIndex)}}
      />
    </Page>
    )
  }
}

export default withRouter(Interface)
