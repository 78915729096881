import React, { useRef, useState, useEffect } from 'react'
import Button from '../../widgets/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import i18next from 'i18next'
import { Box, MenuItem, Paper, Select, FormControl, InputLabel, Slide, Typography } from '@mui/material'

const KeywordPreview = ({ keywords, keywordCount }) => {
  const prev = useRef({ keywords: null })
  const [activeIndex, setActiveIndex] = useState(0)
  const [slideDirection, setSlideDirection] = useState('left')
  const [slideIn, setSlideIn] = useState(true)

  useEffect(() => {
    if (prev.current.keywords?.length) {
      const oldKeyword = prev.current.keywords[activeIndex]
      const newIndex = keywords.indexOf(oldKeyword)
      setActiveIndex(newIndex >= 0 ? newIndex : 0)
    }
    prev.current.keywords = keywords
  }, [keywords])

  const handleChange = (e) => {
    setActiveIndex(keywords.indexOf(e.target.value))
  }

  const containerRef = useRef(null)
  const containerRef2 = useRef(null)

  const handleClickNext = () => {
    setSlideDirection('left')
    setTimeout(() => {
      setSlideIn(false)
    })
    setTimeout(() => {
      setActiveIndex(activeIndex === 0 || activeIndex !== keywords.length ? activeIndex + 1 : 0)
      setSlideDirection('right')
    }, 500)
    setTimeout(() => {
      setSlideIn(true)
    }, 501)
  }

  const handleClickPrevious = () => {
    setSlideDirection('right')
    setTimeout(() => {
      setSlideIn(false)
    })
    setTimeout(() => {
      setActiveIndex(activeIndex === 0 || activeIndex !== keywords.length ? activeIndex + 1 : 0)
      setSlideDirection('left')
    }, 500)
    setTimeout(() => {
      setSlideIn(true)
    }, 501)
  }

  const createData = (name, value) => {
    name = i18next.t(name)
    return { name, value }
  }

  if (keywords.length == 0) {
    return null
  }

  const keyword = keywords[activeIndex]

  if (keyword == null) {
    return null
  }
  
  const rows = []
  if (keyword.entity_name) {
    rows.push(createData(i18next.t('biddingDetails.keyword'), keyword.entity_name || ''))
  }
  rows.push(...[
    createData('biddingDetails.entityType', keyword.entity_type || ''),
    createData('biddingDetails.convRate', keyword.conversion_rate == null ? '' : keyword.conversion_rate.toFixed(2) + '%'),
    createData('biddingDetails.avgCPC', keyword.cpc == null ? '' : ('$' + keyword.cpc.toFixed(2))),
    createData('biddingDetails.currentBid', keyword.old_bid == null ? '' : ('$' + keyword.old_bid)),
    createData('biddingDetails.newBid', keyword.new_bid == null ? '' : ('$' + keyword.new_bid)),
    createData('biddingDetails.absChange', keyword.abs_change == null ? '' : ('$' + keyword.abs_change)),
    createData('biddingDetails.percentChange', keyword.percent_change == null ? '' : keyword.percent_change.substring(0, 4) + '%'),
    createData('biddingDetails.cost', keyword.cost == null ? '' : ('$' + keyword.cost)),
    createData('biddingDetails.clicks', keyword.clicks == null ? '' : keyword.clicks),
    createData('biddingDetails.conversions', keyword.conversions == null ? '' : keyword.conversions),
    createData('biddingDetails.cpa', keyword.cpa == null ? '' : ('$' + keyword.cpa)),
    createData('biddingDetails.roas', keyword.roas == null ? '' : keyword.roas.toFixed(2)),
    createData('biddingDetails.finalURL', keyword.final_url == null ? '' : <a href={keyword.final_url} target="_blank" rel="noopener noreferrer">
      Link
    </a>),
  ])

  return (
    <Box ref={containerRef2} display='flex' flexDirection='column' alignItems='center'>
      <Slide
        in={keywords?.length > 0}
        direction="up"
        container={containerRef2.current}>
        <FormControl fullWidth>
          <InputLabel>{i18next.t('biddingDetails.keywordPreview')}</InputLabel>
          <Select
            label={i18next.t('biddingDetails.keywordPreview')}
            onChange={(e) => handleChange(e)} value={keyword}
            sx={{
              '& > svg': {
                color: 'primary.main',
                right: -200,
                visibility: 'hidden',
              }
            }}
          >
            {keywords.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option.entity_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Slide>
      {keywords && <Box
        display='flex'
        justifyContent='center'
        sx={{
          borderRadius: 3,
          height: '450px',
          width: '100%',
          overflow: 'hidden',
        }}
        ref={containerRef}>
        <Slide
          direction={slideDirection}
          in={slideIn}
          container={containerRef.current}
          mountOnEnter unmountOnExit>
          <TableContainer
            component={Paper}
            sx={{ my: 1.5, height: '100%', marginTop: '0px' }}
          >
            <Table
              sx={{
                backgroundColor: '#fff',
                borderRadius: 3,
                width: '100%',
              }}
              size='small'>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Slide>
      </Box >}
      {keywords.length == 100 && (
        <Typography align="center" sx={{ fontStyle: 'italic', fontSize: 12, mt: 1 }}>{i18next.t('biddingSummary.sampling1')}{keywordCount}{i18next.t('biddingSummary.sampling2')}</Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'flex-end' }}>
        <Button
          variant='standard'
          onClick={() => handleClickPrevious()}
          buttonText="biddingDetails.prev" />
        <Button
          variant='standard'
          onClick={() => handleClickNext()}
          buttonText="biddingDetails.next" />
      </Box >
    </Box >
  )
}

export default KeywordPreview