import Switch from '../widgets/Switch'
import i18next from 'i18next'

const SqlTemplateSwitch = (props) => {
  return (
    <Switch
      onChange={() => props.setShowSqlTemplates(!props.showSqlTemplates)}
      label={i18next.t('abtest.edit.useSqlTemplate')}
      checked={props.showSqlTemplates} />
  )
}

export default SqlTemplateSwitch