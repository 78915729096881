import { Component } from 'react';
import FTOTable from '../../widgets/Table'

export default class Table extends Component {
  getColumns() {
    return [{
      id: 'date',
      datatype: 'date',
      width: 100,
    }, {
      id: 'experiment_type',
      datatype: 'text',
      width: 100,
    }, {
      id: 'cost',
      datatype: 'numeric',
      width: 90
    }, {
      id: 'clicks',
      datatype: 'numeric',
      width: 85
    }, {
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 150
    }, {
      id: 'internal_roas',
      datatype: 'numeric',
      width: 150
    }, {
      id: 'cpc',
      datatype: 'numeric',
      width: 70,
    }, {
      id: 'rpc',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'internal_cr',
      datatype: 'numeric',
      width: 150
    }, {
      id: 'internal_aov',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'ctr',
      datatype: 'numeric',
      width: 75
    }, {
      id: 'impressions',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'max_cpc',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_conversion',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_cr',
      datatype: 'numeric',
      width: 150,
    }, {
      id: 'engine_revenue',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_cpa',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_roas',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_aov',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'engine_rpc',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'internal_conversion',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'cpa',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'escore_cost',
      header: 'abtest.metrics.escore_cost',
      datatype: 'numeric',
      width: 120,
    }, {
      id: 'escore_clicks',
      header: 'abtest.metrics.escore_clicks',
      datatype: 'numeric',
      width: 120,
    }];
  }

  render() {
    return (
      <div >
        <div className="box">
          <div className="box-header with-border">
            <div className="box-body">
              <FTOTable
                headerHeight={50}
                tableName={'ab_test_result'}
                dataset={this.props.data ? this.props.data : []}
                columns={this.getColumns()} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}