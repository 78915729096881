import request from "./request"
import querystring from 'querystring'

// bidding
export function deleteBidRun(id) {
  const url = '/api/bidding_runs/'+id
  return request(url, {}, 'DELETE')
}

// bidding
export function generateMetrics(id) {
  const url = '/api/bidding_runs/'+id+'/generate_metrics'
  return request(url, {}, 'POST')
}

// bidding
export function revertBidRun(id) {
  const url = '/api/bidding_runs/'+id+'/revert'
  return request(url, {}, 'POST')
}

// bidding
export function deleteBidAlgo(id) {
  const url = '/api/bidding_algos/'+id
  return request(url, {}, 'DELETE')
}

// bidding
export function getBidAlgo(id) {
  const url = '/api/bidding_algos/'+id
  return request(url)
}

// bidding
export function saveBidAlgo(data) {
  const url = '/api/bidding_algos'
  return request(url, {}, 'POST', data)
}

// bidding
export function updateBid(id, data) {
  return request('/api/bids/' + id, {}, 'PUT', data)
}

// bidding
export function deleteBid(id) {
  return request('/api/bids/' + id, {}, 'DELETE')
}

// bidding
export function getClientPacingData(data) {
  const url = '/api/bidding_run?type=client_pacing&internal_client_id='+data.internal_client_id+'&channels='+data.channels+'&subbudget='+data.subbudget
  return request(url)
}

// bidding
export function getDefaultRoas(internal_client_id) {
  const url = '/api/bidding_run?type=default_roas&internal_client_id='+internal_client_id+'&channels='
  return request(url)
}

// bidding
export function getDefaultRoasSubbudget(internal_client_id,subbudget) {
  const url = '/api/bidding_run?type=default_roas_subbudget&internal_client_id='+internal_client_id+'&subbudget='+subbudget+'&channels='
  return request(url)
}

// bidding
export function getBiddingRuns(internalClientId, type, tableData) {
  return request('/api/bidding_runs?internal_client_id=' + internalClientId + '&type=' + type + "&" + querystring.stringify(tableData))
}

// bidding
export function getAlgos(internal_client_id, tableData, bidding=false) {
  return request(`/api/bidding_algos?internal_client_id=${internal_client_id}&bidding=${bidding}&${querystring.stringify(tableData)}`)
}

// bidding
export function saveBiddingRun(data) {
  const url = '/api/bidding_run?type=save_bidding_run'
  return request(url, {}, 'POST', data)
}

// bidding
export function generateBids(data) {
  const url = '/api/bidding_run?type=generate_bids'
  return request(url, {}, 'POST', data)
}

// bidding
export function getBidRunData(id) {
  const url = '/api/bidding_run?type=get_bid_run&bidding_run_id='+id
  return request(url)
}

// bidding
export function getBidSummary(id, tableData) {
  const url = '/api/bidding_run?type=get_bid_summary&bidding_run_id=' + id + '&' + querystring.stringify(tableData)
  return request(url)
}

function addFilters(engines, campaigns, adGroups) {
  let filters = ''
  if (engines) {
    engines.forEach(engine => filters += `&engines=${engine}`)
  }
  if (campaigns) {
    campaigns.forEach(campaign => filters += `&campaigns=${campaign}`)
  }
  if (adGroups) {
    adGroups.forEach(adGroup => filters += `&ad_groups=${adGroup}`)
  }
  return filters
}

// bidding
export function getBidDetails(id, tableData, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=get_bid_details&bidding_run_id=' + id + '&' + querystring.stringify(tableData)
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function getFilters(id) {
  const url = '/api/bidding_run?type=filters&bidding_run_id=' + id
  return request(url)
}

// bidding
export function getBidSummaryNew(id, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=bid_summary&bidding_run_id=' + id
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function getBidDetailsNew(id, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=bid_details&bidding_run_id=' + id
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function getBidHistory(id, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=bid_history&bidding_run_id=' + id
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function exportBidDetails(id, tableData, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=export_bid_details&bidding_run_id=' + id + '&' + querystring.stringify(tableData)
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function exportNoClicks(id, tableData, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=export_no_clicks&bidding_run_id=' + id + '&' + querystring.stringify(tableData)
  return request(url + addFilters(engines, campaigns, adGroups))
}

// bidding
export function getBidComparisonSummary(id1, id2, tableData) {
  const url = '/api/bidding_run?type=get_bid_comparison_summary&id1=' + id1 + '&id2=' + id2 + '&' + querystring.stringify(tableData)
  return request(url)
}

// bidding
export function getBidComparisonDetails(id1, id2, tableData) {
  const url = '/api/bidding_run?type=get_bid_comparison_details&id1=' + id1 + '&id2=' + id2 + '&' + querystring.stringify(tableData)
  return request(url)
}

// bidding
export function exportBidComparisonDetails(id1, id2, tableData) {
  const url = '/api/bidding_run?type=export_bid_comparison_details&id1=' + id1 + '&id2=' + id2 + '&' + querystring.stringify(tableData)
  return request(url)
}

// bidding
export function publishBids(id, engines, campaigns, adGroups) {
  const url = '/api/bidding_run?type=publish_bids&bidding_run_id='+id
  return request(url + addFilters(engines, campaigns, adGroups), {}, 'PATCH')
}

// bidding
export function getBidRunStatus(id) {
  const url = '/api/bidding_run?type=get_status&bidding_run_id='+id
  return request(url)
}

// bidding
export function getExcludedAccounts(internal_client_id, tableData) {
  return request('/api/bidding/excluded_accounts?internal_client_id=' + internal_client_id + '&' + querystring.stringify(tableData))
}

// bidding
export function createExcludedAccount(data) {
  return request('/api/bidding/excluded_accounts', {}, 'POST', data)
}

// bidding
export function deleteExcludedAccount(id) {
  return request('/api/bidding/excluded_accounts/' + id, {}, 'DELETE', {})
}

// bidding
export function getExcludedCampaigns(internal_client_id, tableData) {
  return request('/api/bidding/excluded_campaigns?internal_client_id=' + internal_client_id + '&' + querystring.stringify(tableData))
}

// bidding
export function createExcludedCampaign(data) {
  return request('/api/bidding/excluded_campaigns', {}, 'POST', data)
}

// bidding
export function deleteExcludedCampaign(id) {
  return request('/api/bidding/excluded_campaigns/' + id, {}, 'DELETE', {})
}

// bidding
export function getExcludedLastDays(internal_client_id, tableData=undefined) {
  return request('/api/bidding/excluded_last_days?internal_client_id=' + internal_client_id)
}

// bidding
export function createExcludedLastDays(data) {
  return request('/api/bidding/excluded_last_days', {}, 'POST', data)
}

// bidding
export function updateExcludedLastDays(data) {
  return request('/api/bidding/excluded_last_days', {}, 'PUT', data)
}

// bidding
export function deleteExcludedLastDays(id) {
  return request('/api/bidding/excluded_last_days/' + id, {}, 'DELETE', {})
}

// bidding
export function getExcludedAccountOptions(internal_client_id) {
  return request('/api/bidding/excluded_accounts/options?internal_client_id=' + internal_client_id)
}

// bidding
export function getExcludedCampaignOptions(internal_client_id) {
  return request('/api/bidding/excluded_campaigns/options?internal_client_id=' + internal_client_id)
}

// bidding
export function getLastROAS(internal_client_id) {
  return request('/api/bidding_runs/last_roas?internal_client_id=' + internal_client_id)
}
