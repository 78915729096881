import store from '../redux/store'
let authToken = null

export function setToken(token) {
  authToken = token
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status == 401) {
    if (response.url.endsWith('/login')) {
      return response
    } else {
      location.reload()
    }
  } else {
    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

function parseJSON(response) {
  return response.json()
}

function isProduction() {
  return location.host == 'app.ftoptimize.com'
}

function setTime(label, elapsed) {
  if (isProduction()) {
    gtag('event', 'timing', {
      category: 'API',
      url: label,
      time: elapsed,
    })
  }
}

export default (url, options = {}, method = 'GET', data = {}, json = true, file_data = false) => {
  const started = new Date()
  let params = {}

  if (options == 'json') {
    options = {'Content-Type': 'application/json'}
  }

  const state = store.getState()
  const headers = {
    'X-CSRF-Token': $( 'meta[name="csrf-token"]' ).attr( 'content' ),
    'Authorization': 'Bearer ' + authToken,
  }
  const internal_client_id = state.users?.user?.client?.internal_client_id
  if (internal_client_id != null) {
    headers['Client'] = internal_client_id
  }
  params = Object.assign({
    headers: headers,
    credentials: 'same-origin',
    method: method,
  }, options)

  if (method == 'POST' || method == 'PUT' || method == 'PATCH') {
    if(file_data){
      params = Object.assign(params, {
        headers: Object.assign(params.headers ? params.headers : {}, 
          { 'X-CSRF-Token': $( 'meta[name="csrf-token"]' ).attr( 'content' )}),
        body: data
       })
    }
    else{
      params = Object.assign(params, {
        headers: Object.assign(params.headers ? params.headers : {}, { 'X-CSRF-Token': $( 'meta[name="csrf-token"]' ).attr( 'content' ),
                   'Content-Type': 'application/json'}),
        body: JSON.stringify(data)
      })
    }
  }

  document.body.style.cursor = "wait"
  return fetch(url, params)
    .then(checkStatus).then((response) => {
      document.body.style.cursor = "default"
      const elapsed = new Date() - started
      setTime(url, elapsed)
      return json ? parseJSON(response) : response
    })
    .catch(function(error) {
      console.log('request failed', error)
      document.body.style.cursor = "default"
    })
}