import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DropDownList from '../DropDownList';

/**
 * Dialog to add an exclusion.
 * 
 * Properties:
 *   name - name of the exclusion (Account or Campaign).
 *   getOptions - Get the options for exclusions (method that takes no parameters and returns a promise).
 *   onClose - function to call on close (will be passed null on cancel or the data to add on OK).
 */
export default class AddExclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engine: '',
      engines: null,
      option: null,
      options: null,
      engineOptions: null,
    }
  }

  componentDidMount() {
    this.props.getOptions().then((options) => {
      const engines = [];
      options.forEach(option => {
        if (engines.indexOf(option.engine) < 0) {
          engines.push(option.engine);
        }
      });
      engines.sort();
      const engine = engines.length ? engines[0] : '';
      const option = options.length ? options[0] : null;
      this.setState({engines: engines, engine: engine, options: options, option: option}, () => this.selectEngine());
    });
  }

  selectEngine() {
    const options = [];
    this.state.options.forEach(option => {
      if (option.engine == this.state.engine) {
        option = Object.assign({}, option);
        option.name = option.name + ' (' + option.id + ')';
        options.push(option);
      }
    })
    this.setState({engineOptions: options});
  }

  addExclusion() {
    this.props.onClose({engine: this.state.engine, id: this.state.option ? this.state.option.id : null});
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={() => this.props.onClose(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title"><b>Create {this.props.name} Exclusion</b></DialogTitle>
        <DialogContent>
          {!this.state.engineOptions && (
            <span>Loading...</span>
          )}
          {this.state.engineOptions && this.state.engineOptions.length == 0 && (
            <span>All {this.props.name.toLowerCase()}s have been excluded.</span>
          )}
          {this.state.engineOptions && this.state.engineOptions.length > 0 && (
            <table>
              <tbody>
                <tr>
                  <td style={{padding: 5, textAlign: 'right'}}>Engine</td>
                  <td style={{padding: 5}}>
                    <select className="form-control" value={this.state.engine} onChange={(event) => this.setState({engine: event.target.value}, () => this.selectEngine())}>
                      {this.state.engines.map((option, i) => (
                        <option key={i} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: 5, textAlign: 'right'}}>{this.props.name}</td>
                  <td style={{padding: 5}}>
                    <DropDownList buttonLabel={this.state.option.name} values={this.state.engineOptions} action={(option) => this.setState({option: option})}/>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </DialogContent>
        <div className="text-center" style={{marginBottom: 20}}>
          {this.state.engineOptions && this.state.engineOptions.length > 0 && (
            <button className="btn btn-primary" onClick={() => this.addExclusion()}>OK</button>
          )}
          <button className="btn btn-primary" onClick={() => this.props.onClose(null)} style={{marginLeft: this.state.engineOptions && this.state.engineOptions.length > 0 ? 10 : 0}}>Cancel</button>
        </div>
      </Dialog>
    );
  }
}
