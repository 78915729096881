import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { guid } from '../../utilities/guids'
import FTNotice from '../FTNotice'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Notebook from '../workflows/Notebook'
import History from './History'
import { getService } from '../../api/services'
import i18next from 'i18next'

class Service extends Component {
  constructor(props) {
    super(props)
    this.state = {
      run_id: this.props.match.params.run_id
        ? this.props.match.params.run_id
        : guid(),
      tabIndex: null,
      tabs: null,
      service: null,
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id != prevProps.match.params.id ||
      this.props.match.params.service_type !=
      prevProps.match.params.service_type
    ) {
      this.getData()
    }
    if (
      this.props.match.params.run_id &&
      this.props.match.params.run_id != this.state.run_id
    ) {
      this.setState({ run_id: this.props.match.params.run_id })
    }
  }

  getId() {
    return this.props.match.params.id == null ? 0 : this.props.match.params.id
  }

  getData() {
    const id = this.getId()
    const serviceType = this.props.match.params.service_type
    getService(id, serviceType).then((service) => {
      if (!service) {
        FTNotice(i18next.t('service.doesNotExist'), 15000)
      } else {
        const hasInstances = service.has_instances
        const tabs = hasInstances
          ? [
            { name: "run", text: i18next.t('service.run') },
            { name: "configure", text: i18next.t('service.configure') },
            { name: "history", text: i18next.t('service.history') },
          ]
          : [
            { name: "run", text: i18next.t('service.run') },
            { name: "history", text: i18next.t('service.history') },
          ]
        const tabIndex =
          this.props.match.params.tab == "configure"
            ? 1
            : this.props.match.params.tab == "history"
              ? hasInstances
                ? 2
                : 1
              : 0
        if (hasInstances) {
          import("./" + serviceType + "/AddEdit.jsx").then((module) => {
            const Configure = module.default
            service.configure = (
              <Configure
                id={id}
                callback={(action) => this.handleCallBack(action)}
              />
            )
            this.setState({ service: service, tabs: tabs, tabIndex: tabIndex })
          })
        } else {
          this.setState({ service: service, tabs: tabs, tabIndex: tabIndex })
        }
      }
    })
  }

  updateTab(index) {
    const tabName = this.state.tabs[index].name
    const runId = tabName == "history" ? "" : "/" + this.state.run_id
    this.props.history(
      "/services/" +
      this.props.match.params.service_type +
      "/" +
      this.props.match.params.id +
      "/" +
      tabName +
      runId
    )
    this.setState({ tabIndex: index })
    this.getData()
  }

  handleCallBack(action) {
    if (action == "add") {
      this.updateTab(0)
    } else if (action == "delete") {
      this.props.history("/services")
    }
  }

  prettyPrint(text) {
    return text.replace(/_/g, " ");
  }

  renderTabPanels() {
    const serviceType = this.props.match.params.service_type
    const service = this.state.service
    const id = this.getId()
    let steps = [
      {
        type: serviceType,
        service: service,
        step_body: id,
      },
    ]
    return (
      <div>
        <TabPanel key="run">
          <Notebook
            steps={steps}
            run_id={this.state.run_id}
            location={this.props.location}
            noIndent={true}
            serviceType={service.service_type}
          />
        </TabPanel>
        {service.configure && (
          <TabPanel key="configure">
            {service.configure}
          </TabPanel>
        )}
        <TabPanel key="history">
          <History
            internal_client_id={this.props.internal_client_id}
            service_type={serviceType}
            toRunTab={() => this.updateTab(0)}
          />
        </TabPanel>
      </div>
    )
  }

  render() {
    return (
      <div className="site-canvas">
        <section className="content">
          <div>
            {this.state.tabs && (
              <Tabs
                selectedIndex={this.state.tabIndex}
                onSelect={(tabIndex) => this.updateTab(tabIndex)}
              >
                <TabList>
                  {this.state.tabs.map((tab) => {
                    return <Tab key={tab.name}>{tab.text}</Tab>
                  })}
                </TabList>
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-info">{this.renderTabPanels()}</div>
                  </div>
                </div>
              </Tabs>
            )}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Service))
