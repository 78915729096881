import React from 'react'
import { Handle, Position } from 'reactflow'
import i18next from 'i18next'
import styles from './NodeStyles'
import Stack from '../widgets/Stack'

const Node = ({ id, data }) => {
  return (
    <>
      {!data.hideTarget && (
        <Handle id={`${id}left`} type="target" position={Position.Left} />
      )}
      {data.showLeftSource && (
        <Handle id={`${id}leftsource`} type="source" position={Position.Left} />
      )}
      {data.link && (
        <Stack spacing={0}>
          {data.showTop && (
            <Handle id={`${id}top`} type="target" position={Position.Top} />
          )}
          {data.showTopSource && (
            <Handle id={`${id}topsource`} type="source" position={Position.Top} />
          )}
          <div style={Object.assign({}, styles.container, {cursor: 'pointer'})} onClick={() => {location.href=data.link}}>
            <img style={styles.image} src={`/resources/images/workflow_icons/${data.image}`} />
            <div>{i18next.t(data.label)}</div>
          </div>
          {data.showBottom && (
            <Handle id={`${id}bottom`} type="source" position={Position.Bottom} />
          )}
        </Stack>
      )}
      {!data.hideSource && (
        <Handle id={`${id}right`} type="source" position={Position.Right} />
      )}
      {data.showRightTarget && (
        <Handle id={`${id}righttarget`} type="target" position={Position.Right} />
      )}
    </>
  )
}

export default Node
