import { useState, useEffect } from 'react'
import { Stack, Box, Switch, useMediaQuery } from '@mui/material'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { connect } from 'react-redux'
import ComparisonChartWithSelectors from '../charts/ComparisonChartWithSelectors'
import PromptDialogChat from './PromptDialogChat'
import { getAiSummary } from '../../api/ai_infrastructure'
import i18next from 'i18next'

const from_date = moment().subtract(28, 'day').format('YYYY-MM-DD')
const to_date = moment().subtract(1, 'day').format('YYYY-MM-DD')
const defaultMetrics = { view: 'metrics', metrics: [['clicks', 'impressions']], where: 'TRUE' }

const PromptDialogDashboard = ({
  user,
  generateTimestamp,
  hidePromptInput,
  setStatus,
  status,
  handleSubmit,
  promptDraft,
  requiredAlert,
  promptEngDraft,
  setPromptEngDraft,
  setPromptDraft,
  handleKeyDown,
  activeTab,
  lastPrompt,
  setLastPrompt,
  lastResponseOutput,
  addToPromptEngHistory,
  conversationId,
  setConversationId,
  conversation,
  setConversation,
  disabled,
  setDisabled,
  responseOutput,
  aiBot,
  userName,
  initialPackagedPrompt,
  promptThread,
  setPromptThread,
  feedback,
  setFeedback,
  rating,
  setRating,
  fullResponse,
  promptId,
  demoMode }) => {

  const [showCharts, setShowCharts] = useState(false)
  const [metrics, setMetrics] = useState(defaultMetrics)
  const [metricsData, setMetricsData] = useState({})
  const rawMetrics = metrics.metrics
  const numCharts = rawMetrics.length
  const metric1 = rawMetrics.length > 0 ? rawMetrics[0][0] : null
  const metric2 = rawMetrics.length > 0 && rawMetrics[0].length > 0 ? rawMetrics[0][1] : null
  const metric3 = rawMetrics.length > 1 ? rawMetrics[1][0] : null
  const metric4 = rawMetrics.length > 1 && rawMetrics[1].length > 0 ? rawMetrics[1][1] : null
  const isSmall = useMediaQuery('(max-width: 1200px)')
  const oneAcross = useMediaQuery('(max-width: 800px)')
  const twoAcross = useMediaQuery('(max-width: 1000px)') && !oneAcross
  const allAcross = !oneAcross && !twoAcross

  const updateSettings = (values) => {
    const newMetrics = numCharts > 1 ? [[values.metric1, values.metric2], [metric3, metric4]] : [[values.metric1, values.metric2]]
    setMetrics({ view: metrics.view, metrics: newMetrics, where: metrics.where })
  }

  const updateSettings2 = (values) => {
    setMetrics({ view: metrics.view, metrics: [[metric1, metric2], [values.metric1, values.metric2]], where: metrics.where })
  }

  const useAiStyledChart = true

  const getData = () => {
    if (metricsData[metrics.view] && metricsData[metrics.view][metrics.where]) {
      return metricsData[metrics.view][metrics.where]
    } else {
      return null
    }
  }

  const setData = (data) => {
    const newData = {}
    if (newData[metrics.view] == null) {
      newData[metrics.view] = {}
    }
    newData[metrics.view][metrics.where] = data
    setMetricsData(Object.assign({}, metricsData, newData))
  }

  useEffect(() => {
    if (getData() == null) {
      const params = { from_date, to_date, view: metrics.view, where: metrics.where }
      getAiSummary(params).then(response => {
        setData(response)
      })
    }
  }, [metrics.view, metrics.where])

  useEffect(() => {
    if (conversation.prompts) {
      const prompt = conversation.prompts[conversation.prompts.length - 1]
      setMetrics(prompt.metrics == null ? defaultMetrics : prompt.metrics)
    }
  }, [conversation])

  const getTitle = () => {
    const title = i18next.t('ai.charts.' + metrics.view)
    if (metrics.where != 'TRUE') {
      return title + i18next.t('ai.charts.where', { where: metrics.where.replace(/_/g, ' ') })
    }
    return title
  }
  
  const charts = (
    <Box style={{ display: 'flex', flexDirection: 'column', width: allAcross ? (isSmall ? "calc(100vw - 295px)" : "calc(80vw - 295px)") : 'calc(100vw - 36px)',  marginRight: allAcross ? '20px' : 0 }}>
      <Box style={{ width: '100%', textAlign: 'center', color: 'white', fontSize: 20, marginBottom: 10 }}>{getTitle()}</Box>
      <Box style={{ display: 'flex', height: '100%', flexDirection: oneAcross ? 'column' : 'row', maxWidth: '100%' }}>
        <Box style={numCharts > 1 ? { marginRight: 5, width: oneAcross ? 'calc(100% - 5px)' : 'calc(50% - 5px)', minWidth: '340px' } : { width: '100%' }}>
          <ComparisonChartWithSelectors
            type={metrics.view}
            hideUnusedMetrics={true}
            aiMetricSelectChart={true}
            fromDate1={from_date}
            toDate1={to_date}
            metric1={metric1}
            summary1={getData()?.summary}
            dataset1={getData()?.results}
            metric2={metric2}
            summary2={getData()?.summary}
            dataset2={getData()?.results}
            callback={values => updateSettings(values)}
            useAiStyledChart={useAiStyledChart}
          />
        </Box>
        {numCharts > 1 && (
          <Box style={{ marginLeft: 5, width: oneAcross ? 'calc(100% - 5px)' : 'calc(50% - 5px)', minWidth: '340px' }}>
            <ComparisonChartWithSelectors
              type={metrics.view}
              hideUnusedMetrics={true}
              aiMetricSelectChart={true}
              fromDate1={from_date}
              toDate1={to_date}
              metric1={metric3}
              summary1={getData()?.summary}
              dataset1={getData()?.results}
              metric2={metric4}
              summary2={getData()?.summary}
              dataset2={getData()?.results}
              callback={values => updateSettings2(values)}
              useAiStyledChart={useAiStyledChart}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
  
  const chat = (
    <Box sx={{ width: '100%', maxWidth: showCharts && allAcross ? '275px' : null, marginLeft: 0 }}>
      <PromptDialogChat
        generateTimestamp={generateTimestamp}
        requiredAlert={requiredAlert}
        status={status}
        setStatus={setStatus}
        hidePromptInput={hidePromptInput}
        setMetrics={setMetrics}
        conversation={conversation}
        setConversation={setConversation}
        user={user}
        handleSubmit={handleSubmit}
        promptEngDraft={promptEngDraft}
        setPromptEngDraft={setPromptEngDraft}
        promptDraft={promptDraft}
        setPromptDraft={setPromptDraft}
        handleKeyDown={handleKeyDown}
        activeTab={activeTab}
        lastPrompt={lastPrompt}
        setLastPrompt={setLastPrompt}
        lastResponseOutput={lastResponseOutput}
        addToPromptEngHistory={addToPromptEngHistory}
        conversationId={conversationId}
        setConversationId={setConversationId}
        disabled={disabled}
        setDisabled={setDisabled}
        responseOutput={responseOutput}
        aiBot={aiBot}
        userName={userName}
        initialPackagedPrompt={initialPackagedPrompt}
        promptThread={promptThread}
        setPromptThread={setPromptThread}
        rating={rating}
        setRating={setRating}
        feedback={feedback}
        setFeedback={setFeedback}
        fullResponse={fullResponse}
        promptId={promptId}
      />
    </Box>
  )

  const width = isSmall ? '100vw' : '80vw'
  const contents = allAcross ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: width}}>
      {showCharts && charts}
      {chat}
    </Box>
  ) :
  (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: demoMode ? 'calc(100vh - 80px)' : 'calc(100vh - 180px)', width: width, overflowY: 'auto' }}>
      <Box sx={{ width: 'calc(100vw - 36px)', marginLeft: '8px' }}>
        {chat}
        {showCharts && charts}
      </Box>
    </Box>
  )
  return (
    <Stack spacing={0}>
      <Stack direction="horizontal" spacing={0} justifyContents="flex-start">
        <Switch color='accent'
          checked={showCharts}
          onChange={() => setShowCharts(!showCharts)} />
        <ShowChartIcon style={{color: 'white', maxWidth: 'fit-content', marginTop: '8px'}} />
      </Stack>
      {contents}
    </Stack>
  )
}

const mapStateToProps = function (state) {
  return {
    admin: state.users.user ? state.users.user.role == "Admin" : null,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}
export default connect(mapStateToProps)(PromptDialogDashboard)
