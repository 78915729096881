import React, { useState } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Link } from 'react-router-dom'
import { initTabIndex, tabs, render } from '../ab_tests/ABTests'
import { getAbTests } from '../../api/ab_tests'
import { prettyPrint } from '../../utilities/formatting'
import i18next from 'i18next'

const Interface = ({match, history, internal_client_id}) => {
  const [tabIndex, setTabIndex] = useState(initTabIndex(match))

  const getBaseUrl = () => {
    return '/manage_ab_tests/'
  }

  const getABTestLink = (row) => {
    return '/manage_ab_tests/edit/' + row.id
  }

  const getColumns = () => {
    return [{
      id: 'title',
      localeKey: 'ab_test_table_title',
      datatype: 'text',
      width: 400,
      cellRenderer: (value, row) => ( 
        <div>
            <Link to={getABTestLink(row)}>
              {value}
            </Link>
        </div>
      )
    }, {
      id: 'test_type',
      datatype: 'text',
      width: 200,
      format: value => value ? prettyPrint(value) : null,
    }]
  }

  const getAddNew = () => {
    return (
      <div style={{textAlign: 'right'}} >
        <Link to={'/manage_ab_tests/edit/new'} className="btn btn-box-tool text-green">
          <i className="fa fa-plus"></i>
          <span>{i18next.t('abtest.manage.addAbTest')}</span>
        </Link>
      </div>
    )
  }

  const getTableName = () => {
    return null
  }

  const getRows = (tableData) => {
    return getAbTests(internal_client_id, tabs[tabIndex]['name'], tableData)
  }

  return render(internal_client_id, getBaseUrl, tabIndex, setTabIndex, getAddNew, getTableName, getRows, getColumns, history)
}

const mapStateToProps = (state) => {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
