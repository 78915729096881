import React from 'react'
import Stack from '../widgets/Stack'
import Section from '../widgets/Section'
import PullDown from '../widgets/PullDown'

const CampaignInputs = (props) => {
  return (
    <Stack sx={{ width: '100%', minWidth: '100%' }}>
      <PullDown
        labelText="abtest.edit.campaignsToInclude"
        options={props.allCampaigns}
        optionFunction={option => option.campaign_name}
        onChange={(_, value) => props.onUpdate(value.map(campaign => campaign.campaign_name), value.map(campaign => campaign.campaign_id))}
      />
    </Stack>
  )
}

const CampaignSelect = (props) => {
  return (
    <Section name={props.name}>{<CampaignInputs {...props} />}</Section>
  )
}

export default CampaignSelect