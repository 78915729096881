import { Component } from 'react';
import { Link }                            from 'react-router-dom'

export default class Title extends Component {
  /*
    Display page title information (typically at the top).
    Properties:
      menu: name of the menu (ex., 'Dashboard')
      item: name of the menu item (ex., 'Overview')
      object_name: Object being edited (or null if none)
      icon: Icon class (defaults to fa-dashboard)
  */

  static defaultProps = {
    icon: 'fa-dashboard'
  };

  constructor(props) {
    super(props);
  }

  renderLabel() {
    return (
      <span>
        <i className={'fa ' + this.props.icon}></i> {this.props.menu}
        <small style={{paddingLeft: 4, fontSize: 15, fontWeight: 300, lineHeight: '15px'}}>{this.props.item}</small>
      </span>
    )
  }

  render() {
    const link = this.props.link;
    const linkIsFunction = typeof link == 'function';
    return (
      <section className="content-header">
        <h1>
        {linkIsFunction &&
          <a onClick={() => {link()}} style={{ color: 'black' }}>
            {this.renderLabel()}
          </a>
        }
        {link && !linkIsFunction &&
          <Link to={link} style={{ color: 'black' }}>
            {this.renderLabel()}
          </Link>
        }
        {!this.props.link &&
          <span>
            {this.renderLabel()}
          </span>
        }
        </h1>
        { this.props.object_name && (
          <h1 style={{'textAlign': 'right', 'position': 'absolute', 'top': 15, 'right': 15}}>
            {this.props.object_name}
          </h1>
        )}
      </section>
    )
  }  
}
