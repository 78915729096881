import { useMemo, useEffect } from 'react'
import TextField from './TextField'
import { guid } from '../../utilities/guids'
import i18next from 'i18next'

/*
  DatePicker uses the JQuery Date Range Picker (see https://www.daterangepicker.com/ for more details).
  Display a date picker so that users can choose what date range to show data for.
  Properties:
    labelText - Field name (generally i18n)
    value - Current value for field (in format YYYY-MM-DD)
    onChange - function to call when value changes (passed in new value in format YYYY-MM-DD)
*/

const FTODateField = ({labelText, value, onChange}) => {
  const id = useMemo(() => 'd' + guid().replaceAll('-', ''), [])

  useEffect(() => {
    if (value) {
      $(`#${id}`).daterangepicker({
        startDate: moment(value, 'YYYY-MM-DD'),
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
          format: 'M/D/Y'
        }
      }, (newValue) => onChange && onChange(newValue.format('YYYY-MM-DD')))
      $(`#${id} .calendar.right`).each(function () {
        this.style.setProperty('display', 'none', 'important')
      })
    }
  }, [value])

  return (
    <TextField id={id} label={i18next.t(labelText)} readOnly />
  )  
}

export default FTODateField