import React from 'react'
import { Handle, Position } from 'reactflow'
import { connect } from 'react-redux'
import i18next from 'i18next'
import styles from './NodeStyles'

const Node = ({ data }) => {
  return (
    <>
      {!data.hideTarget && (
        <Handle type="target" position={Position.Left} />
      )}
      {data.database && data.link && (
        <div style={Object.assign({}, styles.databaseContainer, {cursor: 'pointer'})} onClick={() => {location.href=data.link}}>
          <img style={styles.database} src="/resources/images/workflow_icons/database-1.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {!data.link && !data.database && !data.training && !data.bucket && !data.featureExtract && !data.predict && (
        <div style={Object.assign({}, styles.node, {cursor: 'auto'})}>{i18next.t(data.label)}</div>
      )}
      {data.database && !data.link && (
        <div style={styles.databaseContainer}>
          <img style={styles.database} src="/resources/images/workflow_icons/database-1.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {data.training && (
        <div style={Object.assign({}, styles.trainingContainer, {cursor: 'pointer'})} onClick={() => {location.href=data.link}}>
          <img style={styles.training} src="/resources/images/workflow_icons/AWS-Deep-Learning-AMIs.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {data.predict && (
        <div style={styles.predictContainer}>
          <img style={styles.predict} src="/resources/images/workflow_icons/Amazon-Forecast_light-bg.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {data.featureExtract && (
        <div style={Object.assign({}, styles.featureExtractContainer, {cursor: 'pointer'})} onClick={() => {location.href=data.link}}>
          <img style={styles.featureExtract} src="/resources/images/workflow_icons/Amazon-Textract.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {data.bucket && (
        <div style={styles.bucketContainer}>
          <img style={styles.bucket} src="/resources/images/workflow_icons/Amazon-Simple-Storage-Service-S3.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {!data.hideSource && (
        <Handle type="source" position={Position.Right} />
      )}
    </>
  )
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(Node)
