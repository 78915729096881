import { Box, IconButton, Fade, Typography, InputAdornment } from "@mui/material"
import TextField from '../widgets/TextField'
import SendIcon from '@mui/icons-material/Send'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import i18next from "i18next"

const PromptEngInput = ({
  promptDraft,
  setPromptDraft,
  promptEngDraft,
  setPromptEngDraft,
  handleKeyDown,
  handleSubmit,
  requiredAlert,
  demoMode,
  hidePromptInput,
  status,
  disabled
}) => {

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignContent: 'center'
    }}>
      {!hidePromptInput ?
        <Box sx={{
          padding: 3,
          width: '30vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <TextField
            sx={sxTextField}
            multiline
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit} sx={{ color: 'white.100', alignItems: 'center' }}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // label={i18next.t('ai.enterPrompt')}
            placeholder='Enter a prompt...'
            fullWidth
            autoFocus
            value={promptDraft}
            onChange={e => setPromptDraft(e.target.value)}
            onKeyDown={handleKeyDown} />
          {requiredAlert ?
            <Typography color="#FFFFFF">
              {i18next.t('ai.enterPromptToContinue')}
            </Typography>
            : null}
        </Box>
        : null}
      {!demoMode && <Box sx={{
        padding: 1,
        margin: 1,
        width: '75vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        transformOrigin: 'center bottom',
        alignContent: 'center',
        height: '75vh',
        transform: 'translateY(-3vh)',
      }}>
        <TextField
          sx={{ ...sxTextField }}
          disabled={disabled}
          multiline
          rows={8}
          placeholder={i18next.t('ai.promptEngineering')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{marginBottom: 1}} position="end">
                <IconButton onClick={handleSubmit} sx={{ color: 'white.100' }} disabled={disabled}>
                  <AddCircleIcon sx={{ fontSize: '4rem', alignSelf: 'flex-start' }} />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              alignItems: 'flex-end'
            }
          }}
          value={promptEngDraft}
          onChange={(e) => setPromptEngDraft(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)} />
      </Box>}
    </Box>
  )
}

export default PromptEngInput

const sxTextField = {
  alignSelf: 'center',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'EB Garamond',
    '& fieldset': {
      borderColor: 'white.100',
      backgroundColor: 'white',
      borderRadius: 3,
    },
    '&:hover fieldset': {
      borderColor: 'white.100',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white.100',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white.100',
    fontSize: '2.5rem',
    top: -6,
    '&.Mui-focused': {
      color: 'white.100',
      bgcolor: 'white',
      fontSize: '1.4rem',
      top: 0,
    },
  },
  '& .MuiInputBase-input': {
    color: 'white.100', // Text input color
    fontFamily: 'EB Garamond',
    fontSize: '2.5rem'
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transition: 'none'
  },
}