import withRouter from '../../wrappers/withRouter'
import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import request from "../../api/request"
import FTNotice from '../FTNotice'
import { verifyPasswordToken } from '../../redux/users/sagaActions'

class Interface extends Component {
  constructor(props) {
    super(props);

    this.passwordConfirmRef = createRef();
    this.submitButtonRef = createRef();

    this.state = {
      formData: {
        password: '',
        password_confirmation: '',
        token: null
      },
      tokenIsValid: false
    };
  }

  componentDidMount() {
    const token = location.href.split('/').slice(-1)[0];
    let formData = this.state.formData;
    formData.token = token
    this.setState({formData})
    this.props.dispatch(verifyPasswordToken(`/api/users/verify_password_reset_token/${token}`))
  }

  resetPassword = (event) => {
    event.preventDefault();

    request('/api/users/reset_password', {}, 'PATCH', this.state.formData).then((json) => {
      if(json['passwordUpdated']) {
        this.props.history('/');
        FTNotice('Password successfully updated. Please use your new password to login');
      }
    });
  }

  handleChange = (event) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({formData})
  }

  validatePasswordConfirmation = (event) => {
    this.handleChange(event)

    if(event.target.name == 'password_confirmation'){
      const button = this.submitButtonRef.current;
      const formGroup = this.passwordConfirmRef.current.children
      if(event.target.value !== this.state.formData.password) {
        button.setAttribute('disabled', true)
        formGroup[2].removeAttribute('hidden')
      } else {
        button.removeAttribute('disabled')
        formGroup[2].setAttribute('hidden', true)
      }

    }
  }

  render() {
    const formData = this.state.formData;
    let overlayClass = '';
    let formTitle = '';
    if(this.props.tokenIsValid) {
      overlayClass = 'hidden';
      formTitle = 'Reset password';
    } else {
      formTitle = 'Verifying token';
    }

    return (
      <div className="login-page" style={{height: 'calc(100vh + 106px)', paddingTop: '106px', marginTop: '-106px'}}>
        <div className="login-box">
          <div className="login-logo">
            <b>FT</b>AMI
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">{formTitle}</p>
            {
              this.props.tokenIsValid &&
              <form onSubmit={this.resetPassword}>
                <div className="form-group has-feedback">
                  <input
                    autoFocus="autofocus" className="form-control"
                    type="password" name='password' value={formData.password}
                    onChange={this.handleChange} required={true} placeholder='password'
                    autoComplete='off'
                  />
                  <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                </div>
                <div className="form-group has-feedback" ref={this.passwordConfirmRef}>
                  <input
                    className="form-control" type="password"
                    name="password_confirmation"  value={formData.password_confirmation}
                    onChange={this.validatePasswordConfirmation} required={true} placeholder='password confirmation'
                    autoComplete='off'
                  />
                  <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                  <div className="row" hidden>
                    <small className="text-danger">
                      Passwords do not match.
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <button className="btn btn-primary btn-block btn-flat" type="submit" ref={this.submitButtonRef}>Reset password</button>
                  </div>
                </div>
              </form>
            }
            <div className={'overlay text-center ' + overlayClass}>
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({tokenIsValid: state.users.tokenIsValid})

export default withRouter(connect(mapStateToProps)(Interface));
