import React, { Component } from "react"
import uniqueId from "lodash/uniqueId"
import forEach from "lodash/forEach"
import Metric from '../widgets/Metric'
import { Box, Typography, Grid } from '@mui/material'

export default class Metrics extends Component {
  buildPathFor(metric, compare) {
    if (this.props.path == "/") return "/";

    let path = this.props.path.split("/");
    let left = path[2].split(",");
    let right = path[3].split(",");

    if (compare == 0) {
      right[1] = left[1];
      left[1] = metric;
      path[2] = left.join(",");
      path[3] = right.join(",");
    } else {
      left[1] = metric;
      right[1] = metric;
      path[2] = left.join(",");
      path[3] = right.join(",");
    }

    return path.join("/");
  }

  metrics() {
    const first_row = {
      show: true,
      title: "Metrics",
      data: {
        impressions: {
          name: "Impressions",
          color: "bg-grey",
          icon: "eye"
        },
        clicks: {
          name: "Clicks",
          color: "bg-grey",
          icon: "hand-pointer-o"
        },
        ctr: {
          name: "CTR",
          color: "bg-grey",
          icon: "hourglass-half",
        },
        cost: {
          name: "Cost",
          color: "bg-grey",
          icon: "dollar",
        },
        cpc: {
          name: "CPC",
          color: "bg-grey",
          icon: "mouse-pointer",
        },
      },
    };

    const second_row = {
      show: this.props.engineMetrics,
      title: "Engine Metrics",
      data: {
        engine_conversion: {
          name: "Engine Conversions",
          color: "bg-grey",
          icon: "check-square-o",
        },
        engine_revenue: {
          name: "Engine Revenue",
          color: "bg-grey",
          icon: "money",
        },
        engine_cr: {
          name: "Engine Conversion Rate",
          color: "bg-grey",
          icon: "filter",
        },
        engine_roas: {
          name: "Engine ROAS",
          color: "bg-grey",
          icon: "money",
        },
        engine_cpa: {
          name: "Engine CPA",
          color: "bg-grey",
          icon: "dollar",
        },
      },
    };


    const third_row = {
      show: true,
      title: "Internal Metrics",
      data: {
        internal_conversion: {
          name: "Conversions",
          color: "bg-grey",
          icon: "check-square-o",
        },
        internal_revenue: {
          name: "Revenue",
          color: "bg-grey",
          icon: "money",
        },
        internal_cr: {
          name: "Conversion Rate",
          color: "bg-grey",
          icon: "filter",
        },
        internal_roas: {
          name: "ROAS",
          color: "bg-grey",
          icon: "money",
        },
        internal_cpa: {
          name: "CPA",
          color: "bg-grey",
          icon: "dollar",
        },
      },
    }

    const metric_rows = [first_row, second_row, third_row];

    let compare_days = 0;
    if (this.props.fromDate != null && this.props.compareFrom != null) {
      compare_days = moment(this.props.fromDate).diff(
        moment(this.props.compareFrom),
        "day"
      );
    }

    let rows = []
    forEach(metric_rows, (row, key) => {
      if (row.show === true) {
        rows.push(
          <Box key={key} sx={{ marginBottom: 1 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>{row.title}</Typography>
            <Grid container direction='row' >
              {this.render_metric_row(row.data, compare_days)}
            </Grid>
          </Box>
        )
      }
    })

    return rows
  }

  render_metric_row(metric_row, compare_days) {
    let components = [];
    let { summary1, summary2 } = this.props;

    forEach(metric_row, (data, key) => {
      let metric = { ...data };
      let compare_value = null;

      if (compare_days != 0 && summary2) {
        compare_value = summary2[key];
      }

      components.push(
        <Grid item xs={10} lg={2.4} key={uniqueId() + "-col"}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Metric
              key={uniqueId() + "-metric"}
              id={key}
              name={metric.name}
              value={summary1[key]}
              compare_value={compare_value}
              compare_days={compare_days}
              icon={metric.icon}
              color={metric.color}
              path={this.buildPathFor(key, compare_days)}
            />
          </Box>
        </Grid>
      );
    });

    return (
      <Grid container direction='row' spacing={5} >
        {components}
      </Grid>
    );
  }

  render() {
    return <div>{this.metrics()}</div>;
  }
}
