import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DeleteButton from './DeleteButton'
import i18next from 'i18next'

/*
  Used to display a field on a form.  Properties:
  objectType - The type of object being edited (ex., 'Link')
  objectName - The name of the object being edited (ex. 'FTO Web Site')
  newObject - Whether this is a new object
  updateObject - The function to call on updating the object
  deleteObject - The function to call on delete (or null if delete is not allowed or newObject)
  parentLink - Where to go on completion / cancel (can be a link or a function)
  otherButtons - Any addional buttons to display    
  children - The input for the field (ex. <input/>
  canEdit - Whether this object can be updated (default=true)
  canDelete - Whether this object can be deleted (default=true if not new)
  filters - Widgets to display at top of form
  tabs - list of tabs (id, name, component)
  tabIndex - which tab is selected
  updateTab - called on tab selection 
  menu - override of menu in title
  item - override of item in title
  noRow - don't add row inset to form data
  childClass - class to use for each child (default = box)
*/
export default class Form extends Component {
  static defaultProps = {
    canEdit: true,
    canDelete: true,
    tabs: [],
    tabIndex: 0,
    showTitle: true,
    childClass: 'box',
  };

  renderElement(children) {
    const parentLink = this.props.parentLink;
    const parentIsFunction = typeof parentLink == 'function';
    return (
      <div className={this.props.childClass} style={{height: 'fit-content', marginLeft: this.props.noIndent ? 0 : 15, width: this.props.noIndent ? '100%' : 'calc(100% - 30px)', paddingBottom: 10}}>
        {parentLink &&
          <div className="box-tools">
            {parentIsFunction &&
              <a className="btn btn-box-tool" style={{float: 'right', marginTop: 5, marginRight: 10}} onClick={() => {parentLink()}}>
                <i className="fa fa-times"></i>
              </a>
            }
            {!parentIsFunction && !parentLink.startsWith('http') &&
              <Link className="btn btn-box-tool" style={{float: 'right', marginTop: 5, marginRight: 10}} to={parentLink}>
                <i className="fa fa-times"></i>
              </Link>
            }
            {!parentIsFunction && parentLink.startsWith('http') &&
              <a className="btn btn-box-tool" style={{float: 'right', marginTop: 5, marginRight: 10}} href={parentLink}>
                <i className="fa fa-times"></i>
              </a>
            }
          </div>
        }
        <div className="row" style={{marginLeft: this.props.noRow ? 0 : null, marginRight: this.props.noRow ? 0 : null}}>
          {children}
          {!this.props.noRow && (
            <div>
              <div className="col-md-12 mt-25 text-center">
                { this.props.canEdit && (
                  <button name="edit" className="btn btn-primary" onClick={this.props.updateObject} disabled={this.props.buttonDisabled}>
                    {this.props.newObject ? i18next.t('form.add') : i18next.t('form.update')} {i18next.t(this.props.objectType)}
                  </button>
                )}
                {this.props.otherButtons}
                <DeleteButton
                  objectType={this.props.objectType}
                  objectName={this.props.objectName}
                  canDelete={!this.props.newObject && this.props.canDelete}
                  deleteObject={this.props.deleteObject}/>
              </div>
              <div className="col-md-12 mt-25"></div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className='site-wrapper-offcanvas' id="chart-hudd">
        <div className='site-canvas'>
          <br/>
          {this.props.filters}
          {this.props.tabs.length > 0 && (
            <div className="form-tabs">
              <Tabs selectedIndex={this.props.tabIndex} onSelect={tabIndex => this.props.updateTab(tabIndex)}>
                <TabList>
                 {this.props.tabs.map((tab) => {
                    return (
                      <Tab key={tab.id}>
                        {tab.name}
                      </Tab>
                    )
                  })}
                </TabList>
                {this.props.tabs.map((tab) => {
                  return (
                    <TabPanel key={tab.id}>
                      {this.renderElement(tab.component)}
                    </TabPanel>
                  )
                })}
              </Tabs>
            </div>
          )}
          {this.props.tabs.length == 0 && (
            this.renderElement(this.props.children)
          )}
        </div>
      </div>
    );
  }
}
