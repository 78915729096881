import request from './request';
import querystring from 'querystring';

// change_history
export function getChangeHistory(internal_client_id, start_date, end_date, tableData) {
  return request('api/change_history?internal_client_id=' + internal_client_id + '&start_date=' + start_date + '&end_date=' + end_date + '&' + querystring.stringify(tableData));
}

// change_history
export function updateChangeHistoryComment(data) {
  return request('api/change_history/comments', {}, 'POST', data);
}
