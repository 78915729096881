import { Component } from 'react';
import Table from '../widgets/Table'
import { Link } from 'react-router-dom'
import { getSQLTemplates } from "../../api/sql_templates"
import Dialog             from '@mui/material/Dialog';
import DialogTitle        from '@mui/material/DialogTitle';
import DialogContent      from '@mui/material/DialogContent';
import SyntaxHighlighter  from 'react-syntax-highlighter';
import { docco }          from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default class Interface extends Component {
  constructor(props){
    super(props)
    this.state = {
      open_dialog: false,
      dialog_content: ''
    }
  }

  getColumns() {
    return [{
      id: 'name',
      header: 'Name',
      datatype: 'text',
      width: 300,
      cellRenderer: (value, row) => (<Link to={`/sql_templates/${row.id}`}>{value}</Link>),
    },{
      id: 'sql',
      header: 'SQL',
      datatype: 'text',
      width: 130,
      preventSort: true,
      cellRenderer: (value) => (
        <div>
          <button className="btn-link"
            onClick={() => this.setState({open_dialog: true, dialog_content: value})}>
            show sql
          </button>
        </div>
      ),
    },{
      id: 'created_at',
      header: 'Created At',
      datatype: 'date',
      width: 200,
    },{
      id: 'updated_at',
      header: 'Updated At',
      datatype: 'date',
      width: 200,
    }]
  }

  renderTable() {
    return (
      <div className='box-body no-padding js_complete' style={{marginTop: '15px'}}>
        <Table
          fetch={(tableData) => {return getSQLTemplates(tableData)}}
          columns={this.getColumns()}/>
      </div>
    );
  }

  renderSqlPreview() {
    return(
      <Dialog open={this.state.open_dialog}
        onClose={() => this.setState({open_dialog: false})} 
        maxWidth='md' >
        <DialogTitle><b>SQL</b></DialogTitle>
        <DialogContent dividers>
          <SyntaxHighlighter language="sql" style={docco} 
            customStyle={{marginTop: '1px'}} >
          {this.state.dialog_content}
        </SyntaxHighlighter>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    return(
      <div className="site-canvas" style={{ minHeight: '540px' }}>
        <div className='content'>
          <div className='box'>
            <div className='box-header'>
              <div className='box-tools'>
                <Link to='/sql_templates/new' className='btn btn-box-tool text-green'>
                  <i className='fa fa-plus' />
                  <span>Add New SQL</span>
                </Link>
              </div>
            </div>
            {this.renderTable()}
            {this.renderSqlPreview()}
          </div>
        </div>
      </div>
    )
  }
}
