import React from 'react'
import { Link } from 'react-router-dom'
import { Handle, Position } from 'reactflow'
import styles from './BiddingStepStyles'

const Node = ({ data }) => {
  return (
    <>
      {!data.hideTarget && (
        <Handle type="target" position={Position.Left} />
      )}
      <Link to={data.link}>
        <div style={styles.node}>{data.label}</div>
      </Link>
      {!data.hideSource && (
        <Handle type="source" position={Position.Right} />
      )}
    </>
  )
}

export default Node
