import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { getAllDashboards } from '../../api/dashboards'
import { getBudgetGoals } from '../../api/budget'
import Box from '@mui/material/Box'
import Page from '../widgets/Page'
import Stack from '../widgets/Stack'
import Wrench from '../widgets/Wrench'
import Pacing from '../pacing/Pacing'
import Dashboard from '../dashboards/Interface'
import Configuration from './Configuration'
import MonthSelector from './MonthSelector'
import querystring from 'querystring'

class Budget extends Component {
  constructor(props) {
    super(props)
    const values = querystring.parse(this.props.location.search ? this.props.location.search.substring(1) : '')
    const channels = values.channels ? values.channels.split(',') : ['sem', 'display', 'social']

    this.state = {
      channels: channels,
      wrenchChannels: channels.slice(),
      month: values.month ? new Date(values.month.split('-')[0], values.month.split('-')[1] - 1, 1) : new Date(),
      dashboards: null,
      showMonths: false,
      allSubbudgets: [],
      subbudgets: [],
      wrenchSubbudgets: [],
    }
  }
  
  componentDidMount() {
    this.getData()
  }

  getData() {
    getAllDashboards(false, [this.props.internal_client_id], "Budget").then(response => {
        this.setState({ dashboards: response.results })
      }
    )
    getBudgetGoals(this.props.internal_client_id).then(result => {
      const subbudgets = ['Overall'].concat(result.results.map(subbudget => subbudget.subbudget))
      this.setState({allSubbudgets: subbudgets.slice(), subbudgets, wrenchSubbudgets: subbudgets.slice()})
    })
  }

  updateChannel(channel, value) {
    const channels = this.state.wrenchChannels.slice()
    if (value) {
      if (this.state.wrenchChannels.indexOf(channel) < 0) {
        channels.push(channel)
      }
    } else if (this.state.wrenchChannels.indexOf(channel) >= 0) {
      channels.splice(channels.indexOf(channel), 1)
    }
    this.setState({ wrenchChannels: channels })
  }

  toggleSubbudgets(subbudget, value) {
    const current = this.state.wrenchSubbudgets.find(b => b == subbudget)
    if (value && !current) {
      this.setState({ wrenchSubbudgets: this.state.allSubbudgets.filter(b => b == subbudget || this.state.wrenchSubbudgets.includes(b)) })
    } else if (!value && current) {
      this.setState({ wrenchSubbudgets: this.state.wrenchSubbudgets.filter(b => b != subbudget) })
    }
  }

  findSubbudget(subbudget) {
    return this.state.wrenchSubbudgets.find(b => b == subbudget) != null
  }

  updateMonth(month) {
    this.setState({ month: month.id }, () => this.updateUrl())
  }

  updateUrl() {
    window.location.href = `/#/budget?channels=${this.state.channels.join(",")}&month=${this.state.month.getFullYear()}-${this.state.month.getMonth() + 1}`
  }

  applyChanges() {
    this.setState({ channels: this.state.wrenchChannels.slice(), subbudgets: this.state.wrenchSubbudgets.slice() }, () => this.updateUrl())
  }

  render() {
    const now = new Date()
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    const startDate = moment()
      .year(now.getFullYear())
      .month(now.getMonth())
      .date(1)
      .format("YYYY-MM-DD")
    const endDate = moment()
      .year(now.getFullYear())
      .month(now.getMonth())
      .date(lastDay.getDate())
      .format("YYYY-MM-DD")
    const pacingMonth = `${this.state.month.getFullYear()}-${this.state.month.getMonth() + 1}`

    return (
      <Page>
        {this.state.dashboards && this.state.dashboards.length == 0 && (
          <>
            <Configuration
              wrenchChannels={this.state.wrenchChannels}
              updateChannel={(channel, checked) => this.updateChannel(channel, checked)}
              allSubbudgets={this.state.allSubbudgets}
              findSubbudget={subbudget => this.findSubbudget(subbudget)}
              toggleSubbudgets={(subbudget, checked) => this. toggleSubbudgets(subbudget, checked)}
              apply={() => this.applyChanges()}
              cancel={() =>
                this.setState({
                  wrenchChannels: this.state.channels.slice(),
                  wrenchSubbudgets: this.state.subbudgets.slice(),
                })
              }
            />
            <Box className="page-nav">
              <Stack>
                <Stack direction="row">
                  <Wrench />
                  <MonthSelector month={this.state.month} updateMonth={(month) => this.updateMonth(month)}/>
                </Stack>
                <Pacing month={pacingMonth} channels={this.state.channels} subbudgets={this.state.subbudgets}/>
              </Stack>
            </Box>
          </>
        )}
        {this.state.dashboards && this.state.dashboards.length > 0 && (
          <>
            {this.state.dashboards.map((dashboard, i) => (
              <Dashboard
                key={i}
                dashboard={dashboard}
                startDate={startDate}
                endDate={endDate}
                hidePage={true}
              />
            ))}
          </>
        )}
      </Page>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    admin: state.users.user ? state.users.user.role == "Admin" : null,
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
  }
}

export default withRouter(connect(mapStateToProps)(Budget))
