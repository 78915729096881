import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Section from '../widgets/Section'
import Checkbox from '../widgets/Checkbox'
import ButtonRow from '../widgets/ButtonRow'
import Button from '../widgets/Button'
import { connect, useDispatch } from 'react-redux'
import { patchUser } from '../../api/users'
import { getUser } from '../../redux/users/sagaActions'

const AllClients = ({userId, allClients, clients, setClients}) => {
  const dispatch = useDispatch()
  const [localClients, setLocalClients] = useState(clients)
  
  useEffect(() => {
    setLocalClients(clients)
  }, [clients])
  
  const includesClient = (client) => {
    return localClients.map(c => c.internal_client_id).includes(client.internal_client_id)
  }
  
  const toggleClient = (client, checked) => {
    const newClients = localClients.slice()
    const index = localClients.map(c => c.internal_client_id).indexOf(client.internal_client_id)
    if (checked) {
      if (index < 0) {
        newClients.push(client)
      }
    } else {
      if (index >= 0) {
        newClients.splice(index, 1)
      }
    }
    setLocalClients(newClients)
  }
  
  const apply = () => {
    if (localClients.length == 0) {
      setClients(allClients)
      setLocalClients(allClients)
    } else {
      setClients(localClients)
    }
    patchUser({id: userId, clients_toshow: localClients.map(c => c.internal_client_id)}).then(() => {
      dispatch(getUser())
    })
  }
  
  const cancel = () => {
    setLocalClients(clients)
  }
  
  return (
    <Box className="site-menu">
      <Section name="allClients.clients">
        {allClients.map((client, i) =>
          <Box key={i}>
            <Checkbox labelText={client.name} checked={includesClient(client)} onChange={event => toggleClient(client, event.target.checked)} />
          </Box>
        )}
      </Section>
      <Box display="flex" justifyContent="center" >
        <ButtonRow>
          <Button
            buttonText="allClients.apply"
            onClick={apply}
            className="btn btn-default apply toggle-nav"
          />
          <Button
            color="secondary"
            buttonText="allClients.cancel"
            onClick={cancel}
            className={"btn btn-default cancel toggle-nav"}
          />
        </ButtonRow>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    userId: state.users.user ? state.users.user.id : null,
    allClients: state.users.user ? state.users.user.clients : []
  }
}

export default connect(mapStateToProps)(AllClients)
