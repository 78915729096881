import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import * as qs from 'query-string'
import { getDefaultRoas, getDefaultRoasSubbudget, saveBiddingRun, generateBids, getBidRunData, publishBids, getBidRunStatus, deleteBidRun, revertBidRun, getAlgos, getLastROAS } from '../../api/bidding'
import { getBudgetGoals } from '../../api/budget'
import { getCampaigns, getAdGroups } from '../../api/campaigns'
import { getClient } from '../../api/clients'
import { getLabels } from '../../api/ads'
import Pacing from '../pacing/Pacing'
import Table from '../widgets/Table'
import Checkbox from '../widgets/Checkbox'
import BidSummary from './bidding_run/BidSummary'
import Confirm from '../Confirm'
import FTNotice from '../FTNotice'
import { _ } from 'ag-grid-community'
import i18next from 'i18next'
import BiddingLayout from './simpleBiddingView/BiddingLayout'
import { Link } from 'react-router-dom'

class AddEditBiddingRun extends Component {
  SIMPLE = 'simple'
  ADVANCED = 'advanced'
  PRELIM_BIDS = 'prelim_bids'
  BIDS = 'bids'
  DEFAULT_BID_LEVELS = { scale: 100, minimum: -10, maximum: 20 }
  DEFAULT_WHERE_CLAUSE = 'TRUE'
  OVERALL = 'Overall'
  tableRef = createRef()

  constructor(props) {
    super(props)
    let url_params = qs.parse(this.props.location.search)
    this.state = {
      id: url_params.id ? parseInt(url_params.id) : -1,
      status: url_params.id && url_params.id != -1 ? null : 'new',
      client: null,
      bid_run_name: '',
      simpleRoas: '',
      advancedRoas: '',
      subbudget: this.OVERALL,
      allSubbudgets: [],
      direction: 'optimize',
      bid_lookback_duration: '30',
      min_percent_clicks: 1,
      min_impressions: 100,
      volume: 'high',
      roas_floor: 1.0,
      where_clause: this.DEFAULT_WHERE_CLAUSE,
      algos: null,
      bid_levels: null,
      started: false,
      deleteClicked: false,
      error: null,
      output_type: this.PRELIM_BIDS,
      previous_bidding_run_id: '',
      run_dampering: true,
      view: this.SIMPLE,
      labels: [],
      exclude_labels: false,
      allLabels: [],
      allCampaigns: [],
      allAdGroups: [],
      selectByLabel: false,
      create_ab_test: false, 
      reverting: false,
      sem: true,
      display: true,
      social: true,
      exclude_inactive_campaigns: true,
      exclude_inactive_adgroups: true,
      exclude_inactive_keywords: true,
      exclude_excluded_accounts: true,
      exclude_excluded_campaigns: true,
      exclude_excluded_ab_tests: true,
      description: '',
      campaign_ids: [],
      ad_group_ids: [],
      min_days_since_bidding: 2,
      bid_floor: '',
      bid_ceiling: '',
      max_impression_share: '',
      use_clicks: false,
      use_roas: false,
      use_cpa: false,
      use_conversion_rate: false,
      use_engagement_score_clicks: false,
      use_engagement_score_cost: false,
      isAdmin: this.props.isAdmin,
      publishResponse: null,
      schedule: null,
      allEngines: [],
      engines: [],
      allTypes: [],
      entity_types: [],
    }
  }

  toggleView(currentView) {
    if (currentView == 'simple') {
      this.setState({ view: 'advanced' })
    }
    else {
      this.setState.view({ view: 'simple' })
    }
  }

  componentDidMount() {
    this.getLastRoas()
    this.getLabels()
    this.getCampaigns()
    this.getAdGroups()
    this.getData()
    if (this.state.id != -1) {
      this.getStatus()
    }
  }

  getLastRoas() {
    getLastROAS(this.props.internal_client_id).then(result => {
      if (this.state.simpleRoas == '') {
        this.setState({ simpleRoas: result.roas == null ? '' : result.roas })
      }
    })
  }

  getLabels() {
    getLabels(this.props.internal_client_id).then(labels => {
      this.setState({ allLabels: labels == null ? [] : labels })
    })
  }

  getCampaigns() {
    let allEngines = new Set([])
    let allTypes = new Set([])
    getCampaigns(this.props.internal_client_id).then(campaigns => {
      const allCampaigns = campaigns.map(c => {
        allEngines.add(c.engine)
        const types = JSON.parse(c.types)
        types.forEach(type => allTypes.add(type))
        return Object.assign({}, c, {types})
      })
      allEngines = [...allEngines].sort()
      allTypes = [...allTypes].sort()
      const newState = { allCampaigns, allEngines, allTypes }
      if (this.state.id == -1) {
        newState['engines'] = allEngines.includes('google') ? ['google'] : allEngines
        newState['entity_types'] = allTypes
      }
      this.setState(newState)
    })
  }

  getAdGroups() {
    getAdGroups(this.props.internal_client_id).then(adGroups => {
      const allAdGroups = adGroups.map(c => {
        const allTypes = new Set([])
        const ad_groups = c.ad_groups.map(ag => {
          const types = JSON.parse(ag.types)
          types.forEach(type => allTypes.add(type))
          return Object.assign({}, ag, {types})
        })
        return Object.assign({}, c, {ad_groups, types: [...allTypes]})
      })
      this.setState({ allAdGroups })
    })
  }

  getData() {
    getAlgos(this.props.internal_client_id, null, true).then((response) => {
      this.setState({ algos: response.results }, () => {
        if (this.state.id != -1) {
          this.getBidRunData()
        } else {
          this.handleSelectClient(this.props.internal_client_id)
        }
      })
    })
  }

  getBidRunData() {
    getBidRunData(this.state.id).then(response => {
      const data = response.data
      this.handleSelectClient(data.internal_client_id, data.subbudget || this.OVERALL, () => {
        const labels = data.labels == null ? [] : JSON.parse(data.labels)
        const newState = {
          bid_run_name: data.name,
          bid_levels: data.bid_levels,
          output_type: data.output_type,
          previous_bidding_run_id: data.previous_bidding_run_id == null ? '' : data.previous_bidding_run_id,
          run_dampering: data.run_dampering,
          // view: data.view,
          labels,
          exclude_labels: data.exclude_labels || false,
          selectByLabel: labels.length > 0,
          exclude_inactive_campaigns: data.exclude_inactive_campaigns,
          exclude_inactive_adgroups: data.exclude_inactive_adgroups,
          exclude_inactive_keywords: data.exclude_inactive_keywords,
          exclude_excluded_accounts: data.exclude_excluded_accounts,
          exclude_excluded_campaigns: data.exclude_excluded_campaigns,
          exclude_excluded_ab_tests: data.exclude_excluded_ab_tests,
          description: data.description || '',
          campaign_ids: data.campaign_ids || [],
          ad_group_ids: data.ad_group_ids || [],
          min_days_since_bidding: data.min_days_since_bidding == null ? 2 : data.min_days_since_bidding,
          bid_floor: data.bid_floor || '',
          bid_ceiling: data.bid_ceiling || '',
          max_impression_share: data.max_impression_share || '',
          use_clicks: data.use_clicks,
          use_roas: data.use_roas,
          use_cpa: data.use_cpa,
          use_conversion_rate: data.use_conversion_rate,
          use_engagement_score_clicks: data.use_engagement_score_clicks,
          use_engagement_score_cost: data.use_engagement_score_cost,
          workflow_id: data.workflow_id,
          workflow_name: data.workflow_name,
          run_id: data.run_id,
          create_ab_test: data.create_ab_test,
          schedule: data.schedule,
          engines: data.engines,
          entity_types: data.entity_types,
          min_percent_clicks: data.min_percent_clicks,
          min_impressions: data.min_impressions,
          volume: data.volume,
          roas_floor: data.roas_floor,
        }
        if (data.subbudget != null) {
          newState.subbudget = data.subbudget
        }
        if (data.roas != null) {
          newState[data.view == this.ADVANCED ? 'advancedRoas' : 'simpleRoas'] = data.roas
        }
        if (data.bid_direction != null) {
          newState.direction = data.bid_direction
        }
        if (data.bid_lookback_duration != null) {
          newState.bid_lookback_duration = data.bid_lookback_duration
        }
        if (data.where_clause != null) {
          newState.where_clause = data.where_clause
        }
        if (data.algos != null) {
          if (data.view == this.ADVANCED) {
            newState.algos = this.determineAlgos(data.algos)
          } else {
            // Since simple, let the algo update to the latest
          }
        } else if (!this.isDraft()) {
          newState.algos = this.state.algos.filter(algo => algo.checked)
        }
        this.setState(newState, () => this.updateRows())
      })
    })
  }

  determineAlgos(runAlgos) {
    let algos = []
    this.state.algos.forEach((candidate) => {
      candidate.checked = false
    })
    runAlgos.forEach((algo) => {
      this.state.algos.forEach((candidate) => {
        if (candidate.id == algo) {
          candidate.checked = true
        }
      })
    })

    // Add in all current algos (if draft) or algos used (if not)
    const isDraft = this.isDraft()
    this.state.algos.forEach((algo) => {
      if (isDraft || algo.checked) {
        algos.push(algo)
      }
    })
    return algos
  }

  handleSelectClient(internal_client_id, subbudget = this.OVERALL, callback = null) {
    getBudgetGoals(internal_client_id, null).then(result => {
      if (result.results.length > 0) {
        if (subbudget == this.OVERALL) {
          subbudget = result.results[0].subbudget
        }
      }
      getClient(internal_client_id).then(client => {
        if (this.state.algos == null) {
          FTNotice('biddingRun.biddingAlgosNotDefined', 10000)
        } else {
          this.state.algos.forEach(algo => {
            algo.checked = client.algos.indexOf(algo.id) >= 0
          })
          this.setState({ client, subbudget, allSubbudgets: result.results }, () => {
            this.setState({ bid_run_name: this.getDefaultBidRunName() }, () => this.getDefaultRoas(callback))
          })
        }
      })
    })
  }

  handleSelectClientSubbudget(subbudget, callback = null) {
    this.setState({ subbudget }, () => {
      this.getDefaultRoas(callback)
    })
  }

  getDefaultRoas(callback = null) {
    const api = (this.state.allSubbudgets.length == 0 || this.state.subbudget == null) ? getDefaultRoas(this.state.client.internal_client_id) : getDefaultRoasSubbudget(this.state.client.internal_client_id, this.state.subbudget)
    api.then(response => {
      this.setState({ advancedRoas: response.data }, callback)
    })
  }

  getStatus() {
    getBidRunStatus(this.state.id)
      .then(response => {
        if (!response || response.success) {
          let status = response ? response.data.status : 'no status'
          if (status == 'draft' || status == 'no status') {
            setTimeout(() => { this.getStatus() }, 3000)
          } else if (status == 'error') {
            FTNotice(response.data.error_message, 15000)
            this.setState({ started: false, error: response.data.error_message, status: status })
          } else {
            this.setState({ started: false, error: null, status: status })
          }
        } else if (response.error) {
          FTNotice(response.error, 3000)
          window.location.href = '/#/bidding/' + (this.state.schedule == null ? 'runs' : 'schedules')
        } else {
          FTNotice(response.data, 3000)
        }
      })
  }

  formatAMPM(date) {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  getDefaultBidRunName() {
    let today = new Date()
    let date = (today.getMonth() + 1) + '/' + today.getDate() + '/' +
      today.getFullYear() + '-'
    return this.state.client.name + '-' + date + this.formatAMPM(today)
  }

  save(shouldGenerateBids) {
    const isAdvanced = this.state.view == this.ADVANCED
    const isSimple = !isAdvanced
    if (!isAdvanced && this.state.selectByLabel && this.state.labels.length == 0) {
      FTNotice('biddingRun.mustSelectLabel', 15000)
      return
    }
    const algos = []
    this.state.algos.forEach(algo => {
      if ((isAdvanced && algo.checked) || (isSimple && algo.name == 'simple')) {
        algos.push(algo.id)
      }
    })
    const data = {
      name: this.state.bid_run_name,
      internal_roas: Number(isAdvanced ? this.state.advancedRoas : this.state.simpleRoas),
      where_clause: isAdvanced ? this.state.where_clause : this.DEFAULT_WHERE_CLAUSE,
      internal_client_id: isAdvanced ? Number(this.state.client.internal_client_id) : this.props.internal_client_id,
      bid_direction: this.state.direction,
      bid_lookback_duration: this.state.bid_lookback_duration,
      min_percent_clicks: this.state.min_percent_clicks,
      min_impressions: this.state.min_impressions,
      volume: this.state.volume,
      roas_floor: this.state.roas_floor,
      algos: algos,
      bid_levels: isAdvanced ? this.state.bid_levels : this.state.bid_levels || this.DEFAULT_BID_LEVELS,
      user_id: this.props.current_user_id,
      output_type: isAdvanced ? this.state.output_type : this.PRELIM_BIDS,
      previous_bidding_run_id: !isAdvanced || this.state.previous_bidding_run_id == '' ? null : this.state.previous_bidding_run_id,
      run_dampering: isAdvanced ? this.state.run_dampering : false,
      subbudget: isAdvanced ? this.state.subbudget : null,
      view: this.state.view,
      labels: isAdvanced || this.state.labels.length == 0 ? null : JSON.stringify(this.state.labels),
      exclude_labels: this.state.exclude_labels,
      status: 'draft',
      exclude_inactive_campaigns: isSimple || this.state.exclude_inactive_campaigns,
      exclude_inactive_adgroups: isSimple || this.state.exclude_inactive_adgroups,
      exclude_inactive_keywords: isSimple || this.state.exclude_inactive_keywords,
      exclude_excluded_accounts: isAdvanced && this.state.exclude_excluded_accounts,
      exclude_excluded_campaigns: isAdvanced && this.state.exclude_excluded_campaigns,
      exclude_excluded_ab_tests: isAdvanced && this.state.exclude_excluded_ab_tests,
      description: this.state.description == '' ? null : this.state.description,
      campaign_ids: isAdvanced || this.state.campaign_ids.length == 0 ? null : this.state.campaign_ids,
      ad_group_ids: isAdvanced || this.state.ad_group_ids.length == 0 ? null : this.state.ad_group_ids,
      min_days_since_bidding: isAdvanced || this.state.min_days_since_bidding == '' ? null : parseInt(this.state.min_days_since_bidding),
      bid_floor: isAdvanced || this.state.bid_floor == '' ? null : parseFloat(this.state.bid_floor),
      bid_ceiling: isAdvanced || this.state.bid_ceiling == '' ? null : parseFloat(this.state.bid_ceiling),
      max_impression_share: isAdvanced ? 89 : this.state.max_impression_share == null ? null : parseFloat(this.state.max_impression_share),
      use_clicks: this.state.use_clicks,
      use_roas: this.state.use_roas,
      use_cpa: this.state.use_cpa,
      use_conversion_rate: this.state.use_conversion_rate,
      use_engagement_score_clicks: this.state.use_engagement_score_clicks,
      use_engagement_score_cost: this.state.use_engagement_score_cost,
      create_ab_test: this.state.create_ab_test,
      schedule: this.state.schedule,
      engines: this.state.engines,
      entity_types: this.state.entity_types,
    }

    if (data.bid_levels && (data.bid_levels.scale == null || data.bid_levels.scale < 10 || data.bid_levels.scale > 400)) {
      FTNotice(i18next.t('bidLevels.scalingPercentageMustBeBetween', { min: 10, max: 400 }))
      return
    }

    if (this.state.id != -1) {
      data['id'] = this.state.id
    }

    this.setState({ started: true }, () => {
      saveBiddingRun(data).then(response => {
        if (response.error) {
          FTNotice(response.error, 15000)
        } else {
          if (shouldGenerateBids) {
            generateBids({id: response.data.id, internal_client_id: data.internal_client_id})
              .then(response => {
                if (response.error) {
                  this.setState({started: false})
                  FTNotice(response.error, 15000)
                } else if (response.data == 'started') {
                  window.location.href = '/#/bidding/bidding_run?id=' + response.id
                  this.setState(
                    { id: response.id, error: null },
                    () => setTimeout(() => { this.getStatus() }, 3000)
                  )
                }
              })
          } else {
            window.location.href = `/#/bidding/schedules`
          }
        }
      })
    })
  }

  publish(engines, campaigns, adGroups) {
    this.setState({ publishing: true }, () =>
      publishBids(this.state.id, engines, campaigns, adGroups)
        .then(response => {
          if (response.success)
            this.setState({ status: 'published' })
        })
    )
  }

  handleRevert() {
    this.setState({ reverting: true })
    revertBidRun(this.state.id)
      .then(response => {
        if (!response.success) {
          this.setState({ reverting: false })
          FTNotice(response.error, 15000)
        } else {
          window.location.href = `/#/bidding/bidding_run?id=${response.id}`
          window.scrollTo(0, 0)
          this.setState({ id: response.id, reverting: false }, () => {
            this.getStatus()
            this.getData()
          })
        }
      })
  }

  handleDelete(confirmed) {
    if (confirmed) {
      deleteBidRun(this.state.id)
        .then(response => {
          if (!response.success) {
            FTNotice(response.error, 15000)
            this.setState({ deleteClicked: false })
          } else {
            window.location.href = '/#/bidding/' + (this.state.schedule == null ? 'runs' : 'schedules')
          }
        })
    } else {
      this.setState({ deleteClicked: false })
    }
  }

  renderConfirmDelete() {
    return (
      <Confirm opened={this.state.deleteClicked} title={i18next.t('biddingRun.deleteBiddingRun')} question={i18next.t('biddingRun.confirmDeleteBiddingRun')}
        action={(confirmed) => { this.handleDelete(confirmed) }} button="Delete" />
    )
  }

  updateView(view) {
    if (view == this.ADVANCED) {
      this.setState({ view })
    } else {
      const state = { view }
      if (this.state.direction == '') {
        state.direction = 'optimize'
      }
      this.setState(state)
    }
  }

  renderSelectView() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.view')}</h3>
        <div className='col-xs-4'>
          <select value={this.state.view}
            onChange={event => this.updateView(event.target.value)}
            className="form-control" disabled={!this.isDraft()}>
            <option value="advanced">{i18next.t('biddingRun.advanced')}</option>
            <option value="simple">{i18next.t('biddingRun.simple')}</option>
          </select>
        </div>
      </div>
    )
  }

  renderSelectClient() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.client')}</h3>
        <div className='col-xs-4'>
          <select value={this.state.client.internal_client_id}
            onChange={event => this.handleSelectClient(event.target.value)}
            className="form-control" id="schedule-dag" disabled={!this.isDraft()}>
            {this.props.clients.map((client, i) => {
              return (
                <option key={i} value={client.internal_client_id}>{client.name}</option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }

  renderWorkflow() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.workflow')}</h3>
        <div className='col-xs-4'>
          <Link to={`/workflows/notebook/${this.state.workflow_id}/run/${this.state.run_id}`}>{this.state.workflow_name}</Link>
        </div>
      </div>
    )
  }

  renderSelectClientSubbudget() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.subbudget')}</h3>
        <div className='col-xs-4'>
          <select value={this.state.subbudget}
            onChange={event => this.handleSelectClientSubbudget(event.target.value)}
            className="form-control" id="schedule-dag" disabled={!this.isDraft()}>
            {this.state.allSubbudgets.map((client_goal, i) => {
              return (
                <option key={i} value={client_goal.subbudget}>{client_goal.subbudget}</option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }

  renderKPI() {
    let subbudget = null
    this.state.allSubbudgets.forEach(budget => {
      if (budget.subbudget == this.state.subbudget) {
        subbudget = budget
      }
    })
    return (
      <div>
        <h3>{i18next.t('biddingRun.keyPerformanceIndicator')}</h3>
        <div className='col-xs-4'>
          {subbudget && subbudget.kpi ? (subbudget.kpi == 'roas' ? i18next.t('biddingRun.roas') : i18next.t('biddingRun.budget')) : i18next.t('biddingRun.notSet')}
        </div>
      </div>
    )
  }

  renderClientPacing() {
    const channels = []
    if (this.state.sem) {
      channels.push('sem')
    }
    if (this.state.display) {
      channels.push('display')
    }
    if (this.state.social) {
      channels.push('social')
    }
    return (
      <div>
        <h3>{i18next.t('biddingRun.clientPacing')}</h3>
        <div style={{ marginBottom: 50 }}>
          <h4>{i18next.t("biddingRun.channel")}</h4>
          <div className="col-md-1">
            <input
              type="checkbox"
              checked={this.state.sem}
              onChange={event => this.setState({ sem: event.target.checked })}
            />&nbsp;{i18next.t("biddingRun.sem")}
          </div>
          <div className="col-md-1">
            <input
              type="checkbox"
              checked={this.state.display}
              onChange={event => this.setState({ display: event.target.checked })}
            />&nbsp;{i18next.t("biddingRun.display")}
          </div>
          <div className="col-md-1">
            <input
              type="checkbox"
              checked={this.state.social}
              onChange={event => this.setState({ social: event.target.checked })}
            />&nbsp;{i18next.t("biddingRun.social")}
          </div>
        </div>
        <Pacing hideSection={true} internal_client_id={this.state.client.internal_client_id} subbudgets={[this.state.subbudget]} channels={channels} />
      </div>
    )
  }

  renderBidRunName() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.bidRunName')}</h3>
        <div className='col-xs-4'>
          <input type="text" disabled={!this.isDraft()}
            value={this.state.bid_run_name}
            onChange={event => this.setState({ bid_run_name: event.target.value })}
            className="form-control" />
        </div>
      </div>
    )
  }

  renderBidDirection() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.biddingDirection')}</h3>
        <div className="col-md-12 mt-25">
          {this.state.view == this.ADVANCED && (
            <div className="col-xs-2">
              <input type="radio" value="" disabled={!this.isDraft()}
                checked={this.state.direction == ''} onChange={() => this.setState({ direction: event.target.value })} />
              <label>{i18next.t('biddingRun.calculateDirection')}</label>
            </div>
          )}
          <div className="col-xs-2">
            <input type="radio" value="optimize" disabled={!this.isDraft()}
              onChange={event => this.setState({ direction: event.target.value })}
              checked={this.state.direction == 'optimize'} />
            <label>{i18next.t('biddingRun.optimize')}</label>
          </div>
          <div className="col-xs-2">
            <input type="radio" value="up" disabled={!this.isDraft()}
              checked={this.state.direction == 'up'} onChange={event => this.setState({ direction: event.target.value })} />
            <label>{i18next.t('biddingRun.bidUp')}</label>
          </div>
          <div className="col-xs-2">
            <input type="radio" value="down" disabled={!this.isDraft()}
              onChange={event => this.setState({ direction: event.target.value })}
              checked={this.state.direction == 'down'} />
            <label>{i18next.t('biddingRun.bidDown')}</label>
          </div>
        </div>
      </div>
    )
  }

  renderRunDampering() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.dampering')}</h3>
        <div className="col-md-12 mt-25">
          <div className="col-xs-2">
            <input type="radio" value="default" disabled={!this.isDraft()}
              checked={this.state.run_dampering} onChange={() => this.setState({ run_dampering: true })} />
            <label>{i18next.t('biddingRun.excludeRepetitive')}</label>
          </div>
          <div className="col-xs-2">
            <input type="radio" value="up" disabled={!this.isDraft()}
              checked={!this.state.run_dampering} onChange={() => this.setState({ run_dampering: false })} />
            <label>{i18next.t('biddingRun.includeAll')}</label>
          </div>
        </div>
      </div>
    )
  }

  substituteVariables(sql) {
    sql = sql.replace(/(\/\*[^*]*\*\/)/g, '')
    sql = sql.replace(/\@CLIENTID/g, 1)
    sql = sql.replace(/\@\(BIDDINGRUNID\)/g, 1)
    sql = sql.replace(/\@BIDDINGRUNID/g, 1)
    sql = sql.replace(/\@WHERE_CLAUSE/g, 'TRUE')
    sql = sql.replace(/\@PRIOR_BID_RUN/g, 1)
    return sql
  }

  updateAlgoCheck(algo, checked) {
    algo.checked = checked
    this.updateRows()
  }

  getColumns() {
    return [{
      header: 'Select',
      width: 70,
      cellRenderer: (_, row) => (
        <input
          type={this.state.output_type == this.BIDS ? 'radio' : 'checkbox'}
          defaultChecked={row.checked}
          onChange={event => this.updateAlgoCheck(row, event.target.checked)} />
      ),
    }, {
      id: 'name',
      header: 'Name',
      width: 200,
    }, {
      id: 'algo',
      header: 'Algorithm',
      width: 1000,
      cellRendererAffectsSize: false,
      cellRenderer: (_, row) => (
        <textarea rows="10" style={{width: '975px', overflow: 'auto'}} defaultValue={row.algo} className="form-control" readOnly />
      ),
    }]
  }

  checkAll(checked) {
    this.state.algos.forEach(algo => {
      algo.checked = checked
    })
    this.updateRows()
  }

  updateRows() {
    if (this.tableRef.current) {
      this.tableRef.current.updateRows(this.state.algos)
    }
  }

  isDraft() {
    const status = this.state.status
    return status == 'new' || status == 'draft' || status == 'pending' || status == 'error'
  }

  renderSelectAlgos() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.algorithms')}</h3>
        {this.state.algos && this.isDraft() && (
          <div>
            <div>
              <div className="col-md-1">
                <button className="form-control btn btn-success mt-10"
                  onClick={() => this.checkAll(true)}>{i18next.t('biddingRun.selectAll')}</button>
              </div>
              <div className="col-md-1">
                <button className="form-control btn btn-success mt-10"
                  onClick={() => this.checkAll(false)}>{i18next.t('biddingRun.clearAll')}</button>
              </div>
            </div>
            {this.state.algos &&
              <div>
                <div className="col-md-12" style={{ marginTop: 10 }}>
                  <Table dataset={this.state.algos}
                    ref={this.tableRef}
                    columns={this.getColumns()}
                    hidePagination={true}
                    rowHeight={225}
                    autoHeight={true}
                  />
                </div>
              </div>}
          </div>
        )}
        {this.state.algos && !this.isDraft() && (
          <div>
            {this.state.algos.map((algo, i) => {
              return (
                <div key={i}>
                  <div className="col-md-2" />
                  <div className="col-md-8">
                    <textarea rows="20" value={algo.algo}
                      className="form-control" disabled />
                    <br />
                  </div>
                  <div className="col-md-2" />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  renderRoas() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.roas')}</h3>
        <div className='col-xs-2'>
          <input type="text" disabled={!this.isDraft()}
            value={this.state.view == this.ADVANCED ? this.state.advancedRoas : this.state.simpleRoas}
            onChange={event => this.setState(this.state.view == this.ADVANCED ? { advancedRoas: event.target.value } : { simpleRoas: event.target.value })}
            className="form-control" />
        </div>
      </div>
    )
  }

  excludeCheckbox(key) {
    return (
      <div style={{ display: 'inline-block', width: 200 }}>
        <input
          type="checkbox"
          style={{ verticalAlign: 'top' }}
          checked={this.state[key]}
          onChange={event => {
            const state = {}
            state[key] = event.target.checked
            this.setState(state)
          }}
          disabled={!this.isDraft()} /> {i18next.t('biddingRun.' + key)}
      </div>
    )
  }

  renderRouter() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.router')}</h3>
        <div className='col-md-8'>
          <div>
            {this.excludeCheckbox('exclude_inactive_campaigns')}
            {this.excludeCheckbox('exclude_inactive_adgroups')}
            {this.excludeCheckbox('exclude_inactive_keywords')}
          </div>
          <div>
            {this.excludeCheckbox('exclude_excluded_accounts')}
            {this.excludeCheckbox('exclude_excluded_campaigns')}
            {this.excludeCheckbox('exclude_excluded_ab_tests')}
          </div>
          <div style={{ marginTop: 10 }}>{i18next.t('biddingRun.whereClause')}</div>
          <textarea rows="5" value={this.state.where_clause}
            onChange={event => this.setState({ where_clause: event.target.value })}
            className="form-control" disabled={!this.isDraft()} />
        </div>
      </div>
    )
  }

  updateLabels(label, include) {
    const newLabels = this.state.labels.slice()
    const labelPresent = newLabels.includes(label)
    if (include && !labelPresent) {
      newLabels.push(label)
    } else if (!include && labelPresent) {
      newLabels.splice(newLabels.indexOf(label), 1)
    }
    this.setState({ labels: newLabels })
  }

  renderBidLevels() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.bidLevels')}</h3>
        <div >
          <div className="col-md-12 mt-25" style={{ marginBottom: 10 }}>
            <div className="col-xs-2">
              <input type="radio" value="default" disabled={!this.isDraft()}
                checked={this.state.bid_levels == null || this.state.bid_levels.scale == null} onChange={() => this.setState({ bid_levels: null })} />
              <label>{i18next.t('bidLevels.useCalculated')}</label>
            </div>
            <div className="col-xs-2">
              <input type="radio" value="up" disabled={!this.isDraft()}
                checked={this.state.bid_levels != null && this.state.bid_levels.scale != null} onChange={() => this.setState({ bid_levels: this.DEFAULT_BID_LEVELS })} />
              <label>{i18next.t('bidLevels.adjustBidLevels')}</label>
            </div>
          </div>
          {this.state.bid_levels != null && this.state.bid_levels.scale != null && (
            <table style={{ marginLeft: 40 }}>
              <tbody>
                <tr>
                  <td style={{ paddingRight: 10 }}>
                    <div style={{ fontSize: 16 }}>{i18next.t('bidLevels.scalingPercentage')}</div>
                    <div style={{ fontSize: 10 }}>{i18next.t('bidLevels.scalingPercentageHelp')}</div>
                  </td>
                  <td>
                    {this.isDraft() ? (
                      <input type="number" value={this.state.bid_levels.scale || ''} style={{ width: 60, marginRight: 5 }}
                        onChange={event => this.setState({ bid_levels: Object.assign({}, this.state.bid_levels, { scale: event.target.value == '' ? null : parseInt(event.target.value) }) })} />
                    ) : this.state.bid_levels.scale}
                    {i18next.t('bidLevels.percent')}
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: 10, paddingTop: 10 }}>
                    <div style={{ fontSize: 16 }}>{i18next.t('bidLevels.bidPercentageRange')}</div>
                    <div style={{ fontSize: 10 }}>{i18next.t('bidLevels.bidPercentageRangeHelp')}</div>
                    <div style={{ fontSize: 10 }}>{i18next.t('bidLevels.bidPercentageRangeHelp2')}</div>
                  </td>
                  <td style={{ paddingTop: 10 }}>
                    <span>
                      {this.isDraft() ? (
                        <input type="number" value={this.state.bid_levels.minimum || ''} style={{ width: 60, marginRight: 5 }}
                          onChange={event => this.setState({ bid_levels: Object.assign({}, this.state.bid_levels, { minimum: event.target.value == '' ? null : parseInt(event.target.value) }) })} />
                      ) : this.state.bid_levels.minimum}
                      {i18next.t('bidLevels.percent')}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>
                        {i18next.t('bidLevels.to')}
                      </span>
                      {this.isDraft() ? (
                        <input type="number" value={this.state.bid_levels.maximum || ''} style={{ width: 60, marginRight: 5 }}
                          onChange={event => this.setState({ bid_levels: Object.assign({}, this.state.bid_levels, { maximum: event.target.value == '' ? null : parseInt(event.target.value) }) })} />
                      ) : this.state.bid_levels.maximum}
                      {i18next.t('bidLevels.percent')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    )
  }

  renderGenerate() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.generateBids')}</h3>
        <div>
          <Checkbox
            value={this.state.create_ab_test}
            onChange={event => this.setState({create_ab_test: event.target.checked})}
            labelText="bidding.createAbTest"
            disabled={this.state.started || this.state.status == 'published'} />
        </div>
        <div className="col-md-5 text-right" />
        <div className="col-md-1 text-right">
          <button className="form-control btn btn-success mt-10"
            onClick={() => this.save(true)} disabled={this.state.started}>{i18next.t('biddingRun.generate')}</button>
        </div>
        {this.state.error &&
          <h3 style={{ textAlign: 'center', clear: 'both', paddingTop: 10, color: 'red' }}>
            {this.state.error}
          </h3>
        }
        <div className="col-md-5 text-right" />
      </div>
    )
  }

  renderSummary() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.biddingAnalyticsSummary')}</h3>
        <BidSummary
          bidding_run_id={this.state.id} 
          isDraft={this.isDraft()} 
          props={props}
          onUpdate={newState => this.setState(newState)}
          publish={(engines, campaigns, adGroups) => this.publish(engines, campaigns, adGroups)} />
      </div>
    )
  }

  renderPublish() {
    return (
      <div>
        <h3>{i18next.t('biddingRun.publishBids')}</h3>
        <div className="col-md-5 text-right" />
        {this.state.status != 'published' && this.state.status != 'abandoned' && (
          <div className="col-md-1 text-right">
            <button className="form-control btn btn-success mt-10"
              disabled={this.state.publishing} onClick={() => this.publish()}>{i18next.t('biddingRun.publish')}</button>
          </div>
        )}
        {this.state.status == 'published' && (
          <div className="col-md-1 text-right">
            <button className="form-control btn btn-danger mt-10"
              disabled={this.state.reverting} onClick={() => this.handleRevert()}>{i18next.t('biddingRun.revert')}</button>
          </div>
        )}
        {this.state.status != 'published' && (
          <div className="col-md-1 text-right">
            <button className="form-control btn btn-danger mt-10"
              onClick={() => this.setState({ deleteClicked: true })} >{i18next.t('biddingRun.delete')}</button>
          </div>
        )}
        <div className="col-md-5 text-right" />
      </div>
    )
  }

  renderBidsSections() {
    return (
      <div>
        {this.renderSection(this.renderSummary())}
        {this.renderSection(this.renderPublish())}
      </div>
    )
  }

  renderSection(content) {
    return (
      <div>
        <div className='col-xs-12'>
          <div className='box'>
            <div className='box-header with-border'>
              {content}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.clients && this.state.client) {
      return (
        <>
          {this.renderConfirmDelete()}
          {
            this.state.view == 'simple' || !this.props.isAdmin ?
              <BiddingLayout
                currentBiddingRunId={this.state.id}
                previous_bidding_run_id={this.state.previous_bidding_run_id}
                toggleView={() => this.toggleView(this.state.view)}
                bidRunName={this.state.bid_run_name}
                onChangeBidRunName={event => this.setState({ bid_run_name: event.target.value })}
                bidDirection={this.state.direction}
                onChangeBidDirection={event => this.setState({ direction: event.target.value })}
                lookbackDuration={this.state.bid_lookback_duration}
                volume={this.state.volume}
                roasFloor={this.state.roas_floor}
                minPercentClicks={this.state.min_percent_clicks}
                minImpressions={this.state.min_impressions}
                onChangeLookbackDuration={event => this.setState({ bid_lookback_duration: event.target.value })}
                getCampaigns={this.getCampaigns}
                allCampaigns={this.state.allCampaigns}
                allAdGroups={this.state.allAdGroups}
                engines={this.state.engines}
                allEngines={this.state.allEngines}
                entityTypes={this.state.entity_types}
                allTypes={this.state.allTypes}
                allLabels={this.state.allLabels}
                bidLevels={this.state.bid_levels || this.DEFAULT_BID_LEVELS}
                min_days_since_bidding={this.state.min_days_since_bidding}
                bid_floor={this.state.bid_floor}
                bid_ceiling={this.state.bid_ceiling}
                max_impression_share={this.state.max_impression_share}
                use_clicks={this.state.use_clicks}
                use_roas={this.state.use_roas}
                use_cpa={this.state.use_cpa}
                use_conversion_rate={this.state.use_conversion_rate}
                use_engagement_score_clicks={this.state.use_engagement_score_clicks}
                use_engagement_score_cost={this.state.use_engagement_score_cost}
                onUpdate={newState => this.setState(newState)}
                generateBids={() => this.save(true)}
                save={() => this.save(false)}
                internal_client_id={this.props.internal_client_id}
                started={this.state.started}
                renderSummary={() => this.renderSummary()}
                renderSection={() => this.renderPublish()}
                bidding_run_id={this.state.id}
                isDraft={this.isDraft()}
                isAdmin={this.state.isAdmin}
                action={(confirmed) => { this.handleDelete(confirmed) }}
                status={this.state.status}
                publishing={this.state.publishing}
                publish={(engines, campaigns, adGroups) => this.publish(engines, campaigns, adGroups)}
                reverting={this.state.reverting}
                handleRevert={() => this.handleRevert()}
                renderConfirmDelete={() => this.renderConfirmDelete()}
                campaign_ids={this.state.campaign_ids}
                ad_group_ids={this.state.ad_group_ids}
                labels={this.state.labels}
                excludeLabels={this.state.exclude_labels}
                selectByLabel={this.state.selectByLabel}
                createAbTest={this.state.create_ab_test}
                schedule={this.state.schedule}
              />
              :
              <div>
                {this.renderSection(this.renderBidRunName())}
                {this.props.isAdmin && this.renderSection(this.renderSelectView())}
                {this.props.isAdmin && this.state.view == this.ADVANCED && this.renderSection(this.renderSelectClient())}
                {this.state.view == this.ADVANCED && this.state.workflow_name && this.renderSection(this.renderWorkflow())}
                {this.state.output_type == this.PRELIM_BIDS && (
                  <div>
                    {this.state.view == this.ADVANCED && !(this.state.allSubbudgets.length < 1 || this.state.subbudget == null) && this.renderSection(this.renderSelectClientSubbudget())}
                    {this.state.view == this.ADVANCED && this.renderSection(this.renderKPI())}
                    {this.renderSection(this.renderRoas())}
                    {this.state.view == this.ADVANCED && this.renderSection(this.renderClientPacing())}
                    {this.renderSection(this.renderBidDirection())}
                    {this.state.view == this.ADVANCED && this.renderSection(this.renderBidLevels())}
                    {this.renderSection(this.renderRouter())}
                    {this.state.output_type == this.PRELIM_BIDS && this.renderSection(this.renderSelectAlgos())}
                    {this.state.view == this.ADVANCED && this.renderSection(this.renderRunDampering())}
                    {this.renderSection(this.renderGenerate())}
                  </div>
                )}
                {this.state.id != -1 && !this.state.started ? this.renderBidsSections() : null}
              </div>
          }
        </>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    current_user_id: state.users.user ? state.users.user.id : null,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default withRouter(connect(mapStateToProps)(AddEditBiddingRun))
