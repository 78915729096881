import { Component } from 'react'
import { fetchDailyDimensionsData } from '../../api/dimensions'
import i18next from 'i18next'
import locale from '../../api/locale'
import Section from '../widgets/Section'
import Table from '../widgets/Table'

export default class Keywords extends Component {
  getChartData(mode, tableData) {
    const { start, end, filters, internal_client_id, dimension } = this.props
    const params = Object.assign(
      {
        internal_client_id: internal_client_id,
        from_date: start,
        to_date: end,
        mode: mode,
        dimension: dimension,
      },
      filters,
      tableData
    )
    return fetchDailyDimensionsData(params)
  }

  getCommonColumns() {
    return [{
        id: 'clicks',
        datatype: 'numeric',
        width: 100,
      }, {
        id: 'cost',
        datatype: 'numeric',
        width: 100
      }, {
        id: 'cpc',
        datatype: 'numeric',
        width: 100,
      }, {
        id: 'internal_roas',
        datatype: 'numeric',
        width: 140
      }, {
        id: 'internal_rpc',
        datatype: 'numeric',
        width: 130
      }, {
        id: 'impressions',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'internal_revenue',
        datatype: 'numeric',
        width: 155,
      }, {
        id: 'engine_roas',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'engine_rpc',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'engine_revenue',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'ctr',
        datatype: 'numeric',
        width: 100
      }, {
        id: 'cr',
        datatype: 'numeric',
        width: 150
      }, {
        id: 'engine_cr',
        datatype: 'numeric',
        width: 150
      }, {
        id: 'internal_aov',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'engine_aov',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'internal_cpa',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'engine_cpa',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'max_cpc',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'internal_conversion',
        datatype: 'numeric',
        width: 120
      }, {
        id: 'engine_conversion',
        datatype: 'numeric',
        width: 120
      },
    ]
  }
  
  getColumns() {
    return [{
        id: 'engine',
        datatype: 'text',
        width: 90,
      },{
        id: 'account_descriptive_name',
        datatype: 'text',
        width: 250,
      },{
        id: 'campaign_name',
        datatype: 'text',
        width: 250,
      },{
        id: 'ad_group_name',
        datatype: 'text',
        width: 250,
      },{
        id: 'criteria',
        header: 'dimensions.keyword',
        datatype: 'text',
        width: 250,
      }, ...this.getCommonColumns()]
  }

  columnOverride(columns) {
    const copy = []
    columns.forEach(col => {
      if (!['engine', 'account_descriptive_name', 'campaign_name', 'ad_group_name', 'criteria'].includes(col.id)) {
        copy.push(Object.assign({}, col, {header: i18next.t('dimensions.current', {column: locale.metrics[col.id]})}))
        copy.push(Object.assign({}, col, {id: col.id + '_2', header: i18next.t('dimensions.previous', {column: locale.metrics[col.id]})}))
      } else {
        copy.push(col)
      }
    })
    return copy
  }

  render() {
    return (
      <>
        <Section name="dimensions.top">
          <Table
            height={230}
            headerHeight={50}
            tableName="dimension_top"
            fetch={tableData => this.getChartData('top', tableData)}
            columns={this.getColumns()} />
        </Section>
        <Section name="dimensions.bottom">
          <Table
            height={230}
            headerHeight={50}
            tableName="dimension_bottom"
            fetch={tableData => this.getChartData('bottom', tableData)}
            columns={this.getColumns()} />
        </Section>
        <Section name="dimensions.mostChanged">
          <Table
            headerHeight={50}
            tableName="dimension_by_day"
            fetch={tableData => this.getChartData('by_day', tableData)}
            columns={this.getColumns()}
            columnOverride={columns => this.columnOverride(columns)}
            preventSort={true} />
        </Section>
      </>
    )
  }
}
