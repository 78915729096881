import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import Form from '../Form'
import Field from '../Field'
import Table from '../widgets/Table'
import FTNotice from '../FTNotice'
import AddRow from '../AddRow'
import DeleteButton from '../DeleteButton'
import SQLEditor from '../SQLEditor'
import { executeSql, exportSql } from '../../api/dashboards'
import { getAlert, updateAlert, createAlert, deleteAlert} from '../../api/alerts.js'
import { getSlackChannels } from '../../api/slack'
import i18next from 'i18next'

class Alert extends Component {
  OK = 'OK'
  ERROR = 'Error'
  PAUSED = 'Paused'
  SLACK = 'Slack'
  EMAIL = 'Email'
  REDSHIFT = 'Redshift'
  POSTGRES = 'Postgres'
  DAILY = 'Daily'
  HOURLY = 'Hourly'

  ALERT_EMAIL_RECIPIENTS = [{
    column: 'emailAddress',
    required: true
  }]
  
  previewTableRef = createRef()
  recipientTableRef = createRef()

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      sql: '',
      internal_client_id: props.isAdmin ? null : props.internal_client_id,
      alert_type: this.EMAIL,
      database: this.REDSHIFT,
      daily_frequency: this.DAILY,
      status: this.OK,
      showCreate: false,
      slack_channel_id: '',
      slack_channel_name: '',
      slackChannels: [],
      emailRecipients: [],
      columns: null,
      showPreview: null,
      use_client_channel: true,
    }
  }

  componentDidMount() {
    if(this.props.match.params.id !== 'new') {
      getAlert(this.props.match.params.id, this.props.internal_client_id).then(alert => {
        if (alert.email_recipients != null) {
          alert.emailRecipients = alert.email_recipients.split(',').map(recipient => ({emailAddress: recipient}))
        }
        delete alert.email_recipients
        if (alert.slack_channel_id == null) {
          alert.slack_channel_id = ''
        }
        if (alert.slack_channel_name == null) {
          alert.slack_channel_name = ''
        }
        this.setState(alert)
      })
    }
    getSlackChannels(this.props.internal_client_id).then(channels => {
      this.setState({slackChannels: channels.filter(channel => this.props.isAdmin || !channel.name.startsWith('admin: '))})
    })
  }

  handleSubmit() {
  	let slackChannelId = this.state.slack_channel_id
  	let slackChannelName = this.state.slack_channel_name
  	if (slackChannelId == '' && this.state.slackChannels.length) {
  		slackChannelId = this.state.slackChannels[0].id
  		slackChannelName = this.state.slackChannels[0].name
  	}
    const data = {
      name: this.state.name,
      description: this.state.description,
      internal_client_id: this.state.internal_client_id,
      sql: this.state.sql,
      status: this.state.status,
      alert_type: this.state.alert_type,
      database: this.state.database,
      daily_frequency: this.state.daily_frequency,
      email_recipients: this.state.emailRecipients.map(recipient => recipient.emailAddress).join(','),
      slack_channel_id: slackChannelId ? slackChannelId : null,
      slack_channel_name: slackChannelName ? slackChannelName : null,
      use_client_channel: this.state.use_client_channel,
    }
    if (data.name.trim() == '') {
      FTNotice('alert.pleaseEnterName', 15000)
    } else if (data.sql.trim() == '') {
      FTNotice('alert.pleaseEnterSQL', 15000)
    } else if (data.alert_type == this.SLACK && data.slack_channel_id == '') {
      FTNotice('alert.pleaseSelectSlackChannel', 15000)
    } else if (data.alert_type == this.EMAIL && data.email_recipients == '') {
      FTNotice('alert.pleaseIdentifyEmailAddresses', 15000)
    } else {
      if (this.props.match.params.id !== 'new') {
        updateAlert(this.props.match.params.id, this.props.internal_client_id, data).then(result => {
          if (result && result.name) {
            window.location.href = '/#/alerts'
          } else {
            FTNotice('alert.couldNotBeDeleted', 15000)
          }
        })
      } else {
        createAlert(this.props.internal_client_id, data).then(result => {
          if (result && result.name) {
            window.location.href = '/#/alerts'
          } else {
            FTNotice('alert.couldNotBeCreated', 15000)
          }
        })
      }
    }
  }

  deleteAlert() {
    deleteAlert(this.props.match.params.id).then((result) => {
      if (result.success) {
        window.location.href = '/#/alerts'
      } else {
        FTNotice('alert.couldNotBeDeleted', 15000)
      }
    })
  }

  addEmailRecipient(recipient) {
    const newRecipients = this.state.emailRecipients.slice()
    newRecipients.push(recipient)
    this.setState({emailRecipients: newRecipients, showCreate: false}, () => this.updateRows())
  }

  removeEmailRecipient(recipient) {
    if (this.state.emailRecipients.indexOf(recipient) >= 0) {
      const newRecipients = this.state.emailRecipients.slice()
      newRecipients.splice(newRecipients.indexOf(recipient), 1)
      this.setState({emailRecipients: newRecipients}, () => this.updateRows())
    }
  }

  updateRows() {
    if (this.recipientTableRef.current) {
      this.recipientTableRef.current.updateRows(this.state.emailRecipients)
    }
  }

  getEmailColumns() {
    return [{
        id: 'emailAddress',
        header: 'Email Address',
        datatype: 'text',
      }, {
        cellRenderer: (_, row) => ( 
          <DeleteButton
            objectType=""
            objectName={row.emailAddress}
            deleteObject={() => this.removeEmailRecipient(row)}/>
        ),
        width:200,
      }
    ]
  }

  fetch(tableData={}) {
    return this.executeSql(tableData).then(response => {
      if (response.result != 'success') {
        FTNotice(response.result, 15000)
      }
      return response
    })
  }

  exportSql(tableData={}) {
    return exportSql(-1, {sql: this.state.sql, database: 'redshift', internal_client_id: this.props.internal_client_id}, tableData)
  }

  executeSql(tableData) {
    return executeSql(-1, {sql: this.state.sql, database: 'redshift', internal_client_id: this.props.internal_client_id}, tableData)
  }

  preview() {
    this.executeSql({page: 1, page_size: 1}).then(response => {
      const sqlResult = response.results
      if (response.result == 'success') {
        let columns=[]
        if (sqlResult.length > 0) {
          Object.keys(sqlResult[0]).forEach(key => {
            columns.push({
              id: key,
              header: key.replace('_', ' '),
              datatype: 'other',
              cellRenderer: (value) => ( <div dangerouslySetInnerHTML={{ __html: value }} />),
            })
          })
        }
        const table = this.previewTableRef.current
        this.setState({columns: columns, showPreview: true}, () => {
          if (table) {
            table.refreshRows()
          }
        })
      } else {
        FTNotice(response.result, 15000)
      }
    })
  }

  substituteVariables(sql) {
    return sql.replace(/\{internal_client_id\}/g, this.props.internal_client_id)
  }
  
  updateSlackChannel(slackChannelId) {
    const state = {slack_channel_id: slackChannelId, slack_channel_name: 'unknown'}
    this.state.slackChannels.forEach(channel => {
      if (channel.id == slackChannelId) {
        state.slack_channel_name = channel.name
      }
    })
    this.setState(state)
  }

  render() {
    return (
      <Form
        objectType="alert.alert"
        objectName={this.state.name}
        newObject={this.props.match.params.id == 'new'}
        updateObject={() => this.handleSubmit()}
        deleteObject={() => this.deleteAlert()}
        parentLink={'/alerts'}
      >
        <Field label="alert.name">
          <input className="form-control" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} />
        </Field>
        <Field label="alert.description">
          <textarea className="form-control" value={this.state.description} onChange={(event) => this.setState({description: event.target.value})} />
        </Field>
        {this.props.isAdmin && (
          <Field label="Client">
            <select className="form-control" value={this.state.internal_client_id == null ? '' : this.state.internal_client_id}
              onChange={(event) => this.setState({internal_client_id: event.target.value == '' ? null : event.target.value})}>
              <option value=''>All Clients</option>
              {
                this.props.clients.map(client => {
                  return (
                    <option key={client.internal_client_id} value={client.internal_client_id}>
                      {client.name}
                    </option>
                  )
                })
              }
            </select>
          </Field>
        )}
        <Field label="alert.database">
          <div style={{width: 'calc(100% - 20px)', margin: 'auto'}}> 
            <input type="radio" value={this.REDSHIFT} 
              checked={this.state.database == this.REDSHIFT} onChange={event => this.setState({database: event.target.value})}/> 
              {i18next.t('alert.redshift')}
            <input style={{marginLeft: '300px'}} type="radio" value={this.POSTGRES} 
              checked={this.state.database == this.POSTGRES} onChange={event => this.setState({database: event.target.value})}/> 
              {i18next.t('alert.postgres')}
          </div>
        </Field>
        <Field label="alert.sql"
          validate={
            <div>
              <button className="form-control btn btn-success mt-25" onClick={() => {this.preview()}}>{i18next.t('alert.preview')}</button>
            </div>
          }>
          <SQLEditor
            code={this.state.sql}
            database={this.state.database.toLowerCase()}
            instructions={'The following variable is available: {internal_client_id}.'}
            substitute={(sql) => this.substituteVariables(sql)}
            onChange={(sql) => this.setState({sql: sql})}
          />
        </Field>
        {this.state.showPreview && (
          <div className="col-md-12 mt-25">
            <div style={{width: 'calc(100% - 20px)', margin: 'auto'}}>
              <Table
                ref={this.previewTableRef}
                fetch={(tableData) => this.fetch(tableData)}
                export={(tableData) => this.exportSql(tableData)}
                columns={this.state.columns} />
            </div>
          </div>
        )}
        <Field label="alert.deliveryMethod">
          <select className="form-control" value={this.state.alert_type} onChange={(event) => this.setState({alert_type: event.target.value})}>
            <option value={this.EMAIL}>{i18next.t('alert.email')}</option>
            {this.state.slackChannels.length > 0 && (
              <option value={this.SLACK}>{i18next.t('alert.slack')}</option>
            )}
          </select>
        </Field>
        {this.state.alert_type == this.SLACK && (
          <Field label="alert.slackChannel">
            <div>
              <input
                type="checkbox"
                style={{verticalAlign: 'top'}}
                checked={this.state.use_client_channel}
                onChange={event => this.setState({use_client_channel: event.target.checked})}
              />{i18next.t('alert.useClientChannel')}
            </div>
            <select className="form-control" value={this.state.slack_channel_id}
              onChange={event => this.updateSlackChannel(event.target.value)}>
              {
                this.state.slackChannels.map(slackChannel => {
                  return (
                    <option key={slackChannel.id} value={slackChannel.id}>
                      {slackChannel.name}
                    </option>
                  )
                })
              }
            </select>
          </Field>
        )}
        {this.state.alert_type == this.EMAIL && (
          <Field label="alert.emailRecipients">
            <div style={{float: 'right'}}>
              <a className="btn btn-box-tool text-green" style={{paddingTop: 0}} onClick={event => {
                event.preventDefault()
                this.setState({showCreate: true})
              }}>
                <i className="fa fa-plus"></i>
                <span>{i18next.t('alert.addEmailRecipient')}</span>
              </a>
              { this.state.showCreate && (
                <AddRow title="alert.addEmailRecipient" columns={this.ALERT_EMAIL_RECIPIENTS} onClose={row => this.addEmailRecipient(row)} />
              )}
            </div>
            <Table 
              ref={this.recipientTableRef}
              dataset={this.state.emailRecipients}
              columns={this.getEmailColumns()}
              autoHeight={true}
              rowHeight={40}/>
          </Field>
        )}
        <Field label='alert.frequency'>
          <div style={{width: 'calc(100% - 20px)', margin: 'auto'}}>
            <input type="radio" value={this.DAILY} 
              checked={this.state.daily_frequency == this.DAILY} onChange={event => this.setState({daily_frequency: event.target.value})}/> 
              {i18next.t('alert.daily')}
            <input style={{marginLeft: '320px'}} type="radio" value={this.HOURLY} 
              checked={this.state.daily_frequency == this.HOURLY} onChange={event => this.setState({daily_frequency: event.target.value})}/> 
              {i18next.t('alert.hourly')}
          </div>
        </Field>
        <Field label="alert.status">
          <select className="form-control" value={this.state.status} onChange={event => this.setState({status: event.target.value})}>
            <option value={this.OK}>{i18next.t('alert.ok')}</option>
            <option value={this.ERROR}>{i18next.t('alert.error')}</option>
            <option value={this.PAUSED}>{i18next.t('alert.paused')}</option>
          </select>
        </Field>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
  internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  clients: state.users.user ? state.users.user.clients : [],
})

export default withRouter(connect(mapStateToProps)(Alert))
