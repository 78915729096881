import CodeEditor from './CodeEditor'
import { getDatabaseSqlHint } from '../api/sql_templates'
import { autocompletion } from '@codemirror/autocomplete'
import { sql } from '@codemirror/lang-sql'
import { lint } from '../utilities/formatting'
import {
  validateSQLBeforeSave,
} from '../utilities/utils'
import FTNotice from './FTNotice'
import i18next from 'i18next'

export default class SQLEditor extends CodeEditor {
  constructor(props) {
    super(props)
    this.state.tables = []
  }
  
  getCompletions(context) {
    const word = context.matchBefore(/[_a-z0-9\.]*/i)
    if (word.from == word.to && !context.explicit) {
      return null
    }
    const splits = word.text.split('.')
    const prefix = splits.slice(0, -1).join('.')
    if (this.state.tables[prefix]) { // show attributes
      const values = this.state.tables[prefix]
      values.sort()
      return {
        from: word.from + prefix.length + 1,
        options: values.map(t => ({label: t, type: 'keyword'}))
      }
    } else { // show tables
      const keys = Object.keys(this.state.tables)
      keys.sort()
      return {
        from: word.from,
        options: keys.map(t => ({label: t, type: 'keyword'}))
      }
    }
  }
  
  getExtensions() {
    const extensions = [autocompletion({
      override: [context => this.getCompletions(context)]
    }), sql()]
    return super.getExtensions().concat(extensions)
  }
  
  getClickAction() {
    super.getClickAction()
    this.getDatabaseSqlHint()
  }
  
  shouldValidate() {
    return true
  }

  validate(event, func) {
    event.stopPropagation()
    const code = this.replaceVariables(this.state.code)
    const validate = this.props.validate ? this.props.validate : validateSQLBeforeSave
    validate(code, this.props.database, this.props.substitute).then(
      (error) => {
        if (error) {
          FTNotice(error, 15000)
        } else {
          super.validate(event, func)
        }
      }
    )
  }

  getDatabaseSqlHint() {
    getDatabaseSqlHint(this.state.database).then((response) => {
      this.setState({ tables: response.result })
    })
  }
  
  isReady() {
    return this.state.tables
  }

  getButtons() {
    return (
      <>
        <button className="btn btn-primary" onClick={() => this.formatSql()}>
          {i18next.t('sqlEditor.format')}
        </button>
        <button
          className="btn btn-primary"
          onClick={(event) => this.validate(event, () => FTNotice('sqlEditor.sqlIsValid', 15000))}
          style={{ marginLeft: 10 }}
        >
          {i18next.t('sqlEditor.validate')}
        </button>
      </>
    )
  }

  formatSql() {
    this.setState({ code: lint(this.state.code, this.props.database) })
  }
}

export const convertVariables = (variables) => {
  variables = JSON.parse(variables)
  if (Array.isArray(variables)) {
    return variables
  } else {
    const varArray = []
    for (const [key, value] of Object.entries(variables)) {
      varArray.push({name: key, type: 'Custom', value})
    }
    return varArray
  }
}
