import { Component } from 'react'
import CompareLineChart from '../../charts/CompareLineChart'
import locale from '../../../api/locale'

const dimensions = [
  'clicks',
  'cost',
  'cpc',
  'ctr',
  'impressions',
  'max_cpc',
  'engine_conversion',
  'engine_cr',
  'engine_revenue',
  'engine_cpa',
  'engine_roas',
  'engine_aov',
  'engine_rpc',
  'escore_clicks',
  'escore_cost',
  'internal_conversion',
  'internal_cr',
  'internal_revenue',
  'internal_cpa',
  'internal_roas',
  'internal_aov',
  'internal_rpc',
]

export default class Performance extends Component {
  static getDerivedStateFromProps(props) {
    return {
      data: props.data,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      options: null,
      dimension: null,
      data: props.data,
    }
  }

  componentDidMount() {
    let options = []
    dimensions.forEach((dimension) => {
      options.push({ label: locale.metrics[dimension], value: dimension })
    })
    this.setState({ options: options })
    this.getDimension('clicks')
  }

  componentDidUpdate(prevProps) {
    if (this.props.data != prevProps.data) {
      this.generateCompareData()
    }
  }

  getDimension(dimension) {
    this.setState({ dimension: dimension }, () => this.generateCompareData())
  }

  generateCompareData() {
    if (this.state.data && this.state.dimension) {
      let data_t1 = []
      let data_t2 = []
      if (this.props.comparison == 'experiment') {
        let midDate = this.getMiddleDate()
        this.state.data.forEach((row) => {
          let data
          if (row['date'] < midDate) data = data_t1
          else if (row['date'] > midDate) data = data_t2
          else return
          data.push([
            moment(row['date']).valueOf(),
            parseFloat(row[this.state.dimension]),
          ])
        })
      } else if (this.props.comparison == 'control') {
        this.state.data.forEach((row) => {
          let data
          if (row['experiment_type'] == 'control') data = data_t1
          else if (row['experiment_type'] == 'experiment') data = data_t2
          else throw 'Wrong experiment type'
          data.push([
            moment(row['date']).valueOf(),
            parseFloat(row[this.state.dimension]),
          ])
        })
      }

      this.setState({ data_t1: data_t1, data_t2: data_t2 })
    }
  }

  getMiddleDate() {
    let midDate
    this.state.data.forEach((row) => {
      if (row['experiment_type'] == '-') midDate = row['date']
    })
    return midDate
  }

  render() {
    return (
      <div>
        <div className="box">
          <div className="box-body">
            {this.state.options && (
              <div className="btn-group">
                <a className="btn-info dropdown-toggle btn-sm btn-right" data-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-line-chart" /> <span className="metric">{locale.metrics[this.state.dimension]}</span> <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  {this.state.options.map((option, i) => (
                    <li style={{ width: 150 }} key={i}
                      onClick={() => this.getDimension(option.value)}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {this.state.data_t1 && (
              <CompareLineChart
                title_t1={
                  this.props.comparison == "control" ? "Control" : "Pre"
                }
                title_t2={
                  this.props.comparison == "control" ? "Experiment" : "Post"
                }
                data_t1={this.state.data_t1}
                data_t2={this.state.data_t2}
                dimension={this.state.dimension}
                annotation_text={this.props.title}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
