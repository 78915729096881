import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// create redux store
const initialState = { }
const middleware   = [ sagaMiddleware ]

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(),
  initialState,
  composeEnhancers( applyMiddleware(...middleware) ),
)

// then run the saga
sagaMiddleware.run(rootSaga)

export default store