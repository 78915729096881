import { useEffect, useRef } from 'react'
import { Box, InputAdornment, IconButton, Typography, CircularProgress, Slide, Chip } from '@mui/material'
import { connect } from 'react-redux'
import TextField from '../widgets/TextField'
import SendIcon from '@mui/icons-material/Send'
import PromptHistoryLink from './PromptHistoryLink'
import MarkdownResponse from './MarkdownResponse'
import AiRating from './AiRating'
import i18next from 'i18next'

const PromptDialogChat = ({
  status,
  setStatus,
  promptDraft,
  setPromptDraft,
  requiredAlert,
  conversation,
  setConversation,
  disabled,
  response,
  handleSubmit,
  aiBot,
  promptThread,
  feedback,
  setFeedback,
  rating,
  setRating,
  fullResponse,
  promptId
}) => {
  const inputRef = useRef(null)
  const boxRef = useRef(null)

  const handleKeyDown = (e) => {
    if ((e.key === 'Enter' || e.key === 'Return') && e.target.value) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleClick = () => {
    if (inputRef.current.value) {
      handleSubmit()
    }
  }

  // place focus back to textfield after response 
  useEffect(() => {
    inputRef.current.focus()
  }, [response])

  // scroll to bottom of chatbox container effect to keep message thread viewable at each new message
  useEffect(() => {
    const scrollToBottom = () => {
      const lastMessage = boxRef.current.lastElementChild
      if (lastMessage) {
        setTimeout(() => {
          lastMessage.scrollIntoView({ behavior: 'smooth' });
        }, 500)
      }
      if (inputRef.current.value) {
        inputRef.current.value = null
      }
      inputRef.current.focus()
    }
    scrollToBottom()
  }, [promptThread])

  const alignMessage = (source) => {
    if (source == aiBot) {
      return 'flex-start'
    } else {
      return 'flex-end'
    }
  }

  const inputStyle = {
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white.100',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'EB Garamond',
      '& fieldset': {
        borderColor: 'white.100',
        backgroundColor: 'white',
        borderRadius: 3,
      },
      '&:disabled fieldset': {
        backgroundColor: 'white.100',
        color: 'white.100',
      },
      '&:hover fieldset': {
        borderColor: 'white.100',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white.100',
      },

    },
    '& .MuiInputLabel-root': {
      color: 'white.100',
      fontSize: '2rem',

      '&.Mui-focused': {
        color: 'white.100',
        bgcolor: 'white',
        fontSize: '1.2rem',
      },
      '&.MuiInputLabel-shrink': {
        color: 'white.100',
        bgcolor: 'white',
        fontSize: '1.2rem',
        transform: 'translate(14px, -9px) scale(0.75)',
      },
      '&.Mui-disabled.MuiInputLabel-shrink': {
        color: 'white.100',
        fontSize: '1.2rem',
      },

    },
    '& .MuiInputBase-input': {
      fontFamily: 'EB Garamond',
      fontSize: '2rem'
    },
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-between',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
        justifyContent: 'flex-end',
        maxHeight: '100%',
        height: '50vh',
        width: '100%'
      }}>
        <Box
          ref={boxRef}
          sx={{
            height: "100%",
            width: '100%',
            maxHeight: '55vh',
            padding: 1,
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            alignSelf: 'flex-start',
            flexGrow: 1,
            marginBottom: 2,
            overflow: 'auto',
          }}>
          {promptThread.map((message, i) => (
            <Slide
              key={i}
              direction={message.source == aiBot ? 'right' : 'up'}
              in={message.content != ''}>
              <Box display='flex' sx={{
                mb: .75,
                mt: 1.25,
                display: 'flex',
                alignSelf: alignMessage(message.source)
              }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: alignMessage(message.source),
                    justifyContent: 'center'
                  }}>
                  <Box sx={{
                    padding: 1,
                    mb: .5,
                  }}>
                    <MarkdownResponse status={status} aiBot={aiBot} response={message.content} source={message.source} />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row' sx={{ justifyContent: alignMessage(message.source) }}>
                    {(message.source != aiBot || (message.source == aiBot && (rating != '' && feedback != '')) || i == 0) &&
                      <Chip
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, .35)',
                          color: 'white.100'
                        }} label={<><strong>{message.source}</strong> | {message.date}</>} />
                    }
                  </Box>
                  {message.source == aiBot && i == promptThread.length - 1 && i != 0 && promptId != null ?
                    <Box
                      display='flex'
                      flexDirection='row'
                      sx={{ mt: 1, justifyContent: alignMessage(message.source) }}>
                      <AiRating
                        promptId={promptId}
                        rating={rating}
                        setRating={setRating}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        fullResponse={fullResponse} />
                    </Box> : null}
                </Box>
              </Box>
            </Slide>
          ))}
        </Box>
      </Box>
      <TextField
        disabled={disabled}
        inputRef={inputRef}
        sx={{
          '& input': {
            visibility: disabled ? 'hidden' : 'visible',
            color: 'white !important'
          },
          alignSelf: 'flex-end',
          ...inputStyle
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick} color='accent' disabled={disabled}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            disabled ? <InputAdornment position="start">
              <CircularProgress sx={{ color: 'white.100' }} />
            </InputAdornment> : null
          )
        }}
        onKeyDown={e => handleKeyDown(e)}
        onChange={e => setPromptDraft(e.target.value)}
        value={promptDraft}
        label={i18next.t('ai.enterPrompt')}
        fullWidth />
      {
        requiredAlert ?
          <Typography color="#FFFFFF">
            {i18next.t('ai.enterPromptToContinue')}
          </Typography>
          : null
      }
      <PromptHistoryLink
        parentIsPromptDialog={true}
        conversation={conversation}
        setConversation={setConversation}
        status={status}
        setStatus={setStatus}
        disabled={disabled} />
    </Box >
  )
}

const mapStateToProps = function (state) {
  return {
    admin: state.users.user ? state.users.user.role == "Admin" : null,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(PromptDialogChat)

