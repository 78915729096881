import { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import locale from '../../api/locale'
import i18next from 'i18next'

const ads = 'ads'
const metrics = 'metrics'

class SelectMetrics extends Component {
  /**
   * Selector to select metrics.
   *
   * Properties:
   *   type - (optional) ads (default), metrics or analytics
   *   engine - (optional) the default engine to show (defaults to all)
   *   metric - (optional) the defualt metric to show (defaults to clicks)
   *   callback - (optional) callBack method (will receive dictionary with engine and metric)
   *   maxHeight - (optional) the max height of the pull downs.
   **/
  static defaultProps = {
    type: ads,
    engine: 'all',
    metric: 'clicks',
  }

  constructor(props) {
    super(props)
    this.state = {
      engine: this.props.engine,
      metric: this.props.metric
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.metric != prevProps.metric) {
      this.setState({ metric: this.props.metric })
    }
  }

  engines() {
    const engines = []
    Object.keys(locale.transactions).forEach((key) => {
      engines.push(
        <MenuItem key={key}
          value={key}
          onClick={() => {
            this.setState(
              { engine: key },
              () => this.props.callback && this.props.callback(this.state)
            )
          }}
        >
          {locale.transactions[key]}
        </MenuItem>
      )
    })

    return engines
  }

  metricOptions() {
    const metricsAvailable = this.props.type == ads ? [
      'clicks',
      'cost',
      'cpc',
      'ctr',
      'impressions',
      'max_cpc',
      'engine_conversion',
      'engine_cr',
      'engine_revenue',
      'engine_cpa',
      'engine_roas',
      'engine_aov',
      'engine_rpc',
      'internal_conversion',
      'internal_cr',
      'internal_revenue',
      'internal_cpa',
      'internal_roas',
      'internal_aov',
      'internal_rpc',
    ] : this.props.type == metrics ? [
      'average_bid',
      'clicks',
      'cost',
      'cpc',
      'ctr',
      'impressions',
      'impression_share',
      'conversions',
      'cr',
      'revenue',
      'cpa',
      'roas',
      'aov',
      'rpc',
    ] : [
      'sessions',
      'bounce_sessions',
      'add_to_carts',
      'conversions',
      'revenue',
    ]

    let options = []
    if (this.props.type == ads) {
      var localeMetrics = Object.keys(locale.metrics).map(function (key) {
        return [key, locale.metrics[key]]
      })
  
      localeMetrics.sort(function (a, b) {
        return metricsAvailable.indexOf(a[0]) - metricsAvailable.indexOf(b[0])
      })
      
      options = Object.keys(localeMetrics)
        .map(metric => localeMetrics[metric][0])
        .filter(key => metricsAvailable.includes(key))
        .map(key => ({key: key, label: locale.metrics[key]}))
    } else {
      options = metricsAvailable.map(metric => ({key: metric, label: i18next.t(`metrics.${this.props.type}.${metric}`)}))
    }

    return options.map(metric => (
      <MenuItem
        value={metric.key}
        key={metric.key}
        onClick={() => {
          this.setState(
            { metric: metric.key },
            () => this.props.callback && this.props.callback(this.state)
          )
        }}
      >
        {metric.label}
      </MenuItem>
    ))
  }

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {this.props.type == ads && (
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ ...sxFormControl, minWidth: 20 }}>
              <InputLabel sx={this.props.aiMetricSelectChart ? sxInputLabelAi : sxInputLabel}>Engine</InputLabel>
              <Select sx={this.props.aiMetricSelectChart ? sxSelectAi : sxSelectWebApp} variant='outlined' value={this.state.engine}>{this.engines()}</Select>
            </FormControl>
          </Box>
        )}
        <Box sx={{ display: 'flex', marginLeft: this.props.type == ads ? 1 : 0 }}>
          <FormControl sx={{ ...sxFormControl, minWidth: this.props.type == ads ? 120 : null }}>
            <InputLabel sx={this.props.aiMetricSelectChart ? sxInputLabelAi : sxInputLabel}>Metric</InputLabel>
            <Select sx={this.props.aiMetricSelectChart ? sxSelectAi : sxSelectWebApp} variant='outlined' value={this.state.metric}>{this.metricOptions()}</Select>
          </FormControl>
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}
export default connect(mapStateToProps)(SelectMetrics)

const sxFormControl = {
  '& .MuiInputLabel-root': {
    color: 'white.100',
  },
  '& .MuiSelect-root': {
    color: 'white.100',
  },
  '& .MuiOutlinedInput-input': {
    padding: '5px 12px'
  },
}

const sxSelectWebApp = {
  padding: '1px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
    borderWidth: '2px',
    // backgroundColor: 'primary.main',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'grey.900',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'grey.900',
  },
  '& > svg': {
    visibility: 'hidden',
  },
}

const sxSelectAi = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white.100',
    borderWidth: '1px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'grey.300',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white.100',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white.100'
  },
  '& > svg': {
    visibility: 'hidden',
  },
}

const sxInputLabelAi = {
  color: 'white.100',
  '&.MuiInputLabel-shrink': {
    color: 'white.100',
    visibility: 'hidden',
  },
  '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(5px, -20px) scale(0.95)',
  },
}

const sxInputLabel = {
  '&.MuiInputLabel-shrink': {
    color: 'white.100',
    visibility: 'hidden',
  },
  '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(5px, -20px) scale(0.95)',
  },
}