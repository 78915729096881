import { forwardRef } from 'react'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import i18next from 'i18next'

/*
Show a full screen dialog.

Properties:
  open - whether to show the dialog
  onClose - what to do when closing the dialog
  title - title of the dialog (typically i18n)
*/

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const FTOFullScreenDialog = ({ open, onClose, title, children, bidding_run_id }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{'& > .MuiDialog-container > .MuiPaper-root': {overflowX: 'hidden'}}}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {i18next.t(title) + (bidding_run_id ? `: ${bidding_run_id}` : null)}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  )
}

export default FTOFullScreenDialog
