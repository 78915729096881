import request from './request';

// all users
export function getColumns(internal_client_id, table_name) {
  return request('/api/columns/' + table_name + '?internal_client_id=' + internal_client_id);
}

// admin only
export function updateColumns(internal_client_id, table_name, columns) {
  return request('/api/columns/' + table_name + '?internal_client_id=' + internal_client_id, {}, 'POST', columns);
}
