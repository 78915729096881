import * as React from 'react';
import i18next from 'i18next'
import { Box } from '@mui/material'
import Section from '../../widgets/Section'
import Select from '../../widgets/Select'
import Option from '../../widgets/Option'

const PercentSelect = (props) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" marginRight={3}>
      <Box display="flex" sx={{ width: 150 }}>
        <Select disabled={props.status == 'published'} labelText="biddingRun.min" value={props.bidLevels.minimum} onChange={event => props.onUpdate({ bid_levels: Object.assign(props.bidLevels, { minimum: event.target.value }) })} >
          {[...Array(25).keys()].map(i => ( // -1 - -25
            <Option key={i} value={-i - 1} labelText={-i - 1 + '%'} />
          ))}
        </Select>
      </Box>
      <Box display="flex" sx={{ width: 150 }}>
        <Select disabled={props.status == 'published'} labelText="biddingRun.max" value={props.bidLevels.maximum} onChange={event => props.onUpdate({ bid_levels: Object.assign(props.bidLevels, { maximum: event.target.value }) })} >
          {[...Array(25).keys()].map(i => ( // 1 - 25
            <Option key={i} value={i + 1} labelText={i + 1 + '%'} />
          ))}
        </Select>
      </Box>
    </Box>
  )
}

const BidRangePercent = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.limitBidRangeDesc')}>
      {<PercentSelect {...props} />}
    </Section>
  )
}

export default BidRangePercent