import { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import DatePicker from '../widgets/DatePicker'
import PersistentDrawerLeft from '../Drawer'
import DashboardCanvas from './DashboardCanvas'

export default class CustomDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: props.dashboard,
      start_date: this.props.params.start_date ?
        this.props.params.start_date : 
        this.props.startDate ? 
          this.props.startDate :
          this.props.showDates == 'range' ?
            moment().subtract(28, 'day').format('YYYY-MM-DD') :
            moment().subtract(1, 'day').format('YYYY-MM-DD'),
      end_date: this.props.params.end_date ? this.props.params.end_date : this.props.endDate ? this.props.endDate : moment().subtract(1, 'day').format('YYYY-MM-DD'),
      compare_from: null,
      compare_to: null,
      params: {},
    }
    if (this.props.params) {
      const params = Object.assign({}, this.props.params)
      delete params.start_date
      delete params.end_date
      for (const [key, value] of Object.entries(params)) {
        if (value.indexOf(';') > -1) { // be wary of SQL injection
          delete params[key]
        }
      }
      this.state.params = params
    }
    this.updateDates = this.updateDates.bind(this)
    this.drawer = React.createRef();
  }

  updateDates(dates) {
    this.setState({start_date: dates.start, end_date: dates.end, compare_from: dates.compareStart, compare_to: dates.compareEnd});
  }

  render() {
    const { showWrench } = this.props;
    if (this.state.dashboard) {
      return (
        <div {...this.state}>
          <PersistentDrawerLeft ref={this.drawer} data_configuration={this.props.data_configuration}>
            {this.props.startDate == null && this.props.showDates && (
              <div className="page-nav" style={{position: 'relative'}}>
                <div className="btn-group" style={{width: '100%'}}>
                  { showWrench &&
                    <a className="btn btn-default" onClick={() => this.drawer.current.handleDrawerOpen()} >
                      <i className="fa fa-wrench" />
                    </a>
                  }
                  <DatePicker
                    start={this.state.start_date}
                    end={this.state.end_date}
                    compareStart={this.state.compare_from}
                    compareEnd={this.state.compare_to}
                    showCompare={true}
                    callback={dates => this.updateDates(dates)}
                    range={this.props.showDates == 'range'}
                  />
                </div>
              </div>
            )}
            <DashboardCanvas
              params={this.state.params}
              dashboard_id={this.props.dashboard_id}
              cards={this.props.dashboard.cards}
              editable={false}
              start_date={this.state.start_date}
              end_date={this.state.end_date}
              compare_start={this.state.compare_from}
              compare_end={this.state.compare_to}
              filters={this.props.filters}
              extraFilters={this.props.extraFilters}
            />
          </PersistentDrawerLeft>
        </div>
      );
    } else {
      return (<CircularProgress className="progress"/>);
    }
  }
}
