import React, { useState } from 'react'
import Section from '../widgets/Section'
import Stack from '../widgets/Stack'
import PullDown from '../widgets/PullDown'

const LabelInputs = (props) => {
  const [filtered, setFiltered] = useState()

  React.useEffect(() => {
    setFiltered(props.allLabels.filter(l => props.labels.includes(l.name)))
  }, [props.allLabels, props.labels])

  return (
    <Stack sx={{ width: '100%', minWidth: '100%' }}>
      {filtered && (
        <PullDown
          labelText="abtest.edit.labelsToInclude"
          value={filtered}
          options={props.allLabels}
          optionFunction={option => option.name}
          onChange={(_, value) => props.onUpdate(value.map(label => label.name))}
        />
      )}
    </Stack>
  )
}

const LabelSelect = (props) => {
  return (
    <Section name={props.labelSelectName}>
      <LabelInputs {...props} />
    </Section>
  )
}

export default LabelSelect