import request from './request';
import querystring from 'querystring';

// offline_conversions
export function getOfflineConversions(internal_client_id, tableData) {
  return request('/api/offline_conversions?internal_client_id=' + internal_client_id + '&' + querystring.stringify(tableData));
}

// offline_conversions
export function getOfflineConversion(id) {
  return request('/api/offline_conversions/' + id);
}

// offline_conversions
export function createOfflineConversion(data) {
  return request('/api/offline_conversions', {}, 'POST', data);
}

// offline_conversions
export function updateOfflineConversion(id, data) {
  return request('/api/offline_conversions/' + id, {}, 'PUT', data);
}

// offline_conversions
export function deleteOfflineConversion(id) {
  return request('/api/offline_conversions/' + id, {}, 'DELETE');
}

// offline_conversions
export function previewOfflineConversionQuery(internal_client_id, sql, state, engine, table_data) {
  return request('/api/offline_conversions/preview?' + querystring.stringify(table_data), {}, 'POST', {sql: sql, internal_client_id: internal_client_id, state: state, engine: engine});
}

// offline_conversions
export function exportOfflineConversionQuery(internal_client_id, sql, state, engine, table_data) {
  return request('/api/offline_conversions/export?' + querystring.stringify(table_data), {}, 'POST', {sql: sql, internal_client_id: internal_client_id, state: state, engine: engine});
}
