import i18next from 'i18next'
import Section from '../../widgets/Section'
import RadioButtons from '../../widgets/RadioButtons'
import RadioButton from '../../widgets/RadioButton'

const RadioButtonsGroup = (props) => {
  return (
    <RadioButtons value={props.lookbackDuration} onChange={e => props.onChange(e)} groupText="biddingRun.biddingLookbackDuration">
      <RadioButton disabled={props.disabled} value="30" labelText="biddingRun.30DayLookback" />
      <RadioButton disabled={props.disabled} value="60" labelText="biddingRun.60DayLookback" />
      <RadioButton disabled={props.disabled} value="90" labelText="biddingRun.90DayLookback" />
    </RadioButtons>
  )
}

const BiddingLookbackDuration = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.biddingLookbackDurationDesc')}>
      {<RadioButtonsGroup disabled={props.status == 'published'} lookbackDuration={props.lookbackDuration} onChange={props.onChange} />}
    </Section>
  )
}

export default BiddingLookbackDuration