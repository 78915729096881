/*
This uses an MUI MenuItem (https://mui.com/material-ui/api/menu-item/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - the label to use for the button (this should generally be an i18n key)
*/
const FtoOption = () => {
  // You can't wrap MenuItem, so this just captures the props and Select uses them to create MenuItems
  return null
}

export default FtoOption