import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import DeleteButton from '../DeleteButton'
import FTNotice from '../FTNotice'
import Table from '../widgets/Table'
import Field from '../Field'
import Form from '../Form'
import AddRow from '../AddRow'
import AddExclusion from './AddExclusion'
import { getClient, patchClient } from '../../api/clients'
import { getAlgos } from '../../api/bidding'
import { getExcludedAccounts, createExcludedAccount, deleteExcludedAccount, getExcludedAccountOptions } from '../../api/bidding'
import { getExcludedCampaigns, createExcludedCampaign, deleteExcludedCampaign, getExcludedCampaignOptions } from '../../api/bidding'
import { getExcludedLastDays, createExcludedLastDays, deleteExcludedLastDays} from '../../api/bidding'
import { formatCurrency } from '../../utilities/formatting'
import i18next from 'i18next'

class Configuration extends Component {
  BIDDING_DISABLED = 'disabled'
  BIDDING_PREVIEW = 'preview'
  BIDDING_LIVE = 'live'
  
  ACCOUNT = i18next.t('client.account')
  CAMPAIGN = i18next.t('client.campaign')
  LAST_DAY = i18next.t('client.lastDay')
  
  accountTableRef = createRef()
  campaignTableRef = createRef()
  lastDaysTableRef = createRef()

  //Used for AddRow Component
  EXCLUDE_LAST_DAYS_TABLE_COLUMNS = [{
    title: i18next.t('client.excludeDaysStart') + i18next.t('client.example', {example: 1}),
    column: 'start_exclude_last_n_days',
    required: true,
    identity: false,
  }, {
    title: i18next.t('client.excludeDaysEnd') + i18next.t('client.example', {example: 7}),
    column: 'end_exclude_last_n_days',
    required: true,
    identity: false,
  }]

  constructor(props) {
    super(props)
    this.state = {
      client: null,
      algos: [],
    }
  }

  componentDidMount() {
    this.getAlgos()
    this.getClient()
  }

  getClient() {
    getClient(this.props.internal_client_id).then(client => {
      this.setState({client: client})
    })
  }
  
  getAlgos() {
    getAlgos(this.props.internal_client_id).then(algos => {
      if (algos.results != null) {
        this.setState({algos: algos.results})
      }
    })
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.client)

    if(event.target.name == 'algos') {
      const algos = formData.algos.slice()
      const algoId = parseInt(event.target.value)
      if (event.target.checked && algos.indexOf(algoId) < 0) {
        algos.push(algoId)
      } else if (!event.target.checked && algos.indexOf(algoId) >= 0) {
        algos.splice(algos.indexOf(algoId), 1)
      }
      formData[event.target.name] = algos
    } else if(event.target.type === 'checkbox') {
      formData[event.target.name] = event.target.checked
    } else {
      formData[event.target.name] = event.target.value
    }
    this.setState({client: formData})
  }

  submitChanges(event) {
    event.preventDefault()
    const client = {
      internal_client_id: this.props.internal_client_id,
      algos: this.state.client.algos,
      automated_bidding: this.state.client.automated_bidding,
    }
    patchClient(client).then((response) => {
      if (response == null || response.error) {
        FTNotice(i18next.t('client.couldNotBeUpdated', {error: response ? response.error : i18next.t('client.unknownError')}), 15000)
      } else {
        FTNotice('biddingConfiguration.updated', 3000)
      }
    })
  }

  getExclusionColumns(name) {
    return [{
      id: 'engine',
      datatype: 'text',
      width: 100,
    },{
      id: name.toLowerCase() + '_id',
      header: name + ' Id',
      datatype: 'numeric',
      width: 120,
    },{
      id: name.toLowerCase() + '_name',
      header: name,
      datatype: 'text',
      width: 400,
    },{
      id: 'clicks',
      datatype: 'numeric',
      width: 80,
    },{
      id: 'cost',
      datatype: 'numeric',
      width: 110,
    },{
      id: 'cpc',
      datatype: 'numeric',
      format: value => {return formatCurrency(value)},
      width: 80,
    },{
      id: 'internal_roas',
      datatype: 'numeric',
      width: 130,
    },{
      id: 'rpc',
      datatype: 'numeric',
      format: value => {return formatCurrency(value)},
      width: 80,
    },{
      id: 'impressions',
      datatype: 'numeric',
      width: 120,
    },{
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 140,
    }]
  }
  //add columns from table bidding.excluded_last_days
  getExclusionLastDaysColumns() {
    return [{
      id: 'start_exclude_last_n_days', 
      header: 'client.excludeDaysStart',
      datatype: 'numeric',
      width: 275,
    },{
      id: 'end_exclude_last_n_days',
      header: 'client.excludeDaysEnd',
      datatype: 'numeric',
      width: 275,
    }]
  }

  addExclusionDeleteButton(columns, name, deleteExclusion) {
    columns.push({
      cellRenderer: (_, row) => ( 
        <DeleteButton
          objectType=""
          objectName={i18next.t('client.thisExclusion')}
          deleteObject={() => this.deleteExclusion(name, deleteExclusion, row)}/>
      ),
      width:100,
    })
    return columns
  }

  deleteExclusion(name, deleteExclusion, row) {
    deleteExclusion(row.id).then((result) => {
      if (result.successful) {
        (name == this.ACCOUNT ? this.accountTableRef : name == this.CAMPAIGN ? this.campaignTableRef : this.lastDaysTableRef).current.refreshRows()
      } else {
        FTNotice(result.error ? result.error : i18next.t('client.errorAddingExclusion'), 15000)
      }
    })
  }

  addExclusion(addState) {
    const state = {}
    state[addState] = true
    this.setState(state)
  }

  finishAddExclusion(name, data, createExclusion) {
    if (data && name != this.LAST_DAY) {
      const values = {
        internal_client_id: this.props.internal_client_id,
        engine: data.engine,
      }
      values[name.toLowerCase() + '_id'] = data.id
      createExclusion(values).then(result => {
        if (result.successful) {
          (name == this.ACCOUNT ? this.accountTableRef : name == this.CAMPAIGN ? this.campaignTableRef : this.lastDaysTableRef).current.refreshRows()
        } else {
          FTNotice(result.error ? result.error : i18next.t('client.errorAddingExclusion'), 15000)
        }
      })
    }
    const state = {}
    state['addExclude' + name] = false
    this.setState(state)
  }

  renderExclusion(name, getExclusions, createExclusion, deleteExclusion, getOptions) {
    const addState = 'addExclude' + name
    return (
      <Field label={i18next.t('client.excludedNames', {name: name})}>
        <div>
          <button className="btn btn-primary" style={{marginBottom: 10}} onClick={event => {
            event.preventDefault()
            this.addExclusion(addState)
          }}>{i18next.t('client.addExcluded', {name: name})}</button>
        </div>
        { this.state[addState] && name == this.LAST_DAY && (
          <AddRow title={i18next.t("biddingConfiguration.updateHeader", {name: name})} table="bidding.excluded_last_days" columns={this.EXCLUDE_LAST_DAYS_TABLE_COLUMNS}  primaryKeys={['id']}
          onClose={(data) => { this.finishAddExclusion(name, data, createExclusion); (name == this.ACCOUNT ? this.accountTableRef : name == this.CAMPAIGN ? this.campaignTableRef : this.lastDaysTableRef).current.refreshRows() }} defaultValues={{internal_client_id: this.props.internal_client_id}}/>
        )}
        { this.state[addState] && name != this.LAST_DAY && (
          <AddExclusion name={name} getOptions={() => getOptions(this.props.internal_client_id)} onClose={(data) => this.finishAddExclusion(name, data, createExclusion)}/>
        )}
        <Table
          ref={name == this.ACCOUNT ? this.accountTableRef : name == this.CAMPAIGN ? this.campaignTableRef : this.lastDaysTableRef}
          tableName={name == this.LAST_DAY ? 'excluded_last_days' : name == this.CAMPAIGN ? 'excluded_campaigns' : 'excluded_accounts'}
          fetch={tableData => getExclusions(this.props.internal_client_id, tableData)}
          columns={name == this.LAST_DAY ? this.getExclusionLastDaysColumns() : this.getExclusionColumns(name)}
          columnOverride={columns => this.addExclusionDeleteButton(columns, name, deleteExclusion)}
          rowHeight={40} />
      </Field>
    )
  }

  render() {
    if (this.state.client == null) {
      return null
    }
    
    return (
      <Form
        objectType={i18next.t('bidding.configuration')}
        newObject={false}
        updateObject={(event) => this.submitChanges(event)}
        canDelete={false}
        childClass=""
      >
        <Field label="client.automatedBidding">
          <select
            className="form-control" name='automated_bidding'
            value={this.state.client.automated_bidding}
            onChange={event => this.handleChange(event)}>
              <option value={this.BIDDING_DISABLED}>{i18next.t('client.disabled')}</option>
              <option value={this.BIDDING_PREVIEW}>{i18next.t('client.preview')}</option>
              <option value={this.BIDDING_LIVE}>{i18next.t('client.live')}</option>
          </select>
        </Field>
        {this.state.algos && (
          <div>
            <Field label="client.algos">
              {this.state.algos.map((algo, i) => (
                <div key={i}>
                  <input
                    type="checkbox" value={algo.id}
                    checked={this.state.client.algos.indexOf(algo.id) >= 0} name="algos"
                    onChange={event => this.handleChange(event)} /> {algo.name}
                </div>
              ))}
            </Field>
          </div>
        )}
        {this.renderExclusion(this.ACCOUNT, getExcludedAccounts, createExcludedAccount, deleteExcludedAccount, getExcludedAccountOptions)}
        {this.renderExclusion(this.CAMPAIGN, getExcludedCampaigns, createExcludedCampaign, deleteExcludedCampaign, getExcludedCampaignOptions)}
        {this.renderExclusion(this.LAST_DAY, getExcludedLastDays, createExcludedLastDays, deleteExcludedLastDays, null)}
      </Form>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(Configuration)
