import moment from "moment";
import _ from "lodash";
import { Component } from "react";
import { getChartAnnotations } from "../../api/dashboards";
import { convertName } from "../../utilities/formatting";
import LineChart from "../charts/LineChart";

export default class DailyChart extends Component {
  static defaultProps = {
    start: moment().subtract(28, "day").format("YYYY-MM-DD"),
    end: moment().subtract(1, "day").format("YYYY-MM-DD"),
    compareStart: null,
    compareEnd: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      annotations: null,
    };
  }

  componentDidMount() {
    this.getChartAnnotations();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(Object.assign({}, prevProps, {showAnnotations: true}), Object.assign({}, this.props, {showAnnotations: true}))) {
      this.getChartAnnotations();
    }
  }

  getChartAnnotations() {
    getChartAnnotations("dimension", this.props.internal_client_id).then(
      response => {
        this.setState({ annotations: response.results })
      }
    )
  }

  generateChartData(metric) {
    const x_axis_labels = [];
    let date = moment(this.props.start)
    const end = moment(this.props.end)
    while (!date.isAfter(end)) {
      x_axis_labels.push(moment(date).format('M/D/YY'))
      date = date.add(1, 'days')
    }
    const dates = {};
    const dimensions = new Set();
    this.props.data.forEach((row) => {
      const dimension = convertName(row.dimension);
      const date = moment.utc(new Date(row["date"])).format('M/D/YY')
      if (!(date in dates)) {
        dates[date] = {}
      }
      if (!dimensions.has(dimension)) {
        dimensions.add(dimension);
      }
      dates[date][dimension] = row[metric];
    });
    const result = {};
    dimensions.forEach((dimension) => {
      result[dimension] = {
        x_axis_labels: x_axis_labels,
        dataset: x_axis_labels.map(date => dates[date] ? dates[date][dimension] : null),
      }
    });
    return result;
  }

  renderChart(chart, i) {
    if (chart.type == "line_chart" && !chart.hide) {
      let metric = chart.name;
      let chart_data = this.generateChartData(metric);
      let x_axis_labels = [];
      let datasets = [];
      let dataset_labels = [];
      for (let k in chart_data) {
        x_axis_labels = chart_data[k].x_axis_labels;
        datasets.push(chart_data[k].dataset);
        dataset_labels.push(k);
      }
      return (
        <div className="col-sm-3" key={i}>
          <div className="box">
            <div className="box-header with-border">
              <h3 className="box-title" title={chart.localizedName} style={{overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{chart.localizedName}</h3>
            </div>
            <div className="box-body" style={{height: 220}}>
              {this.state.annotations && (
                <LineChart
                  key={JSON.stringify(this.props)}
                  x_axis_labels_1={x_axis_labels}
                  x_axis_labels_2={null}
                  dataset_labels={dataset_labels}
                  datasets={datasets}
                  annotations={this.props.showAnnotations ? this.state.annotations : null}
                  height={250}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="row">
        {this.props.charts
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((chart, i) => {
            return this.renderChart(chart, i);
          })}
      </div>
    );
  }
}
