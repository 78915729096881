import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getDimensions } from '../../api/dimensions'
import SummaryTable from './SummaryTable'
import i18next from 'i18next'

import './Interface.scss'

const Interface = ({internal_client_id}) => {
  const [dimensions, setDimensions] = useState([])
  
  useEffect(() => {
    getDimensionData()
  }, [])

  const getDimensionData = () => {
    getDimensions(internal_client_id).then(dimensions => {
      const allDimensions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
        const dimension = dimensions.find(d => d.index == i)
        return dimension == null ? {index: i} : dimension
      })
      setDimensions(allDimensions)
    })
  }

  const editDimension = (dimension) => {
    location.href = '/#/dimension/' + dimension.index
  }

  return (
    <div className="site-canvas">
      <section className="content">
        {dimensions.map((dimension, i) => (
          <div key={i} className="dimension js_complete">
            <div className="index">{i18next.t('manageDimensions.dimension', {index: dimension.index})}</div>
            <div className="title">{dimension.title}</div>
            <div className="preview">
              {dimension.sql && (
                <SummaryTable dimension={dimension} internal_client_id={internal_client_id}/>
              )}
            </div>
            <button className="btn btn-primary" onClick={() => editDimension(dimension)}>{i18next.t('manageDimensions.edit')}</button>
          </div>
        ))}
      </section>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(Interface)
