import { useState } from 'react'
import { Typography, Popover } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import i18next from 'i18next'

/*
This uses an MUI Popover (https://mui.com/material-ui/popover/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - Label to show (generally i18n)
*/

const FTOPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const defaultProps = {
    open,
    anchorEl,
    onClose,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    }
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { labelText, ...popoverProps } = revisedProps

  return (
    <div>
      <InfoIcon size="large" color="secondary" onClick={onClick} style={{ cursor: 'pointer' }} />
      <Popover {...popoverProps} >
        <Typography sx={{ p: 2 }}>{i18next.t(props.labelText)}</Typography>
      </Popover>
    </div>
  )
}

export default FTOPopover