export function showWrench() {
  $('body').addClass('site-wrapper-offcanvas-fixed-show-navx')
  $('.site-wrapper-offcanvas').addClass('show-nav')
}

export function hideWrench() {
  $('body').removeClass('site-wrapper-offcanvas-fixed-show-navx')
  $('.site-wrapper-offcanvas').removeClass('show-nav')
}

$(function(){
  $('body').on('click', '.site-wrapper-offcanvas .toggle-nav', function(){
    if ($('.site-wrapper-offcanvas').hasClass('show-nav')) {
      hideWrench()
    } else {
      showWrench()
    }
  })
})

// To avoid black squares when filters are larger than content
$(document).ready(function() {
  var siteCanvas = $('#chart-hudd > .site-canvas')
  var siteMenu = $('#chart-hudd > .site-canvas > .site-menu')
  var siteCanvasMinHeight = siteCanvas.css('min-height')
  var siteMenuDisplay = siteMenu.css('display')
  siteMenu.css('display', 'none')
  $('body').on('click', '#chart-hudd > .site-canvas .toggle-nav', function(){
    if (siteCanvasMinHeight == siteCanvas.css('min-height')) {
      siteMenu.css('display', siteMenuDisplay)
      siteCanvas.css('min-height', $('#chart-hudd > .site-canvas > .site-menu > form').height() + 150)
    } else {
      siteCanvas.css('min-height', siteCanvasMinHeight)
      siteMenu.css('display', 'none')
    }
  })
})

$('#logout').click(function() {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
  fetch('/users/sign_out', {method: 'DELETE'})
})
