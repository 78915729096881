import { format } from 'sql-formatter'
import i18next from 'i18next'

const Humanize = require("humanize-plus")

const CURRENCY_COLUMNS = [
  'engine_aov', 'internal_aov', 'aov', 'engine_cpa', 'cpa',
  'internal_cpa', 'cpc', 'max_cpc', 'internal_rpc', 'engine_rpc', 'eng_rpc', 'int_rpc', 'average_bid'
]
const CURRENCY_WHOLE_COLUMNS = [
  'internal_revenue', 'revenue', 'int_revenue', 'cost', 'engine_revenue', 'budget',
  'spend', 'actual_spend', 'recommended_spend', 'eng_revenue'
]
const INTEGER_COLUMNS = [
  'clicks', 'tot_clicks', 'total_click', 'impressions', 'imp', 'average_position', 'sessions', 'bounce_sessions', 'add_to_carts', 'impression_share'
]
const PERCENT_COLUMNS = [
  'cr', 'engine_cr', 'internal_cr', 'conversion_rate', 'internal_conversion_rate',
  'engine_conversion_rate', 'ctr', 'int_cvr', 'eng_cvr', 'cvr', 'conv_rate', 'cost_impact']
const FLOAT_COLUMNS = [
  'internal_roas', 'roas', 'int_roas', 'engine_roas', 'eng_roas', 'roi', 'escore_cost', 'escore_clicks'
]
const FLOAT_ONE_DECIMAL_COLUMNS = [
  'engine_conversion', 'internal_conversion', 'engine_conversions', 'internal_conversions',
  'conversion', 'conversions', 'conv', 'tot_conversions', 'int_conv', 'int_conversions', 'eng_conv', 'eng_conversions'
]

export function dataFormatter(metric, nullIfNone=false) {
  metric = metric != null ? i18next.t(metric).toLowerCase().replace(/\./g, '').replace(/\s/g, '_') : null
  if (CURRENCY_COLUMNS.includes(metric)) {
  	return formatCurrency
  } else if (CURRENCY_WHOLE_COLUMNS.includes(metric)) {
	  return formatCurrencyNoDecimal
  } else if (INTEGER_COLUMNS.includes(metric)) {
	  return formatInteger
  } else if (PERCENT_COLUMNS.includes(metric)) {
	  return formatPercent
  } else if (FLOAT_COLUMNS.includes(metric)) {
    return formatFloat
  } else if (FLOAT_ONE_DECIMAL_COLUMNS.includes(metric)) {
    return formatFloatOneDecimal
  } else if (metric != null && metric.indexOf('percent') >= 0) {
    return formatPercent
  } else {
	  return nullIfNone ? null : formatAsIs
  }
}

export function formatCurrency(value, decimalPlaces=2) {
  if (value != null && isNumber(typeof value == 'string' ? value.replace('$', '').replace(',', '') : value)) {
    if (typeof value == 'string'){
      value = value.replace('$', '').replace(',', '')
    } else {
      value = value + ''
    }
    const isNegative = value.startsWith('-')
    if (isNegative) {
      value = value.substring(1)
    }
    value = (isNegative ? '-' : '') + '$' + Humanize.formatNumber(value, decimalPlaces)
  }
  return value
}

export function formatCurrencyNoDecimal(value) {
  return formatCurrency(value, 0)
}

export function formatPercent(value, decimalPlaces=2) {
  if (value != null && isNumber(typeof value == 'string' ? value.replace('%', '').replace(',', '') : value)) {
    if (typeof value == 'string'){
      value = value.replace('%', '').replace(',', '')
    }
    value = Humanize.formatNumber(value, decimalPlaces) + '%'
  }
  return value
}

export function formatPercentNoDecimal(value) {
  return formatPercent(value, 0)
}

export function formatFloat(value, decimalPlaces=2) {
  if (value != null && isNumber(value)) {
    value = Humanize.formatNumber(value, decimalPlaces)
  }
  return value
}

export function formatFloatOneDecimal(value) {
  return formatFloat(value, 1)
}

export function formatInteger(value) {
  if (value != null && isNumber(value)) {
    value = Humanize.formatNumber(value, 0)
  }
  return value
}

export function formatConcise(value) {
  const isNegative = String(value).startsWith('-')
  value = isNegative ? String(value).substring(1) : String(value)
  const hasCurrency = value.startsWith('$')
  const hasPercent = value.endsWith('%')
  const stripped = value.replace(/[-$%,]/g, '')
  const val = Math.abs(stripped) > 999999
    ? Humanize.formatNumber(stripped / 1000000, 1) + "M"
    : Math.abs(stripped) > 999
      ? Humanize.formatNumber(stripped / 1000, 1) + "K"
      : Humanize.formatNumber(stripped, stripped.indexOf('.') >= 0 ? 2 : 0)
  return (isNegative ? '-' : '') + (hasCurrency ? '$' : '') + val + (hasPercent ? '%' : '')  
}

function formatAsIs(value) {
  return value
}

function isNumber(value) {
  return !isNaN(value)
}

export function compactInteger(value) {
  return Humanize.compactInteger(value)
}

export function prettyPrint(value) {
  const words = []
  value.split('_').forEach(split => {
    words.push(split.charAt(0).toUpperCase() + split.slice(1))
  })
  return words.join(' ')
}

export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function convertName(value) {
  return (value == null ? '' : value).split('_').map(v => v.split('-').map(p => convertWord(p)).join('-')).join(' ')
}

export function convertWord(value) {
  if (value == '') {
    return 'Unknown'
  } else if (value == 'sem') {
    return 'SEM'
  } else if (value == 'pla') {
    return 'PLA'
  } else if (value == 'dsa') {
    return 'DSA'
  } else if (value == 'uac') {
    return 'UAC'
  } else if (value == 'non-brand') {
    return 'Non Brand'
  } else if (value == 'connected_tv') {
    return 'TV'
  }
  return capitalize(value)
}

export function lint(sql, database) {
  // Replace {...} before formatting and put it back afterwards
  // Note: Formatter cannot handle nested case statements
  return format(sql.replace(/\{/g, "wrb_").replace(/\}/g, "brw_"), {
    language: database == 'redshift' ? 'redshift' : 'postgresql',
    tabWidth: 4,
    keywordCase: 'upper',
    linesBetweenQueries: 1,
  }).replace(/wrb_/g, '{').replace(/brw_/g, '}')
}

function getOrdinal(number) {
  if ([1, 21, 31].includes(number)) {
    return number + 'st'
  } else if ([2, 22].includes(number)) {
    return number + 'nd'
  } else if ([3, 23].includes(number)) {
    return number + 'rd'
  } else {
    return number + 'th'
  }
}

export function formatSchedule(schedule) {
  const splits = schedule.split(' ')
  const minute = splits[0]
  const hour = splits[1]
  const daysOfMonth = splits[2] != '*' ? splits[2].split(',').map(n => getOrdinal(n)).toSorted().join(', ') : '*'
  const month = splits[3]
  const daysOfWeek = splits[4] != '*' ? splits[4].split(',').toSorted().map(i => i18next.t('schedule.days.' + i)).join(', ') : '*'
  const time = hour + ":" + (minute.length == 1 ? '0' : '') + minute
  if (daysOfMonth == '*' && month == '*' && daysOfWeek == '*') {
    return i18next.t('format.daily', {time})
  } else if (daysOfMonth == '*' && month == '*') {
    return i18next.t('format.weekly', {daysOfWeek, time})
  } else if (month == '*' && daysOfWeek == '*') {
    return i18next.t('format.monthly', {daysOfMonth, time})
  } else {
    return i18next.t('format.yearly', {month, day: daysOfMonth[0], time})
  }
}