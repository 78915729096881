import Title from '../widgets/Title'
import Page from '../widgets/Page'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBiddingChanges } from '../../api/bidding_changes'
import Table from '../widgets/Table'
import { formatCurrencyNoDecimal, formatInteger, formatPercent } from '../../utilities/formatting'

const BiddingChanges = ({internal_client_id}) => {
  const getColumns = () => {
    return [{
      id: 'bidding_run_id',
      datatype: 'numeric',
      header: 'Run ID',
      width: 80,
      cellRenderer: (value) => {
        return (
          <Link to={'/bidding_changes/' + value} style={{display: 'block', width: 'fit-content', margin: 'auto'}}>{value}</Link>
        )
      }
    },{
      id: 'name',
      datatype: 'text',
      header: 'Run Name',
      width: 400,
    }, {
      id: 'created_at',
      datatype: 'date',
      header: 'Run Date',
      width: 150,
    }, {
      id: 'bid_direction',
      datatype: 'option',
      options: ['Up', 'Down'],
      header: 'Bid Direction',
      width: 120,
    }, {
      id: 'cost_impact',
      datatype: 'numeric',
      header: 'Cost Impact',
      width: 120,
      format: value => formatCurrencyNoDecimal(value),
    }, {
      id: 'clicks_impact',
      datatype: 'numeric',
      header: 'Click Impact',
      width: 120,
      format: value => formatInteger(value),
    }, {
      id: 'cost_weight_change',
      datatype: 'numeric',
      header: 'Cost Weight Change',
      width: 155,
      format: value => formatPercent(value, 2),
    }]
  }

  return (
    <Page>
      <Title title="menu.bidding_changes" />
      <Table
        height={'calc(100vh - 283px)'}
        tableName={'bidding_changes'}
        fetch={tableData => {return getBiddingChanges(internal_client_id, tableData)}}
        columns={getColumns()} />
    </Page>
  )
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(BiddingChanges)
