import { Box, Select, FormControl, MenuItem, InputLabel } from '@mui/material'
import i18next from 'i18next'

const PromptEngineeringSelect = ({ promptEngHistory, setPromptEngDraft, promptEngDraft }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ ...sxFormControl, minWidth: 150, maxWidth: 150 }}>
        <InputLabel sx={sxInputLabelAi}>{i18next.t('ai.promptEngineeringMenu')}</InputLabel>
        <Select
          onChange={e => setPromptEngDraft(e.target.value)}
          sx={sxSelectAi}
          variant='outlined'
          value={promptEngDraft}>
          <MenuItem key={1000} value='' sx={{ maxWidth: 150 }}>
            {i18next.t('ai.none')}
          </MenuItem>
          {
            promptEngHistory.map((pe, i) => (
              <MenuItem key={i} value={pe} sx={{ maxWidth: 300 }}>
                {pe}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export default PromptEngineeringSelect

const sxFormControl = {
  '& .MuiInputLabel-root': {
    color: 'white.100',
  },
  '& .MuiSelect-root': {
    color: 'white.100',
  },
  '& .MuiOutlinedInput-input': {
    padding: '5px 12px'
  },
}


const sxSelectAi = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white.100',
    borderWidth: '1px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'grey.300',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white.100',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white.100'
  },
  '& > svg': {
    visibility: 'hidden',
  },
}

const sxInputLabelAi = {
  '&.MuiInputLabel-outlined': {
    transform: 'translate(8px, 5px) scale(0.95)',
  },
  color: 'white.100',
  '&.MuiInputLabel-shrink': {
    color: 'white.100',
  },
  '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(5px, -20px) scale(0.95)',
  },
}
