import { Component } from 'react'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const drawerWidth = 300

const styles = {
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  wrapper: {
    width: drawerWidth,
    padding: 20
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: 24,
    marginLeft: -drawerWidth,
  },
  contentShift: {
    marginLeft: 0,
  },
}

class PersistentDrawerLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  handleDrawerOpen() {
    this.setState({open:true})
  }

  handleDrawerClose() {
    this.setState({open:false})
  }

  render() {
    return (
      <div style={styles.root}>
        <Drawer
          style={styles.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.open}
        >
          <div style={styles.wrapper}>
            <div style={styles.drawerHeader}>
              <IconButton onClick={() => this.handleDrawerClose()}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {this.props.data_configuration()}
          </div>
        </Drawer>

        <main
          style={Object.assign({}, styles.content, this.state.open ? styles.contentShift : {})}
        >
          {this.props.children}
        </main>
      </div>
    )
  }
}

export default PersistentDrawerLeft
