import * as React from 'react'
import Dialog from '../../widgets/Dialog'
import Button from '../../widgets/Button'

const ConfirmRevert = (props) => {
  const handleClose = () => {
    if (!props.reverting) {
      props.setConfirmRevertOpen(false)
      window.location.href = `/#/bidding/`
    }
  }

  const handleConfirm = () => {
    props.setConfirmRevertOpen(false)
  }

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={handleClose}
      icon="warning"
      title="biddingRun.confirmToContinuePublishing"
      text="biddingRun.confirmDeleteDesc"
      buttons={
        <Button buttonText="biddingRun.confirm" onClick={handleConfirm} color="primary" variant="outlined" />
      }
    />
  )
}

export default ConfirmRevert