import { Tab } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI Tab (https://mui.com/material-ui/api/tab/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.
  label - the label to use for the tab (this should generally be an i18n key)
*/
const FtoTab = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const {children, ...tabProps} = revisedProps
  if (tabProps.label) {
    tabProps.label = i18next.t(tabProps.label)
  }
  return (
    <Tab {...tabProps} />
  )
}

export default FtoTab