import { useMemo, useState, useEffect } from 'react'
import { Box, Grid, Paper, Stack } from '@mui/material'
import { getFilters, getBidSummaryNew, getBidDetailsNew, getBidHistory, exportNoClicks } from '../../../api/bidding'
import Metric from '../../widgets/Metric'
import BidReasons from '../bidding_run/BidReasons'
import BidDetails from '../bidding_run/BidDetails'
import PullDown from '../../widgets/PullDown'
import Button from '../../widgets/Button'
import Title from '../../widgets/Title'
import Checkbox from '../../widgets/Checkbox'
import PieChart from '../../charts/PieChart'
import BarChart from '../../charts/BarChart'
import LineChart from '../../charts/LineChart'
import KeywordPreview from './KeywordPreview'
import i18next from 'i18next'
import PublishBids from './PublishBids'
import { capitalize } from '../../../utilities/formatting'

const DashboardBidSummary = ({ isDraft, bidding_run_id, disabled, ...props }) => {
  const allMetrics = [
    {id: 'clicks', data: b => b.clicks, visible: true},
    {id: 'cost', data: b => b.cost, visible: true},
    {id: 'conversions', data: b => b.internal_conversions},
    {id: 'roas', data: b => b.roas},
    {id: 'engagementScoreClicks', data: b => b.escore_clicks},
    {id: 'engagementScoreCost', data: b => b.escore_cost},
  ]
  const formatName = (name) => {
    return name.split(' ').map((s, i) => i == 0 || s == 'keyword' ? capitalize(s) : s.toUpperCase()).join(' ')
  }
  const [engines, setEngines] = useState()
  const [campaigns, setCampaigns] = useState()
  const [adGroups, setAdGroups] = useState()
  const [filters, setFilters] = useState()
  const [summary, setSummary] = useState()
  const [details, setDetails] = useState()
  const [history, setHistory] = useState()
  const [selectedMetrics, setSelectedMetrics] = useState(['clicks', 'cost'])

  const metrics = allMetrics.filter(m => selectedMetrics.includes(m.id))
  const engineValues = engines ? engines.map(v => v.name) : null
  const campaignValues = campaigns ? campaigns.map(v => v.id) : null
  const adGroupValues = adGroups ? adGroups.map(v => v.id) : null

  useEffect(() => {
    getFilters(bidding_run_id).then(result => setFilters(result))
  }, [])

  useEffect(() => {
    getBidSummaryNew(bidding_run_id, engineValues, campaignValues, adGroupValues).then(result => setSummary(result))
    getBidDetailsNew(bidding_run_id, engineValues, campaignValues, adGroupValues).then(result => setDetails(result))
    getBidHistory(bidding_run_id, engineValues, campaignValues, adGroupValues).then(result => setHistory(result))
  }, [engines, campaigns, adGroups])

  const engineBreakdown = useMemo(() => {
    if (summary) {
      const labels = summary['by_engine'].map(v => formatName(v.engine))
      const datasets = [summary['by_engine'].map(v => v.count)]
      return {
        labels,
        title: i18next.t('biddingSummary.engine'),
        datasets
      }
    }
  }, [summary])

  const entityBreakdown = useMemo(() => {
    if (summary) {
      const labels = summary['by_entity'].map(v => formatName(v.entity_type))
      const data = summary['by_entity'].map(v => v.count)
      return {
        labels,
        datasets: [{ metric: i18next.t('biddingSummary.bids'), data }],
        title: i18next.t('biddingSummary.entityBreakdown')
      }
    }
  }, [summary])

  const timeBreakdown = useMemo(() => {
    if (history) {
      return {
        two_datasets_mode: selectedMetrics.length > 1,
        x_axis_labels_1: history.map(b => b.date),
        dataset_labels: metrics.map(m => i18next.t('biddingSummary.' + m.id)),
        datasets: metrics.map(m => history.map(m.data)),
        title: i18next.t('biddingSummary.lastDays', {days: props.lookbackDuration == 'automatic' ? 30 : props.lookbackDuration}),
      }
    }
  }, [history, selectedMetrics])

  const barChartOptions = {
    title: {
      fontSize: 23,
    },
  }

  const pieChartOptions = {
    layout: {
      padding: 10,
    },
    plugins: {
      customCanvasBackgroundColor: {
        color: 'primary.main',
      }
    }
  }

  const gs = 4
  
  const downloadNoClickKeywords = () => {
    exportNoClicks(bidding_run_id).then(result => {
      if (result.url) {
        const link = document.createElement('a')
        link.href = result.url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      if (result.error) {
        FTNotice(result.error, 15000)
      }
    })
  }
  
  const selectMetric = (metric, include) => {
    const without = selectedMetrics.filter(m => metric.id != m)
    setSelectedMetrics(include ? without.concat(metric.id) : without)
  }
  
  return (
    <>
      <Grid container spacing={gs} sx={{ width: '95vw', height: '100%', mb: gs }}>
        <Grid item md={2} sm={12} xs={12}>
          <Box sx={{ height: 50 }}>
            {filters?.engines && (
              <PullDown
                disableCloseOnSelect={false}
                disabled={disabled}
                textFieldVariant="standard"
                isConfig={true}
                labelText="biddingSummary.engine"
                options={filters.engines}
                value={engines == null ? filters.engines : engines}
                optionFunction={option => option.name}
                allTagsFunction={() => engines == null ? 'biddingSummary.all' : null}
                optionTagFunction={option => option.name}
                onChange={(_, values) => setEngines(values.length == 0 || values.length == filters.engines.length ? null : values)}
                hideSelectedChips={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          <Box sx={{ height: 50 }}>
            {filters?.campaigns && (
              <PullDown
                disableCloseOnSelect={false}
                hideSelectedChips={true}
                disabled={disabled}
                textFieldVariant="standard"
                isConfig={true}
                labelText="biddingSummary.campaign"
                options={filters.campaigns}
                value={campaigns == null ? filters.campaigns : campaigns}
                optionFunction={option => option.name}
                allTagsFunction={() => campaigns == null ? 'biddingSummary.all' : null}
                optionTagFunction={option => option.name}
                onChange={(_, values) => setCampaigns(values.length == 0 || values.length == filters.campaigns.length ? null : values)}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          <Box sx={{ height: 50 }}>
            {filters?.ad_groups && (
              <PullDown
                disableCloseOnSelect={false}
                hideSelectedChips={true}
                disabled={disabled}
                textFieldVariant="standard"
                isConfig={true}
                labelText="biddingSummary.adGroup"
                options={filters.ad_groups}
                value={adGroups == null ? filters.ad_groups : adGroups}
                optionFunction={option => option.name + ' (' + option.campaign_name + ')'}
                allTagsFunction={() => adGroups == null ? 'biddingSummary.all' : null}
                optionTagFunction={option => option.name}
                onChange={(_, values) => setAdGroups(values.length == 0 || values.length == filters.ad_groups.length ? null : values)}
              />
            )}
          </Box>
        </Grid>
        <PublishBids
          setPublishingProgressCircle={props.setPublishingProgressCircle}
          setGeneratingRevertProgressCircle={props.setGeneratingRevertProgressCircle}
          engines={engineValues}
          campaigns={campaignValues}
          adGroups={adGroupValues}
          {...props} />
      </Grid>

      {/* grid item 1: bar graph*/}
      <Grid item sm={12} container spacing={gs} sx={{ mb: gs }}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ height: '350px' }}>
          <Paper elevation={3} sx={{
            borderRadius: 3,
            height: '100%',
            padding: 3,
            backgroundColor: '#fff',
          }}>
            {entityBreakdown && (
              <BarChart options={barChartOptions} {...entityBreakdown} />
            )}
          </Paper>
        </Grid>
        {/* grid item 2: pie chart */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper elevation={3} sx={{
            borderRadius: 3,
            height: '100%',
            padding: 3,
            backgroundColor: '#fff'
          }}>
            {engineBreakdown && (
              <PieChart options={pieChartOptions} {...engineBreakdown} />
            )}
          </Paper>
        </Grid>
        {/* grid item 3: scorecards */}
        <Grid item md={12} sm={12} xs={12} container spacing={gs} sx={{ mb: 1 }}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingSummary.keywords"
              value={summary?.keywords} />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingSummary.clicks"
              value={summary?.clicks} />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingSummary.cost"
              value={summary?.cost} />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingSummary.percentOfCost"
              value={summary?.percent_of_cost} />
          </Grid>
        </Grid>
      </Grid>

      {/* bottom metric scorecards */}
      <Grid item md={12} container spacing={gs} sx={{ mb: gs }}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Metric
            name="biddingSummary.conversions"
            value={summary?.conversions}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Metric
            name="biddingSummary.conversionRate"
            value={summary?.conversion_rate} />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Metric
            name="biddingSummary.cpa"
            value={summary?.cpa} />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Metric
            name="biddingSummary.roas"
            value={summary?.roas} />
        </Grid>
      </Grid>

      {/* averages */}
      <Grid container spacing={gs} sx={{ mb: gs }}>
        <Grid item md={12} sm={12} xs={12}>
          <Title title={i18next.t('biddingRun.performanceMetricAverageValues')} variant='h4' />
        </Grid>
      </Grid >
      <Grid item md={12} container spacing={gs} sx={{ mb: gs }}>
        {props.props.use_clicks && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.clicks"
              value={summary?.avg_clicks}
            />
          </Grid>
        )}
        {props.props.use_roas && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.roas"
              value={summary?.avg_roas}
            />
          </Grid>
        )}
        {props.props.use_cpa && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.cpa"
              value={summary?.avg_cpa}
            />
          </Grid>
        )}
        {props.props.use_conversion_rate && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.conversionRate"
              value={summary?.avg_conversion_rate}
            />
          </Grid>
        )}
        {props.props.use_engagement_score_clicks && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.engagementScoreClicks"
              value={summary?.avg_escore_clicks}
            />
          </Grid>
        )}
        {props.props.use_engagement_score_cost && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Metric
              name="biddingRun.engagementScoreCost"
              value={summary?.avg_escore_cost}
            />
          </Grid>
        )}
      </Grid>

      {/* starts next row */}
      <Grid container spacing={gs} sx={{ mb: gs }}>
        <Grid item md={8} sm={12} xs={12}>
          <Paper elevation={3} sx={{ backgroundColor: '#fff', borderRadius: 3, padding: 3, height: '100%' }}>
            {timeBreakdown && (
              <LineChart {...timeBreakdown} />
            )}
          </Paper>
        </Grid >
        <Grid item md={4} sm={12} xs={12}>
          <Box>
            {details?.length > 0 && (
              <KeywordPreview
                keywords={details}
                keywordCount={summary?.keywords} />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={gs} sx={{ mb: gs }}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row">
            {allMetrics.map((metric, i) => (
              <Checkbox key={i} checked={selectedMetrics.includes(metric.id)} onChange={event => selectMetric(metric, event.target.checked)} labelText={i18next.t('biddingSummary.' + metric.id)} />
            ))}
          </Stack>
        </Grid>
      </Grid>

      {/* start bidding reasons */}
      {props.isAdmin && summary?.reasons && (
        <Grid container spacing={gs} sx={{ mb: gs }}>
          <Grid item md={12} sm={12} xs={12}>
            <Title title={i18next.t('biddingRun.reasonExcluded')} variant='h4' />
            <BidReasons reasons={summary?.reasons} />
          </Grid>
        </Grid >
      )}
      {/* start keyword details table */}
      <Grid container spacing={gs} sx={{ mb: gs }}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row">
            <Title title={i18next.t('biddingRun.details')} variant='h4' />
            <Button buttonText={i18next.t('biddingRun.downloadNoClickKeywords')} onClick={downloadNoClickKeywords} sx={{height: '24px', left: '10px', top: '7px'}} />
          </Stack>
          <Box sx={{ maxHeight: "auto" }}>
            <BidDetails
              isDraft={isDraft}
              bidding_run_id={bidding_run_id}
              engines={engineValues}
              campaigns={campaignValues}
              adGroups={adGroupValues} />
          </Box>
        </Grid>
      </Grid >
    </>
  )
}

export default DashboardBidSummary