import React, { useMemo } from 'react'
import { previewDimensionSummary } from '../../api/dimensions'
import Table from '../widgets/Table'
import { formatInteger } from '../../utilities/formatting'

const SummaryTable = ({internal_client_id, dimension}) => {
  const getColumns = () => {
    return [{
      id: 'value',
      header: 'Value',
      width: 170,
    }, {
      id: 'count',
      header: 'Count',
      format: value => formatInteger(value),
      width: 120,
    },{
      id: 'clicks',
      header: 'Clicks',
      datatype: 'numeric',
      width: 110
    },{
      id: 'cost',
      datatype: 'numeric',
      width: 125
    },{
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 125
    }]
  }
  
  const table = useMemo(() => (
    <Table
      tableName='manage_dimensions'
      fetch={tableData => {
        tableData.page_size = -1
        return previewDimensionSummary(dimension.sql.replace(/\{internal_client_id\}/g, internal_client_id), tableData)
      }}
      columns={getColumns()}
      autoHeight={true}
      hidePagination={true} />    
  ), [])

  return table
}

export default SummaryTable
