import request from './request'

// integrations
export function getAccounts(internalClientId) {
  return request('/api/clients/' + internalClientId + '/accounts')
}

// integrations
export function getAccount(internalClientId, accountId) {
  return request(`/api/clients/${internalClientId}/accounts/${accountId}`);
}

// integrations
export function createAccount(internalClientId, data) {
  return request(`/api/clients/${internalClientId}/accounts`, {}, 'POST', data);
}

// integrations
export function updateAccount(internalClientId, accountId, data) {
  return request(`/api/clients/${internalClientId}/accounts/${accountId}`, {}, 'PUT', data);
}

// integrations
export function deleteAccount(internalClientId, accountId) {
  return request(`/api/clients/${internalClientId}/accounts/${accountId}`, {}, 'DELETE');
}
