import _ from 'lodash'
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle, useMemo } from 'react'
import { connect } from 'react-redux'
import locale from '../../api/locale'
import { AgGridReact } from 'ag-grid-react'
import { getColumns, updateColumns } from '../../api/columns'
import FTNotice from '../FTNotice'
import UpdateColumns from '../UpdateColumns'
import { dataFormatter } from '../../utilities/formatting'
import i18next from 'i18next'

// stylesheets
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import './Table.scss'

/**
 * This widget uses AG Grid.  See https://ag-grid.com/react-data-grid/ for more information on the underlying library.
 * Table widget.  For best results, numeric data returned by the server should have the same precision (rounding) as in the UI.  Otherwise, if a user
 * select values that equal 5, a value of 5 might not show (because it is 4.95 to the back end).
 *
 * Properties:
 *   fetch (must set dataset or fetch) - function which gets the data for the table. Should return the result of an http request.  For example:
 *     {(tableData) => {return getData(tableData)}} where getData returns the result of the call to request.  tableData should be added to the end of
 *     the http call as a query string).
 *   dataset (must set dataset or fetch) - data (array of rows that doesn't require server interaction).
 *   refresh (optional) - If dataset, this is a function (no parameters) that can be called to refresh the data.
 *   tableName - name of table in db (if it exists, which columns to show and order will be pulled from there).
 *   columns - array of columns:
 *     id (optional if cellRenderer present) - the property to use to get the data for this column for a row (will be used for lookup in locale for column name if header and localeKey not provided)
 *     columnId (optional override of id) - used to determine which column to map to in the db (uses id if blank).  This is good for a variable column.
 *     header (optional) - value to show for header (if absent look up the localeKey or id in locale)
 *     localeKey (optional) - key to look up the header name in the locale (if something other than id needed)
 *     datatype (optional) - text, numeric, date, option or other.  If present, filters will be enabled for this column (note: option is intended fot the case
 *       where there is a fixed set of options; currently it is implemented the same as text).  If other, it will be sortable, but not filterable.
 *       If date is used with dataset, the values should formatted like 2020-11-01 20:45 (in utc) or 2020-11-01 if no time.
 *     options (optional) - list of possible options for column; currently not used
 *     className (optional) - a css class to add to the cells in the column (ex., text-center)
 *     classRules (optional) - a dictionary of class names to functions that get cell params.  Ex: {'green': (params) => {return params.value > 0}}
 *     width (optional) - the width of the column
 *     cellRenderer (optional) - a function to return a component for the cell (it is passed the value and the row data)
 *     cellRendererAffectsSize (optional) - a function to return whether the cell renderer affects the size of the cell (if not set, we assume yes)
 *     cellEditor (optional) - a function to return a component for editing the cell (it is passed the value, the row data, a setValue method and a ref (for focus))
 *     onCellValueChanged (optional) - a function to process changes to the cell value via editing (see https://ag-grid.com/angular-data-grid/cell-editing/)
 *     format (optional) - a function to process the column value (ex., rounding; it is passed the value and the row data)
 *     rowDrag (optional) - if true, row can be dragged / dropped by this column
 *     pinned (optional) - can be 'left', 'right' or if left off, will not be pinned
 *   columnOverride (optional) - If provided, allow caller to make some final tweaks to columns after using db config.  Will be passed columns and should return new columns.
 *   headerHeight (optional) - the height of the headers.  If not set, it will be 25.
 *   rowHeight (optional) - the height of the rows.  Needed if a custom cell is present.  Otherwise, it will be auto-calculated.
 *   autoHeight (optional) - if true, will make the height of the table based on the size of the data. If false or not set, set to height.
 *   height (optional) - if not set, default to 80vh.
 *   hidePagination (optional) - if true, will hide the pagination controls.
 *   hideExport (optional) - if true, will hide the export button.
 *   export (optional) - if not set, export is automatically handled; if set, the function is called on export and will be sent tableData (sorts, filters, etc).
 *     The function should return a promise with either error or url.
 *   preventSort (optional) - if true, don't allow sort on any of the columns.
 *   noDataText (optional) - text to show if no rows.  If null, leave empty.
 *   onRowDragEnd (optional) - Event on drop, passed the new list of rows (in order).
 *   pageSize (optional) - The maximum number of elements to show on a page (defaults to 500).
 *   marginBottom (optional) - The margin at the bottom (defaults to 10).
 *   className (optional) - className for the containing div.
 *   style (optional) - style for the containing div.
 *   disableCellSelection (optional) - defaults to false; if true, cannot select cells (useful if editing).
 *   changeParams (optional) - Recreate table if changeParams change.
 */
const FTOTable = forwardRef((props, _ref) => {
  // interface for callers that use ref property
  useImperativeHandle(_ref, () => ({
    refreshCells, refreshRows, updateRows, resizeColumns
  }))

  const grid = useRef()
  const [columnConfiguration, setColumnConfiguration] = useState()
  const [columns, setColumns] = useState()
  const [rows, setRows] = useState()
  const [updatingColumns, setUpdatingColumns] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)

  useEffect(() => {
    if (props.tableName) {
      getConfiguredColumns()
    } else {
      setColumns(props.columns)
    }
  }, [JSON.stringify(props.columns)])

  const getConfiguredColumns = () => {
    /**
     * Get the columns to show based on the database and the overrides.
     */
    getColumns(props.internal_client_id, props.tableName).then(
      (response) => {
        let newColumns = []
        response.results.forEach(column => {
          props.columns.forEach(candidate => {
            const columnId = candidate.columnId
              ? candidate.columnId
              : candidate.id
            if (columnId == column.column_id) {
              column.name = getColumnHeader(candidate)
              if (column.shown) {
                newColumns.push(candidate)
              }
            }
          })
        })
        if (props.columnOverride) {
          newColumns = props.columnOverride(newColumns)
        }
        setColumnConfiguration(response.results)
        setColumns(newColumns)
      }
    )
  }

  const showUpdateColumns = () => {
    /**
     * Show the update columns dialog.
     */
    setUpdatingColumns(true)
  }

  const updateTableColumns = (newColumns) => {
    /**
     * Update the columns based on the results of the update columns dialog.
     */
    if (newColumns) {
      updateColumns(
        props.internal_client_id,
        props.tableName,
        newColumns
      ).then(() => {
        getConfiguredColumns()
      })
    }
    setUpdatingColumns(false)
  }

  const refreshRows = () => {
    /**
     * Update the table data (for example, after removing a row from the table).  Note: FTOTable instance is sent via ref property.
     */
    if (grid.current) {
      if (props.fetch) {
        if (grid.current.api.getInfiniteRowCount()) {
          grid.current.api.refreshInfiniteCache()
        } else {
          // See https://github.com/ag-grid/ag-grid/issues/2286
          grid.current.api.onSortChanged()
        }
      } else if (props.refresh) {
        props.refresh()
      }
    }
  }

  const updateRows = (newRows) => {
    /**
     * Update the table data when provided via the dataset property.  Note: FTOTable instance is sent via ref property.
     */
    if (grid.current) {
      grid.current.api.setRowData(newRows)
    }
  }

  const refreshCells = () => {
    /**
     * Refresh the cells (for example, after changing the column definitions).
     */
    if (grid.current) {
      grid.current.api.redrawRows()
    }
  }

  const getTableColumns = () => {
    /**
     * Get the table columns.  Do any necessary preprocessing (such as header lookup, accessor set up or renderer determination).
     */
    const tableColumns = []
    columns.forEach((col) => {
      const column = Object.assign({}, col)
      column.header = getColumnHeader(column)
      column.accessor = row => {
        const result = row.data
        if (result) {
          let value = null
          if (column.format) {
            value = result[col.id]
          } else {
            value =
              typeof result[col.id] === 'boolean'
                ? result[col.id].toString()
                : result[col.id] // Otherwise, won't show booleans
          }
          return value
        } else {
          return null
        }
      }
      const altId = column.id ? getAltId(column.id) : null
      if (!column.format) {
        if (column.datatype == 'date') {
          column.format = (value) => {
            const date = value == null ? null : parseDate(value)
            return value == null
              ? null
              : (value.split(' ').length > 1 || value.indexOf('T') == 10)
                ? date.format('M/D/YYYY h:mm a')
                : date.format('M/D/YYYY')
          }
        } else {
          let formatter = dataFormatter(column.id, true)
          if (formatter == null) {
            formatter = dataFormatter(altId, true)
          }
          if (formatter != null) {
            column.format = (value) => formatter(value)
          }
        }
      }
      if (column.cellRenderer) {
        column.componentRenderer = 'cellRenderer'
      }
      if (column.cellEditor) {
        column.componentEditor = 'cellEditor'
      }
      if (!column.alignment) {
        column.alignment = column.datatype == 'numeric' ? 'right' : 'center'
      }
      tableColumns.push(column)
    })
    return tableColumns
  }

  const getAltId = (id) => {
    const splits = id.split('_')
    splits.pop() // remove last item
    return splits.join('_')
  }

  const getColumnHeader = (column) => {
    /**
     * Get the column header.  First look at header, then locale key (for look up in locale) and finally id (for look up in locale).
     */
    if (column.header) {
      return i18next.t(column.header)
    } else if (column.localeKey) {
      return locale.metrics[column.localeKey]
    } else {
      return locale.metrics[column.id]
    }
  }

  const onFirstDataRendered = () => {
    /**
     * Data is being rendered for the first time (only useful if dataset is provided).
     */
    if (props.dataset) {
      resizeColumns()
    }
  }

  const resizeColumns = () => {
    /**
     * Resize the columns based on content.
     */
    const columnIds = []
    getTableColumns().forEach((column) => {
      if (!column.width) {
        columnIds.push(column.id)
      }
    })
    if (columnIds.length) {
      if (props.dataset) {
        grid.current.columnApi.autoSizeColumns(columnIds)
        resizeRows()
      } else {
        setTimeout(() => {
          // create dummy element position fixed, so it isn't restricted to the boundaries of the parent
          var eDummyContainer = document.createElement('form')
          const columnApi = grid.current.columnApi
          const autoWidthCalculator = columnApi.columnModel.autoWidthCalculator
          const eBodyContainer = autoWidthCalculator.centerRowContainerCtrl.getContainerElement()
          eBodyContainer.appendChild(eDummyContainer)
          const rowRenderer = autoWidthCalculator.rowRenderer
          const displayedColumns = columnApi.columnModel.displayedColumns

          const newWidths = []
          let maxWidth = (window.innerWidth - 350) / 3
          maxWidth = maxWidth < 200 ? 200 : maxWidth
          columnIds.forEach(columnId => {
            const column = displayedColumns.find(c => c.colDef.colId == columnId)
            const columnDef = column.colDef
            let columnWidth = autoWidthCalculator.getPreferredWidthForColumn(column) // Header
            columnWidth = columnWidth > maxWidth ? maxWidth : columnWidth
            grid.current.api.forEachNode(node => {
              const rowController = rowRenderer.rowCtrlsByRowIndex[node.rowIndex]
              const row = node.data
              if (rowController && row) {
                const value = row[columnDef['colId']]
                // short circuit so that we only calculate size if there is a cell renderer that affects size or the cell contents have a length > 5
                if (
                  (column.cellRenderer &&
                    (!column.cellRendererAffectsSize ||
                      column.cellRendererAffectsSize(value, row))) ||
                  (value != null && ('' + value).length > 5)
                ) {
                  // get the height of the cell if the width is fixed to the column width
                  const cell = rowController.getCellElement(column)
                  if (cell) {
                    autoWidthCalculator.cloneItemIntoDummy(cell, eDummyContainer)
                    const child = eDummyContainer.firstChild
                    child.style.position = 'fixed' // unrestricted by parent size
                    child.style.width = 'auto'
                    let newWidth = child.offsetWidth > maxWidth ? maxWidth : child.offsetWidth
                    if (newWidth > columnWidth) {
                      columnWidth = newWidth
                    }
                    eDummyContainer.removeChild(child)
                  }
                }
              }
            })
            newWidths.push({ key: columnId, newWidth: columnWidth })
          })
          columnApi.setColumnWidths(newWidths)

          // we are finished with the dummy container, so get rid of it
          eBodyContainer.removeChild(eDummyContainer)
          resizeRows()
        }, 100)
      }
    } else {
      resizeRows()
    }
  }

  const resizeRows = () => {
    /**
     * Resize the rows based on content (only necessary for infinite; ag-grid handles for client-side).
     */
    if (!props.rowHeight && !props.dataset && grid.current) {
      setTimeout(() => {
        // create dummy element position fixed, so it isn't restricted to the boundaries of the parent
        var eDummyContainer = document.createElement('form')
        const columnApi = grid.current.columnApi
        const autoWidthCalculator = columnApi.columnModel.autoWidthCalculator
        const eBodyContainer = autoWidthCalculator.centerRowContainerCtrl.getContainerElement()
        eBodyContainer.appendChild(eDummyContainer)
        const rowRenderer = autoWidthCalculator.rowRenderer
        const displayedColumns = columnApi.columnModel.displayedColumns
        let gridHeight = 0

        grid.current.api.forEachNode(node => {
          let rowHeight = 25
          const rowController = rowRenderer.rowCtrlsByRowIndex[node.rowIndex]
          const row = node.data
          if (rowController && row) {
            for (let i = 0; i < displayedColumns.length; i++) {
              const column = displayedColumns[i]
              const columnDef = column.colDef
              const value = row[columnDef['colId']]
              // short circuit so that we only calculate size if there is a cell renderer that affects size or the cell contents have a length > 5
              if (
                (column.cellRenderer &&
                  (!column.cellRendererAffectsSize ||
                    column.cellRendererAffectsSize(value, row))) ||
                (value != null && ('' + value).length > 5)
              ) {
                // get the height of the cell if the width is fixed to the column width
                const cell = rowController.getCellElement(column)
                if (cell) {
                  autoWidthCalculator.cloneItemIntoDummy(cell, eDummyContainer)
                  const child = eDummyContainer.firstChild
                  child.style.position = 'fixed' // unrestricted by parent size
                  child.style.width = column.actualWidth + 'px'
                  child.style.height = 'auto'
                  child.classList.add('ag-row-position-absolute') // this will allow wrapping
                  if (child.offsetHeight > rowHeight) {
                    rowHeight = child.offsetHeight
                  }
                  eDummyContainer.removeChild(child)
                }
              }
            }
            node.setRowHeight(rowHeight)
            node.setRowTop(gridHeight)
            gridHeight += rowHeight
          } else {
            node.setRowHeight(rowHeight)
            gridHeight = node.rowTop + node.rowHeight
          }
        })

        // we are finished with the dummy container, so get rid of it
        eBodyContainer.removeChild(eDummyContainer)
      }, 0)
    }
  }

  const getRows = (params) => {
    /**
     * Get the row data (including adding any sorting or filtering).
     */
    if (grid.current) {
      grid.current.api.showLoadingOverlay()
    }
    const pageSize = params.endRow - params.startRow
    const tableData = {
      page: 1 + params.startRow / pageSize,
      page_size: pageSize,
    }
    if (params.sortModel && params.sortModel.length) {
      const sorted = []
      params.sortModel.forEach((sort) => {
        sorted.push(sort.colId + ' ' + sort.sort)
      })
      tableData.sorted = sorted
    }
    if (params.filterModel) {
      for (var key in params.filterModel) {
        const value = params.filterModel[key]
        if (value.filterType == 'text') {
          if (value.operator == 'AND') {
            addTextFilter(tableData, key, value.condition1)
            addTextFilter(tableData, key, value.condition2)
          } else {
            addTextFilter(tableData, key, value)
          }
        } else if (value.filterType == 'number') {
          if (value.operator == 'AND') {
            addNumericFilter(tableData, key, value.condition1)
            addNumericFilter(tableData, key, value.condition2)
          } else {
            addNumericFilter(tableData, key, value)
          }
        } else if (value.filterType == 'date') {
          if (value.operator == 'AND') {
            addDateFilter(tableData, key, value.condition1)
            addDateFilter(tableData, key, value.condition2)
          } else {
            addDateFilter(tableData, key, value)
          }
        }
      }
    }
    setRows(tableData)
    props.fetch(tableData).then((results) => {
      if (results) {
        params.successCallback(results.results, results.total_rows)
        if (firstFetch) {
          setFirstFetch(false)
          resizeColumns()
        } else {
          resizeRows()
        }
      } else {
        params.failCallback()
      }
      if (grid.current) {
        grid.current.api.hideOverlay()
      }
    })
  }

  const addTextFilter = (tableData, key, condition) => {
    /**
     * Determine how to map a text filter.
     */
    const type = condition.type
    const filter = key + ' ' + condition.filter
    if (type == 'contains') {
      addFilterType(tableData, 'text_like_filter', filter)
    } else if (type == 'equals') {
      addFilterType(tableData, 'text_equal_filter', filter)
    } else if (type == 'notContains') {
      addFilterType(tableData, 'text_not_like_filter', filter)
    } else if (type == 'notEqual') {
      addFilterType(tableData, 'text_not_equal_filter', filter)
    } else if (type == 'startsWith') {
      addFilterType(tableData, 'text_starts_with_filter', filter)
    } else if (type == 'endsWith') {
      addFilterType(tableData, 'text_ends_with_filter', filter)
    }
  }

  const addNumericFilter = (tableData, key, condition) => {
    /**
     * Determine how to map a numeric filter.
     */
    const type = condition.type
    const filter = key + ' ' + condition.filter
    if (type == 'equals') {
      addFilterType(tableData, 'numeric_equals_filter', filter)
    } else if (type == 'notEqual') {
      addFilterType(tableData, 'numeric_not_equal_filter', filter)
    } else if (type == 'lessThan') {
      addFilterType(tableData, 'numeric_less_than_filter', filter)
    } else if (type == 'lessThanOrEqual') {
      addFilterType(tableData, 'numeric_to_filter', filter)
    } else if (type == 'greaterThan') {
      addFilterType(tableData, 'numeric_greater_than_filter', filter)
    } else if (type == 'greaterThanOrEqual') {
      addFilterType(tableData, 'numeric_from_filter', filter)
    } else if (type == 'inRange') {
      addFilterType(tableData, 'numeric_from_filter', filter)
      addFilterType(
        tableData,
        'numeric_to_filter',
        key + ' ' + condition.filterTo
      )
    }
  }

  const addDateFilter = (tableData, key, condition) => {
    /**
     * Determine how to map a date filter.
     */
    const type = condition.type
    const filter = key + ' ' + condition.dateFrom.split(' ')[0]
    if (type == 'equals') {
      addFilterType(tableData, 'date_equals_filter', filter)
    } else if (type == 'notEqual') {
      addFilterType(tableData, 'date_not_equal_filter', filter)
    } else if (type == 'lessThanOrEqual') {
      addFilterType(tableData, 'date_less_than_filter', filter)
    } else if (type == 'greaterThanOrEqual') {
      addFilterType(tableData, 'date_greater_than_filter', filter)
    } else if (type == 'inRange') {
      addFilterType(tableData, 'date_greater_than_filter', filter)
      addFilterType(
        tableData,
        'date_less_than_filter',
        key + ' ' + condition.dateTo.split(' ')[0]
      )
    }
  }

  const addFilterType = (tableData, key, filter) => {
    /**
     * Add a filter to a certain type of filter (ex., text_like_filter)
     */
    if (!tableData[key]) {
      tableData[key] = []
    }
    tableData[key].push(filter)
  }

  const compareDates = (filterLocalDateAtMidnight, value) => {
    /**
     * Compare dates (used for sorting / filtering if dataset is used).
     */
    if (value == null) {
      return 0
    }

    var cellDate = parseDate(value).toDate()

    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1
    } else {
      return 0
    }
  }

  const parseDate = (value) => {
    /**
     * Parse a date or date/time from the back end.
     */
    return value.split(' ').length > 1
      ? moment(value + '+00:00', moment.ISO_8601)
      : moment(value, moment.ISO_8601)
  }

  const exportTable = () => {
    /**
     * Export table to a csv file.
     */

    if (grid.current) {
      if (props.export) {
        const tableData = Object.assign({}, rows)

        // Export all pages
        delete tableData.page
        tableData.page_size = 1000000

        props.export(tableData).then((result) => {
          if (result.url) {
            const link = document.createElement('a')
            link.href = result.url
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
          if (result.error) {
            FTNotice(result.error, 15000)
          }
        })
      } else {
        grid.current.api.exportDataAsCsv()
      }
    }
  }

  const agTable = useMemo(() => {
    if (columns) {
      let hasRowDrag = false
      columns.forEach(column => {
        if (column.rowDrag) {
          hasRowDrag = true
        }
      })
      const disableCellSelection = props.disableCellSelection
      return (
        <AgGridReact
          ref={grid}
          scrollbarWidth={15}
          onFirstDataRendered={() => onFirstDataRendered()}
          onColumnResized={() => resizeRows()}
          onColumnVisible={() => resizeRows()}
          onBodyScroll={() => resizeRows()}
          onRowDragEnd={() => {
            if (props.onRowDragEnd) {
              const selectedRows = []
              grid.current.api.getModel().rowsToDisplay.forEach((row) => {
                selectedRows.push(row.data)
              })
              props.onRowDragEnd(selectedRows)
            }
          }}
          domLayout={props.autoHeight ? 'autoHeight' : null}
          rowModelType={props.dataset ? 'clientSide' : 'infinite'}
          headerHeight={
            props.headerHeight == null ? 25 : props.headerHeight
          }
          rowHeight={props.rowHeight}
          suppressColumnVirtualisation={true}
          datasource={
            props.dataset
              ? null
              : {
                getRows: params => getRows(params),
              }
          }
          rowData={props.dataset}
          pagination={!props.hidePagination}
          paginationPageSize={props.pageSize ? props.pageSize : 500}
          cacheBlockSize={props.pageSize ? props.pageSize : 500}
          overlayLoadingTemplate={'<span className="ag-overlay-loading-center">' + i18next.t('table.loadingData') + '</span>'}
          overlayNoRowsTemplate={
            '<span>' +
            (props.noDataText ? props.noDataText : '') +
            '</span>'
          }
          enableCellTextSelection={true}
          suppressCellFocus={props.disableCellSelection}
          rowDragManaged={hasRowDrag}
          components={{ cellRenderer: CellRenderer, cellEditor: CellEditor }}
          enterMovesDownAfterEdit={true}
          columnDefs={
            getTableColumns().map(column => {
              return {
                colId: column.id,
                headerName: column.header,
                valueGetter: column.accessor,
                valueSetter: params => {
                  params.data[params.column.colId] = params.newValue
                  return true
                },
                valueFormatter:
                  column.format
                    ? params => column.format(params.value, params.data)
                    : null,
                autoHeight: !props.rowHeight && props.dataset, // can only use autoHeight if client side or server side row model, not infinite
                wrapText: false, // covered by wrapped-cell class below which enables us to only wrap when in the table, not when determining width
                width: column.width,
                pinned: column.pinned,
                resizable: true,
                headerClass: column.alignment,
                cellClass:
                  (column.className ? column.className : '') +
                  ' ' +
                  column.alignment +
                  ' wrapped-cell',
                cellClassRules: column.classRules,
                editable: column.editable,
                singleClickEdit: true,
                onCellValueChanged: column.onCellValueChanged,
                cellEditor: column.componentEditor,
                cellEditorParams: { column: column },
                cellRenderer: column.componentRenderer,
                cellRendererParams: { column: column },
                filter:
                  column.datatype == 'text' || column.datatype == 'option'
                    ? 'agTextColumnFilter'
                    : column.datatype == 'numeric'
                      ? 'agNumberColumnFilter'
                      : column.datatype == 'date'
                        ? 'agDateColumnFilter'
                        : null,
                filterParams:
                  column.datatype == 'date'
                    ? {
                      suppressAndOrCondition: true,
                      filterOptions: [
                        'equals',
                        'notEqual',
                        'lessThanOrEqual',
                        'greaterThanOrEqual',
                        'inRange',
                      ],
                      inRangeInclusive: true,
                      comparator: (
                        filterLocalDateAtMidnight,
                        cellValue
                      ) => {
                        return compareDates(
                          filterLocalDateAtMidnight,
                          cellValue
                        )
                      },
                    }
                    : null,
                sortable:
                  !props.preventSort &&
                  column.datatype &&
                  !column.preventSort,
                rowDrag: column.rowDrag,
                suppressKeyboardEvent: params =>
                  disableCellSelection
                    ? ['Tab', 'ArrowLeft', 'ArrowRight'].indexOf(
                      params.event.code
                    ) >= 0
                    : false,
              }
            })
          } />
      )
    } else {
      return null
    }
  }, [JSON.stringify({ columns, changeParams: props.changeParams, dataset: props.dataset })])

  if (!agTable) {
    return null
  }
  return (
    <div className={props.className} style={props.style}>
      {updatingColumns && (
        <UpdateColumns
          columns={columnConfiguration}
          action={newColumns => updateTableColumns(newColumns)}
        />
      )}
      {!props.hideExport && (
        <div
          style={{
            overflow: 'hidden',
            background: 'white',
            borderLeft: '1px solid #bdc3c7',
            borderTop: '1px solid #bdc3c7',
            borderRight: '1px solid #bdc3c7',
            padding: '5px 20px 5px 20px',
          }}
        >
          <div
            onClick={() => exportTable()}
            style={{ marginTop: 3, float: 'right', cursor: 'pointer' }}
          >
            <div style={{ textAlign: 'center' }}>
              <i className="fa fa-download" title={i18next.t('table.downloadHelp')}></i>
              <div style={{ fontSize: 10 }}>{i18next.t('table.download')}</div>
            </div>
          </div>
          {props.user.role == 'Admin' && props.tableName && (
            <div
              onClick={() => showUpdateColumns()}
              style={{
                marginRight: 10,
                textAlign: 'center',
                marginTop: 3,
                float: 'right',
                cursor: 'pointer',
              }}
            >
              <i className="fa fa-columns" title={i18next.t('table.updateColumns')}></i>
              <div style={{ fontSize: 10 }}>{i18next.t('table.columns')}</div>
            </div>
          )}
          {props.user.role == 'Admin' &&
            (props.fetch || props.refresh) && (
              <div
                onClick={() => refreshRows()}
                style={{
                  marginRight: 10,
                  textAlign: 'center',
                  marginTop: 3,
                  float: 'right',
                  cursor: 'pointer',
                }}
              >
                <i className="fa fa-refresh" title={i18next.t('table.refreshHelp')}></i>
                <div style={{ fontSize: 10 }}>{i18next.t('table.refresh')}</div>
              </div>
            )}
        </div>
      )}

      <div
        className="ag-theme-balham"
        style={{
          height: props.autoHeight ? 'initial' : props.height ? props.height : '80vh',
          width: '100%',
          marginBottom:
            props.marginBottom == null ? 10 : props.marginBottom,
        }}
      >
        {agTable}
      </div>

    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    internal_client_id: state.users.user
      ? state.users.user.client.internal_client_id
      : null,
    user: state.users.user,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(FTOTable)

/**
 * Renderer for any cells that define their own cell renderer.
 */
const CellRenderer = ({ colDef, value, valueFormatted, data }) => {
  // Pass the row data and the row id into the Cell.
  const col = colDef.cellRendererParams.column
  return data
    ? col.cellRenderer(
      valueFormatted || valueFormatted == ''
        ? valueFormatted
        : value,
      data
    )
    : null
}

/**
 * Editor for any cells that define their own cell editor.
 */
const CellEditor = forwardRef(({ colDef, value, data }, _ref) => {
  const [newValue, setNewValue] = useState(value)
  const refInput = useRef(null)

  useEffect(() => {
    // focus on the input
    if (refInput.current) {
      refInput.current.focus()
    }
  }, [])

  /* Component Editor Lifecycle methods */
  useImperativeHandle(_ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return newValue
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        return false
      }
    }
  })

  // Pass the row data and the row id into the Cell.
  const col = colDef.cellEditorParams.column
  return data
    ? col.cellEditor(
      newValue,
      data,
      setNewValue,
      refInput
    )
    : null
})
