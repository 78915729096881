import Stack from './Stack'

const ButtonRow = (props) => {
  const defaultProps = {
    direction: "row",
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { ...buttonRowProps } = revisedProps
  return (
    <Stack {...buttonRowProps} />
  )
}

export default ButtonRow
