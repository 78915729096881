import request from "./request";

// forecast
export function getDeferredResponse(run_id, callback) {
  request('/api/actions/' + run_id + '/response').then(response => {
	  if (response == null) {
		setTimeout(() => getDeferredResponse(run_id, callback), 3000);
	  } else {
		callback(response);
	  }
  })
}
