import _ from "lodash"
import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchDimensionFilters } from '../api/dimensions'
import { convertName } from '../utilities/formatting'
import Button from './widgets/Button'
import ButtonRow from './widgets/ButtonRow'
import Section from './widgets/Section'
import Stack from './widgets/Stack'
import PullDown from './widgets/PullDown'
import RadioButtons from './widgets/RadioButtons'
import RadioButton from './widgets/RadioButton'
import { MULTISELECT, RANGE } from './CodeEditor'
import FTNotice from './FTNotice'
import i18next from 'i18next'
import { Box } from '@mui/material'

export class DataConfiguration extends Component {
  /*
    Enable user to choose which data to view.
    Properties:
      dimension: if null, don't select dimension.  if not null, default selected dimension to focus on.
      filterValues: initial values (if missing for a field, will default to all)
      filtersToInclude: filters to include (defaults to all).  ex. ['engines', 'dimensions']
      hideSubfilters: if true, hide subfilters like Broad vs Exact
      change: function to call when the values change (receives the new values)
      title: function to call when the title changes (a description of the filters)
      attachToSideNav: whether it should be part of the side nav (defaults to true)
  */

  static defaultProps = {
    dimension: null,
    filterValues: {},
    filtersToInclude: { channels: null, engines: null, entity_types: null, criteria_types: null, devices: null, dimensions: null },
    change: function () { },
    title: null,
    attachToSideNav: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      dimension: this.props.dimension,
    }
  }

  componentDidMount() {
    this.getFilters()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.filtersToInclude) != JSON.stringify(prevProps.filtersToInclude)) {
      this.getFilters()
    }
  }

  getFilters() {
    const filters = Object.keys(this.props.filtersToInclude).filter(f => this.props.filtersToInclude[f] == null)
    filters.slice().forEach(filter => {
      if (filter.startsWith('dim_value_') && filters.indexOf('dimensions') == -1) {
        filters.push('dimensions')
      }
    })
    fetchDimensionFilters(this.props.internal_client_id, filters).then(response => {
      this.setState(this.getDefaultState(this.props, response), () => {
        this.updateTitle()
        this.sendUpdate()
      })
    })
  }

  getDefaultState(props, filters) {
    const extra_filters = Object.keys(this.props.filtersToInclude).filter(f => this.props.filtersToInclude[f] != null).map(f => this.props.filtersToInclude[f])
    extra_filters.forEach(f => {
      if (f.type == MULTISELECT && f.allValues == null) {
        if (f.value == null || f.value == '') {
          f.allValues = []
        } else {
          f.allValues = f.value.split(',').map(v => v.trim())
        }
      }
    })
    extra_filters.sort((a, b) => a.name.localCompare(b.name))
    const values = { filters, extra_filters }
    filters.forEach((filter) => {
      if (props.filterValues[filter.id]) {
        // values have been overridden, but might not be valid for this client
        const validValues = []
        props.filterValues[filter.id].forEach(value => {
          if (filter.values.indexOf(value) >= 0) {
            validValues.push(value)
          }
        })
        values[filter.id] = validValues.length ? validValues : filter.values
      } else {
        values[filter.id] = filter.values
      }
      if (filter.subfilters) {
        filter.subfilters.forEach((subfilter) => {
          values[subfilter.id] = props.filterValues[subfilter.id] ? props.filterValues[subfilter.id] : subfilter.values
        })
      }
    })
    filters.map(filter => {
      const options = []
      {
        filter.values.map(value => {
          options.push({ id: filter.id, value, filter })
          if (filter.subfilters && !this.props.hideSubfilters) {
            filter.subfilters.forEach(subfilter => {
              if (subfilter.value == value) {
                subfilter.values.forEach(subvalue => {
                  options.push({
                    id: subfilter.id,
                    value: subvalue,
                    filter,
                    subfilter,
                    parent: value,
                    indent: true,
                  })
                })
              }
            })
          }
        })
      }
      filter.options = options
    })
    return values
  }

  updateTitle() {
    if (this.props.title) {
      this.props.title(this.getTitle(), this.getDimension())
    }
  }

  sendUpdate() {
    const settings = this.getDimensionAndFilters()
    this.props.changed(settings.filters, settings.dimension, settings.fullValues, this.state.extra_filters)
    this.updateTitle()
  }

  getDimensionAndFilters() {
    const values = {}
    const fullValues = {}
    this.state.filters.forEach(filter => {
      if (this.includeFilter(filter)) {
        fullValues[filter.id] = this.state[filter.id]
        if (!this.isEqual(filter.values, this.state[filter.id])) {
          values[filter.id] = this.state[filter.id]
        }
      }
      if (filter.subfilters) {
        filter.subfilters.forEach(subfilter => {
          if (this.includeSubfilter(filter, subfilter)) {
            fullValues[subfilter.id] = this.state[subfilter.id]
            if (!this.isEqual(subfilter.values, this.state[subfilter.id])) {
              values[subfilter.id] = this.state[subfilter.id]
            }
          }
        })
      }
    })
    return { filters: values, dimension: this.state.dimension, fullValues: fullValues }
  }

  getTitle() {
    const aspects = []
    this.state.filters.forEach((filter) => {
      if (this.includeFilter(filter) && !this.isEqual(filter.values, this.state[filter.id])) {
        aspects.push(this.getAspect(this.state[filter.id]))
      }
      if (filter.subfilters) {
        filter.subfilters.forEach((subfilter) => {
          if (this.includeSubfilter(filter, subfilter) && !this.isEqual(subfilter.values, this.state[subfilter.id])) {
            aspects.push(this.getAspect(this.state[subfilter.id]))
          }
        })
      }
    })
    const metrics = i18next.t('dataConfiguration.metrics')
    return aspects.length == 0 ? metrics : aspects.join(', ') + ' ' + metrics
  }

  getDimension() {
    if (this.state.dimension) {
      let name = convertName(this.state.dimension.substring(0, this.state.dimension.length - 1))
      this.state.filters.forEach(filter => {
        if (filter.id == this.state.dimension) {
          name = filter.name
        }
      })
      return name
    } else {
      return null
    }
  }

  isEqual(values1, values2) {
    values1 = values1.slice()
    values2 = values2.slice()
    values1.sort()
    values2.sort()
    return _.isEqual(values1, values2)
  }

  getAspect(values) {
    const and = i18next.t('dataConfiguration.and')
    let aspect = ''
    for (let i = 0; i < values.length; i++) {
      const value = convertName(values[i])
      if (i == 0) {
        aspect += value
      } else if (i == values.length - 1) {
        aspect += ' ' + and + ' ' + value
      } else {
        aspect += ', ' + value
      }
    }
    return aspect
  }

  apply() {
    if (this.validate()) {
      this.sendUpdate()
    }
  }

  validate() {
    if (this.state.extra_filters) {
      const invalid = this.state.extra_filters.filter(f => f.type == RANGE && (f.min == '' || f.max == '')).map(f => f.name).join(', ')
      if (invalid.length) {
        FTNotice(i18next.t("dataConfiguration.invalidValues", { fields: invalid }), 15000)
        return false
      }
    }
    return true
  }

  cancel() {
    this.setState(this.getDefaultState(this.props, this.state.filters))
  }

  value(attr, value) {
    return this.state[attr].indexOf(value) != -1
  }

  selectAll(filter) {
    const state = {}
    state[filter.id] = []
    filter.values.forEach(value => {
      state[filter.id].push(value)
    })
    this.setState(state)
  }

  clearAll(filter) {
    const state = {}
    state[filter.id] = []
    this.setState(state)
  }

  includeFilter(filter) {
    return Object.keys(this.props.filtersToInclude).indexOf(filter.id) >= 0 ||
      (filter.id.startsWith('dim_value_') && Object.keys(this.props.filtersToInclude).indexOf('dimensions') >= 0)
  }

  includeSubfilter(filter, subfilter) {
    return this.state[filter.id].indexOf(subfilter.value) >= 0 && this.includeFilter(subfilter)
  }

  renderExtra() {
    // can be overriden by children
    return null
  }

  renderSave() {
    // can be overriden by children
    return null
  }

  renderFilters() {
    const filters = this.state.filters.map((filter, i) => this.renderFilter(filter, i))
    if (this.state.dimension) {
      return (
        <RadioButtons value={this.state.dimension} onChange={event => this.setState({ dimension: event.target.value })}>{filters}</RadioButtons>
      )
    } else {
      return filters
    }
  }

  renderFilter(filter, i) {
    if (this.includeFilter(filter) && filter.values.length) {
      const options = filter.options.filter(o => o.subfilter == null || this.includeSubfilter(o.filter, o.subfilter))
      const selected = options.filter(o => this.value(o.id, o.value))
      const sx = this.state.dimension ? { width: '195px' } : {}
      const pullDown = (
        <PullDown
          key={i}
          textFieldVariant="standard"
          isConfig={true}
          labelText={filter.name}
          options={options}
          value={selected}
          optionFunction={option => convertName(option.value)}
          optionIndentFunction={option => option.indented ? 20 : 0}
          allTagsFunction={() => filter.options.length == selected.length ? 'dataConfiguration.all' : null}
          optionTagFunction={option => (option.parent ? (convertName(option.parent) + ': ') : '') + convertName(option.value)}
          onChange={(_, values) => {
            const newState = {}
            newState[filter.id] = values.length == 0 ? filter.options.map(v => v.value) : values.map(v => v.value)
            this.setState(newState)
          }}
          sx={sx}
        />
      )
      if (this.state.dimension) {
        return (
          <RadioButton key={i} value={filter.id} labelText={pullDown} labelProps={{ sx: { marginTop: '5px' } }} />
        )
      } else {
        return pullDown
      }
    } else {
      return null
    }
  }

  handleApply() {
    this.apply()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
    )
  }

  handleCancel() {
    this.cancel()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
    )
  }

  render() {
    if (this.state.filters) {
      return (
        <div className={this.props.attachToSideNav ? 'site-menu' : ''}>
          <form style={{ marginBottom: '120px' }}>
            <Section name="dataConfiguration.filters">
              <Stack spacing={3}>
                {this.renderFilters()}
                {this.state.extra_filters && this.state.extra_filters.map((filter, i) => [MULTISELECT, RANGE].includes(filter.type) && (
                  <Box key={i}>
                    {filter.type == MULTISELECT && (
                      <PullDown
                        textFieldVariant="standard"
                        isConfig={true}
                        labelText={convertName(filter.name)}
                        options={filter.allValues}
                        value={filter.value.split(',').map(v => v.trim())}
                        optionFunction={option => convertName(option)}
                        allTagsFunction={() => filter.allValues.length == filter.value.split(',').length ? 'dataConfiguration.all' : null}
                        optionTagFunction={option => convertName(option)}
                        onChange={(_, values) => {
                          values = values.length == 0 ? filter.allValues : values
                          this.setState({ extra_filters: this.state.extra_filters.map(f =>
                            f.name == filter.name ? Object.assign({}, f, { value: values.join(',') }) : f
                        )})}} />
                    )}
                    {filter.type == RANGE && (
                      <div className="box" key={i}>
                        <input type="number" value={filter.min} style={{ display: 'inline-block', width: 100 }}
                          onChange={event => this.setState({ extra_filters: this.state.extra_filters.map(f => f.name == filter.name ? Object.assign({}, f, { min: event.target.value }) : f) })} />
                        <span style={{ marginLeft: 5, marginRight: 5 }}>{i18next.t('sqlEditor.to')}</span>
                        <input type="number" value={filter.max} style={{ display: 'inline-block', width: 100 }}
                          onChange={event => this.setState({ extra_filters: this.state.extra_filters.map(f => f.name == filter.name ? Object.assign({}, f, { max: event.target.value }) : f) })} />
                      </div>
                    )}
                  </Box>
                ))}
              </Stack>
            </Section>
            {this.renderExtra()}
            <Box display="flex" justifyContent="center" >
              <ButtonRow>
                <Button
                  buttonText="dataConfiguration.apply"
                  // onClick={() => this.apply()}
                  onClick={() => this.handleApply()}
                  className={"btn btn-default apply " + (this.props.attachToSideNav ? "toggle-nav" : "")}
                />
                <Button
                  color="secondary"
                  buttonText="dataConfiguration.cancel"
                  onClick={() => this.handleCancel()}
                  className={"btn btn-default cancel toggle-nav"}
                />
                {this.renderSave()}
              </ButtonRow>
            </Box>
          </form>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default connect(mapStateToProps)(DataConfiguration)