import _ from "lodash";
import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FTNotice from './FTNotice';
import OrderedList from './widgets/OrderedList';

/*
Select from a list of possible columns and set sort order:
columns - List of columns (ex., [{column_id: 'name', name: 'Name', shown: true, sort_order: 1}])
action - function to be called on completion (takes a parameter which is the updated columns if confirmed, null otherwise.
*/

export default class SelectColumns extends Component {
  constructor(props) {
    super(props);
    
    this.state = this.getColumnState();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.columns, prevProps.columns)) {
      this.setState(this.getColumnState());
    }
  }

  getColumnState() {
    /**
     * Copy the columns so that we can cancel.
     */
    const columns = [];
    this.props.columns.forEach((column) => {
      columns.push(Object.assign({}, column));
    });
    return {
      columns: columns,
    };
  }

  save() {
    let count = 0;
    this.state.columns.forEach((column) => {
      if (column.shown) {
        count++;
      }
    });
    if (count) {
      this.props.action(this.state.columns);
    } else {
      FTNotice('You must select at least one column.', 3000);
    }
  }

  getColumns() {
    return [{
      id: 'order',
      header: 'Order',
      rowDrag: true,
      width: 70,
    },{
      id: 'name',
      header: 'Name',
      width: 175,
    },{
      header: 'Show',
      cellRenderer: (_, row) => {
        return (
          <div>
            <input type="checkbox" defaultChecked={row.shown} onChange={() => row.shown = !row.shown}/>
          </div>
        )
      },
      width: 58,
    }];
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={() => this.props.action(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><b>Update Columns</b></DialogTitle>
        <DialogContent>
          <div style={{width: 300}}>
            <OrderedList
              autoHeight={true}
              formatted={false}
              items={this.state.columns}
              columns={this.getColumns()}
              hideExport={true}
              updateItemOrder={(column, order) => column.sort_order=order}
            />
          </div>
        </DialogContent>
        <div className="text-center" style={{marginBottom: 20}}>
          <button className="btn btn-primary" onClick={() => this.save()}>OK</button>
          <button className="btn btn-primary" onClick={() => this.props.action(null)} style={{marginLeft: 10}}>Cancel</button>
        </div>
      </Dialog>
    );
  }
}
