import React from 'react'
import { Box } from '@mui/material'
import TitleIcon from '@mui/icons-material/Title'
import ExcludeFromBidding from './ExcludeFromBidding'
import HasControl from './HasControl'
import SelectionMethod from './SelectionMethod'
import Stack from '../widgets/Stack'
import Section from '../widgets/Section'
import i18next from 'i18next'
import AbTestDatePicker from './AbTestDatePicker'
import Button from '../widgets/Button'
import FtoTextField from '../widgets/TextField'
import Switch from '../widgets/Switch'

const AbTestSettingsInputs = (props) => {
  return (
    <Stack width="100%">
      <Box sx={{
        display: "flex",
        flexDirection: "column"
      }}>
        <FtoTextField variant="outlined" label={i18next.t('abtest.edit.name')}
          value={props.title}
          onChange={event => props.onUpdate({ title: event.target.value })}
          startAdornmentIcon={<TitleIcon />}
        />
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column"
      }}>
        <FtoTextField variant="outlined" label={i18next.t('abtest.edit.description')}
          value={props.description}
          multiline
          onChange={event => props.onUpdate({ description: event.target.value })}
          startAdornmentIcon={<TitleIcon />}
        />
        <AbTestDatePicker startDate={props.startDate} endDate={props.endDate} onUpdate={props.onUpdate} />
        <Box sx={{ mt: 3 }}>
          <SelectionMethod selectionMethod={props.selectionMethod} setSelectionMethod={props.setSelectionMethod} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <ExcludeFromBidding excludeFromBidding={props.excludeFromBidding} onUpdate={props.onUpdate} />
        </Box>
        <Box sx={{ mt: 3, mb: 3 }}>
          <HasControl hasControl={props.hasControl} setHasControl={props.setHasControl} />
        </Box>
        <Switch checked={props.google_ab_test} onChange={event => props.onUpdate({google_ab_test: event.target.checked })} label="abtest.edit.useGoogleAbTest"></Switch>
        <Stack justifyContent={"center"} spacing={3} direction="row">
          <Button
            buttonText={props.id == 'new' ? 'abtest.edit.addAbTest' : 'abtest.edit.updateAbTest'}
            color="primary"
            onClick={props.save} />
          {props.id != 'new' && (
            <Button buttonText="abtest.edit.deleteAbTest" color="primary" onClick={props.delete} />
          )}
          <Button buttonText="abtest.edit.cancel" color="secondary" onClick={props.cancel} />
        </Stack>
      </Box>
    </Stack>
  )
}

const AbTestSettings = (props) => {
  return (
    <Section name={i18next.t('abtest.edit.settings')} width="100%">
      {<AbTestSettingsInputs {...props} />}
    </Section>
  )
}

export default AbTestSettings