const styles = {
  container: {
    display: 'table',
    width: '100%',
    backgroundColor: '#800020',
    color: 'white',
    borderCollapse: 'collapse',
  },
  mainRow: {
    display: 'table-row',
    borderBottom: '1px solid white',
  },
  row: {
    display: 'table-row',
  },
  header: {
    display: 'table-cell',
    padding: 5,
    textDecoration: 'underline',
  },
  cell: {
    display: 'table-cell',
    padding: 5,
    verticalAlign: 'middle'
  },
}

export default styles
