import request from './request';
import querystring from 'querystring';
import { getDeferredResponse } from './actions.js'; 

// forecast
export function forecast(internalClientId, startDate, endDate, budget, callback) {
  const data = {
	internal_client_id: internalClientId,
	start_date: startDate,
	end_date: endDate,
	budget: budget
  };
  request('/api/forecast?' + querystring.stringify(data)).then(response => {
	  getDeferredResponse(response.run_id, callback);
  });
}
