import request from './request'
import querystring from 'querystring'

export const url = '/api/users/current';

// admin only
export function getUsers(internal_client_id, table_data) {
  return request('/api/users?internal_client_id=' + internal_client_id + "&" + querystring.stringify(table_data))
}

// admin only
export function getUser(userId) {
  return request(`/api/users/${userId}`);
}

// admin only
export function createUser(data) {
  return request('/api/users', {}, 'POST', data);
}

// admin only unless self
export function updateUser(data) {
  return request(`/api/users/${data.id}`, {}, 'PUT', data);
}

// admin only unless self
export function patchUser(data) {
  return request(`/api/users/${data.id}`, {}, 'PATCH', data);
}

// admin only
export function deleteUser(userID) {
  return request(`/api/users/${userID}`, {}, 'DELETE');
}

// anyone
export function getCurrentUser() {
  return request(`${url}`);
}
