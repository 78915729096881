import request from './request'

// ai_infrastructure
export function getDescriptions() {
  return request('/api/ai_infrastructure')
}

// ai_infrastructure
export function updateDescription(data) {
  return request(`/api/ai_infrastructure`, {}, 'POST', data)
}

// ai_infrastructure
export function getElementHistory(element){
  return request(`/api/ai_infrastructure/element_history?element=` + element)
}

// ai_infrastructure
export function getSampleData(element){
  return request(`/api/ai_infrastructure/sample_data?element=` + element)
}

// all users
export function getAiSummary(params) {
  return request(`/api/ai/summary`, {}, 'POST', params)
}
