import { Box, Typography } from '@mui/material'

// props.description: arbitratry description with formatting controlled by the typography property in by theme.js 

const Description = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const { fontSize, ...typographyProps } = revisedProps
  return (
    <Box>
      <Typography {...typographyProps}>
        {props.description}
      </Typography>
    </Box>
  )
}

export default Description