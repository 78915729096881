import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DeleteButton from '../DeleteButton'
import FTNotice from '../FTNotice'
import Table from '../widgets/Table'
import Form from '../Form'
import Field from '../Field'
import Confirm from '../Confirm'
import './clients.css'
import { TIMEZONES } from '../../utilities/Constants'
import RequestData from '../RequestData'
import { getClient, createClient, updateClient, getPermissions, updatePermissions } from '../../api/clients'
import { getAlgos } from '../../api/bidding'
import Permissions from './Permissions'
import i18next from 'i18next'
import { getUser } from '../../redux/users/sagaActions'
import { getSlackChannels } from '../../api/slack'

class Client extends Component {
  OUR_DATABASE = "OUR_DATABASE"
  OUR_INSTANCE = "OUR_INSTANCE"
  CLIENT_INSTANCE = "CLIENT_INSTANCE"
  DEFAULT_PERMISSIONS = [
      'exec_summary', 'ab_tests', 'bidding_changes', 'budget', 'comparison', 'dashboards', 'dimensions', 'forecast', 'performance', 'projects',
      'integrations',
      'links', 'profile'
  ]
  GA_CHANNEL = "GA_CHANNEL"
  UTM_SOURCE = "UTM_SOURCE"

  tabs = [
    {id: 'settings', name: i18next.t('client.settings')},
    {id: 'permissions', name: i18next.t('client.permissions')}
  ]

  constructor(props) {
    super(props)
    this.state = {
      client: null,
      database: this.OUR_DATABASE,
      confirmDeactivate: false,
      confirmCreateDatabase: false,
      tabIndex: 0,
      addingCategory: false,
      addingBusinessCategory: false,
      addingPageType: false,
      permissions: this.DEFAULT_PERMISSIONS,
      slackChannels: [],
    }
    for(let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].id == props.match.params.tab) {
        this.state.tabIndex = i
        break
      }
    }
    this.getAlgos()
    if (props.match.params.id != null) {
      this.getClient()
    } else {
      this.state.client = {
        name: '',
        active: true,
        keyword_classification: true,
        use_engine_revenue: true,
        jira_filter: '',
        automated_bidding: this.BIDDING_DISABLED,
        algos: [],
        time_tasks: [{
          name: 'Programming',
        }, {
          name: 'SEM',
        }, {
          name: 'SEO',
        }],
        url: '',
        default_crawler: true,
        redshift_host: null,
        redshift_port: '5439',
        redshift_db: null,
        redshift_username: null,
        redshift_password: null,
        redshift_proxy_host: null,
        redshift_proxy_username: null,
        slack_channel_id: '',
        slack_channel_name: '',
        analytics_source: this.GA_CHANNEL,
        analytics_categories: [],
        analytics_page_types: [],
        conversion_duration: 14,
      }
      this.state.previouslyActive = false
    }
  }
  
  componentDidMount() {
    getSlackChannels(this.props.internal_client_id).then(channels => {
      this.setState({slackChannels: channels.filter(channel => this.props.isAdmin || !channel.name.startsWith('admin: '))})
    })
  }

  getClient() {
    getClient(this.props.match.params.id).then((client) => {
      client.port = client.port || '5439'
      const database = client.redshift_db == null ? this.OUR_DATABASE : client.redshift_host == null ? this.OUR_INSTANCE : this.CLIENT_INSTANCE
      this.setState({client, database, previouslyActive: client.active})
    })
    getPermissions(this.props.match.params.id).then(permissions => {
      this.setState({permissions: permissions})
    })
  }
  
  getAlgos() {
    getAlgos(this.props.internal_client_id).then((algos) => {
      const filtered = []
      algos.results.forEach((algo) => {
        if (algo.output_type == 'prelim_bids') {
          filtered.push(algo)
        }
      })
      this.setState({algos: filtered})
    })
  }

  handleChange(event) {
    const formData = Object.assign({}, this.state.client)

    if(event.target.type === 'checkbox') {
      formData[event.target.name] = event.target.checked
    } else {
      formData[event.target.name] = event.target.value
    }
    this.setState({client: formData})
  }

  submitChanges(event) {
    event.preventDefault()
    const client = this.state.client
    if (client.name.trim() == '') {
      FTNotice('client.enterName', 15000)
    } else if (!client.time_zone) {
      FTNotice('client.enterTimeZone', 15000)
    } else if (!client.active && client.active != this.state.previouslyActive) {
      this.setState({confirmDeactivate: true})
    } else {
      if (this.state.database == this.OUR_INSTANCE) {
        if (!client.redshift_db) {
          FTNotice('client.enterDatabase', 15000)
          return
        }
      } else if (this.state.database == this.CLIENT_INSTANCE) {
        if (!client.redshift_host) {
          FTNotice('client.enterHost', 15000)
          return
        }
        if (!client.redshift_port) {
          FTNotice('client.enterPort', 15000)
          return
        }
        if (!client.redshift_db) {
          FTNotice('client.enterDatabase', 15000)
          return
        }
        if (!client.redshift_username) {
          FTNotice('client.enterUsername', 15000)
          return
        }
        if (!client.redshift_password) {
          FTNotice('client.enterPassword', 15000)
          return
        }
        if (client.redshift_proxy_username && !client.redshift_proxy_host) {
          FTNotice('client.enterSSHProxyHost', 15000)
          return
        }
        if (client.redshift_proxy_host && !client.redshift_proxy_username) {
          FTNotice('client.enterSSHProxyUsername', 15000)
          return
        }
        if (!client.conversion_duration) {
          FTNotice('client.enterConversionDuration', 15000)
          return
        }
      }
      this.submit(true)
    }
  } 
  
  submitCreateClient(client) {
    createClient(client).then((response) => {
      if (response == null || response.error) {
        FTNotice(i18next.t('client.couldNotBeCreated', {error: response ? response.error : i18next.t('client.unknownError')}), 15000)
      } else {
        updatePermissions(response.internal_client_id, this.state.permissions).then(response => {
          if (response == null || response.error) {
            FTNotice(i18next.t('client.permissionsCouldNotBeUpdated', {error: response ? response.error : i18next.t('client.unknownError')}), 15000)
          } else {
            if (client.redshift_db != null) {
              FTNotice('client.waitForClientToBeCreated')
            }
            window.location.href = '/#/clients'
          }
        })
      }
    })
  }

  submit(confirmed) {
    if (confirmed) {
      this.setState({confirmDeactivate: false}, () => {
          const client = Object.assign({}, this.state.client)
          if (this.state.database == this.OUR_DATABASE) {
            client.redshift_host = null
            client.redshift_port = null
            client.redshift_db = null
            client.redshift_username = null
            client.redshift_password = null
            client.redshift_proxy_host = null
            client.redshift_proxy_username = null
          } else if (this.state.database == this.OUR_INSTANCE) {
            client.redshift_host = null
            client.redshift_port = null
            client.redshift_username = null
            client.redshift_password = null
            client.redshift_proxy_host = null
            client.redshift_proxy_username = null
          }
          client.slack_channel_id = client.slack_channel_id ? client.slack_channel_id : null
          client.slack_channel_name = client.slack_channel_name ? client.slack_channel_name : null
          if (client.url && client.url.toLowerCase().startsWith('https://')) {
            client.url = client.url.substring(8)
          }
          if (this.state.client.internal_client_id != null) {
            updateClient(client).then((response) => {
              if (response == null || response.error) {
                FTNotice(i18next.t('client.couldNotBeUpdated', {error: response ? response.error : i18next.t('client.unknownError')}), 15000)
              } else {
                updatePermissions(this.state.client.internal_client_id, this.state.permissions).then(response => {
                  if (response == null || response.error) {
                    FTNotice(i18next.t('client.permissionsCouldNotBeUpdated', {error: response ? response.error : i18next.t('client.unknownError')}), 15000)
                  } else {
                    this.props.dispatch(getUser()) // In case it is the current client
                    window.location.href = '/#/clients'
                  }
                })
              }
            })
          } else {
            if (client.redshift_db != null) {
              client.redshift_db = client.redshift_db.toLowerCase()
              this.setState({confirmCreateDatabase: true, createAction: () => this.submitCreateClient(client)})
            } else {
              this.submitCreateClient(client)
            }
          }
        })
    } else {
      this.setState({confirmDeactivate: false})
    }
  }

  getTimeTrackingColumns() {
    return [{
      id: 'name',
      datatype: 'text'
    },{
      cellRenderer: (_, row) => ( 
        <DeleteButton
          objectType=""
          objectName={row.name}
          deleteObject={() => this.deleteTimeTrackingCategory(row)}/>
      ),
      width:100,
    }]
  }

  getAnalyticsColumns(deleteFunction) {
    return [{
      id: 'name',
      datatype: 'text'
    },{
      id: 'search_string',
      header: 'client.searchString',
      datatype: 'text'
    },{
      cellRenderer: (_, row) => ( 
        <DeleteButton
          objectType=""
          objectName={row.name}
          deleteObject={() => deleteFunction.call(this, row)}/>
      ),
      width:100,
    }]
  }

  renderTimeTracking() {
    return (
      <Field label={i18next.t('client.timeTrackingCategories')}>
        <div>
          <button className="btn btn-primary" style={{marginBottom: 10}} onClick={event => {
            event.preventDefault()
            this.setState({addingCategory: true})
          }}>{i18next.t('client.addCategory')}</button>
        </div>
        <RequestData
          opened={this.state.addingCategory}
          title={i18next.t('client.addTimeTrackingCategory')}
          fields={[{id: 'name', label: i18next.t('client.name'), defaultValue: '', required: true}]}
          action={data => this.finishAddingTimeTrackingCategory(data)}/>
        <Table
          dataset={this.state.client.time_tasks}
          columns={this.getTimeTrackingColumns()}
          rowHeight={40}
          autoHeight={true}
          hidePagination={true}
          hideExport={true} />
      </Field>
    )
  }

  renderAnalytics() {
    return (
      <>
        <Field label="client.analyticsSource">
          <select
            className="form-control" name='analytics_source'
            value={this.state.client.analytics_source}
            onChange={event => this.handleChange(event)}>
              <option value={this.GA_CHANNEL}>{i18next.t('client.gaChannel')}</option>
              <option value={this.UTM_SOURCE}>{i18next.t('client.utmSource')}</option>
          </select>
        </Field>
        <Field label={i18next.t('client.businessCategories')}>
          <div>
            <button className="btn btn-primary" style={{marginBottom: 10}} onClick={event => {
              event.preventDefault()
              this.setState({addingBusinessCategory: true})
            }}>{i18next.t('client.addCategory')}</button>
          </div>
          <RequestData
            opened={this.state.addingBusinessCategory}
            title={i18next.t('client.addBusinessCategory')}
            fields={[
              {id: 'name', label: i18next.t('client.name'), defaultValue: '', required: true},
              {id: 'search_string', label: i18next.t('client.searchStringExpanded'), defaultValue: '', required: true},
            ]}
            action={data => this.finishAddingBusinessCategory(data)}/>
          <Table
            dataset={this.state.client.analytics_categories}
            columns={this.getAnalyticsColumns(this.deleteAnalyticsCategory)}
            rowHeight={40}
            autoHeight={true}
            hidePagination={true}
            hideExport={true} />
        </Field>
        <Field label={i18next.t('client.pageTypes')}>
          <div>
            <button className="btn btn-primary" style={{marginBottom: 10}} onClick={event => {
              event.preventDefault()
              this.setState({addingPageType: true})
            }}>{i18next.t('client.addType')}</button>
          </div>
          <RequestData
            opened={this.state.addingPageType}
            title={i18next.t('client.addPageType')}
            fields={[
              {id: 'name', label: i18next.t('client.name'), defaultValue: '', required: true},
              {id: 'search_string', label: i18next.t('client.searchString'), defaultValue: '', required: true},
            ]}
            action={data => this.finishAddingPageType(data)}/>
          <Table
            dataset={this.state.client.analytics_page_types}
            columns={this.getAnalyticsColumns(this.deleteAnalyticsPageType)}
            rowHeight={40}
            autoHeight={true}
            hidePagination={true}
            hideExport={true} />
        </Field>
      </>
    )
  }

  deleteTimeTrackingCategory(category) {
    const categories = this.state.client.time_tasks.slice()
    categories.splice(categories.indexOf(category), 1)
    this.state.client.time_tasks = categories
    this.setState({client: this.state.client})
  }

  deleteAnalyticsCategory(category) {
    const categories = this.state.client.analytics_categories.slice()
    categories.splice(categories.indexOf(category), 1)
    this.state.client.analytics_categories = categories
    this.setState({client: this.state.client})
  }

  deleteAnalyticsPageType(pageType) {
    const pageTypes = this.state.client.analytics_page_types.slice()
    pageTypes.splice(pageTypes.indexOf(pageType), 1)
    this.state.client.analytics_page_types = pageTypes
    this.setState({client: this.state.client})
  }

  finishAddingTimeTrackingCategory(category) {
    if (category == null) {
      this.setState({addingCategory: false})
    } else {
      const categories = this.state.client.time_tasks.slice()
      categories.push(category)
      categories.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name))
      this.state.client.time_tasks = categories
      this.setState({client: this.state.client, addingCategory: false})
    }
  }

  finishAddingBusinessCategory(category) {
    if (category == null) {
      this.setState({addingBusinessCategory: false})
    } else {
      const categories = this.state.client.analytics_categories.slice()
      categories.push(category)
      categories.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name))
      this.state.client.analytics_categories = categories
      this.setState({client: this.state.client, addingBusinessCategory: false})
    }
  }

  finishAddingPageType(pageType) {
    if (pageType == null) {
      this.setState({addingPageType: false})
    } else {
      const pageTypes = this.state.client.analytics_page_types.slice()
      pageTypes.push(pageType)
      pageTypes.sort((pt1, pt2) => pt1.name.localeCompare(pt2.name))
      this.state.client.analytics_page_types = pageTypes
      this.setState({client: this.state.client, addingPageType: false})
    }
  }

  updateTab(tabIndex) {
    this.props.history('/clients/' + (this.state.client.internal_client_id == null ? 'new' : this.state.client.internal_client_id) + '/' + this.tabs[tabIndex].id)
    this.setState({tabIndex: tabIndex})
  }

  updateSlackChannel(slackChannelId) {
    const client = Object.assign({}, this.state.client)
    client.slack_channel_id = slackChannelId
    client.slack_channel_name = ''
    this.state.slackChannels.forEach(channel => {
      if (channel.id == slackChannelId) {
        client.slack_channel_name = channel.name
      }
    })
    this.setState({client})
  }

  filterInteger(event) {
    event.target.value = event.target.value.replace(/[^0-9]/g,'')
  }

  render() {
    const shouldRenderForm = this.state.client != undefined
    return (
      <div>
        { shouldRenderForm &&
          <div>
            <Form
              objectType={i18next.t('client.client')}
              objectName={this.state.client.name}
              newObject={this.state.client.internal_client_id == undefined}
              updateObject={(event) => this.submitChanges(event)}
              canDelete={false}
              parentLink={'/clients'}
            >
              <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.updateTab(tabIndex)}>
                <div className="col-md-12 mt-25">
                  <div className="col-md-2"></div>
                  <div className="col-md-7">
                    <TabList>
                      {this.tabs.map(tab => {
                        return (
                          <Tab key={tab.id}>
                            {tab.name}
                          </Tab>
                        )
                      })}
                    </TabList>
                  </div>
                </div>
                <TabPanel key="settings">
                  <Confirm
                    opened={this.state.confirmDeactivate}
                    title="client.deactiveClient"
                    question={i18next.t('client.areYouSureDeactivate', {name: this.state.client.name})}
                    action={confirmed => this.submit(confirmed)} />
                  <Confirm
                    opened={this.state.confirmCreateDatabase}
                    title="client.createDatabase"
                    question={i18next.t('client.areYouSureCreateDatabase', {name: this.state.client.redshift_db})}
                    action={confirmed => {
                      this.setState({confirmCreateDatabase: false})
                      if (confirmed) {
                        this.state.createAction()
                      }
                    }} />
                  <Field label="client.name">
                    <input
                      className="form-control" name='name'
                      value={this.state.client.name}
                      onChange={event => this.handleChange(event)} />
                  </Field>
                  { this.state.client.internal_client_id != null &&
                    <Field label="client.internalClientId">
                      <input className="form-control" name='internal_client_id' value={this.state.client.internal_client_id} readOnly={true} />
                    </Field>
                  }
                  <Field label="client.database">
                    { this.state.client.internal_client_id == null ? (
                      <>
                        <div>
                          <input type="radio" value={this.OUR_DATABASE} 
                            checked={this.state.database == this.OUR_DATABASE} onChange={event => this.setState({database: event.target.value})}/> 
                            &nbsp;{i18next.t('client.useOurRedshiftDb')}
                        </div>
                        <div>
                          <input type="radio" value={this.OUR_INSTANCE} 
                            checked={this.state.database == this.OUR_INSTANCE} onChange={event => this.setState({database: event.target.value})}/> 
                            &nbsp;{i18next.t('client.useOurRedshiftInstance')}
                        </div>
                        {this.state.database == this.OUR_INSTANCE && (
                          <div style={{height: 115, marginLeft: -150}}>
                            <Field label="client.redshiftDatabase">
                              <input
                                className="form-control" name='redshift_db'
                                value={this.state.client.redshift_db || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                          </div>
                        )}
                        <div>
                          <input type="radio" value={this.CLIENT_INSTANCE} 
                            checked={this.state.database == this.CLIENT_INSTANCE} onChange={event => this.setState({database: event.target.value})}/> 
                            &nbsp;{i18next.t('client.useClientInstance')}
                        </div>
                        {this.state.database == this.CLIENT_INSTANCE && (
                          <div style={{height: 450, marginLeft: -150}}>
                            <Field label="client.redshiftHost">
                              <input
                                className="form-control" name='redshift_host'
                                value={this.state.client.redshift_host || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <Field label="client.redshiftPort">
                              <input
                                type="number" step="1"
                                className="form-control" name='redshift_port'
                                value={this.state.client.redshift_port}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <Field label="client.redshiftDatabase">
                              <input
                                className="form-control" name='redshift_db'
                                value={this.state.client.redshift_db || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <Field label="client.redshiftUsername">
                              <input
                                className="form-control" name='redshift_username'
                                value={this.state.client.redshift_username || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <Field label="client.redshiftPassword">
                              <input
                                type="password"
                                className="form-control" name='redshift_password'
                                value={this.state.client.redshift_password || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <div style={{marginLeft: 190, paddingTop: 20, marginRight: 190, clear: 'both'}}>
                              {i18next.t('client.sshProxy')}
                            </div>
                            <Field label="client.redshiftProxyHost">
                              <input
                                className="form-control" name='redshift_proxy_host'
                                value={this.state.client.redshift_proxy_host || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                            <Field label="client.redshiftProxyUsername">
                              <input
                                className="form-control" name='redshift_proxy_username'
                                value={this.state.client.redshift_proxy_username || ''}
                                onChange={event => this.handleChange(event)} />
                            </Field>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.database == this.OUR_DATABASE && (
                          <div>
                            {i18next.t('client.useOurRedshiftDb')}
                          </div>
                        )}
                        {this.state.database == this.OUR_INSTANCE && (
                          <div>
                            {i18next.t('client.useOurRedshiftInstance')}
                            <div>{i18next.t('client.redshiftDatabase')}: {this.state.client.redshift_db}</div>
                          </div>
                        )}
                        {this.state.database == this.CLIENT_INSTANCE && (
                          <div>
                            {i18next.t('client.useClientInstance')}
                            <div>{i18next.t('client.redshiftHost')}: {this.state.client.redshift_host}</div>
                            <div>{i18next.t('client.redshiftPort')}: {this.state.client.redshift_port}</div>
                            <div>{i18next.t('client.redshiftDatabase')}: {this.state.client.redshift_db}</div>
                            <div>{i18next.t('client.redshiftUsername')}: {this.state.client.redshift_username}</div>
                            <div>{i18next.t('client.redshiftProxyHost')}: {this.state.client.redshift_proxy_host}</div>
                            <div>{i18next.t('client.redshiftProxyUsername')}: {this.state.client.redshift_proxy_username}</div>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                  <Field label="client.timeZone">
                    <select
                      className="form-control" name='time_zone'
                      value={this.state.client.time_zone || 'Please select timezone'}
                      onChange={event => this.handleChange(event)}>
                        <option key='default-option' name='default-option'>{i18next.t('client.selectTimeZone')}</option>
                      {
                        Object.keys(TIMEZONES).map(key => (<option key={key} value={key}>{TIMEZONES[key]}</option>))
                      }
                    </select>
                  </Field>
                  <Field label="client.url">
                    <input
                      className="form-control" name='url'
                      value={this.state.client.url || ''}
                      onChange={event => this.handleChange(event)} />
                  </Field>
                  <Field label="client.conversionDuration">
                    <input
                      className="form-control" name='conversion_duration'
                      value={this.state.client.conversion_duration}
                      onKeyUp={event => this.filterInteger(event)}
                      onChange={event => this.handleChange(event)} />
                  </Field>
                  {this.state.client.url && this.state.client.url.length && (
                    <Field label="client.crawlRobots">
                      <div>
                        <input
                          type="checkbox" value={this.state.client.default_crawler}
                          checked={this.state.client.default_crawler} name="default_crawler"
                          onChange={event => this.handleChange(event)} />&nbsp;{i18next.t('client.enabled')}
                      </div>
                    </Field>
                  )}
                  <Field label="client.slackChannel">
                    <select className="form-control" value={this.state.client.slack_channel_id || ''}
                      onChange={event => this.updateSlackChannel(event.target.value)}>
                      <option key="" value="">{i18next.t('client.none')}</option>
                      {
                        this.state.slackChannels.map(slackChannel => {
                          return (
                            <option key={slackChannel.id} value={slackChannel.id}>
                              {slackChannel.name}
                            </option>
                          )
                        })
                      }
                    </select>
                  </Field>
                  { this.props.license == 'all' &&
                    <Field label={i18next.t('client.jiraFilter', {label: this.state.client.name.toLowerCase().replace(/ /g, '_')})}>
                      <input
                        className="form-control" name='jira_filter'
                        value={this.state.client.jira_filter || ''}
                        onChange={event => this.handleChange(event)} />
                    </Field>
                  }
                  {this.renderTimeTracking()}
                  {this.renderAnalytics()}
                  <Field label="client.status">
                    <div>
                      <input
                        type="checkbox" value={this.state.client.active}
                        checked={this.state.client.active} name="active"
                        onChange={event => this.handleChange(event)} />&nbsp;{i18next.t('client.active')}
                    </div>
                  </Field>
                  <Field label="client.keywordClassification">
                    <div>
                      <input
                        type="checkbox" value={this.state.client.keyword_classification}
                        checked={this.state.client.keyword_classification} name="keyword_classification"
                        onChange={event => this.handleChange(event)} />&nbsp;{i18next.t('client.enabled')}
                    </div>
                  </Field>
                  <Field label="client.useEngineRevenue">
                    <div>
                      <input
                        type="checkbox" value={this.state.client.use_engine_revenue}
                        checked={this.state.client.use_engine_revenue} name="use_engine_revenue"
                        onChange={event => this.handleChange(event)} />&nbsp;{i18next.t('client.enabled')}
                    </div>
                  </Field>
                </TabPanel>
                <TabPanel key="permissions">
                  <Permissions permissions={this.state.permissions} updatePermissions={permissions => this.setState({permissions: permissions})} />
                </TabPanel>
              </Tabs>
            </Form>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    license: state.users.user ? state.users.user.license : null,
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Client))
