import _ from "lodash"
import { Component } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getModel, updateModel, getPending } from '../../api/data_science'
import { getSQLTemplates } from  '../../api/sql_templates'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FTNotice from '../FTNotice'
import SQLEditor from '../SQLEditor'
import PythonEditor from '../PythonEditor'
import Form from '../Form'
import Field from '../Field'
import i18next from 'i18next'

class ModelQuery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.id,
      type: props.match.params.type,
      name: '',
      predictionColumn: '',
      sql: '',
      reshape: '',
      templates: [],
      template: '',
      pending: false,
    }
  }

  componentDidMount() {
    getModel(this.state.id).then(model => {
      this.setState({
        name: model.name,
        predictionColumn: model.prediction_column,
        sql: this.state.type == 'training' ? model.training_sql : model.inferencing_sql,
        reshape: model.reshape,
      })
    })
    getSQLTemplates().then(response => this.setState({templates: response.results}))
  }

  returnToModel() {
    window.location.href = `/#/data_science/${this.state.id}`
  }

  getResult = (guid) => {
    getPending(guid).then((result) => {
      if (result.pending) {
        setTimeout(() => this.getResult(guid), 5000)
      } else if (result.error) {
        this.setState({pending: false})
        FTNotice(result.error, 15000)
      } else {
        this.setState({pending: false})
        this.returnToModel()
      }
    })
  }
    
  save() {
    if (this.state.sql.trim() == '') {
      FTNotice('dataScience.enterSql', 15000)
    } else {
      const values = {reshape: this.state.reshape}
      values[this.state.type + '_sql'] = this.state.sql
      updateModel(this.state.id, values).then(model => {
        if (model != null) {
          if (model.guid != null) {
            this.setState({pending: true}, () => this.getResult(model.guid))
          } else {
            this.returnToModel()
          }
        } else {
          FTNotice(i18next.t('dataScience.errorUpdating'))
        }
      })
    }
  }

  handleSelectTemplate(template_id) {
    this.state.templates.forEach(template => {
      if(template.id == template_id) {
        this.setState({template: template_id, sql: template.sql})
      }
    })
  }

  render() {
    const sqlName = 'dataScience.' + this.state.type + 'Sql'
    return (
      <>
        <Dialog
          open={this.state.pending}
          onClose={() => this.setState({pending: false}, () => this.returnToModel())}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18next.t('dataScience.generatingModel')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18next.t('dataScience.generatingModelDetails')}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Form
          objectType="dataScience.model"
          updateObject={() => this.save()}
          canDelete={false}
          parentLink={`/data_science/${this.state.id}`}>
          <Field label="dataScience.name">
            <input type="text" value={this.state.name} disabled={true} className="form-control"/>
          </Field>
          {this.state.type == 'training' && (
            <Field label="dataScience.predictionColumn">
              <input type="text" value={this.state.predictionColumn} onChange={event => this.setState({predictionColumn: event.target.value})} className="form-control"/>
            </Field>
          )}
          <Field label="dataScience.template">
            <select className="form-control" value={this.state.template} onChange={event => this.handleSelectTemplate(event.target.value)}>
              <option value={''}></option>
              {this.state.templates.map(template => (<option key={template.id} value={template.id} >{template.name}</option>))}
            </select>
          </Field>
          <Field label={sqlName}>
            <SQLEditor
              instructions={((this.state.type == 'training' && this.state.predictionColumn ? i18next.t('dataScience.shouldHavePrediction', {predictionColumn: this.state.predictionColumn}) : null) || (this.state.type == 'inferencing'))
                &&
                (<div style={{ paddingLeft: 30, paddingRight: 30 }}>
                  The following are examples of subsitutions that can occur in
                  your SQL:
                  <ul>
                    <li>{"internal_client_id = {internal_client_id}"}</li>
                  </ul>
                </div>)}
              code={this.state.sql}
              onChange={sql => this.setState({sql: sql})}
            />
          </Field>
          <Field label="dataScience.reshape">
            <PythonEditor
              instructions={i18next.t('dataScience.reshapeInstructions')}
              code={this.state.reshape}
              onChange={python => this.setState({reshape: python})}
            />
          </Field>
        </Form>
      </>
    )
  }
}

export default withRouter(ModelQuery)
