import React, { useState } from 'react'
import Table from '../widgets/Table'
import { previewAbTestQuery, exportAbTestQuery } from '../../api/ab_tests'

const PreviewResults = ({ sql, previewOpen, previewMode, preview }) => {

  const getColumns = () => {
    return [{
      id: 'account_id',
      header: 'abtest.edit.accountId',
      datatype: 'text',
      width: 110,
    }, {
      id: 'account_descriptive_name',
      header: 'abtest.edit.accountName',
      datatype: 'text',
      width: 180,
    }, {
      id: 'campaign_name',
      header: 'abtest.edit.campaignName',
      datatype: 'text',
      width: 270,
    }, {
      id: 'ad_group_id',
      header: 'abtest.edit.adGroupId',
      datatype: 'text',
      width: 150,
    }, {
      id: 'criteria_id',
      header: 'abtest.edit.criteriaId',
      datatype: 'text',
      width: 120,
    }, {
      id: 'criteria',
      header: 'abtest.edit.criteria',
      datatype: 'text',
      width: 240,
    }, {
      id: 'clicks',
      header: 'abtest.edit.clicks',
      datatype: 'numeric',
      width: 80
    }, {
      id: 'cost',
      datatype: 'numeric',
      width: 90
    }, {
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 145
    }]
  }

  if (previewOpen) {
    return (
      <Table
        fetch={tableData => previewAbTestQuery(sql, tableData)}
        export={tableData => exportAbTestQuery(sql, tableData)}
        columns={getColumns()}
        changeParams={{ previewMode }} />
    )
  } else {
    return null
  }
}

export default PreviewResults