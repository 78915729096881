/*
The Wrench opens a panel to the left (with className of site-menu)
*/

const Wrench = () => {
  return (
    <a className="btn wrench-default toggle-nav" style={{ height: 34 }}>
      <i className="fa fa-wrench" />
    </a>
  )
}

export default Wrench
