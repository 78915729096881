import { Component } from 'react';
import { connect } from 'react-redux';
import Project from './Project';
import { getActiveProjects } from '../../api/projects';

class ActiveProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      filter: '',
      projects: [],
      done: false,
    }
    this.getProjects();
  }

  getProjects() { 
    getActiveProjects({internal_client_id: this.props.internal_client_id, show_all: this.state.showAll, filter: this.state.filter}).then((projects) => {
      this.setState({projects: projects, done: true});
    });
  }

  updateShowAll() {
    this.setState({showAll: !this.state.showAll}, () => {this.getProjects()});
  }

  updateFilterKey(event) {
    if (event.key == 'Enter') {
      this.updateFilter(event.currentTarget.value);
    }
  }

  updateFilter(newValue) {
    if (newValue != this.state.filter) {
      this.setState({filter: newValue}, () => {this.getProjects()});
    }
  }
 
  render() { 
    return (
      <div className='site-wrapper-offcanvas' id="chart-hudd">
        <div className='site-canvas'>
          <section className='content'>
            <div className="projects">
              <div className="controls" style={{paddingBottom: 10}}>
                <div className="filter">
                  <span className="label">Filter</span>
                  <input onBlur={(event) => {this.updateFilter(event.target.value);}} onKeyPress={(event) => {this.updateFilterKey(event);}} />
                </div>
                <div className="showAll">
                  <input type="checkbox" defaultChecked={this.state.showAll} onChange={()=>{this.updateShowAll()}} /><span className="label">Show all</span>
                </div>
              </div>
              <div className={`box ${this.state.done ? 'js_complete' : ''}`} style={{height: 'fit-content', padding: 10, width: '100%'}}>
                <div className="column">
                  <div className="title" style={{minHeight: 30}}>To Do</div>
                  {this.state.projects.map(function(project) {
                    if (project.status == 'To Do') {
                      return (<Project key={project.id} project={project}/>);
                    }
                  })}
                </div>
                <div className="column">
                  <div className="title" style={{minHeight: 30}}>In Progress</div>
                  {this.state.projects.map(function(project) {
                    if (project.status == 'In Progress') {
                      return (<Project key={project.id} project={project}/>);
                    }
                  })}
                </div>
                <div className="column done">
                  <div className="title" style={{minHeight: 30}}>Done</div>
                  {this.state.projects.map(function(project) {
                    if (project.status == 'Done') {
                      return (<Project key={project.id} project={project}/>);
                    }
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  };
}

export default connect(mapStateToProps)(ActiveProjects);
