import { Component } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { getServiceStatus, getRunHistory } from '../../api/services'
import { getRunColumns, runStep, getStepHistory } from '../../api/workflows'
import { generateMetrics } from '../../api/bidding'
import { getDeferredResponse } from '../../api/actions'
import RunResult from './workflow/RunResult'
import Dashboard from '../dashboards/Interface'
import SQLEditor from '../SQLEditor'
import { getServiceHistory } from '../../api/services'
import { result } from 'lodash'
import i18next from 'i18next'
import { Box, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '../widgets/Button'
import NotebookInterface from './WorkflowComponents/NotebookInterface'
import StepDescriptionPopover from './WorkflowComponents/StepDescriptionPopover'
import Schedule from '../services/schedule/Run'

export default class NotebookStep extends Component {
  RUNNING = "running"
  SUCCESS = "success"
  STOPPED = "stopped"
  ERROR = "error"
  NOT_FOUND = "not_found"

  constructor(props) {
    super(props)
    const isService = this.isService()
    this.state = {
      edit: false,
      running: isService,
      result: null,
      success: true,
      callback: null,
      runHistory: null,
      run: null,
      downloadInterval: null,
      stepHistory: null,
      input_history: null,
      data: {
        data: null,
        isValid: () => { return true }
      }
    }
  }

  componentDidMount() {
    this.updateServiceStatus()
    this.getStepHistory()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.running != prevState.running && this.state.running) ||
      this.props.run_id != prevProps.run_id
    ) {
      this.updateServiceStatus()
    }
  }

  getStepHistory() {
    this.setState({ stepHistory: null })

    if (this.props.step.step_id !== undefined) {
      getStepHistory(this.props.step.step_id, this.props.original_run_id).then((response) => {
        this.setState({ stepHistory: response.result })
      })
    } else {
      getServiceHistory(this.props.internal_client_id, this.props.step.type, {
        page: 1,
        page_size: 100,
      }).then((res) => {
        for (let i in res.results) {
          result = res.results[i]
          if (result.run_id == this.props.run_id) {
            this.setState({ input_history: result.input })
            break
          }
        }
      })
    }
  }

  isService() {
    return !['sql', 'sql_dashboard', 'trigger_dag', 'generate_metrics'].includes(
      this.props.step.type
    )
  }

  updateServiceStatus() {

    if (this.isService()) {
      getRunHistory(this.props.run_id).then((history) => {
        if (this.state.downloadInterval) {
          clearInterval(this.state.downloadInterval)
        }
        import("../services/" + this.props.step.type + "/Run.jsx").then(
          (module) => {
            const Run = module.default
            const state = {
              runHistory: history,
              run: Run,
              downloadInterval: null,
            }
            if (history.input) {
              state.data = {
                data: history.input,
                isValid: () => true,
              }
            }
            this.setState(state, () => this.pollServiceStatus())
          }
        )
      })
    }
  }

  pollServiceStatus() {
    getServiceStatus(this.props.run_id).then((result) => {
      const status = result ? result.status : this.NOT_FOUND
      this.setState({
        result: status != this.NOT_FOUND ? result : null,
        running: status == this.RUNNING,
        success: status != this.ERROR && status != this.STOPPED,
      })
      if (status != this.RUNNING) {
        if (this.state.downloadInterval != null) {
          clearInterval(this.state.downloadInterval)
          this.setState({ downloadInterval: null })
        }
        if (this.state.callback) {
          const callback = this.state.callback
          this.setState({ callback: null }, () => {
            if (result.status == this.SUCCESS) {
              callback()
            }
          })
        }
      } else if (this.state.downloadInterval == null) {
        this.setState({
          downloadInterval: setInterval(() => {
            this.pollServiceStatus()
          }, 5000),
        })
      }
    })
  }

  runStep(callback = null) {
    const type = this.props.step.type
    this.setState({ edit: false, running: true })
    var run
    if (type == "sql") {
      run = this.runSqlStep(callback)
    } else if (type == "trigger_dag") {
      run = this.runTriggerDag(callback)
    } else if (type == "generate_metrics") {
      run = this.runGenerateMetrics(callback)
    } else {
      run = this.runService(callback)
    }
    if (run != null) {
      run.finally(() => this.getStepHistory())
    }
  }

  showResult(result, success = true, running = false) {
    this.setState(
      { result: result, running: running, success: success },
      () => {
        this.scrollToResult()
      }
    )
  }

  scrollToResult() {
    const result = $("#step" + this.props.step.step_id + " .run_result").get(0)
    if (result) {
      result.scrollIntoView()
    }
  }

  variablesToRecord() {
    const variables = [...this.props.variables]
    variables.splice(0, 3)
    return variables
  }

  runSqlStep(callback) {
    const step = this.props.step
    step.columns = null
    const original_step_body = step.original_step_body
    let step_body = this.removeComments(original_step_body)
    step_body = this.replaceVariables(step_body, this.props.variables)
    step.actual_sql = step_body
    const data = {
      internal_client_id: this.props.internal_client_id,
      step_id: step.step_id,
      original_step_body: original_step_body,
      step_body: step_body,
      run_id: this.props.run_id,
      workflow_id: this.props.workflow_id,
      user_id: this.props.current_user_id,
      variables: this.variablesToRecord(),
    }
    return getRunColumns(step.step_id, data).then((response) => {
      if (response?.success) {
        step.columns = response.data
        this.showResult(
          i18next.t('workflowStep.successful', { message: response.message ? response.message + '.' : response.message }),
          this.getFollowingMessage(callback) == ""
        )
      } else {
        this.showResult(response ? response.error : i18next.t('workflowStep.errorRunningSql', { error: response }), false)
      }
    })
  }

  runGenerateMetrics(callback) {
    return generateMetrics(this.props.run_id).then((response) => {
      if (response.error) {
        this.showResult(i18next.t('workflowStep.errorGeneratingMetrics', { error: response.error }), false)
      } else {
        this.showResult(i18next.t('workflowStep.generatedMetrics') + this.getFollowingMessage(callback))
      }
    })
  }

  handleDagResult(result) {
    this.showResult(
      i18next.t('workflows.dagHasCompleted') + ' ' + result.message,
      result.successful,
    )
  }

  runTriggerDag(callback) {
    const step = this.props.step
    const dag = step.step_body ? step.step_body : step.original_step_body
    const data = {
      internal_client_id: this.props.internal_client_id,
      dag: dag,
      run_id: this.props.run_id,
      step_body: dag,
      variables: this.variablesToRecord(),
    }
    return runStep(step.step_id, "trigger_dag", data).then((response) => {
      if (response.successful) {
        this.showResult(
          i18next.t('workflows.dagHasBeenStarted') +
          this.getFollowingMessage(callback),
          true,
          true
        )
        getDeferredResponse(this.props.run_id, result => this.handleDagResult(result))
      } else {
        this.showResult(
          i18next.t('workflows.dagFailed') +
          false
        )
      }
    })
  }

  runService(callback) {
    try {
      const dataWithValidation = this.state.data
      if (dataWithValidation.isValid()) {
        if (dataWithValidation.preAction) {
          dataWithValidation.preAction().then((error) => {
            if (error) {
              this.showResult({ status: this.ERROR, message: error })
            } else {
              return this.callService(dataWithValidation.data, callback)
            }
          })
        } else {
          return this.callService(dataWithValidation.data, callback)
        }
      } else {
        this.showResult(null, true, false)
      }
    } catch (error) {
      this.showResult(
        {
          status: this.ERROR,
          message: error + "",
        },
        false
      )
    }
  }

  callService(data, callback) {
    const step = this.props.step
    data = Object.assign(
      {
        service_type: step.type,
        id: step.step_body ? parseInt(step.step_body) : 0,
        step_body: step.original_step_body,
        internal_client_id: this.props.internal_client_id,
        run_id: this.props.run_id,
        variables: this.variablesToRecord(),
      },
      data
    )
    return runStep(step.step_id, "service", data).then((result) => {
      if (!result || result.error) {
        this.showResult(
          {
            status: this.ERROR,
            message: result
              ? result.error
              : "Service is currently unavailable.",
          },
          false
        )
      } else {
        this.setState({ callback: callback }, () =>
          this.showResult(result, true, true)
        )
      }
    })
  }

  getFollowingMessage(callback) {
    if (callback) {
      return "  Following steps must be run manually."
    } else {
      return "" // Nothing else to run
    }
  }

  replaceVariables(step_body, variables) {
    if (variables == null) {
      return step_body
    }
    const re = /\{[^}]*\}/g
    let sql = step_body
    const var_array = sql.match(re)

    if (var_array != null) {
      for (let i = 0; i < var_array.length; i++) {
        const v = var_array[i]
        try {
          var varValue = this.getVarValue(variables, v.slice(1, -1))
          sql = sql.replace(v, varValue)
        } catch (e) {
          console.log(e)
        }
      }
    }
    return sql
  }

  getVarValue(variables, varName) {
    for (let i = 0; i < variables.length; i++) {
      if (variables[i].name == varName) {
        return variables[i].value
      }
    }
    throw "Variable " + varName + " Not Found!"
  }

  removeComments(sql) {
    sql = sql.replace(/(\/\*[^*]*\*\/)/g, "")
    sql = sql.replace(/^--.*\n?/gm, "")
    return sql
  }

  substitute(sql) {
    this.props.variables.forEach((variable) => {
      sql = sql.replace(
        new RegExp("{" + variable.name + "}", "g"),
        variable.value
      )
    })
    return sql
  }

  renderBody() {
    const step = this.props.step
    let type = step.type
    let step_body = step.original_step_body
    if (type == "trigger_dag") {
      return (
        <h5 style={{ textTransform: "capitalize" }}>
          {this.prettyPrint(step_body ? step_body : step.original_step_body)}
        </h5>
      )
    } else if (type == "sql_dashboard") {
      return (
        <div>
          <Dashboard dashboard_id={step_body} />
        </div>
      )
    } else if (this.isService()) {
      if (this.state.runHistory) {
        const Run = this.state.run
        return (
          <Run
            id={this.props.step.step_body}
            input={this.state.runHistory["input"]}
            input_history={this.state.input_history}
            updateData={(data) => this.setState({ data: data })}
          />
        )
      } else {
        return null
      }
    } else if (type == "sql") {
      return (
        <SQLEditor
          code={step_body}
          substitute={(sql) => this.substitute(sql)}
          onChange={(sql) => this.props.setStepBody(step, sql)}
        />
      )
    } else if (type == "schedule") {
      return null
    } else if (step_body != null) {
      if (this.state.edit) {
        return (
          <textarea
            style={{
              height: "500px",
              marginTop: "1px",
            }}
            rows="25"
            value={step_body}
            onChange={(event) =>
              this.props.setStepBody(step, event.target.value)
            }
            className="form-control"
          />
        )
      } else {
        return (
          <SyntaxHighlighter
            language="sql"
            style={docco}
            onClick={() => this.editStep(step)}
            customStyle={{
              height: "500px",
              marginTop: "1px",
            }}
          >
            {step_body}
          </SyntaxHighlighter>
        )
      }
    }
  }

  renderResult() {
    const step = this.props.step
    if (step.type == 'schedule') {
      return (
        <Schedule workflowId={this.props.workflow_id} runId={this.props.run_id} variables={this.variablesToRecord()} />
      )
    } else {
      return (
        <div id="result">
          <RunResult
            step_id={step.step_id}
            columns={step.columns}
            sql={step.actual_sql}
            run_id={this.props.run_id}
            type={step.type}
            running={this.state.running}
            result={this.state.result}
            success={this.state.success}
            devMode={this.props.devMode}
          />
        </div>
      )
    }
  }

  prettyPrint(text) {
    return text == null ? null : text.replace(/_/g, " ")
  }

  editStep() {
    this.setState({ edit: true })
  }

  renderRunButton() {
    if (this.props.step.type != 'schedule') {
      let buttonText = "RUN"
      if (this.state.running) {
        buttonText = "RUNNING"
      } else if (this.state.stepHistory) {
        buttonText = "RERUN"
        // Convert UTC time to local timezone.
        var last_run_time = new Date(this.state.stepHistory.date_time + "Z")
        var formatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
        last_run_time = last_run_time.toLocaleDateString("en-US", formatOptions)
      }
      return (
        <div>
          <Button onClick={() => this.runStep()} disabled={this.state.running} buttonText={buttonText} />
        </div>
      )
    }
  }

  async copySql() {
    try {
      const sql = this.replaceVariables(this.props.step.original_step_body, this.props.variables)
      await navigator.clipboard.writeText(sql)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  render() {
    const step = this.props.step
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '70%' }}>
        {
          this.props.devMode || this.props.serviceCheck ?
            <Box component="div" id={"step" + step.step_id} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
                  {step.step_name}
                </Typography>
              </Box>
              <Box>
                {/* left column holds run button, step type and info icon */}
                <Box>
                  <Box display="flex" sx={{ mb: 3 }}>
                    {!["sql_dashboard"].includes(step.type) && (
                      <span>{this.renderRunButton()}</span>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                    <Box sx={{ marginRight: 1 }}>
                      <Typography style={{ textTransform: "capitalize" }}>
                        {this.prettyPrint(step.type)}
                      </Typography>
                    </Box>
                    {step.type == 'sql' && (
                      <Box sx={{ marginRight: 1 }}>
                        <ContentCopyIcon onClick={() => this.copySql()} sx={{ cursor: 'pointer' }} />
                      </Box>
                    )}
                    <StepDescriptionPopover step_description={step.step_description} />
                  </Box>
                </Box>
                {/* right column holds SQL body and result */}
                <Box>
                  <Box sx={{ display: 'inline-block', width: '80vw', maxWidth: '100%' }}>{this.renderBody(step)}</Box>
                  <Box sx={{ display: 'inline-block', width: '80vw', maxWidth: '100%' }}>{this.renderResult(step)}</Box>
                </Box>
              </Box>
            </Box>

            :

            <NotebookInterface
              renderRunButton={this.renderRunButton()}
              renderResult={this.renderResult(step)}
              stepName={step.step_name}
              type={step.type}
              stepId={this.props.step.step_id}
              step={step}
              devMode={this.props.devMode}
              isService={this.isService}
              stepDescription={step.step_description}
            />
        }
      </Box>
    )
  }
}