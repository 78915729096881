import { Component } from 'react';
import FTNotice from '../FTNotice';
import OrderedList from '../widgets/OrderedList';
import DeleteButton from '../DeleteButton';
import { patchStep, saveStep, deleteStep, getStep, getSteps } from '../../api/feeds';
import { getTriggerableDags } from '../../api/workflows'

export default class EditSteps extends Component {
  constructor(props) {
    super(props)
    this.state = {
      feed: props.feed_id,
      referer: props.referer,
    }
    this.getSteps = this.getSteps.bind(this)
  }

  componentDidMount() {
    this.setMode(this.props);
    getTriggerableDags().then(response => {
      this.setState({triggerable_dags: response.result});
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.step_id != prevProps.step_id) {
      this.setMode(this.props); 
    }
  }

  setMode(props) {
    const stepId = props.step_id;
    if (stepId) {
      this.editStep(stepId);
    } else {
      this.getSteps();
    }
  }

  getSteps() {
    if (this.state.referer == null) {
      window.location.href = '/#/feeds/' + this.state.feed + '/' + this.getTable();
      if (!this.props.updateParentLink(null, null)) {
        getSteps(this.props.feed_id, this.getTable()).then((result) => {
          this.setState({editView: false, steps: result.feed_steps});
        });
      }
    } else {
      window.location.href = '/#' + this.state.referer;
    }
  }

  addStep() {
    window.location.href = '/#/feeds/' + this.state.feed + '/' + this.getTable() + '/' + 'new';
    this.props.updateParentLink(this.getSteps, 'new');
  }

  editStep(stepId) {
    window.location.href = '/#/feeds/' + this.state.feed + '/' + this.getTable() + '/' + stepId;
    if (!this.props.updateParentLink(this.getSteps, stepId)) {
      if (stepId == 'new') {
        this.newStep();
      } else {
        getStep(this.props.feed_id, this.getTable(), stepId).then((response) => {
          const newState = Object.assign(this.additionalData(), {editView: true, ...response.feed_step});
          this.setState(newState, () => this.getAdditionalData());
        });
        
      }
    }
  }

  getAdditionalData() {
    // need to be overriden
  }

  additionalData() {
    return {}; // can be overriden
  }

  updateStep() {
    if (this.validateFields()) {
      this.startUpdate();
    }
  }

  startUpdate() {
    this.finishUpdate();
  }

  finishUpdate() {
    const data = Object.assign({}, this.state);
    delete data.editView;
    delete data.steps;
    delete data.triggerable_dags;
    this.prepareData(data);
    saveStep(this.props.feed_id, this.getTable(), this.state.id, data).then((response) => {
      if (response.success) {
        this.props.isChanged(false)
        this.getSteps();
      } else {
        FTNotice('There was an error saving the step: ' + response.error, 3000);
      }
    });
  }

  prepareData(data) { // Can be overriden
  }

  validateFields() {
    if (this.state.step_name.trim() == '') {
      FTNotice('Please enter a name', 3000);
    } else {
      return true;
    }
    return false;
  }

  updateStepOrder(step, order) {
     patchStep(this.props.feed_id, this.getTable(), step.id, {step_order: order});
  }

  deleteStep() {
    deleteStep(this.props.feed_id, this.getTable(), this.state.id).then((response) => {
      if (response.success) {
        this.getSteps();
      } else {
        FTNotice('The step could not be deleted', 3000);
      }
    });
  }

  render() {
    return (
      <div style={{padding: 30}}>
        { this.state.editView == false && (
          <OrderedList
            objectType="Step"
            items={this.state.steps}
            columns={this.getColumns()}
            addItem={() => {this.addStep()}}
            updateItemOrder={(step, order) => { this.updateStepOrder(step, order) }}
          />
        )}
        { this.state.editView && (
          <div>
            { this.renderFields() }
            <div className="col-md-12 mt-25 text-center">
              <button name="edit" className="btn btn-primary" onClick={() => {this.updateStep()}}>{this.state.id == null ? 'Add'  : 'Update'} Step</button>
              <DeleteButton
                objectType="Step"
                objectName={this.state.step_name}
                canDelete={this.state.id != null}
                deleteObject={() => {this.deleteStep()}}/>
            </div>
          </div>
        )}
      </div>
    )
  }

  getTable() {
    throw "getTable should be implemented by subclass";
  }

  getColumns() {
    throw "columns should be implemented by subclass";
  }

  newStep() {
    throw "newStep should be implemented by subclass";
  }

  renderFields() {
    throw "renderFields should be implemented by subclass";
  }
}
