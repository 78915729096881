import { Button } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI Button (https://mui.com/material-ui/api/button/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  buttonText - the label to use for the button (this should generally be an i18n key)
*/
const FtoButton = (props) => {
  const defaultProps = {
    size: "large",
    variant: "contained", // If on a dialog, or not a primary action, use "outlined"
  }
  const revisedProps = Object.assign(defaultProps, props)
  const {buttonText, ...buttonProps} = revisedProps
  return (
    <Button
      {...buttonProps}
    >
      {i18next.t(buttonText)}
    </Button>
  )
}

export default FtoButton