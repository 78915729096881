import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import i18next from 'i18next'

const FtoBreadCrumb = (props) => {
  const prettyPrint = (text) => {
    return text.replace(/_/g, " ")
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/">
        {i18next.t('menu.home')}
      </Link>
      {
        props.serviceCheck == false ? <Link color="inherit" href="/#/workflows/all">
          {i18next.t('menu.workflows')}
        </Link>
          :
          <Link color="inherit" href="/#/services">
            {i18next.t('menu.services')}
          </Link>
      }
      <Typography color="textPrimary" sx={{
        textTransform: "capitalize"
      }}>
        {props.serviceCheck ? prettyPrint(props.name) : props.name}
      </Typography>
    </Breadcrumbs>
  )
}

export default FtoBreadCrumb