export function getImage(engine) {
  /*
    Get the corresponding image name for an engine.
  */
  switch (engine.toLowerCase()) {
    case "bing":
      return "bing.png";
    case "facebook":
      return "facebook.png";
    case "google":
      return "google_ads.png";
    case "google_analytics_ua":
    case "google_analytics_v4":
      return "google_analytics.png";
    case "google_mail":
      return "google_mail.png";
    case "google_merchant_center":
      return "google_merchant_center.png";
    case "google_search_console":
      return "google_search_console.svg";
    case "google_trends":
      return "google_trends.png";
      case "google_big_query":
        return "google_big_query.png";
    case "linkedin":
      return "linkedin.png";
    case "rakuten":
      return "rakuten.jpeg";
    case "salesforce":
      return "salesforce.png";
    case "shopify":
      return "shopify.jpg";
    case "slack":
      return "slack.png";
    default:
      return null;
  }
}
