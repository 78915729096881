import { GET_NOTIFICATIONS, ACKNOWLEDGE } from './types'
import { put, takeEvery, select } from 'redux-saga/effects';
import { currentUser } from '../users/selectors'
import { getNotifications as getNotificationsApi, acknowledge as acknowledgeApi } from '../../api/alerts'
import { saveNotifications } from './reducer' 

function* getNotifications({internal_client_id}) {
  const data = yield getNotificationsApi(internal_client_id)
  if (data != null) {
    yield put(saveNotifications(data.results))
  }
}

function* acknowledge({notification_id}) {
  const data = yield acknowledgeApi(notification_id)
  if (data != null && data.success) {
    const user = yield select(currentUser)
    const internal_client_id = user.client.internal_client_id
    const newData = yield getNotificationsApi(internal_client_id)
    if (newData != null) {
      yield put(saveNotifications(newData.results))
    }
  }
}

function* mySaga() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications)
  yield takeEvery(ACKNOWLEDGE, acknowledge)
}

export default mySaga;
