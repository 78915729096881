import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { getStep, patchStep, addStep, deleteStep } from "../../api/revenue_pipeline"
import FTNotice from "../FTNotice"
import Form from '../Form'
import Field from '../Field'
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'

class AddEditStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      name: '',
      description: '',
      type: this.props.match.params.type,
      subtype: 'sql',
      sql: '',
      step_order: null,
      location: '',
      output_format: 'CSV',
      user_id: this.props.current_user_id,
      internal_client_id: this.props.internal_client_id
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.current_client_id !== prevState.internal_client_id || nextProps.current_user_id !== prevState.user_id)
      return {internal_client_id : nextProps.internal_client_id, user_id: nextProps.current_user_id}
    else
      return null
  }

  componentDidMount() {
    let id = this.props.match.params.step_id
    if (id) {
      this.getData(id)
    }
  }

  getData(id) {
    getStep(id)
    .then(response => {
      if (response.success) {
        response.data.subtype = 'sql'
        this.setState(response.data)
      } else {
        FTNotice(i18next.t('revenuePipeline.error', {error: response.data}), 3000)
      }
    })
  }

  saveData() {
    if (this.state.name.trim() == '') {
      FTNotice('revenuePipeline.enterName', 3000)
      return
    }

    let data = this.state
    if(!data.id) {
      addStep(data)
      .then(response => {
        if (!response.success) {
          FTNotice(i18next.t('revenuePipeline.saveFailed', {error: response.error}), 3000)
        } else {
          this.props.history('/revenue_pipeline/' + this.state.type)
        }
      })
    } else {
      patchStep(data['id'], data)
      .then(response => {
        if (!response.success) {
          FTNotice(i18next.t('revenuePipeline.saveFailed', {error: response.error}), 3000)
        } else {
          this.props.history('/revenue_pipeline/' + this.state.type)
        }
      })
    } 
  }

  deleteStep() {
    deleteStep(this.state.id)
    .then(response => {
      if (!response.success) {
        FTNotice(response.error, 30000)
      } else {
        this.props.history('/revenue_pipeline/' + this.state.type)
      }
    })
  }

  renderSQL() {
    return(
      <div>
        <Field label={i18next.t('revenuePipeline.sql')}>
          <SQLEditor
            code={this.state.sql}
            onChange={(sql) => this.setState({sql: sql})}
          />
        </Field>
        <div className="col-md-12 mt-25">
          <div className="col-md-2"></div>
          <div className="col-md-7 text-left">
            {i18next.t('revenuePipeline.toSubmit')}
          </div>
        </div>
        <Field label={i18next.t('revenuePipeline.url')}>
          <input type="text" className="form-control" value={this.state.location} onChange={event => {this.setState({location: event.target.value})}} />
        </Field>
        <Field label={i18next.t('revenuePipeline.fileFormat')}>
          <select className="form-control" value={this.state.output_format} onChange={event => {{this.setState({output_format: event.target.value})}}}>
            <option value="CSV">{i18next.t('csv')}</option>
            <option value="TAB">{i18next.t('tabDelimited')}</option>
          </select>
        </Field>
      </div>
    )
  }

  render() {
    if (this.state.type)
      return (
        <div>
          <Form
            objectType={i18next.t('revenuePipeline.step')}
            objectName={this.state.name}
            newObject={!this.state.id}
            updateObject={() => {this.saveData()}}
            deleteObject={() => {this.deleteStep()}}
            parentLink={'/revenue_pipeline/' + this.state.type}
          >
            <Field label={i18next.t('revenuePipeline.name')}>
              <input type="text"
                value={this.state.name}
                onChange={event => this.setState({name:event.target.value})}
                className="form-control" />
            </Field>
            <Field label={i18next.t('revenuePipeline.description')}>
              <textarea rows="5"
                value={this.state.description}
                onChange={event => this.setState({description:event.target.value})}
                className="form-control" />
            </Field>
            {this.state.subtype == 'sql' && this.renderSQL()}
          </Form>
        </div>
      )
    else
      return null
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    current_user_id: state.users.user ? state.users.user.id : null
  }
}

export default withRouter(connect(mapStateToProps)(AddEditStep))
