import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '../widgets/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HistoryIcon from '@mui/icons-material/History'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { getConversations, getConversation } from '../../api/prompts'
import i18next from 'i18next'

const PromptHistory = ({ disabled, status, setStatus, conversation, setConversation, parentIsPromptDialog }) => {
  const [conversations, setConversations] = useState([])
  const [state, setState] = useState({ left: false })

  useEffect(() => {
    getConversations().then(result => setConversations(result))
  }, [])

  useEffect(() => {
    if (conversation?.id != null && conversations.find(c => c.id == conversation.id) == null) {
      setConversations([{
        id: conversation.id,
        prompt: conversation.prompts[0].input
      }, ...conversations])
    }
  }, [conversation])

  const toggleDrawer = (anchor, state) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, [anchor]: state })
  }

  const loadConversation = (anchor, conversation) => {
    getConversation(conversation.id).then(result => {
      setConversation(result)
    })
    toggleDrawer(anchor, false)
    if (status == 'processing') {
      setStatus('response')
    }
  }

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    >
      <List>
        {conversations.map((conversation, index) => (
          <ListItem key={index} disablePadding onClick={() => loadConversation(anchor, conversation)}>
            <ListItemButton>
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary={conversation.prompt} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <div>
      <Button disabled={disabled} sx={{ color: parentIsPromptDialog ? 'white.100' : null }} buttonText={i18next.t('ai.promptHistory')} color='secondary' startIcon={<HistoryIcon />} variant='standard' onClick={toggleDrawer(parentIsPromptDialog ? 'right' : 'left', true)} />

      {parentIsPromptDialog ? <Drawer
        sx={{ zIndex: 4000 }}
        onClick={toggleDrawer('right', false)}
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        {list('left')}
      </Drawer> :
        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>}
    </div>
  )
}

export default PromptHistory