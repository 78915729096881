import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import RadioButtons from '../../widgets/RadioButtons'
import RadioButton from '../../widgets/RadioButton'
import Schedule from '../../widgets/Schedule'
import { getWorkflowSchedules, deleteWorkflowSchedule, createWorkflowSchedule, saveWorkflowSchedule, updateHistory } from '../../../api/workflows'
import i18next from 'i18next'

const RunSchedule = ({workflowId, runId, variables, timeZone}) => {
  const defaultSchedule = {
    workflow_id: workflowId,
    runid: runId,
    schedule: null,
  }
  const [schedule, setSchedule] = useState(defaultSchedule)
  
  useEffect(() => {
    getWorkflowSchedules(workflowId, runId).then(response => {
      if (response) {
        setSchedule(response)
      }
    })
  }, [workflowId, runId])
  
  const onChange = newSchedule => {
    const newScheduleObject = Object.assign({}, schedule, {schedule: newSchedule, id: newSchedule ? schedule.id : null})
    if (schedule.id != null && newSchedule == null) {
      deleteWorkflowSchedule(schedule.id).then(() => {})
    } else if (newSchedule != null) {
      const method = schedule.id == null ? createWorkflowSchedule : saveWorkflowSchedule
      method(newScheduleObject).then(result => {
        if (result.id) {
          setSchedule(Object.assign({}, newScheduleObject, {id: result.id}))
        }
      })
      updateHistory(runId, {workflow_id: workflowId, variables}).then(() => {})
    }
    setSchedule(newScheduleObject)
  }
  
  return (
    <RadioButtons
      value={schedule.schedule == null ? 'notScheduled' : 'scheduled'}
      onChange={e => onChange(e.target.value == 'notScheduled' ? null : '0 0 1 * *')}>
      <RadioButton value="notScheduled" labelText={i18next.t("workflow.notScheduled")} />
      <RadioButton value="scheduled" labelText={i18next.t("workflow.schedule")} />
      {schedule.schedule != null && (
        <Schedule
          value={schedule.schedule}
          onChange={onChange}
          timeZone={timeZone}
        />
      )}
    </RadioButtons>
  )
}

const mapStateToProps = function (state) {
  return {
    timeZone: state.users.user ? state.users.user.client.time_zone : 'America/Los_Angeles',
  }
}

export default connect(mapStateToProps)(RunSchedule)