import React, { useState, useEffect, createRef } from 'react'
import FunnelGraph from 'funnel-graph-js'
import { guid } from '../../utilities/guids'
import useRefDimensions from '../../hooks/Dimensions'

import 'funnel-graph-js/dist/css/main.min.css'
import 'funnel-graph-js/dist/css/theme.min.css'
import './FunnelChart.scss'

/**
 * A funnel chart component created with chart.js.
 * Source: https://github.com/greghub/funnel-graph-js
 *
 * Properties:
 *  labels          - An array of names of metrics. E.g. ['phase 1', 'phase 2', ...]
 *  datasets        - An array of data for the metrics. E.g [2031, 1200, ...]
 *  title           - (optional) Chart title.
 *  data_formatter  - (optional) E.g "{value}%"
 */

const FunnelChart = ({ title, datasets, labels }) => {
  const [unique] = useState('fg-' + guid())
  const [chart, setChart] = useState()

  const divRef = createRef()
  const dimensions = useRefDimensions(divRef)

  const generateData = () => {
    if (datasets.length > 0) {
      const colors = ['blue']
      const values = datasets[0]
      return {labels, colors, values}
    }
  }

  const updateChart = () => {
    const data = generateData()
    const chart = new FunnelGraph({
      container: '#' + unique,
      data: data,
      displayPercent: false,
      direction: 'vertical',
    })
    setChart(chart)
  }
  
  const drawChart = () => {
    if (chart != null) {
      chart.draw()
    }
  }
  
  useEffect(() => updateChart(), [datasets, labels])
  useEffect(() => drawChart(), [chart, dimensions])

  return (
    <div style={{height: '100%', width: '100%'}}>
      {title && (
        <h3>{title}</h3>
      )}
      <div id={unique} style={{height: title ? 'calc(100% - 26px)' : '100%', width: '100%'}} />
    </div>
  )
}

export default FunnelChart