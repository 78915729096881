import request from './request';

// alerts, bidding, budget_admin, dashboard_admin, feeds, manage_ab_tests, manage_dimensions, offline_conversions,
// revenue_pipeline, services, sql, workflows
export function validateSql(data) {
  const url = '/api/sql/validate';
  return request(url, {}, 'POST', data);
}

// budget_admin, manage_ab_tests, manage_dimensions, offline_conversions
export function getColumns(data) {
  const url = '/api/sql/get_columns';
  return request(url, {}, 'POST', data);
}
