import request from "./request";

export function getEngineData(engine, internal_client_id) {
  const url = '/api/ad_engines/' + engine + '/' + internal_client_id;
  return request(url);
}

export function addEditEngineData(engine, internal_client_id, data) {
  const url = '/api/ad_engines/' + engine + '/' + internal_client_id;
  return request(url, {}, 'PUT', data);
}
