import React from 'react'
import Section from '../../widgets/Section'
import Switch from '../../widgets/Switch'
import PullDown from '../../widgets/PullDown'
import Stack from '../../widgets/Stack'
import i18next from 'i18next'

const LabelInputs = (props) => {
  return (
    <Stack sx={{ width: "100%", margin: "auto" }}>
      <PullDown
        disabled={props.status == 'published'}
        labelText="biddingRun.searchForALabel"
        value={props.allLabels.filter(l => props.labels.includes(l.name))}
        options={props.allLabels}
        optionFunction={option => option.name}
        noOptionsText={i18next.t('biddingRun.noLabelsMatchYourQuery')}
        onChange={(_, value) => props.onUpdate({ labels: value.map(l => l.name) })}
      />
      <Switch
        disabled={props.status == 'published'}
        checked={props.excludeLabels}
        onChange={() => props.onUpdate({ exclude_labels: !props.excludeLabels })}
        label={i18next.t('biddingRun.excludeLabels')} />
    </Stack>
  )
}

const LabelSelect = (props) => {
  return (
    <Section
      name={props.labelSelectName}
      description={i18next.t('biddingRun.labelSelectDesc')}
    >
      {<LabelInputs {...props} />}
    </Section>
  )
}

export default LabelSelect

