import { connect } from 'react-redux'
import OrderedList from '../widgets/OrderedList'
import Confirm from '../Confirm'
import { patchAbTest } from '../../api/ab_tests'
import { DataConfiguration } from '../DataConfiguration'
import FTNotice from '../FTNotice'
import i18next from 'i18next'
import './ABTestConfig.scss'
import Section from '../widgets/Section'
import { Checkbox } from '@mui/material'
import Button from '../widgets/Button'

class ABTestConfig extends DataConfiguration {
  /*
    Enable admin to configure charts on ab test page.
  */
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state, {
      originalCharts: null,
      charts: null,
      saveClicked: false,
    })
  }

  componentDidMount() {
    super.componentDidMount()
    this.getCharts()
  }

  getCharts() {
    let charts = null
    if (this.props.settings != null) {
      /* This step checks if new charts were added to defaultCharts.
      *  If so, we add the newly added charts as options to display in the filter.
      *  In this way, we don't need to insert the new charts to the database manually.
      */
      charts = {
        info_boxes: this.props.settings.info_boxes == null ? this.props.defaults.info_boxes : this.syncWithDefaultCharts(this.props.defaults.info_boxes, this.props.settings.info_boxes),
        significance: this.props.settings.significance == null ? this.props.defaults.significance : this.syncWithDefaultCharts(this.props.defaults.significance, this.props.settings.significance)
      }
    } else {
      /* If there is no data in the database, it means the current client is new,
      *  we use the default charts.
      */
      charts = this.props.defaults
    }
    charts = {
      info_boxes: charts.info_boxes.map((item, i) => Object.assign({}, item, { order: i + 1 })),
      significance: charts.significance.map((item, i) => Object.assign({}, item, { order: i + 1 }))
    }
    this.setState({ originalCharts: this.copyCharts(charts), charts });
  }

  copyCharts(charts) {
    return {
      info_boxes: charts.info_boxes.map(item => Object.assign({}, item)),
      significance: charts.significance.map(item => Object.assign({}, item))
    }
  }

  syncWithDefaultCharts(defaults, charts) {
    const defaultKeys = defaults.map(value => Object.keys(value)[0])
    const newKeys = charts.map(value => Object.keys(value)[0])
    charts = charts.filter(value => defaultKeys.indexOf(Object.keys(value)[0]) >= 0)
    defaultKeys.forEach(value => {
      if (newKeys.indexOf(value) < 0) {
        const dict = {}
        dict[value] = false
        charts.push(dict)
      }
    })
    return charts
  }

  getColumns() {
    return [{
      id: 'order',
      header: 'abtest.config.order',
      rowDrag: true,
      width: 65,
    }, {
      id: 'localizedName',
      header: 'abtest.config.name',
      alignment: 'left',
      width: 110,
      format: (_, row) => i18next.t('abtest.metrics.' + Object.keys(row)[0])
    }, {
      id: 'hide',
      header: 'abtest.config.show',
      cellRenderer: (_, row) => {
        const key = Object.keys(row)[0]
        const show = row[key]
        return (
          <Checkbox value={false} defaultChecked={show} onChange={event => row[key] = event.target.checked} />
        )
      },
      width: 55,
    }]
  }

  apply() {
    super.apply()
    this.sendCharts(this.state.charts)
    this.setState({ originalCharts: this.copyCharts(this.state.charts) })
  }

  save() {
    this.setState({ saveClicked: true })
  }

  confirmSave(confirmed) {
    this.apply()
    if (confirmed) {
      patchAbTest(this.props.id, { settings: this.orderCharts(this.state.charts) }).then(result => {
        if (!result) {
          FTNotice('abtest.config.couldNotSave', 15000)
        }
      })
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
    )
    this.setState({ saveClicked: false })
  }

  sendCharts(charts) {
    this.props.updateSettings(this.orderCharts(charts));
  }

  orderCharts(charts) {
    return {
      info_boxes: this.orderItems(charts.info_boxes),
      significance: this.orderItems(charts.significance),
    }
  }

  orderItems(charts) {
    charts = charts.map(chart => Object.assign({}, chart));
    charts.sort((a, b) => { return a.order - b.order })
    charts.forEach(chart => {
      delete chart.order
    })
    return charts
  }

  cancel() {
    super.cancel()
    this.setState({ charts: this.state.originalCharts })
  }

  renderChartTable(type) {
    return (
      <Section name={('abtest.config.' + type)}>
        <OrderedList
          formatted={false}
          autoHeight={true}
          items={this.state.charts[type]}
          columns={this.getColumns()}

        />
      </Section>
    )
  }

  renderChartTables() {
    return (
      <div>
        {this.renderChartTable('info_boxes')}
        {this.renderChartTable('significance')}
      </div>
    )
  }

  renderExtra() {
    return (
      <>
        {this.renderConfirm()}
        {this.renderCharts()}
      </>
    )
  }

  renderConfirm() {
    return (
      <Confirm opened={this.state.saveClicked} title={i18next.t('abtest.config.changeDefaults')} question={i18next.t('abtest.config.changeDefaultsMessage')}
        action={(confirmed) => { this.confirmSave(confirmed) }} button="abtest.config.save" cancelButton="abtest.config.apply" />
    )
  }

  renderCharts() {
    if (this.props.isAdmin && this.state.charts) {
      return (
        <div>
          {this.renderChartTables()}
        </div>
      )
    }
  }

  renderSave() {
    if (this.props.isAdmin) {
      return (
        <Button buttonText='abtest.config.save' color="accent" className={this.props.attachToSideNav ? "toggle-nav" : ""} onClick={() => this.save()} />
      )
    }
  }
}

const mapStateToProps = function (state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
  }
}

export default connect(mapStateToProps)(ABTestConfig)