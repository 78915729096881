import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { logout } from '../api/auth'
import { setToken } from '../redux/users/sagaActions'
import { REPORT, OPTIMIZE, AUTOMATE, USER, CONFIGURE } from '../routes/index'
import { AI_DASHBOARD, AI_INFRASTRUCTURE, ALL_CLIENTS, EXEC_SUMMARY, AB_TESTS, BIDDING_CHANGES, BUDGET, BUDGET_ADMIN, COMPARISON, DASHBOARD_ADMIN, FORECAST } from '../routes/index'
import { DASHBOARDS, DIMENSIONS, PERFORMANCE, PROJECTS, BIDDING, WORKFLOWS, OFFLINE_CONVERSIONS, CHANGE_HISTORY, ALERTS } from '../routes/index'
import { MANAGE_DIMENSIONS, MANAGE_AB_TESTS, FEEDS, REVENUE_PIPELINE, DATA_SCIENCE, SERVICES, STATUS, CLIENTS, USERS, INTEGRATIONS, TIME_TRACKING } from '../routes/index'
import { LINKS, SQL, PROFILE } from '../routes/index'
import i18next from 'i18next'
import { selectClient } from '../redux/users/sagaActions'

import './Header.scss';

const Header = ({ dispatch, menu, menuItem, showJira, showConfig, user, permissions, aiAction }) => {
  const REPORT_MENU = [
    { name: AI_DASHBOARD, action: () => aiAction() },
    { name: ALL_CLIENTS, url: '/all_clients' },
    { name: EXEC_SUMMARY, url: '/' },
    { name: AB_TESTS, url: '/ab_tests' },
    { name: BIDDING_CHANGES, url: '/bidding_changes' },
    { name: BUDGET, url: '/budget' },
    { name: BUDGET_ADMIN, url: '/budget_admin' },
    { name: COMPARISON, url: '/comparison' },
    { name: DASHBOARDS, url: '/dashboards' },
    { name: DASHBOARD_ADMIN, url: '/dashboard_admin' },
    { name: DIMENSIONS, url: '/dimensions' },
    { name: FORECAST, url: '/forecast' },
    { name: PERFORMANCE, url: '/performance' },
  ]
  const OPTIMIZE_MENU = [
    { name: BIDDING, url: '/bidding' },
    { name: WORKFLOWS, url: '/workflows/all' },
  ]
  const AUTOMATE_MENU = [
    { name: ALERTS, url: '/alerts' },
    { name: AI_INFRASTRUCTURE, url: '/ai_infrastructure' },
    { name: CHANGE_HISTORY, url: '/change_history' },
    { name: DATA_SCIENCE, url: '/data_science' },
    { name: FEEDS, url: '/feeds/section/all' },
    { name: INTEGRATIONS, url: '/integrations' },
    { name: MANAGE_AB_TESTS, url: '/manage_ab_tests' },
    { name: MANAGE_DIMENSIONS, url: '/manage_dimensions' },
    { name: OFFLINE_CONVERSIONS, url: '/offline_conversions' },
    { name: REVENUE_PIPELINE, url: '/revenue_pipeline' },
    { name: SERVICES, url: '/services' },
    { name: STATUS, url: '/status' },
  ]

  const [openDialog, setOpenDialog] = useState(false)

  const logoutUser = (event) => {
    event.preventDefault()
    logout().then(() => {
      dispatch(setToken(null, '/'))
    })
  }

  const handleClick = (event, clientID) => {
    event.preventDefault()
    dispatch(selectClient(clientID));
  }

  const getPageHelp = () => {
    const key = 'help.' + menuItem
    return i18next.exists(key) ? i18next.t(key).replace(/<\/ul>\n/g, '</ul>').replace(/<\/ol>\n/g, '</ol>').replace(/<\/li>\n/g, '</li>').replace(/\n/g, '<br/><br/>') : null
  }

  const canShowPageHelp = () => {
    return getPageHelp() != null
  }

  const showPageHelp = () => {
    setOpenDialog(true)
  }

  const renderPageHelp = () => {
    return (
      <Dialog open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth='md' >
        <DialogTitle><b>{i18next.t('menu.help')}</b></DialogTitle>
        <DialogContent dividers dangerouslySetInnerHTML={{ __html: getPageHelp() }}>
        </DialogContent>
      </Dialog>
    );
  }

  const addItem = (items, item) => {
    if (permissions.includes(item.name)) {
      if (items.length) {
        items.push(<li key={item.name + '_divider'} className="divider"></li>)
      }
      if (item.url) {
        items.push(<li key={item.name}><Link className={menuItem == item.name ? 'current-menu-item' : ''} to={item.url}>{i18next.t('menu.' + item.name)}</Link></li>)
      } else {
        items.push(<li key={item.name}><a onClick={item.action}>{i18next.t('menu.' + item.name)}</a></li>)
      }
    }
  }

  const addUserItem = (items, item, url, icon, alwaysShow, neverShow) => {
    if ((alwaysShow || permissions.includes(item)) && !neverShow) {
      items.push(
        <div key={item} className="text-center" style={{ width: 'min-content', padding: 7, display: 'inline-block' }}>
          <Link className={menuItem == item ? 'current-menu-item' : ''} to={url}>
            <i className={`fa ${icon}`} />&nbsp;
            {i18next.t('menu.' + item)}
          </Link>
        </div>
      )
    }
  }

  const reportItems = []
  REPORT_MENU.forEach(item => addItem(reportItems, item))
  if (showJira) {
    addItem(reportItems, { name: PROJECTS, url: '/projects' })
  }

  const optimizeItems = []
  OPTIMIZE_MENU.forEach(item => addItem(optimizeItems, item))

  const automateItems = []
  AUTOMATE_MENU.forEach(item => addItem(automateItems, item))

  const userItems = []
  addUserItem(userItems, CLIENTS, '/clients', 'fa-address-card', user.role == 'Admin')
  addUserItem(userItems, USERS, '/users', 'fa-users', user.role == 'Admin')
  addUserItem(userItems, LINKS, '/links', 'fa-link')
  addUserItem(userItems, SQL, '/sql_templates', 'fa-file-code-o')
  addUserItem(userItems, TIME_TRACKING, '/time_tracking', 'fa-clock-o', false, !user.enable_time_tracking)

  const getMenu = (identifier, items) => {
    if (items.length) {
      return (
        <li>
          <a className={'dropdown-toggle' + (menu == identifier ? ' current-menu' : '')} href="#" data-toggle="dropdown" aria-expanded="true">
            {i18next.t('menu.' + identifier)}
            <span className="caret"></span>
          </a>
          <ul className="dropdown-menu" role="menu">
            {items}
          </ul>
        </li>
      )
    }
    return null
  }

  return (
    <header className="header main-header">
      <div className="nav navbar navbar-static-top" role="navigation">
        <div className="container-fluid">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand" style={{ backgroundColor: 'black' }}>
              <span><b>{i18next.t('menu.ft')}</b>{i18next.t('menu.ami')}</span>
            </Link>
            <button className="navbar-toggle collapsed" type="button" data-toggle="collapse" data-target="#navbar-collapse">
              <i className="fa fa-bars" />
            </button>
          </div>
          <div id="navbar-collapse" className="collapse navbar-collapse">
            <ul className="nav navbar-nav">
              {getMenu(REPORT, reportItems)}
              {getMenu(OPTIMIZE, optimizeItems)}
              {getMenu(AUTOMATE, automateItems)}
            </ul>
            <div className="navbar-custom-menu" style={{ marginRight: '15px' }}>
              <ul className="nav navbar-nav">
                {(user.role == 'Admin' || user.clients.length > 1) && (
                  <li className="dropdown messages-menu">
                    <a className="dropdown-toggle" href="#" data-toggle="dropdown">
                      <i className="fa fa-address-card" />&nbsp;
                      <span>{user.client.name}</span>
                      <p className="hidden">{user.client.internal_client_id}</p>
                    </a>
                    {user.role == 'Admin' && (
                      <ul className="dropdown-menu">
                        <li className="header">
                          <span>{i18next.t('menu.youHaveClients', { numClients: user.clients.length })}</span>
                          <div className="pull-right"><Link to={'/clients/' + user.client.internal_client_id}>{user.client.name}</Link></div>
                        </li>
                        <li>
                          <ul className="menu">
                            {user.clients.map((client, i) => {
                              return (
                                <li key={client.internal_client_id}>
                                  <a onClick={(event) => (handleClick(event, client.internal_client_id))}>
                                    <h4 style={{ margin: '0px' }}>
                                      <span>{client.name}</span>
                                      <small>
                                        <span className="label label-default">{client.internal_client_id}</span>
                                      </small>
                                    </h4>
                                  </a>
                                </li>);
                            })}
                          </ul>
                        </li>
                        <li className="footer"><Link to='/clients'>{i18next.t('menu.seeAllClients')}</Link></li>
                      </ul>)}
                    {user.role != 'Admin' && (
                      <ul className="dropdown-menu">
                        <li>
                          <ul className="menu">
                            {user.clients.map((client, i) => {
                              return (
                                <li key={client.internal_client_id}>
                                  <a data-method="post" href={'/clients/' + client.internal_client_id + '/select'}>
                                    <h4 style={{ margin: '0px' }}>
                                      <span>{client.name}</span>
                                    </h4>
                                  </a>
                                </li>);
                            })}
                          </ul>
                        </li>
                      </ul>)}
                  </li>)}
                <li className="dropdown user user-menu">
                  <a className={'dropdown-toggle' + (menu == USER ? ' current-menu' : '')} href="#" data-toggle="dropdown">
                    <i className="fa fa-user-circle-o" />
                    <span className="hidden-xs">{user.name}</span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-header">
                      <i className="fa fa-user-circle-o fa-5x" style={{ color: 'white' }} />
                      <p>
                        <small style={{ paddingBottom: '10px' }}>
                          <span className="label label-info">{user.role}</span>
                        </small>
                        <span>{user.email}</span>
                        <small style={{ paddingTop: '10px' }}>
                          <span>{i18next.t('menu.lastLogin')}</span>&nbsp;
                          {user.last_sign_in}
                        </small>
                      </p>
                    </li>
                    <li className="user-body">
                      <div className="row" style={{ textAlign: 'left', marginLeft: 6 }}>
                        {userItems}
                      </div>
                    </li>
                    <li className="user-footer">
                      {permissions.includes('profile') && (
                        <div className="pull-left">
                          <Link className={'btn btn-default btn-flat' + (menuItem == PROFILE ? ' current-menu-item' : '')} to='/users/profile'>{i18next.t('menu.profile')}</Link>
                        </div>
                      )}
                      <div className="pull-right">
                        <a className="btn btn-default btn-flat" onClick={event => logoutUser(event)}>{i18next.t('menu.signOut')}</a>
                      </div>
                    </li>
                  </ul>
                </li>
                {(user.role == 'Admin' && showConfig) && (
                  <li><Link className={menu == CONFIGURE ? 'current-menu' : ''} to="/config">{i18next.t('menu.config')}</Link></li>
                )}
                {(user.role == 'Admin' || canShowPageHelp()) && (
                  <li className="dropdown">
                    <a className={'dropdown-toggle'} href="#" data-toggle="dropdown">
                      <span className="hidden-xs">Help</span>
                    </a>
                    <ul className="dropdown-menu">
                      {user.role == 'Admin' && (
                        <>
                          <li><a href="/resources/pdfs/FT%20Optimize%20Marketing%20Optimization%20Manual.pdf" target="_blank">{i18next.t('menu.general')}</a></li>
                          <li><a href="/resources/pdfs/FT%20Optimize%20Using%20Your%20Database.pdf" target="_blank">{i18next.t('menu.usingYourDatabase')}</a></li>
                        </>
                      )}
                      {user.role == 'Admin' && canShowPageHelp() && (
                        <li className="divider"></li>
                      )}
                      {canShowPageHelp() && (
                        <li><a onClick={() => showPageHelp()}>{i18next.t('menu.thisPage')}</a></li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {renderPageHelp()}
    </header>
  )
}

const mapStateToProps = function (state) {
  return {
    permissions: state.users.user ? state.users.user.permissions : [],
    user: state.users.user ? state.users.user : {
      name: '',
      email: '',
      role: 'User',
      last_sign_in: '',
      client: {
        internal_client_id: -1,
        name: ''
      },
      clients: [{
        internal_client_id: -1,
        name: ''
      }]
    }
  }
}

export default connect(mapStateToProps)(Header)
