import React, { Component } from 'react'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Chart, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip, Title } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { dataFormatter } from '../../utilities/formatting'
import { yyyymmddToMmddyyyy } from '../charts/util'
import { metrics } from '../../api/locale.js'

import '../../assets/stylesheets/charts.css'

Chart.register(annotationPlugin, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip, Title)

/**
 * A line chart component created with chart.js.
 * Source: https://www.chartjs.org   React Wrapper: https://github.com/reactchartjs/react-chartjs-2
 *
 * Properties:
 *  two_datasets_mode     - Designed for comparing two metrics. E.g. the line chart in home page. Default to false.
 *  x_axis_labels_1       - An array of X axis labels. E.g. ['2021-01-01', '2021-01-02', '2021-01-03']
 *  x_axis_labels_2       - (optional) An array of X axis labels. E.g. ['2021-01-07', '2021-01-08', '2021-01-09']
 *  y_axis_labels         - (optional) An array of Y axis labels. E.g. ['Dollars']
 *  dataset_labels        - An array of names of metrics. E.g. ['Click', 'Impressions', ...]
 *  datasets              - An array of data for the metrics. E.g [[2031, 3324, 5930], [43, 22, 45], ...]
 *  annotations           - (optional) [{x_value: '2021-01-01', y_value: 300, text: 'test'}, ...]
 *  top_padding           - The padding on the top. Default to 0
 *  title                 - (optional) Chart title.
 *  animation             - (optional) Whether to animate on drawing.  Default to true.
 */

const aiColors = ['#ffffff', '#00bcd4']
const gridYColor = '#9e9e9e'


export default class LineChartAi extends Component {
  static defaultProps = {
    animation: true,
    two_datasets_mode: false,
    top_padding: 0,
    x_axis_labels_2: null,
    annotations: null,
  }
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      options: null,
    }
  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateChart()
    }
  }

  getAnnotationYValue(x_value) {
    return this.props.datasets[0][x_value]
  }

  getAnnotations() {
    let annotations = []
    if (this.props.annotations != null) {
      this.props.annotations.forEach((annotation) => {
        const x_value = this.props.x_axis_labels_1.indexOf(moment(annotation.x_value).format('M/D/YY'))
        if (x_value >= 0) {
          let y_value = this.getAnnotationYValue(x_value)
          y_value = y_value == null ? 0 : y_value
          const newAnnotation = {
            type: "line",
            yScaleID: "y_1",
            xScaleID: "x_1",
            yMin: y_value,
            yMax: y_value,
            xMin: x_value,
            xMax: x_value,
            borderColor: "transparent",
            borderWidth: 2,
            label: {
              font: {
                size: 12,
                style: "normal",
              },
              yAdjust: -15,
              rotation: "auto",
              backgroundColor: "rgba(30, 30, 30, 0.7)",
              content: annotation.text,
              display: true,
            }
          }
          annotations.push(newAnnotation)
        }
      })
    }
    return annotations
  }

  getXAxisLabels() {
    if (this.props.two_datasets_mode && this.props.x_axis_labels_2 != null) {
      let result = []
      result = this.props.x_axis_labels_1.map(
        (e, i) => e + ";" + this.props.x_axis_labels_2[i]
      )
      return result
    } else {
      return this.props.x_axis_labels_1
    }
  }

  generateDatasets() {
    let datasets = []
    for (let i = 0; i < this.props.datasets.length; i++) {
      datasets.push({
        label: metrics[this.props.dataset_labels[i]] ? metrics[this.props.dataset_labels[i]] : this.props.dataset_labels[i],
        data: this.props.datasets[i],
        fill: false,
        backgroundColor: aiColors[i],
        borderColor: aiColors[i],
        xAxisID: this.displayX2() ? "x_" + (i + 1) : "x_1",
        yAxisID: this.props.y_axis_labels != null ? (this.props.y_axis_labels[i] == this.props.y_axis_labels[0] ? "y_1" : "y_2") : (this.displayY2() ? "y_" + (i + 1) : "y_1"),
        lineTension: 0.3,
      })
    }
    return datasets
  }

  displayX2() {
    return this.props.two_datasets_mode && this.props.x_axis_labels_2 != null &&
      JSON.stringify(this.props.x_axis_labels_1) !==
      JSON.stringify(this.props.x_axis_labels_2)
  }

  displayY2() {
    return this.props.two_datasets_mode ? this.props.dataset_labels.length > 1 &&
      this.stripSource(this.props.dataset_labels[0]).split(" (")[0].split(": ")[0] !==
      this.stripSource(this.props.dataset_labels[1]).split(" (")[0].split(": ")[0]
      : false
  }

  stripSource(string) {
    return string == null ? null : string.replace('Engine ', '').replace('Internal ', '')
  }

  processLabel(label) {
    if (label == null || label == "undefined") {
      return ""
    } else if (label.includes("/") && label.length <= 8) {
      return moment(label, 'M/D/YY').format('M/D/YY')
    } else if (label.includes("/") && label.length <= 10) {
      return moment(label, 'M/D/YYYY').format('M/D/YY')
    } else if (label.includes("-") || label.includes("/")) {
      return moment(label).format('M/D/YY')
    } else {
      return label
    }
  }

  formatTicks(metric, value) {
    value = value.toLocaleString("en-US", { maximumFractionDigits: 2 })
    const formatter = metric == null ? null : dataFormatter(metric.toLowerCase().replaceAll(" ", "_"))
    return formatter == null ? value : formatter(value)
  }


  updateChart() {
    let metric_1 = null
    let metric_2 = null
    if (this.props.two_datasets_mode) {
      metric_1 = this.props.dataset_labels[0]
        .split(" (")[0].split(': ')[0]
      if (this.props.dataset_labels.length > 1) {
        metric_2 = this.props.dataset_labels[1]
          .split(" (")[0].split(': ')[0]
      }
    } else if (this.props.y_axis_labels != null) {
      metric_1 = this.props.y_axis_labels[0]
      metric_2 = this.props.y_axis_labels.find(label => label != metric_1)
    }

    let _this = this

    const animation = this.props.animation ? {} : { duration: 0 }

    const newState = {
      data: {
        labels: this.getXAxisLabels(),
        datasets: this.generateDatasets(),
      },
      options: {
        animation: animation,
        maintainAspectRatio: false,
        responsive: true,
        borderColor: '#ffffff',
        color: '#ffffff',
        interaction: {
          mode: "index",
          intersect: false,
        },
        layout: {
          padding: {
            top: 10,
            right: 10,
          },
        },
        plugins: {
          title: {
            display: this.props.title,
            text: this.props.title,
            font: {
              size: 19,
              weight: "bold",
              color: '#ffffff',
            },
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItems) {
                const splits = tooltipItems[0].label.split(";")
                let title = splits[0]
                if (title.includes("-")) {
                  title = yyyymmddToMmddyyyy(title)
                }
                let title2 = splits.length > 1 ? splits[1] : null
                if (title2 != null) {
                  if (title2.includes("-")) {
                    title2 = yyyymmddToMmddyyyy(title2)
                  }
                  if (title != title2) {
                    title += " vs " + title2
                  }
                }
                return title
              },
              label: function (context) {
                const metric = context.dataset.label
                  .split(" (")[0].split(': ')[0]
                const formatter = dataFormatter(metric.trim().toLowerCase().replaceAll(" ", "_"))
                return metric + ": " + formatter(context.formattedValue)
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            size: 30,
            labels: {
              padding: 20,
              color: '#ffffff',
            },
          },
          annotation: {
            annotations: this.getAnnotations(),
          },
        },
        scales: {
          x_1: {
            stacked: true,
            grid: {
              display: false,
              color: '#ffffff',
            },
            ticks: {
              color: '#ffffff',
              autoSkip: true,
              maxTicksLimit: 15,
              callback: function (value) {
                const label =
                  this.getLabelForValue(value) != null
                    ? this.getLabelForValue(value).toString().split(";")[0]
                    : ""
                return _this.processLabel(label)
              },
            },
          },
          y_1: {
            beginAtZero: true,
            stacked: false,
            type: "linear",
            position: "left",
            color: '#ffffff',
            ticks: {
              color: '#ffffff',
              maxTicksLimit: 6,
              callback: value => this.formatTicks(metric_1, value),
            },
            grid: {
              color: gridYColor,
            },
            title: {
              display: this.props.two_datasets_mode || (this.props.y_axis_labels && metric_1),
              text: this.stripSource(metric_1),
              color: '#ffffff',
            },
            border: {
              color: 'rgba(0,0,0,0)',
            },
          },
        },
      },
    }
    if (metric_2) {
      newState.options.scales['x_2'] = {
        display: "auto",
        position: "top",
        stacked: true,
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          color: '#ffffff',
          autoSkip: true,
          maxTicksLimit: 15,
          callback: function (value) {
            const label =
              this.getLabelForValue(value) != null
                ? this.getLabelForValue(value).toString().split(";")[1]
                : ""
            return _this.processLabel(label)
          },
        },
      }
      newState.options.scales['y_2'] = {
        color: '#ffffff',
        border: {
          color: 'rgba(0,0,0,0)',
        },
        beginAtZero: true,
        display: "auto",
        stacked: false,
        type: "linear",
        position: "right",
        ticks: {
          color: '#ffffff',
          maxTicksLimit: 6,
          callback: value => this.formatTicks(metric_2, value),
        },
        grid: {
          drawOnChartArea: true, // only want the grid lines for one axis to show up
          color: gridYColor,
        },
        title: {
          display: this.props.two_datasets_mode || (this.props.y_axis_labels && metric_2),
          text: this.stripSource(metric_2),
          color: '#ffffff',
        },
      }
    }
    this.setState(newState)
  }

  render() {
    if (this.state.data) {
      return (
        <Line
          data={this.state.data}
          options={this.state.options}
        />
      )
    } else {
      return null
    }
  }
}
