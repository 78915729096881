import { Component } from 'react'
import { connect } from 'react-redux'
import { getVariables, updateVariables, updateHistory } from '../../../api/workflows'
import FTNotice from '../../FTNotice'
import Confirm from '../../Confirm'
import i18next from 'i18next'
import { Box, ButtonGroup, IconButton } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '../../widgets/Button'
import Select from '../../widgets/Select'
import Option from '../../widgets/Option'
import TextField from '../../widgets/TextField'

class Variables extends Component {
  READ_ONLY_FIELDS = ['internal_client_id', 'run_id', 'user_id', 'workflow_id']
  ANY = 'ANY'
  NUMBER = 'NUMBER'
  LIST_OF_NUMBERS = 'LIST_OF_NUMBERS'

  static defaultProps = {
    passBack: () => { },
  }

  static getDerivedStateFromProps(props) {
    return {
      internal_client_id: props.current_client_id,
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      variables: null,
      edit: false,
      internal_client_id: props.current_client_id,
      original: [],
      confirmAddDelete: false,
      askAllClients: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.run_id != this.props.run_id) {
      this.getData()
    }
  }

  getData() {
    getVariables(this.props.workflow_id, this.props.run_id, this.state.internal_client_id)
      .then(response => {
        let variables = response.data.filter(v => !['internal_client_id', 'run_id', 'user_id', 'workflow_id'].includes(v.name))
        variables.unshift(
          { name: 'internal_client_id', value: this.state.internal_client_id, workflow: this.props.workflow_id, type: this.NUMBER },
          { name: 'run_id', value: this.props.run_id, workflow: this.props.workflow_id, type: this.ANY },
          { name: 'user_id', value: this.props.current_user_id, workflow: this.props.workflow_id, type: this.NUMBER },
          { name: 'workflow_id', value: this.props.workflow_id, workflow: this.props.workflow_id, type: this.NUMBER },
        )
        this.setState(
          { original: variables.map(variable => Object.assign({}, variable)), variables: variables },
          () => this.props.passBack(variables)
        )
      })
  }

  setVariableName(index, name) {
    let variables = [...this.state.variables]
    variables[index].name = name
    this.setState(
      { variables: variables },
      () => this.props.passBack(variables)
    )
  }

  setVariableType(index, type) {
    let variables = [...this.state.variables]
    variables[index].type = type
    this.setState(
      { variables: variables },
      () => this.props.passBack(variables)
    )
  }

  setVariableValue(index, value) {
    let variables = [...this.state.variables]
    variables[index].value = value
    this.setState(
      { variables: variables },
      () => this.props.passBack(variables)
    )
  }
  
  isNumber(value) {
    value = value + ''
    return value.trim() != '' && !isNaN(value)
  }
  
  isNumberList(value) {
    value = value + ''
    return !value.split(',').map(v => v.trim()).find(v => !this.isNumber(v))
  }

  validate() {
    const variables = this.state.variables
    const badVariable = variables.find(variable => variable.name.trim() == '')
    if (badVariable != null) {
      FTNotice('workflowVariables.enterVariableName', 15000)
      return false
    }
    const variableNames = new Set(variables.map(variable => variable.name))
    if (this.state.variables.length > variableNames.size) {
      FTNotice('workflowVariables.variableNameMustBeUnique', 15000)
      return false
    }
    const badNumberValue = variables.find(variable => variable.type == this.NUMBER && !this.isNumber(variable.value))
    if (badNumberValue) {
      FTNotice(i18next.t('workflowVariables.valueMustBeNumber', {variable: badNumberValue.name}))
      return false
    }
    const badNumberListValue = variables.find(variable => variable.type == this.LIST_OF_NUMBERS && !this.isNumberList(variable.value))
    if (badNumberListValue) {
      FTNotice(i18next.t('workflowVariables.valueMustBeListOfNumbers', {variable: badNumberListValue.name}))
      return false
    }
    return true
  }
  
  saveForRun() {
    if (this.validate()) {
      updateHistory(this.props.run_id, {workflow_id: this.props.workflow_id, variables: this.state.variables.filter(v => !['internal_client_id', 'run_id', 'user_id', 'workflow_id'].includes(v.name))}).then(() => {
        this.setState({ edit: false })
      })
    }
  }

  update() {
    if (this.validate()) {
      const added = this.state.variables.filter(variable => !this.READ_ONLY_FIELDS.includes(variable) && this.state.original.find(candidate => candidate.name == variable.name) == null).map(v => v.name)
      const deleted = this.state.original.filter(variable => this.state.variables.find(candidate => candidate.name == variable.name) == null).map(v => v.name)
      if (this.props.allClients && (added.length > 0 || deleted.length > 0)) {
        const messages = []
        if (deleted.length > 0) {
          messages.push(i18next.t('workflows.confirmDelete', { variables: deleted.join(', ') }))
        }
        if (added.length > 0) {
          messages.push(i18next.t('workflows.confirmAdd', { variables: added.join(', ') }))
        }
        this.setState({ confirmAddDelete: messages.join('\n\n') })
      } else {
        this.confirmAddDelete(true)
      }
    }
  }

  confirmAddDelete(confirm) {
    this.setState({ confirmAddDelete: false })
    if (confirm) {
      const changed = this.state.original.find(variable => this.state.variables.find(candidate => candidate.name == variable.name && (candidate.value != variable.value || candidate.type != variable.type))) != null
      if (this.props.allClients && changed) {
        this.setState({ askAllClients: true })
      } else {
        this.updateValues(false)
      }
    }
  }

  updateValues(allClients) {
    const variables = [...this.state.variables]
    variables.splice(0, 4) // don't change internal_client_id, run_id, user_id or workflow_id
    updateVariables(variables, this.props.workflow_id, this.state.internal_client_id, allClients).then(response => {
      if (response.success) {
        FTNotice('workflowVariables.variablesHaveBeenUpdated', 3000)
        this.setState({ askAllClients: false, edit: false, original: variables.map(variable => Object.assign({}, variable)) })
      } else {
        FTNotice(response.error, 15000)
        this.setState({ askAllClients: false })
      }
    })
  }

  add() {
    let variables = [...this.state.variables];
    variables.push({ name: '', value: '', type: this.ANY, workflow: this.props.workflow_id });
    this.setState(
      { variables: variables },
      () => this.props.passBack(variables)
    )
  }

  deleteVariable(variable) {
    const variables = this.state.variables.filter(v => v != variable)
    this.setState(
      { variables: variables },
      () => this.props.passBack(variables)
    )
  }

  render() {
    if (this.state.variables) {
      return (
        <div className={this.props.siteMenu ? 'site-menu' : null}>
          {this.props.siteMenu && (
            <h3 style={{ textAlign: 'center', color: 'white' }}>Variables</h3>
          )}
          <Confirm opened={this.state.confirmAddDelete != false} title="workflows.allClients" question={this.state.confirmAddDelete}
            action={confirm => { this.confirmAddDelete(confirm) }} />
          <Confirm opened={this.state.askAllClients} title="workflows.allClients" question="workflows.allClientsQuestion"
            action={allClients => { this.updateValues(allClients) }} button="workflows.yes" cancelButton="workflows.no" />
          {this.state.variables.map((variable, i) => {
            return (
              <Box key={i} display="flex" width="100%">
                <div style={{ marginBottom: this.props.siteMenu ? 20 : 0 }}>
                  <div style={{ float: 'right' }}>
                    {this.state.edit && i >= this.READ_ONLY_FIELDS.length
                      ?
                      <Box>
                        <IconButton style={{ padding: 0, paddingRight: 4, paddingTop: 34 }} onClick={() => this.deleteVariable(variable)}><DeleteIcon fontSize="small" /></IconButton>
                      </Box>
                      : null
                    }
                  </div>
                  {/* name of variable */}
                  <Box sx={{ paddingRight: 2, paddingLeft: 2 }}>
                    {this.state.edit ?
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': {
                            m: 1,
                            width: '300px',
                          },
                          bgcolor: "#f9f9f9",
                          borderRadius: 3,
                          padding: 1
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          disabled={i < this.READ_ONLY_FIELDS.length}
                          label={i18next.t('workflowVariables.name')}
                          value={variable.name}
                          variant="outlined"
                          onChange={event => this.setVariableName(i, event.target.value)} />
                        {this.props.isAdmin && (
                          <Select
                            disabled={i < this.READ_ONLY_FIELDS.length}
                            labelText="workflowVariables.type"
                            variant='outlined'
                            value={variable.type}
                            onChange={event => this.setVariableType(i, event.target.value)}
                            sx={{
                              '& > svg': {
                                left: 130,
                                display: i < this.READ_ONLY_FIELDS.length ? 'none' : null
                              }
                            }} >
                            <Option labelText="workflowVariables.any" value={this.ANY} />
                            <Option labelText="workflowVariables.number" value={this.NUMBER} />
                            <Option labelText="workflowVariables.listOfNumbers" value={this.LIST_OF_NUMBERS} />
                          </Select>
                        )}
                        <TextField
                          disabled={i < this.READ_ONLY_FIELDS.length}
                          label={i18next.t('workflowVariables.defaultValue')}
                          value={variable.value}
                          variant="outlined"
                          onChange={event => this.setVariableValue(i, event.target.value)} />
                      </Box>
                      :
                      <Box component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '300px' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          disabled
                          readOnly={i < this.READ_ONLY_FIELDS.length}
                          label={variable.name.replace(/_/g, " ").toUpperCase()}
                          defaultValue={variable.value}
                          variant="standard"
                        />
                      </Box>
                    }
                  </Box>
                </div>
              </Box>
            )
          })
          }
          {
            this.state.edit
              ? <Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "flex-start",
                  paddingLeft: 3
                }}>
                  <Button
                    variant="outlined"
                    buttonText="workflowVariables.add"
                    onClick={() => this.add()}
                    startIcon={<AddCircleIcon />}
                  />
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}>
                  <ButtonGroup >
                    <Button variant="standard" buttonText="workflowVariables.saveForRun" onClick={() => this.saveForRun()} />
                    <Button variant="standard" buttonText="workflowVariables.saveDefaults" onClick={() => this.update()} />
                  </ButtonGroup>
                </Box>
              </Box>
              : <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 3,
              }}>
                <Button buttonText="workflowVariables.edit" onClick={() => this.setState({ edit: true })} />
              </Box>
          }
          <div style={{ textAlign: 'center' }}>
            {this.state.siteMenu && (
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              >
                <Button buttonText="workflowVariables.close" onClick={() => this.setState({ edit: false })} />
              </Box>
            )}
          </div>
          <div className="clearfix"></div>
        </div >
      )
    }
    else {
      return null
    }
  }
}

const mapStateToProps = function (state) {
  return {
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
    current_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    current_user_id: state.users.user ? state.users.user.id : null,
  }
}

export default connect(mapStateToProps)(Variables)
