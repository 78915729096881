import _ from "lodash"
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import { Component, createRef } from 'react'
import { getSQLTemplates } from  '../../api/sql_templates'
import { getOfflineConversion, createOfflineConversion, updateOfflineConversion, deleteOfflineConversion, previewOfflineConversionQuery, exportOfflineConversionQuery } from '../../api/offline_conversions'
import { getColumns } from '../../api/database'
import { validateSQLBeforeSave } from '../../utilities/utils'
import { capitalize } from '../../utilities/formatting'
import FTNotice from '../FTNotice'
import Form from '../Form'
import Field from '../Field'
import Table from '../widgets/Table'
import SQLEditor from '../SQLEditor'
import i18next from 'i18next'

class Interface extends Component {
  FACEBOOK = 'facebook'
  GOOGLE = 'google'
  
  tableRef = createRef()
  previewRef = createRef()
    
  componentDidMount() {
    if (this.props.match.params.id == 'new') {
      this.setState({
        internal_client: this.props.internal_client_id,
        name: '',
        description: '',
        adjustTime: false,
        sql: '',
        engine: this.GOOGLE
      })
    } else {
      this.getOfflineConversion()
    }
    this.getTemplates()
  }

  getTemplates() {
    getSQLTemplates().then(response => {
      this.setState({sql_templates: response.results})
    })
  }

  handleSelectTemplate(template_id) {
    this.state.sql_templates.forEach(template => {
      if(template.id == template_id) {
        this.setState({sql_template: template_id, sql: template.sql})
      }
    })
  }

  getOfflineConversion() {
    getOfflineConversion(this.props.match.params.id).then(result => {
      this.setState(result)
    })
  }

  save() {
    const values = {
      internal_client_id: this.state.internal_client,
      name: this.state.name,
      engine: this.state.engine,
      description: this.state.description,
      adjust_time: this.state.adjust_time,
      sql: this.state.sql,
    }
    if (this.state.name.trim() == '') {
      FTNotice('offlineConversions.enterName', 15000)
    } else if (this.state.sql.trim() == '') {
      FTNotice('offlineConversions.enterSql', 15000)
    } else {
      const id = this.state.id
      const api_call = id ? updateOfflineConversion(id, values) : createOfflineConversion(values)
      const action = id ? 'updated' : 'added'
      api_call.then((result) => {
        if (result.id) {
          window.location.href = '/#/offline_conversions'
        } else {
          FTNotice(i18next.t('offlineConversions.error', { action: action, error: result.error}))
        }
      }).catch((result) => {
          FTNotice(i18next.t('offlineConversions.error', { action: action, error: result.error}))
      })
    }
  }

  deleteOfflineConversion() {
    deleteOfflineConversion(this.props.match.params.id).then((result) => {
      if (result.success) {
        window.location.href = '/#/offline_conversions';
      } else {
        FTNotice('offlineConversions.errorDeleting', 15000)
      }
    }).catch(() => {
      FTNotice('offlineConversions.errorDeleting', 15000)
    });
  }

  validate(sql) {
    return validateSQLBeforeSave(sql, 'redshift', null, true).then(error => {
      if (!error) {
        const desired_columns = ['click_id', 'conversion_date', 'conversion_name', 'revenue', 'currency']
        if (this.state.engine == this.FACEBOOK) {
          desired_columns.push('customer_id')
        }
        return getColumns({'sql': sql}).then(response => {
          if (!response.data || desired_columns.find(col => !response.data.includes(col))) {
            return i18next.t('offlineConversions.queryMustReturn', {columns: desired_columns.join(', ')})
          }
        })
      } else {
        return error
      }
    })
  }

  preview() {
    this.setState({preview: true}, () => {
      const table = this.tableRef.current
      if (table) {
        table.refreshRows()
      }
      this.showPreview()
    })
  }

  showPreview() {
    const previewWidget = this.previewRef.current
    if (previewWidget) {
      previewWidget.scrollIntoView()
    }
  }

  getColumns() {
    const headers = [{
      id: 'click_id',
      header: 'offlineConversions.clickId',
      datatype: 'text',
      width: 680,
    },{
      id: 'conversion_date',
      header: 'offlineConversions.conversionDate',
      datatype: 'date',
      width: 140,
    },{
      id: 'conversion_name',
      header: 'offlineConversions.conversionName',
      datatype: 'text',
      width: 350,
    },{
      id: 'internal_revenue',
      datatype: 'numeric',
      width: 145,
    },{
      id: 'currency',
      header: 'offlineConversions.currency',
      datatype: 'text',
      width: 120,
    }]
    if (this.state.engine == this.FACEBOOK) {
      headers.push({
        id: 'customer_id',
        header: 'offlineConversions.customerId',
        datatype: 'text',
        width: 200,
      })
    }
    headers.push({
      id: 'status',
      header: 'offlineConversions.status',
      datatype: 'text',
      width: 700,
    })
    return headers
  }

  render() {
    if (this.state && this.state.name != null) {
      return (
        <Form
          objectType="offlineConversions.offlineConversion"
          objectName={this.state.title}
          newObject={this.props.match.params.id == 'new'}
          updateObject={() => {this.save()}}
          deleteObject={() => {this.deleteOfflineConversion()}}
          parentLink={'/offline_conversions'}>
          <Field label="offlineConversions.name">
            <input type="text" value={this.state.name} onChange={event => {this.setState({name: event.target.value})}} className="form-control"/>
          </Field>
          <Field label="offlineConversions.engine">
            <select className="form-control" value={this.state.engine} onChange={event => this.setState({engine: event.target.value})}>
              <option value={'bing'}>{i18next.t('offlineConversions.bing')}</option>
              <option value={'facebook'}>{i18next.t('offlineConversions.facebook')}</option>
              <option value={'google'}>{i18next.t('offlineConversions.google')}</option>
            </select>
          </Field>
          <Field label="offlineConversions.description">
            <textarea value={this.state.description} onChange={event => {this.setState({description: event.target.value})}} className="form-control" rows="5"/>
          </Field>
          <Field>
            <div>
              <input
                type="checkbox"
                style={{verticalAlign: 'top'}}
                checked={this.state.adjust_time}
                onChange={event => this.setState({adjust_time: event.target.checked})}
              />&nbsp;{i18next.t('offlineConversions.adjustTime')}
            </div>
          </Field>
          <Field label="offlineConversions.sqlTemplate">
            <select className="form-control" value={this.state.sql_template} onChange={event => this.handleSelectTemplate(event.target.value)}>
              <option value={''}></option>
              {this.state.sql_templates ? this.state.sql_templates.map(template => {
                return (<option key={template.id} value={template.id} >{template.name}</option>);
              }) : null}
            </select>
          </Field>
          <Field
            label="offlineConversions.sql"
            validate={
              <div className="js_complete">
                <button className="form-control btn btn-success mt-25" onClick={() => this.preview()}>{i18next.t('offlineConversions.preview')}</button>
              </div>
            }
          >
            <SQLEditor
              code={this.state.sql}
              instructions={
                <div dangerouslySetInnerHTML={{__html:
                  i18next.t('offlineConversions.instructions') + 
                  '<br/>' + 
                  i18next.t([this.FACEBOOK].includes(this.state.engine) ? ('offlineConversions.instructions' + capitalize(this.state.engine)) : 'offlineConversions.instructionsColumns').replace(/\n/g, '<br/>')}} />
              }
              validate={(sql) => this.validate(sql)}
              onChange={(sql) => this.setState({sql: sql})}
            />
          </Field>
          {this.state.preview && (
            <div>
              <div ref={this.previewRef} className="col-md-12 mt-25" style={{fontWeight: 'bold', marginLeft: 20}}>{i18next.t('offlineConversions.pending')}</div>
              <div className="col-md-12">
                <div style={{width: 'calc(100% - 40px)', margin: 'auto'}}>
                  <Table
                    fetch={(tableData) => {return previewOfflineConversionQuery(this.state.internal_client, this.state.sql, 'pending', this.state.engine, tableData)}}
                    export={(tableData) => {return exportOfflineConversionQuery(this.state.internal_client, this.state.sql, 'pending', this.state.engine, tableData)}}
                    columns={this.getColumns()} />
                </div>
              </div>
              <div className="col-md-12 mt-25" style={{fontWeight: 'bold', marginLeft: 20}}>{i18next.t('offlineConversions.uploaded')}</div>
              <div className="col-md-12">
                <div style={{width: 'calc(100% - 40px)', margin: 'auto'}}>
                  <Table
                    ref={this.tableRef}
                    fetch={(tableData) => {return previewOfflineConversionQuery(this.state.internal_client, this.state.sql, 'uploaded', this.state.engine, tableData)}}
                    export={(tableData) => {return exportOfflineConversionQuery(this.state.internal_client, this.state.sql, 'uploaded', this.state.engine, tableData)}}
                    columns={this.getColumns()} />
                </div>
              </div>
            </div>
          )}
        </Form>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
