import { Component } from 'react'
import { connect } from 'react-redux'
import { login } from '../../api/auth'
import { setToken } from '../../redux/users/sagaActions'
import request from "../../api/request"
import i18next from 'i18next'
import * as qs from 'query-string'

class Interface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      error:  qs.parse(this.props.location.search).error,
      notify: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    const loadScript = function (src) {
      const tag = document.createElement('script')
      tag.async = true
      tag.defer = true
      tag.src = src
      const body = document.getElementsByTagName('body')[0]
      body.appendChild(tag)
    }

    loadScript('https://accounts.google.com/gsi/client')
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState({[target.name]: value, error: null, notify: null})
  }

  login(event) {
    event.preventDefault()
    login({
      email: this.state.email,
      password: this.state.password,
      remember_me: this.state.rememberMe,
    }).then((json) => {
      if (json.error) {
        this.setState({error: json.error})
      } else {
        this.props.dispatch(setToken(json.token))
      }
    }).catch((err) => {
      console.log(err)
      this.setState({error: 'Invalid Credentials'})
    })
  }

  handleForgotPassword = (e) => {
    e.preventDefault()
    if(this.state.email === '') {
      this.setState({error: 'Please provide an email'})
    } else {
      const url = '/api/users/forgot_password'
      request(url, {}, 'POST', {email: this.state.email})
      this.setState({notify: 'An email was sent with instructions to reset your password'})
    }
  }

  isFTOptimize = () => {
    return location.host == 'localhost' || location.host.endsWith('.ftoptimize.com')
  }

  getLoginUrl = () => {
    return location.protocol + '//' + location.host + '/api/login_google'
  }

  render() {
    return (
      <div className="login-page js_complete" style={{height: 'calc(100vh + 106px)', paddingTop: '106px', marginTop: '-106px'}}>
        <div className="login-box">
          <div className="login-logo">
            <b>{i18next.t('login.ft')}</b>{i18next.t('login.ami')}
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">{i18next.t('login.signInToStart')}</p>
            <form className="new_user" onSubmit={event => this.login(event)}>
              <div className="form-group has-feedback">
                <input autoFocus="autofocus" className="form-control" type="email" name='email' value={this.state.email} onChange={this.handleInputChange} autoComplete="username"/>
                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
              </div>
              <div className="form-group has-feedback">
                <input className="form-control" type="password" name='password'  value={this.state.password} onChange={this.handleInputChange} autoComplete="current-password" />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              </div>
              <div className="row">
                <div className="col-xs-8">
                  <div className="checkbox">
                    <input type="checkbox" name="rememberMe" checked={this.state.rememberMe} onChange={this.handleInputChange} />
                    <label> {i18next.t('login.rememberMe')}</label>
                  </div>
                </div>
                <div className="col-xs-4">
                  <button name="submitButton" className="btn btn-primary btn-block btn-flat" type="submit">{i18next.t('login.signIn')}</button>
                </div>
              </div>
              <div className='row text-center'>
                <a onClick={this.handleForgotPassword}>{i18next.t('login.forgotPassword')}</a>
              </div>
              {this.isFTOptimize() && (
                <div className="row" style={{padding: '10px 70px 0px 70px'}}>
                  <div className="col-xs-12">
                    <div id="g_id_onload"
                      data-client_id="83168457598-5akan6uss0sj7bdg0b7a6or22do1aap8.apps.googleusercontent.com"
                      data-login_uri={this.getLoginUrl()}
                      data-auto_prompt="false">
                    </div>
                    <div className="g_id_signin"
                      data-type="standard"
                      data-size="large"
                      data-theme="outline"
                      data-text="sign_in_with"
                      data-shape="rectangular"
                      data-logo_alignment="left">
                    </div>
                  </div>
                </div>
              )}
            </form>
            {
              this.state.error != null &&
              <div style={{color: 'red', marginTop: 10}}>{this.state.error}</div>
            }
            {
              this.state.notify != null &&
              <div>{this.state.notify}</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(_) {
  return {}
}

export default connect(mapStateToProps)(Interface)
