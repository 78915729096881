import request from './request'

export function login(params) {
  return request('/api/login', {}, 'POST', params);
}

export function logout() {
  return request('/api/logout', {}, 'POST');
}

export function verifyPasswordResetToken(url) {
  return request(url);
}
