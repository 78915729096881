import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FTNotice from './FTNotice'
import DatePicker from './widgets/DatePicker'
import i18next from 'i18next'

/*
Request data from the user.  Passes in properties:
opened - whether the dialog should be open (true or false)
title - the title of the dialog (ex., Delete User)
description - optional - description on how to use
fields - an array of fields to ask for
  ex. [{id: 'shop_name', label: 'Shop Name', help: 'as in <shop name>.myshopify.com', defaultValue: '', required: true}]
  help is optional and defaults to null)
  type is optional and defaults to text (other values: dateRange, checkbox)
  defaultValue is optional and defaults to '' (only used for text and checkbox)
  defaultStart is optional (only used for dateRange)
  defaultEnd is optional (only used for dateRange)
  maxDate is optional (only used for dateRange)
  required is optional and defaults to false
button - Name of the confirmation button (optional; defaults to OK).
action - function to be called on completion (takes a parameter which contains the data if confirmed, null otherwise).  Return true if error.
*/
const RequestData = ({opened, title, fields, action, description = null, button = 'requestData.ok'}) => {
  const [formData, setFormData] = useState({})
  
  const setDefaults = () => {
    const formData = {}
    fields.forEach((field) => {
      formData[field.id] = field.defaultValue ? field.defaultValue : ''
    })
    setFormData(formData)
  }

  useEffect(() => {
    setDefaults()
  }, [])
  
  const updateField = (field, value) => {
    const newFormData = Object.assign({}, formData)
    newFormData[field.id] = value
    setFormData(newFormData)
  }

  const cancel = (event) => {
    event.stopPropagation()
    action(null)
    setDefaults()
  }

  const ok = (event) => {
    event.stopPropagation()
    let valid = true
    fields.forEach(field => {
      if (field.required && formData[field.id].trim() == '') {
        FTNotice(i18next.t('requestData.requiredError', {field: i18next.t(field.label)}), 15000)
        valid = false
      }
    })
    if (valid) {
      if (!action(formData)) {
        setTimeout(() => {
          setDefaults() // wait until after close      
        }, 500)
      }
    }
  }
  
  const hasDate = fields.find(field => field.type == 'dateRange')

  return (
    <div>
      <Dialog
        open={opened}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={hasDate ? 'lg' : 'sm'}
      >
        <DialogTitle id="alert-dialog-title">{i18next.t(title)}</DialogTitle>
        <DialogContent>
        {description && (
          <div>{i18next.t(description)}</div>
        )}
        <table>
          <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              <td style={{padding: 5, textAlign: 'right'}}>{i18next.t(field.label)}{field.help ? (' (' + i18next.t(field.help) + ')') : ''}{field.required ? '*' : ''}</td>
              <td style={{padding: 5}}>
                {field.type == 'dateRange' && (
                  <DatePicker
                    autoApply={true}
                    stayOpen={true}
                    start={field.defaultStart}
                    end={field.defaultEnd}
                    maxDate={field.maxDate}
                    callback={dates => updateField(field, dates)}
                    style={{minHeight: 320, minWidth: 650}}
                  />
                )}
                {field.type == 'checkbox' && (
                  <input type='checkbox' checked={formData[field.id] == true} onChange={event => updateField(field, event.target.checked)} />
                )}
                {!['dateRange', 'checkbox'].includes(field.type) && (
                  <input className="form-control" value={formData[field.id]} onChange={event => updateField(field, event.target.value)} />
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        <div style={{textAlign: 'center'}}>{i18next.t('requestData.required')}</div>
        </DialogContent>
        <div className="text-center" style={{marginBottom: 20}}>
          <button className="btn btn-primary" onClick={ok}>{i18next.t(button)}</button>
          <button className="btn btn-primary" onClick={cancel} style={{marginLeft: 10}}>{i18next.t('requestData.cancel')}</button>
        </div>
      </Dialog>
    </div>
  )
}

export default RequestData