import React, { useEffect, useState } from 'react'
import withRouter from '../../wrappers/withRouter'
import { getModelParameters, getModelOptimalParameters, saveModelParameters, getPending } from '../../api/data_science'
import Table from '../widgets/Table'
import FTNotice from '../FTNotice'
import Form from '../Form'
import i18next from 'i18next'

const ModelParameters = ({match}) => {
  const modelId = match.params.id
  const [parameters, setParameters] = useState([])
  const [pending, setPending] = useState(false)
  const [optimized, setOptimized] = useState(false)
  
  const getColumns = () => {
    return [{
      id: 'name',
      header: 'dataScience.name',
      datatype: 'text',
      width: 150,
    },{
      id: 'value',
      header: 'dataScience.value',
      datatype: 'number',
      width: 150,
    }]
  }
  
  const getResult = (guid) => {
    getPending(guid).then((result) => {
      if (result.pending) {
        setPending(true)
        setTimeout(() => getResult(guid), 5000)
      } else if (result.error) {
        setPending(false)
        FTNotice(result.error, 15000)
      } else {
        setPending(false)
        setParameters(result.results)
      }
    })
  }
  
  useEffect(() => {
    getModelParameters(modelId).then(result => setParameters(result.results))
  }, [])
  
  const optimize = () => {
    setParameters(null)
    setOptimized(true)
    getModelOptimalParameters(modelId).then(result => {
      if (result.guid) {
        getResult(result.guid)
      } else {
        FTNotice(result.error, 15000)
      }
    })
  }
  
  const save = () => {
    saveModelParameters(modelId, parameters).then(result => {
      if (result.error) {
        FTNotice(result.error, 15000)
      } else {
        location.href = `/#/data_science/${modelId}`
      }
    })
  }

  return (
    <div style={{cursor: pending ? 'wait' : null}}>
      <Form
        objectType="dataScience.parameters"
        updateObject={() => save()}
        canEdit={optimized && parameters != null}
        canDelete={false}
        otherButtons={optimized ? null : <button className="btn btn-primary" onClick={() => optimize()}>{i18next.t('dataScience.optimize')}</button>}
        parentLink={`/data_science/${modelId}`}>
        <div style={{marginLeft: 30, marginRight: 30, marginTop: 35}}>
          <h3>{i18next.t('dataScience.parameters')}</h3>
          {parameters == null && (
            <div>{i18next.t('dataScience.loadingParameters')}</div>
          )}
          {parameters != null && (
            <Table
              dataset={parameters}
              columns={getColumns()}
              autoHeight={true} />
          )}
        </div>
      </Form>
    </div>
  )
}

export default withRouter(ModelParameters)
