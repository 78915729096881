import { useState } from 'react'
import { Box, Typography, IconButton, Fade, Tooltip, Chip, Stack } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { updatePrompt } from '../../api/prompts'
import FTNotice from '../FTNotice'

const AiRating = ({
  feedback,
  setFeedback,
  rating,
  setRating,
  promptId
}) => {
  const [ratingClicked, setRatingClicked] = useState(null)
  const [feedbackClicked, setFeedbackClicked] = useState(null)

  let semiWhite = 'rgba(255, 255, 255, 0.5)'

  const handleRatingClick = (value, index) => {
    setRatingClicked(index)
    setTimeout(() => {
      setRating(value)
      setRatingClicked(null)
    }, 500)
    updatePrompt(promptId, { rating: value == 'good' ? 1 : 0 }).then(response => {
      if (response?.rating == null) {
        FTNotice(i18next.t('ai.errorProcessing'))
        setRating(false)
      }
    }).catch(_ => {
      FTNotice(i18next.t('ai.errorProcessing'))
      setRating(false)
    })
  }

  const handleFeedbackClick = (value, index) => {
    setFeedbackClicked(index)
    setTimeout(() => {
      setFeedback(value)
      setFeedbackClicked(null)
    }, 500)
    updatePrompt(promptId, { rating_reason: value }).then(response => {
      if (response?.rating_reason == null) {
        FTNotice(i18next.t('ai.errorProcessing'))
        setFeedback('false')
      }
    }).catch(_ => {
      FTNotice(i18next.t('ai.errorProcessing'))
      setFeedback(false)
    })
  }

  const ratingDimensions = ['intelligence', 'accuracy', 'formatting']
  const ratings = ['good', 'not good']

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignContent='center'>
      {rating == false ? <Fade in={rating == false ? true : false}>
        <Box>
          <Stack spacing={1} direction='row'>
            <Typography sx={{ color: 'white.100', alignSelf: 'center' }}>Rate response: </Typography>
            {ratings.map((value, i) => (
              <Tooltip title={value} key={i}>
                <IconButton onClick={() => handleRatingClick(value, i)}
                  sx={{
                    color: ratingClicked == i ? 'success.main' : semiWhite,
                    '&:hover': {
                      color: i == 0 ? 'success.main' : 'error.main'
                    }
                  }}>
                  {i == 0 &&
                    <ThumbUpIcon />}
                  {i == 1 &&
                    <ThumbDownIcon />}
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        </Box>
      </Fade> : null}
      {rating != '' && feedback == '' ? <Fade in={rating != '' && feedback == '' ? true : false}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' }}>
          <Stack spacing={1} direction='row'>
            <Typography sx={{ color: 'white.100', alignSelf: 'center' }}>{rating.charAt(0).toUpperCase() + rating.slice(1)}: </Typography>
            {ratingDimensions.map((dim, i) => (
              <Tooltip key={i} title={dim}>
                <Chip
                  sx={{
                    backgroundColor: feedbackClicked == i ? 'success.main' : semiWhite,
                    '&:hover': {
                      backgroundColor: 'success.main'
                    },
                    color: 'white.100',
                  }} label={<strong>{dim}</strong>} onClick={() => handleFeedbackClick(dim, i)} />
              </Tooltip>
            ))}
          </Stack>
        </Box>
      </Fade> : null}
    </Box>

  )
}

export default AiRating
