import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import Form from '../Form'
import Field from '../Field'
import FTNotice from "../FTNotice"
import { getUser, createUser, updateUser, deleteUser } from '../../api/users'
import { getAllDashboards } from '../../api/dashboards'
import { getUser as getCurrentUser } from '../../redux/users/sagaActions'
import i18next from 'i18next'

class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      availableDashboards: [],
    }
    if (props.match.params.id != null) {
      this.getUser(props.match.params.id)
    } else if (this.isProfile(props)) {
      this.getUser(props.currentUser.id)
    } else {
      this.state.user = {
        name: '',
        email: '',
        role: 'User',
        password: '',
        password_confirmation: '',
        client_ids: [],
        dashboard_ids: [],
        enable_time_tracking: false,
      }
    }
  }
  
  isProfile(props) {
    return props.location.pathname == '/users/profile' || props.location.pathname == '/'
  }

  getUser(id) {
    getUser(id).then((user) => {
      user.password = ''
      user.password_confirmation = ''
      this.setState({user: user}, () => this.getAvailableDashboards())
    })
  }
  
  getAvailableDashboards() {
    if (this.state.user.client_ids.length > 0) {
      getAllDashboards(false, this.state.user.client_ids, 'Dashboards', null, true).then(response =>
        this.setState({availableDashboards: response.results})
      )
    } else {
      this.setState({availableDashboards: []})
    }
  }

  handleChange(event) {
    let user = Object.assign({}, this.state.user)
    if(event.target.type === "select-multiple") {
      let selected = Array.from(event.target.selectedOptions).map(option => (parseInt(option.value)))
      user[event.target.name] = selected
    } else if(event.target.type === 'checkbox') {
      user[event.target.name] = event.target.checked
    } else {
      user[event.target.name] = event.target.value
      if (event.target.name == 'role') {
        user['enable_time_tracking'] = event.target.value == 'Admin'
      }
    }
    if (event.target.name == 'client_ids') {
      this.setState({user}, () => this.getAvailableDashboards())
    } else {
      this.setState({user})
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.state.user.name.trim() == '') {
      FTNotice('user.enterName', 3000)
    } else if (this.state.user.email.trim() == '') {
      FTNotice('user.enterEmail', 3000)
    } else if (!this.state.user.id && this.state.user.password.trim() == '') {
      FTNotice('user.enterPassword')
    } else if (this.state.user.password != this.state.user.password_confirmation) {
      FTNotice('user.passwordsDontMatch', 3000)
    } else if (this.state.user.role != 'Admin' && this.state.user.client_ids.length == 0) {
      FTNotice('user.selectClients', 3000)
    } else if (this.state.user.role == 'Limited User' && this.state.user.dashboard_ids.length == 0) {
      FTNotice('user.selectDashboards', 3000)
    } else {
      if (this.state.user.id) {
        updateUser(this.state.user).then((response) => {
          if (response == null || response.error) {
            FTNotice(i18next.t('user.couldNotUpdateUser', {'error': response ? response.error : i18next.t('user.unknownError')}), 15000)
          } else {
            this.props.dispatch(getCurrentUser()) // In case it is the current user
            if (!this.isProfile(this.props)) {
              window.location.href='/#/users'
            } else {
              FTNotice(i18next.t('user.userUpdated'))
            }
          }
        })
      } else {
        createUser(this.state.user).then((response) => {
          if (response == null || response.error) {
            FTNotice(i18next.t('user.couldNotCreateUser', {'error': response ? response.error : i18next.t('user.unknownError')}), 15000)
          } else {
            if (!this.isProfile(this.props)) {
              window.location.href='/#/users'
            } else {
              FTNotice(i18next.t('user.userUpdated'))              
            }
          }
        })
      }
    } 
  }

  deleteUser() {
    deleteUser(this.state.user.id).then(() => {
      FTNotice('user.userDeleted', 3000)
      window.location.href = '/#/users'
    }).catch(() => {
      FTNotice('user.couldNotDeleteUser', 15000)
    })
  }

  render() {
    return (
      <div>
        { this.state.user &&
          <Form
            objectType="user.user"
            objectName={this.state.user.name}
            newObject={!this.state.user.id}
            updateObject={user => {this.handleSubmit(user)}}
            canDelete={this.state.user.id && this.state.user.id != this.props.currentUser.id}
            deleteObject={() => this.deleteUser()}
            parentLink={'/users'}
          >
            <Field label="user.name">
              <input
                className='form-control' name='name'
                value={this.state.user.name}
                onChange={event => {this.handleChange(event)}} />
            </Field>
            <Field label="user.email">
              <input autoComplete="off" className='form-control' name='email' value={this.state.user.email} onChange={event => {this.handleChange(event)}} />
            </Field>
            <Field label="user.password">
              <input autoComplete="off" className='form-control' type='password' name='password' value={this.state.user.password}
                    onChange={event => {this.handleChange(event)}} />
            </Field>
            <Field label="user.passwordConfirmation">
              <input autoComplete="off" className='form-control js_complete' type='password' name='password_confirmation'
                    value={this.state.user.password_confirmation} onChange={event => {this.handleChange(event)}} />
            </Field>
            { this.props.currentUser.role === 'Admin' && (
              <Field label="user.role">
                <div className="radio radio-default">
                  <input type="radio" value="User" name="role" id="user_role_user"
                    onChange={event => {this.handleChange(event)}}
                    checked={this.state.user.role == 'User'}
                  />
                  <label htmlFor="user_role_user">{i18next.t('user.user')}</label>
                </div>
                <div className="radio radio-default">
                  <input type="radio" value="Limited User" name="role" id="user_role_limited_user"
                    onChange={event => {this.handleChange(event)}}
                    checked={this.state.user.role == 'Limited User'}
                  />
                  <label htmlFor="user_role_limited_user">{i18next.t('user.limitedUser')}</label>
                </div>
                <div className="radio radio-default">
                  <input type="radio" value="Admin" name="role" id="user_role_admin"
                    onChange={event => {this.handleChange(event)}}
                    checked={this.state.user.role == 'Admin'}
                  />
                  <label htmlFor="user_role_admin">{i18next.t('user.admin')}</label>
                </div>
              </Field>
            )}
            { this.state.user.role !== 'Admin' && this.props.currentUser.role === 'Admin' &&
              <Field label="user.clients">
                <select
                  className='form-control' name='client_ids'
                  size={5}
                  value={this.state.user.client_ids} multiple={true}
                  onChange={event => this.handleChange(event)}>
                  {
                    this.props.activeClients &&
                    this.props.activeClients.map(client =>
                      <option key={client.internal_client_id} value={client.internal_client_id}>{client.name}</option>
                    )
                  }
                </select>
              </Field>
            }
            { this.state.user.role === 'Limited User' && this.props.currentUser.role === 'Admin' &&
              <Field label="user.dashboards">
                <select
                  className='form-control' name='dashboard_ids'
                  size={5}
                  value={this.state.user.dashboard_ids} multiple={true}
                  onChange={event => {this.handleChange(event)}}>
                  {
                    this.state.availableDashboards.map(dashboard =>
                      <option key={dashboard.id} value={dashboard.id}>{dashboard.title}</option>
                    )
                  }
                </select>
              </Field>
            }
            { this.props.permissions.includes('time') && (
              <Field>
                <input
                  type="checkbox" value={this.state.user.enable_time_tracking}
                  checked={this.state.user.enable_time_tracking} name="enable_time_tracking"
                  onChange={(event) => this.handleChange(event)} /> {i18next.t('user.enableTimeTracking')}
              </Field>
            )}
          </Form>
        }
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    permissions: state.users.user ? state.users.user.permissions : [],
    activeClients: state.users.user ? state.users.user.clients : [],
    currentUser: state.users.user,
  }
}

export default withRouter(connect(mapStateToProps)(User))
