const styles = {
  node: {
    padding: 10,
    height: 80,
    width: 120,
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textOverflow: 'ellipsis',
  },
}
  
export default styles
