import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import FeedOverview from './FeedOverview'
import GetRawData from './GetRawData'
import BuildFeed from './BuildFeed'
import QaFeed from './QaFeed'
import SubmitFeed from './SubmitFeed'
import Form from '../Form'
import Confirm from '../Confirm'
import { getDataOverview } from '../../api/feeds'
import queryString from 'query-string'

class Feed extends Component {
  constructor(props) {
    super(props)
    const queries = queryString.parse(props.location.search)
    this.state = {
      tabId: this.props.match.params.tab_id ? this.props.match.params.tab_id : 'overview',
      feed_name:'',
      step_id: this.props.match.params.step_id,
      parentLink: '/feeds/section/all',
      nextTab: null,
      unsavedChange: false,
      referer: queries.referer
    }
    this.updateParentLink = this.updateParentLink.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.feed_id) {
      getDataOverview(this.props.match.params.feed_id).then((response) => {
        this.setState({'feed_name': response.name});
      })
    }
  }

  updateTab(tab) {
    if (this.props.match.params.feed_id) {
      this.setState({nextTab: tab})
      if(!this.state.unsavedChange) {
        this.jumpToTab(tab, true)
      }
    }
  }

  jumpToTab(tab, confirmed) {
    if(confirmed) {
      window.location.href = '/#/feeds/' + this.props.match.params.feed_id + '/' + tab.id;
      this.setState({
        tabId: tab.id,
        step_id: null,
        unsavedChange: false,
        nextTab: null
      });
    } else {
      this.setState({nextTab: null});
    }
  }

  updateParentLink(link, stepId) {
    const parentLink = link ? link : '/feeds/section/all';
    if (stepId != this.state.step_id) {
      this.setState({step_id: stepId, parentLink: parentLink});
      return true;
    } else if (parentLink != this.state.parentLink) {
      this.setState({parentLink: parentLink});
      return false;
    } else {
      return false; // nothing changed
    }
  }

  render() {
    const feedId = this.props.match.params.feed_id;
    const feedName = this.state.feed_name;
    const tabs = [
      {id: 'overview', name: 'Feed overview', component: <FeedOverview feed_id={this.props.match.params.feed_id} user_id={this.props.user_id} isChanged={(changed) => this.setState({unsavedChange: changed})}/>},
      {id: 'raw_data', name: 'Get raw data', component: <GetRawData feed_id={this.props.match.params.feed_id} user_id={this.props.user_id} isChanged={(changed) => this.setState({unsavedChange: changed})}/>},
      {id: 'build', name: 'Build feed', component: <BuildFeed feed_id={this.props.match.params.feed_id} step_id={this.state.step_id} updateParentLink={this.updateParentLink} user_id={this.props.user_id} isChanged={(changed) => this.setState({unsavedChange: changed})} referer={this.state.referer}/>},
      {id: 'qa', name: 'QA feed', component: <QaFeed feed_id={this.props.match.params.feed_id} step_id={this.state.step_id} updateParentLink={this.updateParentLink} user_id={this.props.user_id} isChanged={(changed) => this.setState({unsavedChange: changed})}/>},
      {id: 'submit', name: 'Submit feed', component: <SubmitFeed feed_id={this.props.match.params.feed_id} step_id={this.state.step_id} updateParentLink={this.updateParentLink} user_id={this.props.user_id} isChanged={(changed) => this.setState({unsavedChange: changed})}/>}
    ];
    let tabIndex = 0;
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id == this.state.tabId) {
        tabIndex = i;
      }
    }

    return (
      <div>
        <Confirm
          opened={this.state.unsavedChange && this.state.nextTab != null}
          title={'Unsaved Changes'}
          question={'Changes have not been saved and will be lost if you move to another tab. Are you sure you want to continue?'}
          action={(confirmed) => this.jumpToTab(this.state.nextTab, confirmed)} />
        <Form
          objectType="Feed"
          objectName={feedName}
          newObject={!feedId}
          canEdit={false}
          canDelete={false}
          parentLink={this.state.referer ? this.state.referer : this.state.parentLink}
          tabs={tabs} tabIndex={tabIndex} updateTab={(tabIndex) => {this.updateTab(tabs[tabIndex])}}
        >
        </Form>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    user_id: state.users.user ? state.users.user.id : null
  };
}

export default withRouter(connect(mapStateToProps)(Feed));
