import { SET_TOKEN, GET_USER, SELECT_CLIENT, VERIFY_PASSWORD_RESET_TOKEN } from './types'

export function setToken(token, url) {
  return { type: SET_TOKEN, token, url }
}

export function getUser() {
  return { type: GET_USER }
}

export function selectClient(internalClientId) {
  return { type: SELECT_CLIENT, internalClientId }
}

export function verifyPasswordToken(url) {
  return { type: VERIFY_PASSWORD_RESET_TOKEN, url }
}
