import React from 'react'
import { Box } from "@mui/material"
import Toggles from '../../widgets/Toggles'
import Toggle from '../../widgets/Toggle'

const HelperToggle = (props) => {
  const handleChange = (e, newHelperMode) => {
    props.setHelperMode(newHelperMode)
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Toggles value={props.helperMode} onChange={handleChange}>
        <Toggle value="settings" labelText="workflows.settings" />
        <Toggle value="stepper" labelText="workflows.workflowSteps" />
      </Toggles>
    </Box>
  )
}

export default HelperToggle