import { connect } from 'react-redux'
import OrderedList from '../widgets/OrderedList'
import Confirm from '../Confirm'
import { updateCharts, getCharts, updateDimensionSettings } from "../../api/dimensions"
import AddEditAnnotations from '../charts/AddEditAnnotation'
import { getChartAnnotations } from "../../api/dashboards"
import _ from 'lodash'
import { DataConfiguration } from '../DataConfiguration'
import FTNotice from '../FTNotice'
import { Box, Checkbox } from '@mui/material'
import Section from '../widgets/Section'
import Button from '../widgets/Button'
import i18next from 'i18next'

class ChartConfig extends DataConfiguration {
  /*
    Enable admin to configure charts on dimensions page.
  */
  constructor(props) {
    super(props)
    this.state = Object.assign(this.state, {
      originalCharts: null,
      charts: null,
      openAnnotations: false,
      saveClicked: false,
    })
  }

  componentDidMount() {
    super.componentDidMount()
    this.getCharts()
  }

  getCharts() {
    getCharts(this.props.internal_client_id).then(response => {
      let charts = null
      if(response.result.length > 0) {
        /* This step checks if new charts were added to defaultCharts.
        *  If so, we add the newly added charts as options to display in the filter.
        *  In this way, we don't need to insert the new charts to the database manually.
        */
        charts = this.syncWithDefaultCharts(response.result)
      } else {
        /* If there is no data in the database, it means the current client is new,
        *  we use the default charts.
        */
       charts = this.defaultCharts()
      }
      charts.forEach(chart => {
        chart.localizedName = this.localizeName(chart.name)
      })
      this.setState({originalCharts: this.copyCharts(charts), charts: charts}, () => this.sendCharts(this.state.originalCharts))
    })
    this.getChartAnnotations()
  }

  copyCharts(charts) {
    const copy = []
    charts.forEach(chart => {
      copy.push(Object.assign({}, chart)) // copy so we can revert
    })
    return copy
  }

  getChartAnnotations() {
    this.setState({annotations: null})
    getChartAnnotations('dimension', this.props.internal_client_id).then(response => {
      this.setState({annotations: response.results})
    })
  }

  updateAnnotationCallBack() {
    this.getChartAnnotations()
  }

  chartNotExistsInCharts(chart, charts) {
    for (let i = 0; i < charts.length; i++) {
      let c = charts[i]
      if(chart.name == c.name && chart.type == c.type) {
        return false
      }
    }
    return true
  }

  syncWithDefaultCharts(charts) {
    let defaultCharts = this.defaultCharts()
    if (defaultCharts.length != charts.length) {
      let new_charts = defaultCharts.filter(chart => this.chartNotExistsInCharts(chart, charts))
      return [...charts, ...new_charts]
    }
    return charts
  }

  defaultCharts() {
    return [
      {type: 'line_chart', name: 'internal_revenue',    order: 1,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'cost',                order: 2,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'clicks',              order: 3,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'internal_conversion', order: 4,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'internal_roas',       order: 5,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'cpc',                 order: 6,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'cr',                  order: 7,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'internal_cpa',        order: 8,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_revenue',      order: 9,  hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_conversion',   order: 10, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_cpa',          order: 11, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_roas',         order: 12, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'impressions',         order: 13, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'ctr',                 order: 14, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'max_cpc',             order: 15, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'internal_rpc',        order: 16, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'internal_aov',        order: 17, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_rpc',          order: 18, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_cr',           order: 19, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'line_chart', name: 'engine_aov',          order: 20, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_revenue',     order: 1, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'cost',                 order: 2, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'clicks',               order: 3, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_conversion',  order: 4, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_roas',        order: 5, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'cpc',                  order: 6, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'cr',                   order: 7, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_cpa',         order: 8, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_revenue',       order: 9, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_conversion',    order: 10, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_cpa',           order: 11, hide: false, internal_client_id: this.props.internal_client_id},      
      {type: 'pie_chart', name: 'engine_roas',          order: 12, hide: false, internal_client_id: this.props.internal_client_id},      
      {type: 'pie_chart', name: 'impressions',          order: 13, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'ctr',                  order: 14, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'max_cpc',              order: 15, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_rpc',         order: 16, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'internal_aov',         order: 17, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_rpc',           order: 18, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_cr',            order: 19, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'pie_chart', name: 'engine_aov',           order: 20, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_revenue',     order: 1, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'cost',                 order: 2, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'clicks',               order: 3, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_conversion',  order: 4, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_roas',        order: 5, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'cpc',                  order: 6, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'cr',                   order: 7, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_cpa',         order: 8, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_revenue',       order: 9, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_conversion',    order: 10, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_cpa',           order: 11, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_roas',          order: 12, hide: false, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'impressions',          order: 13, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'ctr',                  order: 14, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'max_cpc',              order: 15, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_rpc',         order: 16, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'internal_aov',         order: 17, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_rpc',           order: 18, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_cr',            order: 19, hide: true, internal_client_id: this.props.internal_client_id},
      {type: 'bar_chart', name: 'engine_aov',           order: 20, hide: true, internal_client_id: this.props.internal_client_id},
    ]
  }

  localizeName(name) {
    return i18next.t('dimensions.' + name)
  }

  getColumns() {
    return [{
      id: 'order',
      header: 'Order',
      rowDrag: true,
      width: 70,
    },{
      id: 'localizedName',
      header: 'Name',
      alignment: 'left',
      width: 115,
    },{
      id: 'hide',
      header: 'Show',
      cellRenderer: (_, row) => {
        return (
          <Checkbox value={false} defaultChecked={!row.hide} onChange={() => this.handleHide(row)}/>
        )
      },
      width: 58,
    }]
  }

  handleHide(row) {
    row.hide = !row.hide
  }

  apply() {
    super.apply()
    this.sendCharts(this.state.charts)
    this.setState({originalCharts: this.copyCharts(this.state.charts)})
  }

  save() {
    this.setState({saveClicked: true})
  }

  confirmSave(confirmed) {
    this.apply()
    if (confirmed) {
      const charts = []
      this.state.charts.forEach(chart => {
        chart = Object.assign({}, chart)
        delete chart.localizedName
        charts.push(chart)
      })
      updateCharts(charts)
      const data = this.getDimensionAndFilters()
      delete data.fullValues
      updateDimensionSettings(this.props.internal_client_id, data).then(result => {
        if (!result) {
          FTNotice('dimensions.couldNotSaveSettings', 15000)
        }
      })
    }
    this.setState({saveClicked: false})
  }

  sendCharts(charts) {
    this.props.charts(charts)
  }

  sendUpdate() {
    const settings = this.getDimensionAndFilters()
    this.props.changed(settings.filters, settings.dimension, this.state.annotations)
    this.updateTitle()
  }

  cancel() {
    super.cancel()
    this.setState({charts: this.state.originalCharts})
  }

  renderChartTable(title, type) {
    let items = this.state.charts.filter(chart => chart.type == type)
    return (
      <Section name={title}>
        <Box sx={{width: '100%', height: '50%'}}>
          <OrderedList
            formatted={false}
            autoHeight={true}
            items={items}
            columns={this.getColumns()}
          />
          {type == 'line_chart' &&
            <Button
              buttonText="dimensions.chartAnnotations"
              onClick={event => {event.preventDefault(); this.setState({openAnnotations: true})}}
            />
          }
        </Box>
      </Section>
    )
  }

  renderChartTables() {
    return (
      <>
        {this.renderChartTable('Line Charts', 'line_chart')}
        {this.renderChartTable('Pie Charts', 'pie_chart')}
        {this.renderChartTable('Bar Charts', 'bar_chart')}
      </>
    )
  }

  renderExtra() {
    return (
      <> 
        {this.renderConfirm()}
        {this.renderCharts()}
      </>
    )
  }

  renderConfirm() {
    return(
      <Confirm opened={this.state.saveClicked} title="dimensions.changeDefaults" question="dimensions.changeDefaultsQuestion"
         action={confirmed => {this.confirmSave(confirmed)}} button="dimensions.save" cancelButton="dimensions.apply"/>
    )
  }

  renderCharts() {
    if (this.props.isAdmin && this.state.charts) {
      return (
        <>
          {this.renderChartTables()}
          <AddEditAnnotations
            open={this.state.openAnnotations}
            location='dimension'
            location_id={this.props.internal_client_id}
            closeCallBack={() => this.setState({openAnnotations: false})}
            updateCallBack={() => this.updateAnnotationCallBack()}
            />
        </>
      )
    } else {
      return null
    }
  }

  renderSave() {
    if (this.props.isAdmin) {
      return (
        <Button buttonText="dimensions.save" className={this.props.attachToSideNav ? "toggle-nav" : ""} onClick={() => this.save()}/>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
    isAdmin: state.users.user ? state.users.user.role == 'Admin' : false,
  }
}

export default connect(mapStateToProps)(ChartConfig)