import * as React from 'react'
import i18next from 'i18next'
import Checkboxes from '../../widgets/Checkboxes'
import Checkbox from '../../widgets/Checkbox'
import Section from '../../widgets/Section'

const Metrics = (props) => {
  return (
    <Checkboxes>
      <Checkbox disabled={props.status == 'published'} checked={props.use_clicks} onChange={event => props.onUpdate({ use_clicks: event.target.checked })} labelText="biddingRun.clicks" />
      <Checkbox disabled={props.status == 'published'} checked={props.use_cpa} onChange={event => props.onUpdate({ use_cpa: event.target.checked })} labelText="biddingRun.cpa" />
      <Checkbox disabled={props.status == 'published'} checked={props.use_roas} onChange={event => props.onUpdate({ use_roas: event.target.checked })} labelText="biddingRun.roas" />
      <Checkbox disabled={props.status == 'published'} checked={props.use_conversion_rate} onChange={event => props.onUpdate({ use_conversion_rate: event.target.checked })} labelText="biddingRun.conversionRate" />
      <Checkbox disabled={props.status == 'published'} checked={props.use_engagement_score_clicks} onChange={event => props.onUpdate({ use_engagement_score_clicks: event.target.checked })} labelText="biddingRun.engagementScoreClicks" />
      <Checkbox disabled={props.status == 'published'} checked={props.use_engagement_score_cost} onChange={event => props.onUpdate({ use_engagement_score_cost: event.target.checked })} labelText="biddingRun.engagementScoreCost" />
    </Checkboxes>
  )
}

const SelectMetrics = (props) => {
  return (
    <Section
      name={props.name}
      description={i18next.t('biddingRun.performanceMetricsDesc')}
      inputHasValue={props.metricSelected}
    >
      {<Metrics {...props} />}
    </Section>
  )
}

export default SelectMetrics