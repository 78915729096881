import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  user: null,
  tokenIsValid: false,
}

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    saveToken: (state, { payload }) => ({ ...state, token: payload }),
    saveUser: (state, { payload }) => ({ ...state, user: payload }),
    saveTokenIsValid: (state, { payload }) => ({ ...state, tokenIsValid: payload }),
  }
})

export const { saveToken, saveUser, saveTokenIsValid } = users.actions
export default users.reducer
