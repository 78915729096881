import {toast, Slide } from "react-toastify"
import i18next from 'i18next'

export default (text, duration = 5000) => {
  // Moves the toast container upper in the DOM to see the toasts even when scrolling
  if ($('#ftNoticeContainer','#react-root').length == 1) {
    $('#ftNoticeContainer').detach().prependTo('#chart-hudd');
  }

  toast(i18next.t(text), {
      position: "top-right",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide
    })
}

