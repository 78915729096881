import { FormGroup } from "@mui/material"

/*
This uses an MUI FormGroup (https://mui.com/material-ui/api/form-group/).  Any of those properties can be passed through.  Invididual Checkbox tags
should be children of the Checkboxes tag.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - the label to use for the checkbox (this should generally be an i18n key)
*/
const FtoCheckboxes = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const {...checkboxesProps} = revisedProps
  return (
    <FormGroup {...checkboxesProps} />
  )
}

export default FtoCheckboxes