import isUndefined from 'lodash/isUndefined'
import includes from 'lodash/includes'
import isNull from 'lodash/isNull'
import { Link } from 'react-router-dom'
import { dataFormatter, formatFloat, formatConcise } from '../../utilities/formatting'
import { Box, Typography, Divider, Paper, Grid, useMediaQuery, useTheme } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import i18next from 'i18next'

/*
Show a graphic with a metric value.  Optionally include comparable information.
  id - The id of the metric (ex., 'cpc' or 'internal_cpa')
  name - User facing name for the metric (ex., 'Impressions')
  color - Color to show for the metric (ex., 'bg-grey')
  value - Current Value for the metric (ex., 2000)
  path (optional) - If present, the metric will be a link to navigate to this path
  compare_value (optional) - The comparable value (ex., 2500)
  showNoChange - a boolean value used to force showing the zero values when there is no change
  className - Optional className (ex., "col-md-2")
*/

const Metric = ({ showNoChange, id, name, color, value, path = null, compare_value = null, className = null }) => {
  const displayValue = (display) => {
    return display == null ? null : formatConcise(dataFormatter(name)(display))
  }

  const textColor = (percent) => {
    if (percent == 0) {
      return color
    } else {
      let green = 'success.main'
      let red = 'error.main'
      if (includes(['cpc', 'internal_cpa', 'engine_cpa'], id)) {
        // these metrics go in the opposite direction
        green = 'error.main'
        red = 'success.main'
      }
      return percent > 0 ? green : red
    }
  }

  let showCompare = false
  let comparePercent = 0

  if (
    !isNull(compare_value) &&
    !isUndefined(value) &&
    compare_value != value
  ) {
    comparePercent = value == 0 ? compare_value * -100 : (value / compare_value - 1) * 100
    showCompare = true
  } else if (showNoChange === true) {
    showCompare = true
  }

  const comparePercentFormatted = isFinite(comparePercent) ? (
    <Box sx={{
      display: 'flex', justifyContent: 'flex-start', paddingX: 1, borderRadius: '50px'
    }}>
      {comparePercent > 0 && <ArrowUpwardIcon sx={{ flexShrink: 1 }} />}
      {comparePercent < 0 && <ArrowDownwardIcon sx={{ flexShrink: 1 }} />}
      {formatFloat(comparePercent)}%
    </Box>
  ) : ''

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const getMetric = () => {

    return (
      <Paper elevation={3} sx={{ borderRadius: 3 }}>
        <Box
          sx={{
            borderRadius: 2,
            bgcolor: "white.100",
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            height: showCompare ? 175 : 140,
            minWidth: 200,
            width: 'auto',
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              height: 'auto',
              paddingTop: 1,
            }}
          >
            <Typography
              color="grey.900"
              variant="metricLabel">
              {name == 'Engine Conversion Rate' ? 'Engine Conv. Rate' : i18next.t(name)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 1.25,
              marginBottom: 1,
              width: 'auto'
            }}
          >
            <Typography
              color={'grey.900'}
              variant="metricValue"
            >
              {displayValue(value)}
            </Typography>
            {showCompare && (
              <Divider flexItem variant='fullWidth' sx={{ marginTop: 2, width: '100%' }} />
            )}
          </Box>
          {showCompare && (
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: 'auto',
                width: 'auto',
                paddingBottom: 1,
                marginTop: .5,
              }}
            >
              <Typography color={textColor(comparePercent)} variant="metricPercentChange">
                {comparePercentFormatted}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="metricCompareValue">
                  {displayValue(compare_value)}
                </Typography>
              </Box>
            </Box>
          )}
        </Box >
      </Paper>
    )
  }

  if (path) {
    return (
      <Link to={path}>
        {getMetric()}
      </Link>
    )
  } else {
    return getMetric()
  }
}

export default Metric