import { Link } from "react-router-dom"
import './accounts.scss'

const Accounts = ({disabled, accounts, internal_client_id}) => {
  const renderAccount = (account) => {
    return (
      <div className='account'>
        <div style={{color: account.status == 'true' && !disabled ? 'black' : '#aaaaaa', fontStyle: account.status == 'true' ? 'normal' : 'italic'}}>{account.name}</div>
        <div style={{color: account.status == 'true' && !disabled ? 'black' : '#aaaaaa', fontStyle: account.status == 'true' ? 'normal' : 'italic'}}>{account.pub_account_id}</div>
      </div>
    )
  }
  
  return (
    <div style={{display: 'inline-block', marginLeft: 20, overflow: 'auto', width: 815, whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
      {accounts.map((account, i) => {
        return disabled ? renderAccount(account) : (
          <Link
            to={"/clients/" + internal_client_id + "/accounts/" + account.id + "/edit"}
            key={i}
          >
            {renderAccount(account)}
          </Link>
        )
      })}
    </div>
  )
}

export default Accounts