import { validateSql } from '../api/database'
import FTNotice from '../components/FTNotice'
import i18next from 'i18next'

export const VARIABLE_DEFAULT_VALUE = {
  internal_client_id: 1,
  account_id: 11111,
  user_id: 1,
  current_month: "January",
  run_date: "2021-01-01",
  run_date_digit: "20210101",
  load_date: "2021-01-01",
  load_date_digit: "20210101",
  in_date_range: "run_date >= '2021-01-01' AND run_date <= '2021-01-31'",
  start_date: "2021-01-01",
  end_date: "2021-02-01",
  channels: "('sem')",
  engines: "('google')",
  entity_types: "('keyword')",
  devices: "('desktop')",
  run_id: "default_sql_run_id",
  bidding_algos: "SELECT TRUE",
  disable_repetitive: "FALSE",
  dim1: "('')",
  dim2: "('')",
  dim3: "('')",
  dim4: "('')",
  dim5: "('')",
  dim6: "('')",
  dim7: "('')",
  dim8: "('')",
  dim9: "('')",
  dim10: "('')",
  roas: 1,
  direction: "",
  where_clause: "true",
  algo: "''",
  algos: "('')",
  scale: 'NULL',
  minimum: 'NULL',
  maximum: 'NULL',
}

export function substituteVariables(sql) {
  sql = sql.replace(/(\/\*[^*]*\*\/)/g, "")
  sql = sql.replace(
    /\{internal_client_id\}/g,
    VARIABLE_DEFAULT_VALUE.internal_client_id
  )
  sql = sql.replace(/\{current_month\}/g, VARIABLE_DEFAULT_VALUE.current_month)
  sql = sql.replace(/\{run_date\}/g, VARIABLE_DEFAULT_VALUE.run_date)
  sql = sql.replace(
    /\{run_date_digit\}/g,
    VARIABLE_DEFAULT_VALUE.run_date_digit
  )
  sql = sql.replace(/\{load_date\}/g, VARIABLE_DEFAULT_VALUE.load_date)
  sql = sql.replace(
    /\{load_date_digit\}/g,
    VARIABLE_DEFAULT_VALUE.load_date_digit
  )
  sql = sql.replace(/\{in_date_range\}/g, VARIABLE_DEFAULT_VALUE.in_date_range)
  sql = sql.replace(/\{start_date\}/g, VARIABLE_DEFAULT_VALUE.start_date)
  sql = sql.replace(/\{end_date\}/g, VARIABLE_DEFAULT_VALUE.end_date)
  sql = sql.replace(/\{channels\}/g, VARIABLE_DEFAULT_VALUE.channels)
  sql = sql.replace(/\{engines\}/g, VARIABLE_DEFAULT_VALUE.engines)
  sql = sql.replace(/\{entity_types\}/g, VARIABLE_DEFAULT_VALUE.entity_types)
  sql = sql.replace(/\{devices\}/g, VARIABLE_DEFAULT_VALUE.devices)
  sql = sql.replace(/\{run_id\}/g, VARIABLE_DEFAULT_VALUE.run_id)
  sql = sql.replace(/\{bidding_algos\}/g, VARIABLE_DEFAULT_VALUE.bidding_algos)
  sql = sql.replace(/\{disable_repetitive\}/g, VARIABLE_DEFAULT_VALUE.disable_repetitive)
  sql = sql.replace(/\{dim1\}/g, VARIABLE_DEFAULT_VALUE.dim1)
  sql = sql.replace(/\{dim2\}/g, VARIABLE_DEFAULT_VALUE.dim2)
  sql = sql.replace(/\{dim3\}/g, VARIABLE_DEFAULT_VALUE.dim3)
  sql = sql.replace(/\{dim4\}/g, VARIABLE_DEFAULT_VALUE.dim4)
  sql = sql.replace(/\{dim5\}/g, VARIABLE_DEFAULT_VALUE.dim5)
  sql = sql.replace(/\{dim6\}/g, VARIABLE_DEFAULT_VALUE.dim6)
  sql = sql.replace(/\{dim7\}/g, VARIABLE_DEFAULT_VALUE.dim7)
  sql = sql.replace(/\{dim8\}/g, VARIABLE_DEFAULT_VALUE.dim8)
  sql = sql.replace(/\{dim9\}/g, VARIABLE_DEFAULT_VALUE.dim9)
  sql = sql.replace(/\{dim10\}/g, VARIABLE_DEFAULT_VALUE.dim10)
  sql = sql.replace(/\{roas\}/g, VARIABLE_DEFAULT_VALUE.roas)
  sql = sql.replace(/\{direction\}/g, VARIABLE_DEFAULT_VALUE.direction)
  sql = sql.replace(/\{where_clause\}/g, VARIABLE_DEFAULT_VALUE.where_clause)
  sql = sql.replace(/\{algo\}/g, VARIABLE_DEFAULT_VALUE.algo)
  sql = sql.replace(/\{algos\}/g, VARIABLE_DEFAULT_VALUE.algos)
  sql = sql.replace(/\{scale\}/g, VARIABLE_DEFAULT_VALUE.scale)
  sql = sql.replace(/\{minimum\}/g, VARIABLE_DEFAULT_VALUE.minimum)
  sql = sql.replace(/\{maximum\}/g, VARIABLE_DEFAULT_VALUE.maximum)
  sql = sql.replace(/\{account_id\}/g, VARIABLE_DEFAULT_VALUE.account_id)
  return sql
}

export function getVariables(sql) {
  if (sql != null && sql != "") {
    let variables = sql.match(/{[^}\n]*}/g)
    if (variables != null) {
      variables = variables.map(res => res.replace(/{|}/g, ""))
      variables = Array.from(new Set(variables))
      variables.sort()
      return variables
    }
  }
  return []
}

export function getCustomVariables(sql) {
  let result = []
  const variables = getVariables(sql)
  variables.forEach((variable) => {
    if (!(variable in VARIABLE_DEFAULT_VALUE)) {
      result.push(variable)
    }
  })
  return result
}

export function validateRawSQL(
  sql,
  database,
  success_msg = null,
  error_msg = null,
  show_success = true,
  show_failure = true,
  preventSemicolon = false
) {
  return validateSql({ sql: sql, database: database }).then((response) => {
    if (response.valid) {
      if (preventSemicolon && sql.trim().endsWith(';')) {
        const error = i18next.t('utils.sqlSemiColon')
        if (show_failure) {
          FTNotice(error, 15000)
        }
        return error
      }
      if (show_success) {
        if (success_msg != null) {
          FTNotice(success_msg, 3000)
        } else {
          FTNotice("utils.queryValid", 3000)
        }
      }
      return null
    } else {
      const error =
        error_msg != null
          ? error_msg + response.error
          : i18next.t('utils.queryInvalid', {error: response.error})
      if (show_failure) {
        FTNotice(error, 15000)
      }
      return error
    }
  })
}

export function validateSQL(
  sql,
  database,
  success_msg = null,
  error_msg = null,
  show_success = true
) {
  sql = substituteVariables(sql)
  return validateRawSQL(sql, database, success_msg, error_msg, show_success)
}

export function validateSQLBeforeSave(sql, database, substitution = null, preventSemicolon = false) {
  sql = (substitution ? substitution : substituteVariables)(sql)
  return validateRawSQL(sql, database, null, null, false, false, preventSemicolon)
}

export function downloadFile(url) {
  const link = document.createElement("a")
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function filterInteger(event) {
  event.target.value = event.target.value.replace(/[^0-9]/g, "")
}

export function filterFloat(event) {
  event.target.value = event.target.value.replace(/[^0-9\.]/g, "")
}

export function capitalize(string) {
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1)
}

export function determineType(rows, columnName) {
  const values = rows.map(r => r[columnName]).filter(v => v != null)
  const numericValues = values.filter(v => !isNaN(v))
  const dateValues = values.filter(v => {
    const splits = typeof variable === 'string' ? v.split('-') : []
    return splits.length == 3
      && !isNaN(splits[0]) && splits[0].length == 4
      && !isNaN(splits[1]) && splits[1].length <= 2
      && !isNaN(splits[2]) && splits[2].length <= 2
  })
  if (values.length == 0) {
    return 'text'
  } else if (values.length == numericValues.length) {
    return 'numeric'
  } else if (values.length == dateValues.length) {
    return 'date'
  } else {
    return 'text'
  }
}
