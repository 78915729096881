import { combineReducers } from 'redux'
import alerts from './alerts/reducer'
import users from './users/reducer'

const rootReducer = () =>
  combineReducers({
    alerts,
    users
  })

export default rootReducer