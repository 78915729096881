import React, { useMemo } from 'react'
import withRouter from '../../wrappers/withRouter'
import Form from '../Form'
import Pipelines from './StageSteps'
import i18next from 'i18next'
import styles from './RevenuePipelineStyles'

const RevenuePipeline = ({match}) => {
  const OPTIONS = [
    {id: 'before_cost', name: i18next.t('revenuePipeline.beforePerformance'), component: <Pipelines type={'before_cost'}/>},
    {id: 'after_cost', name: i18next.t('revenuePipeline.afterPerformance'), component: <Pipelines type={'after_cost'}/>},
  ]
  const option = useMemo(() => OPTIONS.filter(option => option.id == match.params.tab)[0], [])

  return(
    <Form
      menu={i18next.t('menu.automate')}
      item={i18next.t('menu.revenuePipeline')}
      canEdit={false}
      canDelete={false}
      parentLink={() => {location.href = '/#/revenue_pipeline'}}
    >
      <div style={styles.container}>
        <h3 style={styles.title}>{option.name}</h3>
        {option.component}
      </div>
    </Form>
  )
}

export default withRouter(RevenuePipeline)
