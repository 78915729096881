import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import withRouter from '../../wrappers/withRouter'
import ReactFlow from 'reactflow'
import Node from './Node'
import styles from './InterfaceStyles'
import { getModels, getModel, deleteModel} from '../../api/data_science'
import DeleteButton from '../DeleteButton'
import i18next from 'i18next'

import 'reactflow/dist/style.css'

const Interface = ({internal_client_id, match}) => {
  const [models, setModels] = useState([])
  const [model, setModel] = useState()

  function updateUrl(id) {
    window.location.href = '/#/data_science' + (id == null ? '' : ('/' + id))
  }

  function updateModels(modelId = null) {
    getModels(internal_client_id).then(data => {
      const candidates = data.results
      setModels(candidates)
      let candidate = candidates.find(candidate => modelId == null ? candidate.id == match.params.id : candidate.id == modelId)
      if (candidate == null && candidates.length > 0) {
        candidate = candidates[0]
        updateUrl(candidate.id)
      }
      setModel(candidate)
    })
  }    

  function deleteRow() {
    updateUrl()
    deleteModel(model.id).then(_ => updateModels())
  }

  function updateRow(id){
    updateUrl(id)
    getModel(id).then(model => model && setModel(model))
  }
  
  function getClientName(model) {
    return model.internal_client_id == null ? i18next.t('dataScience.all') : this.props.clients.find(c => c.internal_client_id == model.internal_client_id).name
  }

  useEffect(() => {
    updateModels()
  }, [])

  const nodeTypes = useMemo(() => ({ custom: Node }), [])
  const nodes = [{
    id: '1',
    type: 'custom',
    data: { label: i18next.t('dataScience.train')},
    position: { x: 0, y: 10},
  }, {
    id: '1a',
    type: 'custom',
    data: { label: i18next.t('dataScience.queryTrainingData'), database: true, link: model ? `/#/data_science/${model.id}/training` : null },
    position: { x: 5, y: 140},
  }, {
    id: '1b',
    type: 'custom',
    data: { label: i18next.t('dataScience.featureExtraction'), featureExtract: true, link: model ? `/#/data_science/${model.id}/features` : null},
    position: { x: 125, y: 140 },
  }, {
    id: '1c',
    type: 'custom',
    data: { label: i18next.t('dataScience.randomForestTraining'), training: true, link: model ? `/#/data_science/${model.id}/parameters` : null},
    position: { x: 250, y: 140 },
  },{
    id: '2',
    type: 'custom',
    data: { label: i18next.t('dataScience.dailyPredictions'), },
    position: { x: 0, y: 280 },
  }, {
    id: '2a',
    type: 'custom',
    data: { label: i18next.t('dataScience.retrieveModel'), bucket: true},
    position: { x: 390, y: 280 },
  }, {
    id: '3a',
    type: 'custom',
    data: { label: i18next.t('dataScience.queryInferencingData'), database: true, link: model ? `/#/data_science/${model.id}/inferencing` : null},
    position: { x: 10, y: 420 },
  }, {
    id: '3b',
    type: 'custom',
    data: { label: i18next.t('dataScience.featureExtraction'), featureExtract: true, link: model ? `/#/data_science/${model.id}/features` : null},
    position: { x: 390, y: 420 },
  }, {
    id: '3c',
    type: 'custom',
    data: { label: i18next.t('dataScience.randomForestPrediction'), predict: true},
    position: { x: 580, y: 420 },
  }, {
    id: '3d',
    type: 'custom',
    data: { label: i18next.t('dataScience.uploadResults'), database: true, link: model ? `/#/data_science/${model.id}/output_table` : null},
    position: { x: 770, y: 420 },
  }]
  const edges = [
    { id: 'e1', source: '1a', target: '1b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e2', source: '1b', target: '1c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e3', source: '1c', target: '2a', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e4', source: '2a', target: '3c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e5', source: '3a', target: '3b', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e6', source: '3b', target: '3c', markerEnd: {type: 'arrowclosed', color: 'black'} },
    { id: 'e7', source: '3c', target: '3d', markerEnd: {type: 'arrowclosed', color: 'black'} },
  ] 

  return (
    <div style={styles.root}>
      <a className="btn btn-box-tool" style={{float: 'right', marginTop: 5, marginRight: 10}} onClick={() => window.location.href = `/#/data_science`}>
        <i className="fa fa-times"></i>
      </a>
      <div style={styles.container}>
        <div style={{width: 'fit-content'}}>
          <select 
            value={model ? model.id : ''}
            onChange={event =>  updateRow(event.target.value)}
            style={{width: 189, marginBottom: 10}}
            className="form-control">
            {models.map((option, i) => {
              return <option key={i} value={option.id}>{option.name}</option>
            })}
          </select>
          {model != null && (
            <div style={{marginLeft: 15}}>{i18next.t('dataScience.field', {field: i18next.t('dataScience.client'), value: getClientName(model)})}</div>
          )}
          {model != null && model.confidence != null && (
            <div style={{marginLeft: 15}}>{i18next.t('dataScience.field', {field: i18next.t('dataScience.modelType'), value: i18next.t('dataScience.' + model.model_type)})}</div>
          )}
          {model != null && model.confidence != null && (
            <div style={{marginLeft: 15, marginBottom: 10}}>{i18next.t('dataScience.field', {field: i18next.t('dataScience.accuracy'), value: Math.round(model.confidence)})}%</div>
          )}
          <div className="js_complete">
            {model != null && (
              <DeleteButton
                objectType=""
                objectName={model.name}
                style={{width:"40%"}}
                deleteObject={() => deleteRow()}/>
            )}
          </div>
        </div>
          
        <div style={Object.assign({}, styles.immediateContainer, {height: 550, width: 900, marginLeft: 20})}>
          <ReactFlow
            style={styles.diagram}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
          />
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = function(state) {
  return {
    permissions: state.users.user ? state.users.user.permissions : [],
    internal_client_id: state.users.user.client.internal_client_id,
    clients: state.users.user ? state.users.user.clients : [],
  }
}

export default withRouter(connect(mapStateToProps)(Interface))
