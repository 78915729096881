import React from 'react'
import Table from '../../widgets/Table'
import { formatInteger } from '../../../utilities/formatting'

const BidReasons = ({ reasons }) => {
  const getColumns = () => {
    return [{
      id: 'reason',
      header: 'biddingReasons.reason',
      datatype: 'text',
    }, {
      id: 'count',
      header: 'biddingReasons.count',
      format: value => formatInteger(value),
      datatype: 'numeric',
    }, {
      id: 'percentage',
      header: 'biddingReasons.percentage',
      datatype: 'numeric',
    }]
  }

  return (
    <div className='row'>
      <div className="col-md-12">
        <Table
          autoHeight={true}
          dataset={reasons}
          columns={getColumns()} />
      </div>
    </div>
  )
}

export default BidReasons