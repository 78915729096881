import React, { useState, useEffect } from 'react'
import { Handle, Position } from 'reactflow'
import { getClient, patchClient } from '../../api/clients'
import { backfill } from '../../api/revenue_pipeline'
import { connect } from 'react-redux'
import i18next from 'i18next'
import styles from './NodeStyles'
import FTNotice from '../FTNotice'
import RequestData from '../RequestData'

const Node = ({ data, internal_client_id }) => {
  const [usePerformance, setUsePerformance] = useState(false)
  const [showBackfill, setShowBackfill] = useState(false)

  useEffect(() => {
    if (data.revenue) {
      getClient(internal_client_id).then(client => {
        setUsePerformance(client.use_engine_revenue)
      })
    }
  }, [])

  const updateUsePerformance = (usePerformance) => {
    patchClient({internal_client_id: internal_client_id, use_engine_revenue: usePerformance}).then(response => {
      if (!response || response.error) {
        FTNotice(i18next.t('revenuePipeline.saveFailed', {error: response ? response.error : ''}), 3000)
      } else {
        setUsePerformance(usePerformance)
      }
    })
  }
  
  const closeAndBackfill = (values) => {
    if (values && !values.google && !values.bing) {
      FTNotice('revenuePipeline.mustSelectEngine', 15000)
      return true
    } else {
      setShowBackfill(false)
      if (values) {
        const engines = []
        if (values.google) {
          engines.push('google')
        }
        if (values.bing) {
          engines.push('bing')
        }
        backfill({internal_client_id: internal_client_id, engines: engines, start: values.dateRange.start, end: values.dateRange.end}).then(response => {
            if (response.successful) {
              FTNotice('revenuePipeline.backfillStarted', 3000)
            } else {
              FTNotice('revenuePipeline.backfillFailed', 15000)
            }
        })
      }
    }
  }
  
  return (
    <>
      <RequestData
        opened={showBackfill}
        title="revenuePipeline.backfill"
        description="revenuePipeline.backfillDescription"
        fields={[{
          id: 'google',
          label: 'revenuePipeline.google',
          type: 'checkbox',
          defaultValue: true,
        }, {
          id: 'bing',
          label: 'revenuePipeline.bing',
          type: 'checkbox',
          defaultValue: true,
        }, {
          id: 'dateRange',
          label: 'revenuePipeline.dateRange',
          type: 'dateRange',
          defaultStart: moment().subtract(364, 'days').format('YYYY-MM-DD'),
          defaultEnd: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }]}
        action={values => closeAndBackfill(values)} />
      {!data.hideTarget && (
        <Handle type="target" position={Position.Left} />
      )}
      {data.link && !data.revenue && (
        <>
          <div style={styles.node} onClick={() => {location.href=data.link}}>{i18next.t(data.label)}</div>
          {data.backfill && (
            <button style={styles.button} className="btn btn-primary" onClick={() => {setShowBackfill(true)}}>{i18next.t('revenuePipeline.backfill')}</button>
          )}
        </>
      )}
      {data.link && data.revenue && (
        <>
          <div
            style={Object.assign({}, styles.revenue, {color: usePerformance ? 'lightgrey' : 'black', borderColor: usePerformance ? 'lightgrey' : 'black', cursor: usePerformance ? 'auto' : 'pointer'})}
            onClick={() => {if (!usePerformance) {location.href=data.link}}}>{i18next.t(data.label)}</div>
        </>
      )}
      {!data.link && !data.database && (
        <div style={{cursor: 'auto'}}>{i18next.t(data.label)}</div>
      )}
      {data.database && (
        <div style={styles.databaseContainer}>
          <img style={styles.database} src="/resources/images/workflow_icons/database-1.png" />
          <div>{i18next.t(data.label)}</div>
        </div>
      )}
      {!data.hideSource && (
        <Handle type="source" position={Position.Right} />
      )}
    </>
  )
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  }
}

export default connect(mapStateToProps)(Node)
