import * as React from 'react'
import ButtonRow from '../../widgets/ButtonRow'
import Button from '../../widgets/Button'
import FTNotice from '../../FTNotice'

const GenerateBids = (props) => {
  const isValid = () => {
    if (props.campaignIds.length == 0 && props.adGroupIds.length == 0) {
      FTNotice('biddingRun.mustSelectCampaign')
      return false
    } else if (!props.metricSelected) {
      FTNotice('biddingRun.mustSelectMetric', 15000)
      return false
    } else if (props.schedule != null && props.schedule.endsWith('*')) {
      FTNotice('biddingRun.mustSelectDayOfWeek', 15000)
      return false
    } else if (props.engines.length == 0) {
      FTNotice('biddingRun.selectAtLeastOneEngine')
      return false
    } else if (props.entityTypes.length == 0) {
      FTNotice('biddingRun.selectAtLeastOneAdType')
      return false
    } else {
      return true
    }
  }

  const handleSave = () => {
    if (isValid()) {
      props.save()
    }
  }

  const handleGenerateClick = () => {
    if (isValid()) {
      props.setBidSummaryOpen(true)
      props.generateBids()
    }
  }

  const handleClick = () => {
    if (props.status == 'published') {
      props.setSuccessDialogOpen(true)
    } else {
      props.setBidSummaryOpen(true)
    }
  }

  return (
    <ButtonRow>
      {props.schedule != null && (
        <Button
          variant="contained"
          color="primary"
          disabled={props.started || props.status == 'published'}
          onClick={handleSave}
          buttonText={'biddingRun.save'}
        />
      )}
      {props.bidding_run_id != -1 && (
        <Button
          buttonText="biddingRun.delete"
          disabled={props.started || props.status == 'published'}
          onClick={() => props.onUpdate({ deleteClicked: true })}
          color="error" />
      )}
      <Button
        variant="contained"
        color={props.schedule == null ? 'primary' : 'secondary'}
        disabled={props.started || props.status == 'published'}
        onClick={handleGenerateClick}
        buttonText={props.bidding_run_id > -1 ? 'biddingRun.regenerateBids' : 'biddingRun.generateBids'}
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={props.bidding_run_id == -1}
        onClick={handleClick}
        buttonText={"biddingRun.openPublishing"}
      />
    </ButtonRow>
  )
}

export default GenerateBids