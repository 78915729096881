import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '../widgets/Table'
import { getAlerts } from '../../api/alerts.js'

class Interface extends Component {
  getColumns() {
    return [{
      id: 'name',
      datatype: 'text',
      cellRenderer: (value, row) => <Link to={'/alerts/'+row.id}>{value}</Link>
    }, {
      id: 'status',
      datatype: 'text',
    },{
      id: 'error_start',
      datatype: 'date',
    },{
      id: 'alert_type',
      datatype: 'text',
    }];
  }

  render() {
    return (
      <div className="site-canvas">
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header js_complete">
                  <div style={{float: 'right'}}>
                    <Link to={'/alerts/new'} className="btn btn-box-tool text-green">
                      <i className="fa fa-plus"></i>
                      <span>Add New Alert</span>
                    </Link>
                  </div>
                </div>
                <Table
                  fetch={(tableData) => getAlerts(this.props.internal_client_id, tableData)}
                  columns={this.getColumns()}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null
  };
}

export default connect(mapStateToProps)(Interface);
