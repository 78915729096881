import { Box } from "@mui/material"
import FtoBreadCrumb from "./FtoBreadCrumb"
import { prettyPrint } from '../../../utilities/formatting'
import Title from "../../widgets/Title"

const Header = (props) => {
  const MAX_LENGTH = 300

  return (
    <Box padding={2}>
      <FtoBreadCrumb name={props.name} serviceCheck={props.serviceCheck} />
      <Title title={props.serviceCheck ? prettyPrint(props.name) : props.name} variant="h1" />
      {
        props.description && props.description.length <= MAX_LENGTH && (
          <Title title={props.description} variant="h5" />
        )
      }
      {
        props.description && props.description.length > MAX_LENGTH && (
          <Title variant="h5" title={props.description.substring(0, MAX_LENGTH) + '...'} />
        )
      }
    </Box>
  )
}

export default Header