import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Table from '../../widgets/Table'
import DeleteButton from '../../DeleteButton'
import FTNotice from '../../FTNotice'
import { getHistory, deleteRun } from '../../../api/workflows'

export default class History extends Component {
  tableRef = createRef()
  
  constructor(props) {
    super(props)
    this.state = {
      api: null
    }
  }

  continueRun(run) {
    this.props.history('/workflows/notebook/' + run.workflow_id + '/run/' + run.runid)
  }

  deleteRun(runId) {
    deleteRun(runId).then((result) => {
      if (result.success) {
        this.tableRef.current.refreshRows()
      } else {
        FTNotice(result?.error || 'workflows.couldNotDeleteHistory')
      }
    })
  }

  getColumns() {
    let col_1 = []
    if (this.props.workflow_id == 0) {
      col_1 = [{
        id: 'workflow_name',
        cellRenderer: (value, row) => {
          return (
            <div>
              <Link to={'/workflows/notebook/' + row.workflow_id + '/run'} >
                {value}
              </Link>
            </div>
          )
        },
        header: 'Workflow',
        width:350
      }]
    }

    let col_2 =  [{
      id: 'date_time',
      datatype: 'date',
      width: 145
    },{
      id: 'executor',
      datatype: 'text',
      width: 150
    },{
      id: 'runid',
      datatype: 'text',
      width: 300
    },{
      cellRenderer: (_, row) => {
        return (
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <button type="button" className="btn btn-primary" onClick={() => this.continueRun(row)}>
                Continue
              </button>
            </Grid>
            <Grid item xs={6}>
              <DeleteButton
                objectType=""
                objectName={row.runid}
                deleteObject={() => {this.deleteRun(row.runid)}}/>
            </Grid>
          </Grid>
        )
      },
      width:200,
    }]

    return [...col_1, ...col_2]
  }

  render() {
    return(
      <div className='site-wrapper-offcanvas' id='chart-hudd' style={{paddingLeft: 35, paddingRight: 35}}>
        <Table
          ref={this.tableRef}
          fetch={(tableData) => {return getHistory(this.props.workflow_id, this.props.internal_client_id, tableData)}}
          columns={this.getColumns()}
          rowHeight={37}
        />
      </div>
    )
  }
}
