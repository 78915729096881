import { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  substituteVariables,
  getVariables,
  VARIABLE_DEFAULT_VALUE,
} from '../utilities/utils'
import CodeMirror from '@uiw/react-codemirror'
import i18next from 'i18next'

import '../assets/stylesheets/codeEditor.css'

export const CUSTOM = 'Custom'
export const RANGE = 'Range'
export const MULTISELECT = 'Multiselect'

/*
Display a text area when not editing.  Edit in dialog on clicking.  Passes in properties:
  code - code to edit.
  onChange - function to be called on completion (takes a parameter which contains the new code).
  title (optional) - the title of the dialog (defaults to Edit SQL)
  instructions (optional) - any instructions to provide to the user.
  substitute (optional) - function to call before validating to substitute variables.  Defaults to typical substitution.
  validate (optional) - customized validation function
  height (optional) - height of text area when not editing.
  width (optional) - width of text area when not editing.
  showVariables (optional) - defaults to false; set to true if this sql supports custom variables.
  readOnly (optional) - defaults to false; set to true if the user can't click on it to edit
*/
export default class SQLEditor extends Component {
  static defaultProps = {
    title: i18next.t('codeEditor.title'),
    height: 'fit-content',
    width: 800,
    substitute: substituteVariables,
    database: "redshift",
    open: false,
    variables: [],
    default_variables: [],
    showVariables: false,
    readOnly: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.database !== nextProps.database) {
      return {
        database: nextProps.database,
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
      open: false,
      original: props.code,
      code: props.code,
      database: props.database,
      variables: null,
      variable_values: props.default_variables,
    }
  }

  componentDidMount() {
    // Work around for showing the SQLEditor in the grid; otherwise it will not show any content
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }
  
  getDefaultVariableValues(variables) {
    const variableValues = []
    if (variables) {
      variables.forEach(variable => {
        const variableValue = this.state.variable_values.find(v => v.name == variable)
        if (variableValue == null) {
          variableValues.push({name: variable, type: CUSTOM, value: variable in VARIABLE_DEFAULT_VALUE ? VARIABLE_DEFAULT_VALUE[variable] : ''})
        } else {
          variableValues.push(variableValue)
        }
      })
      return variableValues
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.code != prevProps.code ||
      this.props.default_variables.toString() !=
        prevProps.default_variables.toString()
    ) {
      this.updateVariables()
    }
  }
  
  updateVariables() {
      const variables = getVariables(this.props.code)
      const variableValues =
        this.props.default_variables.length === 0
          ? this.getDefaultVariableValues(variables)
          : this.props.default_variables

      this.setState({
        original: this.props.code,
        code: this.props.code,
        variables: variables,
        variable_values: variableValues,
      })
  }

  ok(event) {
    if (this.shouldValidate()) {
      this.validate(event, () => this.saveChanges())
    } else {
      this.saveChanges()
    }
  }
  
  shouldValidate() {
    return false
  }

  validate(_event, _func) {
    _func()
  }

  saveChanges() {
    this.setState({ open: false, original: this.state.code })
    const customVariables = this.state.variable_values.filter(v => !(v.name in VARIABLE_DEFAULT_VALUE))
    this.props.onChange(this.state.code, customVariables)
  }

  replaceVariables(code) {
    this.state.variable_values.forEach(variable => {
      if (variable.type == CUSTOM) {
        const value = variable.value
        if (value != null && value != "") {
          code = code.replaceAll("{" + variable.name + "}", value)
        }
      } else if (variable.type == MULTISELECT) {
        let value = variable.value
        if (value != null && value != "") {
          value = value.split(',').map(v => "'" + v.trim().replace(/'/g, "''") + "'").join(', ')
          code = code.replaceAll("{" + variable.name + "}", value)
        }
      } else {
        const min = variable.min
        const max = variable.max
        if (min != null && min != "" && max != null && max != "") {
          code = code.replaceAll("{" + variable.name + "}", min + ' AND ' + max)
        }
      }
    })
    return code
  }

  cancel(event) {
    event.stopPropagation()
    this.setState({ open: false, code: this.state.original }, () => this.updateVariables())
  }

  renderVariables() {
    const userVariables = this.state.variables == null ? [] : this.state.variables.filter(v => !(v in VARIABLE_DEFAULT_VALUE))
    if (userVariables.length == 0 || !this.props.showVariables) {
      return null
    }
    return (
      <div>
        <h4>{i18next.t('codeEditor.variables')}</h4>
        <div style={{display: 'table'}}>
          {userVariables.map((variableName, i) => {
            const variable = this.state.variable_values.find(variable => variable.name == variableName)
            return (
              <div key={i} style={{display: 'table-row'}}>
                <div style={{display: 'table-cell', paddingTop: i > 0 ? 5 : 0}}>
                  <label>{variableName}</label>
                </div>
                <div style={{display: 'table-cell', paddingTop: i > 0 ? 5 : 0}}>
                  <select style={{marginLeft: 10, marginRight: 5}} value={variable.type} onChange={event => {
                    variable.type = event.target.value
                    if (variable.type == CUSTOM || variable.type == MULTISELECT) {
                      delete variable.min
                      delete variable.max
                      variable.value = ''
                    } else {
                      delete variable.value
                      variable.min = ''
                      variable.max = ''
                    }
                    this.setState({ variable_values: this.state.variable_values.slice() })
                  }}>
                    <option value={CUSTOM}>{i18next.t('codeEditor.custom')}</option>
                    <option value={MULTISELECT}>{i18next.t('codeEditor.multiselect')}</option>
                    <option value={RANGE}>{i18next.t('codeEditor.range')}</option>
                  </select>
                  {(variable.type == CUSTOM || variable.type == MULTISELECT) && (
                    <input style={{width: 500}} type="text" value={variable.value} onChange={event => {
                      variable.value = event.target.value
                      this.setState({ variable_values: this.state.variable_values.slice() })
                    }}/>
                  )}
                  {variable.type == RANGE && (
                    <>
                      <input style={{width: 100}} type="number" value={variable.min} onChange={event => {
                        variable.min = event.target.value
                        this.setState({ variable_values: this.state.variable_values.slice() })
                      }}/>
                      <span style={{marginLeft: 5, marginRight: 5}}>{i18next.t('codeEditor.to')}</span>
                      <input style={{width: 100}} type="number" value={variable.max} onChange={event => {
                        variable.max = event.target.value
                        this.setState({ variable_values: this.state.variable_values.slice() })
                      }}/>
                    </>
                  )}
                </div>
                <div style={{display: 'table-cell', paddingTop: i > 0 ? 5 : 0, paddingLeft: 10}}>
                  {variable.type == CUSTOM && (
                    <span>{i18next.t('codeEditor.exampleCustom', {name: `{${variable.name}}`})}</span>
                  )}
                  {variable.type == MULTISELECT && (
                    <span>{i18next.t('codeEditor.exampleMultiselect', {name: `{${variable.name}}`})}</span>
                  )}
                  {variable.type == RANGE && (
                    <span>{i18next.t('codeEditor.exampleRange', {name: `{${variable.name}}`})}</span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  
  getExtensions() {
    return []
  }
  
  getClickAction() {
    // overridden in some subclasses
  }
  
  isReady() {
    return true
  }
  
  getButtons() {
    // overridden in some subclasses
  }
  
  updateCode(code) {
    const variables = getVariables(code)
    const variable_values = this.getDefaultVariableValues(variables)
    this.setState({
      code: code,
      variables: variables,
      variable_values: variable_values,
    })
  }

  render() {
    if (this.state.mounted) {
      if (this.state.open) {
        const options = {
          lineNumbers: true,
        }
        return (
          <div>
            <Dialog
              open={true}
              fullScreen={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div style={{fontWeight: 'bold'}}>
                  {this.props.title}
                </div>
              </DialogTitle>
              <DialogContent>
                <div>
                  {this.props.instructions && (
                    <div>
                      {this.props.instructions}
                    </div>
                  )}
                  {this.renderVariables()}
                </div>
                <br />
                {this.isReady() && (
                  <div style={{border: '1px solid #d2d6de'}}>
                    <CodeMirror
                      extensions={this.getExtensions()}
                      value={this.state.code}
                      options={options}
                      onChange={code => this.updateCode(code)}
                    />
                  </div>
                )}
              </DialogContent>
              <div className="text-center" style={{ marginBottom: 20 }}>
                {this.getButtons()}
                <button
                  className="btn btn-primary"
                  onClick={(event) => this.ok(event)}
                  style={{ marginLeft: 10 }}
                >
                  {i18next.t('codeEditor.ok')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(event) => this.cancel(event)}
                  style={{ marginLeft: 10 }}
                >
                  {i18next.t('codeEditor.cancel')}
                </button>
              </div>
            </Dialog>
          </div>
        )
      } else {
        return (
          <div
            style={{
              height: this.props.height,
              width: this.props.width,
              overflow: "auto",
              border: '1px solid #d2d6de'
            }}
            onClick={() => {
              if (!this.props.readOnly) {
                this.getClickAction()
                this.setState({ open: true })
              }
            }}
          >
            <CodeMirror
              readOnly
              extensions={this.getExtensions()}
              value={this.state.code}
              options={{lineNumbers: true}}
            />
          </div>
        )
      }
    } else {
      return null
    }
  }
}
