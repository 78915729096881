import PromptHistory from "./PromptHistory"
import { Box } from '@mui/material'

const PromptHistoryLink = ({ disabled, status, setStatus, conversation, setConversation, parentIsPromptDialog }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
      <PromptHistory disabled={disabled} status={status} setStatus={setStatus} parentIsPromptDialog={parentIsPromptDialog} conversation={conversation} setConversation={setConversation} />
    </Box>
  )
}

export default PromptHistoryLink