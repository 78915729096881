import { Radio, FormControlLabel } from "@mui/material"
import i18next from 'i18next'

/*
This uses an MUI Radio (https://mui.com/material-ui/api/radio/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.

Additional properties:
  labelText - the label to use for the checkbox (this should generally be an i18n key)
  labelProps - properties to pass to the label (other props go to the Radio)
  value - value of the radio button
*/
const FtoRadio = (props) => {
  const defaultProps = {}
  const revisedProps = Object.assign(defaultProps, props)
  const {value, labelText, labelProps, ...radioProps} = revisedProps
  return (
    <FormControlLabel
      value={value}
      control={<Radio {...radioProps} />}
      label={typeof labelText === 'string' ? i18next.t(labelText) : labelText}
      {...labelProps} />
  )
}

export default FtoRadio