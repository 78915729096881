import { Tabs } from "@mui/material"

/*
This uses an MUI Tabs (https://mui.com/material-ui/api/tabs/).  Any of those properties can be passed through.
See defaultProps below for changes to default properties / notes on property use.
*/
const FtoTabs = (props) => {
  const defaultProps = {
    sx: {
      '& .MuiButtonBase-root': {
        fontSize: '1.3em',
      }
    }
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { ...tabsProps } = revisedProps
  return (
    <Tabs {...tabsProps} />
  )
}

export default FtoTabs