const styles = {
  root: {
    display: 'relative'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh - 102px)',
    width: 'fit-content',
    margin: 'auto',
  },
  immediateContainer: {
    margin: 'auto'
  },
  diagram: {
    '& .reactflow__handle.connectable': {
      pointerEvents: 'none'
    },
    '& .reactflow__handle': {
      border: 'none',
      background: 'none'
    },
    '& .reactflow__node': {
      cursor: 'auto'
    }
  }
}
  
export default styles
