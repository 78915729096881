import React from 'react'
import Field from '../Field'
import i18next from 'i18next'

const Permissions = ({permissions, updatePermissions}) => {
  const REPORT_PERMISSIONS = [
    'all_clients', 'exec_summary', 'ai_dashboard', 'ab_tests', 'bidding_changes', 'budget', 'budget_admin', 'comparison', 'dashboards', 'dashboard_admin', 'dimensions', 'forecast', 'performance', 'projects',
  ]
  const OPTIMIZE_PERMISSIONS = [
    'bidding', 'workflows',
  ]
  const AUTOMATE_PERMISSIONS = [
    'alerts', 'ai_infrastructure', 'change_history', 'data_science', 'feeds', 'integrations', 'manage_ab_tests', 'manage_dimensions', 'offline_conversions', 'revenue_pipeline', 'services', 'status',
  ]
  const USER_PERMISSIONS = [
    'links', 'sql', 'time', 'profile'
  ]
  const ALL_PERMISSIONS = [
    ...REPORT_PERMISSIONS.map(permission => ({menu: i18next.t('menu.all_clients'), permission: permission, name: i18next.t('menu.' + permission)}, {menu: i18next.t('menu.report'), permission: permission, name: i18next.t('menu.' + permission)})),
    ...OPTIMIZE_PERMISSIONS.map(permission => ({menu: i18next.t('menu.optimize'), permission: permission, name: i18next.t('menu.' + permission)})),
    ...AUTOMATE_PERMISSIONS.map(permission => ({menu: i18next.t('menu.automate'), permission: permission, name: i18next.t('menu.' + permission)})),
    ...USER_PERMISSIONS.map(permission => ({menu: i18next.t('menu.user'), permission: permission, name: i18next.t('menu.' + permission)})),
  ]
  const REQUIRES_SQL = [
    'alerts', 'bidding', 'budget_admin', 'dashboard_admin', 'feeds', 'manage_ab_tests', 'manage_dimensions', 'offline_conversions',
    'revenue_pipeline', 'services', 'sql', 'workflows'
  ]

  return (
    <Field label={i18next.t('client.permissions')}>
      <div>{i18next.t('client.permissionWarning')}</div>
      <select
        className='form-control'
        size={15}
        value={permissions} multiple={true}
        onChange={event => updatePermissions(Array.from(event.target.selectedOptions).map(option => option.value))}>
        {
          ALL_PERMISSIONS.map(permission => 
            <option
              key={permission.permission}
              value={permission.permission}>
              {permission.menu + ' > ' + permission.name}{REQUIRES_SQL.includes(permission.permission) ? ' *' : ''}
            </option>
          )
        }
      </select>
    </Field>
  )
}

export default Permissions