import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import FTNotice from '../../FTNotice'
import Form from '../../Form'
import Field from '../../Field'
import Table from '../../widgets/Table'
import { getBiddingRuns, getBidComparisonSummary, getBidComparisonDetails, exportBidComparisonDetails } from '../../../api/bidding'
import { formatCurrency, formatPercent, formatFloat } from '../../../utilities/formatting'
import './Comparison.scss'

class Interface extends Component {
  summaryTableRef = createRef()
  detailsTableRef = createRef()
  
  constructor(props) {
    super(props)
    this.state = {
      bidding_runs: [],
      bidding_run_1: '',
      bidding_run_2: '',
      showCompare: false,
      summary_data: [],
    }
  }

  componentDidMount() {
    this.getBiddingRuns()
  }

  getBiddingRuns() {
    getBiddingRuns(this.props.internal_client_id, 'all').then(data => {
      const runs = data.results
      this.setState({
        bidding_runs: runs,
        bidding_run_1: runs.length ? runs[0].id : '',
        bidding_run_2: runs.length > 1 ? runs[1].id : ''
      })
    })
  }

  compare() {
    if (this.state.bidding_runs.length < 2) {
      FTNotice('There are must be at least two bidding runs to do a comparison.', 15000)
    } else if (this.state.showCompare) {
      this.getBidSummary()
      if (this.detailsTable.current) {
        this.detailsTable.current.refreshRows()
      }
    } else {
      this.setState({showCompare: true})
      this.getBidSummary()
    }
  }

  getBidSummary() {
    getBidComparisonSummary(this.state.bidding_run_1, this.state.bidding_run_2).then(data => {
      this.setState({summary_data: data.results}, () => {
        if (this.summaryTableRef.current) {
          this.summaryTableRef.current.updateRows(data.results)
        }
      })
    })
  }

  getSummaryColumns() {
    return [{
      id: 'subbudget',
      datatype: 'text',
      width: 120,
    }, {
      id: 'engine',
      datatype: 'text',
      width: 100,
    }, {
      id: 'entity_type',
      datatype: 'text',
      width: 100,
    }, {
      id: 'bid_change',
      header: 'Bid Change',
      datatype: 'text',
      width: 100,
    }, {
      id: 'entity_cost_pct',
      header: 'Run 1\nCost %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 80,
    }, {
      id: 'entity_cost_pct2',
      header: 'Run 2\nCost %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 80,
    }, {
      id: 'entity_revenue_pct',
      header: 'Run 1\nRevenue %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 100,
    }, {
      id: 'entity_revenue_pct2',
      header: 'Run 2\nRevenue %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 100,
    }, {
      id: 'total_cost_pct',
      header: 'Run 1\nTot Cost %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 100,
    }, {
      id: 'total_cost_pct2',
      header: 'Run 2\nTot Cost %',
      datatype: 'numeric',
      format: value => formatPercent(value),
      width: 100,
    }, {
      id: 'internal_roas',
      header: 'Run 1\nROAS',
      datatype: 'numeric',
      format: value => formatFloat(value),
      width: 80,
    }, {
      id: 'internal_roas2',
      header: 'Run 2\nROAS',
      datatype: 'numeric',
      format: value => formatFloat(value),
      width: 80,
    }, {
      id: 'cpc',
      header: 'Run 1\nCPC',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 80,
    }, {
      id: 'cpc2',
      header: 'Run 2\nCPC',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 80,
    }]
  }

  getDetailsColumns() {
    return [{
      id: 'subbudget',
      datatype: 'text',
      width: 150,
    }, {
      id: 'campaign',
      header: 'Campaign',
      datatype: 'text',
      width: 300,
    }, {
      id: 'ad_group',
      header: 'Ad Group',
      datatype: 'text',
      width: 300,
    }, {
      id: 'criteria',
      datatype: 'text',
      width: 300
    }, {
      id: 'bid',
      header: 'Run 1\nBid',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 80,
      classRules: {
        'positive': (params) => {return params.data && params.data.bid_change > 0},
        'negative': (params) => {return params.data && params.data.bid_change < 0},
      },
    }, {
      id: 'bid2',
      header: 'Run 2\nBid',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 80,
      classRules: {
        'positive': (params) => {return params.data && params.data.bid_change2 > 0},
        'negative': (params) => {return params.data && params.data.bid_change2 < 0},
      },
    }, {
      id: 'bid_change',
      header: 'Run 1\nChange',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 90,
    }, {
      id: 'bid_change2',
      header: 'Run 2\nChange',
      datatype: 'numeric',
      format: value => formatCurrency(value),
      width: 90,
    }]
  }

  renderSelect(run) {
    return (
      <select
        value={this.state[run]}
        onChange={event => {
          const newState = {}
          newState[run] = event.target.value
          this.setState(newState)
        }}
        className="form-control"
      >
        {this.state.bidding_runs.map((run, i) => (
          <option key={i} value={run.id}>{run.bid_run}</option>
        ))}
      </select>
    )    
  }

  render() {
    return (
      <div>
        <Form
          objectType="Comparison"
          objectName=""
          newObject={false}
          canEdit={false}
          canDelete={false}
          otherButtons={<button className="btn btn-primary" onClick={() => this.compare()}>Compare</button>}
          parentLink={null}
        >
          <Field label="Bidding Run 1">
            {this.renderSelect('bidding_run_1')}
          </Field>
          <Field label="Bidding Run 2">
            {this.renderSelect('bidding_run_2')}
          </Field>
        </Form>
        {this.state.showCompare && (
          <div style={{marginLeft: 15, width: 'calc(100% - 30px)'}}>
            <Table
              ref={this.summaryTableRef}
              dataset={this.state.summary_data}
              columns={this.getSummaryColumns()} 
              refresh={() => this.getBidSummary()}
              hidePagination={true}
              headerHeight={50}
              autoHeight={true} />
            <Table
              ref={this.detailsTableRef}
              fetch={(tableData) => getBidComparisonDetails(this.state.bidding_run_1, this.state.bidding_run_2, tableData)}
              export={(tableData) => exportBidComparisonDetails(this.state.bidding_run_1, this.state.bidding_run_2, tableData)}
              headerHeight={50}
              columns={this.getDetailsColumns()} />
          </div>
        )}
      </div>
    )
  } 
}

const mapStateToProps = (state) => ({
  internal_client_id: state.users.user ? state.users.user.client.internal_client_id : null,
})

export default connect(mapStateToProps)(Interface)
