import Box from '@mui/material/Box'
import Checkbox from '../widgets/Checkbox'
import ButtonRow from '../widgets/ButtonRow'
import Button from '../widgets/Button'
import Section from '../widgets/Section'

const Configuration = ({wrenchChannels, updateChannel, allSubbudgets, findSubbudget, toggleSubbudgets, apply, cancel}) => {
  return (
    <Box className="site-menu">
      <Section name="budget.channel">
        <Box>
          <Checkbox
            labelText="budget.sem"
            checked={wrenchChannels.indexOf('sem') >= 0}
            onChange={event => {updateChannel('sem', event.target.checked)}} />
        </Box>
        <Box>
          <Checkbox 
            labelText="budget.display"
            checked={wrenchChannels.indexOf('display') >= 0} 
            onChange={event => {updateChannel('display', event.target.checked)}} />
        </Box>
        <Box>
          <Checkbox 
            labelText="budget.social"
            checked={wrenchChannels.indexOf('social') >= 0} 
            onChange={event => {updateChannel('social', event.target.checked)}} />
        </Box>
      </Section>
      <Section name="budget.subbudget">
        {allSubbudgets.map((subbudget, i) => (
            <Box key={i}>
              <Checkbox 
                labelText={subbudget} 
                checked={findSubbudget(subbudget)} 
                onChange={event => {
                  toggleSubbudgets(
                    subbudget,
                    event.target.checked
                  )
                }} />
            </Box>
          ))}
      </Section>
      <Box display="flex" justifyContent="center" >
        <ButtonRow>
          <Button
            buttonText="allClients.apply"
            onClick={apply}
            className="btn btn-default apply toggle-nav"
          />
          <Button
            color="secondary"
            buttonText="allClients.cancel"
            onClick={cancel}
            className={"btn btn-default cancel toggle-nav"}
          />
        </ButtonRow>
      </Box>
    </Box>
  )
}

export default Configuration
