import Switch from '../widgets/Switch'
import i18next from 'i18next'

const ExcludeFromBidding = (props) => {
  return (
    <Switch
      checked={props.excludeFromBidding}
      onChange={event => props.onUpdate({ exclude_from_bidding: event.target.checked })}
      label={i18next.t('abtest.edit.excludeFromBidding')}
    />
  )
}

export default ExcludeFromBidding