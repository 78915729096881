import React from 'react'
import { Box, Typography, Stack, Paper, Divider, useTheme } from '@mui/material'
import Description from './Description'
import i18next from 'i18next'

// props.description: a short descrition providing the user with understanding of what the input does 
// props.name: the name of the input i.e. Campaign Select
// props.input: generally should be another Widget from /widgets and will be a component passed in as a prop
// props.showWarning: a boolean that is held in state by a parent component used to hide/show the warning message
// props.inputHasValue: a boolean that tracks state of whether an input has or does not have a value, used to trigger warning message on required inputs
// props.warningMessage: input required message; a string passed from parent component; should be in i18n form when passed

const Section = (props) => {
  const theme = useTheme()
  const defaultProps = {
    padding: 2,
    elevation: 3,
    backgroundColor: "theme.palette.grey[300]",
    direction: 'column',
    maxWidth: '95vw'
  }
  const revisedProps = Object.assign(defaultProps, props)
  const { minHeight, maxWidth, direction, sectionBackground, padding, elevation, children, inputHasValue, warningMessage, showWarning, description, name, minWidth, width, height, ...sectionProps } = revisedProps
  return (
    <Box sx={{
      bgcolor: theme.palette.grey[300],
      padding: padding,
      borderRadius: 3,
      mb: 3,
      height: height,
      width: width,
      maxWidth: maxWidth,
      minWidth: minWidth,
      minHeight: minHeight,
      display: "block",
    }}>
      <Paper elevation={elevation} sx={{ p: 2, height: '100%', backgroundColor: sectionBackground }}>
        <Stack spacing={3} direction={direction}>
          {name && <Typography variant="h5">{i18next.t(name)}</Typography>}
          {(description && name) && <Divider variant="fullwidth" />}
          {description &&
            <Description description={i18next.t(description)} {...sectionProps} />
          }
          {children}
          {showWarning && <Box>
            {!inputHasValue && <Typography sx={{ variant: "h4", color: "red" }}>{i18next.t(warningMessage)}</Typography>}
          </Box>}
        </Stack>
      </Paper>
    </Box>
  )
}

export default Section